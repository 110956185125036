import React from 'react'
import {
  Form,
  Button
} from 'carbon-components-react'
import { compose } from 'react-apollo'
import { CardElement, injectStripe } from 'react-stripe-elements'
import WithAlertModal from '../hocs/WithAlertModal'

const cardElementStyle = {
  base: {
    backgroundColor: '#1f1f1',
    color: 'white',
    fontFamily: 'inherit',
    '::placeholder': {
      color: '#4e4e4'
    }
  },
  invalid: {
    color: '#e0182d',
    iconColor: '#e0182d'
  },
  complete: {
    backgroundColor: '#1f1f1'
  }
}
class CardDetailsForm extends React.PureComponent {
  state = {
    loading: false
  }

  constructor(props) {
    super(props)

    this.cardRef = React.createRef()
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(e) {
    e.preventDefault()
    if (this.state.loading) {
      return
    }

    this.setState({
      loading: true
    })

    this.props.stripe.createToken().then(({ token, error }) => {
      if (error) {
        return
      }
      this.props.onSubmit(token, error)
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.loading) {
      return {
        loading: true
      }
    }

    return {
      loading: prevState.loaading
    }
  }

  render() {
    let buttonText = 'Save'
    if (this.props.hasSavedCard) {
      buttonText = (this.state.loading ? 'Saving...' : 'Save')
    } else {
      buttonText = (this.state.loading ? 'Upgrading...' : 'Upgrade')
    }
    return (
      <>
      <div className='form'>
        <Form className='stripe-element-form' onSubmit={this.onSubmit} autoComplete='off'>
          <CardElement ref={this.cardRef} style={cardElementStyle} autoComplete='off' onChange={(e) => this.props.cardChange(e)} />
          <div className='formRow formRowLast formRowSubmit'>
            <button className='bx--btn bx--btn--secondary' onClick={() => this.props.onCancel(this.cardRef)}>
                  Cancel
            </button>
            <Button type='submit'>
              {buttonText}
            </Button>
          </div>
        </Form>
      </div>
    </>
    )
  }
}

export default compose(injectStripe, WithAlertModal)(CardDetailsForm)

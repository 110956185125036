import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import ToggleMenu from './ToggleMenu'
import dashboard from '../assets/leftMenu/dashboard.svg'
import dashboardActive from '../assets/leftMenu/dashboardActive.svg'
import favorites from '../assets/leftMenu/favorites.svg'
import favoritesActive from '../assets/leftMenu/favoritesActive.svg'
import projects from '../assets/leftMenu/projects.svg'
import projectsActive from '../assets/leftMenu/projectsActive.svg'
import files from '../assets/leftMenu/files.svg'
import filesActive from '../assets/leftMenu/filesActive.svg'
import parties from '../assets/leftMenu/parties.svg'
import partiesActive from '../assets/leftMenu/partiesActive.svg'
import feedback from '../assets/leftMenu/feedback.svg'
import feedbackActive from '../assets/leftMenu/feedbackActive.svg'
import songActive from '../assets/leftMenu/songActive.svg'
import song from '../assets/leftMenu/song.svg'
import playlistActive from '../assets/leftMenu/playlistActive.svg'
import playlist from '../assets/leftMenu/playlist.svg'
import support from '../assets/leftMenu/support.svg'
import supportActive from '../assets/leftMenu/supportActive.svg'
import newUploadActive from '../assets/leftMenu/newUploadActive.svg'
import newPartyActive from '../assets/leftMenu/newPartyActive.svg'
import newSongActive from '../assets/leftMenu/newSongActive.svg'
import newProjectActive from '../assets/leftMenu/newProjectActive.svg'
import close from '../assets/leftMenu/close.svg'
import open from '../assets/leftMenu/open.svg'
import { compose, withApollo } from 'react-apollo'
const navitems = [
  { href: '/', text: 'Dashboard', icon: dashboard, iconActive: dashboardActive },
  { href: '/favorites', text: 'Favorites', icon: favorites, iconActive: favoritesActive },
  { href: '/projects', text: 'Projects', icon: projects, iconActive: projectsActive },
  { href: '/songs', text: 'Songs', icon: song, iconActive: songActive },
  { href: '/playlists', text: 'Playlists', icon: playlist, iconActive: playlistActive },
  { href: '/files', text: 'Files', icon: files, iconActive: filesActive },
  { href: '/parties', text: 'Parties', icon: parties, iconActive: partiesActive },
  {
    href: 'https://www.vevacollect.com/feedback',
    text: 'Feedback',
    target: '_blank',
    icon: feedback,
    iconActive: feedbackActive
  },
  {
    href: 'https://www.youtube.com/channel/UCZX1rl77nP75x6H2IByuMpw/videos?view_as=public',
    text: 'Support',
    target: '_blank',
    icon: support,
    iconActive: supportActive
  }
]

const newitems = [
  { href: '/projects/create', text: 'Create Project', icon: newProjectActive },
  { href: '/songs/create', text: 'Create Song', icon: newSongActive },
  { href: '/parties/create', text: 'Create Party', icon: newPartyActive },
  { href: '/files', text: 'Upload Files', icon: newUploadActive }
]
class Sidebar extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openNew: false
    }
  }
  updateView(main) {
    if (this.props.menuOpen) {
      main[0].style.marginLeft = '221px'
      if (document.getElementsByClassName('main-Switcher').length) {
        document.getElementsByClassName(
          'main-Switcher'
        )[0].style.marginLeft = '0'
      }
    } else {
      if (window.innerWidth >= 1024) {
        main[0].style.marginLeft = '81px'
      }
      if (document.getElementsByClassName('main-Switcher').length) {
        document.getElementsByClassName(
          'main-Switcher'
        )[0].style.marginLeft = '0'
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const main = document.getElementsByClassName('main')
    if (prevProps.menuOpen !== this.props.menuOpen || this.props.menuOpen) {
      if (window.innerWidth >= 1024) {
        if (main.length) {
          this.updateView(main)
        } else {
          this.observer = new MutationObserver(() => {
            const main = document.getElementsByClassName('main')
            if (main.length) {
              this.removeObserver()
              this.updateView(main)
            }
          })
          this.observer.observe(document, { subtree: true, childList: true })
        }
      }
    }
  }

  componentWillUnmount = () => {
    this.removeObserver()
  }

  removeObserver = () => {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }
  }

  render() {
    const handleUpdate = () => {
      this.props.handleMenuToggle()
      if (this.props.newOpen === true) {
        setTimeout(() => this.props.handleNewToggle(), 10)
      }
    }

    const handleLogOut = () => {
      localStorage.removeItem('authToken')
      this.props.client.resetStore()
      this.props.history.push('/login')
    }
    const handleOpenCloe = () =>{
      this.setState({ openNew: !this.state.openNew })
    }

    return (
      <div>
        <div className={'appSidebar ' + (this.props.menuOpen ? ' appSidebarOpen' : '')}>

          <div className={'toggleMenu'}>
            <ToggleMenu size='small'
              open={this.props.menuOpen}
              updateMenu={handleUpdate}/>
          </div>

          <nav className='appNavigation'>
            {navitems.map((link, j) => {
              if (link.target === '_blank') {
                return (
                  <a
                    href={link.href} key={link.text}
                    target='_blank' rel='noopener noreferrer'
                  >
                    <div key={j} className='slide-bar-items'>
                      <img src={link.icon} alt='icon'/>
                      {this.props.menuOpen ? link.text : ''}
                    </div>
                  </a>
                )
              }

              return (
                <Link className={
                  window.location.pathname === link.href ?
                    'appNavigationItem' : 'appNavigationItemPassive'}
                to={link.href} key={link.text}>
                  <div key={j}
                    className={link.text === 'Parties' ? 'slide-bar-items-party' :
                      link.text === 'Log Out' ? 'slide-bar-items-out' :
                        'slide-bar-items'}
                    onClick={() => link.text === 'Log Out' ? handleLogOut() : ''}>
                    <img src={window.location.pathname === link.href ?
                      link.iconActive : link.icon} alt='icon'
                    />
                    <p
                      className={window.location.pathname === link.href ?
                        'active-text' : ''}>  {this.props.menuOpen ? link.text : ''}</p>

                  </div>
                </Link>
              )
            })}
          </nav>
        </div>


        {this.state.openNew === true && (
          <div className='new-actions-items-wrapper'>
            {newitems.map((link, j) => (
              <div
                onClick={handleOpenCloe}
                key={j}
                className='new-actions-items'
              >
                <Link
                  to={{ pathname: link.href, state: { uploadFiles: true } }}
                  className='new-items-name'
                >
                  <span>{link.text}</span>
                </Link>
                <Link
                  to={{ pathname: link.href, state: { uploadFiles: true } }}
                >
                  {' '}
                  <div className='new-items-image'>
                    <img src={link.icon} alt={'icon'} />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
        <button onClick={handleOpenCloe} className='new-actions'>
          <img src={this.state.openNew === true ? close : open} alt='icon' />
        </button>
      </div>
    )
  }
}
export default compose(
  withRouter,
  withApollo,
)(Sidebar)

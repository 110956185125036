import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'

import { DELETE_PARTY_CONTACT } from '../graphql/mutations'
import WithAlertModal from '../hocs/WithAlertModal'
import SimpleListItem from './SimpleListItem'


class PartyContactList extends React.PureComponent {
  render() {
    const contactsItems = this.props.rows

    return (
      contactsItems.map((item, j) => (
        <div key={j} className='affiliations-wrapper'>
          <SimpleListItem
            onDeleteItem={this.props.onDeleteContact}
            onDelete={this.props.onDelete}
            row={item}
            deletePartyItem={this.props.deletePartyContact}
            alert={this.props.alert}
            partyId={this.props.partyId}
            alertType='delete'
            alertMessage='Are you sure you want to delete this contact?'
            alertButtonText='Yes'
            title={`${item.type.toUpperCase().slice(0,1)+item.type.slice(1)} \xa0\xa0|\xa0\xa0 ${item.value}`} // eslint-disable-line
          />
        </div>
      ))
    )
  }
}

export default compose(
  withRouter,
  WithAlertModal,
  graphql(DELETE_PARTY_CONTACT, { name: 'deletePartyContact' })
)(PartyContactList)


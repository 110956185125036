import React from 'react'
import { graphql } from 'react-apollo'
import { Form, TextInput, Modal, TextArea } from 'carbon-components-react'
import { DateTime } from 'luxon'

import { CREATE_PARTY } from '../graphql/mutations'
import Validator from '../utils/Validator'
import PartyValidator from '../validators/Party'
import Tooltip from './ui/Tooltip'
import definitions from '../utils/definitions.json'

const initialState = {
  type: 'person',
  title: '',
  prefix: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  isni: '',
  comments: '',
  birthDate: '',
  deathDate: '',
  errors: {}
}

class CreateRecordLabelModal extends Validator {
  validator = PartyValidator

  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
      type: props.defaultType || 'person'
    }
  }

  submit() {
    const dateFormattedState = {
      ...this.state,
      contacts: [],
      addresses: [],
      birthDate: this.state.birthDate
        ? DateTime.fromFormat(this.state.birthDate, 'LL/dd/yyyy').toISODate()
        : null,
      deathDate: this.state.deathDate
        ? DateTime.fromFormat(this.state.deathDate, 'LL/dd/yyyy').toISODate()
        : null
    }
    const dataToSubmit = Object.assign(
      {},
      ...Object.keys(dateFormattedState)
        .filter(key => {
          if (key === 'birthDate' || key === 'deathDate') {
            return Boolean(dateFormattedState[key])
          }
          return true
        })
        .map(key => ({ [key]: dateFormattedState[key] }))
    )

    this.props
      .createParty(dataToSubmit)
      .then(response => {
        this.props.onRecordLabelCreated(response.data.createParty)
        this.setState({ ...initialState })
      })
      .catch(e => {
        this.setState({
          ...this.state,
          errors: Validator.flattenInitialErrors(e, 'input.')
        })
      })
  }

  render() {
    return (
      <Modal
        className='modal-scrollable artist-modal'
        open={this.props.open}
        modalHeading='New Record Label'
        primaryButtonText='Create'
        secondaryButtonText='Cancel'
        shouldSubmitOnEnter={true}
        selectorPrimaryFocus='.bx--text-input'
        onRequestSubmit={this.handleSubmit}
        onRequestClose={this.props.onRequestClose}
      >
        <div className='form'>
          <Form>
            <div className='formRow'>
              <TextInput
                id='firstName'
                name='firstName'
                labelText='* Name'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.firstName || ''}
                invalid={this.state.errors.firstName ? true : false}
                invalidText={this.state.errors.firstName}
              />
            </div>
            <div className='formRow'>
              <TextInput
                id='isni'
                name='isni'
                labelText={<Tooltip term={'ISNI'} definition={definitions.ISNI} />}
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.isni || ''}
                invalid={this.state.errors.isni ? true : false}
                invalidText={this.state.errors.isni}
              />
            </div>
            <div className='formRow'>
              <TextArea
                id='comments'
                name='comments'
                labelText='Description'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.comments || ''}
                invalid={this.state.errors.comments ? true : false}
                invalidText={this.state.errors.comments || ''}
              />
            </div>
          </Form>
        </div>
      </Modal>
    )
  }
}

export default graphql(CREATE_PARTY, {
  name: 'createParty',
  props: data => {
    return {
      createParty: party =>
        data.createParty({
          variables: party
        })
    }
  }
})(CreateRecordLabelModal)

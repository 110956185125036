import { graphql } from 'react-apollo'
import { CREATE_SESSION_RECORDING } from '../../graphql/mutations'

export default graphql(CREATE_SESSION_RECORDING, {
  name: 'createSessionRecording',
  props: data => {
    return {
      createSessionRecording: (sessionId, recordingId) =>
        data.createSessionRecording({
          variables: {
            sessionId,
            recordingId
          }
        })
    }
  }
})

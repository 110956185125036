import React from 'react'
import { iconCaretDown } from 'carbon-icons'
import { Icon } from 'carbon-components-react'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/lib/Async'
import CreatableSelect from 'react-select/lib/Creatable'

const CustomStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'var(--component-background-color)',
    borderColor: '',
    borderWidth: 0,
    boxShadow: state.isFocused ? '0 2px 0 0 var(--accent-color)' : '',
    borderRadius: 0,
    fontFamily: 'Poppins, Helvetica Neue, Arial, sans-serif',
    fontSize: '0.875rem',
    minHeight: 40,
    lineHeight: 'normal'
  }),
  input: provided => ({
    ...provided,
    border: '',
    color: 'white',
    fontFamily: 'Poppins, Helvetica Neue, Arial, sans-serif',
    fontSize: '0.875rem'
  }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: '1rem'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white'
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white'
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
    width: '2.5rem'
  }),
  clearIndicator: provided => ({
    ...provided,
    'padding': 0,
    '> svg': {
      fill: 'var(--accent-color)'
    }
  }),
  option: (base, state) =>({
    ...base,
    'color': 'white',
    'backgroundColor': state.isSelected ?
      'var(--accent-color)' : 'var(--component-background-color)',
    'fontFamily': 'Poppins, Helvetica Neue, Arial, sans-serif',
    'fontSize': '12px',
    'fontWeight': 'normal',

    ':hover': {
      backgroundColor: 'var(--accent-color)'
    }
  })
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon={iconCaretDown} description='open list of options' className='bx--select__arrow' />
    </components.DropdownIndicator>
  )
}

const error = (id, text) => (
  <div className={`bx--form-requirement`} id={`${id}-error-msg`}>
    {text}
  </div>
)

const helper = text => <div className={`bx--form__helper-text`}>{text}</div>

export const SelectStandard = props => {
  const add = props.btnText === 'Add' ?
    require('../assets/images/connect.svg') :
    require('../assets/images/addActive.svg')
  return (
    <div className='bx--form-item'>
      <div className='bx--select'>
        <label htmlFor={props.id} className='bx--label'>
          {props.labelText}
          {props.btnText ?
            <img onClick={()=>props.btnOnClick()}
              className='icon-img' src={add} alt='add'/> : null}
        </label>
        {props.helperText && helper(props.helperText)}
        <Select
          className={'bx--react-select' + (props.invalid ? ' bx--react-select--invalid' : '')}
          components={{ DropdownIndicator }}
          styles={CustomStyles}
          {...props}
        />
        {props.invalid && error(props.id, props.invalidText)}
      </div>
    </div>
  )
}

export const SelectASync = props => {
  const add = props.btnText === 'Add' ?
    require('../assets/images/connect.svg') :
    require('../assets/images/addActive.svg')
  const placeholder = props.placeholder ? props.placeholder : 'Select...'
  return (
    <div className='bx--form-item'>
      <div className='bx--select'>
        <label htmlFor={props.id} className='bx--label'>
          {props.labelText}
          {props.buttonFromParent}
          {props.btnText ? <img onClick={()=>props.btnOnClick()}
            className='icon-img' src={add} alt='add'/> : null}

        </label>
        {props.helperText && helper(props.helperText)}
        <AsyncSelect
          className={'bx--react-select' + (props.invalid ? ' bx--react-select--invalid' : '')}
          components={{ DropdownIndicator }}
          placeholder={placeholder}
          styles={CustomStyles}
          {...props}
        />
        {props.invalid && error(props.id, props.invalidText)}
      </div>
    </div>
  )
}

export const SelectCreatable = props => {
  const add = props.btnText === 'Add' ?
    require('../assets/images/connect.svg') :
    require('../assets/images/addActive.svg')
  return (
    <div className='bx--form-item'>
      <div className='bx--select'>
        <label htmlFor={props.id} className='bx--label'>
          {props.labelText}
          {props.btnText ? <img onClick={()=>props.btnOnClick()}
            className='icon-img' src={add} alt='add'/> : null}
        </label>
        {props.helperText && helper(props.helperText)}
        <CreatableSelect
          className={'bx--react-select' + (props.invalid ? ' bx--react-select--invalid' : '')}
          components={{ DropdownIndicator }}
          styles={CustomStyles}
          {...props}
        />
        {props.invalid && error(props.id, props.invalidText)}
      </div>
    </div>
  )
}

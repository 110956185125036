import React from 'react'
import { DataTable } from 'carbon-components-react'

import { Query } from 'react-apollo'

import { GET_SHARES_AND_DIRECT_LINKS } from '../../graphql/queries/share'
import GetUser from '../../hocs/queries/GetUser'
import ShareTable from './ShareTable'
import SectionContainer from '../SectionContainer'
import EmptyTable from '../EmptyTable'
import CreatePagesHeader from '../CreatePagesHeader'
import { ReactComponent as FolderIcon } from '../../assets/images/folder.svg'

const { TableCell, Table, TableRow, TableBody } = DataTable

function SharesList({
  shouldRerender,
  user, // from GetUser
  ...props
}) {
  return (
    <>
      <Query
        query={GET_SHARES_AND_DIRECT_LINKS}
        fetchPolicy='cache-and-network'
      >
        {({ loading, error, data, refetch }) => {
          if (error) {
            return (
              <EmptyTable>
                <p>
                  We encountered an error loading your file shares. Please try
                  again later.
                </p>
              </EmptyTable>
            )
          }

          // Format files to how ShareModal expects them
          // - Some graphql variable names are inconsistent between queries and mutations
          // - What comes back is a ShareFile object, but we need the File object inside that
          let shareRows = data.getShares ? data.getShares.concat(data.getShareDirectLinks) : []
          shareRows = shareRows ? shareRows.map(share => ({
            ...share,
            expiry: share.expiresAt,
            uuid: share.id,
            files: share.files && share.files.map(shareFile => shareFile.file),
            copylink: 'Copy Linkitem-ghosted'
          }))
            : []

          shareRows = shareRows.sort((a, b)=> {
            return new Date(b.createdAt) - new Date(a.createdAt)
          })
          const shareRowsLimit = shareRows.slice(0, 5)
          if (!loading && shareRows.length < 1) {
            return (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className='empty-row'>
                      <FolderIcon /> <span>No File</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )
          }
          return (
            <>
              {!loading && (
                <>
                  {window.location.pathname === '/files' && (
                    <CreatePagesHeader
                      subtitle='Files and Playlist Shares'
                      showSearch={true}
                      linkText='View All'
                      linkUrl='/files/shares'
                    />
                  )}
                  {window.location.pathname === '/files/shares' && (
                    <CreatePagesHeader
                      subtitle='Files and Playlist Shares'
                      showSearch={true}
                    />
                  )}
                  <div className='sections-container files-playlist-shares'>
                    <SectionContainer>
                      <ShareTable
                        nameCellClass='share-table'
                        rows={
                          window.location.pathname === '/files'
                            ? shareRowsLimit
                            : shareRows
                        }
                        batchActions
                        showSkeleton={loading}
                        userId={user.id}
                        pagination={window.location.pathname !== '/files'}
                        refetch={refetch}
                        refetchShare={refetch}
                        shouldRerender={shouldRerender}
                        perPage={5}
                        filesPage={true}
                        {...props}
                      />
                    </SectionContainer>
                  </div>
                </>
              )}
            </>
          )
        }}
      </Query>
    </>
  )
}

export default GetUser(SharesList)

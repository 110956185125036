import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import Helmet from 'react-helmet-async'
import { compose, Query } from 'react-apollo'

import {
  GET_PROJECT_ROOT_FILES,
  GET_PROJECT_FOLDER
} from '../../graphql/queries'

import * as permissions from '../../utils/permissions'

import { getUserFavouritesByType } from '../../utils/selectors'
import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import FileTable from '../../components/FileTable'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

// import RecentActivity from '../../components/RecentActivity'

import WithUploads from '../../hocs/WithUploads'
import WithUploadModal from '../../hocs/WithUploadModal'
import Hero from '../../components/Hero'
import { WithMoveFilesFrom } from '../../hocs/MoveFiles'
import { filterPermissions } from '../../utils/permissions'

class ProjectFiles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
  render() {
    const favourites = getUserFavouritesByType(this.props.user)
    const uploadInQuery = new URLSearchParams(this.props.location.search).get(
      'upload'
    )
    return (
      <>
        <Helmet>
          <title>Files | {this.props.project.name}</title>
        </Helmet>
        <main className='main project-list-file'>
          <div className='project-files'>
            <FileList
              match={this.props.match}
              location={this.props.location}
              uploadModal={this.props.uploadModal}
              shouldOpenUploadModal={uploadInQuery !== null}
              uploads={this.props.uploads}
              project={this.props.project}
              setProjectIdAndFolderId={
                this.props.uploads.actions.setProjectIdAndFolderId
              }
              fileFavourites={favourites.file || []}
              folderFavourites={favourites.folder || []}
              moveFilesFrom={this.props.moveFilesFrom}
            />
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  WithUploads,
  WithUploadModal,
  withRouter,
  GetUser,
  GetProject,
  WithMoveFilesFrom
)(ProjectFiles)

class FileList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
      shouldOpenUploadModal: this.props.shouldOpenUploadModal
    }
  }
  getSearchTerm = value => {
    this.setState({
      searchTerm: value
    })
  };
  getTags = permission => {
    const tags = []
    const permissionsKyes = Object.keys(permission)
    permissionsKyes.forEach(key => {
      if (permission[key] === true) {
        tags.push({
          tagName: key.charAt(0).toUpperCase() + key.slice(1)
        })
      }
    })
    return tags
  };

  getBreadcrumb(file, folders, currentFolder = false) {
    let breadcrumb =
      window.location.hash === '#all'
        ? [
          {
            href: `/files`,
            name: 'Files'
          },
          {
            href: `/projects/${this.props.project.id}`,
            name: this.props.project.name
          },
          {
            href: `/projects/${this.props.project.id}/files`,
            name: 'Project Files'
          }
        ]
        : [
          {
            href: `/projects`,
            name: 'Projects'
          },
          {
            href: `/projects/${this.props.project.id}`,
            name: this.props.project.name
          },
          {
            href: `/projects/${this.props.project.id}/files`,
            name: 'Files'
          }
        ]

    let path = []
    if (currentFolder) {
      let hrefPath = `/projects/${this.props.project.id}/files`

      if (currentFolder.path.length > 0) {
        currentFolder.path.forEach(item => {
          hrefPath = `${hrefPath}/${item.id}`
          breadcrumb.push({
            href: hrefPath,
            name: item.name
          })

          path.push(item.name)
        })
      }

      hrefPath = `${hrefPath}/${currentFolder.id}`
      breadcrumb.push({
        href: hrefPath,
        name: currentFolder.name
      })

      path.push(currentFolder.name)
    }

    return { path, breadcrumb }
  }

  hasRecordingAccess(data) {
    if (this.props.match.params.folderId && data.getFolder.recording) {
      return permissions.hasRecordingAccess(
        this.props.project.id,
        data.getFolder.recording.id
      )
    }

    return false
  }

  render() {
    const isProjectOwner = permissions.isProjectOwner(this.props.project.id)
    const can = permissions.can(this.props.project.id, 'file')

     const canRecording = permissions.can(this.props.project.id, 'recording')
     const canCreate = isProjectOwner || can.create
     const canDownload = isProjectOwner || can.download
     const canDelete = isProjectOwner || can.delete
     let projectFilePermissions = can


    return (
      <>
        {this.props.location.pathname === this.props.match.url && (
          <Query
            query={
              this.props.match.params.folderId
                ? GET_PROJECT_FOLDER
                : GET_PROJECT_ROOT_FILES
            }
            variables={{
              projectId: this.props.project.id,
              folderId: this.props.match.params.folderId
            }}
            fetchPolicy='cache-and-network'
            pollInterval={
              this.props.uploads.state.inProgressCount > 0 ? 0 : 10000
            }
          >
            {({ loading, error, data, refetch, networkStatus }) => {
              if (loading && networkStatus !== 6 && networkStatus !== 4) {
                return null
              }

              if (error) {
                return `Error! ${error.message}`
              }

              const files = this.props.match.params.folderId
                ? data.getFolder.files
                : data.getProject.files

              let folders = this.props.match.params.folderId
                ? data.getFolder.folders
                : data.getProject.folders

              const isRecordingCollaborator = this.hasRecordingAccess(data)

              const { breadcrumb, path } = this.getBreadcrumb(
                files,
                folders,
                data.getFolder
              )

              // should we open the modal on page load?
              if (this.state.shouldOpenUploadModal) {
                this.props.uploadModal.open(
                  '/' + path.join('/'),
                  this.props.project.name,
                  this.props.project.id,
                  this.props.match.params.folderId,
                  '',
                  refetch
                )
                // don't open again on rerenders
                this.setState({ shouldOpenUploadModal: false })
              }

              return (
                <>
                <Hero
                  subtitle='Files'
                  getSearchTerm={this.getSearchTerm}
                  showSearch={true}
                  btnText={canCreate || isRecordingCollaborator ? 'Upload' : false}
                  btnOnClick={() => this.props.uploadModal.open(
                    '/' + path.join('/'),
                    this.props.project.name,
                    this.props.project.id,
                    this.props.match.params.folderId,
                    '',
                    refetch
                  )}>
                  <Breadcrumb items={breadcrumb} />
                </Hero>
                  <CreatePagesHeader
                    subtitle='Project Files'
                    withDivider={false}
                    tags={!isProjectOwner ? this.getTags(filterPermissions(projectFilePermissions)) : []}
                  />

                  <div className='sections-container custom-checkbox'>
                    <SectionContainer>
                      <FileTable
                        rows={[...folders, ...files]}
                        nameCellClass='files-project-table'
                        searchTerm={this.state.searchTerm}
                        showSearch={false}
                        projectId={this.props.project.id}
                        project={this.props.project}
                        folderId={this.props.match.params.folderId}
                        fileFavourites={this.props.fileFavourites}
                        folderFavourites={this.props.folderFavourites}
                        showSkeleton={
                          loading && networkStatus !== 6 && networkStatus !== 4
                        }
                        refetch={refetch}
                        sortByOrder={true}
                        favourite
                        sortDirection='ASC'
                        sortColumn='name'
                        batchActions
                        actions
                        rowActions
                        pagination
                        canCreate={canCreate || isRecordingCollaborator}
                        canDownload={canDownload || isRecordingCollaborator}
                        canDelete={canDelete || isRecordingCollaborator}
                        canCreateRecording={
                          isProjectOwner || canRecording.create
                        }
                        canDownloadRecording={
                          isProjectOwner || canRecording.download
                        }
                        canDeleteRecording={
                          isProjectOwner || canRecording.delete
                        }
                        canUpdateRecording={
                          isProjectOwner || canRecording.update
                        }
                        isProjectOwner={isProjectOwner}
                        perPage='100'
                      />
                    </SectionContainer>
                  </div>
                </>
              )
            }}
          </Query>
        )}
        <Route
          path={`${this.props.match.url}/:folderId`}
          render={props => (
            <FileList
              {...props}
              project={this.props.project}
              uploadModal={this.props.uploadModal}
              uploads={this.props.uploads}
              setProjectIdAndFolderId={this.props.setProjectIdAndFolderId}
              fileFavourites={this.props.fileFavourites}
              folderFavourites={this.props.folderFavourites}
            />
          )}
        />
      </>
    )
  }
}

import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'
import { DateTime } from 'luxon'

import { DELETE_RECORDING } from '../graphql/mutations'
import WithComments from '../hocs/WithComments'

import ResponsiveTable from './ResponsiveTable'
import WithAlertModal from '../hocs/WithAlertModal'

class SessionRecordingTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.getHeaders = this.getHeaders.bind(this)
    this.getRowActions = this.getRowActions.bind(this)
    this.isFavourite = this.isFavourite.bind(this)
    this.getFavouriteType = this.getFavouriteType.bind(this)
  }

  getHeaders = (() => {
    const headers = [
      {
        key: 'name',
        header: 'Recording Title',
        formatter: (data, row) => `${row.name}  ${(row.version ? ` - ${row.version}` : '')}`
      },
      {
        key: 'subtitle',
        header: 'Subtitle',
        formatter: (subtitle) => subtitle || '-'
      },
      {
        key: 'party.name',
        header: 'Artist',
        formatter: (data, row) => (row.party ? row.party.name : '-')
      },
      {
        key: 'recordedOn',
        header: 'Recorded On',
        formatter: data => data ? DateTime.fromSQL(data, { zone: 'utc' }).toISODate() : '-'
      }
    ]

    return () => headers
  })()

  getRowActions = (() => {
    let actions = []

    actions.push({
      itemText: 'View',
      onClick: row => this.props.history.push(`/projects/${row.projectId}/recordings/${row.id}`)
    })


    actions.push({
      itemText: 'Delete',
      className: this.props.canUpdate ? '' : 'item-ghosted',
      onClick: row => {
        this.props.alert('Are you sure you want to permanently delete this recording?', {
          type: 'delete',
          buttonText: 'Yes',
          submitCallback: close => {
            this.props
              .deleteRecording({
                variables: {
                  recordingId: row.id
                },
                update: this.props.onDelete
              }).then(() => {
                this.props.alert('The recording has been successfully deleted.')
                if (this.props.refetch) {
                  this.props.refetch()
                }
              })
              .catch(() => {
                this.props.alert('This recording could not be deleted.')
              })
            close()
          }
        })
      }
    })

    if (this.props.rowActions) {
      actions = [...actions, ...this.props.rowActions]
    }

    return () => actions
  })()

  isFavourite(row) {
    return this.props.favourites.indexOf(parseInt(row.id, 10)) > -1
  }

  getFavouriteType() {
    return 'recording'
  }

  generateLink(row) {
    return `/projects/${row.projectId}/recordings/${row.id}`
  }

  getActions = (() => {
    let actions = []
    if (this.props.refetch) {
      actions.push({
        name: 'restart',
        iconDescription: 'Refresh',
        onClick: () => this.props.refetch()
      })
    }

    if (actions.length === 0) {
      actions = false
    }
    return () => actions
  })();

  render() {
    return (
      <ResponsiveTable
        headers={this.getHeaders()}
        {...this.props}
        actions={this.getActions()}
        linkColumn='name'
        generateLink={this.generateLink}
        isFavourite={this.isFavourite}
        rowActions={this.getRowActions()}
        getFavouriteType={this.getFavouriteType}
        showSkeleton={this.props.loading}
      />
    )
  }
}

export default compose(
  withRouter,
  WithComments,
  WithAlertModal,
  graphql(DELETE_RECORDING, { name: 'deleteRecording' })
)(SessionRecordingTable)

import React, { useState, useEffect } from 'react'
import crossIcon from '../assets/leftMenu/close.svg'
import hamburgerIcon from '../assets/leftMenu/hamburger.svg'


const PublicShareNavBar = ()=> {
  const [mobileView, setMobileView] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const handleResize = ()=> {
      if (window.innerWidth <= 768) {
        setMobileView(true)
      } else {
        setMobileView(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
  }, [open, mobileView])

  return (
    <div className='navbar'>
      {!mobileView && <ul className='navbar-items-wrapper'>
        <a
          href='https://www.vevacollect.com/'
          target='_blank'
          rel='noopener noreferrer' >
          <li className='navbar-item'>About Us</li>
        </a>
        <a
          href='https://www.vevacollect.com/why-veva/features'
          target='_blank'
          rel='noopener noreferrer' >
          <li className='navbar-item'>Products</li>
        </a>
        <a
          href='https://app.vevacollect.com/register'
          target='_blank'
          rel='noopener noreferrer' >
          <li className='navbar-item'>Sign Up</li>
        </a>
        <a
          href=' https://app.vevacollect.com/'
          target='_blank'
          rel='noopener noreferrer' >
          <li className='navbar-item'>Login</li>
        </a>
      </ul>
      }
      {mobileView &&
      <div>
        <button className='toggle-button' onClick={(e) => {
          e.preventDefault()
          setOpen(!open)
        }}>

          <img className={open ? 'nav-toggle-icon-cross' : 'nav-toggle-icon'} src={open ? crossIcon : hamburgerIcon} alt='' />
        </button>
        {open &&
      <nav className='navbar'>
        <ul className='navbar-items-mobile-wrapper'>
          <a
            href='https://www.vevacollect.com/'
            target='_blank'
            rel='noopener noreferrer' >
            <li className='navbar-item'>About Us</li>
          </a>
          <a
            href='https://www.vevacollect.com/why-veva/features'
            target='_blank'
            rel='noopener noreferrer' >
            <li className='navbar-item'>Features</li>
          </a>
          <a
            href='https://app.vevacollect.com/register'
            target='_blank'
            rel='noopener noreferrer' >
            <li className='navbar-item'>Sign Up</li>
          </a>
          <a
            href=' https://app.vevacollect.com/'
            target='_blank'
            rel='noopener noreferrer' >
            <li className='navbar-item'>Login</li>
          </a>
        </ul>
      </nav>
        }
      </div>
      }


    </div>
  )
}
export default PublicShareNavBar

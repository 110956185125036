import React, { useState, useRef } from 'react'
import { compose } from 'react-apollo'
import PublicFileShareForm from '../../components/PublicFileShareForm'
import WithUploads from '../../hocs/WithUploads'
import WithUploadModal from '../../hocs/WithUploadModal'
import GetPlatformImages from '../../hocs/queries/GetPlatformImages'
import WithPublicShare from '../../hocs/WithPublicShare'
import PublicShareNavBar from '../../components/PublicShareNavBar'
import AppBranding from '../../components/AppBranding'
import UploadPlus from '../../assets/images/upload-plus.png'
import { convertBytes } from '../../utils/functions'
import CancelUpload from '../../assets/images/cancel-upload.svg'
import SendPageBackground from '../../assets/images/send-page-background.png'

const PublicFileShare = ({ platformImages }) => {
  const [files, setFiles] = useState([])
  const [entries, setEntries] = useState([])
  const [folders, setFolders] = useState([])
  const [drag, setDrag] = useState(false)
  const [dragEffect, setDragEffect] = useState(false)
  const dragRef = useRef(null)

  const uniqueFiles = (file, key) => [
    ...new Map(file.map(item => [key(item), item])).values()
  ]

  const handleChangeFiles = selected => {
    let list = []
    let entriesList = []
    if (Array.isArray(selected)) {
      selected.forEach(item => {
        list.push(item.file)
        entriesList.push(item.entry)
      })
    } else {
      list = Array.from(selected)
    }
    const data = uniqueFiles([...files, ...list], item => item.name)
    const entriesData = uniqueFiles(
      [...entries, ...entriesList],
      item => item.name
    )
    const AllFiles = [...data]

    const foldersData = []
    AllFiles.forEach(item => {
      if (item.webkitRelativePath === '') {
        const isEntry = entriesData.find(items => items.name === item.name)
        let namesArray = []
        if (isEntry) {
          namesArray = isEntry.fullPath.split('/')
        }
        if (isEntry && namesArray.length > 2) {
          const isExist = foldersData.find(data => data.name === namesArray[1])
          if (!isExist) {
            foldersData.push({ name: namesArray[1], extension: 'folder' })
          }
        } else {
          foldersData.push(item)
        }
      } else {
        const folderNames = item.webkitRelativePath.split('/')
        const isExist = foldersData.find(
          names => names.name === folderNames[0]
        )
        if (!isExist) {
          foldersData.push({ name: folderNames[0], extension: 'folder' })
        }
      }
    })
    setFolders(foldersData)
    setFiles(data)
    setEntries(entriesData)
  }

  const handleDragEnter = e => {
    e.preventDefault()
    setDrag(true)
    if (e.target !== dragRef.current) {
      setDragEffect(true)
    }
  }

  const handleDragLeave = e => {
    e.preventDefault()
    setDrag(false)
    if (e.target === dragRef.current) {
      setDragEffect(false)
    }
  }

  const handleDragOver = e => {
    setDrag(true)

    e.preventDefault()
  }
  const readEntryContentAsync = entry => {
    return new Promise(resolve => {
      let reading = 0
      const contents = []
      const readReaderContent = reader => {
        reading = reading + 1
        reader.readEntries(function (entries) {
          reading = reading - 1
          for (const entry of entries) {
            readEntry(entry)
          }
          if (reading === 0) {
            resolve(contents)
          }
        })
      }
      const readEntry = entry => {
        const { isFile, isDirectory } = entry
        if (isFile) {
          reading = reading + 1
          entry.file(file => {
            reading = reading - 1
            contents.push({ file, entry })
            if (reading === 0) {
              resolve(contents)
            }
          })
        } else if (isDirectory) {
          readReaderContent(entry.createReader())
        }
      }
      readEntry(entry)
    })
  }

  const handleDrop = async e => {
    e.preventDefault()
    let items = e.dataTransfer.items
    const itemsArray = []
    const fileEntryPromises = [...items]
      .filter(item => item.kind === 'file')
      .map(item => item.webkitGetAsEntry())

    for await (const handle of fileEntryPromises) {
      const entryContent = await readEntryContentAsync(handle)
      itemsArray.push(...entryContent)
    }
    handleChangeFiles(itemsArray)
    setDrag(false)
    setDragEffect(false)
  }
  const removeFile = (item, i) => {
    const tempFiles = [...files]
    const tempFolders = [...folders]
    if (item.extension === 'folder') {
      tempFolders.splice(i, 1)
      files.forEach(file => {
        const isEntry = entries.find(entry => entry.name === file.name)
        if (isEntry && isEntry.fullPath.includes(item.name)) {
          const index = tempFiles.findIndex(({ name }) => name === file.name)
          tempFiles.splice(index, 1)
        } else if (file.webkitRelativePath.includes(item.name)) {
          const index = tempFiles.findIndex(({ name }) => name === file.name)
          tempFiles.splice(index, 1)
        }
      })
    } else {
      tempFiles.splice(i, 1)
      tempFolders.splice(i, 1)
    }


    setFiles(tempFiles)
    setFolders(tempFolders)
  }
  const [image] = platformImages.filter(i => i.location === 'login')
  return (
    <div
      className={`publicShare ${drag ? 'drag' : 'leaveDrag'}`}
      id='sendFile'
      onDrop={e => handleDrop(e)}
      onDragOver={e => handleDragOver(e)}
      onDragEnter={e => handleDragEnter(e)}
      onDragLeave={e => handleDragLeave(e)}
    >
      <div className='send-advertisement-container'>
        <img
          className='advertisement-img'
          src={SendPageBackground}
          alt={image.altText}
        />
      </div>
      {dragEffect && (
        <div ref={dragRef} className='drag-effect'>
          <div>
            <h1>Drop files to upload to VEVA Collect</h1>
            <p>Upload files and folders by dropping them in this window</p>
          </div>
        </div>
      )}
      <PublicShareNavBar />
      <div className='publicShareBackground'>
        <div className='public-share-logo-container'>
          <AppBranding />
        </div>
        <div className='publicShareWrapper'>
          <div className='uploadFiles'>
            <label htmlFor='filePicker' className='uploadImgContainer'>
              <img src={UploadPlus} alt='upload' className='upload-plus' />
            </label>
            <input
              id='filePicker'
              type='file'
              multiple='multiple'
              className='file-share'
              onChange={e => handleChangeFiles(e.target.files)}
            />
            <div className='file-text'>
              <h3 className='file-text-title'>Add Your Files</h3>
              <label htmlFor='folderPicker'>
                <h6 className='folder-select'>Or select a folder</h6>
              </label>
              <input
                id='folderPicker'
                type='file'
                webkitdirectory='true'
                mozdirectory='true'
                multiple={true}
                className='file-share'
                onChange={e => handleChangeFiles(e.target.files)}
              />
            </div>
          </div>
          {folders.length ? (
            <div>
              <div className='selectedFiles'>
                {folders.map((item, i) => (
                  <div key={i} className='publicShareContainer'>
                    <div className='selectedFileContainer'>
                      <p className='selectedFile'>
                        {item.name.length > 28
                          ? `${item.name.substring(0, 28)}...`
                          : item.name}
                      </p>
                      <div className='fileOptions'>
                        <div className='fileExtension'>
                          {item.extension
                            ? item.extension.toUpperCase()
                            : item.name
                              .split('.')[item.name.split('.').length - 1].toUpperCase()}
                        </div>
                        <p className='fileItemSize'>
                          {convertBytes({ bytes: item.size })
                            .toString()
                            .toLowerCase()}
                        </p>
                      </div>
                    </div>
                    <div
                      onClick={() => removeFile(item, i)}
                      className='deleteFile'
                    >
                      <img src={CancelUpload} alt='cancel-upload' />
                    </div>
                  </div>
                ))}
              </div>
              <div className='lineBreakFiles' />
            </div>
          ) : null}
          <div className='publicShareForm'>
            <PublicFileShareForm files={files} entries={entries} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(
  WithUploads,
  WithUploadModal,
  WithPublicShare,
  GetPlatformImages
)(PublicFileShare)

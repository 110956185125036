import React from 'react'
import { Query } from 'react-apollo'
import { GET_PARTY } from '../../graphql/queries'

const GetParty = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_PARTY}
          variables={{ id: this.props.match.params.partyId }}
          fetchPolicy={fetchPolicy}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            if (data.getParty === null) {
              return <h1 style={{color: 'white', textAlign: 'center'}}>404</h1>
            }

            return (
              <WrappedComponent {...this.props} party={data.getParty} partyRefetch={refetch} />
            )
          }}
        </Query>
      )
    }
  }
}

export default GetParty

export const GetPartyRefreshed = (WrappedComponent) => {
  return GetParty(WrappedComponent, 'network-only')
}

import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

import { listOfEmails } from '../utils/Validator'

export default Schema(
  {
    emails: Field()
      .string()
      .required({ message: Errors.Required })
      .custom(listOfEmails, {message: "One of these is not an email."}),
    message: Field()
      .string(),
    password: Field()
      .string(),
    expiry: Field()
      .string()
    //   .custom(notInPast, {
    //     message: 'The date cannot be in the past'
    //   })
  },
  { verbose: true, async: true }
)

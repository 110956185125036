import React from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from 'react-apollo'

import { DO_RESET_PASSWORD } from '../graphql/mutations'
import GetPlatformImages from '../hocs/queries/GetPlatformImages'

import AppBranding from '../components/AppBranding'
import ResetPasswordForm from '../components/ResetPasswordForm'

class ResetPassword extends React.Component {
  state = {
    completed: false,
    status: null
  }

  render() {
    const [image] = this.props.platformImages.filter(i => i.location === 'reset-password')

    return (
      <div className='login'>
        <div className='loginBackground'>
          <img src={image.fullPath} alt={image.altText} />
          {image.caption && <div className='loginBackgroundCredit'>{image.caption}</div>}
        </div>
        <div className='loginFormBackground'>
          <div className='loginFormWrapper'>
            <AppBranding />
            {this.state.completed && this.state.status === 'success' && (
              <>
                <p
                  style={{
                    color: 'white'
                  }}
                >
                  Your password has been reset!
                </p>
                <Link to='/login'>Login to Continue</Link>
              </>
            )}
            {this.state.completed && this.state.status === 'error' && (
              <>
                <p
                  style={{
                    color: 'white'
                  }}
                >
                  We’re sorry, an error occurred.
                </p>
                <Link to='/forgot-password'>Please try again.</Link>
              </>
            )}

            {!this.state.completed && (
              <>
                <div className='loginForm'>
                  <Mutation
                    mutation={DO_RESET_PASSWORD}
                  >
                    {(doResetPassword, { loading, error }) => (
                      <ResetPasswordForm
                        onSubmit={state => {
                          import('query-string').then(queryString => {
                            const query = queryString.parse(this.props.location.search)
                            doResetPassword({
                              variables: {
                                email: query.email,
                                token: this.props.match.params.token,
                                ...state
                              }
                            }).then(({ data })=> {
                              let { doResetPassword } = data

                              if (doResetPassword.success) {
                                this.setState({
                                  completed: true,
                                  status: 'success'
                                })
                              } else {
                                this.setState({
                                  completed: true,
                                  status: 'error'
                                })
                              }
                            }).catch(() => {
                              //
                            })
                          })
                        }}
                        loading={loading}
                        errors={error}
                      />
                    )}
                  </Mutation>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default GetPlatformImages(ResetPassword)

import React from 'react'
import { withApollo } from 'react-apollo'
import { Query } from 'react-apollo'

import { GET_COMMENTS, GET_PROJECT } from '../graphql/queries'
import { COMMENTS_SUBSCRIPTION } from '../graphql/subscriptions'
import GetUser from '../hocs/queries/GetUser'

import { compose } from 'react-apollo'


import Comments from '../components/Comments/Comments'
import ApolloClient from '../utils/Apollo'

export const CommentsContext = React.createContext(null)
export const CommentsConsumer = CommentsContext.Consumer
export class CommentsProvider extends React.Component {
  state = {
    commentsVisible: false,
    projectId: '',
    projectName: '',
    projectOwner: '',
    resourceType: '',
    resourceName: '',
    resourceId: '',
    objectType: ''
  }

  constructor(props) {
    super(props)

    this.loadComments = this.loadComments.bind(this)
    this.showComments = this.showComments.bind(this)
    this.hideComments = this.hideComments.bind(this)
  }
  // eslint-disable-next-line max-len
  loadComments(projectId, resourceId, resourceType, name, pName, pOwner) { // eslint-disable-line max-params
    const projectName = (pName ?
      pName : this.props.client.store.cache.data.data[`Project:${projectId}`].name
    )
    const ObjectType = resourceType.charAt(0).toUpperCase() + resourceType.slice(1)
    let resourceName = (name ?
      name : this.props.client.store.cache.data.data[`${ObjectType}:${resourceId}`].name
    )
    const projectOwner = (pOwner ?
      pOwner : this.props.client.store.cache.data.data[`Project:${projectId}`].userId
    )

    this.setState({
      projectId,
      projectName,
      resourceType,
      resourceId,
      resourceName,
      projectOwner,
      objectType: ObjectType,
      commentsVisible: true
    })
  }

  showComments() {
    this.setState({
      ...this.state,
      commentsVisible: true
    })
  }

  hideComments() {
    this.setState({
      ...this.state,
      commentsVisible: false
    })
    ApolloClient.query({
      query: GET_PROJECT,
      variables: {
        id: this.state.projectId
      },
      fetchPolicy: 'network-only'
    })
  }

  render() {
    return (
      <CommentsContext.Provider value={{
        actions: {
          loadComments: this.loadComments
        }
      }}>
        {this.props.children}
        {this.state.commentsVisible &&
          <Query query={GET_COMMENTS}
            variables={this.state}
            fetchPolicy='cache-and-network'>
            {({ subscribeToMore, data, loading, error, refetch }) => (
              <Comments comments={data.getComments || []}
                hideComments={this.hideComments}
                loading={loading}
                error={error}
                projectId={this.state.projectId}
                projectName={this.state.projectName}
                resourceId={this.state.resourceId}
                resourceType={this.state.resourceType}
                resourceName={this.state.resourceName}
                objectType={this.state.objectType}
                refetch={refetch}
                onCreateComment={(cache, { data: { createComment } }) => {
                  const { getComments } = cache.readQuery({
                    query: GET_COMMENTS,
                    variables: this.state
                  })

                  const comments = [ ...getComments, createComment ]
                  cache.writeQuery({
                    query: GET_COMMENTS,
                    variables: this.state,
                    data: {
                      getComments: comments
                    }
                  })
                }}
                subscribeToNewComments={() =>
                  subscribeToMore({
                    document: COMMENTS_SUBSCRIPTION,
                    variables: this.state,
                    updateQuery: (prev, { subscriptionData }) => {
                      if (!subscriptionData.data) {
                        return prev
                      }

                      return {
                        ...prev,
                        getComments: [subscriptionData.data.commentCreated, ...prev.getComments]
                      }
                    }
                  })
                }
                userInfo={this.props.user.id}
                projectOwner={this.state.projectOwner}
              />
            )}
          </Query>
        }
      </CommentsContext.Provider>
    )
  }
}

export default compose(withApollo, GetUser)(CommentsProvider)

import React from 'react'
import { withRouter } from 'react-router'
import { compose } from 'react-apollo'
import { DateTime } from 'luxon'

import WithComments from '../hocs/WithComments'

import ResponsiveTable from './ResponsiveTable'

const headers = [
  {
    key: 'name',
    header: 'Name'
  },
  {
    key: 'type',
    header: 'Type',
    formatter: (type) => type.charAt(0).toUpperCase() + type.slice(1)
  },
  {
    key: 'artist',
    header: 'Artist'
  },
  {
    key: 'updatedAt',
    header: 'Last Updated',
    formatter: (data) => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
  }
]

class RecentResourceTable extends React.PureComponent {
  state = {
    originalRows: [],
    rows: []
  }

  constructor(props) {
    super(props)

    this.getRowActions = this.getRowActions.bind(this)
    this.canFavourite = this.canFavourite.bind(this)
    this.isFavourite = this.isFavourite.bind(this)
    this.getFavouriteType = this.getFavouriteType.bind(this)
    this.generateLink = this.generateLink.bind(this)
  }

  getRowActions = (() => {
    const actions = [
      {
        itemText: 'View',
        onClick: (row) => this.props.history.push(this.generateLink(row))
      }
    ]

    return () => actions
  })()

  isFavourite(row) {
    if (!this.props.favourites[row.type]) {
      return false
    }

    return this.props.favourites[row.type].indexOf(parseInt(row.resourceId, 10)) > -1
  }

  canFavourite(row) {
    if (row.type === 'song') {
      return false
    }

    return true
  }

  getFavouriteType(row) {
    return row.type
  }

  generateLink(row) {
    if (row.type === 'project') {
      return `/projects/${row.resourceId}`
    } else if (row.type === 'session') {
      return `/projects/${row.projectId}/sessions/${row.resourceId}`
    } else if (row.type === 'recording') {
      return `/projects/${row.projectId}/recordings/${row.resourceId}`
    } else if (row.type === 'file') {
      if (row.projectId) {
        if (row.folderId) {
          return `/projects/${row.projectId}/files/${row.folderId}`
        }
        return `/projects/${row.projectId}/files`
      }
      if (row.folderId) {
        return `/files/${row.folderId}`
      }
      return `/files`
    } else if (row.type === 'folder') {
      return `/projects/${row.projectId}/files/${row.resourceId}`
    } else if (row.type === 'song') {
      return `/songs/${row.resourceId}`
    }
    return '/'
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rows === prevState.originalRows) {
      return null
    }

    const rows = nextProps.rows.map(row => {
      if (!row.resource) {
        return false
      }

      let artist = '-'

      if (row.resource) {
        if (row.resourceType !== 'project' && row.resource.project && row.resource.project.artist) {
          artist = row.resource.project.artist.name
        }
      }

      return {
        id: row.id,
        resourceId: row.resourceId,
        projectId: (row.resourceType !== 'project' ? row.resource.projectId : row.resourceId),
        folderId: row.resource.folderId,
        name: row.resource.name,
        artist: artist,
        type: row.resourceType,
        updatedAt: row.updatedAt
      }
    }).filter(Boolean)

    return {
      rows,
      originalRows: nextProps.rows
    }
  }

  render() {
    return (
      <ResponsiveTable headers={headers}
        {...this.props}
        rows={this.state.rows}
        showSearch={this.props.showSearch}
        pagination={this.props.pagination}
        linkColumn='name'
        generateLink={this.generateLink}
        favourite
        favourites={this.props.favourites}
        canFavourite={this.canFavourite}
        isFavourite={this.isFavourite}
        rowActions={false}
        getFavouriteType={this.getFavouriteType}
        emptyText='No Recent'
      />
    )
  }
}


export default compose(
  withRouter,
  WithComments
)(RecentResourceTable)


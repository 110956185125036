import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { compose } from 'react-apollo'
import { DateTime } from 'luxon'

import ShareResponsiveTable from '../ResponsiveTableFilesPlaylistShare'
import WithAlertModal from '../../hocs/WithAlertModal'
import WithShareModal from '../../hocs/WithShareModal'
import ExpireShare from '../../hocs/mutations/ExpireShare'
import ExpireShareLink from '../../hocs/mutations/ExpireShareLink'
import ApolloClient from '../../utils/Apollo'
import { GET_SHARE_STATUS } from '../../graphql/queries/share'
import WithPlaylistShareModal from '../../hocs/WithPlaylistShareModal'

import downloadIcon from '../../assets/images/downloaded-white.svg'
import playlistIcon from '../../assets/images/playlist-icon-white.svg'

function ShareTable({
  // from WithShareModal
  shareModal,
  // from WithAlertModal
  alert,
  expireShare,
  expireShareDirectLink,
  refetch,
  refetchShare,
  shouldRerender,
  playlistShareModal,
  ...props
}) {
  useEffect(() => {
    if (shouldRerender !== 'foo') {
      setTimeout(()=>{
        refetch()
      }, 10000)
    }
  }, [shouldRerender])

  const [headerState, setHeaderState] = useState([])
  const [rowState, setRowState] = useState([])

  useEffect(()=> {
    setRowState(props.rows)
    setHeaderState([])
  }, [shouldRerender])

  useEffect(()=> {
    // makeHeaders()
  }, [rowState])


  const handleExpire = async (uuid, link) => {
    try {
      if (link) {
        await expireShareDirectLink(uuid)
      } else {
        await expireShare(uuid)
      }
      refetch()
    } catch (error) {
      console.error('expireShare', error)
    }
  }

  const handleViewShare = (row) => {
    shareModal.open('update', {
      share: row,
      onSuccess: refetch && refetchShare
    })
  }

  function dateReformat(date) {
    let raw = DateTime.fromSQL(date, { zone: 'utc' }).toISODate()
    let year = raw.slice(0, 4)
    let month = raw.slice(5, 7)
    let day = raw.slice(8, 10)
    return `${month}/${day}/${year}`
  }

  const headers =
    [
      {
        key: 'users',
        header: `Details`,
        formatter: (users, row) => {
          // Compose "N files"
          // eslint-disable-next-line
          let emailsPhrase = ''
          if (users && users.length === 0) {
            emailsPhrase = 'shared via Link'
          } else if (users && users.length === 1) {
            emailsPhrase = `shared to ${users && users[0].email}`
          } else if (users && users.length > 1) {
            emailsPhrase = `shared to ${users && users.length} emails`
          }

          if (row && row.sharePlaylists && row.sharePlaylists.length !== 0) {
            let playlistTitle = ''
            // eslint-disable-next-line
            let playlistPhrase = ''
            let playlistAudio = ''
            if (row && row.sharePlaylists.length !== 0) {
              playlistTitle = row.sharePlaylists[0].playlist.title || ''
              playlistAudio = row.sharePlaylists[0].playlist.files.length
            }
            playlistPhrase = `${playlistAudio} Audio Files in Playlist ${playlistTitle}`
            if (props.filesPage) {
              const fieldValue = row.link !== null ? row.link : (row.users.length > 0 ? row.users[0].email : '') //eslint-disable-line
              return fieldValue

            }
            return playlistPhrase + ` ` + emailsPhrase
          }
          // eslint-disable-next-line
          if (props.filesPage) {
            const fieldValue = row.link !== null ? row.link : (row.users.length > 0 ? row.users[0].email : '') //eslint-disable-line
            return fieldValue
          }
          const filesPhrase = row.files
            ? row.files.length
          + ' file' + (row.files.length === 1 ? '' : 's')
            : ''
          // Display phrase
          return filesPhrase + ` ` + emailsPhrase
        }
      },
      {
        key: 'link',
        header: 'Share Type',
        formatter: (row) => {
          // return 'Thing'
          return row ? 'Link' : 'Email'
        }
      },
      {
        key: 'createdAt',
        header: 'Created On',
        formatter: date => dateReformat(date)
      },
      {
        key: 'expiresAt',
        header: 'Expire',
        // Display expiration date or "Expired" or "Never"
        formatter: (expirationDate) => {
          if (!expirationDate) {
            return 'Never'
          }
          if (new Date(expirationDate) < new Date()) {
            return 'Expired'
          }
          return dateReformat(expirationDate)
        }
      },
      {
        key: 'downloadCount',
        header: 'Downloaded',
        formatter: (downloadCount, row) => {
          if (row.sharePlaylists && row.sharePlaylists.length > 0) {
            return (
              <>
                <img src={playlistIcon} alt= 'playlist' className='table-cell-icon'/>
                {downloadCount}
              </>
            )
          }
          return (
            <>
              <img src={downloadIcon} alt= 'downloads' className='table-cell-icon'/>
              {downloadCount}
            </>
          )
        }
      }
    ]


  useEffect(()=>{
    // setHeaderDetail(headers)
  }, [])

  const actions = [
    {
      name: 'restart',
      iconDescription: 'Refresh',
      onClick: refetch
    }
  ]


  const batchActions = [
    // {
    //   name: 'trash',
    //   iconDescription: 'Delete',
    //   onClick: () => {}
    // }
  ]
  // /////////////
  const rowActions = [
    {
      itemText: 'View/Edit',
      onClick: handleViewShare
    },
    {
      itemText: 'Reshare',
      onClick: async (row) => {
        if (row.sharePlaylists.length) {
          let playlistId = row.sharePlaylists.length > 0 ? row.sharePlaylists[0].playlistId : null
          row.expiry = ''
          playlistShareModal.open('create', {
            share: row,
            onSuccess: refetch
          }, playlistId)
          return
        }
        const dataToSubmit = {
          users: row.users.map(user=> ({ email: user.email })),
          expiry: '',
          password: null,
          message: null,
          shareId: row.id ? row.id : '',
          files: row.files.map(file => ({
            id: file.id,
            type: file.type ? 'file' : 'folder'
          }))
        }
        const { data } = await ApolloClient.query({
          variables: dataToSubmit,
          query: GET_SHARE_STATUS,
          fetchPolicy: 'no-cache'
        })
        const { shareStatus } = data
        if (shareStatus.success) {
          row.expiry = ''
          row.files = row.files.map((file) => ({
            id: file.id,
            type: 'file'
          }))
          shareModal.open('create', {
            share: row,
            onSuccess: refetch
          })
        } else {
          alert(shareStatus.message, { className: 'project-credits' })
        }
      }
    },
    {
      itemText: 'End share',
      onClick: (row) => alert(
        `This share’s expiration will change to today’s date and anyone who tries to access it will be informed this share has expired.`,
        {
          type: 'confirm',
          title: 'End share',
          buttonText: 'End share',
          submitCallback: async (close) => {
            handleExpire(row.id, row.link)
            refetchShare()
            close()
          }
        }
      )
    },
    {
      itemText: 'Go to Playlist',
      onClick: (row) => {
        let playlistId = row.sharePlaylists && row.sharePlaylists.length > 0 ? row.sharePlaylists[0].playlistId : null
        if (playlistId) {
          return props.history.push(`/playlists/${playlistId}`)
        }
      },
      canBeApplied: (row) => row.sharePlaylists && row.sharePlaylists.length > 0
    }
  ]


  return (
    <>
      <ShareResponsiveTable {...{
        ...props,
        headers,
        headerState,
        rowActions,
        actions,
        batchActions,
        refetch,
        showSearch: false,
        linkColumn: 'users',
        generateLink: (row) => () => handleViewShare(row),
        sortColumn: 'createdAt',
        sortDirection: 'DESC'
      }}
      // headers={}
      isFileTable={(row) => row.link !== null ? true : false}
      />
    </>
  )
}

export default compose(
  ExpireShare,
  ExpireShareLink,
  withRouter,
  WithAlertModal,
  WithShareModal,
  WithPlaylistShareModal
)(ShareTable)

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { DateTime } from 'luxon'
import { CREATE_PARTY_ARTIST } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'

// Our Base Components
import PartyForm from '../../components/PartyForm'
import SectionContainer from '../../components/SectionContainer'
import ApolloClient from '../../utils/Apollo'
import WithAlertModal from '../../hocs/WithAlertModal'
import { GET_PARTY_ARTIST } from '../../graphql/queries'

class MyPartyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      myParty: [],
      loading: false,
      error: null,
      renderForm: false
    }
  }
  componentDidMount = ()=>{
    this.setState({
      renderForm: true
    })
    this.partyRefetch().then(()=>{
      this.setState({
        renderForm: false
      })
    })
  }
  partyRefetch = async () =>{
    const { data } = await ApolloClient.query({
      query: GET_PARTY_ARTIST,
      fetchPolicy: 'no-cache'
    })
    const { getMyParty } = data
    this.setState({
      myParty: getMyParty
    })
  }
  partyCreate = async (variables) =>{
    this.setState({
      loading: true
    })
    await ApolloClient.mutate({
      variables,
      mutation: CREATE_PARTY_ARTIST,
      fetchPolicy: 'no-cache'
    })
  }
  render() {
    const { inMyParty } = this.props
    const { myParty, loading, error, renderForm } = this.state
    return (
      <>
        <Helmet>
          <title>My Party</title>
        </Helmet>
        <main>
          <SectionContainer>
            <div>
              {error && `Error! ${error}`}
              { (!error && !renderForm) && <SectionContainer>
                <PartyForm {...myParty}
                  inMyParty={inMyParty}
                  partyRefetch={this.partyRefetch}
                  first={!myParty && this.props.userInfo.firstName}
                  last={!myParty && this.props.userInfo.lastName}
                  data={myParty}
                  onSubmit={(state) => {
                    let info = { ...state }
                    Object.keys(info).forEach((key) => (info[key] === '')
                                       && (info[key] = null))
                    let variables = {
                      ...info,
                      birthDate: info.birthDate ?
                        DateTime.fromFormat(info.birthDate, 'LL/dd/yyyy').toISODate() :
                        null,
                      deathDate: info.deathDate ?
                        DateTime.fromFormat(info.deathDate, 'LL/dd/yyyy').toISODate() :
                        null,
                      isMy: true
                    }

                    if (!variables.birthDate) {
                      delete variables.birthDate
                    }

                    if (!variables.deathDate) {
                      delete variables.deathDate
                    }
                    this.partyCreate(variables).then(()=>{
                      this.partyRefetch().catch(({ message })=>{
                        this.setState({
                          error: message
                        })
                      })
                      this.props.alert('Your party has been saved')
                      this.setState({
                        loading: false
                      })
                    }).catch(({ message })=>{
                      this.setState({
                        error: message
                      })
                    })
                  }}
                  loading={loading}
                />
              </SectionContainer> }
            </div>
          </SectionContainer>
        </main>
      </>
    )
  }
}


export default compose(
  WithAlertModal,
  GetUser,
)(MyPartyForm)

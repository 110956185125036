import React from 'react'
import { Loading, DataTable } from "carbon-components-react";
import { ReactComponent as FolderIcon } from "../assets/images/folder.svg";

import ProjectTile from './ProjectTile'
const {
  TableCell,
  Table,
  TableRow,
  TableBody
} = DataTable;

const ProjectTileList = React.memo(props => {
  if (props.showLoader) {
    return (
      <Loading withOverlay={false}
        className='bx--loading--center' />
    )
  }

  if (!props.projects.length) {
    return (
      <Table>
      <TableBody>
        <TableRow>
          <TableCell className="empty-row">
            <FolderIcon /> <span>No Projects</span>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    )
  }

  return (
    <div className='projectTileContainer'>
      {props.projects.map((project, index) => (
        <ProjectTile
          {...project}
          refetchProjects={props.refetch}
          artist={project.artist}
          favouritePage={props.favouritePage}
          user={props.user}
          index={index}
          key={project.id}
          favourite={props.favourites && props.favourites.indexOf(parseInt(project.id, 10)) > -1}
          generateUrl={props.generateUrl}
          withOptions={props.withOptions}
          onDelete={props.onDelete}
          showFileCount={props.showFileCount || false}
          showArtist={props.showArtist || false}
          disableFavourite={props.disableFavourite}
          showFavouriteIcon={props.showFavouriteIcon}
        />
      ))}
    </div>
  )
})

export default ProjectTileList

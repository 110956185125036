import React from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from 'react-apollo'

import { DO_FORGOT_PASSWORD } from '../graphql/mutations'
import GetPlatformImages from '../hocs/queries/GetPlatformImages'

import AppBranding from '../components/AppBranding'
import ForgotPasswordForm from '../components/ForgotPasswordForm'

class ForgotPassword extends React.Component {
  state = {}

  render() {
    const [image] = this.props.platformImages.filter(i => i.location === 'forgot-password')

    return (
      <div className='login'>
        <div className='loginBackground'>
          <img src={image.fullPath} alt={image.altText} />
          {image.caption && <div className='loginBackgroundCredit'>{image.caption}</div>}
        </div>
        <div className='loginFormBackground'>
          <div className='loginFormWrapper'>
            <AppBranding />
              <>
                <div className='loginForm'>
                  <Link to='/login' className='forgotPasswordLink'>
                    {'<'} Back to Login
                  </Link>
                  <Mutation
                    mutation={DO_FORGOT_PASSWORD}
                    update={() => {
                    }}
                  >
                    {(doForgotPassword, { loading, error, data }) => (
                      <ForgotPasswordForm
                        onSubmit={state => {
                          doForgotPassword({
                            variables: state
                          }).catch(() => {
                            //
                          })
                        }}
                        loading={loading}
                        errors={error}
                        successMessage={
                          data && data.doForgotPassword && data.doForgotPassword.success
                            ? 'Please check your emails to continue resetting your password.'
                            : null
                        }
                        message={data && data.doForgotPassword && data.doForgotPassword.message ?
                          data.doForgotPassword.message : ''}
                      />
                    )}
                  </Mutation>
                </div>
              </>
          </div>
        </div>
      </div>
    )
  }
}

export default GetPlatformImages(ForgotPassword)

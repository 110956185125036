
import React from 'react'
import { Form,
  Button,
  TextInput,
  TextArea,
  RadioButtonGroup,
  RadioButton

} from 'carbon-components-react'

import DatePicker from '../components/DatePicker'

import ApolloClient from '../utils/Apollo'
import { SelectASync, SelectStandard as Select } from '../components/Select'
import CreateVenueModal from '../components/CreateVenueModal'

import { GET_ALL_RECORDINGS, GET_SESSION_TYPES, GET_SONGS, GET_VENUES } from '../graphql/queries'

import Validator from '../utils/Validator'
import SessionValidator from '../validators/Session'
import CreateRecordingModal from './CreateRecordingModal'
import { withRouter } from 'react-router-dom'


const sessionTypeOptions = inputValue =>
  new Promise(resolve => {
    ApolloClient.query({
      query: GET_SESSION_TYPES,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      let sessionTypes = []
      data.getSessionTypes.forEach(type => {
        if (!type.name.toLowerCase().includes(inputValue.toLowerCase())) {
          return
        }
        sessionTypes.push({
          value: type.id,
          label: type.name
        })
      })
      resolve(sessionTypes)
    })
  })

const venueOptions = inputValue =>
  new Promise(resolve => {
    ApolloClient.query({
      query: GET_VENUES,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      let venues = []
      data.getVenues.forEach(venue => {
        if (!venue.name.toLowerCase().includes(inputValue.toLowerCase())) {
          return
        }
        venues.push({
          value: venue.id,
          label: venue.name
        })
      })
      resolve(venues)
    })
  })

const initialState = {
  sessionTypeId: '',
  sessionTypeName: '',
  type: {},
  venueId: '',
  venueName: '',
  venue: {},
  recording: {},
  recordingId: [],
  recordingName: '',
  bitdepth: '',
  selectedBitdepth: false,
  samplerate: '',
  selectedSamplerate: '',
  unionSession: '',
  selectedUnionSession: '',
  analogSession: '',
  selectedAnalogSession: '',
  timecodeType: '',
  selectedTimecodeType: '',
  timecodeFrameRate: '',
  selectedTimecodeFrameRate: '',
  dropFrame: '',
  selectedDropFrame: '',
  startedAt: '',
  initialErrors: {},
  errors: {},
  createVenueModalOpen: false,
  createRecordingModalOpen: false,
  multiValue: [],
  showDiscardModal: false
}

const bitdepths = [12, 16, 20, 24, 32]
const bitdepthOptions = bitdepths.map(b => {
  return { value: b, label: b }
})

const samplerateOptions = [
  {
    value: 32000,
    label: '32 KHz'
  },
  {
    value: 44100,
    label: '44.1 KHz'
  },
  {
    value: 48000,
    label: '48 KHz'
  },
  {
    value: 88200,
    label: '88.2 KHz'
  },
  {
    value: 96000,
    label: '96 KHz'
  },
  {
    value: 176400,
    label: '176.4 KHz'
  },
  {
    value: 192000,
    label: '192 KHz'
  }
]

const timecodeTypeOptions = ['FSK', 'MIDI', 'SMPTE', 'VITC'].map(t => {
  return {
    value: t,
    label: t
  }
})

const timecodeFramerateOptions = ['24', '25', '29.97', '30'].map(t => {
  return {
    value: t,
    label: t
  }
})

  const calendarIcon = 'M16.2 2.31818H15.3V0.5H13.5V2.31818H4.5V0.5H2.7V2.31818H1.8C0.81 2.31818 0 3.13636 0 4.13636V18.6818C0 19.6818 0.81 20.5 1.8 20.5H16.2C17.19 20.5 18 19.6818 18 18.6818V4.13636C18 3.13636 17.19 2.31818 16.2 2.31818ZM16.2 18.6818H1.8V8.68182H16.2V18.6818ZM16.2 6.86364H1.8V4.13636H16.2V6.86364Z' //eslint-disable-line


class NewSessionForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.handleSessionTypeChange = this.handleSessionTypeChange.bind(this)
    this.handleBitdepthChange = this.handleBitdepthChange.bind(this)
    this.handleBitdepthBlur = this.handleBitdepthBlur.bind(this)
    this.handleSampleRateChange = this.handleSampleRateChange.bind(this)
    this.handleSampleRateBlur = this.handleSampleRateBlur.bind(this)
    this.handleUnionSessionChange = this.handleUnionSessionChange.bind(this)
    this.handleUnionSessionBlur = this.handleUnionSessionBlur.bind(this)
    this.handleAnalogSessionChange = this.handleAnalogSessionChange.bind(this)
    this.handleAnalogSessionBlur = this.handleAnalogSessionBlur.bind(this)
    this.handleTimecodeTypeChange = this.handleTimecodeTypeChange.bind(this)
    this.handleTimecodeTypeBlur = this.handleTimecodeTypeBlur.bind(this)
    this.handleTimecodeFrameRateChange = this.handleTimecodeFrameRateChange.bind(this)
    this.handleTimecodeFrameRateBlur = this.handleTimecodeFrameRateBlur.bind(this)
    this.handleDropFrameChange = this.handleDropFrameChange.bind(this)
    this.handleDropFrameBlur = this.handleDropFrameBlur.bind(this)
    this.handleVenueChange = this.handleVenueChange.bind(this)
    this.handleStartedAtChange = this.handleStartedAtChange.bind(this)
    this.handleStartedAtBlur = this.handleStartedAtBlur.bind(this)
    this.handleEndedAtChange = this.handleEndedAtChange.bind(this)
    this.handleEndedAtBlur = this.handleEndedAtBlur.bind(this)
    this.handleRecordingChange = this.handleRecordingChange.bind(this)
    this.handleRecordingBlur = this.handleRecordingBlur.bind(this)

    this.openCreateVenueModal = this.openCreateVenueModal.bind(this)
    this.closeCreateVenueModal = this.closeCreateVenueModal.bind(this)
    this.onVenueCreated = this.onVenueCreated.bind(this)

    this.openCreateRecordingModal = this.openCreateRecordingModal.bind(this)
    this.closeCreateRecordingModal = this.closeCreateRecordingModal.bind(this)
    this.onRecordingCreated = this.onRecordingCreated.bind(this)
    this.songOptions = this.songOptions.bind(this)

    this.validator = SessionValidator

    this.state.multiValue = []
    this.state.sessionTypeId = props.type ? props.type.id : ''
    this.state.sessionTypeName = props.type ? props.type.name : ''
    if (this.state.sessionTypeId) {
      this.state.type = {
        value: this.state.sessionTypeId,
        label: this.state.sessionTypeName
      }
    }

    this.state.venueId = props.venue ? props.venue.id : ''
    this.state.venueName = props.venue ? props.venue.name : ''
    if (this.state.venueId) {
      this.state.venue = {
        value: this.state.venueId,
        label: this.state.venueName
      }
    }

    this.state.recordingId = props.recording ? props.recording.id : []
    this.state.recordingName = props.recording ? props.recording.name : ''
    if (this.state.recordingId) {
      this.state.recording = {
        value: this.state.recordingId,
        label: this.state.recordingName
      }
    }

    if (this.state.recordingId && this.state.recordingName) {
      this.state.multiValue = [{
        value: this.state.recordingId,
        label: this.state.recordingName
      }]
    }

    this.state.startedAt = props.startedAt ? new Date(props.startedAt) : ''
    this.state.endedAt = props.endedAt ? new Date(props.endedAt) : ''
    this.state.name = props.name || ''
    this.state.venueRoom = props.venueRoom || ''

    this.state.bitdepth = props.bitdepth || ''
    let [selectedBitdepth] = bitdepthOptions.filter(option => {
      return option.value === this.state.bitdepth
    })

    this.state.selectedBitdepth = selectedBitdepth

    this.state.samplerate = props.samplerate || ''
    let [selectedSamplerate] = samplerateOptions.filter(option => {
      return option.value === this.state.samplerate
    })

    this.state.selectedSamplerate = selectedSamplerate

    this.state.unionSession = props.unionSession ? 1 : 0
    this.state.selectedUnionSession = {
      label: this.state.unionSession === 1 ? 'Yes' : 'No',
      value: this.state.unionSession
    }

    this.state.analogSession = props.analogSession ? 1 : 0
    this.state.selectedAnalogSession = {
      label: this.state.analogSession === 1 ? 'Yes' : 'No',
      value: this.state.analogSession
    }

    this.state.dropFrame = props.dropFrame ? 1 : 0
    this.state.selectedDropFrame = {
      label: this.state.dropFrame === 1 ? 'Yes' : 'No',
      value: this.state.dropFrame
    }

    this.state.timecodeType = props.timecodeType || ''
    let [selectedTimecodeType] = timecodeTypeOptions.filter(option => {
      return option.value === this.state.timecodeType
    })

    this.state.selectedTimecodeType = selectedTimecodeType

    this.state.timecodeFrameRate = props.timecodeFrameRate || ''
    let [selectedTimecodeFrameRate] = timecodeFramerateOptions.filter(option => {
      return option.value === this.state.timecodeFrameRate
    })

    this.state.selectedTimecodeFrameRate = selectedTimecodeFrameRate
    this.state.description = props.description || ''
    this.state.recordingList = ''
  }

  submit() {
    this.props.onSubmit(this.state)
  }

  songOptions = () => {
    ApolloClient.query({
      query: GET_SONGS,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      let songs = []
      data.getSongs.forEach(song => {
        songs.push({
          value: song.id,
          label: song.title
        })
      })
      this.setState({ recordingList: songs })
    })
  }

  componentDidMount() {
    let projectId = this.props.projectId
    ApolloClient.query({
      query: GET_ALL_RECORDINGS,
      variables: {
        projectId
      },
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      let recordings = []
      if (data.getRecordings.data.length) {
        data.getRecordings.data.forEach(recording => {
          recordings.push({
            value: recording.id,
            label: `${recording.name} ${recording.version ? ` - ${recording.version}` : ''}`
          })
        })
        this.setState({ recordingList: recordings })

        // for edit details form, checks if there are recordings assigned to session
        let sessionRecordings = []
        if (this.props.recordings !== undefined) { // eslint-disable-line no-undefined
          this.props.recordings.forEach(recording => {
            sessionRecordings.push({
              value: recording.id,
              label: `${recording.name} ${recording.version ? ` - ${recording.version}` : ''}`
            })
          })
          this.setState({ multiValue: sessionRecordings })
        }
      } else {
        this.songOptions()
      }
    })
  }


  handleSessionTypeChange(option) {
    this.setState({
      ...this.state,
      sessionTypeId: option.value,
      type: option,
      errors: {
        ...this.state.errors,
        songId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleVenueChange(option) {
    this.setState({
      ...this.state,
      venueId: option.value,
      venue: option,
      errors: {
        ...this.state.errors,
        venueId: undefined // eslint-disable-line no-undefined
      }
    })
  }
  handleRecordingChange(option) {
    this.setState({
      ...this.state,
      multiValue: option,
      recording: option,
      errors: {
        ...this.state.errors,
        recordingId: undefined // eslint-disable-line no-undefined
      }
    })
    let value = Array.from(option, date => date.value)
    this.setState({ recordingId: value })
  }

  handleRecordingBlur() {
    this.handleDirty({
      target: {
        name: 'recordingId',
        value: this.state.recordingId
      }
    })
  }

  handleBitdepthChange(option) {
    this.setState({
      ...this.state,
      bitdepth: option.value,
      selectedBitdepth: option,
      errors: {
        ...this.state.errors,
        bitdepth: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleBitdepthBlur() {
    this.handleDirty({
      target: {
        name: 'bitdepth',
        value: this.state.bitdepth
      }
    })
  }

  handleSampleRateChange(option) {
    this.setState({
      ...this.state,
      samplerate: option.value,
      selectedSamplerate: option,
      errors: {
        ...this.state.errors,
        samplerate: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleSampleRateBlur() {
    this.handleDirty({
      target: {
        name: 'samplerate',
        value: this.state.samplerate
      }
    })
  }

  handleUnionSessionChange(option) {
    this.setState({
      ...this.state,
      unionSession: option.value,
      selectedUnionSession: option,
      errors: {
        ...this.state.errors,
        unionSession: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleUnionSessionBlur() {
    this.handleDirty({
      target: {
        name: 'unionSession',
        value: this.state.unionSession
      }
    })
  }

  handleAnalogSessionChange(option) {
    this.setState({
      ...this.state,
      analogSession: option.value,
      selectedAnalogSession: option,
      errors: {
        ...this.state.errors,
        analogSession: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleAnalogSessionBlur() {
    this.handleDirty({
      target: {
        name: 'analogSession',
        value: this.state.analogSession
      }
    })
  }

  handleTimecodeTypeChange(option) {
    this.setState({
      ...this.state,
      timecodeType: option.value,
      selectedTimecodeType: option,
      errors: {
        ...this.state.errors,
        timecodeType: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleTimecodeTypeBlur() {
    this.handleDirty({
      target: {
        name: 'timecodeType',
        value: this.state.timecodeType
      }
    })
  }

  handleTimecodeFrameRateChange(option) {
    this.setState({
      ...this.state,
      timecodeFrameRate: option.value,
      selectedTimecodeFrameRate: option,
      errors: {
        ...this.state.errors,
        timecodeFrameRate: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleTimecodeFrameRateBlur() {
    this.handleDirty({
      target: {
        name: 'timecodeFrameRate',
        value: this.state.timecodeFrameRate
      }
    })
  }

  handleDropFrameChange(option) {
    this.setState({
      ...this.state,
      dropFrame: option.value,
      selectedDropFrame: option,
      errors: {
        ...this.state.errors,
        dropFrame: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleDropFrameBlur() {
    this.handleDirty({
      target: {
        name: 'dropFrame',
        value: this.state.dropFrame
      }
    })
  }

  handleStartedAtChange(date) {
    this.setState({
      ...this.state,
      startedAt: date,
      errors: {
        ...this.state.errors,
        startedAt: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleStartedAtBlur() {
    this.handleDirty({
      target: {
        name: 'startedAt',
        value: this.state.startedAt
      }
    })
  }

  handleEndedAtChange(date) {
    this.setState({
      ...this.state,
      endedAt: date,
      errors: {
        ...this.state.errors,
        endedAt: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleEndedAtBlur() {
    this.handleDirty({
      target: {
        name: 'endedAt',
        value: this.state.endedAt
      }
    })
  }

  openCreateVenueModal() {
    this.setState({
      ...this.state,
      createVenueModalOpen: true
    })
  }

  closeCreateVenueModal() {
    this.setState({
      ...this.state,
      createVenueModalOpen: false
    })
  }
  openCreateRecordingModal() {
    this.setState({
      ...this.state,
      createRecordingModalOpen: true
    })
  }
  closeCreateRecordingModal() {
    this.setState({
      ...this.state,
      createRecordingModalOpen: false
    })
  }

  onVenueCreated(venue) {
    this.setState({
      ...this.state,
      createVenueModalOpen: false,
      venue: {
        value: venue.id,
        label: venue.name
      },
      venueId: venue.id,
      venueName: venue.name
    })
  }
  onRecordingCreated(recording) {
    this.state.recordingId.push(recording.id)
    this.state.multiValue.push({
      value: recording.id,
      label: recording.name
    })

    this.setState({
      ...this.state,
      createRecordingModalOpen: false,
      recording: {
        value: recording.id,
        label: recording.name
      },
      recordingName: recording.name
    })
  }

  cancelSessionCreate(e) {
    e.preventDefault()
    this.props.history.goBack(-1)
    return
  }

  render() {
    return (
      <div className='form new-session-form'>
        <Form onSubmit={this.handleSubmit}>
          <div className='formRow'>
            <TextInput
              id='name'
              name='name'
              labelText='Session Title *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.name || ''}
              invalid={this.state.errors.name ? true : false}
              invalidText={this.state.errors.name}
            />
          </div>
          <div className='formRow'>
            <SelectASync
              labelText='Type *'
              id='sessionTypeId'
              placeholder='Select from list ...'
              cacheOptions
              defaultOptions
              loadOptions={sessionTypeOptions}
              onChange={this.handleSessionTypeChange}
              defaultValue={this.state.type.value ? this.state.type : null}
              invalid={this.state.errors.sessionTypeId ? true : false}
              invalidText={this.state.errors.sessionTypeId}
            />
          </div>
          <div className='formRow--Four-Column react-datepicker-black with-time'>
            {(this.state.startedAt === '' || this.state.startedAt === null) &&
              <div className='calendar-placeholder-icon-started'>
                <svg width='18' height='21' viewBox='0 0 18 21' fill='none'>
                  <path d={calendarIcon} fill='#D5743E'/>
                </svg>
              </div> }
            <DatePicker
              id='startedAt'
              value={this.state.startedAt || undefined} // eslint-disable-line no-undefined
              onChange={this.handleStartedAtChange}
              onBlur={this.handleStartedAtBlur}
              labelText='Started At *'
              showTimeSelect
              invalid={this.state.errors.startedAt ? true : false}
              invalidText={this.state.errors.startedAt}
            />
            {(this.state.endedAt === '' || this.state.endedAt === null) &&
              <div className='calendar-placeholder-icon-ended'>
                <svg width='18' height='21' viewBox='0 0 18 21' fill='none'>
                  <path d={calendarIcon} fill='#D5743E'/>
                </svg>
              </div> }
            <DatePicker
              id='endedAt'
              value={this.state.endedAt || undefined} // eslint-disable-line no-undefined
              onChange={this.handleEndedAtChange}
              onBlur={this.handleEndedAtBlur}
              labelText='Ended At'
              showTimeSelect
              invalid={this.state.errors.endedAt ? true : false}
              invalidText={this.state.errors.endedAt}
              maxDate={new Date()}
            />
            <div className='self-flex-center'>
              <RadioButtonGroup
                name='bx--radio-button-union'
                onChange={()=> {
                  if (this.state.selectedUnionSession.value === 0) {
                    this.handleUnionSessionChange({ label: 'yes', value: 1 })
                  } else {
                    this.handleUnionSessionChange({ label: 'no', value: 0 })
                  }
                }}
                valueSelected={this.state.selectedUnionSession.value}
              >
                <RadioButton
                  onClick={()=> {
                    if (this.state.selectedUnionSession.value === 1) {
                      this.handleUnionSessionChange({ label: 'no', value: 0 })
                    } else {
                      return
                    }
                  }}
                  id='unionSession'
                  labelText='Union Session'
                  className='radio-session'
                  value={1}
                />
              </RadioButtonGroup>
            </div >
            <div className='self-flex-center'>
              <RadioButtonGroup
                name='bx--radio-button-analog'
                onChange={()=> {
                  if (this.state.selectedAnalogSession.value === 0) {
                    this.handleAnalogSessionChange({ label: 'yes', value: 1 })
                  } else {
                    this.handleAnalogSessionChange({ label: 'no', value: 0 })
                  }
                }}
                valueSelected={this.state.selectedAnalogSession.value}

              >
                <RadioButton
                  onClick={()=> {
                    if (this.state.selectedAnalogSession.value === 1) {
                      this.handleAnalogSessionChange({ label: 'no', value: 0 })
                    } else {
                      return
                    }
                  }}
                  id='analogSession'
                  labelText='Analog Session'
                  className='radio-session'
                  value={1}

                />
              </RadioButtonGroup>

            </div>
          </div>
          <div className='formRow'>
            <SelectASync
              key={this.state.venue.value}
              labelText='Venue *'
              btnText='Create'
              btnOnClick={this.openCreateVenueModal}
              id='venueId'
              placeholder='Select from list ...'
              cacheOptions={false}
              defaultOptions
              loadOptions={venueOptions}
              onChange={this.handleVenueChange}
              defaultValue={this.state.venue.value ? this.state.venue : null}
              invalid={this.state.errors.venueId ? true : false}
              invalidText={this.state.errors.venueId}
            />
          </div>
          <div className='formRow'>
            <TextInput
              id='venueRoom'
              name='venueRoom'
              labelText='Venue Room'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.venueRoom || ''}
              invalid={this.state.errors.venueRoom ? true : false}
              invalidText={this.state.errors.venueRoom}
            />
          </div>
          <div className='formRow'>
            <Select
              id='bitdepth'
              name='bitdepth'
              labelText='Bit Depth'
              placeholder='Select from list ...'
              value={this.state.selectedBitdepth}
              onChange={this.handleBitdepthChange}
              onBlur={this.handleBitdepthBlur}
              invalid={this.state.errors.bitdepth ? true : false}
              invalidText={this.state.errors.bitdepth}
              options={bitdepthOptions}
            />
          </div>
          <div className='formRow'>
            <Select
              id='samplerate'
              name='samplerate'
              labelText='Sample Rate'
              placeholder='Select from list ...'
              value={this.state.selectedSamplerate}
              onChange={this.handleSampleRateChange}
              onBlur={this.handleSampleRateDirty}
              invalid={this.state.errors.samplerate ? true : false}
              invalidText={this.state.errors.samplerate}
              options={samplerateOptions}
            />
          </div>
          <div className='formRow'>
            <Select
              id='timecodeType'
              name='timecodeType'
              labelText='Timecode Type'
              placeholder='Select from list ...'
              value={this.state.selectedTimecodeType}
              onChange={this.handleTimecodeTypeChange}
              onBlur={this.handleTimecodeTypeDirty}
              invalid={this.state.errors.timecodeType ? true : false}
              invalidText={this.state.errors.timecodeType}
              options={timecodeTypeOptions}
            />
          </div>
          <div className='formRow--Two-Column'>
            <Select
              id='timecodeFrameRate'
              name='timecodeFrameRate'
              labelText='Timecode Frame Rate'
              placeholder='Select from list ...'
              value={this.state.selectedTimecodeFrameRate}
              onChange={this.handleTimecodeFrameRateChange}
              onBlur={this.handleTimecodeFrameRateDirty}
              invalid={this.state.errors.timecodeFrameRate ? true : false}
              invalidText={this.state.errors.timecodeFrameRate}
              options={timecodeFramerateOptions}
            />
            <div className='radio-button-inline'>
              <div className='bx--form-item'>
                <RadioButtonGroup
                  name='bx--radio-button-dropframe'
                  onChange={()=> {
                    if (this.state.selectedDropFrame.value === 0) {
                      this.handleDropFrameChange({ label: 'yes', value: 1 })
                    } else {
                      this.handleDropFrameChange({ label: 'no', value: 0 })
                    }
                  }}
                  valueSelected={this.state.selectedDropFrame.value}
                >
                  <RadioButton
                    onClick={()=> {
                      if (this.state.selectedDropFrame.value === 1) {
                        this.handleDropFrameChange({ label: 'no', value: 0 })
                      } else {
                        return
                      }
                    }}
                    id='dropFrame'
                    labelText='Drop Frame'
                    className='radio-session'
                    value={1}
                  />
                </RadioButtonGroup>
              </div>
            </div>
          </div>

          <div className='formRow'>
            <TextArea
              id='description'
              name='description'
              labelText='Description'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.description || ''}
              invalid={this.state.errors.description ? true : false}
              invalidText={this.state.errors.description || ''}
            />
          </div>
          <div className='formRow btns-container'>
            <Button
              onClick={(e) => {
                e.preventDefault()
                this.cancelSessionCreate(e)
              }}
              type='button'
              kind='secondary'
            >
              Cancel
            </Button>
            <Button type='submit'>{this.props.loading ? 'Saving...'
              : (this.props.name && 'Save') || 'Create'}</Button>

          </div>
        </Form>
        <CreateVenueModal
          open={this.state.createVenueModalOpen}
          onRequestClose={this.closeCreateVenueModal}
          onVenueCreated={this.onVenueCreated}
        />

        <CreateRecordingModal
          showAll={true}
          projectId={ this.props.projectId }
          project={this.props.project}
          open={this.state.createRecordingModalOpen}
          onRequestClose={this.closeCreateRecordingModal}
          onRecordingCreated={this.onRecordingCreated}
        />

      </div>
    )
  }
}

export default withRouter(NewSessionForm)

import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    line1: Field()
      .string()
      .required({ message: Errors.Required }),
    city: Field()
      .string()
      .required({ message: Errors.Required }),
    district: Field()
      .string()
      .required({ message: Errors.Required }),
      postalCode: Field()
      .string()
      .required({ message: Errors.Required }),
    countryId: Field()
      .required({ message: Errors.Required })
  },
  { verbose: true, async: true }
)

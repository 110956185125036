import React from 'react'
import { Link } from 'react-router-dom'
import { OverflowMenu, OverflowMenuItem, Button } from 'carbon-components-react'
import add from '../assets/images/addActive.svg'

const SectionContainer = React.memo(props => {
  const { sectionClass, withoutBtnClasses } = props;
  const buttonType = props.btnText === 'Add'
  return (
    <section className='section'>
      {props.title &&
      <header className={`sectionHeader ${sectionClass || ''}`} id={props.id}>
        <div className={`${props.headerWrapper} sectionHeaderWrapper`}>
          <h2>{props.title}</h2>
          {props.btnText && props.btnOnClick && buttonType ?
            <button type='button' className='sectionHeaderButtonAdd'
              onClick={props.btnOnClick}>
              <img src={add} alt='add'/>
            </button>
            :
            props.btnText && props.btnOnClick && props.title !== 'Affiliations' &&
            <Button className={withoutBtnClasses ? '' : ` ${props.additionally} sectionHeaderButton`}
              onClick={props.btnOnClick}>
              {props.btnText}
            </Button>
          }

          {(props.buttons || []).map(button => (
            <button onClick={button.onClick}
              className='sectionHeaderButton'>
              {button.text}
            </button>
          ))}
        </div>
        <div className='sectionHeaderActions'>
          {props.headerText &&
            <p className='sectionHeaderText'>{props.headerText}</p>
          }
          {props.linkUrl && props.linkText &&
            <Link to={props.linkUrl} className='sectionHeaderLink'>{props.linkText}</Link>
          }
          {props.linkOnClick && props.linkText &&
            <button onClick={props.linkOnClick}
              className='sectionHeaderLink'>
              {props.linkText}
            </button>
          }
          {props.menuItems &&
            <OverflowMenu flipped>
              {props.menuItems.map((item, i) =>
                <OverflowMenuItem className={item.className} key={item.key || i} {...item} />
              )}
            </OverflowMenu>
          }
        </div>
      </header>
      }
      {props.children}
    </section>
  )
})

export default SectionContainer

import React from 'react'
import { compose, Mutation } from 'react-apollo'

import { GET_PARTY, GET_PARTY_ARTIST } from '../../../graphql/queries'
import { CREATE_PARTY_ADDRESS } from '../../../graphql/mutations'

import GetUser from '../../../hocs/queries/GetUser'
import PartyAddressForm from '../../../components/PartyAddressForm'
import SectionContainer from '../../../components/SectionContainer'

class PartyAddressCreate extends React.Component {
  render() {
    return (
      <>
          <div className='mainContainer'>
            <SectionContainer>
              <Mutation
                mutation={CREATE_PARTY_ADDRESS}
                update={(cache, { data: { createPartyAddress } }) => {
                  const query = this.props.party.is_my ? GET_PARTY_ARTIST : GET_PARTY
                  const { getParty } = cache.readQuery({
                    query: query,
                    variables: {
                      id: this.props.party.id
                    }
                  })
                  if (getParty) {
                    const party = { ...getParty }
                    party.addresses = [...party.addresses, createPartyAddress]
                    cache.writeQuery({
                      query: query,
                      variables: {
                        id: this.props.party.id
                      },
                      data: {
                        getParty: party
                      }
                    })
                  }
                }}>
                {(createPartyAddress, { loading, error }) => (
                  <PartyAddressForm
                    close={this.props.closeModal}
                    onSubmit={(state) => {
                      const {
                        closeModal,
                        partyRefetch,
                        onSubmitAddress
                      } = this.props
                      if (!this.props.party.id) {
                        onSubmitAddress(state)

                        closeModal()
                        return
                      }
                      closeModal()
                      let data = { ...state }
                      data.partyId = this.props.party.id
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      createPartyAddress({
                        variables: data
                      }).then(()=>{
                        partyRefetch()
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error} 
                    />
                )}
              </Mutation>
            </SectionContainer>
          </div>

      </>
    )
  }
}

export default compose(
  GetUser,
)(PartyAddressCreate)

import React from 'react'
import { UploadsConsumer } from '../contexts/PublicShareUploads'

const WithPublicShare = (WrappedComponent) => {
  return (props) => (
    <UploadsConsumer>
      {context => (
        <WrappedComponent {...props} uploads={context} />
      )}
    </UploadsConsumer>
  )
}

export default WithPublicShare

import gql from 'graphql-tag'

export const GET_SHARES = gql`
  query GetShares {
    getShares {
      id
      link
      users {
        id
        email
      }
      files {
        id
        file {
          id
          type
          name
          prettySize
          folder {
            id
          }
        }
      }
      sharePlaylists {
        playlist {
            title
            files {
                _id
            }
        }
        playlistId
    }
      expiresAt
      createdAt
      updatedAt
      downloadCount
      isPasswordRequired
    }
  }
`
export const GET_SHARE = gql`
  query GetShare(
    $id: String!
  ) {
    getShare(
      id: $id
    ) {
      id
      user {
        id
        name
      }
      users {
        id
        email
      }
      files {
        id
        file {
          id
          folder {
            id
          }
        }
      }
      downloadCount
    }
  }
`
export const GET_SHARES_BY_FILE = gql`
  query getSharesByFile(
    $id: ID!,
    $type: String!
  ) {
      getSharesByFile(input: {
          id: $id,
          type: $type
      })
      {
          id
          user {
              id
          }
          __typename
      }
  }
`
export const VALIDATE_SHARE = gql`
query validateShare(
  $uuid: String!
  $encryptedEmail: String!
  $password: String
) {
  validateShare(
    input: {
      uuid: $uuid
      encryptedEmail: $encryptedEmail
      password: $password
    }
  ) {
    isPasswordRequired
    success
    errors {
      isShareInvalid
      isExpired
      isPasswordInvalid
      isUserInvalid
    }
    url
    files {
        name
        type
        size
        url
        id
    }
    __typename
  }
}
`
export const GET_SHARE_STATUS = gql`
query shareStatus(
  $files: [ShareFileInput!]!,
  $users: [ShareUserInput!]!,
  $expiry: String,
  $password: String,
  $message: String,
  $shareId: String
) {
  shareStatus(input: {
    files: $files,
    users: $users,
    expiry: $expiry,
    password: $password,
    message: $message,
    shareId: $shareId
  })
  {
    success
    message
    url
    __typename
  }
}
`
// Start - Combined API of GET SHARES AND DIRECT LINKS

export const GET_SHARES_AND_DIRECT_LINKS = gql`
  query GetSharesAndDirectLinks {
    getShares {
      ...SharesFragment
    }
    getShareDirectLinks {
      ...DirectLinksFragment
    }
  }
  fragment SharesFragment on Share {
    id
    link
    users {
      id
      email
    }
    files {
      id
      file {
        id
        type
        name
        prettySize
        folder {
          id
        }
      }
    }
    sharePlaylists {
      playlist {
          title
          files {
              _id
          }
      }
      playlistId
    }
    expiresAt
    createdAt
    updatedAt
    downloadCount
    isPasswordRequired
  }
  fragment DirectLinksFragment on ShareDirectLink {
    id
    link
    files {
      id
      file {
        id
        type
        name
        prettySize
        folder {
          id
        }
      }
    }
    expiresAt
    createdAt
    updatedAt
    downloadCount
    isPasswordRequired
  }
`


//  End - Combined API of GET SHARES AND DIRECT LINKS

import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet-async'
import { GetGradientFromId } from '../utils/constants'
import { Tooltip } from 'carbon-components-react'

import playButton from '../assets/images/playPublic.svg'
import pauseButton from '../assets/images/pausePublic.svg'


const PublicPlaylistHero = ({
  totalRecordings,
  totalDuration,
  userName,
  profileImg,
  userId,
  files,
  title,
  filePlayingIdHandler,
  playing,
  fileSelectedHandler,
  showPlayer,
  fileSelectedId,
  filePlayingId
}) => {
  const [titleLength, setTitleLength] = useState(45)
  const initials = title ? title.toUpperCase().replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g).join('').substr(0, 4) : ''
  useEffect(() => {
    window.addEventListener('resize', ()=>{
      if (window.matchMedia('(max-width: 425px)').matches) {
        setTitleLength(15)
      } else {
        setTitleLength(45)
      }
    })
  }, [])

  const playPausePlaylist = () => {
    if (filePlayingId !== null) {
      filePlayingIdHandler({ id: null })
    } else
    if (files.length > 0 && fileSelectedId === null) {
      showPlayer({ curfile: files })
      filePlayingIdHandler({ id: files[0].id })
      fileSelectedHandler({ id: files[0].id })
    } else if (files.length > 0 && fileSelectedId !== null) {
      showPlayer({ curfile: files })
      filePlayingIdHandler({ id: fileSelectedId })
      fileSelectedHandler({ id: fileSelectedId })
    }
  }

  return (
    <div className='publicPlaylistHero'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='playlistHeroContent'>
        <div
          className='playlistHeroThumbnail'
          style={
            profileImg
              ? {}
              : {
                background: GetGradientFromId(userId)
              }
          }
        >
          {!profileImg && <div className='playlistCover'><span>{initials}</span></div>}
          {profileImg && <img src={`${process.env.REACT_APP_IMAGE_URL}playlists/${profileImg}`} alt='Public Playlist' />}
        </div>
        <div className='playlistHeroBody'>
          <header>
            <h3>Public Playlist</h3>
            {title.length > titleLength ?
              <Tooltip
                className='playlist-tooltip'
                direction='bottom'
                tabIndex={0}
                showIcon={false}

                triggerText={ <p className='cs-trigger-text'>{title}</p>}
              >
                <p>{title}</p>


              </Tooltip>
              : <h1>{title}</h1>}
          </header>
          <div className='playlistHeroLinks'>

            <div onClick={playPausePlaylist} className='public-play-icon'>
              <img src={playing ? pauseButton : playButton} alt='play' />
            </div>
            <div className='playlistHeroItem'>
              <img
                src={require('../assets/images/profile-img.svg')}
                className='hero-icon'
                alt=''
              />
              <span>{userName}</span>
            </div>
            <div className='playlistHeroItem'>
              {totalRecordings} Recordings
            </div>
            <div className='playlistHeroItem'>
              <span>Total Time: {totalDuration}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicPlaylistHero

import React from 'react'
import Helmet from 'react-helmet-async'
import { DateTime } from 'luxon'
import { compose } from 'react-apollo'
// import nl2br from 'react-nl2br'

import GetUser from '../../hocs/queries/GetUser'
import GetParty from '../../hocs/queries/GetParty'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import InfoGrid from '../../components/InfoGrid'
import PartyAffiliationTable from '../../components/PartyAffiliationTable'
import PartyAddressTable from '../../components/PartyAddressTable'
import PartyContactTable from '../../components/PartyContactTable'
import SectionContainer from '../../components/SectionContainer'
import {
  aliasAffiliationsName,
  aliasCountryName,
  windowLocationNewWindow } from '../../utils/functions'
import CreatePagesHeader from '../../components/CreatePagesHeader'


class PartyDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      affiliations: []
    }
  }
  componentDidMount() {
    if (this.props.party) {
      this.handleGetPublishers()
    }
  }

  handleGetPublishers = () => {
    const getPublishers = this.props.party.publishers
    const { affiliations } = this.props.party
    const affiliationsArray = [...getPublishers, ...affiliations]
    this.setState({
      affiliations: affiliationsArray
    })
  }

  getFields(type, party) {
    let fields = []
    // infoGrid person fields
    if (type === 'person') {
      fields.push({
        title: 'Alias',
        content: party.alias || party.title || '-'
      })
      fields.push({
        title: 'First Name',
        content: party.firstName || '-'
      })
      fields.push({
        title: 'Middle Name',
        content: party.middleName || '-'
      })
      fields.push({
        title: 'Last Name',
        content: party.lastName || '-'
      })
      fields.push({
        title: 'Suffix',
        content: party.suffix || '-'
      })
      fields.push({
        title: 'ISNI',
        content: party.isni || '-'
      })
      fields.push({
        title: 'Birth Date',
        content: party.birthDate ?
          DateTime.fromSQL(party.birthDate).toISODate() : '-'
      })
      fields.push({
        title: 'Death Date',
        content: party.deathDate ?
          DateTime.fromSQL(party.deathDate).toISODate() : '-'
      })      
      fields.push({
        title: 'IPI/CAE',
        content: party.ipiCae || '-'
      })
      fields.push({
        title: '',
        content: 
          <button
            className='bx--btn bx--btn--secondary'
            onClick={()=> windowLocationNewWindow('https://registration.quansic.com/vevasound')}
          >
            Get an ISNI
          </button>
      })
      fields.push({
        title: 'Comments',
        content: party.comments || ''
      })
      return fields
    }
    // infoGrid organization fields
    if (type !== 'person') {
      fields.push({
        title: 'Title',
        content: party.name || '-'
      })
      fields.push({
        title: 'IPI/CAE',
        content: party.ipiCae || '-'
      })
      fields.push({
        title: 'ISNI',
        content: party.isni || '-'
      })
      fields.push({
        content:
          <button
            className='bx--btn bx--btn--primary'
            onClick={()=> windowLocationNewWindow('https://registration.quansic.com/vevasound')}
          >
            Get an ISNI
          </button>
      })
      fields.push({
        title: 'Comments',
        content: party.comments || '-'
      })
      return fields
    }
    return fields
  }

  render() {
    const {
      user,
      party,
      partyRefetch,
      history
    } = this.props
    const canUpdate = user.id === party.userId


    return (
      <>
        <Helmet>
          <title>{party.name} | Party</title>
        </Helmet>
        <main className='main parties-detail'>
          <div className='party-header'>
            <CreatePagesHeader
              title={this.props.party.name}
              subtitle='Party Details'
              btnText={canUpdate ? 'Edit' : false}
              btnOnClick={() =>
                history.push(
                  `/parties/${this.props.party.id}/edit`
                )}
            >
              <Breadcrumb items={[
                {
                  href: `/parties`,
                  name: 'Parties'
                },
                {
                  href: `/parties/${party.id}`,
                  name: party.name
                }
              ]} />
            </CreatePagesHeader>
          </div>
          <div className='sections-container info-grid'>
            {this.props.party.type === 'person' &&
            <div className='info-grid-wrapper party-person'>
              <InfoGrid items={this.getFields(party.type, party)} fullWidth />
            </div>
            }
            {this.props.party.type !== 'person' &&
            <div className='info-grid-wrapper party-organization'>
              <InfoGrid items={this.getFields(party.type, party)} fullWidth />
            </div>
            }
          </div>
          {this.props.party.type === 'person' &&
            <>
              <CreatePagesHeader 
                subtitle='Affiliations'
              />
                <div className='sections-container party-affiliation-section'>
                  <SectionContainer>
                    <PartyAffiliationTable
                      nameCellClass="parties-affiliations "
                      rows={this.state.affiliations.map(aliasAffiliationsName) || []}
                      partyId={party.id}
                      showSearch={false}
                      rowActions={canUpdate}
                      onDelete={partyRefetch} />
                  </SectionContainer>
                </div>
            </>
          }
          <div className='hero-contacts'>
            <CreatePagesHeader
              subtitle='Contacts'
            />
          </div>
          <div className='sections-container party-contacts-section'>
            <SectionContainer >
              <PartyContactTable rows={party.contacts || []}
                nameCellClass="parties-contact"
                partyId={party.id}
                showSearch={false}
                rowActions={false}
                onDelete={partyRefetch} />
            </SectionContainer>
          </div>
          <div className='hero-contacts'>
            <CreatePagesHeader
              subtitle='Addresses'/>
          </div>
          <div className='sections-container party-address-section'>
            <SectionContainer>
              <PartyAddressTable rows={party.addresses.map(aliasCountryName) || []}
                nameCellClass="parties-address"
                partyId={party.id}
                rowActions={false}
                firstCellColor={true}
                showSearch={false} />
            </SectionContainer>
          </div>

        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetParty
)(PartyDetail)

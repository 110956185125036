import React from 'react'
import { Form, Button } from 'carbon-components-react'
import DownloadSharedFilesLogo from '../assets/images/download-shared-files-logo.svg'
import { Link } from 'react-router-dom'

export default class DownloadShareExpired extends React.PureComponent {

  render() {
    return (
      <Form>
        <div className='formRow shared-download-logo-div'>
          <img className='shared-download-logo' src={DownloadSharedFilesLogo} alt='Download Shared files logo' />
        </div>
        <div className='shared-download-header'>Download Complete!</div>
        <div className='shared-download-content'>Your files have finished downloading.</div>
        <div className='formRow send-files'>
          <Link to={'/send'}>
            <Button type='submit' className='loginSubmit' onClick={this.props.handleClick}>
              { this.props.loading ? 'Sending...' : 'Send Files for Free' }
            </Button>
          </Link>
        </div>
      </Form>
    )
  }
}

import { compose } from 'react-apollo'
import React, { useEffect, useState } from 'react'

import ApolloClient from '../../../utils/Apollo'
import { GET_ALL_RECORDINGS, GET_SESSION } from '../../../graphql/queries'

import GetUser from '../../../hocs/queries/GetUser'
import GetProject from '../../../hocs/queries/GetProject'
import GetSession from '../../../hocs/queries/GetSession'
import CreateSessionRecording from '../../../hocs/mutations/CreateSessionRecording'
import CreateMultipleSessionRecordings from '../../../hocs/mutations/CreateMultipleSessionRecordings'
import WithAlertModal from '../../../hocs/WithAlertModal'

import { Button } from 'carbon-components-react'
import Hero from '../../../components/Hero'
import Breadcrumb from '../../../components/Breadcrumb'
import CreatePagesHeader from '../../../components/CreatePagesHeader'
import CreateRecordingModal from '../../../components/CreateRecordingModal'

import { MapError } from '../../../utils/FilterError'

import { SelectStandard } from '../../../components/Select'

const getRecordingOptions = projectId =>
  new Promise(resolve => {
    ApolloClient.query({
      query: GET_ALL_RECORDINGS,
      variables: {
        projectId
      },
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      let recordings = []
      data.getRecordings.data.forEach(({ id, version, name }) => {
        const versionTitle = version ? ` - ${version}` : ''
        recordings.push({
          value: id,
          label: `${name} ${versionTitle} `
        })
      })
      resolve(recordings)
    })
  })

const SessionToRecording = props => {
  const {
    project,
    session,
    history,
    assignMultipleRecordingSession
  } = props

  const [error, setError] = useState('')
  const [values, setValues] = useState([])
  const [options, setOptions] = useState()
  const [openCreate, setOpenCreate] = useState(false)

  const pageTitle = `Assign Recordings to ${session.name}`

  const setRecordingOptions = async () => {
    const res = await getRecordingOptions(project.id)
    setOptions(res)
  }

  useEffect(() => {
    setRecordingOptions()
  }, [])

  const onChangeHandler = event => {
    if (error.length) {
      setError('')
    }
    setValues(event)
  }

  const onSaveHandler = () => {
    const payload = values.map(data => data.value)
    if (!payload.length) {
      setError('This field is required!')
      return
    }

    assignMultipleRecordingSession(session.id, payload)
      .then(() => {
        ApolloClient.query({
          query: GET_SESSION,
          variables: {
            id: session.id
          },
          fetchPolicy: 'network-only'
        })
        props.alert('Session assigned to recordings successfully', {
          type: 'success',
          submitCallback: () => {
            history.push(`/projects/${project.id}/sessions/${session.id}`)
          }
        })
      })
      .catch(({ message }) => {
        setError(MapError(message))
      })
  }

  const onCancelHandler = () => {
    if (!values.length) {
      history.push(`/projects/${project.id}/sessions/${session.id}`)
    } else {
      props.alert('Are you sure you want to discard changes?', {
        title: 'Discard Changes?',
        type: 'confirm',
        submitCallback: () => {
          history.push(`/projects/${project.id}/sessions/${session.id}`)
        }
      })
    }
  }

  const onCreateHandler = () => {
    setOpenCreate(!openCreate)
  }

  const onCreated = async recording => {
    setOpenCreate(false)
    await props.createSessionRecording(session.id, recording.id)
    props.refetch()
    setRecordingOptions()
  }
  const BreadcrumbLink = (
    <Breadcrumb
      items={[
        {
          href: '/projects',
          name: 'Projects'
        },
        {
          href: `/projects/${project.id}`,
          name: project.name
        },
        {
          href: `/projects/${project.id}/sessions`,
          name: 'Project Sessions'
        },
        {
          href: `/projects/${project.id}/sessions/${session.id}`,
          name: session.name
        },
        {
          href: `/projects/${project.id}/sessions/${session.id}/sessions-to-recording`,
          name: 'Assign Recordings'
        }
      ]}
    />
  )

  return (
    <main className='main assign-recording-session'>
      <Hero subtitle={pageTitle}>{BreadcrumbLink}</Hero>
      <CreatePagesHeader subtitle='Assign Recordings' />
      <div className='session-to-recording'>
        <SelectStandard
          isMulti
          id='sessionToRecording'
          name='session-to-recording'
          placeholder='Select multiple from list...'
          value={values}
          options={options}
          onChange={onChangeHandler}
          labelText='Assign Recording(s) to Session *'
          btnText='Create'
          btnOnClick={onCreateHandler}
          cacheOptions
          defaultOptions
          invalid={error}
          invalidText={error}
        />
        <div className='actions'>
          <Button onClick={onCancelHandler} type='button' kind='secondary'>
            Cancel
          </Button>
          <Button onClick={onSaveHandler} type='button'>
            Save
          </Button>
        </div>

        <CreateRecordingModal
          showAll={true}
          projectId={project.id}
          project={project}
          open={openCreate}
          onRequestClose={() => setOpenCreate(false)}
          onRecordingCreated={onCreated}
        />
      </div>
    </main>
  )
}

export default compose(
  GetUser,
  GetProject,
  GetSession,
  WithAlertModal,
  CreateSessionRecording,
  CreateMultipleSessionRecordings
)(SessionToRecording)

import React from 'react'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'
import { GET_USER_ROOT_FILES, GET_PROJECT_FOLDER } from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import Breadcrumb from '../components/Breadcrumb'
import FileTable from '../components/FileTable'
import SectionContainer from '../components/SectionContainer'

import CreatePagesHeader from '../components/CreatePagesHeader'
import { WithMoveFilesFrom } from '../hocs/MoveFiles'
import folderActive from '../assets/images/folderActive.svg'
import uploadIcon from '../assets/upload-arrow.svg'
import { Loading } from 'carbon-components-react'
import Hero from '../components/Hero'

class MyFiles extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
     getSearchTerm=(value)=>{
       this.setState({
         searchTerm: value
       })
     }
    onClickUpload = ({ refetch })=>{
      this.props.uploadModal.open(
        '/',
        '',
        null,
        this.props.match.params.folderId,
        '',
        refetch
      )
    }

   onMoveFilesFrom =({ refetch })=>{
     const breadCrumb = [{
       href: `#`,
       name: 'Files'
     }]
     this.props.moveFilesFrom.open(null,
       null,
       breadCrumb,
       refetch
     )
   }

    dropDownMenuList = ({ refetch })=>{
      return (
        [
          { name: 'Upload', icon: uploadIcon, click: ()=>this.onClickUpload({ refetch }) },
          { name: 'Files', icon: folderActive, click: ()=> this.onMoveFilesFrom({ refetch }) }
        ]
      )
    }
    getBreadcrumb(file, folders, currentFolder = false) {
      let breadcrumb = [
        {
          href: `/files`,
          name: 'Files'
        }
      ]

      let path = []
      if (currentFolder) {
        let hrefPath = `/files`

        if (currentFolder.path.length > 0) {
          currentFolder.path.forEach(item => {
            hrefPath = `${hrefPath}/${item.id}`
            breadcrumb.push({
              href: hrefPath,
              name: item.name
            })

            path.push(item.name)
          })
        }

        hrefPath = `${hrefPath}/${currentFolder.id}`
        breadcrumb.push({
          href: hrefPath,
          name: currentFolder.name
        })

        path.push(currentFolder.name)
      }

      return { path, breadcrumb }
    }

    render() {
      const { isFolder } = this.props

      return (
        <>
          {this.props.location.pathname === this.props.match.url &&
            <Query
              query={this.props.match.params.folderId ? GET_PROJECT_FOLDER : GET_USER_ROOT_FILES}
              variables={{
                folderId: this.props.match.params.folderId
              }}
              fetchPolicy='cache-and-network'
            >
              {({ loading, error, data, refetch, networkStatus }) => {
                if (loading && networkStatus !== 6 && networkStatus !== 4) {
                  return (
                    <Loading
                      withOverlay={false}
                      className='bx--loading--center'
                    />
                  )
                }

                if (error) {
                  return `Error! ${error.message}`
                }

                const files = this.props.match.params.folderId ?
                  data.getFolder.files.filter((single)=>single.status === 'complete') : data.getUser.files.filter((single)=>single.status === 'complete')

                let folders = this.props.match.params.folderId ?
                  data.getFolder.folders : data.getUser.folders

                const tableRow = [...files, ...folders]

                tableRow.sort((a, b)=>{
                  if (a.name < b.name) {
                    return -1
                  } else if (a.name > b.name) {
                    return 1
                  }
                  return 0
                })

                const tableRowLimit = tableRow.sort().slice(0, 5)

                const { breadcrumb } = this.getBreadcrumb(files, folders, data.getFolder)

                let folderInView = breadcrumb[breadcrumb.length - 1].name || 'Files'

                return (<>

           {
             isFolder === true && <Hero src='ph.hero.projects.jpg' alt='Alt text' subtitle={folderInView || 'Files'} >

               { breadcrumb.length > 1 && (
                 <Breadcrumb items={breadcrumb} />
               ) }
             </Hero>
           }
            <CreatePagesHeader
              getSearchTerm={this.getSearchTerm}
              showSearch={true}
              subtitle={isFolder ? folderInView : 'My Files'}
              dropDownMenuList={this.dropDownMenuList({ refetch })}
              btnText={window.location.pathname === '/files' ? 'Upload' : null}
              linkText={window.location.pathname === '/files' ? 'View All' : null}
              linkUrl={'files/myfiles'}
            />

            <div className='sections-container my-files'>
              <SectionContainer>
                <FileTable
                  rows={window.location.pathname === '/files' ? tableRowLimit : tableRow}
                  nameCellClass='files-table'
                  manualRerender={this.props.manualRerender}
                  searchTerm={this.state.searchTerm}
                  showSearch={false}
                  projectId={null}
                  folderId={this.props.match.params.folderId}
                  fileFavourites={this.props.fileFavourites}
                  folderFavourites={this.props.folderFavourites}
                  showSkeleton={loading && networkStatus !== 6 && networkStatus !== 4}
                  refetch={refetch}
                  favourite
                  batchActions
                  actions
                  rowActions
                  pagination={window.location.pathname !== '/files'}
                  canCreate={true}
                  canDownload={true}
                  canDelete={true}
                  comments={false}
                  sortColumn='name'
                  sortDirection='ASC'
                  sortBy='Title'
                  perPage={this.props.perPage || 5}
                />
              </SectionContainer>
            </div>
                </>)
              }}
            </Query>
          }

        </>
      )
    }
}

export default compose(
  WithUploads,
  WithUploadModal,
  WithMoveFilesFrom,
  GetUser
)(MyFiles)

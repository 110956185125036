import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Modal, Checkbox } from 'carbon-components-react'

import IconArrowRight from '../../assets/images/icon-arrow-right.svg'
import { ReactComponent as IconFolder } from '../../assets/images/folder.svg'
import { ReactComponent as IconDocument } from '../../assets/images/icon-document.svg'
import Breadcrumb from '../Breadcrumb'

const MoveFilesFromModal = ({
  files,
  showModal = false,
  selectedFiles,
  destination = [],
  selectionBreadcrumb,
  onClose,
  onCancel,
  onSave,
  onMoveInDestination,
  onMoveOutDestination,
  onChangeDestinationFlow,
  onSelectDestination
}) => {
  const [destinationType, setDestinationType] = useState('files')
  const [links, setLinks] = useState([])
  const rootLink = {
    id: null,
    name: destinationType,
    action: () => {
      onMoveOutDestination(null, null)
      setLinks([])
    }
  }
  const onChange = ({ target }) => {
    setDestinationType(target.value)
    onChangeDestinationFlow(target.value)
    setLinks([])
  }

  const DestinationFolders = destination.map(data => {
    const { id, name, __typename } = data
    const isFile = __typename.toLowerCase() !== 'file'
    const folderId = __typename.toLowerCase() === 'folder' ? id : null
    const projectId = __typename.toLowerCase() === 'project' ? id : null
    const isFileIncluded = files.find(item=> Number(item.id) === Number(id))
    const isChecked = isFileIncluded ? true : false
    const classNames = `folder-name ${
      isFileIncluded ? 'isSelected' : ''
    }`

    return (
      <div className='folder-info'>
        <div
          onClick={e => {
            onSelectDestination(data, e)
          }}
          key={id}
          className={classNames}
        >
          <Checkbox
            labelText=''
            checked={isChecked}
          />
          {isFile ? <IconFolder /> : <IconDocument />}
          <p>{name}</p>
        </div>
        {isFile && (
          <img
            alt='icon'
            onClick={() => {
              onMoveInDestination(data)
              const newLinks = {
                id: data.id,
                name: data.name,
                action: ()=> {
                  onMoveOutDestination(projectId, folderId)
                  let breadCrumb = []
                  let push = true
            
                  links.forEach(link => {
                    if (push) {
                      breadCrumb.push(link)
                    }
                    if (link.id === id) {
                      push = false
                    }
                  })
                  breadCrumb = [...breadCrumb, newLinks]
                  setLinks(breadCrumb)
                }
              }
              setLinks([...links, newLinks])
            }}
            className='move-in-folder'
            src={IconArrowRight}
          />
        )}
      </div>
    )
  })

  const SelectedFiles = selectedFiles.map(({ id, name, __typename }) => (

    <div key={id} className='folder-info'>
      {__typename === 'Folder' ? <IconFolder /> : <IconDocument />}
      <p>{name}</p>
    </div>
  ))

  const DestinationOptions = (
    <div className='destination-option' onChange={onChange}>
      <input type='radio' checked={destinationType === 'files'} value='files' />
      <label>My Files</label>
      <input
        type='radio'
        checked={destinationType === 'projects'}
        value='projects'
      />
      <label>Projects</label>
      <input type='radio' checked={destinationType === 'songs'} value='songs' />
      <label>Songs</label>
    </div>
  )
  return (
    <Modal
      open={showModal}
      className='move-files-from'
      modalHeading='Move Files From'
      primaryButtonText='Move'
      secondaryButtonText='Cancel'
      onRequestSubmit={onSave}
      onRequestClose={() => {
        onClose()
        onCancel()
      }}
    >
      <div className='from-modal-container'>
        <div className='selected'>
          <div className='selected-files-folder'>
            <p>Selected Files/Folders To Move</p>
            {DestinationOptions}
          </div>
          <div className='selected-destination'>
            <p>Selected Destination</p>
          </div>
        </div>
        <div className='folder'>
          <div className='right'>
            <div className='breadcrumb-container'>
            <Breadcrumb items={[rootLink, ...links]} />
            </div>
            <div className='foler-container'>{DestinationFolders}</div>
          </div>
          <div className='arrow'>
            <img alt='icon' src={IconArrowRight} />
          </div>
          <div className='left'>
            <div className='breadcrumb-container'>
              <Breadcrumb items={selectionBreadcrumb} />
            </div>
            <div className='foler-container'>{SelectedFiles}</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

MoveFilesFromModal.prototype = {
  showModal: propTypes.bool,
  selectedFiles: propTypes.array,
  onClose: propTypes.func,
  destination: propTypes.array,
  onSave: propTypes.func,
  onCancel: propTypes.func,
  onMoveInDestination: propTypes.func,
  getBreadCrumb: propTypes.func,
  onChangeDestinationFlow: propTypes.func,
  onSelectDestination: propTypes.func,
  selectedId: propTypes.string,
  files: propTypes.array,
  selectionBreadcrumb: propTypes.array
}

export default MoveFilesFromModal

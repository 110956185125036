import React from 'react'
import { Query } from 'react-apollo'
import { GET_SHARE_SOCIALS } from '../graphql/queries'

function ShowShareSocialIcons({ uuid }) {
  return (
    <Query
      query={GET_SHARE_SOCIALS}
      variables={{ uuid: uuid }}
      fetchPolicy='network-only'
    >
      {({ loading, error, data }) => {
        if (loading) {
          return null
        }
        if (error) {
          return `Error! ${error.message}`
        }

        if (!data.getShareSocials) {
          return null
        }
        return (
          <div className='social-icons'>
            {data.getShareSocials
              .filter(social => social.isOn === '1')
              .map(social => {
                const socialName = social.social.name
                const hasBg =
                  socialName.toLowerCase() === 'tiktok' ||
                  socialName.toLowerCase() === 'tidal'
                return (
                  <a
                    href={social.social.preUrl + social.socialUrl}
                    target='_blank'
                    key={social.id}
                    rel='noopener noreferrer'
                    className={`socila-img-wrapper ${
                      hasBg ? 'social-icon-bg' : ''
                    }`}
                  >
                    <img
                      src={require(`../assets/images/${socialName}.svg`)}
                      alt={socialName}
                      height='30'
                      width='30'
                    />
                  </a>
                )
              })}
          </div>
        )
      }}
    </Query>
  )
}

export default ShowShareSocialIcons

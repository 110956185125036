import React from 'react'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'
import {
  RECENTLY_DELETED,
  GET_DELETED_FOLDER
} from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import Breadcrumb from '../components/Breadcrumb'
import FileTable from '../components/FileTable'
import SectionContainer from '../components/SectionContainer'
import ApolloClient from '../utils/Apollo'
import CreatePagesHeader from '../components/CreatePagesHeader'

class RecentlyDeletedFiles extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deletedFolderId: 0,
      currentFolder: '',
      rootFolders: []
    }
  }
    setFolderId=(row)=>{
      if (row.__typename === 'Folder') {
        this.setState({
          deletedFolderId: row.id,
          currentFolder: row
        })
        return
      }
      this.setState({
        deletedFolderId: 0
      })
    }
    getFolder = async (id)=>{
      try {
        const { data } = await ApolloClient.query({
          query: GET_DELETED_FOLDER,
          variables: {
            folderId: id
          },
          fetchPolicy: 'network-only'
        })
        const { getDeletedFolder } = data
        return getDeletedFolder
      } catch (error) {
        console.log(error.message)
      }
    }
    getBreadcrumb(file, folders, currentFolder) {
      if (!currentFolder) {
        this.setState({
          rootFolders: folders
        })
      }
      if (currentFolder) {
        const inRoot = this.state.rootFolders.find(item=>item.id === currentFolder.id)
        if (inRoot) {
          currentFolder.path = []
        }
      }

      let breadcrumb = [
        { action: ()=>{
          this.setState({
            deletedFolderId: 0,
            currentFolder: ''
          })
        },
        name: 'Recently Deleted'
        }
      ]

      let path = []
      if (currentFolder) {
        let action = ()=>{
          this.setState({
            deletedFolderId: 0,
            currentFolder: '',
            folders: []
          })
        }

        if (currentFolder.path && currentFolder.path.length > 0) {
          currentFolder.path.forEach((item, index) => {
            if (currentFolder.path.length < 3) {
              action = async ()=>{
                const folder = await this.getFolder(item.id)
                this.setState({
                  deletedFolderId: item.id,
                  currentFolder: folder
                })
              }
              breadcrumb.push({
                action: action,
                name: item.name
              })

              path.push(item.name)
            } else if (index < 2) {
              action = async ()=>{
                const folder = await this.getFolder(item.id)
                this.setState({
                  deletedFolderId: item.id,
                  currentFolder: folder
                })
              }
              breadcrumb.push({
                action: action,
                name: item.name
              })

              path.push(item.name)
            } else if (index === (currentFolder.path.length - 1)) {
              action = async ()=>{
                const folder = await this.getFolder(item.id)
                this.setState({
                  deletedFolderId: item.id,
                  currentFolder: folder
                })
              }
              breadcrumb.push({
                action: action,
                name: '...'
              })
              path.push(item.name)
            }
          })
        }

        action = ()=>{
          this.setState({
            deletedFolderId: currentFolder.id
          })
        }
        breadcrumb.push({
          action: action,
          name: currentFolder.name
        })

        path.push(currentFolder.name)
      }

      return { path, breadcrumb }
    }

    render() {
      const { deletedFolderId, currentFolder } = this.state
      return (
        <>
        <div key={this.props.shouldRerender}>
          {this.props.location.pathname === this.props.match.url &&
            <Query query={RECENTLY_DELETED}
              variables={{
                folderId: deletedFolderId !== 0 ? deletedFolderId : 0
              }}
              fetchPolicy='cache-and-network'
              pollInterval={this.props.uploads.state.inProgressCount > 0 ? 0 : 60000}>
              {({ loading, error, data, refetch, networkStatus }) => {
                if (loading && networkStatus !== 6 && networkStatus !== 4) {
                  return null
                }

                if (error) {
                  return `Error! ${error.message}`
                }
                const files = data.getDeletedFiles
                const folders = data.getDeletedFolders

                const updatedFiles = []
                const updatedFolders = []
                files.forEach(item=>{
                  if (item.folder) {
                    updatedFiles.push({
                      ...item,
                      parentFolder: item.folder.name
                    })
                  } else {
                    updatedFiles.push({
                      ...item,
                      parentFolder: '-'
                    })
                  }
                })
                folders.forEach(item=>{
                  if (item.project) {
                    updatedFiles.push({
                      ...item,
                      parentFolder: item.project.name
                    })
                  } else {
                    updatedFiles.push({
                      ...item,
                      parentFolder: '-'
                    })
                  }
                })

                const tableRow = [...updatedFiles, ...updatedFolders]
                const rowLimit = tableRow.slice(0,5)

                const { breadcrumb } = this.getBreadcrumb(files, folders, currentFolder)
                return (<>
                  { breadcrumb.length > 1 && (
                    <div className='sections-container'><Breadcrumb items={breadcrumb} />
                    </div>
                  ) }
                  <CreatePagesHeader
                    subtitle='Deleted Files'
                    headerText='Permanently Deleted after 30 days'
                    showSearch={true}
                    addClassName={'search-header-flex deleted-files'}
                    linkText={this.props.match.path === '/files' ? 'View All' : null}
                    linkUrl={'files/deletedfiles'}
                  />

                  <div className='sections-container deleted-files'>
                    <SectionContainer>
                      <FileTable 
                        rows={window.location.pathname === '/files' ? rowLimit : tableRow}
                        nameCellClass='deleted-files-table'
                        projectId={null}
                        folderId={this.props.match.params.folderId}
                        fileFavourites={this.props.fileFavourites}
                        folderFavourites={this.props.folderFavourites}
                        showSkeleton={loading && networkStatus !== 6 && networkStatus !== 4}
                        refetch={refetch}
                        favourite
                        batchActions
                        actions
                        rowActions
                        pagination={window.location.pathname !== '/files'}
                        canRestore={true}
                        setFolderId={this.setFolderId}
                        showSearch={false}
                        comments={false}
                        sortDirection='ASC'
                        sortColumn='name'
                        perPage={this.props.perPage || 5}
                        sortBy='Title'
                        disableCopyLink
                      />
                    </SectionContainer>
                  </div>
                </>)
              }}
            </Query>
          }
        </div>
        </>
      )
    }
}


export default compose(
  WithUploads,
  WithUploadModal,
  GetUser
)(RecentlyDeletedFiles)

import React from 'react'
import { Modal } from 'carbon-components-react'
const WithIntegrationDetailsModal = (WrappedComponent) => {
  return class extends React.PureComponent {
    state = {
      open: false,
      message: '',
      options: false
    }

    constructor(props) {
      super(props)

      this.close = this.close.bind(this)
      this.open = this.open.bind(this)
    }

    close() {
      this.setState({
        ...this.state,
        open: false
      })
    }

    open(message, options) {
      this.setState({
        ...this.state,
        open: true,
        message: message,
        options: { ...options }
      })
    }

    render() {
      const {options, message} = this.state
      let buttonText = 'Close'
      let secondaryText = ''
      let title =''
      let logoImage = ''
      let details = ''
      let callback = () => this.close()

      if (options && options.buttonText) {
        buttonText = options.buttonText
      }
      if (options && options.title) {
       title= options.title
      }
      if (options && options.logoImage) {
        logoImage= options.logoImage
       }
       if (options && options.details) {
        details= options.details
       }


      if (options && options.submitCallback) {
        callback = () => {
          this.state.options.submitCallback(this.close)
        }
      }

      let classNames = ['modal']

      if (!secondaryText || secondaryText === '') {
        classNames.push('modal--no-secondary')
      }

      return (
        <>
          <WrappedComponent {...this.props} details={this.open}/>
          <Modal className='integrations-details-modal'
            open={this.state.open}
            passiveModal={false}
            onRequestClose={ this.close}
            onRequestSubmit={callback}
            onSecondarySubmit={this.close}
            primaryButtonText={buttonText}
            secondaryButtonText={secondaryText}
          >
             <div className='integration-details'>
                    <div className='integration-details-header'>
                    <img
                className={`integration-tiles-logo integration-tiles-${title.toLocaleLowerCase()} `}
                src={logoImage} alt='logoImg' />
                <p className='integration-tiles-heading'>
                {title}
              </p>
             </div>
             <p className='integration-tiles-text' >
                { message }
              </p>
              <p className='integration-tiles-heading'>
                How this integration works
              </p>
              <p className='integration-tiles-text' >
                { details }
              </p>
             </div>
          </Modal>
        </>
      )
    }
  }
}

export default WithIntegrationDetailsModal

import { Field, Schema, /* When */ } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    /*
    type: Field()
      .string()
      .required({ message: Errors.Required }),
    recordings: Field()
      .required({
        message: 'You must select at least one recording',
        constraint: When(
          '#type',
          Field()
            .string()
            .equals('recording')
        )
      }),
    */
    name: Field()
      .string()
      .required({ message: Errors.Required }),
    email: Field()
      .string()
      .email({ message: Errors.Email })
      .required({ message: Errors.Required })
  },
  { verbose: true, async: true }
)

import React, { useState, useEffect } from 'react'
import SectionContainer from '../components/SectionContainer'

import MenuSwitcher from '../components/MenuSwitcher'
import Helmet from 'react-helmet-async'
import ApolloClient from '../utils/Apollo'
import { GET_SUBSCRIPTION_BILLING } from '../graphql/queries'
import CreatePagesHeader from '../components/CreatePagesHeader'
import BillingInformationTable from '../components/BillingInformationTable'
import { withRouter } from 'react-router'
import { compose } from 'react-apollo'


const BillingInformation = (props) => {
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)

  // Get Billing Information
  const getSubscriptionBilling = async () => {
    setLoading(true)
    const { data } = await ApolloClient.query({
      query: GET_SUBSCRIPTION_BILLING,
      fetchPolicy: 'network-only'
    })
    const { subscriptionBilling } = data

    setInvoices(subscriptionBilling)
    setLoading(false)
  }

  useEffect(() => {
    getSubscriptionBilling()
  }, [])

  const backToSubscription = () => {
    props.history.push('/account/subscription')
  }

  return (
    <>
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <main className='main'>
        <CreatePagesHeader subtitle='Subscription'>
          <div className='switcher-Style'>
            <MenuSwitcher />
          </div>
        </CreatePagesHeader>
        <div className='sections-container'>
          <div name='billing-information' className='billing-information'>
            <SectionContainer>
              <div className='invoices'>
                <div className='billing-header'>
                  <h2 className='title'>Invoices</h2>

                  <button
                    className='bx--btn bx--btn--secondary'
                    onClick={backToSubscription}
                  >
                    Close Billing Information
                  </button>
                </div>

                <div className='billing-table' >

                  <BillingInformationTable
                    rows={invoices || []}
                    showSkeleton={loading}
                  />
                </div>
              </div>
            </SectionContainer>
          </div>
        </div>
      </main>
    </>
  )
}
export default compose(withRouter)(BillingInformation)

import React from 'react'
import { compose } from 'react-apollo'
import { Link, withRouter } from 'react-router-dom'
import { injectStripe, CardElement } from 'react-stripe-elements'
import {
  Form,
  Button,
  TextInput,
  Checkbox,
  Icon
} from 'carbon-components-react'

import Validator from '../utils/Validator'
import RegistrationValidator from '../validators/Registration'
import {
  PLAN_FREE,
  PLAN_INDIVIDUAL,
  PLAN_NAMES, PLAN_PRICES,
  PLAN_PRO, PLAN_PRO_UNLIMITED
} from '../utils/constants'
import { SelectASync } from './Select'
import ApolloClient from '../utils/Apollo'
import { GET_TIMEZONE_TYPES, VALIDATE_DISCOUNT_CODE } from '../graphql/queries'
import WithAlertModal from '../hocs/WithAlertModal'
import Tooltip from './ui/Tooltip'
import { iconInfoGlyph } from 'carbon-icons'


const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  plan: 'free',
  free: PLAN_PRICES.free,
  individual: PLAN_PRICES.individual,
  pro: PLAN_PRICES.pro,
  prounlimited: PLAN_PRICES.prounlimited,
  discountCode: '',
  terms: false,
  timezoneId: '',
  validDiscountCode: false,
  validationMessage: '',
  type: '',
  initialErrors: {},
  errors: {}
}

class RegistrationForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)
    this.state.code = this.props.match.params.code || ''
    this.state.discountCode = this.state.code
    this.state.plan = this.state.discountCode ? 'individual' : 'free'
    if (this.state.code) {
      this.validDiscountCodevalidateDiscountCode()
    }
    this.validator = RegistrationValidator
    this.handleChangeTimezone = this.handleChangeTimezone.bind(this)
    this.handleChangeDirtyTimezone = this.handleChangeDirtyTimezone.bind(this)
    this.getTimezonesTypeOptions = this.getTimezonesTypeOptions.bind(this)
  }


  submit() {
    if (this.state.plan === 'free') {
      return this.props.onSubmit(this.state)
    }

    this.props.stripe.createToken().then(({ token, error }) => {
      if (error) {
        this.setState({
          errors: {
            ...this.state.errors,
            card: error.message
          }
        })
        return
      }

      this.props.onSubmit({ ...this.state, stripeToken: token.id })
    })
  }

  handleChangeTimezone(ev) {
    this.setState({
      ...this.state,
      timezoneId: ev.value,
      errors: {
        ...this.state.errors,
        timezoneId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleChangeDirtyTimezone() {
    this.handleDirty({
      target: {
        name: 'timezoneId',
        value: this.state.timezoneId
      }
    })
  }


  getTimezonesTypeOptions = inputValue =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_TIMEZONE_TYPES,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let timezoneTypes = []
        data.getTimezone.forEach(timezoneType => {
          if (!timezoneType.timezone.toLowerCase().includes(inputValue.toLowerCase())) {
            return
          }
          timezoneTypes.push({
            value: timezoneType.id,
            label: timezoneType.offset
          })
        })
        resolve(timezoneTypes)
      })
    })
    handlePercentOff = (plan, percentOff) =>{
      const priceArr = PLAN_PRICES[plan].split('')
      priceArr.shift()
      const price = priceArr.join('')
      const discountedPrice = (parseFloat(price) - (parseFloat(price) * (percentOff / 100))).toFixed(2)
      this.setState({
        [plan]: '$' + discountedPrice
      })
    }
    handleAmountOff = (plan, amountOff)=>{
      const priceArr = PLAN_PRICES[plan].split('')
      priceArr.shift()
      const price = priceArr.join('')
      let discountedPrice
      if (parseFloat(price) < amountOff) {
        this.setState({
          [plan]: '$' + 0
        })
      } else {
        discountedPrice = (parseFloat(price) - parseFloat(amountOff)).toFixed(2)
        this.setState({
          [plan]: '$' + discountedPrice
        })
      }
    }

    handleDiscountCodeChange = async (event) => {
      this.setState({
        ...this.state,
        validationMessage: '',
        discountCode: '',
        plan: ''
      })

      const { value } = event.target
      this.setState({
        ...this.state,
        discountCode: value,
        plan: 'individual'
      })
      this.props.updateImage('individual')
      if (value) {
        const { data } = await ApolloClient.query({
          query: VALIDATE_DISCOUNT_CODE,
          variables: {
            couponCode: value
          },
          fetchPolicy: 'network-only'
        })
        const { validateCouponCode } = data
        const { valid, percentOff, amountOff, type, message } = validateCouponCode
        this.setState({
          ...this.state,
          type: type
        })
        if (type === 'referral' || !type) {
          this.setState({
            validationMessage: message
          })
        } else {
          this.setState({
            validationMessage: ''
          })
        }
        const plans = ['pro', 'individual', 'prounlimited']

        this.setState({ validDiscountCode: valid, type: type })
        if (percentOff) {
          plans.forEach(plan=> this.handlePercentOff(plan, percentOff))
        }
        if (amountOff) {
          plans.forEach(plan=> this.handleAmountOff(plan, percentOff))
        }
      } else {
        this.setState({ type: '', validationMessage: '' })
      }
    }

    validDiscountCodevalidateDiscountCode = async () =>{
      const { discountCode } = this.state
      if (discountCode) {
        const { data } = await ApolloClient.query({
          query: VALIDATE_DISCOUNT_CODE,
          variables: {
            couponCode: discountCode
          },
          fetchPolicy: 'network-only'
        })
        const { validateCouponCode } = data
        const { valid, percentOff, amountOff, type, message } = validateCouponCode
        this.setState({
          ...this.state,
          type: type,
          plan: 'individual'
        })
        this.props.updateImage('individual')
        if (type === 'referral' || !type) {
          this.setState({
            validationMessage: message
          })
        } else {
          this.setState({
            validationMessage: ''
          })
        }
        const plans = ['pro', 'individual', 'prounlimited']

        this.setState({ validDiscountCode: valid, type: type })
        if (percentOff) {
          plans.forEach(plan=> this.handlePercentOff(plan, percentOff))
        }
        if (amountOff) {
          plans.forEach(plan=> this.handleAmountOff(plan, percentOff))
        }
      } else {
        this.setState({ type: '', validationMessage: '' })
      }
    }
    render() {
      const {
        firstName,
        lastName,
        plan,
        discountCode,
        terms,
        errors,
        email,
        password,
        passwordConfirmation,
        validDiscountCode,
        type,
        validationMessage

      } = this.state
      return (
        <Form onSubmit={this.handleSubmit}>
          <div className='formRow'>
            <TextInput id='firstName'
              name='firstName'
              labelText='First Name *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={firstName || ''}
              invalid={errors.firstName ? true : false}
              invalidText={errors.firstName || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='lastName'
              name='lastName'
              labelText='Last Name *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={lastName || ''}
              invalid={errors.lastName ? true : false}
              invalidText={errors.lastName || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='email'
              name='email'
              labelText='Email *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={email || ''}
              invalid={errors.email ? true : false}
              invalidText={errors.email || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='password'
              name='password'
              labelText='Password *'
              type='password'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={password || ''}
              invalid={errors.password ? true : false}
              invalidText={errors.password || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='passwordConfirmation'
              name='passwordConfirmation'
              labelText='Confirm Password *'
              type='password'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={passwordConfirmation || ''}
              invalid={errors.passwordConfirmation ? true : false}
              invalidText={errors.passwordConfirmation || ''} />
          </div>
          <div className='formRow'>
            <SelectASync
              labelText='Time Zone *'
              id='timeZone'
              cacheOptions
              defaultOptions
              loadOptions={ this.getTimezonesTypeOptions }
              onChange={ this.handleChangeTimezone }
              onBlue={this.handleChangeDirtyTimezone}
              invalid={errors.timezoneId ? true : false}
              invalidText={errors.timezoneId || '' }/>
          </div>
          <div className='formRow'>
            <div className='plans-container'>
              {Object.keys(PLAN_NAMES).map(key => {
                let planname = ''
                let message = ''
                if (key === PLAN_FREE) {
                  planname = 'free'
                  message = 'No money, no problem. We will never charge to collect credits and metadata.'
                } else if (key === PLAN_INDIVIDUAL) {
                  planname = 'individual'
                  message = 'For the avid music creator.'
                } else if (key === PLAN_PRO) {
                  planname = 'pro'
                  message = 'For powerful collaboration features.'
                } else if (key === PLAN_PRO_UNLIMITED) {
                  planname = 'prounlimited'
                  message = 'Prolific collaborators with unlimited storage for unlimited creativity.'
                }

                return (
                  <div className={'plan-container ' + (discountCode && key === PLAN_FREE ? 'disabled-plan' : '')} key={key}>
                    <div className='plan-info'>
                      <div className='plan-name'>{PLAN_NAMES[key]}</div>
                      <div className='plan-detail'>{message}</div>
                    </div>
                    <button
                      type='button'
                      onClick={() => {
                        this.props.updateImage(planname)
                        this.setState({
                          plan: planname,
                          free: PLAN_PRICES.free,
                          individual: PLAN_PRICES.individual,
                          pro: PLAN_PRICES.pro,
                          prounlimited: PLAN_PRICES.prounlimited
                        })
                      }}
                      disabled={discountCode && key === PLAN_FREE}
                      className={
                        'plan-option ' + (
                          plan === key ? 'selected-plan-option ' : ''
                        )
                      }>
                      {PLAN_PRICES[key]}
                    </button>
                  </div>
                )
              })}
            </div>
            {errors.plan &&
            <div className='bx--form-requirement visible'>{errors.plan}</div>
            }
          </div>

          <React.Fragment>

            <div className={'formRow discount-code-input ' + ((discountCode && validationMessage && plan !== 'free') || type === 'referral' ? 'cs-discount-code-input' : '')}>
              <TextInput id='discountCode'
                name='discountCode'
                labelText={
                  <div className='input-icon-container'>
                    <p className='input-icon-container--text bx--label'>Discount/Activation Code</p>
                    <Tooltip term={
                      <Icon icon={iconInfoGlyph} description='' className='info-icon'/>
                    } definition='Don’t have a Discount Code? Email us at mailto:hello@vevacollect.com' info={true}/>
                  </div>
                }
                onChange={this.handleDiscountCodeChange}
                onPaste={this.handleDiscountCodeChange}
                value={discountCode || ''}
                invalid={errors.discountCode ? true : false}
                invalidText={errors.discountCode || ''}
              />
              <div className='show-validate-message'>
                {discountCode && <>
                { validDiscountCode && type === 'referral' ?
                  <p>
                    {`1 Free Month of any plan. ${PLAN_NAMES[plan]} Plan selected. (${PLAN_PRICES[plan]} after)`}
                  </p>
                  :
                  <p>{this.state.validationMessage}</p>
                }
              </>}
              </div>
            </div>
            {plan !== 'free' &&
            <div className='formRow'>
              <label className='bx--label'>Card Details</label>
              <CardElement style={{ base: { color: '#fff' } }} />
              {errors.card &&
                <div className='bx--form-requirement visible'>{errors.card}</div>
              }
            </div>
            }
          </React.Fragment>
          <div className='formRow terms-conditions'>
            <Checkbox name='terms' id='terms' labelText={
              <span>I agree to the <a href='https://www.vevacollect.com/terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms & Conditions</a></span>
            }
            checked={terms}
            onChange={(e) => {
              this.handleChange({
                target: {
                  name: 'terms',
                  value: e
                }
              })
            }} />
            {errors.terms &&
            <div className='bx--form-requirement visible'>{errors.terms}</div>
            }
          </div>
          <div className='alternateOption success-message'>Already have an account? <Link to='/login'>Login</Link></div>
          <div className='formRow mb-0 register-btn'>
            <Button type='submit' className='loginSubmit register-submit'>
              { this.props.loading ? 'Registering...' : 'Register' }
            </Button>
          </div>
        </Form>
      )
    }
}

export default compose(injectStripe, WithAlertModal, withRouter)(RegistrationForm)

import React from 'react'
import PlusIcon from '../assets/add.svg'

export default class EmptyInfoTile extends React.PureComponent {
  render() {
    const {
      title,
      btnOnClick
    } = this.props

    return (
      <div className='empty-info-container' >
        <div className='empty-icon' onClick={btnOnClick}>
          <img className='empty-image' src={PlusIcon} alt='play' />
        </div>
        <div className='title-container empty-text'>
          <h3>{title}</h3>
        </div>
      </div>
    )
  }
}

import React from 'react'
import { Modal, TextInput } from 'carbon-components-react'
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import { compose } from 'react-apollo'
import ApolloClient from '../../utils/Apollo'
import { COMPLETE_PUBLIC_SHARE } from '../../graphql/mutations'
import Validator from '../../utils/Validator'
import OtpValidator from '../../validators/OtpValidator'
import PublicShareAPI from '../../networking/publicShareAPI'
import WithUploads from '../../hocs/WithUploads'
import { refreshToken } from '../../utils/Apollo'
import WithAlertModal from '../../hocs/WithAlertModal'
import PublicShareUploadsProgress from '../../components/UploadsActivity/PublicUpload/PublicShareUploadsProgress'

const initialState = {
  otp: '',
  uploads: {},

  uploadIds: [],
  inProgressCount: 0,
  errorCount: 0,
  incorrect: false,

  initialErrors: {},
  errors: {}
}

class PublicShareModal extends Validator {
    api = new PublicShareAPI()
    vaidator = OtpValidator


    constructor(props) {
      super(props)
      this.state = { ...initialState }
      this.uppy = Uppy({
        autoProceed: true,
        onBeforeFileAdded: currentFile => {
          if (currentFile.data.size === 0) {
            return false
          }

          if (currentFile.name.indexOf('.DS_Store') > -1) {
            return false
          }

          if (this.state.uploads[currentFile.id]) {
            return false
          }

          return true
        }
      })
      this.getUppy = this.getUppy.bind(this)
      this.removeUpload = this.removeUpload.bind(this)
      this.clearUploads = this.clearUploads.bind(this)
      this.openModal = this.openModal.bind(this)

      this.validator = OtpValidator
    }


    getUppy() {
      return this.uppy
    }
    successPublicShare = async (publicShareId)=>{
      await ApolloClient.mutate({
        mutation: COMPLETE_PUBLIC_SHARE,
        variables: {
          publicShareId: publicShareId
        }
      })
    }
    removeUpload(id) {
      if (this.state.uploads[id].uploading === true) {
        this.uppy.removeFile(id)
        this.props.onRequestClose()
      }

      this.removeFileFromList(id)
      this.props.onRequestClose()
    }

    clearUploads() {
      this.uppy.cancelAll()
      this.setState({
        ...initialState,
        uploadModal: false
      })
      this.props.onRequestClose()
    }

    removeFileFromList(id) {
      let uploads = { ...this.state.uploads }
      let uploadIds = [ ...this.state.uploadIds ]

      const isInProgress = this.state.uploads[id].uploading
      const hasError = this.state.uploads[id].error

      delete uploads[id]
      delete uploadIds[uploadIds.indexOf(id)]

      uploadIds = uploadIds.filter(uploadId => typeof uploadId !== 'undefined')

      this.setState({
        uploads,
        uploadIds,
        inProgressCount: (isInProgress ?
          this.state.inProgressCount - 1 :
          this.state.inProgressCount),
        errorCount: (hasError ?
          this.state.errorCount - 1 : this.state.errorCount)
      })
      this.props.onRequestClose()
    }
    createMultipartUpload(file, publicShareId, resolve, reject) {
      return fetch(`${process.env.REACT_APP_API}/api/public-share/create`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        },
        body: JSON.stringify({ ...file, public_share_id: publicShareId })
      })
        .then(response => response.json())
        .then(response => {
          if (response.message === 'Unauthenticated.') {
            refreshToken().then(
              () => this.createMultipartUpload(file, publicShareId, resolve, reject))
            return
          }
          if (response.name !== file.key) {
            this.uppy.setFileState(file.id, {
              name: response.name
            })
            this.setState({
              ...this.state,
              uploads: {
                ...this.state.uploads,
                [file.id]: {
                  ...this.state.uploads[file.id],
                  fileId: response.id,
                  name: response.name
                }
              }
            })
          }
          resolve(response)
        })
        .catch(e => reject(e))
    }
    prepareUploadPart(file, partData, resolve, reject) {
      return fetch(`${process.env.REACT_APP_API}/api/public-share/prepare`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        },
        body: JSON.stringify(partData)
      })
        .then(response => response.json())
        .then(response => {
          if (response.message === 'Unauthenticated.') {
            refreshToken().then(() => setTimeout(() => {
              this.prepareUploadPart(file, partData, resolve, reject)
            }, 40))
            return
          }
          resolve(response)
        })
        .catch(e => reject(e))
    }
    listParts(uploadId, key, resolve, reject) {
      return fetch(`${process.env.REACT_APP_API}/api/public-share/list`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        },
        body: JSON.stringify({
          uploadId,
          key
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.message === 'Unauthenticated.') {
            refreshToken().then(() => this.listParts(uploadId, key, resolve, reject))
            return
          }
          resolve(response)
        })
        .catch(e => reject(e))
    }

    abortMultipartUpload(file, uploadId, key, resolve, reject) {
      return fetch(`${process.env.REACT_APP_API}/api/public-share/abort`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        },
        body: JSON.stringify({
          id: this.state.uploads[file.id].fileId,
          projectId: null,
          folderId: null,
          uploadId,
          key
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.message === 'Unauthenticated.') {
            refreshToken().then(
              () => this.abortMultipartUpload(file, uploadId, key, resolve, reject))
            return
          }
          resolve(response)
        })
        .catch(e => reject(e))
    }
    completeMultipartUpload(file, uploadId, key, parts, resolve, reject) { // eslint-disable-line
      return fetch(`${process.env.REACT_APP_API}/api/public-share/complete`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        },
        body: JSON.stringify({
          id: this.state.uploads[file.id].fileId,
          projectId: null,
          folderId: null,
          uploadId,
          key,
          parts
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.message === 'Unauthenticated.') {
            refreshToken().then(() =>
              this.completeMultipartUpload(file, uploadId, key, parts, resolve, reject)
            )
            return
          }
          resolve(response)
        })
        .catch(e => reject(e))
    }

    handleVerify = async (info) => {
      try {
        const response = await this.api.verifyEmail(info)

        if (response.ok) {
          const data = await response.json()
          if (data.success === false) {
            this.props.alert('Please enter valid code', {
              submitCallback: (close) => {
                close()
              }
            })
          }
          if (data.public_share_id) {
            this.props.onRequestClose()
            this.props.files.forEach((file) => {
              const fileEntry = this.props.entries.find(item => item.name === file.name)
              const filePath = file.webkitRelativePath === '' ? file.name : file.webkitRelativePath
              const relativePath = fileEntry && fileEntry.name === file.name ? fileEntry.fullPath : '/' + filePath
              try {
                this.uppy.addFile({
                  source: 'uppy-dashboard0',
                  name: file.name,
                  type: file.type,
                  meta: {
                    relativePath: relativePath
                  },
                  relativePath: relativePath,
                  data: file
                })
              } catch (err) {
                if (err.isRestriction) {
                  console.log('Restriction error:', err)
                } else {
                  console.error(err)
                }
              }
            })
            this.uppy.use(AwsS3Multipart, {
              limit: 10,
              createMultipartUpload: file => {
                return new Promise((resolve, reject) => {
                  this.createMultipartUpload(file, data.public_share_id, resolve, reject)
                }).then(res =>{
                  if (res.upgradeRequired) {
                    this.props.alert('You can not share files more than 2GB.', {
                      submitCallback: (close) => {
                        close()
                        window.location.reload(false)
                      }
                    })
                  } else {
                    return res
                  }
                })
              },

              prepareUploadPart: (file, partData) => {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    this.prepareUploadPart(file, partData, resolve, reject)
                  }, 40)
                })
              },
              listParts: (file, { uploadId, key }) => {
                return new Promise((resolve, reject) => {
                  this.listParts(uploadId, key, resolve, reject)
                })
              },
              abortMultipartUpload: (file, { uploadId, key }) => {
                if (!this.state.uploads[file.id]) {
                  return new Promise((resolve) => resolve())
                }
                return new Promise((resolve, reject) => {
                  this.abortMultipartUpload(file, uploadId, key, resolve, reject)
                })
              },
              completeMultipartUpload: (file, { uploadId, key, parts }) => {
                return new Promise((resolve, reject) => {
                  this.completeMultipartUpload(file, uploadId, key, parts, resolve, reject)
                })
              }
            })

            this.uppy.on('upload', ({ fileIDs }) => {
              let uploads = { ...this.state.uploads }
              let uploadIds = [ ...this.state.uploadIds ]

              fileIDs.forEach(id => {
                const file = this.uppy.getFile(id)
                let path = (file.meta.relativePath)
                let pathexp = (path ? path : '').split('/')
                if (pathexp[pathexp.length - 1] === file.name) {
                  delete pathexp[pathexp.length - 1]
                  path = pathexp.join('/')
                }

                uploads[id] = {
                  id,
                  progress: 0,
                  name: file.name,
                  uploading: true,
                  complete: false,
                  size: file.size,
                  path: (path || '/'),
                  location: '',
                  type: file.extension ? file.extension : file.type.split('/')[1]
                }

                if (uploadIds.indexOf(id) === -1) {
                  uploadIds.push(id)
                }
              })

              this.setState({
                uploads,
                uploadIds,
                inProgressCount: this.state.inProgressCount + fileIDs.length
              })
            })

            this.uppy.on('file-removed', file => {
              if (file.progress.uploadComplete) {
                return
              }

              this.abortMultipartUpload(
                file,
                file.s3Multipart.uploadId,
                file.s3Multipart.key,
                () => {},
                () => {}
              )
            })

            this.uppy.on('upload-error', (file, error) => {
              this.setState({
                uploads: {
                  ...this.state.uploads,
                  [file.id]: {
                    ...this.state.uploads[file.id],
                    uploading: false,
                    error: error.message
                  }
                },
                inProgressCount: this.state.inProgressCount - 1,
                errorCount: this.state.errorCount + 1
              })

              this.uppy.removeFile(file.id)
            })

            this.uppy.on('upload-success', file => {
              this.setState({
                uploads: {
                  ...this.state.uploads,
                  [file.id]: {
                    ...this.state.uploads[file.id],
                    uploading: false,
                    complete: true
                  }
                },
                inProgressCount: this.state.inProgressCount - 1
              })

              this.uppy.removeFile(file.id)
            })

            this.uppy.on('upload-progress', (file, progress) => {
              const progressPercentage =
               Math.floor((progress.bytesUploaded / progress.bytesTotal) * 100)

              if (!this.state.uploads[file.id] ||
          progressPercentage === this.state.uploads[file.id].progress) {
                return
              }

              this.setState({
                ...this.state,
                uploads: {
                  ...this.state.uploads,
                  [file.id]: {
                    ...this.state.uploads[file.id],
                    progress: progressPercentage
                  }
                }
              })
            })
            this.uppy.upload().then((result) => {
              this.successPublicShare(data.public_share_id)
              this.props.alert('Thanks for using VEVA Collect', {
                submitCallback: (close) => {
                  close()
                  window.location.reload(false)
                }
              })
            })

            this.uppy.on('upload-started', () => {
              this.setState({
                status: 'started'
              })
            })

            this.uppy.on('complete', () => {
              this.setState({
                status: 'complete'
              })
            })
          }
        } else {
          const data = await response.json()
          console.log(data, 'err')
        }
      } catch (error) {
        console.log(error)
      }
    }

    submit() {
      this.handleVerify({
        sender_email: this.props.email,
        verification_token: this.state.otp
      })
    }

    openModal() {
      this.setState({ uploadModal: true })
    }


    render() {
      const { uploadIds } = this.state
      const open = uploadIds.length === 0 ? this.props.open : false
      return (
        <>
        <Modal className='modal-scrollable'
          open={open}
          modalHeading='Verification'
          primaryButtonText='Verify'
          secondaryButtonText='Cancel'
          shouldSubmitOnEnter={true}
          selectorPrimaryFocus='.bx--text-input'
          onRequestSubmit={this.handleSubmit}
          email={this.props.email}
          onRequestClose={this.props.onRequestClose}>
          <p className='verificationCode'>We have sent a verification code to your email to verify your sender Email address. Please enter this verification code below.</p>
          <div className='form'>
            <div className='formRow'>
              <TextInput id='otp'
                name='otp'
                labelText='Code'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.otp || ''}
                invalid={this.state.errors.otp ? true : false}
                invalidText={this.state.errors.otp || ''} />
            </div>
          </div>
        </Modal>
        <PublicShareUploadsProgress uploads={{
          state: this.state,
          actions: {
            getUppy: this.getUppy,
            removeUpload: this.removeUpload,
            clearUploads: this.clearUploads,
            onClose: this.props.onRequestClose,
            openModal: this.openModal
          }
        }}/>
        </>
      )
    }
}

export default compose(WithAlertModal)(WithUploads(PublicShareModal))
export const getUploads = (state) => {
  return state.uploadIds.map(uploadId => state.uploads[uploadId])
}

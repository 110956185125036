/* eslint-disable no-console */
import { MOVE_FILES } from '../graphql/mutations'
import ApolloClient from './Apollo'
import {
  GET_USER_ROOT_FILES,
  GET_PROJECT_ROOT_FILES,
  GET_PROJECTS,
  GET_PROJECT_FOLDER
} from '../graphql/queries'


export const getQuery = state => {
  const { isMyFile, isProject, isSongs, projectId, folderId } = state

  const isProjectRoot = isProject && !projectId && !folderId
  const isSongsRoot = isSongs && !projectId && !folderId
  const isMyFileRoot = isMyFile && !projectId && !folderId

  const isFolder = folderId
  const isProjectQuery = projectId && !isFolder

  return {
    ...(isProjectRoot && { query: GET_PROJECTS, queryKey: 'getProjects' }),
    ...(isSongsRoot && { query: GET_USER_ROOT_FILES, queryKey: 'getUser' }),
    ...(isMyFileRoot && { query: GET_USER_ROOT_FILES, queryKey: 'getUser' }),
    ...(isFolder && { query: GET_PROJECT_FOLDER, queryKey: 'getFolder' }),
    ...(isProjectQuery && {
      query: GET_PROJECT_ROOT_FILES,
      queryKey: 'getProject'
    })
  }
}

export const onMoveFiles = async (state, selectedId) => {
  let response = null
  const {
    isMyFile,
    isProject,
    isSongs,
    typeName,
    projectId,
    folderId,
    files
  } = state
  const inFiles = isMyFile && !projectId && !folderId && !isProject && !isSongs
  const inFolder = ((typeName && typeName.toLowerCase()) === 'folder' || folderId)
  const inProject =
    isProject &&
    ((typeName && typeName.toLowerCase()) === 'project' || projectId) &&
    !isMyFile &&
    !isSongs

  try {
    const variables = {
      files: files.map(({ id, type }) => ({ id: Number(id), type })),
      ...(inFiles && { project_id: null, folder_id: null, recording_id: null }),
      ...(inProject && { project_id: Number(selectedId), folder_id: 0, recording_id: 0 }),
      ...(inFolder && { project_id: 0, folder_id: Number(selectedId), recording_id: 0 })
    }
    const { data } = await ApolloClient.mutate({
      mutation: MOVE_FILES,
      variables: variables
    })
    response = data.moveFiles
  } catch (error) {
    console.log(error.message)
  }
  return response
}

export const onMoveFilesFrom = async (folderId, projectId, files) => {
  let response = null
  const inFiles = !projectId && !folderId
  try {
    const variables = {
      files: files.map(({ id, type }) => ({ id: Number(id), type })),
      ...(inFiles && { project_id: null, folder_id: null, recording_id: null }),
      ...(projectId && { project_id: Number(projectId), folder_id: 0, recording_id: 0 }),
      ...(folderId && { project_id: 0, folder_id: Number(folderId), recording_id: 0 })
    }
    const { data } = await ApolloClient.mutate({
      mutation: MOVE_FILES,
      variables: variables
    })
    response = data.moveFiles
  } catch (error) {
    console.log(error.message)
  }
  return response
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const getDestinationFolder = async (folderId, projectId)=>{
  console.log(folderId, projectId)
  const { query, queryKey } = {
    ...((!folderId && !projectId) && { query: GET_USER_ROOT_FILES, queryKey: 'getUser' }),
    ...((folderId) && { query: GET_PROJECT_FOLDER, queryKey: 'getFolder' }),
    ...(projectId && { query: GET_PROJECT_ROOT_FILES, queryKey: 'getProject' })
  }
  const variables = {
    ...(folderId && { folderId: folderId }),
    ...(projectId && { projectId: projectId }),
    ...((!projectId && !folderId) && { projectId: null, folderId: null })
  }
  console.log(variables)
  let response = null
  try {
    const { data } = await ApolloClient.query({
      query: query,
      variables: variables
    })
    response = data[queryKey]
  } catch (error) {
    console.log(error.message)
  }
  return response
}

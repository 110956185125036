import React from 'react'
import { CommentsConsumer } from '../contexts/Comments'

const WithComments = (WrappedComponent) => {
  return (props) => (
    <CommentsConsumer>
      {context => (
        <WrappedComponent {...props} loadComments={context.actions.loadComments} />
      )}
    </CommentsConsumer>
  )
}

export default WithComments

import React from 'react'
import { debounced } from '../utils/functions'

import Table from './Table'

export default class ResponsiveTable extends React.PureComponent {
  state = {
    wrapped: null
  }

  constructor(props) {
    super(props)
    this.isComponentMounted = false

    this.detectSize = this.detectSize.bind(this)
    this.detectSizeDebounced = debounced(250, this.detectSize.bind(this))
  }

  componentDidMount() {
    this.isComponentMounted = true
    window.addEventListener('resize', this.detectSizeDebounced)
    this.detectSize()
  }

  componentWillUnmount() {
    this.isComponentMounted = false
    window.removeEventListener('resize', this.detectSizeDebounced)
  }

  detectSize() {
    if (this.isComponentMounted === false) {
      return
    }

    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    let wrapped = (viewportWidth < 768)
    if (this.state.wrapped !== wrapped) {
      this.setState({
        wrapped
      })
    }
  }

  render() {
    if (this.state.wrapped === null) {
      return null
    }
    

    return <Table {...this.props} wrapped={this.state.wrapped} />
  }
}

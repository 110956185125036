import React from 'react'
import Helmet from 'react-helmet-async'
import { Query, compose } from 'react-apollo'

import { GET_PROJECTS } from '../graphql/queries'

import { getUserFavouritesByType } from '../utils/selectors'
import GetUser from '../hocs/queries/GetUser'

// Our Base Components
import Hero from '../components/Hero'
import ProjectTileList from '../components/ProjectTileList'
import SectionContainer from '../components/SectionContainer'
import WithAlertModal from '../hocs/WithAlertModal'
import EmptyInfoTile from '../components/EmptyInfoTile'

class ProjectsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }

    this.searchProjectHandler = this.searchProjectHandler.bind(this)
  }

  searchProjectHandler(value) {
    this.setState({
      searchTerm: value
    })
  }


  createProject = () => {
    this.props.history.push('/projects/create')
  }

  filterProjects = (projects) => {
    if (!projects) {
      return []
    }

    let filteredProjects = projects.filter((project)=> project.name.toString().toLowerCase().includes(this.state.searchTerm.toString().toLowerCase()))

    return filteredProjects || []
  }

  render() {
    const favourites = getUserFavouritesByType(this.props.user)
    return (
      <>
        <Helmet>
          <title>Projects</title>
        </Helmet>
        <main className='main'>
          <Hero src='ph.hero.projects.jpg'
            alt='Alt text'
            subtitle='Projects'
            btnOnClick ={this.createProject}
            btnText= 'Add'
            showSearch={true}
            getSearchTerm={this.searchProjectHandler}
          />
          <div className='mainContainer playlist-empty'>
            <div className='project-tiles project-files'>
              <SectionContainer>
                <Query query={GET_PROJECTS}
                  variables={{
                    userId: this.props.user.id
                  }}
                  fetchPolicy='network-only'>
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    let length = data.getProjects ? data.getProjects.length : null

                    if (!length && !loading) {
                      return (
                        <EmptyInfoTile
                          title='Create Project'
                          btnOnClick={this.createProject}
                        />
                      )
                    }

                    let filteredProjects = data.getProjects ? this.filterProjects(data.getProjects) : []

                    return (
                      <ProjectTileList projects={filteredProjects || []}
                        favourites={favourites.project || []}
                        showLoader={loading}
                        withOptions
                        onDelete={() => this.props.alert('The project has been successfully deleted.')}
                        user={this.props.user}
                        refetch={refetch}
                      />
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>
        </main>
      </>
    )
  }
}

export default compose(GetUser, WithAlertModal)(ProjectsPage)

import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo-veva.svg'

const AppBranding = () => (
  <div className='appBranding'>
    <Link to='/login'><img src={logo} className='appLogo' alt='logo' /></Link>
  </div>
)

export default AppBranding

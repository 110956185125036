import React from 'react'
import { graphql } from 'react-apollo'
import {
  Form,
  TextInput,
  Modal
} from 'carbon-components-react'
import { DateTime } from 'luxon'

import { CREATE_PARTY } from '../graphql/mutations'
import Validator from '../utils/Validator'
import PartyValidator from '../validators/Party'

const initialState = {
  type: 'person',
  title: '',
  prefix: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  isni: '',
  comments: '',

  birthDate: '',
  deathDate: '',

  errors: {}
}

class CreateProjectArtistModal extends Validator {
  validator = PartyValidator

  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
      type: props.defaultType || 'person'
    }
  }

  handleBirthDateChange = (full, formatted) => {
    this.setState({
      ...this.state,
      birthDate: formatted,
      errors: {
        recordedOn: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleDeathDateChange = (full, formatted) => {
    this.setState({
      ...this.state,
      deathDate: formatted,
      errors: {
        recordedOn: undefined // eslint-disable-line no-undefined
      }
    })
  }

  submit() {
    const dateFormattedState = {
      ...this.state,
      contacts: [],
      addresses: [],
      birthDate: this.state.birthDate
        ? DateTime.fromFormat(this.state.birthDate, 'LL/dd/yyyy').toISODate()
        : null,
      deathDate: this.state.deathDate
        ? DateTime.fromFormat(this.state.deathDate, 'LL/dd/yyyy').toISODate()
        : null
    }

    const dataToSubmit = Object.assign(
      {},
      ...Object.keys(dateFormattedState)
        .filter(key => {
          if (key === 'birthDate' || key === 'deathDate') {
            return Boolean(dateFormattedState[key])
          }
          return true
        })
        .map(key => ({ [key]: dateFormattedState[key] }))
    )

    this.props
      .createParty(dataToSubmit)
      .then(response => {
        this.props.onProjectArtistCreated(response.data.createParty)
        this.setState({ ...initialState })
      })
      .catch(e => {
        this.setState({
          ...this.state,
          errors: Validator.flattenInitialErrors(e, 'input.')
        })
      })
  }

  render() {
    return (
      <Modal
        className='modal-scrollable artist-modal'
        open={this.props.open}
        modalHeading='New Project Artist'
        primaryButtonText='Create'
        secondaryButtonText='Cancel'
        shouldSubmitOnEnter={true}
        selectorPrimaryFocus='.bx--text-input'
        onRequestSubmit={this.handleSubmit}
        onRequestClose={this.props.onRequestClose}
      >
        <div className='form'>
          <Form>
            <div className='formRow'>
              <TextInput
                id='title'
                name='title'
                labelText='Alias'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.title || ''}
                invalid={this.state.errors.title ? true : false}
                invalidText={this.state.errors.title}
              />
            </div>
            <div className='formRow'>
              <TextInput
                id='firstName'
                name='firstName'
                labelText='First Name *'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.firstName || ''}
                invalid={this.state.errors.firstName ? true : false}
                invalidText={this.state.errors.firstName}
              />
            </div>
            <div className='formRow'>
              <TextInput
                id='middleName'
                name='middleName'
                labelText='Middle Name'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.middleName || ''}
                invalid={this.state.errors.middleName ? true : false}
                invalidText={this.state.errors.middleName}
              />
            </div>
            <div className='formRow'>
              <TextInput
                id='lastName'
                name='lastName'
                labelText='Last Name'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.lastName || ''}
                invalid={this.state.errors.lastName ? true : false}
                invalidText={this.state.errors.lastName}
              />
            </div>
            <div className='formRow'>
              <TextInput
                id='suffix'
                name='suffix'
                labelText='Suffix'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.suffix || ''}
                invalid={this.state.errors.suffix ? true : false}
                invalidText={this.state.errors.suffix}
              />
            </div>
          </Form>
        </div>
      </Modal>
    )
  }
}

export default graphql(CREATE_PARTY, {
  name: 'createParty',
  props: data => {
    return {
      createParty: party =>
        data.createParty({
          variables: party
        })
    }
  }
})(CreateProjectArtistModal)

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'

import { getUserFavouritesByType } from '../utils/selectors'
import GetUser from '../hocs/queries/GetUser'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import Breadcrumb from '../components/Breadcrumb'
import Hero from '../components/Hero'


import MyFiles from './MyFiles'

class MyFilesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldRerender: 'foo'

    }
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.props.uploadModal.open('/', '', null)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.state !== this.props.location.state && this.props.location.state) {
      this.props.uploadModal.open('/', '', null)
    }
  }
manualRerender = (value) => {
  this.setState({
    shouldRerender: `unel${Math.floor(Math.random() * 10)}`
  })
}
render() {
  const favourites = getUserFavouritesByType(this.props.user)

  return (
      <>
        <Helmet>
          <title>My Files</title>
        </Helmet>
        <main className='main files-share'>
          <Hero
            src='ph.hero.projects.jpg'
            alt='My Files'
            subtitle='My Files'
          >

            <Breadcrumb items={[
              {
                href: `/files`,
                name: 'Files'
              },
              {
                href: `/files/myfiles`,
                name: 'My Files'
              }
            ]} />
          </Hero>

          <MyFiles
            match={this.props.match}
            manualRerender={this.manualRerender}
            location={this.props.location}
            uploadModal={this.props.uploadModal}
            uploads={this.props.uploads}
            project={this.props.project}
            setProjectIdAndFolderId={this.props.uploads.actions.setProjectIdAndFolderId}
            fileFavourites={favourites.file || []}
            folderFavourites={favourites.folder || []}
            perPage={100}
          />

        </main>
      </>
  )
}
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser
)(MyFilesPage)

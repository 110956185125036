import React from 'react'
import { Query } from 'react-apollo'
import { GET_RECORDING } from '../../graphql/queries'

const GetRecording = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      if (!this.props.match.params.recordingId) {
        return <WrappedComponent {...this.props} />
      }

      return (
        <Query
          query={GET_RECORDING}
          variables={{ id: this.props.match.params.recordingId }}
          fetchPolicy={fetchPolicy}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            if (data.getRecording === null) {
              return <h1 style={{ color: 'white', textAlign: 'center' }}>404</h1>
            }

            return <WrappedComponent {...this.props} recording={data.getRecording} />
          }}
        </Query>
      )
    }
  }
}

export default GetRecording

export const GetRecordingRefreshed = WrappedComponent => {
  return GetRecording(WrappedComponent, 'network-only')
}

import React from 'react'
import { Modal } from 'carbon-components-react'
import DashboardModal from '@uppy/react/lib/DashboardModal'

import WithUploads from '../../hocs/WithUploads'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './UploadModal.scss'
import CloseIcon from '../../assets/images/cross-icon.svg'

let globalUploadModalCount = 0

export class UploadModal extends React.PureComponent {
  constructor(props) {
    super(props)

    this.uploadModalId = `uppy-dashboard${globalUploadModalCount}`
    globalUploadModalCount = globalUploadModalCount + 1
    this.closeModal = this.closeModal.bind(this)
    this.showModal = this.showModal.bind(this)
  }

  closeModal(e) {
    if (this.props.uploads.state.upgradeRequired) {
      this.props.uploads.actions.setUpgradeRequiredFlag(false)
    }
    this.props.onRequestClose(e)
  }

  showModal() {
    let uploadsCount = this.props.uploads.state.uploadIds.length
    let uploadModal = this.props.uploads.state.uploadModal
    return uploadModal && uploadsCount < 1
  }

  render() {
    const uppy = this.props.uploads.actions.getUppy()


    return (
      <Modal className='modal uploadModal'
        open={this.props.open && this.showModal()}
        passiveModal={true}
        onRequestClose={this.closeModal}
      >
        {this.props.open &&
          <div className='uploadModalContainer'>

            <div className='closeIcon'>
              <img src={CloseIcon} onClick={this.closeModal} alt='Close Icon' />
            </div>

            {
              !this.props.uploads.state.upgradeRequired && <div className='uploadModalContent'>
                <div className='titleContainer'>
                  <p className='uploadTitle'>Drag & Drop Files to Upload</p>
                  <p className='uploadSubTitle'>or select a file</p>
                </div>
              </div>
            }

            {(this.props.uploads.state.upgradeRequired &&
              ((this.props.projectId && this.props.isProjectOwner) || !this.props.projectId)) &&
              <p>Your account has no space left. Please upgrade your subscription to continue.</p>
            }
            {(this.props.uploads.state.upgradeRequired &&
              this.props.projectId && !this.props.isProjectOwner) &&
              <p>This account has no space left. Please contact the project owner to upgrade their subscription.</p>
            }
            {!this.props.uploads.state.upgradeRequired &&
              <DashboardModal uppy={uppy}
                id={this.props.id || this.uploadModalId}
                inline
                disableStatusBar
                proudlyDisplayPoweredByUppy={false}
                showSelectedFiles={false}
                locale={{
                  strings: {
                    dropPaste: '%{browse}',
                    browse: 'Choose File'
                  }
                }}
                plugins={['Dropbox', 'GoogleDrive']}
                width='100vw'
                height='150px'
              />
            }
          </div>
        }
      </Modal>
    )
  }
}

export default WithUploads(UploadModal)

import React from 'react'
import { graphql, compose } from 'react-apollo'
import {
  Form,
  TextInput,
  Modal,
  TextArea,
  DatePicker,
  DatePickerInput,
  InlineNotification,
  RadioButton,
  RadioButtonGroup
} from 'carbon-components-react'
import { DateTime } from 'luxon'

import { SHARE_PLAYLIST } from '../../graphql/mutations/share'
import Validator from '../../utils/Validator'
import ShareValidator from '../../validators/Share'
import copyLinkIcon from '../../assets/images/CopyLinkIcon.svg'
import WithAlertModal from '../../hocs/WithAlertModal'

const initialState = {
  files: [],
  emails: '',
  password: '',
  expiry: '',
  message: '',
  initialErrors: {},
  errors: {},
  requestError: null,
  working: false,
  shareMode: 'email',
  link: '',
  playlistId: ''
}

class CreateShareModal extends Validator {
  validator = ShareValidator
  state = {
    ...initialState,
    ...this.props.share,
    playlistId: this.props.playlistId,
    files: this.props.files,
    emails: this.props.share.users
      ? this.props.share.users
        .map((user) => user.email)
        .join(', ')
      : initialState.emails,
    expiry: this.props.share.expiry
      ? DateTime.fromSQL(this.props.share.expiry).toFormat('LL/dd/yyyy')
      : initialState.expiry
  }

  constructor(props) {
    super(props)
    this.handleExpiryChange = this.handleExpiryChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    this.props.sharePlaylist({
      "users": [],
      "password": this.state.password,
      "expiry":this.state.expiry,
      "message": this.state.message,
      "playlistId": this.state.playlistId
  })
      .then(response => {
        if (response.data.sharePlaylist.success) {
         
          this.setState({
            link:response.data.sharePlaylist.url || ''
          })

        } 
      })
  }

  // Save DatePicker final value to state
  handleExpiryChange(full, formatted) {
    const selected = new Date(formatted)
    const todayDate = new Date()
    if (selected.setHours(0, 0, 0, 0) >= todayDate.setHours(0, 0, 0, 0)) {
      this.setState({
        expiry: formatted,
        errors: {
          // eslint-disable-next-line no-undefined
          expiry: undefined
        }
      })
    } else {
      this.setState({
        expiry: '',
        errors: {
          expiry: 'You cannot set a past date.'
        }
      })
    }
  }

  // Called by Validator's handleSubmit()
  submit() {
    // Show user we're working
    this.setState({ working: true })

    // Assemble data for request
    const dataToSubmit = Object.assign(
      ...Object.keys(this.state)
        .filter(key => {
          // Remove unwanted properties
          if (![
            'playlistId',
            'emails',
            'expiry',
            'password',
            'message'
          ].includes(key) 
          ) {
            return false
          }
          return true
        })
        .map(key => {
          let value = this.state[key]
          // Format emails
          if (key === 'emails') {
            if(this.state.shareMode !== 'email'){
              return {users: []}
            }
            // This converts a list of comma-separated emails into an array of objects,
            // each with one property: email.
            value = value.split(',').map(s => ({ email: s.trim() }))
            // Easy to miss: this line is renaming the emails property key to users.
            // Because Mohsin named it users in the graphql query.
            return { users: value }
          }
          // Format expiry
          if (key === 'expiry') {
            value = DateTime.fromFormat(value, 'LL/dd/yyyy').toISODate() || initialState.expiry
          }
          // Format files
          if (key === 'files') {
            // Only include id and type properties
            value = value.map(file => ({
              id: file.id,
              type: file.type
            }))
          }
          return { [key]: value }
        })
    )
    // Request back-end
    if (this.state.shareMode === 'email') {
      this.props.sharePlaylist(dataToSubmit)
        .then(response => {
          if (response.data.sharePlaylist.success) {
            // Reset this share modal
            this.setState(initialState)
            // Expose success event to parent
            this.props.onSuccess(response.data.sharePlaylist)
            this.props.getType('email')
          } else {
            this.props.alert(
              'One or several folders selected cannot be shared or downloaded as they are empty')
          }
        })
      // Request fail
        .catch(requestError => {
          this.props.alert(
            'One or several folders selected cannot be shared or downloaded as they are empty')
          this.setState({
            working: false,
            requestError
          })
        })
    } else {
      this.props.sharePlaylist(dataToSubmit)
      // Request success
        .then(response => {
          if (response.data.sharePlaylist.success) {
            navigator.clipboard.writeText(response.data.sharePlaylist.url)
            this.props.alert(
              'A link to this file or folder has been copied to clipboard. You can now share it.')
            this.setState(initialState)
            this.props.onSuccess('link')
            // this.props.onSuccess(response.data.sharePlaylist.success)
            // this.props.getType('link')
          } else {
            this.props.alert(
              'One or several folders selected cannot be shared or downloaded as they are empty')
          }
        })
      // Request fail
        .catch(requestError => {
          this.props.alert(
            'One or several folders selected cannot be shared or downloaded as they are empty')
          this.setState({
            working: false,
            requestError
          })
        })
    }
  }

  primaryButtonText = this.state.working ? 'Sending' : 'Share'

  render() {
    const defaultExpiry = DateTime.local().plus({ days: 30 }).toISODate()
    const {
      expiry,
      errors,
      emails,
      shareMode,
      password,
      message,
      requestError,
      link
    } = this.state
    return (
      <Modal
        className='modal-scrollable share-modal modal-background share-playlist-modal'
        open={this.props.open}
        modalHeading='Share Playlist'
        primaryButtonText={this.primaryButtonText}
        passiveModal={shareMode === 'link'}
        secondaryButtonText='Cancel'
        shouldSubmitOnEnter={false}
        selectorPrimaryFocus='.bx--text-input'
        onRequestSubmit={this.handleSubmit}
        onRequestClose={this.props.onRequestClose}
      >
        <div className='form'>
          {/* <Form>
            <div className='formRow'>
              <div>
              <label htmlFor="share" className="bx--label">Share via</label>
              <RadioButtonGroup
                id='share'
                legendText='Radio button heading'
                name='radio-button-group'
                onChange={(val) => {
                  this.setState({
                    shareMode: val
                  })
                }}
                defaultSelected='email'>
                <RadioButton labelText='Email' value='email' id='email' />
                <RadioButton labelText='Link' value='link' id='link' />
              </RadioButtonGroup>
              </div>
              <div className='playlist-modal--datepicker'>
              <DatePicker
                id='expiry-date-picker'
                onChange={this.handleExpiryChange}
                datePickerType='single'
              >
                <DatePickerInput
                  id='expiry-input'
                  name='expiry'
                  labelText='Expiration Date'
                  placeholder='mm/dd/yyyy'
                  onChange={this.handleExpiryChange}
                  onBlur={this.handleDirty}
                  value={expiry || ''}
                  invalid={errors.expiry ? true : false}
                  invalidText={errors.expiry}
                />
              </DatePicker>
              </div>
            </div>
            {shareMode === 'email' ?
              <div className='formRow'>
                <TextInput
                  id='emails'
                  name='emails'
                  labelText='Share to Email *'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={emails || ''}
                  invalid={errors.emails ? true : false}
                  invalidText={errors.emails || ''}
                  autoComplete='off'
                  />
              </div> :
              <>
              <div className='formRow copy-link-row'>
                <TextInput
                  id='link'
                  name='link'
                  labelText='Link'
                  style={{ width: '94%' }}
                  disabled={true}
                  value={link || ''}
                />
                <div className='linkIcon' onClick={this.submit}>
                  <img src={copyLinkIcon} alt='icon' className='copy-link'/>
                </div>
              </div>
              <p className='link-expiration-text'>
              This link expires on { expiry ? expiry : `${defaultExpiry}, 30 days from now.`}
              </p>
              </>
            }
            <div className='formRow'>
              <TextInput
                id='password'
                name='password'
                type='password'
                labelText='Password'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={password || ''}
                invalid={errors.password ? true : false}
                invalidText={errors.password || ''}
                autoComplete='new-password'
              />
            </div>
            {shareMode === 'email' && <div className='formRow'>
              <TextArea
                id='message'
                name='message'
                labelText='Message'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={message || ''}
                invalid={errors.message ? true : false}
                invalidText={errors.message || ''} />
            </div>}
          </Form> */}
           <Form>
            <div className='formRow'>
              <p>Share via</p>
              <RadioButtonGroup
                legendText='Radio button heading'
                name='radio-button-group'
                onChange={(val) => {
                  this.setState({
                    shareMode: val
                  })
                }}
                defaultSelected='email'>
                <RadioButton labelText='Email' value='email' id='email' />
                <RadioButton labelText='Link' value='link' id='link' />
              </RadioButtonGroup>
            </div>
            {shareMode === 'email' ?
              <div className='formRow'>
                <TextInput
                  id='emails'
                  name='emails'
                  labelText='* Emails'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={emails || ''}
                  invalid={errors.emails ? true : false}
                  invalidText={errors.emails || ''}
                  autoComplete='off'
                />
              </div> :
              <>
              <div className='formRow' style={{ display: 'flex', flexDirection: 'row' }}>
                <TextInput
                  id='link'
                  name='link'
                  labelText='Link'
                  style={{ width: '94%' }}
                  disabled={true}
                  value={link || ''}
                />
                <div className='linkIcon' onClick={this.submit}>
                  <img src={copyLinkIcon} alt='icon' className='copy-link'/>
                </div>
              </div>
              <p className='link-expiration-text'>
              This link expires on { expiry ? expiry : `${defaultExpiry}, 30 days from now.`}
              </p>
              </>
            }
            <div className='formRow'>
              <DatePicker
                id='expiry-date-picker'
                onChange={this.handleExpiryChange}
                datePickerType='single'
              >
                <DatePickerInput
                  id='expiry-input'
                  name='expiry'
                  labelText='Expiration Date'
                  placeholder='mm/dd/yyyy'
                  onChange={this.handleExpiryChange}
                  onBlur={this.handleDirty}
                  value={expiry || ''}
                  invalid={errors.expiry ? true : false}
                  invalidText={errors.expiry}
                />
              </DatePicker>
            </div>
            <div className='formRow'>
              <TextInput
                id='password'
                name='password'
                type='password'
                labelText='Password'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={password || ''}
                invalid={errors.password ? true : false}
                invalidText={errors.password || ''}
                autoComplete='new-password'
              />
            </div>
            {shareMode === 'email' && <div className='formRow'>
              <TextArea
                id='message'
                name='message'
                labelText='Message'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={message || ''}
                invalid={errors.message ? true : false}
                invalidText={errors.message || ''} />
            </div>}
          </Form>
        </div>
        {requestError && (
          <InlineNotification
            kind='warning'
            title='Share failed.'
            subtitle='We weren&rsquo;t able to send your share request. Please try again later.'
            hideCloseButton={true}
          />
        )}
      </Modal>
    )
  }
}

export default compose(
  graphql(SHARE_PLAYLIST, {
    name: 'sharePlaylist',
    props: (data) => ({
      sharePlaylist: (variables) => data.sharePlaylist({ variables })
    })
  }),
  WithAlertModal
)(CreateShareModal)

import React from 'react'
import Helmet from 'react-helmet-async'
import { Query, compose } from 'react-apollo'

import { GET_PROJECTS } from '../graphql/queries'

import ProjectTileList from '../components/ProjectTileList'

import SectionContainer from '../components/SectionContainer'

import { getUserFavouritesByType } from '../utils/selectors'
import GetUser from '../hocs/queries/GetUser'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import Breadcrumb from '../components/Breadcrumb'
import Hero from '../components/Hero'


class AllProjectsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldRerender: 'foo'

    }
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.props.uploadModal.open('/', '', null)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.state !== this.props.location.state && this.props.location.state) {
      this.props.uploadModal.open('/', '', null)
    }
  }
manualRerender = (value) => {
  this.setState({
    shouldRerender: `unel${Math.floor(Math.random() * 10)}`
  })
}
render() {
  const favourites = getUserFavouritesByType(this.props.user)

  return (
      <>
        <Helmet>
          <title>Projects</title>
        </Helmet>
        <main className='main files-share'>
          <Hero
            src='ph.hero.projects.jpg'
            alt='Projects'
            subtitle='Projects'
            btnOnClick ={()=>{
              this.props.history.push('/projects/create')
            }}
            btnText= 'Add'
          >
            <Breadcrumb items={[
              {
                href: `/files`,
                name: 'Files'
              },
              {
                href: `/files/projects`,
                name: 'Projects'
              }
            ]} />
          </Hero>
          <div className='mainContainer project-tiles'>
            <SectionContainer>
              <Query query={GET_PROJECTS}
                fetchPolicy='cache-and-network'>
                {({ loading, error, data, refetch }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }

                  return (
                    <ProjectTileList projects={data.getProjects || []}
                      favourites={favourites.project || []}
                      showLoader={loading}
                      withOptions
                      onDelete={() => this.props.alert('The project has been successfully deleted.')}
                      user={this.props.user} />
                  )
                }}
              </Query>
            </SectionContainer>
          </div>
        </main>
      </>
  )
}
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser
)(AllProjectsPage)

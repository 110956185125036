import React from 'react'
import {
  Form,
  Button,
  TextInput
} from 'carbon-components-react'

import Validator from '../utils/Validator'
import ForgotPasswordValidator from '../validators/ForgotPassword'
import ResendVerificationEmailModal from './modals/ResendVerificationEmailModal'
import { DO_RESEND_VERIFICATION_EMAIL } from '../graphql/mutations'
import { graphql, compose } from 'react-apollo'

const initialState = {
  email: '',
  isOpen: true,
  showSuccess: false,

  initialErrors: {},
  errors: {}
}
class ForgotPasswordForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.validator = ForgotPasswordValidator
    this.handleSubmitResend = this.handleSubmitResend.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  submit() {
    this.props.onSubmit(this.state)
  }
  handleCancel() {
    this.setState({
      isOpen: false
    })
  }
  handleSubmitResend() {
    this.props
      .doResendVerificationEmail({
        variables: {
          email: this.state.email
        }
      })
      .then(() => {
        this.setState({
          ...this.state,
          isOpen: false,
          showSuccess: true
        })
      })
      .catch(() => {
        this.props.alert('Unable to resend verification email')
      })
  }

  render() {
    const doShow = this.state.showSuccess && !this.props.successMessage
    return (
      <Form onSubmit={this.handleSubmit} autoComplete='off' className='forgot-password'>
        {!doShow &&
        <div className='formRow mb-0'>
          <TextInput id='email'
            name='email'
            labelText='Email *'
            onChange={this.handleChange}
            onBlur={this.handleDirty}
            value={this.state.email || ''}
            invalid={this.state.errors.email || this.props.error ? true : false}
            invalidText={this.state.errors.email || ''} />
        </div>
        }
        {this.props.successMessage && !doShow && (
          <p className='success-message'>
            Please check your email to continue resetting your password.
          </p>
        )}
        {doShow && (
          <p className='success-message success-message-forget-password'>
            Please check your email to verify your account.
          </p>
        )}
        {!doShow &&
            <div className='formRow mb-0'>
              <Button type='submit' className='loginSubmit'>
                { this.props.loading ? 'Processing...' : 'Request Password Reset' }
              </Button>
            </div>
        }
        {!this.props.successMessage && this.props.message &&
          <ResendVerificationEmailModal
            isOpen={this.state.isOpen}
            handleCancel={this.handleCancel}
            handleSubmit={this.handleSubmitResend}
            message={this.props.message}
          />
        }
      </Form>
    )
  }
}

export default compose(
  graphql(DO_RESEND_VERIFICATION_EMAIL, { name: 'doResendVerificationEmail' })
)(ForgotPasswordForm)

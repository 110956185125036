import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    name: Field()
      .string()
      .required({ message: Errors.Required }),
    address1: Field()
      .string()
      .required({ message: Errors.Required }),
    address2: Field()
      .string(),
    city: Field()
      .string()
      .required({ message: Errors.Required }),
    state: Field()
      .string()
      .required({ message: Errors.Required }),
    zip: Field()
      .string()
      .required({ message: Errors.Required }),
    countryId: Field()
      .string()
      .required({ message: Errors.Required })
  },
  { verbose: true, async: true }
)

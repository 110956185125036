import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    name: Field()
      .string()
      .required({ message: Errors.Required }),
    description: Field()
      .string(),
    thumbnail: Field(),
    labelId: Field(),
    artistId: Field()
      .required({ message: Errors.Required }),
    number: Field()
      .required(),
  },
  { verbose: true, async: true }
)

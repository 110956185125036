import React from 'react'
import { Mutation, graphql, compose } from 'react-apollo'
import { Button } from 'carbon-components-react'
import { Elements, StripeProvider } from 'react-stripe-elements'

import GetPlatformImages from '../hocs/queries/GetPlatformImages'
import { DO_REGISTER, DO_RESEND_VERIFICATION_EMAIL } from '../graphql/mutations'

import AppBranding from '../components/AppBranding'
import RegistrationForm from '../components/RegistrationForm'
import WithAlertModal from '../hocs/WithAlertModal'

class Registration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      completed: false,
      email: '',
      resending: false,
      resent: false,
      tier: 'free'
    }

    this.resend = this.resend.bind(this)
  }
  updateImage=(tier)=>{
    this.setState({
      tier: tier
    })
  }
  resend() {
    if (this.state.resending) {
      return
    }

    this.setState({
      ...this.state,
      resending: true,
      resent: false
    })

    this.props
      .doResendVerificationEmail({
        variables: {
          email: this.state.email
        }
      })
      .then(() => {
        this.setState({
          ...this.state,
          resending: false,
          resent: true
        })
      })
      .catch(() => {
        this.props.alert('Unable to resend verification email')
      })
  }
  renderFeatures = (features)=>{
    return { __html: features }
  }

  render() {
    let buttonText = 'Resend Verification Email'
    if (this.state.resending) {
      buttonText = 'Resending Verification Email...'
    } else if (this.state.resent) {
      buttonText = 'Resent Verification Email!'
    }
    const [image] = this.props.platformImages
      .filter(i => i.location === 'registration' && i.tier === this.state.tier)
    const planName = image.name === 'Pro Unlimited' ? (<>
    <p className='pro'>Pro </p><p>Unlimited</p></>) : image.name
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <div className='login'>
          <div className='loginBackground'>
            <div className='test-wrapper'>
              <img src={image.fullPath} alt={image.altText} />
              <div className='text-overlay'>
                <div className='content-wrapper'>
                  <div className='header'>
                    <div className='header-plan'>
                      <p className='header-plan-name'>{image.name ? planName : ''}</p>
                      <p className='header-plan-price'>{image.price ? image.price : ''}</p>
                    </div>
                    <div className={`header-caption
                    ${image.name === 'Pro Unlimited' ? 'unlimited' : ''} `}>
                      <p>{image.use ? image.use : ''}</p>
                      <a
                        className='compare-plans loginSubmit register-submit'
                        target='_blank'
                        href='https://vevacollect.com/pricing'
                        rel='noopener noreferrer'>
                        Compare Plans
                      </a>
                    </div>
                  </div>
                  <div className='bullets'>
                    <div dangerouslySetInnerHTML={this.renderFeatures(image.features)} />
                  </div>
                </div>
              </div>
            </div>

            {image.caption && <div className='loginBackgroundCredit'>{image.caption}</div>}
          </div>
          <div className='loginFormBackground'>
            <div className='loginFormWrapper'>
              <AppBranding />
              {this.state.completed && (
                <>
                  <p
                    style={{
                      color: 'white'
                    }}
                  >
                    Please check your emails for your verification email.
                  </p>
                  <br />
                  <Button onClick={this.resend}>{buttonText}</Button>
                </>
              )}
              {!this.state.completed && (
                <>
                  <div className='loginForm'>
                    <Mutation
                      mutation={DO_REGISTER}
                    >
                      {(doRegister, { loading, error }) => (
                        <Elements>
                          <RegistrationForm
                            updateImage={this.updateImage}
                            onSubmit={state => {
                              let data = { ...state }
                              if (state.type === 'referral') {
                                data.referralCode = state.discountCode
                              } else {
                                data.coupon = state.discountCode
                              }
                              doRegister({
                                variables: data
                              })
                                .then(response => {
                                  if (!response.data.doRegister.success) {
                                    if (state.stripeToken) {
                                      if (state.discountCode) {
                                        this.props.alert('We could not create your account. Please check your card details/discount code and try again.', {
                                          title: 'Account creation failed.'
                                        })
                                      } else {
                                        this.props.alert('We could not create your account. Please check your card details and try again.', {
                                          title: 'Account creation failed.'
                                        })
                                      }
                                    } else {
                                      this.props.alert('We could not create your account. Please try again.', {
                                        title: 'Account creation failed.'
                                      })
                                    }
                                    return
                                  }

                                  this.setState({
                                    ...this.state,
                                    email: state.email,
                                    completed: true
                                  })
                                })
                                .catch(() => {
                                  //
                                })
                            }}
                            loading={loading}
                            errors={error}
                          />
                        </Elements>
                      )}
                    </Mutation>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </StripeProvider>
    )
  }
}

export default compose(
  WithAlertModal,
  GetPlatformImages,
  graphql(DO_RESEND_VERIFICATION_EMAIL, { name: 'doResendVerificationEmail' })
)(Registration)

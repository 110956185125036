import { createSelector } from 'reselect'
import { STORAGE_LIMITS } from './constants.js'

const getUserFavourites = user => user.favourites

export const getUserFavouritesByType = createSelector(
  getUserFavourites,
  favourites => {
    const favouritesByType = {}

    favourites.forEach(favourite => {
      if (!favouritesByType[favourite.resourceType]) {
        favouritesByType[favourite.resourceType] = []
      }
      favouritesByType[favourite.resourceType].push(favourite.resourceId)
    })

    return favouritesByType
  }
)

export const getUserStorageLimits = user => {
  const [subscription] = user.subscriptions
  const subscriptionPlan = subscription.stripePlan

  if (!STORAGE_LIMITS[subscriptionPlan]) {
    return {
      used: user.totalStorageUsedPretty,
      limit: 'Unlimited',
      deleted: user.deletedStorageUsedPretty,
      usedPercentage: '100%',
      deletedPercentage: '100%'
    }
  }

  const limitData = STORAGE_LIMITS[subscriptionPlan]

  return {
    used: user.totalStorageUsedPretty,
    deleted: user.deletedStorageUsedPretty,
    limit: limitData.label,
    usedPercentage: String(user.totalStorageUsedPercentage + '%'),
    deletedPercentage: String(user.deletedStorageUsedPercentage + '%')
  }
}

import React from 'react'
import { Form, Button, TextInput } from 'carbon-components-react'

import Validator from '../utils/Validator'
import DownloadShareValidator from '../validators/DownloadShare'

const initialState = {
  password: '',
  initialErrors: {},
  errors: {}
}

export default class PlaylistShareForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)
    this.validator = DownloadShareValidator
  }

  submit() {
    this.props.onSubmit(this.state)
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className='formRow'>
          <TextInput id='password'
            name='password'
            type='password'
            labelText='Password'
            onChange={this.handleChange}
            onBlur={this.handleDirty}
            value={this.state.password || ''}
            invalid={this.state.errors.password || this.props.errors ? true : false}
            invalidText={this.state.errors.password || ''} />
        </div>
        <div className='formRow'>
          <Button type='submit' className='loginSubmit'>
            { this.props.loading ? 'Loading...' : 'Show Playlist' }
          </Button>
        </div>
      </Form>
    )
  }
}

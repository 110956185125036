import React from 'react'
import { Query } from 'react-apollo'
import { GET_USER_CUSTOM_DOWNLOAD_PAGE_PUBLIC } from '../../graphql/queries'

const GetCustomDownloadPagePublic = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_USER_CUSTOM_DOWNLOAD_PAGE_PUBLIC} variables={{ uuid: this.props.match.params.uuid || this.props.match.params.token }} fetchPolicy={fetchPolicy}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            return <WrappedComponent {...this.props} refetchCustomImages={refetch} customImages={data.getUserCustomDownloadPagePublic} />
          }}
        </Query>
      )
    }
  }
}

export default GetCustomDownloadPagePublic

import React from 'react'
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react'
import { Link } from 'react-router-dom'
import { compose, graphql } from 'react-apollo'
import { DELETE_SONG } from '../graphql/mutations'

import { GetGradientFromId } from '../utils/constants'

import DetectEdgeTile from '../hocs/DetectEdgeTile'
import WithAlertModal from '../hocs/WithAlertModal'
import { withRouter } from 'react-router-dom'
import * as permissions from '../utils/permissions'
import GetUser from '../hocs/queries/GetUser'
import CreateUserFavourite from '../hocs/mutations/CreateUserFavourite'
import DeleteUserFavourite from '../hocs/mutations/DeleteUserFavourite'
import WhiteOutlineStarIcon from '../assets/images/white-outline-star.svg'
import OrangeFilledStarIcon from '../assets/images/favourite.svg'

class SongTile extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isFavourite: props.isFavourite,
      favouriteIcon: props.isFavourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon

    }

    if (!props.image) {
      let randGradient = GetGradientFromId(props.id)

      this.selectedGradient = {
        background: randGradient
      }
    }

    this.favHandler = this.favHandler.bind(this)
  }

  // Tags

  getTags=(permissions)=>{
    const tags = []
    const permissionsKyes = Object.keys(permissions)
    permissionsKyes.forEach(key =>{
      if (permissions[key] === true) {
        tags.push({
          tagName: key.charAt(0).toUpperCase() + key.slice(1)
        })
      }
    })
    return tags
  }

  permissionsApplied = (row = this.props)=>{
    const { recordings } = row
    const fullAccess = {
      create: true,
      update: true,
      read: true,
      delete: true,
      download: true,
      comments: true

    }
    if (recordings.length === 0) {
      return fullAccess
    }
    const projectIds = [...new Set(recordings.map(item => item.projectId))]
    if (this.props.userId === this.props.user.id) {
      return fullAccess
    }
    const AllPermissions = []
    projectIds.forEach(async item=>{
      const permission = permissions.can(item, 'song')
      if (Object.keys(permission).length > 0) {
        AllPermissions.push(permission)
      }
    })
    let getPermission = {}
    AllPermissions.forEach(item =>{
      if (Object.keys(getPermission).length === 0) {
        getPermission = { ...item }
      } else {
        if (getPermission.create === false) {
          getPermission.create = item.create
        }
        if (getPermission.update === false) {
          getPermission.update = item.update
        }
        if (getPermission.read === false) {
          getPermission.read = item.read
        }
        if (getPermission.download === false) {
          getPermission.download = item.download
        }
        if (getPermission.delete === false) {
          getPermission.delete = item.delete
        }
        if (getPermission.comments === false) {
          getPermission.comments = item.comments
        }
      }
    })
    return getPermission
  }


  favHandler() {
    if (this.props.disableFavourite) {
      return
    }

    this.setState({
      isFavourite: !this.state.isFavourite,
      favouriteIcon: !this.state.isFavourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon
    })

    if (this.state.isFavourite) {
      this.props.deleteUserFavourite(this.props.id, 'song')
    } else {
      this.props.createUserFavourite(this.props.id, 'song')
    }

    if (this.props.refetchSongs) {
      this.props.refetchSongs()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isFavourite !== prevProps.isFavourite) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        favouriteIcon: this.props.isFavourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon
      })
    }
  }

  render() {
    const isSongOwner = this.props.userId === this.props.user.id
    let getInitials = this.props.title ? this.props
      .title.toString().toUpperCase().replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g) : ''
    const initials = getInitials ? getInitials.join('').substr(0, 4) : ''

    const url = (this.props.generateUrl ?
      this.props.generateUrl(this.props) : `/songs/${this.props.id}`
    )

    const filesUrl = (this.props.generateUrl ?
      this.props.generateUrl(this.props) : `/songs/${this.props.id}/files#song`
    )


    let dynamicRoute = window.location.pathname === '/files/songs' ? filesUrl : url

    const tagName = isSongOwner ? 'Owner' : 'Collaborator'
    return (
      <div className='song'>
        {
          (this.props.image ? (
            <Link to={dynamicRoute} className='songArt'>
              <img src={process.env.REACT_APP_IMAGE_URL + 'song-images/' + this.props.image}
                alt={this.props.title} />
            </Link>
          ) : (
            <Link to={dynamicRoute} className='songCover' style={this.selectedGradient}>
              <span>{initials}</span>
            </Link>
          ))
        }

        <img onClick={this.favHandler}
          src={this.state.favouriteIcon}
          className='songTile-favorites'
          alt='favorites' />
        <div className='songInfoContainer'>
          <div className='songInfo'>
            <p className='song-tile-title'>
              {this.props.title}
            </p>
            <div className='tags-wrapper'>
              <span className='tag'>
                {tagName}
              </span>
            </div>
          </div>
          {this.props.withOptions &&
            <div className='songInfoOptions'>
              <OverflowMenu
                ariaLabel=''
                iconDescription=''
                flipped={this.props.edgeTile}
              >
                <OverflowMenuItem
                  itemText='View'
                  onClick={() => {
                    this.props.history.push(`/songs/${this.props.id}`)
                  }} />

                { isSongOwner && <OverflowMenuItem
                  itemText='Edit'
                  className ={this.permissionsApplied().create ? '' : 'item-ghosted'}
                  onClick={() => {
                    this.props.history.push(`/songs/${this.props.id}/edit`)
                  }} />
                }

                { isSongOwner &&
                  <OverflowMenuItem
                    itemText='Delete'

                    className ={this.permissionsApplied().create ? '' : 'item-ghosted'}
                    onClick={() => {
                      this.props.alert('Are you sure you want to permanently delete this song?', {
                        type: 'delete',
                        buttonText: 'Yes',
                        submitCallback: close => {
                          this.props.deleteSong({
                            variables: {
                              songId: this.props.id,
                              confirmed: true
                            },
                            update: this.props.refetchSongs
                          }).then((res) => {
                            if (!res.data.deleteSong.success) {
                              this.props.alert(`${res.data.deleteSong.message}`, {}, '', res.data.deleteSong.recordings)
                            } else {
                              this.props.alert('The song has been successfully deleted.')
                            }
                          })
                            .catch(() => {
                              this.props.alert('This song could not be deleted.')
                            })
                          close()
                        }
                      })
                    }} />
                }

              </OverflowMenu>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default compose(
  DetectEdgeTile,
  CreateUserFavourite,
  DeleteUserFavourite,
  WithAlertModal,
  withRouter,
  GetUser,
  graphql(DELETE_SONG, { name: 'deleteSong' })
)(SongTile)

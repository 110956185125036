import React from 'react'
import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell
} from 'carbon-components-react'
import { DateTime } from 'luxon'

export default function FileSharesListInModal({
  files,
  ...props
}) {
  const formatter = (expirationDate) => {
    if (!expirationDate) {
      return 'Never'
    }
    if (new Date(expirationDate) < new Date()) {
      return 'Expired'
    }
    return DateTime.fromSQL(expirationDate, { zone: 'utc' }).toISODate()
  }
  const usersFormatter = (users, row) => {
    // Compose "N files"
    const filesPhrase = row.files
      ? row.files.length
       + ' file' + (row.files.length === 1 ? '' : 's')
      : ''
    // Compose " shared to email@domain.com" or " shared to N emails"
    const emailsPhrase = users
      ? ' shared to '
       + (users.length === 1 ? users[0].email : users.length + ' emails')
      : ''
    // Display phrase
    return filesPhrase + emailsPhrase
  }
  return (
    <StructuredListWrapper {...props} className='file-list-in-modal'>
      <StructuredListHead>
        <StructuredListRow head>
          <StructuredListCell head>Details</StructuredListCell>
          <StructuredListCell head>Expiration</StructuredListCell>
        </StructuredListRow>
      </StructuredListHead>
      <StructuredListBody>
        {files.map((file, key) =>{
          return (
            <StructuredListRow key={key}>
              <StructuredListCell>{usersFormatter(file.users, file)}</StructuredListCell>
              <StructuredListCell>{formatter(file.expiry)}</StructuredListCell>
            </StructuredListRow>
          )
        })}
      </StructuredListBody>
    </StructuredListWrapper>
  )
}

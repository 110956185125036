import React from 'react'
import { Mutation } from 'react-apollo'
import { Modal } from 'carbon-components-react'

import { CREATE_PARTY } from '../../graphql/mutations'

import PartyMiniForm from '../PartyMiniForm'


class CreatePartyModal extends React.PureComponent {
  render() {
    return (
      <>
   {
     this.props.open && <Modal className='modal-scrollable create-party-modal'
       open={this.props.open}
       modalHeading='Create Party'
       passiveModal
       shouldSubmitOnEnter={true}
       selectorPrimaryFocus='.bx--text-input'
       onRequestClose={this.props.onRequestClose}>
       <div className='form'>
         <Mutation mutation={CREATE_PARTY} >
           {(createParty, { loading, error }) => (
             <PartyMiniForm
               onSubmit={(state) => {
                 let data = { ...state }
                 Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                 let variables = {
                   ...data
                 }
                 createParty({
                   variables
                 }).then(response => {
                   this.props.onPartyCreated(response.data.createParty)
                   // this.setState({ ...initialState })
                 }).catch(() => {
                   //
                 })
               }}
               loading={loading}
               onRequestClose= { this.props.onRequestClose }
               errors={error} />
           )}
         </Mutation>
       </div>
     </Modal>
   }
   </>
    )
  }
}

export default CreatePartyModal

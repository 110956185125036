import React from 'react'

export default class InfoGrid extends React.PureComponent {
  render() {
    const { gridClass } = this.props
    const contentClasses = (title)=> {
      if (title === 'Lyrics' || title === 'Notes') {
        return 'infoTextContent infoTextContent-secondary'
      }
      return 'infoTextContent'
    }
    return (
      <div className={`infoGrid ${gridClass}`}>
        {this.props.items.map((item, i) => (
          <div className={'infoText' + (item.requiresLargeContainer ? ' infoTextSpanDouble' : '')}
            key={item.title + i}>
            <h3 className='infoTextTitle'>{item.title}</h3>
            <div className={contentClasses(item.title)}>
              {!this.props.fullWidth ?
              <>
              {typeof item.content === 'string' && item.content.length > 70 ? item.content.slice(0, 70) + '...' : item.content}
              </>
                : <>{item.content} </>
              }
            </div>
          </div>
        ))}
      </div>
    )
  }
}

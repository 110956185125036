import React from 'react'
import { ReactComponent as Star } from '../assets/images/star.svg'
import { ReactComponent as StarIcon } from '../assets/images/star-icon-outline.svg'

const SaveToggle = React.memo(props => {
  let text = (props.favourite ? 'Remove from favorites' : 'Save to favorites')
  return (
    <button className={'itemSave' + (props.favourite ? ' itemSaved' : '')}
      onClick={props.onClick}>
      <div className={`hero-icon ${props.locked ? 'locked' : ''}`}>
        {props.favourite && <StarIcon />}
        {!props.favourite && <Star />}
      </div>
      {props.showText && text}
    </button>
  )
})

export default SaveToggle

import { Field, Schema } from 'v4f'

import * as Errors from '../utils/errors'

export default Schema(
  {
    otp: Field()
      .string()
      .required({ message: Errors.Required }),
  },
  { verbose: true, async: true }
)

import React from 'react'
import FilePreviewModal from '../components/FilePreviewModal'

const WithFilePreviewModal = (WrappedComponent) => {
  return class extends React.PureComponent {
    state = {
      open: false,
      fileId: null
    }

    constructor(props) {
      super(props)

      this.doOpen = this.doOpen.bind(this)
      this.doClose = this.doClose.bind(this)
    }

    doOpen(fileId) {
      this.setState({
        ...this.state,
        open: true,
        fileId
      })
    }

    doClose() {
      this.setState({
        ...this.state,
        open: false,
        fileId: null
      })
    }

    render() {
      return (
        <>
          <WrappedComponent {...this.props} filePreviewModal={{
            open: this.doOpen
          }} />
          <FilePreviewModal open={this.state.open}
            onRequestClose={this.doClose}
            id={this.state.fileId} />
        </>
      )
    }
  }
}

export default WithFilePreviewModal

import React from 'react'
import {
  Form,
  Modal
} from 'carbon-components-react'
import FileUploader from './FileUploader'
import WithAlertModal from '../hocs/WithAlertModal'

class ImportProjectRINModal extends React.PureComponent {
  state = {
    isImporting: false,
    rin: ''
  }

  constructor(props) {
    super(props)

    this.handleSelectFile = this.handleSelectFile.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onBeforeUnload = this.onBeforeUnload.bind(this)

    window.addEventListener('beforeunload', this.onBeforeUnload)
  }

  onBeforeUnload(e) {
    if (!this.state.isImporting) {
      return
    }

    e.preventDefault()
    e.returnValue = ''
  }

  handleSelectFile(e) {
    if (this.state.isImporting) {
      return
    }

    const file = e.target.files[0]

    this.setState({
      ...this.state,
      rin: file.name
    })
  }

  handleSubmit(e) {
    e.preventDefault()

    if (this.state.isImporting) {
      return
    }

    this.setState({
      ...this.state,
      isImporting: true
    })

    const file = this.uploader.current.target.files[0]
    if (!file) {
      return
    }

    const formData = new FormData()
    formData.append('rin', file, file.name)
    formData.append('rin', file, file.name)

    fetch(`${process.env.REACT_APP_API}/api/rin/import`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('authToken')
      },
      body: formData
    })
      .then(response => response.json())
      .then(() => {
        window.location.reload()
      }).catch(() => {
        this.setState({
          ...this.state,
          isImporting: true
        })

        this.props.alert('There was a problem importing this RIN file.')
      })
  }

  render() {
    return (
      <Modal className='modal'
        open={this.props.open}
        modalHeading='Import RIN'
        primaryButtonText={this.state.importing ? 'Importing...' : 'Import'}
        secondaryButtonText='Cancel'
        shouldSubmitOnEnter={true}
        selectorPrimaryFocus='.bx--btn--primary'
        onRequestSubmit={this.handleSubmit}
        onRequestClose={() => {
          if (this.state.isImporting) {
            this.props.alert('Please wait while we import your RIN file.')
            return
          }
          this.props.onRequestClose()
        }}>
        <div className='form'>
          <Form>
            <FileUploader
              ref={ref => {this.uploader = ref}}
              disabled={this.state.isImporting}
              labelTitle='Valid RIN XML only'
              buttonLabel='Select file'
              filenameStatus={this.state.isImporting ? 'uploading' : 'edit'}
              accept={['.xml']}
              name='rin'
              value={this.state.rin}
              onClick={e => {
                if (this.state.isImporting) {
                  return
                }

                if (!e.target.classList.contains('bx--visually-hidden')) {
                  return
                }

                this.uploader.clearFiles()

                this.setState({
                  ...this.state,
                  rin: ''
                })
              }}
              onChange={this.handleSelectFile}
            />
          </Form>
        </div>
      </Modal>
    )
  }
}

export default WithAlertModal(ImportProjectRINModal)

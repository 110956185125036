import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { ASSIGN_MULTIPLE_CREDITS } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import GetSession from '../../hocs/queries/GetSession'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import CreditForm from '../../components/CreditForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class SessionCreditCreate extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Create Credit | {this.props.project.name} | {this.props.session.name}
          </title>
        </Helmet>
        <main className='main'>
          <CreatePagesHeader
            title={`Assign New Session Credit to ${this.props.session.name}`}
            subtitle='Session Credit'>
            <Breadcrumb
              items={[
                {
                  href: `/projects`,
                  name: 'Projects'
                },
                {
                  href: `/projects/${this.props.project.id}`,
                  name: this.props.project.name
                },
                {
                  href: `/projects/${this.props.project.id}/sessions`,
                  name: 'Sessions'
                },
                {
                  href: `/projects/${this.props.project.id}/sessions/${this.props.session.id}`,
                  name: this.props.session.name
                },
                {
                  href: `/projects/${this.props.project.id}/sessions/${
                    this.props.session.id
                  }/credits/create`,
                  name: 'New Session Credit'
                }
              ]}
            />

          </CreatePagesHeader>

          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={ASSIGN_MULTIPLE_CREDITS}
                update={() => {
                  this.props.history.push(
                    `/projects/${this.props.project.id}/sessions/${
                      this.props.session.id
                    }#credits`
                  )
                }}
              >
                {(createCredit, { loading, error }) => (
                  <CreditForm
                    projectId={this.props.project.id}
                    discardForm={true}
                    contributionType='session'
                    contribution={{
                      id: this.props.session.id,
                      name: this.props.session.name
                    }}
                    isMulti={true}
                    fromSessionCreditCreate={true}
                    onSubmit={state => {
                      let data = { ...state }
                      Object.keys(data).forEach(key => data[key] === '' && (data[key] = null))
                      createCredit({
                        variables: {
                          ...data,
                          performing: data.performing ? true : false
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error}
                  />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetSession
)(SessionCreditCreate)

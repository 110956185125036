import React from 'react'
import { UploadsConsumer } from '../contexts/Uploads'

const WithUploads = (WrappedComponent) => {
  return (props) => (
    <UploadsConsumer>
      {context => (
        <WrappedComponent {...props} uploads={context} />
      )}
    </UploadsConsumer>
  )
}

export default WithUploads

import React from 'react'
import Helmet from 'react-helmet-async'
import CreatePagesHeader from '../components/CreatePagesHeader'
import Customization from './Customization/Customization'
import MenuSwitcher from '../components/MenuSwitcher'

const CustomizationPage = () => {
  return (
    <>
      <Helmet>
        <title>Customization</title>
      </Helmet>
      <main className='main account-customization'>
        <CreatePagesHeader subtitle='Customization'>
          <MenuSwitcher />
        </CreatePagesHeader>
        <Customization />
      </main>
    </>
  )
}

export default CustomizationPage

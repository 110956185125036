import React from 'react'
import { memo } from 'react'

const ProgressBar = (props) => {
  const { value, progressBarStyle } = props
  return (
    <div className='progressBar'>
      <div className={`currentProgress ${progressBarStyle}`} style={{ width: `${value}%` }} />
    </div>
  )
}

export default memo(ProgressBar)

import React from 'react'

// We're commandeering Carbon's classes here insead of using their package
// because the version in this app at the moment (carbon-components-react 6.62.2)
// uses a <button /> for the tooltip, which submits a form, if it's in a form.
// Which is unacceptable. Hopefully, in time, we can update Carbon and make this better.
export default function Tooltip({
  term = '',
  definition = '',
  info = false,
}) {
  return (
    <span className='bx--tooltip--definition'>
      <span className={`bx--tooltip__trigger ${info ? 'tooltip--icon' : ''}`}>
        { term }
      </span>
      <span className={`bx--tooltip--definition__bottom ${info ? 'bx--tooltip--icon' : ''}`}>
        <span className={`bx--tooltip__caret ${info ? 'bx--tooltip__caret-icon' : ''}`} />
        { definition }
      </span>
    </span>
  )
}

import React from 'react'
import { withRouter } from 'react-router-dom'
import Tether from 'tether';
import {
  Form,
  Button,
  DatePicker,
  DatePickerInput,
  TextInput,
  TextArea
} from 'carbon-components-react'
import { DateTime } from 'luxon'

import ApolloClient from '../utils/Apollo'
import { SelectASync } from '../components/Select'
import { GET_SONG_TYPES } from '../graphql/queries'
import Validator from '../utils/Validator'
import SongValidator from '../validators/Song'
import Tooltip from '../components/ui/Tooltip'
import definitions from '../utils/definitions.json'
import UploadFileIcon from './UploadFileIcon'
import DeleteFileIcon from './DeleteFileIcon'
import DiscardModal from './modals/DiscardModal'
import { GetGradientFromId } from '../utils/constants'

// Hardcoded id and label because can't get it to pull them into `defaultValue`
// when getSongTypeOptions resolves.
const defaultSongType = { value: '', label: '' }
let tetherInstance = null

const initialState = {
  songTypeId: defaultSongType.value,
  songTypeName: defaultSongType.label,
  songType: defaultSongType,
  songTypeUserDefinedValue: '',

  iswc: '',
  title: '',
  subtitle: '',

  titleAlt: '',
  subtitleAlt: '',

  createdOn: '',

  description: '',
  lyrics: '',
  notes: '',

  initialErrors: {},
  errors: {},

  userDefinedSongTypes: []
}

class SongForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.handleSongTypeChange = this.handleSongTypeChange.bind(this)
    this.handleCreatedOnChange = this.handleCreatedOnChange.bind(this)
    this.getSongTypeOptions = this.getSongTypeOptions.bind(this)

    this.validator = SongValidator

    this.state.songTypeId = props.type ? props.type.id : defaultSongType.value
    this.state.songTypeName = props.type ? props.type.name : defaultSongType.label
    this.state.songTypeUserDefinedValue = props.typeUserDefinedValue || ''
    if (this.state.songTypeId) {
      this.state.songType = {
        value: this.state.songTypeId,
        label: this.state.songTypeName
      }
    }

    this.state.iswc = props.iswc || ''
    this.state.title = props.title || ''
    this.state.subtitle = props.subtitle || ''
    this.state.titleAlt = props.titleAlt || ''
    this.state.subtitleAlt = props.subtitleAlt || ''
    this.state.createdOn = props.createdOn ?
      DateTime.fromSQL(props.createdOn).toFormat('LL/dd/yyyy') : ''
    this.state.description = props.description || ''
    this.state.lyrics = props.lyrics || ''
    this.state.notes = props.notes || ''
    this.state.image = props.image || ''
    this.state.isUploading = false
    this.state.showDiscardModal = false
    this.state.gradientBg = GetGradientFromId(this.props.id)
    this.state.isFormChange = false
    this.handleOpen = this.handleOpen.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  submit() {
    this.props.onSubmit(this.state)
  }

  handleSongTypeChange(option) {
    this.setState({
      ...this.state,
      songTypeId: option.value,
      songType: option,
      errors: {
        songTypeId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleCreatedOnChange(full, formatted) {
    this.setState({
      ...this.state,
      createdOn: formatted,
      errors: {
        createdOn: undefined // eslint-disable-line no-undefined
      }
    })
    this.handleBlur()
  }

  getSongTypeOptions = inputValue =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_SONG_TYPES,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let songTypes = []
        let userDefinedSongTypes = []
        data.getSongTypes.forEach(songType => {
          if (!songType.name.toLowerCase().includes(inputValue.toLowerCase())) {
            return
          }

          if (songType.userDefined) {
            userDefinedSongTypes.push(songType.id)
          }

          songTypes.push({
            value: songType.id,
            label: songType.name
          })
        })

        this.setState({
          ...this.state,
          userDefinedSongTypes
        })

        resolve(songTypes)
      })
    })

  uploadImage = async (e) => {
    // setImgError('');
    if (e.target.files.length < 1) {
      return
    }
    if (this.state.isUploading) {
      return
    }
    this.setState({ isUploading: true })

    const file = e.target.files[0]
    // const canUpload = await handleImgDimension(file)
    // if (!canUpload) {
    //   setImgError('Image width must be greater than equal to 1200px')
    //   setIsUploading(false)
    //   return
    // }

    const formData = new FormData()
    formData.append('file', file, file.name)
    fetch(`${process.env.REACT_APP_API}/api/song-thumbnail-upload`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('authToken')
      },
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        this.setState({ image: response.name })
        setTimeout(() => {
          this.setState({ isUploading: false })
        }, 2000)
      })
  }

  removeImage = () => {
    this.setState({ image: null })
  }

  discardChanges = () => {
    this.setState({ isFormChange: false })
    const newState = {}
    newState.songTypeId = this.props.type ? this.props.type.id : defaultSongType.value
    newState.songTypeName = this.props.type ? this.props.type.name : defaultSongType.label
    newState.songTypeUserDefinedValue = this.props.typeUserDefinedValue || ''
    if (newState.songTypeId) {
      newState.songType = {
        value: newState.songTypeId,
        label: newState.songTypeName
      }
    }

    newState.iswc = this.props.iswc || ''
    newState.title = this.props.title || ''
    newState.subtitle = this.props.subtitle || ''
    newState.titleAlt = this.props.titleAlt || ''
    newState.subtitleAlt = this.props.subtitleAlt || ''
    newState.createdOn = this.props.createdOn ?
      DateTime.fromSQL(this.props.createdOn).toFormat('LL/dd/yyyy') : ''
    newState.description = this.props.description || ''
    newState.lyrics = this.props.lyrics || ''
    newState.notes = this.props.notes || ''
    newState.image = this.props.image || ''
    newState.isUploading = false
    newState.showDiscardModal = false
    this.setState(newState)
    this.props.history.goBack()
  }

  getInitials() {
    const [firstWord, secondWord] = (this.props.title || '').split(' ')
    let initials = ''
    if (firstWord.length > 0) {
      initials = initials + firstWord[0]
    }
    if ((secondWord || '').length > 0) {
      initials = initials + secondWord[0]
    }
    return initials
  }

  handleOpen() {
    let ele = document.querySelector('#createdOn-input')
    tetherInstance = new Tether({ 
      element: ele._flatpickr.calendarContainer,
      target: ele, 
      attachment: 'top left', 
      targetAttachment: 'bottom left', 
      constraints: [
        {
          to: 'window',
          attachment: 'together'
        }
      ] 
    })
  }

  handleBlur() {
    if (tetherInstance) {
      tetherInstance.destroy()
      tetherInstance.element.style = ''
      tetherInstance.element.classList.remove('arrowTop')
      tetherInstance.element.classList.remove('arrowBottom')
      tetherInstance.element.classList.remove('open')
    }
  }

  onDataChange(e, handleChange) {
    handleChange(e)
    this.setState({ isFormChange: true })
  }
  render() {
    return (
      <div className='form'>
        <Form onSubmit={this.handleSubmit} autoComplete='off'>
          <div className='formRow'>
            <TextInput id='title'
              name='title'
              labelText='Title *'
              onChange={(e)=> this.onDataChange(e, this.handleChange)}
              onBlur={this.handleDirty}
              value={this.state.title || ''}
              invalid={this.state.errors.title ? true : false}
              invalidText={this.state.errors.title || ''} />
          </div>
          {!this.props.hideUploadImage && <div className='formRow img-upload-section'>
            {this.state.image ? (
              <img
                src={process.env.REACT_APP_IMAGE_URL + 'song-images/' + this.state.image}
                alt=''
                className='song-img' />
            ) : (
              <div
                className='projectHeroThumbnail song-img-placeholder'
                style={{ background: this.state.gradientBg }}
              >
                <span className='projectCover'>{this.getInitials(this.props.title)}</span>
              </div>
            )}
            <div className='actions-container'>
              {this.state.image && (
                <DeleteFileIcon onClick={this.removeImage} />
              )}
              <UploadFileIcon onClick={this.uploadImage} loading={this.state.isUploading} />
            </div>
          </div>}
          <div className='formRow'>
            <TextInput id='subtitle'
              name='subtitle'
              labelText='Subtitle'
              onChange={(e)=> this.onDataChange(e, this.handleChange)}
              onBlur={this.handleDirty}
              value={this.state.subtitle || ''}
              invalid={this.state.errors.subtitle ? true : false}
              invalidText={this.state.errors.subtitle || ''} />
          </div>
          <div className='formRow'>
            <SelectASync
              key={this.state.songType.value}
              labelText='Type *'
              id='songTypeId'
              cacheOptions
              defaultOptions
              loadOptions={this.getSongTypeOptions}
              onChange={this.handleSongTypeChange}
              placeholder={'Select from list...'}
              value={this.state.songType.value ? this.state.songType : ''}
              invalid={this.state.errors.songTypeId ? true : false}
              invalidText={this.state.errors.songTypeId} />
          </div>
          <div className='formRow'>
            <TextInput id='iswc'
              name='iswc'
              labelText={<Tooltip term={'ISWC'} definition={definitions.ISWC} />}
              onChange={(e)=> this.onDataChange(e, this.handleChange)}
              onBlur={this.handleDirty}
              value={this.state.iswc || ''}
              invalid={this.state.errors.iswc ? true : false}
              invalidText={this.state.errors.iswc || ''} />
          </div>
          {this.state.userDefinedSongTypes.indexOf(this.state.songType.value) > -1 &&
            <div className='formRow'>
              <TextInput id='songTypeUserDefinedValue'
                name='songTypeUserDefinedValue'
                labelText='Type Name'
                onChange={(e)=> this.onDataChange(e, this.handleChange)}
                onBlur={this.handleDirty}
                value={this.state.songTypeUserDefinedValue || ''}
                invalid={this.state.errors.songTypeUserDefinedValue ? true : false}
                invalidText={this.state.errors.songTypeUserDefinedValue || ''} />
            </div>
          }
          <div className='formRow'>
          <label className='bx--label'>Created On</label>
            <DatePicker id='createdOn-date-picker'
              onFocus={this.handleOpen}
              onBlur={this.handleBlur}
              onChange={this.handleCreatedOnChange}
              datePickerType='single'>
              <DatePickerInput
                id='createdOn-input'
                name='createdOn'
                placeholder='mm/dd/yyyy'
                onChange={this.handleCreatedOnChange}
                onBlur={this.handleDirty}
                value={this.state.createdOn || ''}
                invalid={this.state.errors.createdOn ? true : false}
                invalidText={this.state.errors.createdOn} />
            </DatePicker>
          </div>
          <div className='formRow'>
            <TextInput id='titleAlt'
              name='titleAlt'
              labelText='Alternative Title'
              onChange={(e)=> this.onDataChange(e, this.handleChange)}
              onBlur={this.handleDirty}
              value={this.state.titleAlt || ''}
              invalid={this.state.errors.titleAlt ? true : false}
              invalidText={this.state.errors.titleAlt || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='subtitleAlt'
              name='subtitleAlt'
              labelText='Alternative Subtitle'
              onChange={(e)=> this.onDataChange(e, this.handleChange)}
              onBlur={this.handleDirty}
              value={this.state.subtitleAlt || ''}
              invalid={this.state.errors.subtitleAlt ? true : false}
              invalidText={this.state.errors.subtitleAlt || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='notes'
              name='notes'
              labelText='Notes'
              onChange={(e)=> this.onDataChange(e, this.handleChange)}
              onBlur={this.handleDirty}
              value={this.state.notes || ''}
              invalid={this.state.errors.notes ? true : false}
              invalidText={this.state.errors.notes || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='description'
              name='description'
              labelText='Description'
              onChange={(e)=> this.onDataChange(e, this.handleChange)}
              onBlur={this.handleDirty}
              value={this.state.description || ''}
              invalid={this.state.errors.description ? true : false}
              invalidText={this.state.errors.description || ''} />
          </div>
          <div className='formRow'>
            <TextArea id='lyrics'
              name='lyrics'
              labelText='Lyrics'
              onChange={(e)=> this.onDataChange(e, this.handleChange)}
              onBlur={this.handleDirty}
              value={this.state.lyrics || ''}
              invalid={this.state.errors.lyrics ? true : false}
              invalidText={this.state.errors.lyrics || ''} />
          </div>
          <div className='formRow btns-container'>
            <Button
              onClick={() => {
                if (this.state.isFormChange) {
                  this.setState({ showDiscardModal: true })
                } else {
                  this.props.history.goBack()
                }
              }}
              type='button'
              kind='secondary'
            >
              Cancel
            </Button>
            <Button type='submit'>
              {this.props.id ? this.props.loading ? 'Saving...' : 'Submit' :
                this.props.loading ? 'Creating...' : 'Create' }
            </Button>
          </div>
        </Form>
        <DiscardModal
          open={this.state.showDiscardModal}
          onRequestClose={() => this.setState({ showDiscardModal: false })}
          modalHeading='Discard Changes?'
          handleSubmit={this.discardChanges}
        />
      </div>
    )
  }
}

export default withRouter(SongForm)
import React from 'react'
import { Loading, DataTable } from 'carbon-components-react'
import { ReactComponent as FolderIcon } from '../assets/images/folder.svg'

import SongTile from './SongTile'

const {
  TableCell,
  Table,
  TableRow,
  TableBody
} = DataTable

const SongTileList = React.memo(props => {
  if (props.showLoader) {
    return <Loading withOverlay={false} className='bx--loading--center' />
  }

  if (!props.songs.length) {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className='empty-row'>
              <FolderIcon /> <span>No Songs</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <div className='songTileContainer'>
      {props.songs.map((song, index) => (
        <SongTile
          {...song}
          favouritePage={props.favouritePage}
          favourite={song.isFavourite}
          refetchSongs={props.refetch}
          user={props.user}
          userId={song.user && song.user.id ? song.user.id : song.userId}
          index={index}
          key={song.id}
          generateUrl={props.generateUrl}
          withOptions={props.withOptions}
          onDelete={props.onDelete}
          showFileCount={props.showFileCount || false}
          showArtist={props.showArtist || false}
          disableFavourite={props.disableFavourite}
          showFavouriteIcon={props.showFavouriteIcon}
        />
      ))}
    </div>
  )
})

export default SongTileList

import { graphql } from 'react-apollo'
import { DELETE_USER_FAVOURITE } from '../../graphql/mutations'
import { GET_USER } from '../../graphql/queries'

export default graphql(DELETE_USER_FAVOURITE, {
  name: 'deleteUserFavourite',
  props: (data) => {
    return {
      deleteUserFavourite: (resourceId, resourceType) => {
        data.deleteUserFavourite({
          variables: {
            resource_type: resourceType.toLowerCase(),
            resource_id: parseInt(resourceId, 10)
          },
          // optimisticResponse: {
          //   __typename: 'Mutation',
          //   deleteUserFavourite: {
          //     resourceId: parseInt(resourceId, 10),
          //     resourceType: resourceType.toLowerCase(),
          //     __typename: 'UserFavourite'
          //   }
          // },
          update: (cache) => {
            const { getUser } = cache.readQuery({ query: GET_USER })
            const user = { ...getUser }
            user.favourites = user.favourites.filter(favourite =>
              !(favourite.resourceId === parseInt(resourceId, 10) &&
                favourite.resourceType === resourceType
              ))
            cache.writeQuery({
              query: GET_USER,
              data: {
                getUser: user
              }
            })
          }
        })
      }
    }
  }
})

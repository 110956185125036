import React from 'react'
import { ToastNotification } from 'carbon-components-react'
import CreateShareModal from '../components/modals/CreateShareModal'
import UpdateShareModal from '../components/modals/UpdateShareModal'
import ApolloClient from '../utils/Apollo'
import { GET_SHARES } from '../graphql/queries/share'

const initialState = {
  open: false,
  files: [],
  projectId: null,
  folderId: null,
  onSuccess: null,
  type: null,
  playlistTitle: null
}

const WithShareModal = WrappedComponent => {
  return class extends React.PureComponent {
  state = initialState

  constructor(props) {
    super(props)

    this.state.projectId = props.projectId
    this.state.folderId = props.folderId

    this.doOpen = this.doOpen.bind(this)
    this.doClose = this.doClose.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.hideSuccessToast = this.hideSuccessToast.bind(this)
    this.handleGetShare = this.handleGetShare.bind(this)
    this.getType = this.getType.bind(this)
  }

  getType = type => {
    this.setState({ type })
  }

  doOpen(action, {
    files = [],
    share = {},
    onSuccess = null
  },
  onCancel
  ) {
    // So function call can just pass share
    if (files.length < 1 && !!share.files) {
      files = share.files
    }

    let playlistTitle = share.sharePlaylists && share.sharePlaylists.length > 0 ? share.sharePlaylists[0].playlist.title : ''
    this.setState({
      ...this.state,
      playlistTitle: playlistTitle,
      open: true,
      action,
      files,
      share,
      onSuccess,
      onCancel
    })
  }

  doClose() {
    this.setState(initialState)
  }

  onSuccess() {
    if (this.state.onSuccess) {
      this.state.onSuccess()
    }
    if (this.state.action === 'create') {
      this.setState({
        // Reset and hide modal
        ...initialState,
        // Show success feedback to user
        successToastIsVisible: true
      })
      this.handleGetShare()
      this.getType()
    } else {
      // this.doClose()
    }
  }

  hideSuccessToast() {
    this.setState({
      successToastIsVisible: false
    })
  }
     handleGetShare = () =>
       new Promise(resolve => {
         ApolloClient.query({
           query: GET_SHARES,
           fetchPolicy: 'network-only'
         }).then(({ data }) => {
           let shares = []
           data.getShares.forEach(sharesList => {
             shares.push({ sharesList })
           })
           resolve(shares)
         })
       })

    getToastTitle() {
      const {type} = this.state;
      let toastTitle = 'Share invite sent'
      if (type === 'link') toastTitle = 'File link copied'
      else if (type === 'email') toastTitle = 'Share Sent!'
      return toastTitle;
    }
     render() {
       const ShareModal = this.state.action === 'update'
         ? UpdateShareModal
         : CreateShareModal
       return (
      <>
        <WrappedComponent
          {...this.props}
          shareModal={{
            open: this.doOpen,
            close: this.doClose
          }}
        />
        {this.state.open &&
          <ShareModal
            refetch={this.props.refetch}
            files={this.state.files}
            share={this.state.share}
            open={this.state.open}
            onRequestClose={this.doClose}
            onSuccess={this.onSuccess}
            getType={this.getType}
            onCancel={this.state.onCancel}
            playlistTitle={this.state.playlistTitle}
          />
        }
        { this.state.successToastIsVisible && (
          <ToastNotification
            kind='success'
            title={this.getToastTitle()}
            subtitle=''
            caption=''
            onCloseButtonClick={ ()=> this.hideSuccessToast() }
            timeout={6000}
          />
        ) }
      </>
       )
     }
  }
}

export default WithShareModal

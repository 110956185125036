import React from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from 'react-apollo'

import { DO_LOGIN, DO_TWO_FACTOR } from '../graphql/mutations'

import GetPlatformImages from '../hocs/queries/GetPlatformImages'
import AppBranding from '../components/AppBranding'
import LoginForm from '../components/LoginForm'
import Login2FAForm from '../components/Login2FAForm'
import Footer from '../components/Footer'
import BackgroundImage from '../assets/images/veva-collect-default-download-image.jpg'

class Login extends React.PureComponent {
  state = {
    requires2FA: false,
    token2FA: ''
  }

  render() {
    const [image] = this.props.platformImages.filter(i => i.location === 'login')

    return (
      <div className='login'>
        <div className='loginFormBackground'>
          <div className='loginFormWrapper'>
            <AppBranding />
            <div className='loginForm'>
              {this.state.requires2FA && (
                <Mutation
                  mutation={DO_TWO_FACTOR}
                  update={(cache, { data: { doTwoFactor } }) => {
                    localStorage.setItem('authToken', doTwoFactor.accessToken)

                    cache.writeData({
                      data: {
                        authStatus: {
                          __typename: 'authStatus',
                          status: 'loggedIn'
                        }
                      }
                    })

                    if (this.props.location.pathname === '/login') {
                      this.props.history.push('/')
                    }
                  }}
                >
                  {(doTwoFactor, { loading, error }) => (
                    <Login2FAForm
                      onSubmit={state => {
                        doTwoFactor({
                          variables: {
                            code: state.code,
                            token: this.state.token2FA
                          }
                        }).catch(() => {
                          //
                        })
                      }}
                      loading={loading}
                      errors={error}
                    />
                  )}
                </Mutation>
              )}
              {!this.state.requires2FA && (
                <>
                  <Mutation
                    mutation={DO_LOGIN}
                    update={(cache, { data: { doLogin } }) => {
                      if (doLogin.twoFactor) {
                        return this.setState({
                          requires2FA: true,
                          token2FA: doLogin.accessToken
                        })
                      }

                      localStorage.setItem('authToken', doLogin.accessToken)

                      cache.writeData({
                        data: {
                          authStatus: {
                            __typename: 'authStatus',
                            status: 'loggedIn'
                          }
                        }
                      })

                      if (this.props.location.pathname === '/login') {
                        this.props.history.push('/')
                      }
                      return null
                    }}
                  >
                    {(doLogin, { loading, error }) => (
                      <LoginForm
                        onSubmit={state => {
                          doLogin({
                            variables: state
                          }).catch(() => {
                            //
                          })
                        }}
                        loading={loading}
                        errors={error}
                      />
                    )}
                  </Mutation>
                  <div className='links-container'>
                    <Link to='/register' className='forgotPasswordLink'>
                      Register for a free account
                    </Link>
                    <Link to='/forgot-password' className='forgotPasswordLink'>
                      Forgot Password?
                    </Link>
                  </div>
                  <Footer/>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='loginBackground'>
          <img src={BackgroundImage} alt="Login Page"/>
          {image.caption && <div className='loginBackgroundCredit'>{image.caption}</div>}
        </div>
      </div>
    )
  }
}

export default GetPlatformImages(Login)

import React from 'react'
import { Form, Button, TextInput, TextArea } from 'carbon-components-react'

// import uuidv4 from '@bundled-es-modules/uuid/v4.js'
import ApolloClient from '../utils/Apollo'
import { SelectASync } from '../components/Select'

import { GET_PARTIES } from '../graphql/queries'

import Validator from '../utils/Validator'
import ProjectValidator from '../validators/Project'
import { sortParties } from '../utils/sortParties'
import { generateRandomNumber } from '../utils/functions'

import CreateRecordLabelModal from './CreateRecordLabelModal'
import CreateProjectArtistModal from './CreateProjectArtistModal'
import UploadFileIcon from './UploadFileIcon'
import DeleteFileIcon from './DeleteFileIcon'
import { GetGradientFromId } from '../utils/constants'
import DiscardModal from './modals/DiscardModal'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router'

const initialState = {
  name: '',
  description: '',

  labelId: null,
  labelName: '',
  label: {},

  artistId: '',
  artistName: '',
  artist: {},

  initialErrors: {},
  errors: {},

  isUploading: false,
  uploadComplete: false,

  recordLabelSelectVisible: true,
  projectArtistSelectVisible: true,
  showDiscardModal: false
}

class ProjectForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.getPartyOptions = this.getPartyOptions.bind(this)
    this.handleLabelChange = this.handleLabelChange.bind(this)
    this.handleLabelBlur = this.handleLabelBlur.bind(this)
    this.handleArtistChange = this.handleArtistChange.bind(this)
    
    this.validator = ProjectValidator

    this.state.name = props.name || ''
    this.state.image = props.image || ''
    this.state.description = props.description || ''
    this.state.number = props.number || 'VEVA' + generateRandomNumber(14)

    this.state.labelId = props.label ? props.label.id : null
    this.state.labelName = props.label ? props.label.name : ''
    if (this.state.labelId) {
      this.state.label = {
        value: this.state.labelId,
        label: this.state.labelName
      }
    }

    this.state.artistId = props.artist ? props.artist.id : ''
    this.state.artistName = props.artist ? props.artist.name : ''
    this.state.gradientBg = GetGradientFromId(this.props.id)
    if (this.state.artistId) {
      this.state.artist = {
        value: this.state.artistId,
        label: this.state.artistName
      }
    }
  }

  submit() {
    this.props.onSubmit(this.state)
  }
  handleLabelChange(option) {
    this.setState({
      ...this.state,
      labelId: option.value,
      label: option,
      errors: {
        ...this.state.errors,
        labelId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleLabelBlur() {
    this.handleDirty({
      target: {
        name: 'labelId',
        value: this.state.labelId
      }
    })
  }

  handleArtistChange(option) {
    this.setState({
      ...this.state,
      artistId: option.value,
      artist: option,
      errors: {
        ...this.state.errors,
        artistId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  getPartyOptions = (inputValue, type, optional = false) =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_PARTIES,
        variables: {
          type: type
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let parties = []

        data.getParties.forEach(party => {
          const label = party.name
          if (!label.toLowerCase().includes(inputValue.toLowerCase())) {
            return
          }
          parties.push({
            value: party.id,
            label: label
          })
        })

        const optionalParty = {
          value: null,
          label: 'None'
        }

        parties = optional
          ? [optionalParty, ...parties.sort(sortParties('label'))]
          : parties.sort(sortParties('label'))

        resolve(parties)
      })
    })

  openCreateRecordLabel = () => {
    this.setState({
      createRecordLabelModalOpen: true,
      recordLabelSelectVisible: false
    })
  }

  closeCreateRecordLabelModal = () => {
    this.setState({
      createRecordLabelModalOpen: false,
      recordLabelSelectVisible: true
    })
  }

  onRecordLabelCreated = recordLabel => {
    this.setState({
      createRecordLabelModalOpen: false,
      recordLabelSelectVisible: true,
      labelId: recordLabel.id,
      label: {
        value: recordLabel.id,
        label: recordLabel.firstName
      }
    })
  }

  openCreateProjectArtist = () => {
    this.setState({
      createProjectArtistModalOpen: true,
      projectArtistSelectVisible: false
    })
  }

  closeCreateProjectArtistModal = () => {
    this.setState({
      createProjectArtistModalOpen: false,
      projectArtistSelectVisible: true
    })
  }

  onProjectArtistCreated = projectArtist => {
    this.setState({
      createProjectArtistModalOpen: false,
      projectArtistSelectVisible: true,
      artistId: projectArtist.id,
      artist: {
        value: projectArtist.id,
        label: projectArtist.name
      }
    })
  }
  uploadImage = async (e) => {
    if (e.target.files.length < 1) {
      return
    }
    if (this.state.isUploading) {
      return
    }
    this.setState({ isUploading: true })

    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file, file.name)
    fetch(`${process.env.REACT_APP_API}/api/project-thumbnail-upload`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('authToken')
      },
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        this.setState({ image: response.name })
        setTimeout(() => {
          this.setState({ isUploading: false })
        }, 2000)
      })
  }

  removeImage = () => {
    this.setState({ image: null })
  }
  getInitials=()=> {
    const [firstWord, secondWord] = (this.props.name || '').split(' ')
    let initials = ''
    if (firstWord.length > 0) {
      initials = initials + firstWord[0]
    }
    if ((secondWord || '').length > 0) {
      initials = initials + secondWord[0]
    }
    return initials
  }
  discardChanges = () => {
    const newState = {}
    newState.name = this.props.name || ''
    newState.image = this.props.image || ''
    newState.description = this.props.description || ''
    newState.number = this.props.number || 'VEVA' + generateRandomNumber(14)

    newState.labelId = this.props.label ? this.props.label.id : null
    newState.labelName = this.props.label ? this.props.label.name : ''
    if (newState.labelId) {
      newState.label = {
        value: newState.labelId,
        label: newState.labelName
      }
    } else {
      newState.label = {}
    }

    newState.artistId = this.props.artist ? this.props.artist.id : ''
    newState.artistName = this.props.artist ? this.props.artist.name : ''
    newState.gradientBg = GetGradientFromId(this.props.id)
    if (newState.artistId) {
      newState.artist = {
        value: newState.artistId,
        label: newState.artistName
      }
    } else {
      newState.artist = {}
    }

    newState.showDiscardModal = false
    this.setState((newState))
    if (this.props.id) {
      this.props.history.push(`/projects/${this.props.id}`)
    } else {
      this.props.history.push('/projects')
    }
  }
  render() {
    return (
      <div className='form'>
        <Form onSubmit={this.handleSubmit} autoComplete='off'>
          <div className='formRow'>
            <TextInput
              id='name'
              name='name'
              labelText='* Title'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.name || ''}
              invalid={this.state.errors.name ? true : false}
              invalidText={this.state.errors.name || ''}
            />
          </div>
          <div className='formRow img-upload-section'>
            {this.state.image ? (
              <img
                src={process.env.REACT_APP_IMAGE_URL + 'project-images/' + this.state.image}
                alt=''
                className='song-img' />
            ) : (
              <div
                className='projectHeroThumbnail song-img-placeholder'
                style={{ background: this.state.gradientBg }}
              >
                {this.props.name && <span className='projectCover'>{this.getInitials(this.props.name)}</span>}
              </div>
            )}
            <div className='actions-container'>
              {this.state.image && (
                <DeleteFileIcon onClick={this.removeImage} />
              )}
              <UploadFileIcon onClick={this.uploadImage} loading={this.state.isUploading} />
            </div>
          </div>
          <div className='formRow'>
            <TextInput
              id='number'
              name='number'
              labelText='Project Number'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.number || ''}
              invalid={this.state.errors.number ? true : false}
              invalidText={this.state.errors.number || ''}
            />
          </div>
          <div className='formRow'>
            {!!this.state.projectArtistSelectVisible && (
              <SelectASync
                labelText='Project Artist (Party) *'
                btnText='Create'
                btnOnClick={this.openCreateProjectArtist}
                id='artistId'
                cacheOptions
                defaultOptions
                key={this.state.artist.value}
                loadOptions={inputValue => this.getPartyOptions(inputValue, 'person')}
                onChange={this.handleArtistChange}
                defaultValue={this.state.artist.value ? this.state.artist : { label: 'Select...', value: '' }}
                invalid={this.state.errors.artistId ? true : false}
                invalidText={this.state.errors.artistId}
              />
            )}
          </div>
          <div className='formRow'>
            {!!this.state.recordLabelSelectVisible && (
              <SelectASync
                labelText='Record Label (Party)'
                id='labelId'
                cacheOptions={this.state.label.value}
                btnText='Create'
                key={this.state.label.value}
                btnOnClick={this.openCreateRecordLabel}
                defaultOptions
                loadOptions={inputValue => this.getPartyOptions(inputValue, 'organisation', true)}
                onChange={this.handleLabelChange}
                onBlur={this.handleLabelBlur}
                defaultValue={this.state.label.value ? this.state.label : { label: 'Select...', value: '' }}
                invalid={this.state.errors.labelId ? true : false}
                invalidText={this.state.errors.labelId}
              />
            )}
          </div>

          <div className='formRow'>
            <TextArea
              id='description'
              name='description'
              labelText='Description'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.description || ''}
              invalid={this.state.errors.description ? true : false}
              invalidText={this.state.errors.description || ''}
            />
          </div>
          <div className='formRow btns-container'>
            <Button
              onClick={() => this.setState({ showDiscardModal: true })}
              type='button'
              kind='secondary'
            >
              Cancel
            </Button>
            <Button type='submit'>
              {this.props.loading ? 'Saving...' : `${this.props.id ? 'Save' : 'Create'}` }
            </Button>
          </div>
        </Form>
        <CreateRecordLabelModal
          open={this.state.createRecordLabelModalOpen}
          onRequestClose={this.closeCreateRecordLabelModal}
          onRecordLabelCreated={this.onRecordLabelCreated}
          defaultType={'organisation'}
        />
        <CreateProjectArtistModal
          open={this.state.createProjectArtistModalOpen}
          onRequestClose={this.closeCreateProjectArtistModal}
          onProjectArtistCreated={this.onProjectArtistCreated}
        />
        <DiscardModal
          open={this.state.showDiscardModal}
          onRequestClose={() => this.setState({ showDiscardModal: false })}
          modalHeading='Discard Changes?'
          handleSubmit={this.discardChanges}
        />
      </div>
    )
  }
}

export default compose(withRouter)(ProjectForm)

import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    currentPassword: Field()
      .string()
      .required({ message: Errors.Required }),
    newPassword: Field()
      .string()
      .required({ message: Errors.Required }),
    confirmNewPassword: Field()
      .equals(['#newPassword'], { message: `${Errors.Equals} New Password` })
  },
  { verbose: true, async: true }
)

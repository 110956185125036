import React from 'react'
import { graphql } from 'react-apollo'
import {
  Form,
  TextInput,
  Modal
} from 'carbon-components-react'

import { SelectASync } from '../components/Select'
import ApolloClient from '../utils/Apollo'
import { CREATE_VENUE } from '../graphql/mutations'
import { GET_COUNTRIES } from '../graphql/queries'
import Validator from '../utils/Validator'
import VenueValidator from '../validators/Venue'

const initialState = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  countryId: '',
  countryName: '',
  country: {},

  errors: {}
}

class CreateVenueModal extends Validator {
  validator = VenueValidator

  state = { ...initialState }

  getCountryOptions(input) {
    return new Promise((resolve) => {
      ApolloClient.query({
        query: GET_COUNTRIES,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let countries = [...data.getCountries.map((country) => {
          if (country.name.toLowerCase().indexOf(input.toLowerCase()) === -1) {
            return false;
          }

          return {
            value: country.isoCode,
            label: country.name
          }
        })].filter(Boolean)

        resolve(countries)
      })
    })
  }

  handleCountryChange(option) {
    this.setState({
      ...this.state,
      countryId: option.value,
      country: option,
      errors: {
        country: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleCountryBlur() {
    this.handleDirty({
      target: {
        name: 'country',
        value: this.state.countryId
      }
    })
  }

  submit() {
    this.props.createVenue(this.state)
      .then(response => {
        this.props.onVenueCreated(response.data.createVenue)
        this.setState({ ...initialState })
      })
      .catch(e => {
        this.setState({
          ...this.state,
          errors: Validator.flattenInitialErrors(e, 'input.')
        })
      })
  }

  render() {
    return (
      <Modal className='modal-scrollable'
        open={this.props.open}
        modalHeading='Create Venue'
        primaryButtonText='Create'
        secondaryButtonText='Cancel'
        shouldSubmitOnEnter={true}
        selectorPrimaryFocus='.bx--text-input'
        onRequestSubmit={this.handleSubmit}
        onRequestClose={this.props.onRequestClose}>
        <div className='form'>
          <Form>
            <div className='formRow'>
              <TextInput labelText='Name *'
                id='name'
                name='name'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.name}
                invalid={this.state.errors.name ? true : false}
                invalidText={this.state.errors.name} />
            </div>
            <div className='formRow'>
              <SelectASync
                labelText='Country *'
                id='countryId'
                cacheOptions
                defaultOptions
                loadOptions={this.getCountryOptions}
                onChange={(e) => this.handleCountryChange(e)}
                onBlur={(e) => this.handleCountryBlur(e)}
                defaultValue={this.state.country.value ? this.state.country : null}
                invalid={this.state.errors.countryId ? true : false}
                invalidText={this.state.errors.countryId || ''} />
            </div>
            <div className='formRow'>
              <TextInput labelText='Address 1 *'
                id='address1'
                name='address1'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.address1}
                invalid={this.state.errors.address1 ? true : false}
                invalidText={this.state.errors.address1} />
            </div>
            <div className='formRow'>
              <TextInput labelText='Address 2'
                id='address2'
                name='address2'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.address2}
                invalid={this.state.errors.address2 ? true : false}
                invalidText={this.state.errors.address2} />
            </div>
            <div className='formRow'>
              <TextInput labelText='City *'
                id='city'
                name='city'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.city}
                invalid={this.state.errors.city ? true : false}
                invalidText={this.state.errors.city} />
            </div>
            <div className='formRow'>
              <TextInput labelText='State / Province / Region *'
                id='state'
                name='state'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.state}
                invalid={this.state.errors.state ? true : false}
                invalidText={this.state.errors.state} />
            </div>
            <div className='formRow'>
              <TextInput labelText='Zip *'
                id='zip'
                name='zip'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.zip}
                invalid={this.state.errors.zip ? true : false}
                invalidText={this.state.errors.zip} />
            </div>
          </Form>
        </div>
      </Modal>
    )
  }
}

export default graphql(CREATE_VENUE, {
  name: 'createVenue',
  props: (data) => {
    return {
      createVenue: venue =>
        data.createVenue({
          variables: venue
        })
    }
  }
})(CreateVenueModal)

import { graphql } from 'react-apollo'
import { REDEEM_FREE_MONTH } from '../../graphql/mutations'

export default graphql(REDEEM_FREE_MONTH, {
  name: 'RedeemFreeMonth',
  props: data => {
    return {
      RedeemFreeMonth: (referralUserId, plan) =>
        data.RedeemFreeMonth({
          variables: {
            referralUserId,
            plan
          }
        })
    }
  }
})

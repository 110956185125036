import React from 'react'
import { Query } from 'react-apollo'
import { GET_CREDIT } from '../../graphql/queries'

const GetCredit = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_CREDIT}
          variables={{ creditId: this.props.match.params.creditId }}
          fetchPolicy={fetchPolicy}>
          {({ loading, error, data }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            if (data.getProject === null) {
              return <h1 style={{color: 'white', textAlign: 'center'}}>404</h1>
            }

            return (
              <WrappedComponent {...this.props} credit={data.getCredit} />
            )
          }}
        </Query>
      )
    }
  }
}

export default GetCredit

export const GetCreditRefreshed = (WrappedComponent) => {
  return GetCredit(WrappedComponent, 'network-only')
}

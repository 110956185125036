import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import Helmet, { HelmetProvider } from 'react-helmet-async'
import { useWindowDimensions } from './hook'

import Overlay from './components/Overlay'
import Routes from './utils/Routes'
import ApolloClient from './utils/Apollo'
import ScrollToTop from './utils/ScrollToTop'

// Providers
import { WrappedUploadsProvider } from './contexts/Uploads'
import { ApolloProvider } from 'react-apollo'
import ShareImage from './assets/images/Share-Image.jpg'

import './App.scss'

const App = () => {
  const isToken = localStorage.getItem('authToken')
  const { width } = useWindowDimensions()
  const isLaptop = width < 1023 && !!isToken

  return (
    <BrowserRouter>
      <>
        {isLaptop ? (
          <Overlay />
        ) : (
          <ApolloProvider client={ApolloClient}>
            <HelmetProvider>
              <WrappedUploadsProvider>
                <div className='App'>
                  <Helmet
                    titleTemplate='%s | VEVA Collect'
                    defaultTitle='VEVA Collect'
                  >
                    <meta name='description' content='VEVA Collect' />
                    <meta property='og:image' content={ShareImage} />
                  </Helmet>
                  <ScrollToTop />
                  <Routes />
                </div>
              </WrappedUploadsProvider>
            </HelmetProvider>
          </ApolloProvider>
        )}
      </>
    </BrowserRouter>
  )
}

export default App

import { Button, Modal, RadioButton, RadioButtonGroup } from 'carbon-components-react'
import React, { useState } from 'react'

function RedeemModal({ open, onRequestClose, message, referralUserId, ...props }) {
  const [tier, setTier] = useState('')

  const redeem = async () =>{
    const { data } = await props.RedeemFreeMonth(referralUserId, tier)
    if (data.redeemFreeMonth.message) {
      props.alert(`${data.redeemFreeMonth.message}`, { submitCallback: close => {
        props.history.push('/')
        close()
        onRequestClose()
      }
      })
    }
  }

  const redirectToDasboard = () =>{
    props.history.push('/')
    onRequestClose()
  }
  const onChangeTier = (value)=>{
    setTier(value)
  }

  return (
    <>
      <Modal className='modal redeem-modal'
        open={open}
        modalHeading='You’ve earned one free month on any tier!'
        passiveModal={true}
        onRequestClose={onRequestClose}>
        <div>
          <p>{message}</p>
          <div className='formRow'>
            <div className='party-form'>
              {/* <label className='bx--label'>Tier</label> */}
              <RadioButtonGroup
                name='radio-button-group'
                valueSelected={tier}
                onChange={onChangeTier}
              >
                <RadioButton
                  id='pree'
                  labelText='Free'
                  value='free'
                />
                <RadioButton
                  id='lite'
                  labelText='Lite'
                  value='lite'
                />
                <RadioButton
                  id='pro'
                  labelText='Pro'
                  value='pro'
                />
                <RadioButton
                  id='unlimited'
                  labelText='Pro Unlimited'
                  value='unlimited'
                />
              </RadioButtonGroup>
            </div>
            <div className='formRow btns-container'>
              <Button
                type='button'
                kind='secondary'
                onClick={redirectToDasboard}
              >
              Cancel
              </Button>
              <Button onClick={redeem}>Redeem</Button>

            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default RedeemModal

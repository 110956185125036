import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'
import { DateTime } from 'luxon'

import { UPDATE_PARTY } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import { GetPartyRefreshed } from '../../hocs/queries/GetParty'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import PartyForm from '../../components/PartyForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class PartyEdit extends React.Component {
  constructor(props) {
    super(props)

    if (props.user.id !== props.party.userId) {
      props.history.push(`/parties/${props.party.id}`)
      return
    }
  }

  componentDidMount=()=> {
    const { party, history } = this.props
    if (party) {
      if (party.is_my) {
        history.push('/account/myParty')
      }
    }
  }

  render() {
    const { party, history } = this.props
    return (
      <>
        <Helmet>
          <title>{party.name} | Party</title>
        </Helmet>
        <main className='main'>
          <CreatePagesHeader
            title={this.props.party.name}
            subtitle='Edit Party Details'
          >
            <Breadcrumb items={[
              {
                href: `/parties`,
                name: 'Parties'
              },
              {
                href: `/parties/${party.id}`,
                name: party.name
              },
              {
                href: `/parties/${party.id}/edit`,
                name: 'Edit Party'
              }
            ]} />
          </CreatePagesHeader>
          <SectionContainer>
            <Mutation
              mutation={UPDATE_PARTY}
              update={(cache, { data: { updateParty } }) => {
                history.push(`/parties/${updateParty.id}`)
              }}>
              {(updateParty, { loading, error }) => (
                <PartyForm {...party}
                  inPartyEdit={true}
                  onSubmit={(state) => {
                    let data = { ...state }
                    Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                    let variables = {
                      ...data,
                      id: party.id,
                      birthDate: data.birthDate ?
                        DateTime.fromFormat(data.birthDate, 'LL/dd/yyyy').toISODate() : null,
                      deathDate: data.deathDate ?
                        DateTime.fromFormat(data.deathDate, 'LL/dd/yyyy').toISODate() : null
                    }

                    if (!variables.birthDate) {
                      delete variables.birthDate
                    }

                    if (!variables.deathDate) {
                      delete variables.deathDate
                    }

                    updateParty({
                      variables
                    }).catch(() => {
                      //
                    })
                  }}
                  loading={loading}
                  errors={error} />
              )}
            </Mutation>
          </SectionContainer>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetPartyRefreshed
)(PartyEdit)

import gql from 'graphql-tag'

export const SHARE_FILES = gql`
  mutation shareFiles(
    $files: [ShareFileInput!]!,
    $users: [ShareUserInput!]!,
    $expiry: String,
    $password: String,
    $message: String,
    $shareId: String
  ) {
    shareFiles(input: {
      files: $files,
      users: $users,
      expiry: $expiry,
      password: $password,
      message: $message,
      shareId: $shareId
    })
    {
      success
      message
      url
      __typename
    }
  }
`
export const UPDATE_SHARE = gql`
mutation updateShare(
  $uuid: String!,
  $password: String,
  $expiry: String
) {
  updateShare(input: {
    uuid: $uuid,
    password: $password,
    expiry: $expiry
  }) {
    success
    errors {
      isShareInvalid
      isExpiryInvalid
      isErrorUpdatingShare
    }
  }
}
`
export const EXPIRE_SHARE = gql`
  mutation expireShare(
    $uuid: String!
  ) {
    expireShare(input: {
      uuid: $uuid
    }) {
      success
      __typename
    }
  }
`

export const EXPIRE_SHARE_LINK = gql`
  mutation expireShareDirectLink(
    $uuid: String!
  ) {
    expireShareDirectLink(input: {
      uuid: $uuid
    }) {
      success
      __typename
    }
  }
`

export const SHARE_PLAYLIST = gql`
    mutation sharePlaylist(
      $users: [ShareUserInput!]!,
      $expiry: String,
      $password: String,
      $message: String,
      $playlistId: String
    ) {
      sharePlaylist(input: {
        users: $users,
        expiry: $expiry,
        password: $password,
        message: $message,
        playlistId: $playlistId
      })
      {
        success
        message
        url
        __typename
      }
    }
`

//  Start - Update Share Direct Links

export const UPDATE_SHARE_DIRECT_LINKS = gql`
mutation UpdateShareDirectLink(
  $expiry: String,
  $password: String,
  $uuid: String!
) {
  updateShareDirectLink(input: {
    expiry: $expiry,
    password: $password,
    uuid: $uuid
  })
  {
    success
    errors {
        isShareInvalid
        isExpiryInvalid
        isErrorUpdatingShare
    }
  }
}
`

//  End - Update Share Direct Links

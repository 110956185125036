import React from 'react'
import Helmet from 'react-helmet-async'
import { Query, compose } from 'react-apollo'
import queryString from 'query-string'

import {
  SEARCH_USER_FILES,
  SEARCH_PROJECT_FILES,
  SEARCH_PROJECTS,
  SEARCH_RECORDINGS,
  SEARCH_SESSIONS,
  SEARCH_SONGS,
  SEARCH_PLAYLISTS
} from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'
import GetProjects from '../hocs/queries/GetProjects'
import * as permissions from '../utils/permissions'

// Our Base Components
import FileSearchTable from '../components/FileSearchTable'
import Hero from '../components/Hero'
import ProjectTable from '../components/ProjectTable'
import RecordingTable from '../components/RecordingTable'
import SessionTable from '../components/SessionTable'
import SectionContainer from '../components/SectionContainer'
import SongTable from '../components/SongTable'
import CreatePagesHeader from '../components/CreatePagesHeader'
import PlayListTileList from '../components/PlayListTileList'

class Search extends React.Component {
  render() {
    const query = queryString.parse(this.props.location.search)

    return (
      <>
        <Helmet>
          <title>Search</title>
        </Helmet>
        <main className='main'>
          <Hero src='ph.hero.projects.jpg'
            alt='Alt text'
            title='Search' />
          <div className='projects-title-wrapper'>
            <CreatePagesHeader
              subtitle='Your Files'
            />
          </div>
          <div className='sections-container'>
            <SectionContainer>
              <Query query={SEARCH_USER_FILES}
                variables={{ term: query.term || '' }}
                fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }

                  return (
                    <FileSearchTable rows={data.searchUserFiles || []}
                      showSearch={false}
                      projectId={null}
                      folderId={0}
                      projectFiles={false}
                      showSkeleton={loading}
                      rowActions
                      canDownload={() => true}
                      comments={false} />
                  )
                }}
              </Query>
            </SectionContainer>
          </div>

          <CreatePagesHeader
            subtitle='Project Files'
          />
          <div className='sections-container'>
            <SectionContainer>
              <Query query={SEARCH_PROJECT_FILES}
                variables={{ term: query.term || '' }}
                fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }

                  return (
                    <FileSearchTable rows={data.searchProjectFiles || []}
                      showSearch={false}
                      folderId={0}
                      projectFiles={true}
                      showSkeleton={loading}
                      rowActions
                      canDownload={(row) => {
                        const isProjectOwner = permissions.isProjectOwner(row.projectId, this.props.user)
                        if (isProjectOwner) {
                          return true
                        }
                        return permissions.canDownload(row.projectId, 'file')
                      }}
                      comments={false} />
                  )
                }}
              </Query>
            </SectionContainer>
          </div>
          <CreatePagesHeader
            subtitle='Projects'
          />
          <div className='sections-container'>

            <SectionContainer>
              <Query query={SEARCH_PROJECTS}
                variables={{ term: query.term || '' }}
                fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }

                  return (
                    <ProjectTable rows={data.searchProjects || []}
                      showSearch={false}
                      showSkeleton={loading} />
                  )
                }}
              </Query>
            </SectionContainer>
          </div>
          <CreatePagesHeader
            subtitle='Recordings'
          />
          <div className='sections-container'>
            <SectionContainer>
              <Query query={SEARCH_RECORDINGS}
                variables={{ term: query.term || '' }}
                fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }

                  return (
                    <RecordingTable rows={data.searchRecordings || []}
                      showSkeleton={loading}
                      favouritePage={true}
                      projectId={null}
                      showSearch={false} />
                  )
                }}
              </Query>
            </SectionContainer>
          </div>

          <CreatePagesHeader
            subtitle='Sessions'
          />
          <div className='sections-container'>
            <SectionContainer>
              <Query query={SEARCH_SESSIONS}
                variables={{ term: query.term || '' }}
                fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }

                  return (
                    <SessionTable rows={data.searchSessions || []}
                      showSkeleton={loading}
                      favouritePage={true}
                      favourite={false}
                      projectId={null}
                      showSearch={false} />
                  )
                }}
              </Query>
            </SectionContainer>
          </div>
          <CreatePagesHeader
            subtitle='Songs'
          />
          <div className='sections-container'>

            <SectionContainer >
              <Query query={SEARCH_SONGS}
                variables={{ term: query.term || '' }}
                fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }

                  return (
                    <SongTable rows={data.searchSongs || []}
                      showSkeleton={loading}
                      favouritePage={true}
                      projectId={null}
                      showSearch={false}
                      pagination={false} />
                  )
                }}
              </Query>
            </SectionContainer>

            <SectionContainer title='Playlists'>
              <Query query={SEARCH_PLAYLISTS}
                variables={{ term: query.term || '' }}
                fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }

                  return (
                    <PlayListTileList playlists={data.searchPlaylists || []}
                      showLoader={loading}
                      withOptions={false}
                      showPlayIcon={false}
                    />
                  )
                }}
              </Query>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProjects
)(Search)

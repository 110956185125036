import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'

import ResponsiveTable from './ResponsiveTable'
import { DELETE_PARTY_CONTACT } from '../graphql/mutations'
import WithAlertModal from '../hocs/WithAlertModal'

const headers = [
  {
    key: 'name',
    header: 'Name',
    formatter: (name) => `${name ? name : '-'}`
  },
  {
    key: 'value',
    header: 'Phone/Email'
  }
]

class PartyContactTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.getRowActions = this.getRowActions.bind(this)
  }

  getRowActions = (() => {
    const actions = [
      {
        itemText: 'Edit',
        onClick: (row) => {
          const { onEdit } = this.props
          if (onEdit) {
            onEdit(row.id)
          }
        }
      },
      {
        itemText: 'Delete',
        onClick: row => {
          const { alert, onDelete, deletePartyContact, partyId, onDeleteContact } = this.props
          alert('Are you sure you want to delete this contact?', {
            type: 'delete',
            buttonText: 'Yes',
            submitCallback: close => {
              if (!partyId) {
                onDeleteContact(row)
                close()
                return
              }
              deletePartyContact({
                variables: {
                  id: row.id
                }
              })
                .then(() => {
                  alert('Contact has successfully been deleted')
                  onDelete()
                })
                .catch(() => {
                  alert('This party contact could not be deleted.')
                })
              close()
            }

          })
        }
      }
    ]

    return () => actions
  })()

  render() {
    const actions = this.props.getContactActions ?
      this.props.getContactActions() : this.getRowActions()
    return (
      <ResponsiveTable headers={headers}
        {...this.props}
        rowActions={this.props.rowActions ? actions : false}
        emptyText='No Contacts'
        // simpleRow={true}
      />
    )
  }
}

export default compose(
  withRouter,
  WithAlertModal,
  graphql(DELETE_PARTY_CONTACT, { name: 'deletePartyContact' })
)(PartyContactTable)


import React, { useState } from 'react'
import { compose } from 'recompose'
import PublicPlaylistHero from '../../components/PublicPlaylistHero'
import PublicPlaylistSong from '../../components/PublicPlaylistSong'
import PublicPlaylistHeader from '../../components/PublicPlaylistHeader'
import WithAuth from '../../hocs/WithAuth'
import { calcPlaylistTotalDuration } from '../../utils/functions'
import PublicPlaylistAudioModal from '../../components/PublicPlaylistAudioModal'
import WithAlertModal from '../../hocs/WithAlertModal'

const PublicPlaylist = ({
  data,
  match: {
    params: { playlistId }
  },
  location,
  alert
}) => {
  const [filePlayingId, setFilePlayingId] = useState(null)
  const [fileSelectedId, setFileSelectedId] = useState(null)
  const [isHovered, setIsHovered ] = useState(false)
  const [playIconToggle, setPlayIconToggle] = useState(false)
  const [file, setFile] = useState(null)


  const filePlayingIdHandler = ({ id }) => {
    setFilePlayingId(id)
  }

  const fileSelectedHandler = ({ id }) => {
    setFileSelectedId(id)
    setPlayIconToggle(!playIconToggle)
  }

  if (!location.state) {
    return (<div className='ppUnauthorized'>
    You are not authorized to view this playlist! Please use the
    correct Shared Playlist Link
    </div>)
  }


  const playlist = location.state && location.state.playlist ? location.state.playlist : {}

  const audioFiles = playlist.files || []
  const description = playlist.description || ''
  const totalRecordings = playlist.files ?
    playlist.files.length : 0
  const totalDuration = calcPlaylistTotalDuration(
    playlist.files || []
  )
  const userName = playlist.user ? playlist.user.name : ''
  const profileImg = playlist.image || null
  const userId = playlist.user ? playlist.user.id : 0
  const title = playlist.title || ''

  const showPlayer = ({ curfile }) => {
    setFile(curfile)
  }

  const doClose = () => {
    setFile(null)
    filePlayingIdHandler({ id: null })
    fileSelectedHandler({ id: null })
    setIsHovered(false)
  }

  return (
    <>
      {data.authStatus.status === 'loggedOut' && <PublicPlaylistHeader />}
      {location.state && (
        <div>
          {
            playlist && <>
            <div className='publicPlaylistHeroContainer'>
              <PublicPlaylistHero
                totalRecordings={totalRecordings}
                totalDuration={totalDuration}
                userName={userName}
                profileImg={profileImg}
                userId={userId}
                title={title}
                files={audioFiles}
                playing={filePlayingId !== null}
                filePlayingIdHandler={filePlayingIdHandler}
                uuid={location.state.uuid}
                fromPlaylist={true}
                fileSelectedHandler={fileSelectedHandler}
                setIsHovered={setIsHovered}
                isHovered={isHovered}
                showPlayer={showPlayer}
                fileSelectedId={fileSelectedId}
                filePlayingId={filePlayingId}
              />
              <div className='ppInfo'>
                {description.length <= 680 ?
              <>
              {description}
              </>
                  :
              <>
              {description.slice(0, 680)}
              <span
                onClick={()=> alert(`${description}`,
                  { title: '   ', className: 'credits-alert' })}
                className='ellipsis-text'> ...more</span>
              </>
                }
              </div>
            </div>

            <hr className='separator public-playlist-separator' />

            <div className='ppContainer'>

              <PublicPlaylistSong
                filePlayingIdHandler={filePlayingIdHandler}
                filePlayingId={filePlayingId}
                fileSelectedId={fileSelectedId}
                fileSelectedHandler={fileSelectedHandler}
                showPlayer={showPlayer}
                files={audioFiles}
              />
            </div>
          </>
          }
        </div>
      )}


      {file && (
        <PublicPlaylistAudioModal
          open={file ? true : false}
          onRequestClose={doClose}
          uuid={location.state.uuid}
          fromPlaylist={true}
          file={file}
          filePlayingIdHandler={filePlayingIdHandler}
          fileSelectedHandler={fileSelectedHandler}
          filePlayingId={filePlayingId}
          playing={filePlayingId !== null}
          fileSelectedId={fileSelectedId}
          setFile={setFile}
        />
      )}
    </>
  )
}

export default compose(
  WithAuth,
  WithAlertModal
)(PublicPlaylist)

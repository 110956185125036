import gql from 'graphql-tag'
import * as fragment from './fragments'

export const GET_ALL_RECORDINGS = gql`
  query GetProjectRecordings($projectId: Int!) {
    getRecordings(projectId: $projectId) {
      data {
        ...RecordingTableFields
      }
    }
  }
  ${fragment.RECORDING_TABLE_FIELDS}
`

export const GET_RECENT_PROJECT_CREDITS = gql`
  query GetRecentProjectCredits($projectId: ID!) {
    getCreditsByProject(projectId: $projectId, count: 5) {
      ...CreditTableFields
    }
  }
  ${fragment.CREDIT_TABLE_FIELDS}
`

export const GET_RECENT_PROJECT_RECORDINGS = gql`
  query GetRecentProjectRecordings($projectId: Int!) {
    getRecordings(
      projectId: $projectId
      count: 5
      ordering: { field: "created_at", direction: "desc" }
    ) {
      data {
        ...RecordingTableFields
      }
    }
  }
  ${fragment.RECORDING_TABLE_FIELDS}
`

export const GET_PROJECT_RECORDINGS = gql`
  query GetProjectRecordings($projectId: Int!) {
    getRecordings(
      projectId: $projectId
      count: 99
      ordering: { field: "created_at", direction: "desc" }
    ) {
      data {
        ...RecordingTableFields
      }
    }
  }
  ${fragment.RECORDING_TABLE_FIELDS}
`

export const GET_RECENT_PROJECT_SESSIONS = gql`
  query GetRecentProjectSessions($projectId: Int!) {
    getSessions(
      projectId: $projectId
      count: 5
      ordering: { field: "created_at", direction: "desc" }
    ) {
      data {
        ...SessionTableFields
      }
    }
  }
  ${fragment.SESSION_TABLE_FIELDS}
`

export const GET_RECENT_PROJECT_FILES = gql`
  query GetRecentProjectFiles($projectId: Int!, $count: Int) {
    getFiles(
      projectId: $projectId
      count: $count
      ordering: { field: "created_at", direction: "desc" }
    ) {
      data {
        ...FileTableFields
      }
    }
  }
  ${fragment.FILE_TABLE_FIELDS}
`

export const GET_PROJECT_ROOT_FILES = gql`
  query GetProjectRootFiles($projectId: ID!) {
    getProject(id: $projectId) {
      id
      files(folder_id: null) {
        ...FileTableFields
      }
      folders(folder_id: null) {
        ...FolderTableFields
      }
    }
  }
  ${fragment.FILE_TABLE_FIELDS}
  ${fragment.FOLDER_TABLE_FIELDS}
`

export const GET_PROJECT_ROOT_FOLDERS = gql`
  query GetProjectRootFolders($projectId: ID!) {
    getProject(id: $projectId) {
      id
      name
      folders(folder_id: null) {
        ...FolderTableFields
      }
    }
  }
  ${fragment.FOLDER_TABLE_FIELDS}
`

export const GET_USER_ROOT_FILES = gql`
  query GetUserRootFiles {
    getUser {
      id
      files(folder_id: null) {
        ...FileTableFields
      }
      folders(folder_id: null) {
        ...FolderTableFields
      }
      songFolders(folder_id: null) {
        ...FolderTableFields
      }
    }
  }
  ${fragment.FILE_TABLE_FIELDS}
  ${fragment.FOLDER_TABLE_FIELDS}
`

export const GET_USER_ROOT_FOLDERS = gql`
  query GetUserRootFolders {
    getUser {
      id
      folders(folder_id: null) {
        ...FolderTableFields
      }
    }
  }
  ${fragment.FOLDER_TABLE_FIELDS}
`

export const GET_RECENT_USER_FILES = gql`
  query GetRecentUserFiles {
    getUser {
      id
      recentFiles {
        ...FileTableFields
      }
    }
  }
  ${fragment.FILE_TABLE_FIELDS}
`

export const GET_PROJECT_FOLDER = gql`
  query GetProjectFolder($folderId: Int!) {
    getFolder(id: $folderId) {
      id
      name
      project{
        id
        name
      }
      path {
        id
        name
      }
      recording {
        id
      }
      resource {
          id
          type
      }
      files {
        ...FileTableFields
      }
      folders {
        ...FolderTableFields
      }
    }
  }
  ${fragment.FILE_TABLE_FIELDS}
  ${fragment.FOLDER_TABLE_FIELDS}
`

export const GET_ALL_PROJECT_SESSIONS = gql`
  query GetProjectSessions($projectId: Int!) {
    getSessions(projectId: $projectId) {
      data {
        ...SessionTableFields
      }
    }
  }
  ${fragment.SESSION_TABLE_FIELDS}
`

export const GET_ALL_RECORDING_SESSIONS = gql`
  query GetRecordingSessions($recordingId: ID!) {
    getSessionsByRecording(recordingId: $recordingId) {
      ...SessionTableFields
    }
  }
  ${fragment.SESSION_TABLE_FIELDS}
`

export const GET_ALL_RECORDING_CREDITS = gql`
  query GetRecordingCredits($recordingId: ID!) {
    getCreditsByRecording(recordingId: $recordingId) {
      ...CreditTableFields
    }
  }
  ${fragment.CREDIT_TABLE_FIELDS}
`

export const GET_ALL_SESSION_CREDITS = gql`
  query GetSessionCredits($sessionId: ID!) {
    getCreditsBySession(sessionId: $sessionId) {
      ...CreditTableFields
    }
  }
  ${fragment.CREDIT_TABLE_FIELDS}
`

export const GET_USER = gql`
  query GetUser {
    getUser {
      ...UserFields
    }
  }
  ${fragment.USER_FIELDS}
`

export const GET_USER_ACCOUNT = gql`
  query GetUserAccount {
    getUser {
      ...UserAccountFields
    }
  }
  ${fragment.USER_ACCOUNT_FIELDS}
`

export const GET_USER_INVITES = gql`
  query GetUserInvites {
    getUserInvites {
      id
      token
      project {
        name
      }
    }
  }
`

export const GET_PROJECT = gql`
  query GetProject($id: ID!) {
    getProject(id: $id) {
      ...ProjectFields
    }
  }
  ${fragment.PROJECT_FIELDS}
`

export const GET_COUNTRIES = gql`
  query GetCountries {
    getCountries {
      ...CountryFields
    }
  }
  ${fragment.COUNTRY_FIELDS}
`

export const GET_COMMENTS = gql`
  query GetComments($projectId: Int!, $resourceType: String!, $resourceId: Int!) {
    getComments(
      projectId: $projectId
      resourceType: $resourceType
      resourceId: $resourceId
      ordering: { field: "created_at", direction: "asc" }
    ) {
      ...CommentFields
    }
  }
  fragment CommentFields on Comment {
    id
    message
    createdAt
    resourceId
    resourceType
    user {
      firstName
      lastName
      image
      id
    }
    parentId
    checklist
  }
`

export const GET_FILE_PREVIEW = gql`
  query GetFilePreview($id: Int!) {
    getFilePreview(id: $id) {
      name
      type
      url
      userId
      regionComments{
        id
        start
        end
        comment
        color
        data
        createdAt
        user {
          id
          firstName
          lastName
          image
        }
      }
    }
  }
`

export const GET_PROJECTS = gql`
  query GetProjects {
    getProjects {
      id
      userId
      isFavourite
      artist {
        id
        name
      }
      name
      image
      fileCount
      createdAt
      updatedAt
      commentCount
    }
  }
`
export const GET_PROJECTS_AND_FILES = gql`
  query GetProjectsAndFiles {
    getProjects {
      id
      userId
      artist {
        id
        name
      }
      name
      image
      fileCount
      files {
        id
        projectId
        project {
          name
        }
        type
        name
        prettySize
        isPreviewable
        status
        createdAt
        commentCount
      }
      folders {
        id
        projectId
        name
        readonly
        recording {
          id
        }
        files {
          id
          projectId
          type
          name
          prettySize
          isPreviewable
          status
          createdAt
          commentCount
        }
        createdAt
        commentCount
      }
      updatedAt
    }
  }
`

export const GET_SONGS = gql`
query GetSongs($userId: Int) {
  getSongs(userId: $userId) {
          ...SongFields

  }
}

   fragment SongFields on Song {
  id
  userId
  image
  isFavourite
  isShared
  type {
    id
    name
  }
  typeUserDefinedValue
  fromRecording
  credits {
    ...CreditTableFields
    instrument {
      id
      name
    }
    split
    featureType
  }
  recordings{
    id
    name
    folderId
    projectId
    party {
      id
      name
    }
    song {
      id
      title
    }
    sessions {
      id
      startedAt
      endedAt
    }
    subtitle
    type {
      id
      name
    }
    typeUserDefinedValue
    description
    isrc
    version
    recordedOn
    mixedOn
    duration
    language {
      id
      code
      name
    }
    keySignature
    timeSignature
    tempo
    createdAt
    updatedAt
    commentCount
  }
  iswc
  createdOn
  title
  subtitle
  titleAlt
  subtitleAlt
  notes
  description
  lyrics
  createdAt
  updatedAt
}

  fragment CreditTableFields on Credit {
  id
  party {
    id
    name
    isni
  }
  role {
    id
    name
  }
  instrument {
    id
    name
  }
  creditRoleUserDefinedValue
  contributionType
  contribution {
    ... on Song {
      id
      name
    }
    ... on Project {
      id
      name
    }
    ... on Recording {
      id
      name
    }
    ... on Session {
      id
      name
    }
  }
}
`

export const GET_SONGS_PAGINATED = gql`
  query GetSongsPaginated($userId: Int, $count: Int) {
    getSongsPaginated(
      userId: $userId
      count: $count
      ordering: { field: "created_at", direction: "desc" }
    ) {
      data {
        _id
        id
        userId
        title
        subtitle
        iswc
        createdAt
        type {
          id
          name
        }
        titleAlt
        subtitleAlt
      }
    }
  }
`

export const GET_SONG = gql`
  query GetSong($id: ID!) {
    getSong(id: $id) {
      ...SongFields
    }
  }
  ${fragment.SONG_FIELDS}
`

export const GET_SONG_CREDITS = gql`
  query GetSongCredits($id: ID!) {
    getSong(id: $id) {
      id
      credits {
        ...CreditTableFields
      }
    }
  }
  ${fragment.CREDIT_TABLE_FIELDS}
`

export const GET_RECORDING = gql`
  query GetRecording($id: ID!) {
    getRecording(id: $id) {
      ...RecordingFields
    }
  }
  ${fragment.RECORDING_FIELDS}
`

export const GET_SESSION = gql`
  query GetSession($id: ID!) {
    getSession(id: $id) {
      ...SessionFields
    }
  }
  ${fragment.SESSION_FIELDS}
`

export const GET_PARTIES = gql`
  query GetParties($type: String) {
    getParties(type: $type) {
      id
      userId
      type
      name
      isni
      is_my
    }
  }
`


export const SEARCH_PARTIES = gql`
  query searchParties($term: String!) {
    searchParties(term: $term) {
      id
      userId
      type
      name
      isni
      is_my
    }
  }
`

export const GET_PARTY = gql`
  query GetParty($id: ID!) {
    getParty(id: $id) {
      ...PartyFields
    }
  }
  ${fragment.PARTY_FIELDS}
`


export const GET_SUBSCRIPTION_BILLING = gql`
  query SubscriptionBilling {
    subscriptionBilling {
        created
        description
        amount
        discountCode
        currency
        invoiceNumber
        invoicePdf
        tier
    }
  }
`
export const GET_PARTY_ARTIST = gql`
  query GetMyParty {
   getMyParty{
      ...PartyFields
   }
  }
  ${fragment.PARTY_FIELDS_ARTIST}
`

export const GET_SONG_TYPES = gql`
  query GetSongTypes {
    getSongTypes {
      id
      name
      userDefined
    }
  }
`

export const GET_PARTY_ADDRESS = gql`
  query GetPartyAddress($id: ID!) {
    getPartyAddress(id: $id) {
      id
      line1
      line2
      line3
      city
      district
      postalCode
      country {
        id
        isoCode
        name
      }
    }
  }
`

export const GET_PARTY_CONTACT = gql`
  query GetPartyContact($id: ID!) {
    getPartyContact(id: $id) {
      id
      name
      type
      value
    }
  }
`

export const GET_SESSION_TYPES = gql`
  query GetSessionTypes {
    getSessionTypes {
      id
      name
    }
  }
`

export const GET_VENUES = gql`
  query GetVenues {
    getVenues {
      id
      name
    }
  }
`

export const GET_PROJECT_CREDIT_OVERVIEW = gql`
  query GetCredits($projectId: ID!, $contributionType: String) {
    getCreditsByProject(projectId: $projectId, contributionType: $contributionType) {
      id
      party {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
`

export const GET_ALL_PROJECT_CREDITS = gql`
  query GetCredits($projectId: ID!, $contributionType: String) {
    getCreditsByProject(projectId: $projectId, contributionType: $contributionType) {
      ...CreditTableFields
    }
  }
  ${fragment.CREDIT_TABLE_FIELDS}
`

export const GET_INSTRUMENTS = gql`
  query GetInstruments {
    getInstruments {
      id
      name
      userDefined
    }
  }
`

export const GET_CREDIT_ROLES = gql`
  query GetCreditRoles($type: String, $isProject: Boolean) {
    getCreditRoles(type: $type, ordering: { field: "name", direction: "asc" },isProject: $isProject) {
      id
      name
      userDefined
    }
  }
`

export const GET_COLLABORATORS = gql`
  query GetCollaborators($projectId: ID!) {
    getCollaborators(projectId: $projectId) {
      id
      type
      user {
        id
        name
        email
      }
      name
      email
      accepted
    }
  }
`

export const GET_RECORDING_COLLABORATORS = gql`
  query GetCollaboratorsByRecording($recordingId: ID!) {
    getCollaboratorsByRecording(recordingId: $recordingId) {
      id
      type
      user {
        id
        name
        email
      }
      name
      email
      accepted
    }
  }
`;

export const GET_COLLABORATOR = gql`
  query GetCollaborator($collaboratorId: ID!) {
    getCollaborator(id: $collaboratorId) {
      id
      type
      user {
        id
        name
        email
      }
      name
      email
      accepted
      permissions {
        type
        level
      }
      recordings {
        id
        name
      }
    }
  }
`

export const GET_CREDIT = gql`
  query GetCredit($creditId: ID!) {
    getCredit(id: $creditId) {
      id
      contributionId
      contributionType
      featureType
      split
      party {
        id
        name
      }
      contribution {
        ... on Song {
          id
          name
        }
        ... on Project {
          id
          name
        }
        ... on Recording {
          id
          name
        }
        ... on Session {
          id
          name
        }
      }
      instrument {
        id
        name
      }
      instrumentUserDefinedValue
      role {
        id
        name
      }
      creditRoleUserDefinedValue
    }
  }
`

export const GET_RECENT_OVERVIEW = gql`
  query GetRecents($default: Boolean) {
    getRecents(count: 5, default : $default) {
      id
      resourceId
      resourceType
      updatedAt
      resource {
        ... on Project {
          id
          name
          artist {
            id
            name
          }
        }
        ... on Recording {
          id
          projectId
          project {
            id
            name
            artist {
              id
              name
            }
          }
          name
        }
        ... on Session {
          id
          projectId
          project {
            id
            name
            artist {
              id
              name
            }
          }
          name
        }
        ... on Song {
          id
          name
        }
        ... on File {
          id
          projectId
          project {
            id
            name
            artist {
              id
              name
            }
          }
         folderId
          name
        }
        ... on Folder {
          id
          projectId
          project {
            id
            name
            artist {
              id
              name
            }
          }
          name
        }
        ... on Song {
          id
          name
        }
      }
    }
  }
`

export const GET_FAVOURITES_OVERVIEW = gql`
  query GetUserFavourites {
    getUserFavourites(count: 5, ordering: { field: "created_at", direction: "desc" }) {
      data {
        id
        resourceId
        resourceType
        favoured {
          ... on Project {
            id
            name
            updatedAt
            artist {
              id
              name
            }
          }
          ... on Recording {
            id
            projectId
            project {
              id
              name
              artist {
                id
                name
              }
            }
            name
            updatedAt
          }
          ... on Session {
            id
            projectId
            name
            project {
              id
              name
              artist {
                id
                name
              }
            }
            updatedAt
          }
          ... on File {
            id
            projectId
            project {
              id
              name
              artist {
                id
                name
              }
            }
            folderId
            name
            updatedAt
          }
          ... on Folder {
            id
            name
            projectId
            project {
              id
              name
              artist {
                id
                name
              }
            }
            updatedAt
          }
        }
      }
    }
  }
`

export const GET_FAVOURITES = gql`
  query GetUserFavourites(
    $count: Int!
    $orderField: String!
    $orderDirection: String!
    $resourceType: String
  ) {
    getUserFavourites(
      resourceType: $resourceType
      count: $count
      ordering: { field: $orderField, direction: $orderDirection }
    ) {
      data {
        favoured {
          ... on Playlist {
            id
            userId
            title
            description
            image
            isFavourite
            user {
              id
              name
              email
            }
            files {
              id
              duration
              projectId
              type
              name
              prettySize
              isPreviewable
              status
              createdAt
              commentCount
            }
            createdAt
            updatedAt
          }
          ... on Project {
            id
            name
            image
            updatedAt
            artist {
              id
              name
            }
          }
          ... on Recording {
            id
            project {
              id
              name
              artist {
                id
                name
              }
            }
            projectId
            name
            subtitle
            isrc
            recordedOn
            createdAt
            updatedAt
          }
          ... on Session {
            id
            _id
            name
            projectId
            project {
              id
              name
              artist {
                id
                name
              }
            }
            startedAt
            endedAt
            venue {
              id
              name
            }
            sessionType {
              id
              name
            }
            createdAt
            updatedAt
          }
          ... on File {
            ...FileTableFields
            project {
              id
              name
              artist {
                id
                name
              }
            }
          }
          ... on Folder {
            id
            name
            project {
              id
              name
              artist {
                id
                name
              }
            }
            updatedAt
          }
          ... on Song {
            id
            user {
              id
            }
            name
            updatedAt
            image
            title
            isFavourite
          }
        }
      }
    }
  }
  ${fragment.FILE_TABLE_FIELDS}
`

export const GET_EVENT_LOGS_BY_PROJECT = gql`
  query GetEventLogsByProject($projectId: ID!, $page: Int) {
    getEventLogsByProject(
      projectId: $projectId
      page: $page
      ordering: { field: "created_at", direction: "desc" }
    ) {
      data {
        ...EventLogFields
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
  ${fragment.EVENT_LOG_FIELDS}
`

export const GET_UNREAD_ACTIVITY = gql`
  query GetUnreadActivity($projectId: ID!) {
    getUnreadActivity(projectId: $projectId) {
      count
    }
  }
`

export const GET_RECORDING_TYPES = gql`
  query GetRecordingTypes {
    getRecordingTypes {
      id
      name
      userDefined
    }
  }
`

export const SEARCH_USER_FILES = gql`
  query SearchUserFiles($term: String!) {
    searchUserFiles(term: $term) {
      ...FileTableFields
    }
  }
  ${fragment.FILE_TABLE_FIELDS}
`
export const SEARCH_USER_EMAIL = gql`
query SearchUserEmail($email: String!) {
  searchUserEmail(email: $email) {
      success
      message
  }
}
`

export const SEARCH_PROJECTS = gql`
  query SearchProjects($term: String!) {
    searchProjects(term: $term) {
      id
      name
      artist {
        id
        name
      }
    }
  }
`

export const SEARCH_PROJECT_FILES = gql`
  query SearchProjectFiles($term: String!) {
    searchProjectFiles(term: $term) {
      ...FileTableFields
      project {
        id
        name
        artist {
          id
          name
        }
      }
    }
  }
  ${fragment.FILE_TABLE_FIELDS}
`

export const SEARCH_RECORDINGS = gql`
  query SearchRecordings($term: String!) {
    searchRecordings(term: $term) {
      ...RecordingTableFields
    }
  }
  ${fragment.RECORDING_TABLE_FIELDS}
`

export const SEARCH_SESSIONS = gql`
  query SearchSessions($term: String!) {
    searchSessions(term: $term) {
      ...SessionTableFields
      sessionType {
        name
      }
      project {
        id
        name
        artist {
          id
          name
        }
      }
    }
  }
  ${fragment.SESSION_TABLE_FIELDS}
`

export const SEARCH_SONGS = gql`
  query SearchSongs($term: String!) {
    searchSongs(term: $term) {
      id
      userId
      type {
        id
        name
      }
      typeUserDefinedValue
      iswc
      createdOn
      title
      subtitle
      titleAlt
      subtitleAlt
      notes
      description
      lyrics
      createdAt
      updatedAt
    }
  }
`
export const SEARCH_USERS_BY_EMAIL = gql`
query SearchUsers($input: SearchUserInput!) {
  searchUsers(input: $input) {
          email
  }
}
`

export const SEARCH_USERS_BY_NAME = gql`
query SearchUsers($input: SearchUserInput!) {
  searchUsers(input: $input) {
          name
  }
}
`

export const GET_LANGUAGES = gql`
  query GetLanguages {
    getLanguages {
      id
      code
      name
    }
  }
`

export const GET_PLATFORM_IMAGES = gql`
  query GetPlatformImages {
    getPlatformImages {
      id
      location
      tier
      fullPath
      caption
      altText
      price
      name
      use
      features
      createdAt
      updatedAt
    }
  }
`

export const GENERATE_SESSION_CODE = gql`
  query GenerateSessionCode($sessionId: ID!) {
    generateSessionCode(input: { session_id: $sessionId }) {
      sessionCode {
        id
        code
        expiresAt
      }
    }
  }
`

export const VALIDATE_DOWNLOAD = gql`
  query ValidateDownload($uuid: String!) {
    validateDownload(
      uuid: $uuid
    ) {
      expired
      url
    }
  }
`

export const GET_TIMEZONE_TYPES = gql`
  query GetTimezone {
    getTimezone {
        id
        offset
        timezone
    }
  }
`

export const GET_USER_AFFILIATIONS_LIST = gql`
  query GetUserAffiliations {
    getUserAffiliations {
        number
        affiliation {
            name
            type
        }
        status
        createdAt
        updatedAt
        id
    }
  }
`

export const GET_AFFILIATIONS_TYPE_LIST = gql`
  query GetAffiliations {
    getAffiliations{
        id
        name
        type
    }
  }
`

export const GET_PPL = gql`
  query GetPPL (
  $route: String
  ) {
    getPPL(
    route: $route
    ){
      url
  }
}
`
export const GET_PUBLISHERS = gql`
  query GetPublishers($partyId: ID!) {
    getPublishers (party_id: $partyId) {
        ...PublisherFields
    }
  }
  ${fragment.PUBLISHER_FIELDS}
`
export const GET_USER_CUSTOM_DOWNLOAD_PAGE = gql`
query GetUserCustomDownloadPage {
    getUserCustomDownloadPage {
        image
    }
}
`
export const GET_PUBLIC_SHARE_FILES = gql`
  query publicSharePreviewFiles($publicShareId: ID!) {
    publicSharePreviewFiles(publicShareId: $publicShareId) {
      expired
      files {
        name
        type
        size
        url
      }
    }
  }
`
export const GET_FOLDER_STATUS = gql`
  query GetFoldersStatus($input: [FolderStatusInput]!) {
    getFoldersStatus(input: $input) {
      success
      message
    }
  }
`
export const VALIDATE_LINK = gql`
  query validateShareDirectLink(
    $uuid: String!
    $password: String
  ) {
    validateShareDirectLink(
      input: {
        uuid: $uuid
        password: $password
      }
    ) {
      isPasswordRequired
      success
      errors {
        isShareInvalid
        isShareNotComplete
        isExpired
        isPasswordInvalid
      }
      url
      __typename
  }
}
`

export const RECENTLY_DELETED = gql`
  query GetDeletedFiles($folderId: Int!) {
    getDeletedFiles(
      folderId: $folderId
    ) {
      id
    projectId
    folderId
    folder{
      id
    projectId
    name
    readonly
    createdAt
    commentCount
    recording {
      id
    }
    createdAt
    commentCount
    }
    type
    name
    path
    transcoded_path
    bitrate
    bitdepth
    size
    prettySize
    status
    createdAt
    commentCount
    isPreviewable
      }
  getDeletedFolders(
      folderId: $folderId
    ) {
    id
    readonly
  projectId
  project{
    id
    name
  }
  parentId
  name
  createdAt
  commentCount
  files{
    id
    projectId
    type
    name
    prettySize
    isPreviewable
    status
    createdAt
    commentCount
  }
  depth
  path {
            id
            name
        }
    }

  }
`
export const CHECK_RESTORE = gql`
query CheckRestoreFiles($files: [RestoreFilesInput!]!) {
  checkRestoreFiles(files: $files) {
    success
  }
}
  `
export const VALIDATE_DISCOUNT_CODE = gql`
query ValidateCouponCode($couponCode: String!) {
  validateCouponCode(couponCode:$couponCode){
       valid
       percentOff
       currency
       amountOff
       type
       message
  }
}
`
export const GET_INTEGRATION_LOG = gql`
query GetIntegrationLog($projectId: ID!) {
  getIntegrationLog(projectId: $projectId) {
    pdf
    rin
    sx
  }
}
`
export const VALIDATE_RIN = gql`
query ValidateRIN($project_id: ID){
  validateRIN(projectId: $project_id){
    success
    message
  }
}
`
export const GET_SOCIALS_LIST = gql`
query GetSocialsList {
    getSocialsList{
        id
        name
    }
}
`
export const GET_FEATURE_TYPES = gql`
query GetFeatureType($id: ID) {
  getFeatureType(creditRoleId: $id) {
      type
  }
}
`
export const GET_DELETED_FOLDER = gql`
query GetDeletedFolder($folderId: Int!) {
  getDeletedFolder(
        id: $folderId
      ) {
      id
    projectId
    parentId
    name
    depth
      parentId
    path {
              id
              name
          }
      }

    }`
export const GET_USER_SOCIALS_LIST = gql`
query GetUserSocials {
    getUserSocials{
        ...UserSocialFields
    }
}

fragment UserSocialFields on UsersSocial {
    id
    socialUrl
    social {
        id
        name
        preUrl
    }
    isOn
}
`
export const GET_USER_SOCIALS = gql`
  query GetUserSocials {
    getUserSocials{
      ...UserSocialFields
    }
  }
  ${fragment.UserSocialFields}
  `
export const GET_ALL_PLAYLISTS = gql`
query getPlaylists{
  getPlaylists{
    ...PlaylistFields
  }
}

  fragment PlaylistFields on Playlist {
  id
  userId
  title
  description
  isFavourite
  image
  user {
    id
    name
    email
  }
  files{
    id
    projectId
    type
    name
    prettySize
    isPreviewable
    status
    createdAt
    commentCount
  }
  createdAt
  updatedAt
}`
export const GET_PLAYLIST = gql`
query getPlaylist($id: ID!){
  getPlaylist(id: $id){
    ...PlaylistFields
  }
}

  fragment PlaylistFields on Playlist {
  id
  userId
  title
  description
  image
  isFavourite
  user {
    id
    name
    email
  }
  files {
    id
    projectId
    regionCommentCount
    folderId 
    type
    name
    prettySize
    isPreviewable
    duration
    status
    createdAt
    duration
    commentCount
  }
  createdAt
  updatedAt
}
`
export const GET_SHARE_SOCIALS = gql`
  query GetShareSocials ($uuid: String!) {
    getShareSocials (uuid: $uuid){
      ...UserSocialFields
    }
  }
  ${fragment.UserSocialFields}
`

export const GET_SHARE_PLAYLIST = gql`
query GetSharePlaylist(
  $uuid: String!
  $token: String!
  $password: String
) {
  getSharePlaylist(
    input: {
      uuid: $uuid
      token: $token
      password: $password
    }
  ) {
    isPasswordRequired
    success
    errors {
      isShareInvalid
      isExpired
      isPasswordInvalid
      isTokenInvalid
    }
    playlist {
        image
        id
        description
        title
        user {
          name
          image
          id
        }
        files {
            id
            name
            type
            duration
            regionCommentCount
            prettySize
            createdAt
        }
    }
    __typename
  }
}`

export const GET_PUBLIC_FILE_PREVIEW = gql`
query GetFilePublicPreview($id: Int!, $uuid: String) {
  getFilePublicPreview(id: $id, uuid: $uuid) {
    name
    type
    url
    regionComments{
      id
      start
      end
      comment
      color
      data
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
  }
}`

export const SEARCH_PLAYLISTS = gql`
query SearchPlaylists($term: String!) {
  searchPlaylists(term: $term) {
    id
    title
    description
    userId
    files {
      _id
     }
  }
}
`

export const GET_USER_CUSTOM_DOWNLOAD_PAGE_PUBLIC = gql`
query GetUserCustomDownloadPagePublic ($uuid: String!) {
  getUserCustomDownloadPagePublic(uuid: $uuid) {
    image
  }
}
`


export const GET_RECORDINGS_BY_SESSION = gql`
query GetRecordingsBySession($sessionId: ID!) {
  getRecordingsBySession(
    sessionId: $sessionId
    count: 5
  ) {
      ...RecordingTableFields
  }
}

${fragment.RECORDING_TABLE_FIELDS}

`

export const GET_REFERRAL_NOTIFICATION = gql`
query GetReferralNotification {
  getReferralNotification {
      success
      message
  }
}
`
export const GET_USER_REFERRAL_CODE = gql`
query GetUserReferralCode {
  getUserReferralCode {
    success
    code
    count
    message
  }
}`


export const GET_REDEEMS = gql`
query GetRedeems {
  getRedeems {
    success
    message
    referralUserId
  }
}`


export const GET_SONG_INTEGRATION_LOG = gql `
query GetSongIntegrationLog ($songId: ID!){
  getSongIntegrationLog(songId: $songId){
    pdf
  }
}`

export default class PublicShareAPI {
    async sendVerificationToken(info) {
      try {
        let data = await fetch(
          `${process.env.REACT_APP_API}/api/public-share/send-verification-code`,
          {
            method: 'POST',
            body: this.createFormData(info),
          }
        )
        return data
      } catch (error) {
        console.log(error)
      }
    }

    async verifyEmail(info) {
        try {
          let data = await fetch(
            `${process.env.REACT_APP_API}/api/public-share/verify-email`,
            {
              method: 'POST',
              body: this.createFormData(info),
            }
          )
          return data
        } catch (error) {
          console.log(error)
        }
      }
  
    createFormData(body) {
      let formData = new FormData()
  
      Object.keys(body).forEach((key) => {
        formData.append(key, body[key])
      })
      return formData
    }
  }
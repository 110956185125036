import React from 'react'
import Helmet from 'react-helmet-async'
import { Mutation } from 'react-apollo'
import { DateTime } from 'luxon'
import { CREATE_PARTY } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import PartyForm from '../../components/PartyForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class PartyCreate extends React.Component {
  render() {
    const forSong = !!this.props.location.state && !this.props.location.state.uploadFiles
    return (
      <>
        <Helmet>
          <title>Create Party</title>
        </Helmet>
        <main className='main'>
            
          <CreatePagesHeader
            title='New Party'
            subtitle='Party Details'
          >
            <Breadcrumb items={
              !forSong ?
                [{
                  href: `/parties`,
                  name: 'Parties'
                },
                {
                  href: `/parties/create`,
                  name: 'New Party'
                }]
                :
                [{
                  href: `/songs`,
                  name: 'Songs'
                },
                {
                  href: `/songs/${this.props.location.state.songId}`,
                  name: this.props.location.state.title
                },
                {
                  href: `/songs/${this.props.location.state.songId}#credits`,
                  name: 'Credits'
                },
                {
                  href: `/songs/${this.props.location.state.songId}/credits/add`,
                  name: 'Add Credit'
                },
                {
                  name: 'Create Party'
                }]
            }
            />
          </CreatePagesHeader>
          <SectionContainer>
            <Mutation
              mutation={CREATE_PARTY}
              update={(cache, { data: { createParty } }) => {
                // eslint-disable-next-line no-unused-expressions
                (forSong ?
                // eslint-disable-next-line max-len
                  this.props.history.push({
                    pathname: `/songs/${this.props.location.state.songId}/credits/add`,
                    state: { party: createParty }
                  })
                  :
                  this.props.history.push(`/parties/${createParty.id}`)
                )
              }}>
              {(createParty, { loading, error }) => (
                <PartyForm
                  onSubmit={(state) => {
                    let data = { ...state }
                    Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                    let variables = {
                      ...data,
                      birthDate: data.birthDate ?
                        DateTime.fromFormat(data.birthDate, 'LL/dd/yyyy').toISODate() : null,
                      deathDate: data.deathDate ?
                        DateTime.fromFormat(data.deathDate, 'LL/dd/yyyy').toISODate() : null
                    }

                    if (!variables.birthDate) {
                      delete variables.birthDate
                    }

                    if (!variables.deathDate) {
                      delete variables.deathDate
                    }

                    createParty({
                      variables
                    }).catch(() => {

                    })
                  }}
                  loading={loading}
                  errors={error} />
              )}
            </Mutation>
          </SectionContainer>
        </main>
      </>
    )
  }
}

export default GetUser(PartyCreate)

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { getUserFavouritesByType } from '../utils/selectors'
import GetUser from '../hocs/queries/GetUser'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import MyFiles from './MyFiles'
import { WithMoveFilesFrom } from '../hocs/MoveFiles'

const Folder = props => {
  const favourites = getUserFavouritesByType(props.user)

  const manualRerender = (value) => {
    this.setState({
      shouldRerender: `unel${Math.floor(Math.random() * 10)}`
    })
  }

  return (
    <>
      <Helmet>
        <title>Files</title>
      </Helmet>

      <main className='main files-share'>

        <MyFiles
          match={props.match}
          manualRerender={manualRerender}
          location={props.location}
          uploadModal={props.uploadModal}
          uploads={props.uploads}
          project={props.project}
          setProjectIdAndFolderId={
            props.uploads.actions.setProjectIdAndFolderId
          }
          fileFavourites={favourites.file || []}
          folderFavourites={favourites.folder || []}
          isFolder = {true}
          perPage= {100}
        />
      </main>
    </>
  )
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser,
  WithMoveFilesFrom
)(Folder)

import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    title: Field()
      .string()
      .required({ message: Errors.Required }),
    description: Field()
      .string(),
    thumbnail: Field()

  },
  { verbose: true, async: true }
)

import React, { useState } from 'react'
import { compose, Query } from 'react-apollo'

import GetPlatformImages from '../hocs/queries/GetPlatformImages'
import AppBranding from '../components/AppBranding'
import { VALIDATE_SHARE } from '../graphql/queries/share'
// Yea, this is the /download route and we're interacting with the share table.
// Because a /share route that handles downloads already existed. Go figure.
import DownloadShareForm from '../components/DownloadShareForm'
import LoadingDots from '../components/ui/LoadingDots'
import GetCustomDownloadPagePublic from '../hocs/queries/GetCustomDownloadPagePublic'
import ShowShareSocialIcons from '../components/ShowShareSocialIcons'
import DownloadShareExpired from '../components/DownloadShareExpired'
import download from '../assets/images/download-share.svg'
import defaultDownloadImage from '../assets/images/veva-collect-default-download-image.jpg'
import WithWaveformPreviewModal from '../hocs/WithWaveformPreviewModal '
import WithDownloadModal from '../hocs/WithDownloadModal'
import WithFilePreviewModal from '../hocs/WithFilePreviewModal'
import ImagePreview from '../assets/images/image-preview.svg'
import playlistTile from '../assets/images/playlist-tile.svg'
import playlistPauseCircle from '../assets/images/pause-play-circle.svg'
import PublicPlaylistAudioModal from '../components/PublicPlaylistAudioModal'
import FilePublicPreviewModal from '../components/FilePublicPreviewModal'
import PublicShareNavBar from '../components/PublicShareNavBar'
import DownloadFile from './DownloadFile'

function Download({
  platformImages,
  downloadFiles,
  customImages,
  match: {
    params: { uuid, encryptedEmail }
  },
  ...props
}) {
  // Background image for layout
  const [image] = platformImages.filter(i => i.location === 'login')

  const [filePlayingId, setFilePlayingId] = useState(null)
  const [fileSelectedId, setFileSelectedId] = useState(null)
  const [playIconToggle, setPlayIconToggle] = useState(false)
  const [file, setFile] = useState(null)
  const [openModal, setOpenModalFile] = useState(false)
  // Password form
  const [password, setPassword] = useState('')
  function handleDownloadShareFormSubmit({ password }) {
    setPassword(password)
  }
  const songImg = playIconToggle ? playlistPauseCircle : playlistTile
  const onDownload = url => {
    window.open(url, '_blank')
  }

  const handleFilePreview = row => {
    if (row) {
      if (['png', 'jpg', 'jpeg', 'avi', 'mov', 'mp4', 'ogg', 'wmv', 'webm'].indexOf(row.type.toLowerCase()) > -1) {
        setFileSelectedId(row.id)
        setOpenModalFile(true)
      }
      if (row.type === 'pdf') {
        return () =>
          downloadFiles([
            {
              id: parseInt(row.id, 10),
              type: 'file'
            }
          ])
      }
    }
  }

  const filePlayingIdHandler = ({ id }) => {
    setFilePlayingId(id)
  }

  const fileSelectedHandler = ({ id }) => {
    setFileSelectedId(id)
    setPlayIconToggle(!playIconToggle)
  }

  const showPlayer = curfile => {
    setFile(curfile)
    setFileSelectedId(curfile.id)
  }
  const doClose = () => {
    setFile(null)
    filePlayingIdHandler({ id: null })
    fileSelectedHandler({ id: null })
  }

  const closeFileModal = () => {
    setOpenModalFile(false)
  }

  const convertIntoMb = (sizeInBytes) =>{
    return (sizeInBytes / (1024 * 1024)).toFixed(2)
  }
  return (
    <div className='login share-download publicShare'>
      <div className='loginBackground'>
        {customImages.image ? (
          <img src={customImages.image} alt='customization' />
        ) : (
          <>
            <img src={defaultDownloadImage} alt={image.altText} />
            {image.caption && (
              <div className='loginBackgroundCredit'>{image.caption}</div>
            )}
          </>
        )}
      </div>
      <PublicShareNavBar />
      <div className='loginFormBackground'>
        <div className='loginFormWrapper'>
          <AppBranding />

          <Query
            query={VALIDATE_SHARE}
            fetchPolicy='network-only'
            variables={{
              uuid,
              encryptedEmail,
              password
            }}
          >
            {({ loading, error, data }) => {
              const share = data.validateShare || {}
              let feedbackMessage = ''
              // Something went wrong with the request itself.
              if (error) {
                return (
                  <p>
                    Something went wrong with our system and we couldn't
                    retrieve your files. Please try again later.
                  </p>
                )
              }

              // Let's get those files!
              // if (share.url) {
              //   window.location.href = share.url
              // }

              // Message to user
              if (loading) {
                feedbackMessage = <LoadingDots />
              } else if (password && share.errors.isPasswordInvalid) {
                feedbackMessage = 'Invalid password.'
              } else if (share.errors.isShareInvalid) {
                feedbackMessage = 'This link is invalid.'
              } else if (share.errors.isExpired) {
                return (
                  <>
                  <div className='loginForm download-share-expired'>
                    <DownloadShareExpired />
                  </div>
                    <ShowShareSocialIcons uuid={uuid} />
                    </>
                )
              } else if (share.errors.isUserInvalid) {
                feedbackMessage = 'You don’t have access to these files.'
              } else if (share.url) {
                // feedbackMessage = 'Your files are downloading.'
              }

              // Markup
              return (
                <>
                { share.success ?
                  <>
                  <div>
                    <DownloadFile
                      data={data}
                      convertIntoMb={convertIntoMb}
                      handleFilePreview={handleFilePreview}
                      showPlayer={showPlayer}
                      download={download}
                      onDownload={onDownload}
                      ImagePreview={ImagePreview}
                      songImg={songImg}
                      share={share}
                      fileSelectedId={fileSelectedId}
                      filePlayingId={filePlayingId}
                      pauseButton={playlistPauseCircle}
                      playButton={playlistTile}
                    />
                  </div>
                  </>
                  :
                  <>
                   <div className='loginForm download-share-form'>
                     <DownloadShareForm
                       onSubmit={handleDownloadShareFormSubmit}
                     />
                   </div>
                  </>
                }
                { feedbackMessage &&
                  <p>{feedbackMessage}</p> }
                  <ShowShareSocialIcons uuid={uuid} />
                  {file && (
                    <PublicPlaylistAudioModal
                      open={file ? true : false}
                      onRequestClose={doClose}
                      uuid={uuid}
                      fromPlaylist={true}
                      file={data.validateShare.files}
                      filePlayingIdHandler={filePlayingIdHandler}
                      fileSelectedHandler={fileSelectedHandler}
                      filePlayingId={filePlayingId}
                      playing={filePlayingId !== null}
                      fileSelectedId={fileSelectedId}
                      setFile={setFile}
                    />
                  )}
                  {openModal && (
                    <FilePublicPreviewModal
                      open={openModal}
                      id={fileSelectedId}
                      uuid={uuid}
                      onRequestClose={closeFileModal}
                    />
                  )}
                </>
              )
            }}
          </Query>
        </div>
      </div>
    </div>
  )
}

export default compose(
  GetPlatformImages,
  GetCustomDownloadPagePublic,
  WithWaveformPreviewModal,
  WithDownloadModal,
  WithFilePreviewModal
)(Download)

import React from 'react'
import defaultIcon from '../../assets/images/addActive.svg'
import { useState, useRef, useEffect } from 'react'
function DropdownMenu(props) {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const dropdownRef = useRef(null)

  const handleDropdown = ()=>{
    setShowDropdownMenu(!showDropdownMenu)
  }
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdownMenu(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const { dropdownList, toggleBtn } = props
  const defaultBtn = toggleBtn ? toggleBtn : defaultIcon
  return (
    <div className='dropdown-container' ref={dropdownRef}>
      <span className='dropDown-Btn' onClick={handleDropdown}>
        <img
          className='dropdown-icon' alt='btn-img'
          src={defaultBtn}
        />
      </span>
      {showDropdownMenu && (<>
        <div className='arrow-1' />
        <div className='arrow-2' />
        <ul className='dropdown-menu'>
          {dropdownList.map(item => {
            return (
              <li className='dropdown-menu-item' onClick={item.click}>
                {item.icon && <img
                  className='dropdown-item-img hero-icon' alt='icon'
                  src={item.icon}
                />}
                <span className='dropdown-item-text'>{item.name}</span>
              </li>
            )
          })}
        </ul>
        </>
      )}
    </div>
  )
}

export default DropdownMenu

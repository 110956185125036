const sortParties = (field) => (a, b) => {
  const aName = a[field].toUpperCase()
  const bName = b[field].toUpperCase()

  if (aName < bName) {
    return -1
  }
  if (aName > bName) {
    return 1
  }
  return 0
}

const sortPartiesByName = sortParties('name')

export { sortParties, sortPartiesByName }

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'
import { DateTime } from 'luxon'

import { CREATE_RECORDING } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import RecordingForm from '../../components/RecordingForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import CreateSessionRecording from '../../hocs/mutations/CreateSessionRecording'

class RecordingCreate extends React.Component {
  constructor(props) {
    super(props)

    this.onRecordingCreated = this.onRecordingCreated.bind(this)
  }

  onRecordingCreated = async recording => {
    await this.props.createSessionRecording(
      this.props.location.state.session.id,
      recording.id
    )
    this.props.history.push(`/projects/${this.props.project.id}/sessions/${this.props.location.state.session.id}`)
  };


  render() {
    return (
      <>
        <Helmet>
          <title>Create Recording | {this.props.project.name}</title>
        </Helmet>
        <main className='main create-recording-main'>
          <CreatePagesHeader
            title='New Recording'
            subtitle='Recording Details'
          >

            <Breadcrumb items={[
              {
                href: `/projects`,
                name: 'Projects'
              },
              {
                href: `/projects/${this.props.project.id}`,
                name: this.props.project.name
              },
              {
                href: `/projects/${this.props.project.id}/recordings`,
                name: 'Project Recordings'
              },
              {
                href: `/projects/${this.props.project.id}/recordings/create`,
                name: 'New Recording'
              }
            ]} />
          </CreatePagesHeader>

          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={CREATE_RECORDING}
                update={(cache, { data: { createRecording } }) => {
                  if (this.props.location.state && this.props.location.state.projectDashboardPage) {
                    this.props.history.push(`/projects/${this.props.project.id}`)
                    return
                  }
                  if (this.props.location && this.props.location.state && this.props.location.state.sessionDetail) {
                    this.onRecordingCreated(createRecording)
                    return
                  }
                  this.props.history.push(`/projects/${this.props.project.id}/recordings/${createRecording.id}`)
                }}>
                {(createRecording, { loading, error }) => (
                  <RecordingForm
                    project={this.props.project}
                    onSubmit={(state) => {
                      let data = { ...state }
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      createRecording({
                        variables: {
                          ...data,
                          projectId: this.props.project.id,
                          recordedOn: data.recordedOn ?
                            DateTime.fromFormat(data.recordedOn, 'LL/dd/yyyy').toISODate() : null,
                          mixedOn: data.mixedOn ?
                            DateTime.fromFormat(data.mixedOn, 'LL/dd/yyyy').toISODate() : null
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error}
                    create={true}
                    party={this.props.project.artist} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  CreateSessionRecording
)(RecordingCreate)

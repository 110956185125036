import React from 'react'
import { compose, Query } from 'react-apollo'

import { GET_PROJECT_ROOT_FILES } from '../graphql/queries'

import * as permissions from '../utils/permissions'
import { getUserFavouritesByType } from '../utils/selectors'
import GetUser from '../hocs/queries/GetUser'
import GetProject from '../hocs/queries/GetProject'
import FileTable from './FileTable'
import SectionContainer from './SectionContainer'

import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'

class ProjectFilesList extends React.Component {
  render() {
    const favourites = getUserFavouritesByType(this.props.user)
    return (
      <>
        <div className='project-files'>
          <FileList
            match={this.props.match}
            location={this.props.location}
            project={this.props.project}
            fileFavourites={favourites.file || []}
            folderFavourites={favourites.folder || []}
            searchFile={this.props.searchFile}
          />
        </div>
      </>
    )
  }
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser,
  GetProject
)(ProjectFilesList)

class FileList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
  getSearchTerm = value => {
    this.setState({
      searchTerm: value
    })
  };

  render() {
    const { searchFile } = this.props
    const isProjectOwner = permissions.isProjectOwner(this.props.project.id)
    const can = permissions.can(this.props.project.id, 'file')

    const canRecording = permissions.can(this.props.project.id, 'recording')

    const canCreate = isProjectOwner || can.create
    const canDownload = isProjectOwner || can.download
    const canDelete = isProjectOwner || can.delete

    return (
      <>
        <Query
          query={GET_PROJECT_ROOT_FILES}
          variables={{
            projectId: this.props.project.id,
          }}
          fetchPolicy='cache-and-network'
        >
          {({ loading, error, data, refetch, networkStatus }) => {
            if (loading && networkStatus !== 6 && networkStatus !== 4) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            const project = data.getProject
            let filteredRows = []
            if(data && project && project.files && project.files.length) {
              const allFiles = project.files.length > 5 ? project.files.slice(-5) : project.files
              const files = allFiles ? allFiles.filter((file)=>file.name.toLowerCase().includes(searchFile.toLowerCase())) : []
              filteredRows = files.filter((file)=> file.status === 'complete')
              if(filteredRows.length < 5 && project.folders && project.folders.length) {
                let count = 5 - filteredRows.length
                let data = project.folders.length > count ? project.folders.slice(-count) :  project.folders
                filteredRows = [...filteredRows, ...data]
              }
            }

            if(!filteredRows.length && project && project.folders && project.folders.length) {
              const allFolders = project.folders.length > 5 ? project.folders.slice(-5) : project.folders
              filteredRows = allFolders ? allFolders.filter((file)=>file.name.toLowerCase().includes(searchFile.toLowerCase())) : []
            }

            return (
              <>
                <div className='sections-container'>
                  <SectionContainer>
                    <FileTable
                      nameCellClass='files-project-table'
                      rows={filteredRows || []}
                      searchTerm={this.state.searchTerm}
                      showSearch={false}
                      projectId={this.props.project.id}
                      folderId={this.props.match.params.folderId}
                      project={this.props.project}
                      fileFavourites={this.props.fileFavourites}
                      folderFavourites={this.props.folderFavourites}
                      showSkeleton={
                        loading
                      }
                      refetch={refetch}
                      refetchProject={refetch}
                      favourite
                      batchActions
                      actions
                      rowActions
                      canCreate={canCreate}
                      canDownload={canDownload}
                      canDelete={canDelete}
                      canCreateRecording={isProjectOwner || canRecording.create}
                      canDownloadRecording={
                        isProjectOwner || canRecording.download
                      }
                      canDeleteRecording={isProjectOwner || canRecording.delete}
                      canUpdateRecording={isProjectOwner || canRecording.update}
                      isProjectOwner={isProjectOwner}
                    />
                  </SectionContainer>
                </div>
              </>
            )
          }}
        </Query>
      </>
    )
  }
}

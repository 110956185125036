import React from 'react'
import DeleteFileIcon from './DeleteFileIcon'
import UploadFileIcon from './UploadFileIcon'
import { GetGradientFromId } from '../utils/constants'
import { memo } from 'react'


const getInitials = ({ firstName, lastName }) => {
  if (!firstName || !lastName) {
    return
  }
  const firstWord = firstName
  const secondWord = lastName
  let initials = ''
  if (firstWord.length > 0) {
    initials = initials + firstWord[0]
  }
  if ((secondWord || '').length > 0) {
    initials = initials + secondWord[0]
  }
  return initials
}

// Props

// title = title of the form Field
// file = currently selected file
// image = image got from database
// url
// removeImage = function to remove selected image if any
// upload image = select image

const DisplayPictureUpload = ({
  firstName,
  lastName,
  title,
  file,
  image,
  url,
  removeImage,
  uploadImage,
  loading,
  fromPlaylist,
  playlistTitle
}) => {
  const renderPictureSrc = () => {
    if (file !== null && file.url) {
      return file.url
    } else if (image) {
      return `${url}/${image}`
    }
    return ''
  }
  const playlistInitials = playlistTitle && playlistTitle.toUpperCase().replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g).join('').substr(0, 4)
  return (
    <div>
      <div className='formRow'>
        <label className='bx--label'>{title}</label>
        <div className='img-upload-section'>
          {image || file ? (
            <img
              src={renderPictureSrc()}
              alt='playlist thumbnail'
              className='song-img'
            />
          ) : (
            <div
              className='projectHeroThumbnail song-img-placeholder'
              style={{ background: GetGradientFromId() }}
            >
              {playlistInitials && <span className='projectCover'>{fromPlaylist ? playlistInitials : getInitials({ firstName, lastName })}</span>}
            </div>
          )}
          <div className='actions-container'>
            {(file || image) && <DeleteFileIcon onClick={removeImage} />}
            <UploadFileIcon onClick={uploadImage} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(DisplayPictureUpload)

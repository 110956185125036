import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { CREATE_COLLABORATOR } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import CollaboratorForm from '../../components/CollaboratorForm'
import SectionContainer from '../../components/SectionContainer'
import WithAlertModal from '../../hocs/WithAlertModal'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class CollaboratorCreate extends React.Component {
  constructor(props) {
    super(props)
    this.type = this.props.match.params.type || ''
  }

  render() {
    let titleName = this.type === 'recording' ? 'Recording' : 'Project'
    let recordingsSelected = this.props.location.state ? this.props.location.state.recordings : []
    return (
      <>
        <Helmet>
          <title>Add Collaborator | {this.props.project.name}</title>
        </Helmet>
        <main className='main'>

          <CreatePagesHeader
            title={ `Assign ${titleName} Collaborator to ${this.props.project.name}` }
            subtitle={`New ${titleName} Collaborator`}
          >
            {
              recordingsSelected.length < 1 && <Breadcrumb
                items={[
                  {
                    href: '/projects',
                    name: 'Projects'
                  },
                  {
                    href: `/projects/${this.props.project.id}`,
                    name: this.props.project.name
                  },
                  {
                    href: `/projects/${this.props.project.id}#collaborators`,
                    name: 'Collaborators'
                  },
                  {
                    href: `/projects/${this.props.project.id}/collaborators/create/${this.type}`,
                    name: `New ${titleName} Collaborator`
                  }
                ]}
              />
            }
            {
              recordingsSelected.length > 0 && <Breadcrumb
                items={[
                  {
                    href: '/projects',
                    name: 'Projects'
                  },
                  {
                    href: `/projects/${this.props.project.id}`,
                    name: this.props.project.name
                  },
                  {
                    href: `/projects/${this.props.project.id}/recordings`,
                    name: 'Recordings'
                  },
                  {
                    href: `/projects/${this.props.project.id}/recordings/${recordingsSelected[0].id}`,
                    name: recordingsSelected[0].name
                  },
                  {
                    href: `/projects/${this.props.project.id}/collaborators/create/${this.type}`,
                    name: `New ${titleName} Collaborator`
                  }
                ]}
              />

            }

          </CreatePagesHeader>

          <div className='sections-container'>
            <SectionContainer>
              <Mutation mutation={CREATE_COLLABORATOR}>
                {(createCollaborator, { loading, error }) => (
                  <CollaboratorForm
                    project={{ ...this.props.project }}
                    onSubmit={state => {
                      let recordings = []

                      if (state.type === 'recording') {
                        recordings = [...state.recordings].map(r => {
                          return { id: r.id }
                        })
                      }
                      let permissions = {}
                      if (state.type === 'normal') {
                        permissions = Object.keys(state.permissions).map((type) => {
                          let level = 'read'
                          if (state.permissions[type] === 1) {
                            level = 'download'
                          } else if (state.permissions[type] === 2) {
                            level = 'full'
                          }

                          return {
                            type,
                            level
                          }
                        })
                      }

                      createCollaborator({
                        variables: {
                          type: state.type,
                          name: state.name,
                          email: state.email,
                          projectId: this.props.project.id,
                          recordings: recordings,
                          permissions
                        }
                      })
                        .then(() => {
                          this.props.history.push(
                            `/projects/${this.props.project.id}#collaborators`
                          )
                        }).catch((errors) => {
                          this.props.alert(errors ? errors.message.replace('GraphQL error: ', '') :
                            'There was a problem adding this collaborator.'
                          )
                        })
                    }}
                    loading={loading}
                    errors={error}
                    type={this.type === 'recording' ? 'recording' : 'normal'}
                    recordingsSelected={recordingsSelected}
                  />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  WithAlertModal
)(CollaboratorCreate)

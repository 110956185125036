import React from 'react'
import { Slider as CompoundSlider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'

const sliderStyle = {
  color: 'white',
  height: 50,
  position: 'relative',
  width: '100%',
  maxWidth: 707
}

const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 10,
  marginTop: 10,
  borderRadius: 5,
  backgroundColor: '#1f1f1f'
}

const Handle = ({
  handle: { id, value, percent },
  getHandleProps
}) => (
  <div
    style={{
      backgroundColor: 'var(--accent-color)',
      border: 0,
      borderRadius: '50%',
      color: 'white',
      cursor: 'pointer',
      height: 27.5,
      left: (percent === 0 ? '15px' : (percent === 100 ? 'calc(100% - 9px)' : `${percent}%`)),
      marginLeft: -15,
      marginTop: 0,
      position: 'absolute',
      textAlign: 'center',
      width: 27.5,
      zIndex: 2
    }}
    {...getHandleProps(id)} />
)

const Track = ({ source, target, getTrackProps }) => (
  <div
    style={{
      backgroundColor: 'white',
      borderRadius: 5,
      cursor: 'pointer',
      height: 10,
      left: `${source.percent}%`,
      marginTop: 10,
      position: 'absolute',
      width: `${target.percent - source.percent}%`,
      zIndex: 1
    }}
    {...getTrackProps()}
  />
)

const Tick = ({ tick, count, names }) => {
  const name = (names[tick.value] ? names[tick.value] : tick.value)
  if (names[tick.value] === '') {
    return null
  }

  return (
    <div
      style={{
        fontSize: 10,
        left: `${tick.percent}%`,
        marginLeft: `${-(100 / count) / 2}%`,
        marginTop: 35,
        position: 'absolute',
        textAlign: 'center',
        width: `${100 / count}%`
      }}
    >
      {name}
    </div>
  )
}

const BuiltSlider = props => (
  <CompoundSlider
    rootStyle={sliderStyle}
    domain={[0, props.maxValue]}
    step={props.step || 1}
    values={[props.value ? props.value : 0]}
    onChange={props.onChange}
  >
    <Rail>
      {({ getRailProps }) => (
        <div style={railStyle} {...getRailProps()} />
      )}
    </Rail>
    <Handles>
      {({ handles, getHandleProps }) => (
        <div>
          {handles.map(handle => (
            <Handle
              key={handle.id}
              handle={handle}
              getHandleProps={getHandleProps}
            />
          ))}
        </div>
      )}
    </Handles>
    <Tracks right={false}>
      {({ tracks, getTrackProps }) => (
        <div>
          {tracks.map(({ id, source, target }) => (
            <Track
              key={id}
              source={source}
              target={target}
              getTrackProps={getTrackProps}
            />
          ))}
        </div>
      )}
    </Tracks>
    <Ticks count={props.maxValue}>
      {({ ticks }) => (
        <div style={{
          marginLeft: '15px',
          position: 'relative',
          width: 'calc(100% - 30px)'
        }}>
          {ticks.map(tick => (
            <Tick key={tick.id}
              tick={tick}
              count={ticks.length}
              names={props.tickNames} />
          ))}
        </div>
      )}
    </Ticks>
  </CompoundSlider>
)

const error = (id, text) => (
  <div className={`bx--form-requirement`} id={`${id}-error-msg`}>
    {text}
  </div>
)

const helper = text => (
  <div className={`bx--form__helper-text`}>{text}</div>
)

const Slider = React.memo(props => (
  <div className='bx--form-item'>
    <label htmlFor={props.id} className='bx--label slider-title'>{props.labelText}</label>
    {props.helperText && helper(props.helperText)}
    <BuiltSlider count={props.count}
      value={props.value}
      maxValue={props.maxValue}
      tickNames={props.tickNames}
      onChange={props.onChange}
      step={props.step} />
    <p className='slider-description'>{props.description.length > props.value && props.description[props.value]}</p>
    {props.invalid && error(props.id, props.invalidText)}
  </div>
))

export default Slider

import { Field, Schema } from 'v4f'

import * as Errors from '../utils/errors'

export default Schema(
  {
    emailto: Field()
      .string()
      .email({ message: Errors.Email })
      .required({ message: Errors.Required }),
    email: Field()
      .string()
      .email({ message: Errors.Email })
      .required({ message: Errors.Required }),
    title: Field().string().required({ message: Errors.Required }),
    message: Field().string()
  },
  { verbose: true, async: true }
)

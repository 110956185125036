import React from 'react'
import { Route } from 'react-router-dom'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'
import { GET_SONGS } from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import FileTable from '../components/FileTable'
import SectionContainer from '../components/SectionContainer'
import CreatePagesHeader from '../components/CreatePagesHeader'

class SongFiles extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
     getSearchTerm=(value)=>{
       this.setState({
         searchTerm: value
       })
     }
     getBreadcrumb(file, folders, currentFolder = false) {
       let breadcrumb = [
         {
           href: `/files`,
           name: 'Files'
         }
       ]

       let path = []
       if (currentFolder) {
         let hrefPath = `/files`

         if (currentFolder.path.length > 0) {
           currentFolder.path.forEach(item => {
             hrefPath = `${hrefPath}/${item.id}`
             breadcrumb.push({
               href: hrefPath,
               name: item.name
             })

             path.push(item.name)
           })
         }

         hrefPath = `${hrefPath}/${currentFolder.id}`
         breadcrumb.push({
           href: hrefPath,
           name: currentFolder.name
         })

         path.push(currentFolder.name)
       }

       return { path, breadcrumb }
     }

     render() {
       return (
         <>
          {this.props.location.pathname === this.props.match.url &&
            <Query query={GET_SONGS}
              variables={{
                userId: this.props.user.id
              }}
              fetchPolicy='cache-and-network'
            >
              {({ loading, error, data, refetch, networkStatus }) => {
                if (loading && networkStatus !== 6 && networkStatus !== 4) {
                  return null
                }

                if (error) {
                  return `Error! ${error.message}`
                }

                const songFilesTableRows = []

                data.getSongs.map(item => {
                  const newItem = item
                  newItem.name = `Song: ${newItem.title}`
                  newItem.songLink = true
                  songFilesTableRows.push(newItem)
                  return null
                })

                songFilesTableRows.sort((a, b)=>{
                  if (a.title < b.title) {
                    return -1
                  } else if (a.title > b.title) {
                    return 1
                  }
                  return 0
                })
                const songFilesRowLimit = songFilesTableRows.slice(0, 5)

                return (<>

            <CreatePagesHeader
              getSearchTerm={this.getSearchTerm}
              showSearch={true}
              subtitle='Song Files'
              linkText='View All'
              linkUrl={'files/songs'}
            />
            <div className='sections-container song-files project-files'>
              <SectionContainer>
                <FileTable
                  rows={window.location.pathname === '/files' ? songFilesRowLimit : songFilesTableRows}
                  nameCellClass='files-table'
                  manualRerender={this.props.manualRerender}
                  searchTerm={this.state.searchTerm}
                  showSearch={false}
                  projectId={null}
                  folderId={this.props.match.params.folderId}
                  fileFavourites={this.props.fileFavourites}
                  folderFavourites={this.props.folderFavourites}
                  showSkeleton={loading && networkStatus !== 6 && networkStatus !== 4}
                  refetch={refetch}
                  favourite
                  batchActions
                  actions
                  pagination={window.location.pathname !== '/files'}
                  canCreate={true}
                  canDownload={true}
                  canDelete={true}
                  comments={false}
                  sortDirection='ASC'
                  sortBy='Title'
                  perPage={5}
                  sortColumn='name'
                  songFiles
                />
              </SectionContainer>
            </div>
                </>)
              }}
            </Query>
          }
          <Route path={`${this.props.match.url}/:folderId`}
            render={(props) => (
              <SongFiles {...props}
                uploadModal={this.props.uploadModal}
                uploads={this.props.uploads}
                setProjectIdAndFolderId={this.props.setProjectIdAndFolderId}
                fileFavourites={this.props.fileFavourites}
                folderFavourites={this.props.folderFavourites} />
            )} />
        </>
       )
     }
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser
)(SongFiles)

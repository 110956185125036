import React from 'react'
import { Modal } from 'carbon-components-react'

const DiscardModal = props => {
  const { open, modalHeading, onRequestClose, handleSubmit } = props
  return (
    <Modal
      className='modal'
      open={open}
      modalHeading={modalHeading}
      onRequestClose={
        onRequestClose
      }
      primaryButtonText='Yes'
      secondaryButtonText='Cancel'
      onRequestSubmit={handleSubmit}
    >
      <p>Are you sure you want to discard changes?</p>
    </Modal>
  )
}

export default DiscardModal

import client from './Apollo'
import { createSelector } from 'reselect'

import { GET_USER, GET_PROJECT } from '../graphql/queries'

const getPermissionsByProjectId = createSelector(
  (user) => user.collaborators,
  (user) => user.updatedAt,
  collaborators => {
    let projects = {}
    collaborators.forEach(collab => {
      if (typeof projects[collab.projectId] === 'undefined') {
        projects[collab.projectId] = {}
      }

      collab.permissions.forEach(permission => {
        if (typeof projects[collab.projectId][permission.type] === 'undefined') {
          projects[collab.projectId][permission.type] = {
            create: false,
            read: false,
            update: false,
            delete: false,
            download: false
          }
        }

        projects[collab.projectId][permission.type][permission.level] = true
      })

      projects[collab.projectId].recordings = []
      if (collab.type === 'recording') {
        collab.recordings.forEach(recording => {
          projects[collab.projectId].recordings.push(recording.id)
        })
      }
    })

    return projects
  }
)

export const isProjectOwner = (projectId, user = false) => {
  let currentUser = user
  if (currentUser === false) {
    const { getUser } = client.readQuery({ query: GET_USER })
    currentUser = getUser
  }
  let getProject
  try {
    const query = client.readQuery({
      query: GET_PROJECT,
      variables: {
        id: projectId
      }
    })
    getProject = query.getProject
  } catch (e) {
    getProject = client.cache.data.data['Project:' + projectId]
    if (!getProject) {
      return false
    }
  }

  return getProject.userId === currentUser.id
}

export const retrieve = (projectId, resourceType) => {
  const { getUser } = client.readQuery({ query: GET_USER })
  const permissions = getPermissionsByProjectId(getUser)
  if (!permissions[projectId]) {
    return {}
  }
  return permissions[projectId][resourceType] || {}
}

export const canRead = (projectId, resourceType) => {
  return retrieve(projectId, resourceType).read === true
}

export const canCreate = (projectId, resourceType) => {
  return retrieve(projectId, resourceType).create === true
}

export const canUpdate = (projectId, resourceType) => {
  return retrieve(projectId, resourceType).update === true
}

export const canDelete = (projectId, resourceType) => {
  return retrieve(projectId, resourceType).delete === true
}

export const canDownload = (projectId, resourceType) => {
  return retrieve(projectId, resourceType).download === true
}

export const hasRecordingAccess = (projectId, recordingId) => {
  const recordingsPermissions = retrieve(projectId, 'recordings')
  return Array.isArray(recordingsPermissions) &&
    recordingsPermissions.indexOf(recordingId) > -1
}

export const hasFullAccessTo = (projectId, resource, user = false) => {
  let currentUser = user
  if (currentUser === false) {
    const { getUser } = client.readQuery({ query: GET_USER })
    currentUser = getUser
  }

  const permissions = getPermissionsByProjectId(currentUser)

  const levels = [
    'create',
    'read',
    'update',
    'delete',
    'download'
  ]

  let fullAccess = true

  levels.forEach(level => {
    const permissionExists = permissions[projectId]
      && permissions[projectId][resource]
      && permissions[projectId][resource][level]

    if (!permissionExists) {
      fullAccess = false
    }
  })

  return fullAccess
}

export const hasFullAccess = (projectId, user = false) => {
  let currentUser = user
  if (currentUser === false) {
    const { getUser } = client.readQuery({ query: GET_USER })
    currentUser = getUser
  }

  if (isProjectOwner(projectId, currentUser)) {
    return true
  }

  const permissions = getPermissionsByProjectId(currentUser)

  let fullAccess = true

  const resources = [
    // 'collaborator',
    'file',
    'recording',
    'session',
    'song'
  ]

  const levels = [
    'create',
    'read',
    'update',
    'delete'
  ]

  resources.forEach(resource => {
    if (!fullAccess) {
      return
    }

    levels.forEach(level => {
      const permissionExists = !permissions[projectId]
        || !permissions[projectId][resource]
        || !permissions[projectId][resource][level]

      if (permissionExists) {
        fullAccess = false
      }
    })
  })

  return fullAccess
}

export const can = (projectId, resourceType) => {
  return retrieve(projectId, resourceType)
}


// Filter Permissions to show only Specific Tags - All Access, Read, Download Tag

export const filterPermissions = (permissions, resourceType) => {
  let allPerm = permissions && Object.keys(permissions).every((cur)=>permissions[cur] === true || cur === 'download')
  if (allPerm) {
    return {
      'Full Access': true
    }
  }

  let access = {}

  if (permissions.read === true) {
    access.Read = true
  }

  if (resourceType === 'files' && permissions.download === true) {
    access.Download = true
  }

  return access
}


export const getTags = (permission)=> {
  const tags = []
  const permissionsKyes = Object.keys(permission)
  permissionsKyes.forEach(key => {
    if (permission[key] === true) {
      tags.push({
        tagName: key.charAt(0).toUpperCase() + key.slice(1)
      })
    }
  })
  return tags
}


export const getCollaboratorType = (projectId, collaborators)=> {
  let collaboratorType = ''
  collaborators.forEach(key => {
    if (key.projectId === projectId) {
      collaboratorType = key.type
    }
  })
  return collaboratorType
}

import React from 'react'
import {
  Form,
  Button,
  TextInput
} from 'carbon-components-react'

import ApolloClient from '../utils/Apollo'
import { SelectASync, SelectStandard as Select } from '../components/Select'
import { sortParties } from '../utils/sortParties'
import {
  GET_ALL_PROJECT_SESSIONS,
  GET_ALL_RECORDINGS,
  GET_INSTRUMENTS,
  GET_CREDIT_ROLES,
  GET_PARTIES,
  GET_FEATURE_TYPES
} from '../graphql/queries'

import Validator from '../utils/Validator'
import CreditValidator from '../validators/Credit'
import CreatePartyModal from './modals/CreatePartyModal'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'
import WithAlertModal from '../hocs/WithAlertModal'
import GetUser from '../hocs/queries/GetUser'
import DiscardModal from './modals/DiscardModal'

let initialCalcState = {}
let recordingOptions = []

class CreditForm extends Validator {
  constructor(props) {
    super(props)
    const newParty = this.props.location.state ? this.props.location.state.party : ''
    this.state = {
      recordingData: [],
      contributionType: '',

      contributionId: '',
      contributionName: '',
      contribution: {},
      contributionTypeOption: {},
      contributionIds: [],
      contributions: [],
      isSelectRecording: false,
      multiValue: [],

      creditRoleId: '',
      creditRoleName: '',
      creditRoleUserDefinedValue: '',
      role: {},

      instrumentId: '',
      instrumentName: '',
      instrument: {},

      partyId: '',
      partyName: '',
      featureType: '',
      feature: '',
      party: {},

      split: '',

      initialErrors: {},
      errors: {},
      sessionOptions: [],
      userDefinedRoles: [],
      userDefinedInstruments: [],
      featureTypeOptions: [],
      creditFormDiscardModal: false
    }
    this.contributionTypeOptions = [
      {
        value: 'project',
        label: 'Project'
      },
      {
        value: 'recording',
        label: 'Recording'
      },
      {
        value: 'session',
        label: 'Session'
      }
    ]
    if (this.props.role) {
      ApolloClient.query({
        query: GET_FEATURE_TYPES,
        variables: {
          id: this.props.role.id
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        const { type } = data.getFeatureType
        const typesList = []


        type.forEach(item =>{
          typesList.push({
            value: item,
            label: item.charAt(0).toUpperCase() + item.slice(1)
          })
        })
        this.state.featureTypeOptions = typesList
        initialCalcState.featureTypeOptions = typesList
        initialCalcState.feature = typesList[0]
        this.handleFeatureTypeChange(typesList[0])
        initialCalcState.featureType = typesList[0].value
      })
    } else {
      this.state.featureTypeOptions = []
    }

    this.handleContributionTypeChange = this.handleContributionTypeChange.bind(this)
    this.handleContributionIdChange = this.handleContributionIdChange.bind(this)
    this.handleContributionIdBlur = this.handleContributionIdBlur.bind(this)
    this.handleCreditRoleIdChange = this.handleCreditRoleIdChange.bind(this)
    this.handleInstrumentIdChange = this.handleInstrumentIdChange.bind(this)
    this.handleInstrumentIdBlur = this.handleInstrumentIdBlur.bind(this)
    this.handlePartyChange = this.handlePartyChange.bind(this)
    this.handleSplitChange = this.handleSplitChange.bind(this)
    this.getSessionOptions = this.getSessionOptions.bind(this)
    this.getRecordingOptions = this.getRecordingOptions.bind(this)
    this.getInstrumentOptions = this.getInstrumentOptions.bind(this)
    this.getRoleOptions = this.getRoleOptions.bind(this)
    this.getPartyOptions = this.getPartyOptions.bind(this)
    this.openCreatePartyModal = this.openCreatePartyModal.bind(this)
    this.closeCreatePartyModal = this.closeCreatePartyModal.bind(this)
    this.onPartyCreated = this.onPartyCreated.bind(this)

    this.validator = CreditValidator

    this.state.contributionId = this.props.contribution ? this.props.contribution.id : ''
    this.state.contributionName = this.props.contribution ? this.props.contribution.name : ''
    if (this.state.contributionId) {
      this.state.contribution = {
        value: this.state.contributionId,
        label: this.state.contributionName
      }
      if (this.props.isMulti) {
        this.state.contributionIds.push(parseInt(this.state.contributionId, 10))
        this.state.multiValue.push({
          value: this.state.contributionId,
          label: this.state.contributionName
        })
      }
    }

    this.state.creditRoleId = this.props.role ? this.props.role.id : ''
    this.state.creditRoleName = this.props.role ? this.props.role.name : ''
    this.state.creditRoleUserDefinedValue = this.props.creditRoleUserDefinedValue || ''
    if (this.state.creditRoleId) {
      this.state.role = {
        value: this.state.creditRoleId,
        label: this.state.creditRoleName
      }
    }

    this.state.instrumentId = this.props.instrument ? this.props.instrument.id : ''
    this.state.instrumentName = this.props.instrument ? this.props.instrument.name : ''
    this.state.instrumentUserDefinedValue = this.props.instrumentUserDefinedValue || ''
    if (this.state.instrumentId) {
      this.state.instrument = {
        value: this.state.instrumentId,
        label: this.state.instrumentName
      }
    }

    this.state.partyId = this.props.party ? this.props.party.id : newParty ? newParty.id : ''
    this.state.partyName = this.props.party ? this.props.party.name : newParty ? newParty.name : ''
    if (this.state.partyId) {
      this.state.party = {
        value: newParty ? newParty.id : this.state.partyId,
        label: newParty ? newParty.name : this.state.partyName
      }
    }

    this.state.contributionType = this.props.contributionType || ''
    if (this.props.contributionType) {
      this.state.contributionTypeOption = this.contributionTypeOptions.find(({ value })=> value === this.props.contributionType)
    }
    this.state.featureType = this.props.featureType || ''
    if (this.props.featureType) {
      this.state.feature = this.state.featureTypeOptions.find(({ value })=> value === (this.props.featureType))
      initialCalcState.feature = this.state.featureTypeOptions.find(({ value })=> value === (this.props.featureType))
    }


    this.state.split = this.props.split || ''
    this.discardChanges = this.discardChanges.bind(this)
    this.closeCreditFormDiscardModal = this.closeCreditFormDiscardModal.bind(this)
    this.openCreditFormDiscardModal = this.openCreditFormDiscardModal.bind(this)
    this.calculateInitialState = this.calculateInitialState.bind(this)
    this.calculateInitialState()
  }

  // Storing current state in a variable

  calculateInitialState() {
    initialCalcState = this.state
  }

  submit() {
    const { hideContributionType, hideRecordingsContributionType } = this.props
    if (hideContributionType) {
      this.setState({
        contributionType: 'session'
      })
    } else if (hideRecordingsContributionType) {
      this.setState({
        contributionType: 'recording'
      })
      if (this.props.isRecordingCredit && !this.state.contributionIds.length) {
        this.setState({
          ...this.state,
          isSelectRecording: true
        })
      }
    }
    if (this.state.contributionIds.length === 0 && this.props.isMulti) {
      this.handleSessionsDirty()
      return
    }
    this.props.onSubmit(this.state)
  }


  handleContributionTypeChange(e) {
    this.setState({
      ...this.state,
      contributionType: e.value,
      contributionId: (e.value === 'project' ? this.props.projectId : e.value === 'session' && this.props.contribution ? this.props.contribution.id : ''),
      errors: {
        ...this.state.errors,
        contributionType: undefined // eslint-disable-line no-undefined
      }
    })
  }
  handleFeatureTypeChange=(e)=> {
    this.setState({
      ...this.state,
      featureType: e.value,
      feature: e,
      errors: {
        ...this.state.errors,
        featureType: undefined // eslint-disable-line no-undefined
      }

    })
  }

  handleContributionIdChange(option) {
    if (this.props.contribution && this.props.isRecordingCredit) {
      const findContributionProps = option.findIndex(contribution =>
        contribution.value === this.props.contribution.value
      )
      if (findContributionProps === -1) {
        const contribution = this.state.contributions && this.state.contributions.filter((contribute, index) => index === 0 && contribute)
        this.setState({ ...this.state, contributions: contribution })
        return
      }
    }
    recordingOptions = option
    if (this.props) {
      if (this.props.contributionType === 'recording') {
        let mappedIds = []
        for (let entry of recordingOptions) {
          mappedIds.push(Number(entry.value))
        }
        this.setState({
          ...this.state,
          contributionIds: mappedIds,
          contributions: recordingOptions,
          isSelectRecording: true
        })
      } else {
        this.setState({
          ...this.state,
          contributionId: option.value,
          contribution: option,
          errors: {
            ...this.state.errors,
            contributionId: undefined // eslint-disable-line no-undefined
          }
        })
      }
    }
  }

  handleContributionIdBlur() {
    if (this.props.isRecordingCredit) {
      this.setState({
        ...this.state,
        isSelectRecording: true
      })
    } else {
      this.handleDirty({
        target: {
          name: 'contributionId',
          value: this.state.contributionId
        }
      })
    }
  }

  handleCreditRoleIdChange(option) {
    this.setState({
      ...this.state,
      creditRoleId: option.value,
      role: option,
      errors: {
        ...this.state.errors,
        creditRoleId: undefined // eslint-disable-line no-undefined
      }
    }, ()=>{
      ApolloClient.query({
        query: GET_FEATURE_TYPES,
        variables: {
          id: option.value
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        const { type } = data.getFeatureType
        const typesList = []

        type.forEach(item =>{
          typesList.push({
            value: item,
            label: item.charAt(0).toUpperCase() + item.slice(1)
          })
        })
        this.setState({
          featureTypeOptions: typesList
        }, this.handleFeatureTypeChange(typesList[0]))
        initialCalcState.featureType = typesList[0].value
      })
    })
  }

  handleInstrumentIdChange(option) {
    this.setState({
      ...this.state,
      instrumentId: option.value,
      instrument: option,
      errors: {
        ...this.state.errors,
        instrumentId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleInstrumentIdBlur() {
    this.handleDirty({
      target: {
        name: 'instrumentId',
        value: this.state.instrumentId
      }
    })
  }

  openCreatePartyModal = ()=> {
    this.setState({
      ...this.state,
      createPartyModalOpen: true
    })
  }

  closeCreatePartyModal() {
    this.setState({
      ...this.state,
      createPartyModalOpen: false
    })
  }

  onPartyCreated(party) {
    this.setState({
      ...this.state,
      createPartyModalOpen: false,
      party: {
        value: party.id,
        label: party.name
      },
      partyId: party.id,
      partyName: party.name
    })
  }

  handlePartyChange(option) {
    this.setState({
      ...this.state,
      partyId: option.value,
      party: option,
      errors: {
        ...this.state.errors,
        partyId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleSplitChange(e) {
    let value = e.target.value
    if (value.indexOf('.') > -1) {
      if (value.toString().split('.')[1].length > 2) {
        value = value.substring(0, value.length - (value.toString().split('.')[1].length - 2))
      }
    }
    this.setState({
      split: value
    })
  }

  getSessionOptions = projectId => inputValue =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_ALL_PROJECT_SESSIONS,
        variables: {
          projectId
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let sessions = []
        data.getSessions.data.forEach(session => {
          if (!session.name.toLowerCase().includes(inputValue.toLowerCase())) {
            return
          }
          sessions.push({
            value: session.id,
            label: session.name
          })
        })
        resolve(sessions)
      })
    })
    getMultiSelectSessionOptions = projectId =>
      new Promise(resolve => {
        ApolloClient.query({
          query: GET_ALL_PROJECT_SESSIONS,
          variables: {
            projectId
          },
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          let sessions = []
          data.getSessions.data.forEach(session => {
            sessions.push({
              value: session.id,
              label: session.name
            })
          })
          resolve(sessions)
        })
      })
     getRecordingOptions = (inputValue, projectId) =>
       new Promise(() => {
         ApolloClient.query({
           query: GET_ALL_RECORDINGS,
           variables: {
             projectId
           },
           fetchPolicy: 'network-only'
         }).then(({ data }) => {
           let recordings = []
           data.getRecordings.data.forEach(recording => {
             if (!recording.name.toLowerCase().includes(inputValue.toLowerCase())) {
               return
             }
             recordings.push({
               value: recording.id,
               label:
             `${recording.name} ${recording.version ? ` - ${recording.version}` : ''}`
             })
           })
           const recordingData = recordings.map(item=> {
             return { value: item.value, label: item.label }
           })
           this.setState({ recordingData: recordingData })
           initialCalcState.recordingData = recordingData
         })
       })

  getInstrumentOptions = inputValue =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_INSTRUMENTS,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let instruments = []
        let userDefinedInstruments = []
        data.getInstruments.forEach(instrument => {
          if (!instrument.name.toLowerCase().includes(inputValue.toLowerCase())) {
            return
          }

          if (instrument.userDefined) {
            userDefinedInstruments.push(instrument.id)
          }

          instruments.push({
            value: instrument.id,
            label: instrument.name
          })
        })

        this.setState({
          ...this.state,
          userDefinedInstruments
        })
        initialCalcState.userDefinedInstruments = userDefinedInstruments
        resolve(instruments)
      })
    })

  getRoleOptions = type => inputValue =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_CREDIT_ROLES,
        variables: {
          type,
          isProject: this.state.contributionType === 'project'
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let roles = []
        let userDefinedRoles = []
        data.getCreditRoles.forEach(role => {
          if (!role.name.toLowerCase().includes(inputValue.toLowerCase())) {
            return
          }

          if (role.userDefined) {
            userDefinedRoles.push(role.id)
          }

          roles.push({
            value: role.id,
            label: role.name
          })
        })

        this.setState({
          ...this.state,
          userDefinedRoles
        })
        initialCalcState.userDefinedRoles = userDefinedRoles

        resolve(roles)
      })
    })

    getPartyOptions = inputValue =>
      new Promise(resolve => {
        ApolloClient.query({
          query: GET_PARTIES,
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          let parties = []
          data.getParties.forEach(party => {
            const label = party.name
            if (!label.toLowerCase().includes(inputValue.toLowerCase())) {
              return
            }
            parties.push({
              value: party.id,
              label: party.is_my === true && this.props.user.id === party.userId ? `${label} (My Party)` : label
            })
          })
          parties = parties.sort(sortParties('label'))
          resolve(parties)
        })
      })


    discardChanges() {
      this.setState(initialCalcState)
    }

  compareCreditFormChange=()=> {
    if (this.state.partyId || this.state.creditRoleId ||
      this.state.instrumentId || this.state.featureType) {
      return false
    }

    this.setState({
      split: this.props.split,
      party: {
        value: this.props.party ? this.props.party.id : '',
        label: this.props.party ? this.props.party.name : ''
      },
      role: {
        value: this.props.role ? this.props.role.id : '',
        label: this.props.role ? this.props.role.name : ''
      },
      instrument: {
        value: this.props.instrument ? this.props.instrument.id : '',
        label: this.props.instrument ? this.props.instrument.name : ''
      },
      multiValue: [],
      creditFormDiscardModal: false
    })
  }

  openCreditFormDiscardModal(e) {
    e.preventDefault()

    let change = false
    Object.entries(initialCalcState).forEach(([key]) => {
      if (JSON.stringify(initialCalcState[key]) !== JSON.stringify(this.state[key])) {
        change = true
      }
    })

    if (change) {
      this.setState({
        creditFormDiscardModal: true
      })
    } else {
      this.props.history.goBack()
    }
  }

  closeCreditFormDiscardModal=()=> {
    this.setState({
      creditFormDiscardModal: false
    })
  }

  componentDidMount=()=> {
    if (this.props.projectId) {
      this.getMultiSelectSessionOptions(this.props.projectId).then(sessions => {
        this.setState({
          sessionOptions: [...sessions]
        })
        initialCalcState.sessionOptions = [...sessions]
      })
    }
    const { isRecordingCredit, contribution, contributionType } = this.props
    if (isRecordingCredit && contribution && contributionType === 'recording') {
      if (contribution) {
        let mappedIds = []
        let mappedContributions = []
        mappedIds.push(Number(contribution.value))
        mappedContributions.push(contribution)
        this.setState({
          ...this.state,
          contributionIds: mappedIds,
          contributions: mappedContributions,
          isSelectRecording: true
        })
        initialCalcState.contributionIds = mappedIds
        initialCalcState.contributions = mappedContributions
        initialCalcState.isSelectRecording = true
      }
    }
    if (this.props.projectId) {
      this.getRecordingOptions('', this.props.projectId)
    }
  }

  handleSessionsChange=(option)=> {
    if (this.props.fromSessionCreditCreate && option.length === 0) {
      this.setState({
        multiValue: this.state.contributionId ?
          [{ label: this.state.contributionName,
            value: this.props.contributionId }] : []
      })
      return
    }
    this.setState({
      ...this.state,
      contributionIds: option.map(({ value }) =>parseInt(value, 10)),
      multiValue: option,
      contributionId: option.length > 0 ? option[0].value : '',
      errors: {
        ...this.state.errors,
        contributionIds: undefined // eslint-disable-line no-undefined
      }
    })
  }
  handleSessionsDirty=()=> {
    if (this.state.contributionIds.length === 0) {
      this.setState({
        errors: {
          contributionIds: 'This field is required'
        }
      })
    }
  }

  render() {
    const { hideContributionType, hideRecordingsContributionType } = this.props
    let showField = true
    if (hideContributionType || hideRecordingsContributionType) {
      showField = false
    }

    const sessionDisabled = this.props.contribution && this.props.contribution.id ? 'select-disabled' : ''
    return (
      <div className='form creditForm'>
        <Form onSubmit={this.handleSubmit} autoComplete='off'>
          {(this.state.contributionType !== 'song' && this.state.contributionType !== 'session' && this.state.contributionType !== 'project'
          && showField && this.state.contributionType !== 'recording') &&
            <div className='formRow'>
              <Select
                id='contributionType'
                name='contributionType'
                labelText='* Contribution Type'
                onChange={this.handleContributionTypeChange}
                onBlur={this.handleDirty}
                defaultValue={this.state.contributionTypeOption}
                invalid={this.state.errors.contributionType ? true : false}
                invalidText={this.state.errors.contributionType}
                options={this.contributionTypeOptions} />
            </div>
          }
          <div className='formRow'>
            <SelectASync
              key={this.state.party.value}
              labelText='Party *'
              btnText='Create'
              btnOnClick={this.openCreatePartyModal}
              id='partyId'
              placeholder={this.state.contributionType === 'recording' ?
                'Select from list...' : 'Select...'}
              cacheOptions
              defaultOptions
              loadOptions={this.getPartyOptions}
              onChange={this.handlePartyChange}
              defaultValue={this.state.party.value ? this.state.party : null}
              invalid={this.state.errors.partyId ? true : false}
              invalidText={this.state.errors.partyId} />
          </div>
          {this.state.contributionType === 'project' &&
            <>
              <div className='formRow'>
                <SelectASync
                  labelText='Role *'
                  id='creditRoleId'
                  defaultOptions
                  loadOptions={this.getRoleOptions('NewStudioRole')}
                  onChange={this.handleCreditRoleIdChange}
                  invalid={this.state.errors.creditRoleId ? true : false}
                  invalidText={this.state.errors.creditRoleId}
                  value={this.state.role.value ? this.state.role : null}
                />

              </div>
              {this.state.userDefinedRoles.indexOf(this.state.role.value) > -1 &&
                <div className='formRow'>
                  <TextInput id='creditRoleUserDefinedValue'
                    name='creditRoleUserDefinedValue'
                    labelText='Role Name'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.creditRoleUserDefinedValue || ''}
                    invalid={this.state.errors.creditRoleUserDefinedValue ? true : false}
                    invalidText={this.state.errors.creditRoleUserDefinedValue || ''} />
                </div>
              }
            </>
          }
          {this.state.contributionType === 'session' &&
            <>
            {!this.props.isMulti && <div className={`formRow ${sessionDisabled}`}>
              <SelectASync
                labelText='Session *'
                id='contributionId'
                cacheOptions
                defaultOptions
                loadOptions={this.getSessionOptions(this.props.projectId)}
                onChange={this.handleContributionIdChange}
                onBlur={this.handleContributionIdBlur}
                defaultValue={this.state.contribution.value ? this.state.contribution : null}
                key={this.state.contribution.value}
                invalid={this.state.errors.contributionId ? true : false}
                invalidText={this.state.errors.contributionId} />
            </div>
            }
             {this.props.isMulti && <div className='formRow'>
               <Select
                 isMulti
                 name='contributionIds'
                 placeholder='Select from list ...'
                 value={this.state.multiValue}
                 options={this.state.sessionOptions.length ? this.state.sessionOptions : []}
                 onChange={this.handleSessionsChange}
                 key={JSON.stringify(this.state.sessionOptions)}
                 id='contributionIds'
                 cacheOptions={false}
                 defaultOptions
                 onBlur={this.handleSessionsDirty}
                 invalid={this.state.errors.contributionIds ? true : false}
                 invalidText={this.state.errors.contributionIds || ''}
                 labelText='Assign Credit to Session(s)*'
               />
             </div>
             }
              <div className='formRow'>
                <SelectASync
                  labelText='Role *'
                  id='creditRoleId'
                  defaultOptions
                  key={this.state.role.value}
                  loadOptions={this.getRoleOptions('NewStudioRole')}
                  onChange={this.handleCreditRoleIdChange}
                  defaultValue={this.state.role.value ? this.state.role : null}
                  invalid={this.state.errors.creditRoleId ? true : false}
                  invalidText={this.state.errors.creditRoleId} />
              </div>
              {this.state.userDefinedRoles.indexOf(this.state.role.value) > -1 &&
                <div className='formRow'>
                  <TextInput id='creditRoleUserDefinedValue'
                    name='creditRoleUserDefinedValue'
                    labelText='Role Name'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.creditRoleUserDefinedValue || ''}
                    invalid={this.state.errors.creditRoleUserDefinedValue ? true : false}
                    invalidText={this.state.errors.creditRoleUserDefinedValue || ''} />
                </div>
              }
              <div className='formRow'>
                <SelectASync
                  labelText='Instrument'
                  id='instrumentId'
                  cacheOptions
                  defaultOptions
                  key={this.state.instrument.value}
                  loadOptions={this.getInstrumentOptions}
                  onChange={this.handleInstrumentIdChange}
                  onBlur={this.handleInstrumentIdBlur}
                  defaultValue={this.state.instrument.value ? this.state.instrument : null}
                  invalid={this.state.errors.instrumentId ? true : false}
                  invalidText={this.state.errors.instrumentId} />
              </div>
              {this.state.userDefinedInstruments.indexOf(this.state.instrument.value) > -1 &&
                <div className='formRow'>
                  <TextInput id='instrumentUserDefinedValue'
                    name='instrumentUserDefinedValue'
                    labelText='Instrument Name'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.instrumentUserDefinedValue || ''}
                    invalid={this.state.errors.instrumentUserDefinedValue ? true : false}
                    invalidText={this.state.errors.instrumentUserDefinedValue || ''} />
                </div>
              }
            </>
          }
          {this.state.contributionType === 'recording' &&
            <>
              <div className='formRow recording-multiselect'>
                <Select
                  isMulti
                  id='contributionId'
                  name='recordings'
                  value={this.state.contributions.length ? this.state.contributions : []}
                  options={this.state.recordingData}
                  onChange={this.handleContributionIdChange}
                  key={this.state.contributionIds}
                  placeholder='Select multiple from list...'
                  labelText='Assign Credit to Recording(s)*'
                  cacheOptions={false}
                  defaultOptions
                  invalid={(!this.state.contributionIds.length && this.state.isSelectRecording) ? true : false}
                  invalidText='Please select at least one element'
                  {...this.props.recordingProps}
                />
              </div>
              <div className='formRow'>
                <SelectASync
                  labelText='Role *'
                  id='creditRoleId'
                  placeholder='Select from list...'
                  defaultOptions
                  loadOptions={this.getRoleOptions('NewStudioRole')}
                  onChange={this.handleCreditRoleIdChange}
                  value={this.state.role.value ? this.state.role : null}
                  invalid={this.state.errors.creditRoleId ? true : false}
                  invalidText={this.state.errors.creditRoleId} />
              </div>
              {this.state.userDefinedRoles.indexOf(this.state.role.value) > -1 &&
                <div className='formRow'>
                  <TextInput id='creditRoleUserDefinedValue'
                    name='creditRoleUserDefinedValue'
                    labelText='Role Name'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.creditRoleUserDefinedValue || ''}
                    invalid={this.state.errors.creditRoleUserDefinedValue ? true : false}
                    invalidText={this.state.errors.creditRoleUserDefinedValue || ''} />
                </div>
              }
              <div className='formRow'>
                <SelectASync
                  labelText='Instrument '
                  id='instrumentId'
                  placeholder='Select from list...'
                  cacheOptions
                  defaultOptions
                  loadOptions={this.getInstrumentOptions}
                  onChange={this.handleInstrumentIdChange}
                  onBlur={this.handleInstrumentIdBlur}
                  value={this.state.instrument.value ? this.state.instrument : null}
                  invalid={this.state.errors.instrumentId ? true : false}
                  invalidText={this.state.errors.instrumentId} />
              </div>
              {this.state.userDefinedInstruments.indexOf(this.state.instrument.value) > -1 &&
                <div className='formRow'>
                  <TextInput id='instrumentUserDefinedValue'
                    name='instrumentUserDefinedValue'
                    labelText='Instrument Name'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.instrumentUserDefinedValue || ''}
                    invalid={this.state.errors.instrumentUserDefinedValue ? true : false}
                    invalidText={this.state.errors.instrumentUserDefinedValue || ''} />
                </div>
              }
            </>
          }
          {this.state.contributionType === 'song' &&
            <>
              <div className='formRow'>
                <SelectASync
                  labelText='Role *'
                  id='creditRoleId'
                  defaultOptions
                  loadOptions={this.getRoleOptions('CreativeContributorRole')}
                  onChange={this.handleCreditRoleIdChange}
                  defaultValue={this.state.role.value ? this.state.role : null}
                  value={this.state.role.value ? this.state.role : null}
                  invalid={this.state.errors.creditRoleId ? true : false}
                  invalidText={this.state.errors.creditRoleId} />
              </div>
              {this.state.userDefinedRoles.indexOf(this.state.role.value) > -1 &&
                <div className='formRow'>
                  <TextInput id='creditRoleUserDefinedValue'
                    name='creditRoleUserDefinedValue'
                    labelText='Role Name'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.creditRoleUserDefinedValue || ''}
                    invalid={this.state.errors.creditRoleUserDefinedValue ? true : false}
                    invalidText={this.state.errors.creditRoleUserDefinedValue || ''} />
                </div>
              }
              <div className='formRow'>
                <TextInput id='split'
                  name='split'
                  labelText='Split'
                  type='number'
                  onChange={this.handleSplitChange}
                  onBlur={this.handleDirty}
                  value={this.state.split || ''}
                  invalid={this.state.errors.split ? true : false}
                  invalidText={this.state.errors.split}
                  className='split-input'
                />
              </div>
            </>
          }
          {this.props.contributionType !== 'song' &&
            <div className='formRow formRow-last'>
              <Select
                id='featureType'
                name='featureType'
                placeholder='Select from list ...'
                isDisabled={this.state.featureTypeOptions.length === 1}
                labelText={<>F/NF/O <img onClick={()=> this.props.alert(
                  `The F/NF/O field is used to identify between the Featured,
                   Non-Featured and Other performer contribution when registering
                   recordings with CMO's like SoundExchange, PPL etc.`, { title: '    ', className: 'project-credits' })}
                src={require('../assets/images/info.svg')} alt='' className='info-icon-img' /></>}
                onChange={this.handleFeatureTypeChange}
                onBlur={this.handleDirty}
                value={this.state.feature}
                invalid={this.state.errors.featureType ? true : false}
                invalidText={this.state.errors.featureType}
                options={this.state.featureTypeOptions} />
            </div>
          }
          <div className='formRow btns-container'>
            {this.props.discardForm &&
              <Button
                onClick={this.openCreditFormDiscardModal}
                type='button'
                kind='secondary'
              >
                Cancel
              </Button>}
            <Button type='submit'> {this.props.loading ? 'Saving...' : 'Save' }</Button>
          </div>
        </Form>
        <CreatePartyModal
          open={this.state.createPartyModalOpen}
          onRequestClose={this.closeCreatePartyModal}
          onPartyCreated={this.onPartyCreated}
        />
        {
          this.state.creditFormDiscardModal && <DiscardModal open={this.state.creditFormDiscardModal}
            onRequestClose={this.closeCreditFormDiscardModal}
            modalHeading='Discard Changes?'
            handleSubmit={this.discardChanges}
          />
        }
      </div>
    )
  }
}
export default compose(
  withRouter,
  WithAlertModal,
  GetUser
)(CreditForm)

import React from 'react'
import {
  Form,
  Button,
  DatePicker,
  DatePickerInput,
  TextInput,
  RadioButton,
  RadioButtonGroup,
  TextArea, Accordion, AccordionItem
} from 'carbon-components-react'
import { DateTime } from 'luxon'
import { withRouter } from 'react-router'
import { windowLocationNewWindow } from '../utils/functions'
import Validator from '../utils/Validator'
import PartyValidator from '../validators/Party'
import Tooltip from './ui/Tooltip'
import definitions from '../utils/definitions.json'
import { compose, graphql, Mutation } from 'react-apollo'
import { CREATE_AFFILIATION,
  CREATE_PUBLISHER,
  DELETE_AFFILIATIONS,
  DELETE_PUBLISHER
} from '../graphql/mutations'
import CreateAffiliation from './CreateAffiliation'
import ApolloClient from '../utils/Apollo'
import {
  GET_PUBLISHERS,
  GET_USER_AFFILIATIONS_LIST,
  GET_PARTY, GET_PARTY_ARTIST } from '../graphql/queries'
import WithAlertModal from '../hocs/WithAlertModal'
import { CreatePublisher } from './CreatePublisher'
import SectionContainer from '../components/SectionContainer'
import PartyAddressList from './PartyAddressList'
import PartyContactList from '../components/PartyContactList'
import { aliasCountryName } from '../utils/functions'
import { Modal } from 'carbon-components-react'
import PartyAddressCreate from '../pages/Party/Address/PartyAddressCreate'
import PartyAddressEdit from '../pages/Party/Address/PartyAddressEdit'
import PartyContactCreate from '../pages/Party/Contact/PartyContactCreate'
import PartyContactEdit from '../pages/Party/Contact/PartyContactEdit'
import CreatePagesHeader from './CreatePagesHeader'
import trashIcon from '../assets/images/trash.svg'

let info = localStorage.getItem('partyInfo') ? JSON.parse(localStorage.getItem('partyInfo')) : ''

const initialState = {
  type: '',
  selectedType: false,
  title: '',
  openAddressModal: false,
  openAddressEditModal: false,
  openContactModal: false,
  openContactEditModal: false,
  prefix: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  isni: '',
  ipiCae: '',
  birthDate: '',
  deathDate: '',
  comments: '',
  affiliationId: '',
  publisherIds: '',
  publishers: [],
  addresses: [],
  tableAddresses: [],
  partyAddress: {},
  partyContact: {},
  contacts: [],
  tableContacts: [],
  refresh: true,

  initialErrors: {},
  errors: {}
}

export class PartyForm extends Validator {
  state = { ...initialState }
  constructor(props) {
    super(props)
    this.validator = PartyValidator
    this.handleGetAffiliations = this.handleGetAffiliations.bind(this)
    this.handleGetPublishers = this.handleGetPublishers.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)

    this.handleBirthDateChange = this.handleBirthDateChange.bind(this)
    this.handleDeathDateChange = this.handleDeathDateChange.bind(this)
    this.handleChangeAffiliationList = this.handleChangeAffiliationList.bind(
      this
    )
    this.handleDeleteAffiliation = this.handleDeleteAffiliation.bind(this)

    this.state.type = props.type || 'person'
    this.state.title = props.title || ''
    this.state.prefix = props.prefix || ''
    this.state.firstName = this.props.first ? this.props.first : props.firstName
    this.state.middleName = props.middleName || ''
    const lastName = props.lastName ? props.lastName : ''
    this.state.lastName = this.props.last ? this.props.last : lastName
    this.state.suffix = props.suffix || ''
    this.state.isni = props.isni || ''
    this.state.ipiCae = props.ipiCae || ''
    this.state.birthDate = props.birthDate
      ? DateTime.fromSQL(props.birthDate).toFormat('LL/dd/yyyy')
      : ''
    this.state.deathDate = props.deathDate
      ? DateTime.fromSQL(props.deathDate).toFormat('LL/dd/yyyy')
      : ''
    this.state.comments = props.comments || ''
    this.state.affiliationId = props.affiliationId || []
    this.state.affiliations = props.affiliations || []
    this.state.publishers = props.publishers || []
    this.state.publisherIds = props.publisherIds || []

    this.state.publisher = props.publisher || ''
    this.state.countryId = props.countryId || ''

    this.state.addedAffiliationList = []
    this.state.reservedName = info ? info.affiliationLabel : []
  }

  handleGetAffiliations = () =>
    new Promise((resolve) => {
      ApolloClient.query({
        query: GET_USER_AFFILIATIONS_LIST,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let affiliations = []
        data.getUserAffiliations.forEach((list) => {
          affiliations.push({ list })
        })
        resolve(affiliations)
      })
    })

  handleGetPublishers = (variables) =>
    new Promise((resolve) => {
      ApolloClient.query({
        query: GET_PUBLISHERS,
        variables,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        this.setState({
          publishers: [...data.getPublishers],
          publisherIds: data.getPublishers.map(item => item.id)
        })
      })
      resolve([])
    })


  handleCreateAffiliation = (variables) =>
    new Promise(() => {
      ApolloClient.mutate({
        variables,
        mutation: CREATE_AFFILIATION,
        fetchPolicy: 'no-cache'
      })
        .then(() => {
          this.handleGetAffiliations().then((r) => {
            const last = r.filter(
              (l) => l.list.affiliation.name === this.state.reservedName
            )
            if (last.length) {
              this.setState((prevState) => ({
                affiliations: [...prevState.affiliations, last[0].list]
              }))
              // eslint-disable-next-line no-undefined
              if (this.state.affiliationId !== undefined) {
                this.setState((prevState) => ({
                  affiliationId: [...prevState.affiliationId, last[0].list.id]
                }))
              } else {
                this.setState({
                  affiliationId: [last[0].list.id]
                })
              }
            } else {
              this.setState((prevState) => ({
                affiliations: [...prevState.affiliations, r[r.length - 1].list]
              }))
            }
          })
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    })

  handleBirthDateChange(full, formatted) {
    this.setState({
      ...this.state,
      birthDate: formatted,
      errors: {
        recordedOn: undefined // eslint-disable-line no-undefined
      }
    })
  }

  checkFormEntry() {
    if (
      this.state.title === '' &&
    (this.state.firstName === '' || this.state.firstName === undefined) &&
    this.state.middleName === '' &&
    this.state.lastName === '' &&
    this.state.suffix === '' &&
    this.state.isni === '' &&
    this.state.ipiCae === '' &&
    this.state.birthDate === '' &&
    this.state.deathDate === '' &&
    this.state.comments === '' &&
    this.state.affiliations.length === 0 &&
    this.state.publishers.length === 0 &&
    this.state.publisher === '' &&
    this.state.countryId === '' &&
    this.state.addedAffiliationList.length === 0 &&
    this.state.reservedName.length === 0
    ){
      return true
    }
    return false

  }

  componentDidMount() {
    const { affiliations, affiliationId } = this.state
    if (affiliations && affiliations.length) {
      affiliations.map((i) => {
        return affiliationId.push(i.id)
      })
    }
    if (this.props.id) {
      this.handleGetPublishers({ partyId: this.props.id })
    }
  }

  handleDeathDateChange(full, formatted) {
    this.setState({
      ...this.state,
      deathDate: formatted,
      errors: {
        recordedOn: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleTypeChange(selected) {
    this.setState({
      ...this.state,
      type: selected.value,
      selectedType: selected,
      errors: {
        type: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleChangeAffiliationList(list) {
    if (list.length) {
      for (let i of list) {
        if (typeof this.state.affiliationId !== 'undefined') {
          this.setState((prevState) => ({
            affiliationId: [...prevState.affiliationId, i.id]
          }))
        } else {
          this.setState({ affiliationId: [i.id] })
        }
      }
    }
  }

  handleDeleteAffiliation(id) {
    let newList = this.state.affiliations.filter((item) => item.id !== id)
    this.setState({ affiliations: newList, reservedName: [] })

    if (!newList.length) {
      this.setState({
        affiliationId: []
      })
    } else {
      let newIdList = newList.map((item) => item.id)
      this.setState({
        affiliationId: newIdList
      })
    }
  }

  handleDeletePublisher(id) {
    let newList = this.state.publishers.filter((item) => item.id !== id)
    this.setState({ publishers: newList })

    if (!newList.length) {
      this.setState({
        publisherIds: []
      })
    } else {
      let newIdList = newList.map((item) => item.id)
      this.setState({
        publisherIds: newIdList
      })
    }
  }

  handleDeleteAddress(id) {
    let newList = this.state.tableAddresses.filter((item) => item !== id)
    this.setState({ tableAddresses: newList })
    this.setState({ addresses: newList })
    this.partyRefetch()
  }
  onChangeType = (value)=>{
    this.setState({
      type: value
    })
  }

  handleDeleteAddress2(id) {
    const { tableAddresses, addresses } = this.state
    const index = tableAddresses.findIndex(item => item.id === id)
    let updatedAddresses = [...addresses]
    updatedAddresses.splice(index, 1)
    let updatedTableAddresses = [...tableAddresses]
    updatedTableAddresses.splice(index, 1)
    this.setState({
      addresses: updatedAddresses,
      tableAddresses: updatedTableAddresses
    })
    // this.partyRefetch()
  }
  onChangeType = (value)=>{
    this.setState({
      type: value
    })
  }
  partyRefetch = async () =>{
    if (this.props.is_my) {
      this.props.partyRefetch()
      return
    }
    const query = this.props.is_my ? GET_PARTY_ARTIST : GET_PARTY
    await ApolloClient.query({
      query: query,
      variables: {
        id: this.props.id
      },
      fetchPolicy: 'network-only'
    })
  }
  onSubmitAddress=(state) => {
    const { addresses, tableAddresses } = this.state
    const id = Math.floor(Math.random() * 100).toString()
    const tableAddress = {
      id: id,
      line1: state.line1,
      line2: '',
      line3: '',
      city: state.city,
      district: state.district,
      postalCode: state.postalCode,
      countryId: state.countryId,
      country: {
        id: state.country.value,
        name: state.country.label
      }
    }
    const address = {
      line_1: state.line1,
      line_2: '',
      line_3: '',
      city: state.city,
      district: state.district,
      postal_code: state.postalCode,
      country_id: state.countryId
    }
    this.setState({
      addresses: [...addresses, address],
      tableAddresses: [...tableAddresses, tableAddress]
    })
  }
  onSubmitEditAddress=(state, addressId) => {
    const { addresses, tableAddresses } = this.state
    const tableAddress = {
      id: addressId,
      line1: state.line1,
      line2: '',
      line3: '',
      city: state.city,
      district: state.district,
      postalCode: state.postalCode,
      countryId: state.countryId,
      country: {
        id: state.country.value,
        name: state.country.label
      }
    }
    const address = {
      line_1: state.line1,
      line_2: '',
      line_3: '',
      city: state.city,
      district: state.district,
      postal_code: state.postalCode,
      country_id: state.countryId
    }
    const addressIndex = tableAddresses.findIndex(({ id })=> id === addressId)
    let updatedAddresses = [...addresses]
    updatedAddresses[addressIndex] = address
    let updatedTableAddresses = [...tableAddresses]
    updatedTableAddresses[addressIndex] = tableAddress
    this.setState({
      addresses: updatedAddresses,
      tableAddresses: updatedTableAddresses
    })
  }
  onDeleteAddress = (row) => {
    const { tableAddresses, addresses } = this.state
    const index = tableAddresses.findIndex(item => item.id === row.id)
    let updatedAddresses = [...addresses]
    updatedAddresses.splice(index, 1)
    let updatedTableAddresses = [...tableAddresses]
    updatedTableAddresses.splice(index, 1)
    this.setState({
      addresses: updatedAddresses,
      tableAddresses: updatedTableAddresses
    })
  }
  onDeleteContact=(row) => {
    const { tableContacts, contacts } = this.state
    const index = tableContacts.findIndex(item => item.id === row.id)
    let updatedContacts = [...contacts]
    updatedContacts.splice(index, 1)
    let updatedTableContacts = [...tableContacts]
    updatedTableContacts.splice(index, 1)
    this.setState({
      contacts: updatedContacts,
      tableContacts: updatedTableContacts
    })
  }

  onSubmitContact = (state) => {
    const { contacts, tableContacts } = this.state
    const contact = {
      name: state.name,
      type: state.type,
      value: state.value
    }
    const tableContact = {
      id: Math.floor(Math.random() * 100).toString(),
      ...contact
    }

    this.setState({
      contacts: [...contacts, contact],
      tableContacts: [...tableContacts, tableContact]
    })
  }
  onSubmitEditContact = (state, contactId) => {
    const { contacts, tableContacts } = this.state
    const contact = {
      name: state.name,
      type: state.type,
      value: state.value
    }
    const tableContact = {
      id: contactId,
      ...contact
    }
    const contactIndex = tableContacts.findIndex(({ id })=> id === contactId)

    let updatedContacts = [...contacts]
    updatedContacts[contactIndex] = contact
    let updatedTableContacts = [...tableContacts]
    updatedTableContacts[contactIndex] = tableContact
    this.setState({
      contacts: updatedContacts,
      tableContacts: updatedTableContacts
    })
  }
  submit() {
    this.props.onSubmit(this.state)
  }

  handleAddressModal=()=>{
    this.setState({

      openAddressModal: !this.state.openAddressModal
    })
  }
  handleContactModal=()=>{
    this.setState({

      openContactModal: !this.state.openContactModal
    })
  }
  handleAddressEditModal=(addressId)=>{
    const { tableAddresses } = this.state
    let partyAddress
    if (this.props.id) {
      partyAddress = this.props.addresses.find(({ id })=> id === addressId)
    } else {
      partyAddress = tableAddresses.find(({ id })=> id === addressId)
    }
    this.setState({

      openAddressEditModal: !this.state.openAddressEditModal,
      partyAddress: partyAddress
    })
  }
  handleContactEditModal=(contactId)=>{
    const { tableContacts } = this.state
    let partyContact
    if (this.props.id) {
      partyContact = this.props.contacts.find(({ id })=> id === contactId)
    } else {
      partyContact = tableContacts.find(({ id })=> id === contactId)
    }
    this.setState({

      openContactEditModal: !this.state.openContactEditModal,
      partyContact: partyContact
    })
  }
  render() {
    const {
      errors,
      comments,
      type,
      title,
      firstName,
      middleName,
      lastName,
      suffix,
      birthDate,
      deathDate,
      isni,
      ipiCae,
      publisherIds,
      publishers,
      affiliations,
      reservedName,
      tableContacts,
      tableAddresses,
      openAddressModal,
      openAddressEditModal,
      openContactEditModal,
      openContactModal,
      partyAddress,
      partyContact
    } = this.state
    const {
      id,
      alert,
      loading,
      inMyParty,
      is_my,
      addresses,
      contacts
    } = this.props
    let ppl = 'PPL - UK (IPN is the actual identifier PPL issues)'
    let pplIndia = 'PPLI - India'
    const canUpdate = true

    return (
      <>
      <div className='form party-form-custom'>
        <Form id='partForm' onSubmit={this.handleSubmit} autoComplete='off'>
          <div className='sections-container'>
            <div className='fields-container'>
              <div className='formRow'>
                <div className='party-form'>
                  <label className='bx--label'>Type</label>
                  <RadioButtonGroup
                    name='radio-button-group'
                    valueSelected={type}
                    onChange={this.onChangeType}
                  >
                    <RadioButton
                      id='person'
                      labelText='Person'
                      value='person'
                      className='radio-person'
                    />
                    <RadioButton
                      id='organization'
                      labelText='Organization'
                      value='organisation'
                    />
                  </RadioButtonGroup>
                </div>
              </div>
              {type === 'person' && (
            <>
              <div className='formRow'>
                <TextInput
                  id='title'
                  name='title'
                  labelText='Alias'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={title || ''}
                  invalid={errors.title ? true : false}
                  invalidText={errors.title}
                />
              </div>
              <div className='formRow'>
                <TextInput
                  id='firstName'
                  name='firstName'
                  labelText='First Name *'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={firstName || ''}
                  invalid={errors.firstName ? true : false}
                  invalidText={errors.firstName}
                />
              </div>
              <div className='formRow'>
                <TextInput
                  id='middleName'
                  name='middleName'
                  labelText='Middle Name'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={middleName || ''}
                  invalid={errors.middleName ? true : false}
                  invalidText={errors.middleName}
                />
              </div>
              <div className='formRow'>
                <TextInput
                  id='lastName'
                  name='lastName'
                  labelText='Last Name'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={lastName || ''}
                  invalid={errors.lastName ? true : false}
                  invalidText={errors.lastName}
                />
              </div>
              <div className='formRow'>
                <TextInput
                  id='suffix'
                  name='suffix'
                  labelText='Suffix'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={suffix || ''}
                  invalid={errors.suffix ? true : false}
                  invalidText={errors.suffix}
                />
              </div>
              <div className='formRow'>
                <DatePicker
                  id='birthDate-date-picker'
                  onChange={this.handleBirthDateChange}
                  datePickerType='single'
                >
                  <DatePickerInput
                    id='birthDate-input'
                    name='birthDate'
                    labelText='Birth Date'
                    placeholder='mm/dd/yyyy'
                    onChange={this.handleBirthDateChange}
                    onBlur={this.handleDirty}
                    value={birthDate || ''}
                    invalid={errors.birthDate ? true : false}
                    invalidText={errors.birthDate}
                  />
                </DatePicker>
              </div>
              <div className='formRow'>
                <DatePicker
                  id='deathDate-date-picker'
                  onChange={this.handleDeathDateChange}
                  datePickerType='single'
                >
                  <DatePickerInput
                    id='deathDate-input'
                    name='deathDate'
                    labelText='Death Date'
                    placeholder='mm/dd/yyyy'
                    onChange={this.handleDeathDateChange}
                    onBlur={this.handleDirty}
                    value={deathDate || ''}
                    invalid={errors.deathDate ? true : false}
                    invalidText={errors.deathDate}
                  />
                </DatePicker>
              </div>
            </>
              )}
              {type !== 'person' && (
                <div className='formRow'>
                  <TextInput
                    id='firstName'
                    name='firstName'
                    labelText='Name *'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={firstName || ''}
                    invalid={errors.firstName ? true : false}
                    invalidText={errors.firstName}
                  />
                </div>
              )}
              <div className='formRow'>
                <TextInput
                  id='ipiCae'
                  type='text'
                  name='ipiCae'
                  labelText={
                    <Tooltip term='IPI/CAE' definition={definitions.IPICAE} />
                  }
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={ipiCae || ''}
                  invalid={errors.ipiCae ? true : false}
                  invalidText={errors.ipiCae || ''}
                />
              </div>
              <div className='formRow button-on-row'>
                <TextInput
                  id='isni'
                  name='isni'
                  labelText={
                    <Tooltip term={'ISNI'} definition={definitions.ISNI} />
                  }
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={isni || ''}
                  invalid={errors.isni ? true : false}
                  invalidText={'ISNI must be 16 characters long with the last character allowed as a number or character X'}
                />
                <button
                  type='button'
                  className='bx--btn bx--btn--secondary bx--btn--mt'
                  onClick={() =>
                    windowLocationNewWindow(
                      'https://registration.quansic.com/vevasound'
                    )
                  }
                >
                  Get an ISNI
                </button>
              </div>
              <div className='formRowComment'>
                <TextArea
                  id='comments'
                  name='comments'
                  labelText='Comments'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={comments || ''}
                  invalid={errors.comments ? true : false}
                  invalidText={errors.comments || ''}
                />
              </div>
            </div>
          </div>

          <CreatePagesHeader
            subtitle='Contacts'
            btnText={canUpdate ? 'Add' : false}
            btnOnClick={()=>{
              this.handleContactModal()
            }}
          />
          <div className='sections-container'>
            <div className='fields-container'>
              <SectionContainer>
                {this.state.contacts &&
                  <PartyContactList rows={contacts ?
                    contacts : tableContacts || []}
                  partyId={id}
                  onEdit={this.handleContactEditModal}
                  isMy={is_my}
                  showSearch={false}
                  onDeleteContact={this.onDeleteContact}
                  rowActions={canUpdate}
                  onDelete={this.partyRefetch} />             
                }
              </SectionContainer>
            </div>
          </div>
          <CreatePagesHeader
            subtitle='Addresses'
            btnText={canUpdate ? 'Add' : false}
            btnOnClick={() =>{
              this.handleAddressModal()
            }}
          />
          <div className='sections-container'>
            <div className='fields-container'>
              <SectionContainer>
                {this.state.tableAddresses &&
                  <PartyAddressList
                    rows={
                      addresses ?
                        addresses.map(aliasCountryName) :
                        tableAddresses.map(aliasCountryName) || []}
                    partyId={id}
                    isMy={is_my}
                    onDeleteAddress ={this.onDeleteAddress}
                    onDelete={this.partyRefetch}
                  />
                }
              </SectionContainer>
            </div>
          </div>

          { type === 'person' &&
            <>
          <div className='formRow'>
            <CreatePagesHeader
              subtitle='Publishers'
            />
            <div className='sections-container'>
              <div className='fields-container'>
                <SectionContainer>
                  <p>
                  Music publishers work with songwriters, composers, and copyright owners to support their creative careers and exploit their works to maximize royalties. When a publishing agreement is entered into between a songwriter and a publishing company, the songwriter agrees to assign rights to the publisher with the publisher taking a share of any royalties earned in exchange for services provided.</p>
                  <div className='affiliations-container'>
                    <Mutation mutation={CREATE_PUBLISHER}>
                      {(addPublisher, { loading, error }) => (
                        <CreatePublisher
                          description='Music publishers work with songwriters,
                          composers, and copyright owners
                      to support their creative careers and exploit their works to maximize
                      royalties. When a publishing agreement is entered into between a songwriter
                      and a publishing company, the songwriter agrees to assign rights to the publisher
                      with the publisher taking a share of any royalties earned in exchange for
                      services provided.'
                          onSubmit={(state) => {
                            let data = { ...state }
                            Object.keys(data).forEach(
                              (key) => data[key] === '' && (data[key] = null)
                            )
                            addPublisher({
                              variables: { input: data }
                            })
                              .then(({ data }) => {
                                this.setState({
                                  publishers: [
                                    ...publishers,
                                    data.addPublisher
                                  ]
                                })
                                if (typeof publisherIds !== 'undefined') {
                                  this.setState((prevState) => ({
                                    publisherIds: [...prevState.publisherIds, data.addPublisher.id]
                                  }))
                                } else {
                                  this.setState({
                                    publisherIds: [data.addPublisher.id]
                                  })
                                }
                              })
                              .catch((err) => {
                                console.log(err)
                              })
                          }}
                          loading={loading}
                          errors={error}
                        />
                      )}
                    </Mutation>
                    {publishers &&
              publishers.map((i, j) => (
                <div key={j} className='affiliations-Wrapper'>
                  <Accordion>
                    <div key={j} className='affiliations-Accordion-Item'>
                      <AccordionItem
                        title={`${i.publisher}`}>
                        <div className='affiliations-Accordion-Item-Date-Wrapper'>
                          <p>
                            {i.date ? i.date : ''}
                          </p>
                        </div>
                      </AccordionItem>
                      <div className='fileOptionsContents'>
                        <button type='button' className='delete-button-svg' onClick={()=>{
                          alert(
                            'Are you sure you want to delete this publisher?',
                            {
                              type: 'confirm',
                              buttonText: 'Yes',
                              submitCallback: (close) => {
                                this.handleDeletePublisher(i.id)
                                close()
                              }
                            }
                          )
                        }}>
                          <img src={trashIcon} alt='Delete' height={16}/>
                        </button>
                      </div>
                    </div>
                  </Accordion>
                </div>
              ))}
                  </div>
                </SectionContainer>
              </div>
            </div>

          </div>
          <div className='formRow'>
            <CreatePagesHeader
              subtitle='Performing Rights Organizations (PRO)'
            />
            <div className='sections-container'>
              <div className='affiliations-container'>
                <div className='fields-container'>
                  <SectionContainer>
                    <p>
                    Performance Rights Organization (PRO) administers royalties from the public performances of songs. It’s a critical royalty partner for songwriters, composers, publishers, and copyright owners. Typically, a public performance occurs when a song is played in front of a substantial group of people; however, a public performance also occurs when a solitary music consumer streams a composition on a streaming platform, such as Apple Music. Additional examples of public performances include the playback of song recordings or live performances of them, at places like live music venues, shops, elevators, bowling alleys, restaurants, and sporting events. Each time a public performance occurs, a royalty should be paid to the copyright owners of the music as long as the PRO properly monitors and captures the usage and the song is properly registered.
                    </p>
                    <CreateAffiliation
                      types='pro'
                      type='PRO'
                      label='Custom PRO Organization'
                      buttonText='Add PRO'
                      title='Performing Rights Organizations (PRO)'
                      description=' Performance Rights Organization (PRO) administers royalties
                    from the public performances of songs. It’s a critical royalty partner for
                    songwriters, composers, publishers, and copyright owners. Typically, a public
                    performance occurs when a song is played in front of a substantial group of
                    people; however, a public performance also occurs when a solitary music consumer
                    streams a composition on a streaming platform, such as Apple Music. Additional
                    examples of public performances include the playback of song recordings or live
                    performances of them, at places like live music venues, shops, elevators,
                    bowling alleys, restaurants, and sporting events. Each time a public
                    performance occurs, a royalty should be paid to the copyright owners of the music
                    as long as the PRO properly monitors and captures the usage
                    and the song is properly registered.'
                      createdAffiliations={affiliations}
                      reserveId={(name) => this.setState({ reservedName: name })}
                      onSubmit={(state) => {
                        let checking =
                  affiliations.length &&
                  affiliations.filter(
                    (k) => k.affiliation.name === reservedName
                  )
                        if (checking === 0 || checking.length === 0) {
                          let data = { ...state }
                          Object.keys(data).forEach(
                            (key) => data[key] === '' && (data[key] = null)
                          )
                          this.handleCreateAffiliation({ ...data })
                        } else {
                          alert(
                            'This affiliation is already defined for this Party.'
                          )
                        }
                      }}
                    />

                    {affiliations &&
                      affiliations
                        .filter((item) => item.affiliation.type === 'pro')
                        .map((i, j) => (
                          <div key={j} className='affiliations-Wrapper'>
                            <Accordion>
                              <div key={j} className='affiliations-Accordion-Item'>
                                <AccordionItem
                                  title={i.affiliation ? `${i.affiliation.name} 
                                    ${i.affiliation.country ? i.affiliation.country.name : ''}`
                                    : ''}
                                >
                                  <div>
                                    <p className='affiliations-Accordion-Item-Id'>
                                      {`ID:
                                    ${i.number ? i.number : ''}`}
                                    </p>
                                  </div>
                                  <div className='affiliations-Accordion-Item-Date-Wrapper'>
                                    <p className='affiliations-Accordion-Item-Date'>
                                      {i.date ? i.date : ''}
                                    </p>
                                  </div>
                                </AccordionItem>
                                <div className='fileOptionsContents'>
                                  <button type='button' className='delete-button-svg' onClick={()=>{
                                    alert(
                                      'Are you sure you want to delete this affiliation?',
                                      {
                                        type: 'confirm',
                                        buttonText: 'Yes',
                                        submitCallback: (close) => {
                                          this.handleDeleteAffiliation(i.id)
                                          close()
                                        }
                                      }
                                    )
                                  }}>
                                    <img src={trashIcon} alt='Delete' height={16}/>
                                  </button>
                                </div>
                              </div>
                            </Accordion>
                          </div>
                        ))}
                  </SectionContainer>
                </div>
              </div>
            </div>
          </div>
          <CreatePagesHeader
            subtitle='Collective Management Organizations (CMO)'
          />
          <div className='sections-container last-section'>
            <div className='affiliations-container'>
              <div className='fields-container'>
                <SectionContainer>
                  <p>
                  A Collective Management Organization (CMO) is an international society responsible for monitoring, licensing, and collecting performance and mechanical rights for the compositions of its members. They also enforce the conditions of each licensing contract and collect and distribute any subsequent royalties to members of its organization.
                  </p>
                  <CreateAffiliation
                    inMyParty={inMyParty}
                    types='cmo'
                    type='CMO'
                    label='Custom CMO Organization'
                    buttonText= 'Add CMO'
                    title='Collective Management Organizations (CMO)'
                    description='A Collective Management Organization (CMO) is an
                  international society responsible for monitoring, licensing, and
                  collecting performance and mechanical rights for the compositions
                  of its members. They also enforce the conditions of each licensing
                  contract and collect and distribute any subsequent royalties
                  to members of its organization.'
                    createdAffiliations={affiliations}
                    reserveId={(name) => this.setState({ reservedName: name })}
                    onSubmit={(state) => {
                      let checking =
                  affiliations.length &&
                  affiliations.filter(
                    (k) => k.affiliation.name === reservedName
                  )
                      if (checking === 0 || checking.length === 0) {
                        let data = { ...state }
                        Object.keys(data).forEach(
                          (key) => data[key] === '' && (data[key] = null)
                        )
                        this.handleCreateAffiliation({ ...data })
                      } else {
                        alert(
                          'This affiliation is already defined for this Party.'
                        )
                      }
                    }}
                  />
                  {affiliations &&
                    affiliations
                      .filter((item) => item.affiliation.type === 'cmo')
                      .map((i, j) => (
                        <div key={j} className='affiliations-Wrapper'>
                          <Accordion>
                            <div key={j} className='affiliations-Accordion-Item'>
                              <AccordionItem
                                title={i.affiliation ? ` ${i.affiliation.name}
                                  ${i.affiliation.country ? i.affiliation.country.name : ''}
                                    ` : ''}
                              >
                                <div>
                                  <p className='affiliations-Accordion-Item-Id'>
                                    {`ID:
                                  ${i.number ? i.number : ''}`}
                                  </p>
                                </div>
                                <div className='affiliations-Accordion-Item-Date-Wrapper'>
                                  <p className='affiliations-Accordion-Item-Date'>
                                    {i.date ? i.date : ''}
                                  </p>
                                  <p className='affiliations-Accordion-Item-Check'>
                                    {i.affiliation.name === ppl ||
                                    i.affiliation.name === pplIndia
                                      ? 'verified'
                                      : ''}
                                  </p>
                                </div>
                              </AccordionItem>
                              <div className='fileOptionsContents'>
                                <button type='button' className='delete-button-svg' onClick={()=>{
                                  alert(
                                    'Are you sure you want to delete this affiliation?',
                                    {
                                      type: 'confirm',
                                      buttonText: 'Yes',
                                      submitCallback: (close) => {
                                        this.handleDeleteAffiliation(i.id)
                                        close()
                                      }
                                    }
                                  )
                                }}>
                                  <img src={trashIcon} alt='Delete' height={16}/>
                                </button>  
                              </div>
                            </div>
                          </Accordion>
                        </div>
                      ))}
                </SectionContainer>
              </div>
            </div>
          </div>
          </>
          }
          <div className='sections-container'>
            <div className='fields-container'>
              <div className='formRow buttons-row'>
                <button type='button' className='bx--btn bx--btn--secondary bx--btn--mt' onClick={()=>{
                  if (this.checkFormEntry()) {
                    this.props.history.goBack()
                  } else {
                    alert(
                      'Are you sure you want to discard changes?',
                      {
                        type: 'discard',
                        buttonText: 'Yes',
                        submitCallback: (close) => {
                          this.props.history.goBack()
                          close()
                        }
                      }
                    )
                  }
                }}>Cancel</button>
                <Button form='partForm' type='submit'>
                  {loading ? 'Saving...' 
                    : (this.state.firstName ? 'Save' : 'Create')} 
                </Button>
              </div>
            </div>
          </div>
        </Form>

      </div>
       <Modal
         className='contact-modal .modal-scrollable'
         onRequestClose={this.handleContactModal}
         passiveModal={false}
         open={openContactModal}
         modalHeading='Create Party Contact'
         onRequestSubmit={this.handleContactModal}
         primaryButtonText='Create'
         secondaryButtonText='Cancel'
         secondaryButtons={true}
       >
         {openContactModal &&
         <PartyContactCreate
           party={this.props}
           partyRefetch={this.partyRefetch}
           onSubmitContact={this.onSubmitContact}
           closeModal={this.handleContactModal}/>
         }
       </Modal>

     <Modal
       className='contact-modal'
       onRequestClose={this.handleContactEditModal}
       passiveModal={false}
       open={openContactEditModal}
       modalHeading='Party Contact Edit'
       onRequestSubmit={this.handleContactEditModal}
       primaryButtonText='Close'
     >
       {openContactEditModal &&
       <PartyContactEdit
         party={this.props}
         onSubmitEditContact={this.onSubmitEditContact}
         closeModal={this.handleContactEditModal}
         partyRefetch={this.partyRefetch}
         partyContact={partyContact}/>}
     </Modal>
     <Modal
       className='address-modal .modal-scrollable'
       onRequestClose={this.handleAddressModal}
       passiveModal={false}
       open={openAddressModal}
       modalHeading='Create Party Address'
       onRequestSubmit={this.handleAddressModal}
       primaryButtonText='Create'
       secondaryButtonText='Cancel'
       secondaryButtons={true}
     >
       {openAddressModal &&
       <PartyAddressCreate
         party={this.props}
         partyRefetch={this.partyRefetch}
         onSubmitAddress={this.onSubmitAddress}
         closeModal={this.handleAddressModal}/>
       }
     </Modal>
     <Modal
       className='address-modal'
       onRequestClose={this.handleAddressEditModal}
       passiveModal={false}
       open={openAddressEditModal}
       modalHeading='Party Address Edit'
       onRequestSubmit={this.handleAddressEditModal}
       primaryButtonText='Close'
     >
       {openAddressEditModal &&
        <PartyAddressEdit
          party={this.props}
          partyRefetch={this.partyRefetch}
          onSubmitEditAddress={this.onSubmitEditAddress}
          closeModal={this.handleAddressEditModal}
          partyAddress={partyAddress}/>}
     </Modal>
      </>
    )
  }
}

export default compose(
  WithAlertModal,
  withRouter,
  graphql(DELETE_AFFILIATIONS, { name: 'deleteAffiliations' }),
  graphql(DELETE_PUBLISHER, { name: 'deletePublisher' }),
)(PartyForm)

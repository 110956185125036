import React from 'react'
import { compose } from 'react-apollo'

import PlayListTile from './PlayListTile'
import WithWaveformPreviewModal from '../hocs/WithWaveformPreviewModal '

const ProjectTileList = React.memo(props => {

  if (props.playlists.length === 0) {
    return null
  }

  return (
    <div className='playlistTileContainer'>
      {props.playlists.map((playlist, index) => (
        <PlayListTile
          {...playlist}
          user={props.user}
          index={index}
          key={playlist.id}
          refetch={props.refetch}
          favourite={props.favourites && props.favourites.indexOf(parseInt(playlist.id, 10)) > -1}
          generateUrl={props.generateUrl}
          withOptions={props.withOptions}
          waveformPreviewModal={props.waveformPreviewModal}
          onDelete={props.onDelete}
          showFileCount={props.showFileCount || false}
          showArtist={props.showArtist || false}
          showPlayIcon={props.showPlayIcon === undefined ? true : props.showPlayIcon}
          disableFavourite={props.disableFavourite}
        />
      ))}
    </div>
  )
})

export default compose(
  WithWaveformPreviewModal
)(ProjectTileList)

import React from 'react'
import { compose, Mutation } from 'react-apollo'

import { GET_PARTY, GET_PARTY_ARTIST } from '../../../graphql/queries'
import { UPDATE_PARTY_CONTACT } from '../../../graphql/mutations'

import GetUser from '../../../hocs/queries/GetUser'

// Our Base Components
import PartyContactForm from '../../../components/PartyContactForm'
import SectionContainer from '../../../components/SectionContainer'

class PartyContactEdit extends React.Component {
  render() {
    return (
      <>
        <main className='main'>
          <div className='mainContainer'>

            <SectionContainer>
              <Mutation
                mutation={UPDATE_PARTY_CONTACT}
                update={(cache, { data: { updatePartyContact } }) => {
                  const query = this.props.party.is_my ? GET_PARTY_ARTIST : GET_PARTY
                  const { getParty } = cache.readQuery({
                    query: query,
                    variables: {
                      id: this.props.party.id
                    }
                  })
                  if (getParty) {
                    const party = { ...getParty }
                    party.contacts = [...party.contacts].map(contact => {
                      if (contact.id === this.props.partyContact.id) {
                        return updatePartyContact
                      }

                      return contact
                    })
                    cache.writeQuery({
                      query: query,
                      variables: {
                        id: this.props.party.id
                      },
                      data: {
                        getParty: party
                      }
                    })
                  }
                  this.props.history.push(`/parties/${this.props.party.id}`)
                }}>
                {(updatePartyContact, { loading, error }) => (
                  <PartyContactForm {...this.props.partyContact}
                    party={{ ...this.props.party }}
                    onSubmit={(state) => {
                      const {
                        closeModal,
                        party,
                        partyContact,
                        onSubmitEditContact,
                        partyRefetch
                      } = this.props
                      if (!party.id) {
                        onSubmitEditContact(state, partyContact.id)
                        closeModal()
                        return
                      }
                      closeModal()
                      let data = { ...state }
                      data.id = this.props.partyContact.id
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      updatePartyContact({
                        variables: data
                      }).then(()=>{
                        partyRefetch()
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
)(PartyContactEdit)

import React from 'react'
import Helmet from 'react-helmet-async'
import { Query, compose } from 'react-apollo'

import { GET_ALL_PLAYLISTS } from '../graphql/queries'
import GetUser from '../hocs/queries/GetUser'

// Our Base Components
import Hero from '../components/Hero'
import PlayListTileList from '../components/PlayListTileList'
import EmptyInfoTile from '../components/EmptyInfoTile'
import SectionContainer from '../components/SectionContainer'
import WithAlertModal from '../hocs/WithAlertModal'

class ProjectsPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
  getSearchTerm=(value)=>{
    this.setState({
      searchTerm: value
    })
  }

  filterByValue(array) {
    let filteredArray = []
    if (array && array.length) {
      array.filter(i => {
        const title = i.title.toLowerCase()
        if (title.includes(this.state.searchTerm.trim().toLowerCase())) {
          filteredArray.push(i)
        }
        return i
      })
      return filteredArray
    }
    return []
  }

  createPlaylist = () => {
    this.props.history.push('/playlists/create')
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Playlists</title>
        </Helmet>
        <main className='main playlist-hero'>
          <Hero src='ph.hero.projects.jpg'
            alt='Alt text'
            subtitle='Playlists'
            showSearch={true}
            getSearchTerm={this.getSearchTerm}
            btnText= 'Add'
            btnOnClick ={()=>{
              this.props.history.push('/playlists/create')
            }}
          />
          <div className='mainContainer playlist-page playlist-empty'>
            <SectionContainer>
              <Query query={GET_ALL_PLAYLISTS}
                fetchPolicy='cache-and-network'>
                {({ loading, error, data, refetch }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }
                  let length = data.getPlaylists ? data.getPlaylists.length : null

                  if (!length && !loading) {
                    return (
                      <EmptyInfoTile
                        title='Create Playlist'
                        btnOnClick={this.createPlaylist}
                      />
                    )
                  }

                  return (
                    <PlayListTileList playlists={this.filterByValue(data.getPlaylists) || []}
                      refetch={refetch}
                      showLoader={loading}
                      withOptions

                      onDelete={() => this.props.alert('The project has been successfully deleted.')}
                      user={this.props.user} />

                  )
                }}
              </Query>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(GetUser, WithAlertModal)(ProjectsPage)

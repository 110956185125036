import React from 'react'
import Helmet from 'react-helmet-async'
import { Mutation } from 'react-apollo'

import { CREATE_PLAYLIST } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import PlayListForm from '../../components/PlayListForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'


class PlaylistCreate extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Create Project</title>
        </Helmet>
        <main className='main createPlaylist'>

          <CreatePagesHeader
            subtitle='New Playlist Details'
            title='New Playlist'
          >
            <Breadcrumb
              items={[
                {
                  href: `/playlists`,
                  name: 'Playlists'
                },
                {
                  href: `/playlists/create`,
                  name: 'New Playlist'
                }
              ]}
            />
          </CreatePagesHeader>
          <div className='playlistCreateContainer'>
            <SectionContainer>
              <Mutation
                mutation={CREATE_PLAYLIST}
                update={(cache, { data: { createPlaylist } }) => {
                  this.props.history.push(`/playlists/${createPlaylist.id}`)
                }}>
                {(createPlaylist, { loading, error }) => (
                  <PlayListForm
                    user={this.props.user}
                    onSubmit={(state) => {
                      createPlaylist({
                        variables: state
                      }).catch((error) => {
                        console.log(error)
                      //
                      })
                    }}
                    loading={loading}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default GetUser(PlaylistCreate)

import React from 'react'
import { Query } from 'react-apollo'
import * as Sentry from '@sentry/browser'
import { GET_USER } from '../../graphql/queries'

const GetUser = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_USER} fetchPolicy={fetchPolicy}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            const user = data.getUser

            Sentry.configureScope(function (scope) {
              scope.setUser({
                id: user.id,
                email: user.email
              })
            })

            return <WrappedComponent {...this.props} refetch={refetch} user={user} />
          }}
        </Query>
      )
    }
  }
}

export default GetUser

export const GetUserRefreshed = WrappedComponent => {
  return GetUser(WrappedComponent, 'network-only')
}

import React from 'react'
import { Button, TextInput } from 'carbon-components-react'
import { SelectASync } from './Select'
import Validator from '../utils/Validator'
import Affiliations from '../validators/Affiliations'
import ApolloClient from '../utils/Apollo'
import { GET_AFFILIATIONS_TYPE_LIST, GET_PPL } from '../graphql/queries'
import { DELETE_AFFILIATIONS } from '../graphql/mutations'
import { compose, graphql } from 'react-apollo'
import WithAlertModal from '../hocs/WithAlertModal'

let locationIpn = window.location.href.slice(window.location.href.search('IPN'))
let IPN = locationIpn.substring(4)
let info = IPN && localStorage.getItem('partyInfo')
  ? JSON.parse(localStorage.getItem('partyInfo'))
  : ''

localStorage.removeItem('partyInfo')
let localUrl = window.location.pathname
let party = '/account/myParty'
let myParty = '/account/myParty'
let windowUrl = localUrl === party ? party : localUrl === myParty ? myParty : '' // eslint-disable-line

const initialState = {
  affiliationId: '',
  affiliationType: '',
  customName: '',
  selectedAffiliation: '',
  description: '',
  errors: {},
  ipn: IPN,
  affiliationLabel: info ? info.affiliationLabel : ''
}

export class CreateAffiliation extends Validator {
  state = {
    ...initialState,
    affiliationLabel: info && this.props.type === 'PRO' ? '' : initialState.affiliationLabel
  }

  getAffiliationsOptions = (inputValue, type) =>
    new Promise((resolve) => {
      const { inMyParty } = this.props
      ApolloClient.query({
        query: GET_AFFILIATIONS_TYPE_LIST,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let affiliationsTypes = []
        if (type === 'pro') {
          const pros = data.getAffiliations.filter(
            (item) => item.type === 'pro'
          )
          pros.forEach((affiliation) => {
            if (
              !affiliation.name.toLowerCase().includes(inputValue.toLowerCase())
            ) {
              return
            }
            affiliationsTypes.push({
              value: affiliation.id,
              label: affiliation.name
            })
          })
        } else {
          const cmos = data.getAffiliations.filter(
            (item) => item.type !== 'pro' && (inMyParty ? true : !item.name.toLowerCase().includes('ppl')) // eslint-disable-line
          )

          cmos.forEach((affiliation) => {
            if (
              !affiliation.name.toLowerCase().includes(inputValue.toLowerCase())
            ) {
              return
            }
            affiliationsTypes.push({
              value: affiliation.id,
              label: affiliation.name
            })
          })
        }
        resolve(
          affiliationsTypes.sort((a, b) => a.label.localeCompare(b.label))
        )
      })
    })

  constructor(props) {
    super(props)
    // Functions
    this.handleChange = this.handleChange.bind(this)
    //
    this.validator = Affiliations
    this.state.selectedAffiliation = props.selectedAffiliation || ''
    this.state.affiliationType = info && this.props.type === 'CMO'
      ? info.affiliationType
      : props.affiliationType || ''
    this.state.url = ''
    this.state.affiliationId = info && this.props.type === 'CMO' ? info.affiliationType : ''
    this.state.description = ''
  }

  submit = () => {
    localStorage.removeItem('partyInfo')
    const variables = {
      affiliationId: this.state.affiliationType,
      number: IPN ? IPN : this.state.affiliationId,
      status: 'verified'
    }

    this.props.onSubmit(variables)

    if (IPN.length) {
      const nextURL = windowUrl
      const nextTitle = ''
      const nextState = { additionalInformation: '' }
      window.history.replaceState(nextState, nextTitle, nextURL)
    }
    this.setState({
      ...initialState,
      ipn: '',
      affiliationType: '',
      affiliationLabel: ''
    })
    initialState.ipn = ''
    initialState.affiliationLabel = ''
    info = ''
    IPN = ''
  }

  handleVerification = () => {
    if (this.state.ipn) {
      this.submit()
      return
    }
    const partyInfo = { ...this.state }
    localStorage.setItem('partyInfo', JSON.stringify(partyInfo))
    if (this.state.affiliationLabel === 'PPL - UK (IPN is the actual identifier PPL issues)' || this.state.affiliationType === 'PPL - UK (IPN is the actual identifier PPL issues)') {
      window.location.href = this.state.url
    }
  }

  componentDidMount() {
    if (IPN.length) {
      const nextURL = windowUrl
      const nextTitle = ''
      const nextState = { additionalInformation: '' }
      window.history.replaceState(nextState, nextTitle, nextURL)
    }
  }

  handleChangeType = (ev) => {
    if (this.props.reserveId) {
      this.props.reserveId(ev.label)
    }
    if (IPN.length) {
      const nextURL = windowUrl
      const nextTitle = ''
      const nextState = { additionalInformation: '' }
      window.history.replaceState(nextState, nextTitle, nextURL)
    }

    if (this.state.ipn) {
      this.setState({
        affiliationType: ev.value,
        affiliationLabel: ev.label,
        affiliationId: '',
        ipn: ''
      })
      localStorage.removeItem('partyInfo')
      initialState.ipn = ''
      initialState.affiliationLabel = ''
      info = ''
      IPN = ''
    } else {
      this.setState({ affiliationType: ev.value, affiliationLabel: ev.label })
    }
    if (ev.value === '19' || ev.value === '7') {
      ApolloClient.query({
        query: GET_PPL,
        variables: {
          route: window.location.pathname
        },
        fetchPolicy: 'network-only'
      }).then((url) => this.setState({ url: url.data.getPPL.url }))
    }
  }

  handleChangeId = (ev) => {
    this.setState({
      affiliationId: ev.target.value
    })
  }
  handleChangeInput = (ev) => {
    this.setState({
      customName: ev.target.value
    })
  }
  cancelAddAffiliation = () => {
    this.setState({
      affiliationId: '',
      affiliationType: '',
      selectedAffiliation: '',
      ipn: '',
      affiliationLabel: ''
    })
  }

  render() {
    // let type = this.state.ipn ? this.state.ipn : this.state.affiliationType
    let pplType =
      this.state.affiliationType === '7' || this.state.affiliationType === '19'
    return (
      <div className='affiliations-Form'>
        <div className='form'>
          <div>
            <div className='formRow' style={{ width: '100%' }}>
              <SelectASync
                key={this.state.affiliationLabel}
                cacheOptions={false}
                placeholder={
                  this.state.affiliationLabel ? // eslint-disable-line
                    this.state.affiliationLabel :
                    this.state.affiliationType ?
                      this.state.affiliationType :
                      'Select from List...' }
                labelText={`Select ${this.props.type}`}
                id='labelId'
                defaultOptions
                loadOptions={(inputValue) =>
                  this.getAffiliationsOptions(inputValue, this.props.types)}
                onChange={this.handleChangeType}
              />
            </div>
            {this.state.affiliationType && (
                <>
                  <div className='formRow'>
                    <TextInput
                      id='affiliationId'
                      name='affiliationId'
                      labelText='Affiliation ID'
                      disabled={this.props.type === 'CMO' &&
                      pplType ? true : !!this.state.ipn}
                      onChange={this.handleChangeId}
                      onBlur={this.handleDirty}
                      value={
                        this.state.ipn
                          ? this.state.ipn
                          : this.state.affiliationId
                      }
                    />
                  </div>
                </>
            )}
          </div>
        </div>

        <div className='affiliations-buttons-display affiliation-add'>
          {(this.state.affiliationLabel === 'PPL - UK (IPN is the actual identifier PPL issues)' || this.state.affiliationType === 'PPL - UK (IPN is the actual identifier PPL issues)' || this.state.affiliationLabel === 'PPL India - India' || this.state.affiliationType === 'PPL India - India') && <><Button
            className=''
            type='button'
            onClick={this.handleVerification}
          >{!this.state.affiliationId ? 'Get Affiliation ID' : 'Add CMO'}
          </Button>
          <button type='button' className='bx--btn bx--btn--secondary bx--btn--mt' onClick={this.cancelAddAffiliation}>Discard</button></>}

          {(this.state.affiliationLabel !== 'PPL - UK (IPN is the actual identifier PPL issues)' && this.state.affiliationType !== 'PPL - UK (IPN is the actual identifier PPL issues)' && this.state.affiliationLabel !== 'PPL India - India' && this.state.affiliationType !== 'PPL India - India') && <Button
            className=''
            type='button'
            onClick={this.submit}
          >
            {`Add ${this.props.type}`}
          </Button>}


        </div>
      </div>
    )
  }
}

export default compose(
  WithAlertModal,
  graphql(DELETE_AFFILIATIONS, { name: 'deleteAffiliations' })
)(CreateAffiliation)

import { graphql } from 'react-apollo'
import { ASSIGN_MULTIPLE_SESSION_RECORDING } from '../../graphql/mutations'

export default graphql(ASSIGN_MULTIPLE_SESSION_RECORDING, {
  name: 'assignMultipleSessionRecording',
  props: data => {
    return {
      assignMultipleSessionRecording: (sessionIds, recordingId) =>
        data.assignMultipleSessionRecording({
          variables: {
            sessionIds,
            recordingId
          }
        })
    }
  }
})

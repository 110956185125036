import React from 'react'
import { DateTime } from 'luxon'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'

import { DELETE_SESSION } from '../graphql/mutations'
import WithComments from '../hocs/WithComments'

import ResponsiveTable from './ResponsiveTable'
import WithAlertModal from '../hocs/WithAlertModal'
import WithCheckInCodeModal from '../hocs/WithCheckInCodeModal'

class SessionTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.getHeaders = this.getHeaders.bind(this)
    this.getRowActions = this.getRowActions.bind(this)
    this.isFavourite = this.isFavourite.bind(this)
    this.getFavouriteType = this.getFavouriteType.bind(this)
  }

  getHeaders = (() => {
    if (this.props.favouritePage) {
      const favouriteHeaders = [
        {
          key: 'name',
          header: 'Session Name'
        },
        {
          key: 'startedAt',
          header: 'Started At',
          formatter: (data) => DateTime.fromSQL(data).toLocaleString(DateTime.DATETIME_SHORT)
        },
        {
          key: 'endedAt',
          header: 'Ended At',
          formatter: (data) => data ? DateTime.fromSQL(data).toLocaleString(DateTime.DATETIME_SHORT) : '-'
        },
        {
          key: 'sessionType.name',
          header: 'Type',
          formatter: (data, row) => row.sessionType.name
        },
        {
          key: 'venue.name',
          header: 'Venue',
          formatter: (data, row) => row.venue.name
        }
      ]

      return () => favouriteHeaders
    }

    const headers = [
      {
        key: 'name',
        header: 'Session Name'
      },
      {
        key: 'startedAt',
        header: 'Started At',
        formatter: (data) => DateTime.fromSQL(data).toLocaleString(DateTime.DATETIME_SHORT)
      },
      {
        key: 'endedAt',
        header: 'Ended At',
        formatter: (data) => data ? DateTime.fromSQL(data).toLocaleString(DateTime.DATETIME_SHORT) : '-'
      },
      {
        key: 'type.name',
        header: 'Type',
        formatter: (data, row) => row.type.name
      },
      {
        key: 'venue.name',
        header: 'Venue',
        formatter: (data, row) => row.venue.name
      }
    ]

    return () => headers
  })()

  getRowActions = (() => {
    let actions = []

    if (!this.props.recordingDetailsPage) {
      actions.push({
        itemText: 'Comments',
        onClick: (row) => this.props.loadComments(
          row.projectId,
          row.id,
          'session'
        )
      })
    }

    actions.push({
      itemText: 'View',
      onClick: (row) =>
        this.props.history.push(`/projects/${row.projectId}/sessions/${row.id}`)
    })


    if (this.props.canUpdate) {
      actions.push({
        itemText: 'Edit',
        onClick: (row) =>{
          this.props.history.push(`/projects/${row.projectId}/sessions/${row.id}/edit`)
        }
      })
    }

    if (this.props.canDelete) {
      actions.push({
        itemText: 'Delete',
        onClick: (row) => {
          this.props.alert('Are you sure you want to permanently delete this session?', {
            type: 'delete',
            buttonText: 'Yes',
            submitCallback: close => {
              this.props.deleteSession({
                variables: {
                  sessionId: row.id
                },
                update: this.props.onDelete
              }).then(() => {
                this.props.alert('The session has been successfully deleted.')
              })
                .catch(() => {
                  this.props.alert('This session could not be deleted.')
                })
              close()
            }
          })
        }
      })
    }

    actions.push({
      itemText: 'Generate Check-In Code',
      onClick: (row) => {
        this.props.checkInCodeModal(row.id)
      }
    })

    return () => actions
  })()

  getActions = (() => {
    let actions = []
    if (this.props.refetch) {
      actions.push({
        name: 'restart',
        iconDescription: 'Refresh',
        onClick: () => this.props.refetch()
      })
    }

    if (actions.length === 0) {
      actions = false
    }

    return () => actions
  })()

  isFavourite(row) {
    return this.props.favourites.indexOf(parseInt(row.id, 10)) > -1
  }

  getFavouriteType() {
    return 'session'
  }

  generateLink(row) {
    return `/projects/${row.projectId}/sessions/${row.id}`
  }

  render() {
    return (
      <ResponsiveTable headers={this.getHeaders()}
        {...this.props}
        linkColumn='name'
        generateLink={this.generateLink}
        favourite={this.props.favourite !== false}
        isFavourite={this.isFavourite}
        rowActions={this.props.favouritePage ? false : this.getRowActions()}
        actions={this.props.actions && this.getActions()}
        getFavouriteType={this.getFavouriteType}
        emptyText='No Sessions'
      />
    )
  }
}

export default compose(
  withRouter,
  WithComments,
  WithAlertModal,
  WithCheckInCodeModal,
  graphql(DELETE_SESSION, { name: 'deleteSession' })
)(SessionTable)

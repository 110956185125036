import { Button } from 'carbon-components-react'
import React from 'react'

function DownloadFile({
  data,
  convertIntoMb,
  handleFilePreview,
  showPlayer,
  download,
  onDownload,
  ImagePreview,
  songImg,
  fileSelectedId,
  filePlayingId,
  pauseButton,
  playButton,
  share
}) {

  const playPasuseSong = (file) =>{
    let songImg = null
    // const
    if ((fileSelectedId && filePlayingId) === file.id) {
      songImg = pauseButton
    } else if (fileSelectedId === file.id) {
      songImg = playButton
    } else {
      songImg = playButton
    }
    return songImg
  }

  const limitedFilesList = data.validateShare.files
  return (
    <>
      <div className='share-file-download'>
        <div className='public-download'>
          <img alt='download' src={download} />
        </div>
        <div className='download-text-container'>
          <h1>Ready to Download</h1>
          <p className='expirationLimitText'>File share expires in 7 days</p>
        </div>
        <div className={'downloadLine'} />
        {data.validateShare &&
          limitedFilesList.length &&
          limitedFilesList.map((item, i) => (
            <div key={i} className='public-share-container'>
              <div className='selected-file-container'>
                <p className='selectedFile'>
                  {item.name.length > 30
                    ? `${item.name.substring(0, 30)}...`
                    : item.name}
                </p>
                <div className='fileOptions'>
                  <div
                    className={`fileType fileType--compact fileType--${
                      item.name.split('.')[item.name.split('.').length - 1]
                    }`}
                  >
                    {item.name.split('.')[item.name.split('.').length - 1]}
                  </div>
                  {['mp3', 'wav', 'm4a', 'aiff', 'aif', 'avi', 'mov', 'mp4', 'ogg', 'wmv', 'webm'].indexOf(item.type.toLowerCase()) > -1 ? (
                      <p className='fileSize'>{convertIntoMb(item.size)} MB</p>
                    ) : (
                      <p className='fileSize'>{item.size} Bytes</p>
                    )}
                </div>
              </div>
              {['png', 'jpg', 'jpeg', 'pdf'].indexOf(item.type.toLowerCase()) > -1 ? (
                  <div
                    onClick={() => {
                      if (item.type.toLowerCase() === 'pdf') {
                        window.open(item.url)
                      } else {
                        return handleFilePreview(item)
                      }
                    }}
                    className='imagePreviewContainer'
                  >
                    <img
                      src={ImagePreview }
                      alt='download-single'
                      className='downloadImage'
                    />
                  </div>
                ) : (
                <>
                  <div>
                    <div
                      onClick={() => {
                        if (['avi', 'mov', 'mp4', 'ogg', 'wmv', 'webm'].indexOf(item.type.toLowerCase()) > -1) {
                          return handleFilePreview(item)
                        } else {
                          return showPlayer(item)
                        }
                      }}
                      className='imagePreviewContainer'
                    >
                      {['mp3', 'wav', 'm4a', 'aiff', 'aif', 'avi', 'mov', 'mp4', 'ogg', 'wmv', 'webm'].indexOf(item.type.toLowerCase()) > -1 ? (
                          <img
                            id={item.id}
                            src={playPasuseSong(item)}
                            alt='download-single'
                            className='downloadImage'
                          />
                        ) : (
                          ''
                        )}
                    </div>
                  </div>
                </>
                )}

              <div
                onClick={() => window.open(item.url)}
                className='downloadImageContainer'
              >
                <img
                  src={download}
                  alt='download-single'
                  className='downloadImage'
                />
              </div>
            </div>
          ))}
        <div className='publicDownloadButton'>
          <Button
            className='downloadButton'
            onClick={() => onDownload(share.url)}
          >
            Download All
          </Button>
        </div>
      </div>
    </>
  )
}

export default DownloadFile

import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'

import { DELETE_PARTY_ADDRESS } from '../graphql/mutations'

import SimpleListItem from './SimpleListItem'

import WithAlertModal from '../hocs/WithAlertModal'


class PartyAddressList extends React.PureComponent {
  render() {
    const addressItems = this.props.rows
    return (
      addressItems.map((item, j) => (
        <div key={j} className='affiliations-wrapper'>
          <SimpleListItem
            onDeleteItem={this.props.onDeleteAddress}
            onDelete={this.props.onDelete}
            row={item}
            deletePartyItem={this.props.deletePartyAddress}
            alert={this.props.alert}
            partyId={this.props.partyId}
            alertType='delete'
            alertMessage='Are you sure you want to delete this address?'
            alertButtonText='Yes'
            title={`${item.line1 ? item.line1 : ''}, ${item.city ? item.city : ''}, ${item.district ? item.district : ''}, ${item.postalCode ? item.postalCode : ''}, ${item.country ? (item.country.name ? item.country.name : ''): ''}`} // eslint-disable-line 
          />
        </div>
      ))
    )
  }
}


export default compose(
  withRouter,
  WithAlertModal,
  graphql(DELETE_PARTY_ADDRESS, { name: 'deletePartyAddress' })
)(PartyAddressList)


import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'
import { DateTime } from 'luxon'

import { UPDATE_SONG } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import { GetSongRefreshed } from '../../hocs/queries/GetSong'

// Our Base Components
import SectionContainer from '../../components/SectionContainer'
import SongForm from '../../components/SongForm'
import Breadcrumb from '../../components/Breadcrumb'
import CreatePagesHeader from '../../components/CreatePagesHeader'
class SongEdit extends React.Component {
  render() {
    const links = [
      {
        href: `/songs`,
        name: 'Songs'
      },
      {
        href: `/songs/${this.props.song.id}`,
        name: this.props.song.title
      },
      {
        href: `/songs/${this.props.song.id}/edit`,
        name: 'Edit Song'
      }
    ]
    return (
      <>
        <Helmet>
          <title>{this.props.song.title} | Song</title>
        </Helmet>
        <main className='main'>
          <CreatePagesHeader
            title={this.props.song.title}
            subtitle='Edit Song Details'
          >
            <Breadcrumb items={links} />
          </CreatePagesHeader>

          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={UPDATE_SONG}
                update={() => {
                  this.props.history.push(
                    `/songs/${this.props.song.id}`
                  )
                }}>
                {(updateSong, { loading, error }) => (
                  <SongForm {...this.props.song}
                    onSubmit={(state) => {
                      let data = { ...state }
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      updateSong({
                        variables: {
                          ...data,
                          id: this.props.song.id,
                          createdOn: data.createdOn ?
                            DateTime.fromFormat(data.createdOn, 'LL/dd/yyyy').toISODate() : null
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetSongRefreshed
)(SongEdit)

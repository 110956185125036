/* eslint-disable max-nested-callbacks */
import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { withClientState } from 'apollo-link-state'
import introspectionQueryResultData from './fragmentTypes.json'
import Pusher from 'pusher-js'

import ApolloPusherLink from './ApolloPusherLink'

import { DO_REFRESH } from '../graphql/mutations'

const httpLink = new BatchHttpLink({
  uri: `${process.env.REACT_APP_API}/graphql`
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('authToken')
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }))

  return forward(operation)
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const appCache = new InMemoryCache({ fragmentMatcher })

const stateLink = withClientState({
  cache: appCache,
  resolvers: {},
  defaults: {
    authStatus: {
      __typename: 'authStatus',
      status: localStorage.getItem('authToken') ? 'loggedIn' : 'loggedOut'
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (!graphQLErrors) {
    return null
  }

  if (graphQLErrors[0].extensions.category === 'authentication') {
    const [firstError] = graphQLErrors

    if (firstError.path.indexOf('doRefresh') !== -1 || !localStorage.getItem('authToken')) {
      localStorage.removeItem('authToken')
      appCache.writeData({
        data: {
          authStatus: {
            __typename: 'authStatus',
            status: 'loggedOut'
          }
        }
      })
      return null
    }

    return new Observable(observer => {
      client
        .mutate({
          // eslint-disable-line no-use-before-define
          mutation: DO_REFRESH
        })
        .then(({ data }) => {
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${data.doRefresh.accessToken}` || null
            }
          }))
          localStorage.setItem('authToken', data.doRefresh.accessToken)
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          }

          // Retry last failed request
          forward(operation).subscribe(subscriber)
        })
        .catch(error => {
          localStorage.removeItem('authToken')
          appCache.writeData({
            data: {
              authStatus: {
                __typename: 'authStatus',
                status: 'loggedOut'
              }
            }
          })

          // window.location.href = '/'

          observer.error(error)
        })
    })
  }

  return null
})

const pusherLink = new ApolloPusherLink({
  pusher: new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    authEndpoint: `${process.env.REACT_APP_API}/graphql/subscriptions/auth`,
    auth: {
      headers: {
        authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    }
  })
})

const client = new ApolloClient({
  link: ApolloLink.from([authMiddleware, errorLink, stateLink, pusherLink, httpLink]),
  cache: appCache,
  shouldBatch: true
})

export const NetworkStatus = {
  loading: 1,
  setVariables: 2,
  fetchMore: 3,
  refetch: 4,
  poll: 6,
  ready: 7,
  error: 8
}

// This function is used for AJAX calls
let refreshTokenPromise
export const refreshToken = () => {
  if (refreshTokenPromise) {
    return refreshTokenPromise
  }

  refreshTokenPromise = new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: DO_REFRESH
      })
      .then(({ data }) => {
        localStorage.setItem('authToken', data.doRefresh.accessToken)
        resolve()

        refreshTokenPromise = false
      })
      .catch(error => {
        localStorage.removeItem('authToken')
        appCache.writeData({
          data: {
            authStatus: {
              __typename: 'authStatus',
              status: 'loggedOut'
            }
          }
        })

        // window.location.href = '/'
        reject(error)

        refreshTokenPromise = false
      })
  })

  return refreshTokenPromise
}

export default client

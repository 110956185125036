import React from 'react'

const style = {
  marginBottom: '35px'
}
const recentlyDeletedStyle = {
  marginLeft: '35px'
}
const wrapperStyle = {
  display: 'flex',
  justifyContent: 'flex-start'
}

const barStyle = {
  height: '8px',
  width: '0%',
  background: '#d5743e',
  display: 'block',
  borderRadius: '4px'
}

const backgroundStyle = {
  height: '8px',
  width: '264px',
  backgroundColor: 'white',
  borderRadius: '4px'
}

const StorageLimit = props => (
  <div style= {wrapperStyle}>
    <div style={style}>
      <b
        style={{
          color: '#B3B3B3',
          display: 'block',
          marginBottom: '15px',
          fontSize: '14px'
        }}
      >
      Storage
      </b>

      <div style={backgroundStyle}>
        <span
          style={{
            ...barStyle,
            width: props.usedPercentage
          }}
        />
      </div>

      <div
        style={{
          color: 'white',
          display: 'block',
          marginTop: '10px',
          fontSize: '12px'
        }}
      >
        <span>
          {props.used} of {props.limit} Used
        </span>
      </div>

    </div>
    <div style={{ ...style, ...recentlyDeletedStyle }}>
      <b
        style={{
          color: '#B3B3B3',
          display: 'block',
          marginBottom: '15px',
          fontSize: '14px'
        }}
      >
      Recently Deleted Storage
      </b>

      <div style={backgroundStyle}>
        <span
          style={{
            ...barStyle,
            width: props.deletedPercentage
          }}
        />
      </div>

      <div
        style={{
          color: 'white',
          display: 'block',
          marginTop: '10px',
          fontSize: '12px'
        }}
      >
        <span>
          {props.deleted} of {props.limit} Deleted
        </span>
      </div>

    </div>
  </div>

)

export default StorageLimit

import React, { useState } from 'react'
import { Eye, EyeOff } from 'react-feather'
import { TextInput } from 'carbon-components-react'

import "./style.scss"

export default function PasswordInput({type, ...rest}) {
  
  const [visible, isVisible] = useState(false)
  
  return <div className="password-input">
    <TextInput
      type={visible ? 'text' : 'password'}
      {...rest}
    />
    <span className="password-icon">
      {visible
        ? <EyeOff color="white" onClick={()=>isVisible(false)} />
        : <Eye color="white" onClick={()=>isVisible(true)} />
      }
    </span>
  </div>

}

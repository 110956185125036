import React from 'react'
import { withRouter } from 'react-router'
import { compose } from 'react-apollo'

import ResponsiveTable from './ResponsiveTable'

class ProjectTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.getHeaders = this.getHeaders.bind(this)
    this.getRowActions = this.getRowActions.bind(this)
    this.isFavourite = this.isFavourite.bind(this)
    this.getFavouriteType = this.getFavouriteType.bind(this)
  }

  getHeaders = (() => {
    if (this.props.favouritePage) {
      const favouriteHeaders = [
        {
          key: 'name',
          header: 'Project',
          formatter: (data, row) => row.name
        },
        {
          key: 'artist.name',
          header: 'Artist',
          formatter: (data, row) => row.artist.name
        }
      ]

      return () => favouriteHeaders
    }

    const headers = [
      {
        key: 'name',
        header: 'Project'
      },
      {
        key: 'artist.name',
        header: 'Artist',
        formatter: (data, row) => row.artist ? row.artist.name : ''
      }
    ]

    return () => headers
  })()

  getRowActions = (() => {
    let actions = [
    ]

    return () => actions
  })()

  isFavourite(row) {
    return this.props.favourites.indexOf(parseInt(row.id, 10)) > -1
  }

  getFavouriteType() {
    return 'project'
  }

  generateLink(row) {
    return `/projects/${row.id}`
  }

  render() {
    return (
      <ResponsiveTable headers={this.getHeaders()}
        {...this.props}
        linkColumn='name'
        generateLink={this.generateLink}
        isFavourite={this.isFavourite}
        rowActions={this.getRowActions()}
        getFavouriteType={this.getFavouriteType}
        emptyText='No Projects'
      />
    )
  }
}


export default compose(
  withRouter,
)(ProjectTable)


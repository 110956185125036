import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import DropdownMenu from './Dropdowns/DropdownMenu'
import SearchField from './SearchField'

const CreatePagesHeader = ({
  title,
  subtitle,
  btnText,
  btnOnClick,
  linkUrl,
  linkText,
  showSearch,
  getSearchTerm,
  name,
  children,
  buttons,
  headerText,
  actionOnClick,
  actionText,
  withDivider = true,
  tags,
  secondaryBtnOnClick,
  secondaryBtnText,
  addClassName,
  dropDown,
  dropDownMenuList,
  refetch,
  commentsPaneText,
  commentsPaneOnClick
})=> {
  const [searchTerm, setSearchTerm] = useState('')
  const secondaryIcon = btnText === 'Upload' ?
    require('../assets/upload-arrow.svg') :
    require('../assets/add.svg')
  const icon = btnText === 'Edit' ? require('../assets/edit.svg') : secondaryIcon


  const refetchHandler = (e) => {
    e.preventDefault()
    refetch()
  }

  const commentsIconPath = 'M4 12H16V10H4V12ZM4 9H16V7H4V9ZM4 6H16V4H4V6ZM20 20L16 16H2C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H18C18.55 0 19.021 0.195667 19.413 0.587C19.8043 0.979 20 1.45 20 2V20ZM2 2V14H16.825L18 15.175V2H2Z' // eslint-disable-line max-len

  return (
    <>
    <div className={`create-hero ${addClassName ? addClassName : ''}`}>
      {children ? <div className='sections-container'>{ children }</div> : null }
      { title ? <>
        <div className='sections-container'>
          <h2>{title}</h2>
        </div>
       </> : null}
      {withDivider && <hr className='separator' />}
      <div className='content-wrapper'>

        <div className='sections-container'>
          <div className='parent-wrapper'>
            <div className='title-wrapper'>
              {subtitle ? <h4>{subtitle}</h4> : null}
              {btnText && btnOnClick ? <button type='button' className='hero-edit-button'
                onClick={btnOnClick}>
                <img src={icon} alt='Edit'/>
              </button> : null
              }
              {dropDown ? dropDown : null}
              {dropDownMenuList ? (
                <DropdownMenu
                  dropdownList={dropDownMenuList}
                />
              ) : null}
              {secondaryBtnText && secondaryBtnOnClick ? <button type='button' className='hero-edit-button'
                onClick={secondaryBtnOnClick}>
                <img src={require('../assets/images/connect.svg')} alt='Edit' />
              </button> : null}
              {linkUrl && linkText
                ? <> <span className='vertical-line'>|</span>  <Link to={linkUrl} className='create-header-link'>{' ' + linkText}</Link>
                </> : null}
              {tags ? <div className='tags-wrapper'>
                {tags.map(({ tagName }) => (<span className='tag'>
                  {tagName}
                </span>))}

              </div> : null}
              {(buttons || []).map(button => (
                <button type='button' onClick={button.onClick}
                  className='hero-button'>
                  {button.text}
                </button>
              ))}
            </div>

            <div className='actions-wrapper'>

              { refetch &&
                <div className='refetchIconContainer' onClick={refetchHandler}>
                  <img src={require('../assets/images/restart.svg')} className='refetchIcon' alt='reload' />
                </div>
              }

              {headerText &&
                <p className='sectionHeaderText'>{headerText}</p>}

              {showSearch ? <SearchField className='appSearch' id='app-search'
                value={searchTerm}
                name={name}
                onChange={e => {
                  setSearchTerm(e.target.value)
                  getSearchTerm(e.target.value, e.target.name)
                } } /> : null}
              {actionOnClick && actionText &&
            <button type='button' onClick={actionOnClick}
              className='sectionHeaderLink'>
              {actionText}
            </button>
              }
              <div className='commentsPaneContainer'>
                {commentsPaneText && commentsPaneOnClick &&
            <button type='button' onClick={actionOnClick}
              className='sectionHeaderLink commentsPane'>
              {commentsPaneText}
              <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                <path d={commentsIconPath} fill='white'/>
              </svg>
            </button>
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    </>
  )
}

export default CreatePagesHeader

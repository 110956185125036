import React from 'react'
import profile from '../assets/account/inactive/AccountInActive.svg'
import profileActive from '../assets/account/active/AccountActive.svg'
import affiliations from '../assets/account/inactive/MyPartyInActive.svg'
import subscriptionImg from '../assets/account/inactive/SubscriptionInActive.svg'
import subscriptionActive from '../assets/account/active/SubscriptionActive.svg'
import affiliationsActive from '../assets/account/active/MyPartyActive.svg'
import integration from '../assets/account/inactive/IntegrationInActive.svg'
import integrationsActive from '../assets/account/active/IntegrationsActive.svg'
import customizationActive from '../assets/account/active/CustomizationActive.svg'
import customizationImg from '../assets/account/inactive/CustomizationInActive.svg'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router'
import AccountSubMenu from './AccountSubMenu'
import GetUser from '../hocs/queries/GetUser'
import {
  PLAN_PRO,
  PLAN_PRO_UNLIMITED,
  PLAN_EDUCATION
} from '../utils/constants'

const account = '/account'
const myParty = '/account/myParty'
const customizationPage = '/account/customization'
const integrations = '/account/integrations'
const subscription = '/account/subscription'
const billing = '/account/subscription/billing'


class MenuSwitcher extends React.Component {
  render() {
    const path = window.location.pathname
    return (
      <div className='main-Switcher'>
        <AccountSubMenu
          title='Account'
          onClickHandler={() => this.props.history.push(account)}
          titleClass={
            path === account
              ? 'main-Switcher-Switch-Selected'
              : 'main-Switcher-Switch-None'
          }
          icon={path === account ? profileActive : profile}
        />
        <AccountSubMenu
          title='My Party'
          onClickHandler={() => this.props.history.push(myParty)}
          titleClass={
            path === myParty
              ? 'main-Switcher-Switch-Selected'
              : 'main-Switcher-Switch-None'
          }
          icon={path === myParty ? affiliationsActive : affiliations}
        />
        {(this.props.user.subscriptions[0].stripePlan === PLAN_PRO ||
          this.props.user.subscriptions[0].stripePlan === PLAN_PRO_UNLIMITED ||
          this.props.user.subscriptions[0].stripePlan === PLAN_EDUCATION) && (
          <AccountSubMenu
            title='Customization'
            onClickHandler={() => this.props.history.push(customizationPage)}
            titleClass={
              path === customizationPage
                ? 'main-Switcher-Switch-Selected'
                : 'main-Switcher-Switch-None'
            }
            icon={path === customizationPage ? customizationActive : customizationImg }
          />
        )}
        <AccountSubMenu
          title='Integrations'
          onClickHandler={() => this.props.history.push(integrations)}
          titleClass={
            path === integrations
              ? 'main-Switcher-Switch-Selected'
              : 'main-Switcher-Switch-None'
          }
          icon={path === integrations ? integrationsActive : integration}
        />
        <AccountSubMenu
          title='Subscription'
          onClickHandler={() => this.props.history.push(subscription)}
          titleClass={
            path === subscription || path === billing
              ? 'main-Switcher-Switch-Selected'
              : 'main-Switcher-Switch-None'
          }
          icon={
            path === subscription || path === billing
              ? subscriptionActive
              : subscriptionImg
          }
        />
      </div>
    )
  }
}
export default compose(
  withRouter,
  GetUser,
)(MenuSwitcher)

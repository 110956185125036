import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    email: Field()
      .string()
      .email({
        message: 'The value must be a valid E-Mail address.'
      })
      .required({ message: Errors.Required })
  },
  { verbose: true, async: true }
)

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'

import { GET_ALL_PROJECT_CREDITS } from '../../graphql/queries'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import * as permissions from '../../utils/permissions'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import CreditTable from '../../components/CreditTable'
import SectionContainer from '../../components/SectionContainer'
import WithAlertModal from '../../hocs/WithAlertModal'

import Hero from '../../components/Hero'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import { filterPermissions } from '../../utils/permissions'
import recordingIcon from '../../assets/images/recording.svg'
import sessionIcon from '../../assets/images/session.svg'
import badgesIcon from '../../assets/images/badges.svg'
import { getTags } from '../../utils/permissions'


class ProjectCredits extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
    this.createProjectCredit = this.createProjectCredit.bind(this)
    this.createRecordingCredit = this.createRecordingCredit.bind(this)
    this.createSessionCredit = this.createSessionCredit.bind(this)
  }
   getSearchTerm=(value)=>{
     this.setState({
       searchTerm: value
     })
   }

   // permission tags to show permission on project credit header

   getTags=(checkPermissions)=>{
     const tags = []
     const permissionsKyes = Object.keys(checkPermissions)
     permissionsKyes.forEach(key =>{
       if (checkPermissions[key] === true) {
         tags.push({
           tagName: key.charAt(0).toUpperCase() + key.slice(1)
         })
       }
     })
     const allAreTrue = (checkTrue) =>{
       const fullAcess = Object.values(checkTrue).every(value => value === true)
       return fullAcess
     }
     if (allAreTrue(checkPermissions)) {
       return [{ tagName: 'Full Access' }]
     }

     return tags
   }

   // Start Routes for Create Credits

   createProjectCredit = ()=> {
     if (this.props.project.locked) {
       alert('Project Credits are locked. Please unlock to edit.', { title: '   ', className: 'credits-alert' })
       return
     }
     this.props.history.push(`/projects/${this.props.project.id}/credits/create`)
   }
   createRecordingCredit = ()=> {
     if (this.props.project.locked) {
       alert('Project Credits are locked. Please unlock to edit.', { title: '   ', className: 'credits-alert' })
       return
     }
     this.props.history.push(`/projects/${this.props.project.id}/recording-credits/create`)
   }
  createSessionCredit = ()=> {
    if (this.props.project.locked) {
      alert('Project Credits are locked. Please unlock to edit.', { title: '   ', className: 'credits-alert' })
      return
    }
    this.props.history.push(`/projects/${this.props.project.id}/session-credits/create`)
  }

  //  End Routes for Create Credits

   creditDropdownList = [
     {
       name: 'Project Credit',
       icon: badgesIcon,
       click: this.createProjectCredit
     },
     { name: 'Recording Credit', icon: recordingIcon, click: this.createRecordingCredit },
     { name: 'Session Credit', icon: sessionIcon, click: this.createSessionCredit }
   ];

   render() {
     const { project, user, match } = this.props
     const hasFullAccess = permissions.hasFullAccess(project.id, user)

     const isProjectOwner = permissions.isProjectOwner(project.id, user)
     const can = permissions.can(project.id, 'session')
     const canCreate = hasFullAccess || isProjectOwner || can.create
     const canUpdate = hasFullAccess || isProjectOwner || can.update
     const canDelete = hasFullAccess || isProjectOwner || can.delete
     const cretitPermissions = permissions.can(project.id, 'session')
     const canSongs = permissions.can(project.id, 'song')

     return (
      <>
        <Helmet>
          <title>Credits | {project.name}</title>
        </Helmet>
        <main className='main Breadcrumb-Project-Credits project-credit-list'>
          <div className={`${project.locked ? 'ghosted-wrapper' : ''}`}>
            <Hero
              dropDownMenuList={canCreate ? this.creditDropdownList : false}
              subtitle={`${project.name} Credits`}
              showSearch={true}
              getSearchTerm={this.getSearchTerm}
            >
              <Breadcrumb
                items={[
                  {
                    href: `/projects`,
                    name: 'Projects'
                  },
                  {
                    href: `/projects/${project.id}`,
                    name: project.name
                  },
                  {
                    href: `/projects/${project.id}/credits`,
                    name: 'Project Credits'
                  }
                ]}
              />
            </Hero>
            <CreatePagesHeader
              subtitle='Project Credits'
              tags={!isProjectOwner ? getTags(filterPermissions(cretitPermissions)) : []}
            />
            <div className='mainContainer project-files'>
              <SectionContainer>
                <Query
                  query={GET_ALL_PROJECT_CREDITS}
                  variables={{ projectId: match.params.projectId }}
                  fetchPolicy='cache-and-network'
                >
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    return (
                      <CreditTable
                        nameCellClass='credit-table'
                        showSearch={false}
                        rows={data.getCreditsByProject || []}
                        locked={project.locked}
                        showSkeleton={loading}
                        projectId={project.id}
                        refetch={refetch}
                        pagination
                        actions
                        rowActions
                        sortByOrder={true}
                        sortDirection='ASC'
                        sortColumn='name'
                        searchTerm={this.state.searchTerm}
                        canUpdate={canUpdate}
                        canDelete={canDelete}
                        onDelete={refetch}
                        songPermissions={canSongs}
                        perPage={100}
                        isProjectOwner={isProjectOwner}
                      />
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>

          </div>
        </main>
      </>
     )
   }
}

export default compose(
  GetUser,
  GetProject,
  WithAlertModal
)(ProjectCredits)

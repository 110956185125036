import React, { useState } from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'
import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import * as permissions from '../../utils/permissions'
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import GetRecording from '../../hocs/queries/GetRecording'
import WithAlertModal from '../../hocs/WithAlertModal'
import Hero from '../../components/Hero'
import { GET_ALL_RECORDING_CREDITS } from '../../graphql/queries'
import WithComments from '../../hocs/WithComments'
import DeleteSessionRecording from '../../hocs/mutations/DeleteSessionRecording'
import { getUserFavouritesByType } from '../../utils/selectors'
import { WithMoveFilesFrom } from '../../hocs/MoveFiles'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import { getTags, filterPermissions } from '../../utils/permissions'
import CreditTable from '../../components/CreditTable'
import RecordingDropdownIcon from '../../assets/recordings/recording-collaborator.svg'
import SessionDropdownIcon from '../../assets/images/sessionCredit.svg'

const ProjectRecordingCredits = props => {
  const [searchTerm, setSearchTerm] = useState('')

  const getSearchTerm = value => {
    setSearchTerm(value)
  }

  const filterCredits = rows => {
    if (!rows) {
      return []
    }

    let filteredRows = rows.filter(
      row =>
        row.party &&
        row.party.name
          .toString()
          .toLowerCase()
          .includes(searchTerm.toString().toLowerCase())
    )
    return filteredRows || []
  }

  const { project, recording, user, history } = props
  const isProjectOwner = permissions.isProjectOwner(project.id)


  // note: the credits use the same permissions property as the sessions.
  const canSession = permissions.can(project.id, 'session')

  const isRecordingCollaborator = permissions.hasRecordingAccess(
    project.id,
    recording.id
  )

  const canCreateCredit =
    isProjectOwner || isRecordingCollaborator || canSession.create
  const canUpdateCredit =
    isProjectOwner || isRecordingCollaborator || canSession.update
  const canDeleteCredit =
    isProjectOwner || isRecordingCollaborator || canSession.delete
  const canSongs = permissions.can(project.id, 'song')

  const creditPermissions = permissions.can(project.id, 'session')


  const favourites = getUserFavouritesByType(user)

  let creditDropdownList = [
    {
      name: 'Recording Credit',
      icon: RecordingDropdownIcon,
      click: () =>
        history.push({
          pathname: `/projects/${project.id}/recordings/${recording.id}/credits/create`,
          state: { contributionType: 'recording' }
        })
    },
    {
      name: 'Session Credit',
      icon: SessionDropdownIcon,
      click: () =>
        history.push({
          pathname: `/projects/${project.id}/credits/create`,
          state: { contributionType: 'session' }
        })
    }
  ]

  return (
    <>
      <Helmet>
        <title>Credits | {recording.name}</title>
      </Helmet>
      <main className='main projectRecordingCredits'>
        <div className={`${project.locked ? 'ghosted-wrapper' : ''}`}>
          <Hero
            subtitle={`${recording.name} Credits`}
            getSearchTerm={getSearchTerm}
            showSearch={true}
            dropDownMenuList={canCreateCredit ? creditDropdownList : false}
          >
            <Breadcrumb
              items={[
                {
                  href: `/projects`,
                  name: 'Projects'
                },
                {
                  href: `/projects/${project.id}`,
                  name: project.name
                },
                {
                  href: `/projects/${project.id}/recordings`,
                  name: 'Recordings'
                },
                {
                  href: `/projects/${project.id}/recordings/${recording.id}`,
                  name: recording.name
                },
                {
                  href: `/projects/${project.id}/recordings/${
                    recording.id
                  }/files`,
                  name: 'Recording Credits'
                }
              ]}
            />
          </Hero>
          <CreatePagesHeader
            subtitle='Recording Credits'
            withDivider={false}
            // eslint-disable-next-line no-nested-ternary
            tags={!isProjectOwner && !isRecordingCollaborator ? getTags(filterPermissions(creditPermissions)) : isRecordingCollaborator ? [{ tagName: 'Full Access' }] : []}
          />
          <div className='mainContainer'>
            <SectionContainer>
              <Query
                query={GET_ALL_RECORDING_CREDITS}
                variables={{
                  recordingId: recording.id
                }}
                fetchPolicy='cache-and-network'
              >
                {({ loading, error, data, refetch }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }

                  let filteredRows = filterCredits(
                    data.getCreditsByRecording || []
                  )

                  return (
                    <>
                      <SectionContainer>
                        <div className='project-files '>
                          <CreditTable
                            nameCellClass='project-recording-credit-table'
                            rows={filteredRows || []}
                            locked={project.locked}
                            showSkeleton={loading}
                            projectId={project.id}
                            recording={recording}
                            favourites={favourites.credit || []}
                            canUpdate={canUpdateCredit}
                            canDelete={canDeleteCredit}
                            onDelete={refetch}
                            showSearch={false}
                            songPermissions={canSongs}
                            pagination={true}
                            refetch={refetch}
                            actions={true}
                            perPage={100}
                            isProjectOwner={isProjectOwner}
                          />
                        </div>
                      </SectionContainer>
                    </>
                  )
                }}
              </Query>
            </SectionContainer>
          </div>
        </div>
      </main>
    </>
  )
}

export default compose(
  GetUser,
  GetProject,
  GetRecording,
  DeleteSessionRecording,
  WithAlertModal,
  WithComments,
  WithMoveFilesFrom
)(ProjectRecordingCredits)

import { Loading } from 'carbon-components-react'
import uploadIcon from '../assets/upload-arrow.svg'
import React, { useRef } from 'react'

function UploadFileIcon({ onClick, className, style, loading }) {
  const input = useRef(null)

  const handleIconClick = () => input.current && input.current.click()
  const handleInputChange = e => {
    if (onClick) {
      onClick(e)
    }
    input.current.value = ''
  }

  return (
    <div className='action-container'>
      {loading ? (
        <Loading withOverlay={false} small className='action-btn-loading' />
      ) : (
        <img
          src={uploadIcon}
          alt='upload'
          role='button'
          className={'dpi-action ' + className}
          style={style}
          onClick={handleIconClick}
          title='Upload'
        />

      )}
      <input
        type='file'
        accept='image/png, image/jpg, image/jpeg'
        hidden
        ref={input}
        onChange={handleInputChange}
      />
    </div>
  )
}

export default UploadFileIcon

import React from 'react'
import { Query } from 'react-apollo'
import { GET_SESSION } from '../../graphql/queries'

const GetSession = (WrappedComponent, fetchPolicy = 'network-only') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_SESSION}
          variables={{ id: this.props.match.params.sessionId }}
          fetchPolicy={fetchPolicy}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            if (data.getSession === null) {
              return <h1 style={{color: 'white', textAlign: 'center'}}>404</h1>
            }

            return (
              <WrappedComponent {...this.props} refetch={refetch} session={data.getSession} />
            )
          }}
        </Query>
      )
    }
  }
}

export default GetSession

export const GetSessionRefreshed = (WrappedComponent) => {
  return GetSession(WrappedComponent, 'network-only')
}

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'
import { DateTime } from 'luxon'

import { UPDATE_RECORDING } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import { GetRecordingRefreshed } from '../../hocs/queries/GetRecording'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import RecordingForm from '../../components/RecordingForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class RecordingEdit extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.recording.name} | {this.props.project.name}</title>
        </Helmet>
        <main className='main editRecording'>
          <CreatePagesHeader

            title={`${this.props.recording.name} ${this.props.recording.version ? ` - ${this.props.recording.version}` : ''} `}
            subtitle='Edit Recording Details'
          >
            <Breadcrumb items={[
              {
                href: `/projects`,
                name: 'Projects'
              },
              {
                href: `/projects/${this.props.project.id}`,
                name: this.props.project.name
              },
              {
                href: `/projects/${this.props.project.id}/recordings`,
                name: 'Project Recordings'
              },
              {
                href: `/projects/${this.props.project.id}/recordings/${this.props.recording.id}`,
                name: this.props.recording.name
              },
              {
                href:
                  `/projects/${this.props.project.id}/recordings/${this.props.recording.id}/edit`,
                name: 'Edit Recording Details'
              }
            ]} />
          </CreatePagesHeader>

          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={UPDATE_RECORDING}
                update={() => {
                  this.props.history.push(
                    `/projects/${this.props.project.id}/recordings/${this.props.recording.id}`
                  )
                }}>
                {(updateRecording, { loading, error }) => (
                  <RecordingForm {...this.props.recording}
                    project={this.props.project}
                    onSubmit={(state) => {
                      let data = { ...state }
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      updateRecording({
                        variables: {
                          ...data,
                          recordingId: this.props.recording.id,
                          recordedOn: data.recordedOn ?
                            DateTime.fromFormat(data.recordedOn, 'LL/dd/yyyy').toISODate() : null,
                          mixedOn: data.mixedOn ?
                            DateTime.fromFormat(data.mixedOn, 'LL/dd/yyyy').toISODate() : null
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error}
                    editing={true}
                  />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetRecordingRefreshed
)(RecordingEdit)

import React from 'react'
import { debounced } from '../utils/functions'

const DetectEdgeTile = (WrappedComponent) => {
  return class extends React.PureComponent {
    state = {
      edgeTile: false
    }

    constructor(props) {
      super(props)
      this.detectEdgeTile = debounced(500, this.detectEdgeTile.bind(this))

      if (typeof props.index === 'undefined') {
        throw new Error('Missing index for Detect Edge Tile')
      }
    }

    componentDidMount() {
      window.addEventListener('resize', this.detectEdgeTile)
      this.detectEdgeTile()
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.detectEdgeTile)
    }

    detectEdgeTile() {
      const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

      if (viewportWidth >= 1200) {
        if (this.props.index % 6 === 5) {
          this.setState({
            edgeTile: true
          })
          return
        }
      } else if (viewportWidth >= 700) {
        if (this.props.index % 4 === 3) {
          this.setState({
            edgeTile: true
          })
          return
        }
      } else if (viewportWidth >= 560) {
        if (this.props.index % 3 === 2) {
          this.setState({
            edgeTile: true
          })
          return
        }
      } else if (this.props.index % 2 === 1) {
        this.setState({
          edgeTile: true
        })
        return
      }

      if (this.state.edgeTile === false) {
        return
      }

      this.setState({
        edgeTile: false
      })
    }

    render() {
      return (
        <WrappedComponent {...this.props} edgeTile={this.state.edgeTile} />
      )
    }
  }
}

export default DetectEdgeTile

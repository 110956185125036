import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'

import ResponsiveTable from './ResponsiveTable'
import { DELETE_PARTY_CONTACT } from '../graphql/mutations'
import WithAlertModal from '../hocs/WithAlertModal'


const headers = [
  {
    key: 'name',
    header: 'Name',
    formatter: (name) => `${name ? name : '-'}`
  },
  {
    key: 'number',
    header: 'ID',
    formatter: (number) => `${number ? number : '-'}`
  },
  {
    key: 'type',
    header: 'Type',
    formatter: (type) => `${type ? type : '-'}`
  }
]

class PartyAffiliationTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.getRowActions = this.getRowActions.bind(this)
  }

  getRowActions = (() => {
    const actions = [
      {
        itemText: 'Edit',
        onClick: (row) =>
          this.props.history.push(`/parties/${this.props.partyId}/contacts/${row.id}`)
      },
      {
        itemText: 'Delete',
        onClick: (row) =>
          this.props.deletePartyContact({
            variables: {
              id: row.id
            },
            update: this.props.onDelete
          }).catch(() => {
            this.props.alert('This party contact could not be deleted.')
          })
      }
    ]

    return () => actions
  })()

  render() {
    return (
      <ResponsiveTable headers={headers}
        {...this.props}
        rowActions={false}
        emptyText='No Affiliations'
      />
    )
  }
}

export default compose(
  withRouter,
  WithAlertModal,
  graphql(DELETE_PARTY_CONTACT, { name: 'deletePartyContact' })
)(PartyAffiliationTable)


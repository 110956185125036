import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'

import {
  DELETE_COLLABORATOR,
  DO_RESEND_COLLABORATOR_INVITE,
  LEAVE_COLLABORATOR } from '../graphql/mutations'
import { GET_COLLABORATORS } from '../graphql/queries'

import ResponsiveTable from './ResponsiveTable'
import WithAlertModal from '../hocs/WithAlertModal'


class CollaboratorsTable extends React.PureComponent {
  state = {
    originalRows: [],
    rows: [],
    headers: []
  }

  constructor(props) {
    super(props)

    this.getRowActions = this.getRowActions.bind(this)
  }

  getRowActions = (() => {
    const projectText = 'Are you sure you want to leave this Project?  By leaving, you will no longer be able to participate as a collaborator and will lose access to any files you have uploaded, as well as comments and metadata associated with the project.'
    const recordingText = 'Are you sure you want to leave this Recording?  By leaving, you will no longer be able to participate as a collaborator and will lose access to any files you have uploaded, as well as comments and metadata associated with the recording.'
    const actions = [
      {
        itemText: 'Edit',
        canBeApplied: row => this.props.canUpdate && row.accepted !== 'Owner' && row.userId !== this.props.userId,
        onClick: row =>
          this.props.history.push(`/projects/${this.props.projectId}/collaborators/${row.id}`)
      },
      {
        itemText: 'Remove',
        canBeApplied: row => (this.props.canDelete) && row.accepted !== 'Owner' && row.userId !== this.props.userId,
        onClick: row => {
          this.props.alert('Are you sure you want to remove this collaborator?', {
            type: 'confirm',
            buttonText: 'Yes',
            submitCallback: close => {
              this.props.deleteCollaborator(row.id)
                .then(() => {
                  this.props.alert('The collaborator has been successfully removed.')
                })
              close()
            }
          })
        }
      },
      {
        itemText: 'Resend Invite',
        canBeApplied: row => this.props.canUpdate && row.accepted === 'No',
        onClick: row => {
          this.props.doResendCollaboratorInvite(parseInt(row.id, 0))
          this.props.alert('The invitation has been resent')
        }
      },
      {
        itemText: this.props.projectPage ? 'Leave Project' : 'Leave Recording',
        canBeApplied: row => row.userId === this.props.userId && !(row.type === 'Project' && !this.props.projectPage),
        onClick: row => {
          this.props.alert(this.props.projectPage ? projectText : recordingText, {
            type: 'confirm',
            buttonText: 'Yes',
            submitCallback: async (close) => {
              await this.props.leaveCollaborator({
                collaboratorId: row.id,
                recordingId: this.props.recordingId || ''
              })
              close()
              this.props.history.push(`/projects`)
            }
          })
        }
      }
    ]

    return () => actions
  })()


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rows === prevState.originalRows) {
      return null
    }

    let owner = false

    if (nextProps.owner) {
      owner = {
        id: 'owner',
        type: 'Project',
        name: nextProps.owner.name,
        email: nextProps.owner.email,
        accepted: 'Owner',
        userId: 0
      }
    }

    const formattedRows = nextProps.rows.map(row => {
      let type = 'Project'

      if (row.type === 'recording') {
        type = 'Recording'
      }

      return {
        id: row.id,
        type: type,
        name: row.accepted ? row.user.name : row.name,
        email: row.accepted ? row.user.email : row.email,
        accepted: row.accepted ? 'Yes' : 'No',
        userId: row.user ? row.user.id : ''
      }
    })

    const header = [
      {
        key: 'name',
        header: `Collaborator`
      },
      {
        key: 'type',
        header: `Type`
      },
      {
        key: 'email',
        header: 'Email'
      },
      {
        key: 'accepted',
        header: 'Accepted'
      }
    ]

    let rows = [owner, ...formattedRows].filter(a => a !== false)

    if (rows) {
      const entryIndex = rows.findIndex(entry => entry.userId === nextProps.userId)

      if (entryIndex !== -1) {
        const entry = rows.splice(entryIndex, 1)[0]
        rows.unshift(entry)
      }
    }

    return {
      rows: rows,
      originalRows: nextProps.rows,
      headers: header
    }
  }

  render() {
    return (
      <ResponsiveTable
        {...this.props}
        headers={this.state.headers}
        rows={this.state.rows}
        showSkeleton={this.props.showSkeleton}
        rowActions={this.getRowActions()}
        emptyText='No Collaborators'
        sortDirection='NONE'
      />
    )
  }
}

export default compose(
  withRouter,
  WithAlertModal,
  graphql(DELETE_COLLABORATOR, {
    name: 'deleteCollaborator',
    props: data => {
      return {
        deleteCollaborator: id => {
          return new Promise((resolve) => {
            data.deleteCollaborator({
              variables: {
                collaboratorId: id
              },
              update: cache => {
                const { getCollaborators } = cache.readQuery({
                  query: GET_COLLABORATORS,
                  variables: {
                    projectId: data.ownProps.projectId
                  }
                })
                const collaborators = getCollaborators.filter(collaborator => collaborator.id !== id)
                cache.writeQuery({
                  query: GET_COLLABORATORS,
                  variables: {
                    projectId: data.ownProps.projectId
                  },
                  data: {
                    getCollaborators: collaborators
                  }
                })
                resolve(true)
              }
            })
          })
        }
      }
    }
  }),
  graphql(DO_RESEND_COLLABORATOR_INVITE, {
    name: 'doResendCollaboratorInvite',
    props: data => {
      return {
        doResendCollaboratorInvite: id => {
          data.doResendCollaboratorInvite({
            variables: {
              collaboratorId: id
            }
          })
        }
      }
    }
  }),
  graphql(LEAVE_COLLABORATOR, {
    name: 'leaveCollaborator',
    props: data => {
      return {
        leaveCollaborator: payload => {
          data.leaveCollaborator({
            variables: payload
          })
        }
      }
    }
  })
)(CollaboratorsTable)

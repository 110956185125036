import { graphql } from 'react-apollo'
import { CREATE_USER_FAVOURITE } from '../../graphql/mutations'
import { GET_USER } from '../../graphql/queries'

export default graphql(CREATE_USER_FAVOURITE, {
  name: 'createUserFavourite',
  props: (data) => {
    return {
      createUserFavourite: (resourceId, resourceType) => {
        data.createUserFavourite({
          variables: {
            resource_type: resourceType.toLowerCase(),
            resource_id: parseInt(resourceId, 10)
          },
          // optimisticResponse: {
          //   __typename: 'Mutation',
          //   createUserFavourite: {
          //     resourceId: parseInt(resourceId, 10),
          //     resourceType: resourceType.toLowerCase(),
          //     __typename: 'UserFavourite'
          //   }
          // },
          update: (cache, { data: { createUserFavourite } }) => {
            const { getUser } = cache.readQuery({ query: GET_USER })
            const user = { ...getUser }
            user.favourites = [...user.favourites, createUserFavourite]
            cache.writeQuery({
              query: GET_USER,
              data: {
                getUser: user
              }
            })
          }
        })
      }
    }
  }
})

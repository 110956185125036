import React from 'react'
import { Field } from 'v4f'

export default class Validator extends React.PureComponent {
  constructor(props) {
    super(props)

    if (props.errors) {
      this.state.errors = props.errors
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDirty = this.handleDirty.bind(this)
  }

  componentDidUpdate = () => {
    try {
      const inputsSelector =
        '.bx--react-select--invalid input, .bx--text__input--invalid, [data-invalid="true"]'
      const inputs = document.querySelectorAll(inputsSelector)
      if (
        inputs.length &&
        Object.values(this.state.errors).filter(Boolean).length &&
        document.activeElement.tagName === 'BUTTON'
      ) {
        inputs[0].focus()
      }
    } catch {
      return
    }
  }

  handleSubmit(e, callback) {
    if (e) {
      e.preventDefault()
    }

    const cb = callback ? callback.bind(this) : this.submit.bind(this)
    this.validator
      .validate(this.state)
      .then(cb)
      .catch(errors => {
        this.setState({
          ...this.state,
          errors
        })
      })
  }

  handleChange(e) {
    // checks if email field
    if (e.target.name === 'email' || e.target.name === 'emails') {
    // limits email characters to 48 to avoid browser crash
      if (e.target.value.length <= 48) {
        this.setState({
          [e.target.name]: e.target.value
        })
      } else {
        const { name } = e.target
        let maxLength = 'Maximum length'
        this.setState({
          ...this.state,
          errors: {
            ...this.state.errors,
            [name]: maxLength
          }
        })
        return
      }
    }
    // if not email
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleDirty(e) {
    const { name, value } = e.target

    let isValid
    try {
      isValid = this.validator[name].validate(value, {
        verbose: true,
        values: this.state
      })
    } catch {
      isValid = 'Invalid'
    }

    if (isValid !== true) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          [name]: isValid
        }
      })
    } else {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          [name]: undefined // eslint-disable-line no-undefined
        }
      })
    }
  }

  static fixErrorNames(errors) {
    let e = {}
    Object.keys(errors).forEach(error => {
      let key = error
      if (key.indexOf('_') > -1) {
        key = key.replace(/(_\w)/g, m => m[1].toUpperCase())
      }
      e[key] = errors[error]
    })
    return e
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors === prevState.initialErrors) {
      return null
    }

    let errors = prevState.errors
    if (nextProps.errors) {
      errors = Validator.flattenInitialErrors(nextProps.errors || {}, 'input.')
    }

    return {
      // ...prevState,
      errors: Validator.fixErrorNames(errors),
      initialErrors: nextProps.errors || {}
    }
  }

  static flattenInitialErrors(initialErrors, inputKey) {
    let errors = { ...initialErrors }

    if (!errors.graphQLErrors) {
      return errors
    }

    let flatErrors = {}
    errors.graphQLErrors.forEach(error => {
      if (error.extensions.category !== 'validation') {
        return true
      }

      if (typeof error.extensions.validation !== 'undefined') {
        Object.keys(error.extensions.validation).forEach(field => {
          const fieldName = field.replace(inputKey || '', '')

          if (flatErrors[fieldName]) {
            return
          }

          let message = error.extensions.validation[field][0]
          if (inputKey) {
            message = message.replace(inputKey, '')
          }

          flatErrors[fieldName] = message
        })
      }

      if (error.message) {
        flatErrors.message = error.message
      }
    })

    if (Object.keys(flatErrors).length === 0) {
      return errors
    }

    return flatErrors
  }
}

export const notInFuture = value => {
  if (value === '' || value.length < 1) {
    return true
  }

  const today = new Date().getTime()
  const date = new Date(value).getTime()

  return today - date > 0
}

export const listOfEmails = (value) => {
  // Blank is technically not false
  if (value === '' || value.length < 1) {
    return true
  }

  const arrayOfStrings = value.split(',').map(s=>s.trim())
  return arrayOfStrings.reduce((isEmails, email) => {
    // isEmails starts true, then turns and remains false if our check is ever once false
    return isEmails && (
      // Allow user to accidentally end list with a comma
      email === ''
      // Validate as email
      || Field().string().email().validate(email)
    )
  }, true)
}

/* eslint-disable max-len */
import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'
import { Subscription as ApolloSubscription } from 'react-apollo'

import WithAuth from '../hocs/WithAuth'
import { UploadsConsumer } from '../contexts/Uploads'
import { GET_USER } from '../graphql/queries'
import {
  USER_SUBSCRIPTION_UPDATED,
  USER_PERMISSIONS_UPDATED,
  USER_STORAGE_UPDATED
} from '../graphql/subscriptions'

// Layouts
import Base from '../layouts/Base'

// General Pages
import AccountEdit from '../pages/AccountEdit'
import MyParty from '../pages/MyParty'
import Dashboard from '../pages/Dashboard'
import Favourites from '../pages/Favourites'
import Files from '../pages/Files'
import ForgotPassword from '../pages/ForgotPassword'
import Invite from '../pages/Invite'
import Login from '../pages/LogIn'
import Share from '../pages/Share'
import Download from '../pages/Download'
import Parties from '../pages/Parties'
import Registration from '../pages/Registration'
import ResetPassword from '../pages/ResetPassword'
import Search from '../pages/Search'
import Songs from '../pages/Songs'
import Subscription from '../pages/Subscription'
import Verification from '../pages/Verification'

// Project Pages
import Projects from '../pages/Projects'
import ProjectCredits from '../pages/Project/ProjectCredits'
import ProjectDashboard from '../pages/Project/ProjectDashboard'
import ProjectCreate from '../pages/Project/ProjectCreate'
import ProjectEdit from '../pages/Project/ProjectEdit'
import ProjectFiles from '../pages/Project/ProjectFiles'
import ProjectRecordings from '../pages/Project/ProjectRecordings'
import ProjectSessions from '../pages/Project/ProjectSessions'

import ProjectCreditCreate from '../pages/Credit/ProjectCreditCreate'
import ProjectCreditEdit from '../pages/Credit/ProjectCreditEdit'

import CollaboratorCreate from '../pages/Collaborator/CollaboratorCreate'
import CollaboratorEdit from '../pages/Collaborator/CollaboratorEdit'

import RecordingCreate from '../pages/Recording/RecordingCreate'
import RecordingDetail from '../pages/Recording/RecordingDetail'
import RecordingEdit from '../pages/Recording/RecordingEdit'

import RecordingCreditCreate from '../pages/Credit/RecordingCreditCreate'
import RecordingCreditEdit from '../pages/Credit/RecordingCreditEdit'

import PartyCreate from '../pages/Party/PartyCreate'
import PartyDetail from '../pages/Party/PartyDetail'
import PartyEdit from '../pages/Party/PartyEdit'
import PartyAddressCreate from '../pages/Party/Address/PartyAddressCreate'
import PartyAddressEdit from '../pages/Party/Address/PartyAddressEdit'
import PartyContactCreate from '../pages/Party/Contact/PartyContactCreate'
import PartyContactEdit from '../pages/Party/Contact/PartyContactEdit'

import SongCreate from '../pages/Song/SongCreate'
import SongDetail from '../pages/Song/SongDetail'
import SongEdit from '../pages/Song/SongEdit'
import SongCreditCreate from '../pages/Credit/SongCreditCreate'
import SongCreditEdit from '../pages/Credit/SongCreditEdit'

import SessionCreate from '../pages/Session/SessionCreate'
import SessionDetail from '../pages/Session/SessionDetail'
import SessionEdit from '../pages/Session/SessionEdit'

import SessionCreditCreate from '../pages/Credit/SessionCreditCreate'
import SessionCreditEdit from '../pages/Credit/SessionCreditEdit'
import ProjectRecordingSessions from '../pages/Project/ProjectRecordingSessions'
import LinkShare from '../pages/LinkShare'
import VerifyEmailChange from '../pages/VerifyEmailChange'
import Integrations from '../pages/Integrations/Integrations'
import PublicFileShare from '../pages/PublicFileShare/PublicFileShare'
import PublicShareDownload from '../pages/PublicShareDownload'
import BillingInformation from '../pages/BillingInformation'
import SongFiles from '../pages/Song/SongFiles'
import SongCredits from '../pages/SongCredits'
import Customization from '../pages/Customization'
import DownloadPreview from '../pages/DownloadPreview'
import PlayLists from '../pages/PlayLists'
import PlayListCreate from '../pages/playlist/PlayListCreate'
import PlaylistEdit from '../pages/playlist/PlaylistEdit'
import PlaylistDetails from '../pages/playlist/PlaylistDetails'

import PublicPlaylist from '../pages/PublicPlaylist/PublicPlaylist'
import PlaylistShare from '../pages/PublicPlaylist/PlaylistShare'
import AssignSessionToRecording from '../pages/Recording/AssignSessionToRecording'
import SessionToRecording from '../pages/Session/Sections/SessionToRecoding'
import DeletedFilesPage from '../pages/DeletedFiles'
import MyFilesPage from '../pages/MyFilesPage'
import AllProjectsPage from '../pages/AllProjectsPage'
import AllShares from '../pages/AllShares'
import AllSongsPage from '../pages/AllSongsPage'
import SessionCreditCreateFromProject from '../pages/Credit/SessionCreditCreateFromProject'
import ProjectRecordingFiles from '../pages/Project/ProjectRecordingFiles'
import ProjectRecordingCredits from '../pages/Project/ProjectRecordingCredits'
import Folder from '../pages/Folder'

const redirectToDashboard = compose(withRouter)((props) => (
  <Redirect to={'/'} />
))


const Routes = compose(WithAuth)((props) => {
  const spinner = document.getElementById('spinner')
  if (spinner && spinner.hasAttribute('hidden') === false) {
    spinner.setAttribute('hidden', 'true')
  }

  if (props.data.authStatus.status === 'loggedOut') {
    return (
      <Switch>
        <Route exact path='/reset-password/:token' component={ResetPassword} />
        <Route exact path='/verify/:token' component={Verification} />
        <Route exact path='/verify-email-change/:token' component={VerifyEmailChange} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/register' component={Registration} />
        <Route exact path='/register/discount-code/:code' component={Registration} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/share/:token' component={Share} />
        <Route exact path='/direct-links/:token' component={LinkShare} />
        <Route exact path='/download/:uuid/u/:encryptedEmail' component={Download} />
        <Route exact path='/send' component={PublicFileShare} />
        <Route exact path='/download/public-share/:token' component={PublicShareDownload} />
        <Route exact path='/public-playlist/:playlistId' component={PublicPlaylist} />
        <Route exact path='/public-playlist/:token+/u/:uuid' component={PlaylistShare} />
        <Route exact path='/' component={Login} />
        <Route component={Login} />
      </Switch>
    )
  }

  return (
    <>
    <Base>
      <Switch>
        <Route exact path='/share/:token' component={Share} />
        <Route path='/subscription' component={Subscription} />
        {/* <Route exact path='/customization' component={Customization} /> */}
        <Route exact path='/send' component={PublicFileShare} />
        <Route exact path='/direct-links/:token' component={LinkShare} />
        <Route exact path='/download/public-share/:token' component={PublicShareDownload} />
        <Route exact path='/download/:uuid/u/:encryptedEmail' component={Download} />
        <Route exact path='/invite/:token' component={Invite} />
        <Route exact path='/verify-email-change/:token' component={VerifyEmailChange} />
        <Route exact path='/public-playlist/:playlistId' component={PublicPlaylist} />
        <Route exact path='/public-playlist/:token+/u/:uuid' component={PlaylistShare} />

        <Route exact path='/account' component={AccountEdit} />
        <Route exact path='/account/myParty' component={MyParty} />
        <Route exact path='/account/integrations' component={Integrations} />
        <Route exact path='/account/subscription' component={Subscription} />
        <Route exact path='/account/subscription/billing' component={BillingInformation} />
        <Route exact path='/account/customization' component={Customization} />
        <Route exact path='/download-preview' component={DownloadPreview} />

        <Route exact path='/files/myfiles' component={MyFilesPage} />
        <Route exact path='/files/shares' component={AllShares} />
        <Route exact path='/files' component={Files} />
        <Route exact path='/files/projects' component={AllProjectsPage} />
        <Route exact path='/files/deletedfiles' component={DeletedFilesPage} />

        <Route exact path='/search' component={Search} />

        <Route exact path='/parties/create' component={PartyCreate} />
        <Route exact path='/parties/:partyId/contacts/create' component={PartyContactCreate} />
        <Route
          exact
          path='/parties/:partyId/contacts/:partyContactId'
          component={PartyContactEdit}
        />
        <Route exact path='/parties/:partyId/addresses/create' component={PartyAddressCreate} />
        <Route
          exact
          path='/parties/:partyId/addresses/:partyAddressId'
          component={PartyAddressEdit}
        />
        <Route exact path='/parties/:partyId/edit' component={PartyEdit} />
        <Route exact path='/parties/:partyId' component={PartyDetail} />
        <Route exact path='/parties' component={Parties} />

        <Route exact path='/songs/create' component={SongCreate} />
        <Route exact path='/songs/:songId/credits' component={SongCredits} />
        <Route exact path='/songs/:songId/credits/add' component={SongCreditCreate} />
        <Route exact path='/songs/:songId/credits/:creditId/edit' component={SongCreditEdit} />
        <Route exact path='/songs/:songId/edit' component={SongEdit} />
        <Route exact path='/songs/:songId/files' component={ SongFiles } />
        <Route exact path='/songs/:songId/files/:folderId' component={ SongFiles } />
        <Route exact path='/songs/:songId' component={SongDetail} />
        <Route exact path='/songs' component={Songs} />
        <Route exact path='/files/songs' component={AllSongsPage} />
        <Route exact path='/files/songs/:songId/files' component={ SongFiles } />

        <Route exact path='/projects/create' component={ProjectCreate} />
        <Route exact path='/playlists/create' component={PlayListCreate} />
        <Route exact path='/projects/:projectId/edit' component={ProjectEdit} />
        <Route exact path='/playlists/:playlistId' component={PlaylistDetails} />
        <Route exact path='/playlists/:playlistId/edit' component={PlaylistEdit} />
        <Route
          exact
          path='/projects/:projectId/collaborators/create/:type'
          component={CollaboratorCreate}
        />
        <Route
          exact
          path='/projects/:projectId/collaborators/:collaboratorId'
          component={CollaboratorEdit}
        />
        <Route
          exact
          path='/projects/:projectId/collaborators/:collaboratorId'
          component={CollaboratorEdit}
        />
        <Route exact path='/projects/:projectId/credits/create' component={ProjectCreditCreate} />
        <Route exact path='/projects/:projectId/credits/:creditId' component={ProjectCreditEdit} />
        <Route exact path='/projects/:projectId/credits' component={ProjectCredits} />
        <Route exact path='/projects/:projectId/sessions/create' component={SessionCreate} />
        <Route exact path='/projects/:projectId/sessions/:sessionId/credits/create' component={SessionCreditCreate} />
        <Route exact path='/projects/:projectId/session-credits/create' component={SessionCreditCreateFromProject} />
        <Route exact path='/projects/:projectId/sessions/:sessionId/credits/:creditId' component={SessionCreditEdit} />
        <Route exact path='/projects/:projectId/sessions/:sessionId/edit' component={SessionEdit} />
        <Route exact path='/projects/:projectId/sessions/:sessionId' component={SessionDetail} />
        <Route exact path='/projects/:projectId/sessions' component={ProjectSessions} />
        <Route exact path='/projects/:projectId/recordings/create' component={RecordingCreate} />
        <Route
          exact
          path='/projects/:projectId/recordings/:recordingId/credits/create'
          component={RecordingCreditCreate}
        />
        <Route
          exact
          path='/projects/:projectId/recording-credits/create'
          component={RecordingCreditCreate}
        />
        <Route
          exact
          path='/projects/:projectId/recordings/:recordingId/credits/:creditId'
          component={RecordingCreditEdit}
        />
        <Route
          exact
          path='/projects/:projectId/recordings/:recordingId/sessions/create'
          component={SessionCreate}
        />
        <Route
          exact
          path='/projects/:projectId/recordings/:recordingId/sessions/assign'
          component={AssignSessionToRecording}
        />
        <Route
          exact
          path='/projects/:projectId/recordings/:recordingId/sessions'
          component={ProjectRecordingSessions}
        />
        <Route
          exact
          path='/projects/:projectId/recordings/:recordingId/files'
          component={ProjectRecordingFiles}
        />
        <Route
          exact
          path='/projects/:projectId/recordings/:recordingId/credits'
          component={ProjectRecordingCredits}
        />
        <Route
          exact
          path='/projects/:projectId/recordings/:recordingId/edit'
          component={RecordingEdit}
        />
        <Route
          exact
          path='/projects/:projectId/recordings/:recordingId'
          component={RecordingDetail}
        />
        <Route exact path='/projects/:projectId/recordings' component={ProjectRecordings} />
        <Route path='/projects/:projectId/files' component={ProjectFiles} />
        <Route exact path='/projects/:projectId/files#all' component={ProjectFiles} />
        <Route exact path='/projects/:projectId' component={ProjectDashboard} />
        <Route exact path='/projects' component={Projects} />
        <Route exact path='/projects/:projectId/sessions/:sessionId/sessions-to-recording' component={SessionToRecording} />

        <Route exact path='/playlists' component={PlayLists} />

        <Route exact path='/favorites' component={Favourites} />

        <Route path='/files/:folderId' component={Folder} />

        <Route
          exact
          path='/reset-password/:token'
          component={redirectToDashboard}
        />
        <Route exact path='/verify/:token' component={redirectToDashboard} />
        <Route exact path='/login' component={redirectToDashboard} />
        <Route exact path='/register' component={redirectToDashboard} />
        <Route
          exact
          path='/forgot-password'
          component={redirectToDashboard}
        />

        <Route exact path='/' component={Dashboard} />

        <Route exact path='/redeem' render={() => <Dashboard />} />


        <ApolloSubscription
          subscription={USER_PERMISSIONS_UPDATED}
          onSubscriptionData={({ client, subscriptionData }) => {
            const { getUser } = client.readQuery({
              query: GET_USER
            })

            const user = {
              ...getUser,
              ...subscriptionData.userPermissionsUpdated
            }

            client.writeQuery({
              query: GET_USER,
              data: {
                getUser: user
              }
            })
          }}
        />

        <ApolloSubscription
          subscription={USER_STORAGE_UPDATED}
          onSubscriptionData={({ client, subscriptionData }) => {
            const { getUser } = client.readQuery({
              query: GET_USER
            })

            const user = {
              ...getUser,
              ...subscriptionData
            }

            client.writeQuery({
              query: GET_USER,
              data: {
                getUser: user
              }
            })
          }}
        />

        <UploadsConsumer>
          {(context) => (
            <ApolloSubscription
              subscription={USER_SUBSCRIPTION_UPDATED}
              onSubscriptionData={({ client, subscriptionData }) => {
                const { getUser } = client.readQuery({
                  query: GET_USER
                })
                const user = { ...getUser }
                const subscription = {
                  ...user.subscriptions[0],
                  ...subscriptionData.userSubscriptionUpdated
                }

                client.writeQuery({
                  query: GET_USER,
                  data: {
                    getUser: {
                      ...getUser,
                      subscriptions: [subscription]
                    }
                  }
                })

                context.actions.setUpgradeRequiredFlag(false)
              }}
            />
          )}
        </UploadsConsumer>
      </Switch>
    </Base>

    </>

  )
})

export default Routes

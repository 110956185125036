import React from 'react'

const Footer = ()=> {
  const currentYear = new Date().getFullYear()
  return (
    <>
    <div className='app-footer'>
      <div className='legal-text'>
        &copy;{currentYear} VEVA Sound, LLC. All rights reserved.
      </div>
      <div className='legal-links-container'>
        <a
          className='legal-links'
          target='_blank'
          href='https://vevacollect.com/legal/termsofservice'
          rel='noopener noreferrer'>
            Terms of Service
        </a>
        <span className='divider'>|</span>
        <a
          className='legal-links'
          target='_blank'
          href='https://vevacollect.com/legal/privacy-policy'
          rel='noopener noreferrer'>
          Privacy Policy
        </a>
      </div>
    </div>
    </>
  )
}
export default Footer

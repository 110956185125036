import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    firstName: Field()
      .string()
      .required({ message: Errors.Required }),
    lastName: Field()
      .string()
      .required({ message: Errors.Required }),
    email: Field()
      .string()
      .email({ message: Errors.Email })
      .required({ message: Errors.Required }),
    password: Field()
      .string()
      .required({ message: Errors.Required }),
    passwordConfirmation: Field()
      .string()
      .equals(['#password'], { message: `${Errors.Equals} Password` }),
    plan: Field()
      .string()
      .required({ message: Errors.Required }),
    discountCode: Field()
      .string(),
    terms: Field()
      .boolean()
      .truthy({ message: Errors.Required }),
    timezoneId: Field()
      .string()
      .required({ message: Errors.Required })
  },
  { verbose: true, async: true }
)

import React from 'react'
import Helmet from 'react-helmet-async'
import { Mutation } from 'react-apollo'
import { DateTime } from 'luxon'

import { CREATE_SONG } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import SongForm from '../../components/SongForm'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class SongCreate extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Create Song</title>
        </Helmet>
        <main className='main'>
         
           
            <CreatePagesHeader 
            title='New Song'
            subtitle='Song Details'
            >
               <Breadcrumb items={[
              {
                href: `/songs`,
                name: 'Songs'
              },
              {
                href: `/songs/create`,
                name: 'New Song'
              },
            ]} />
            </CreatePagesHeader>
             <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={CREATE_SONG}
                update={(cache, { data: { createSong } }) => {
                  this.props.history.push(`/songs/${createSong.id}`)
                }}>
                {(createSong, { loading, error }) => (
                  <SongForm
                    onSubmit={(state) => {
                      let data = { ...state }
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      createSong({
                        variables: {
                          ...data,
                          createdOn: data.createdOn ?
                            DateTime.fromFormat(data.createdOn, 'LL/dd/yyyy').toISODate() : null
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default GetUser(SongCreate)

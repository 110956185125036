import React from 'react'
import { Modal } from 'carbon-components-react'
import FileSharesListInModal from '../components/modals/FileSharesListInModal'
import AlertListModal from '../components/modals/AlertListModal'

// HOW TO USE
//
// props.alert('Are you sure you want to delete this song?', {
//   type: 'confirm',
//   buttonText: 'Yes',
//   secondaryText: 'No',
//   submitCallback: (close) => {
//     console.log('Confirmed')
//     close()
//   }
// })

const WithAlertModal = (WrappedComponent) => {
  return class extends React.PureComponent {
    state = {
      open: false,
      message: '',
      options: false,
      files: null,
      list: []
    }

    constructor(props) {
      super(props)

      this.close = this.close.bind(this)
      this.open = this.open.bind(this)
    }

    close() {
      this.setState({
        ...this.state,
        open: false
      })
    }

    open(message, options, files, list) {
      this.setState({
        ...this.state,
        open: true,
        message: message,
        options: { ...options },
        files: files,
        list: list
      })
    }

    render() {
      let type = 'alert'
      let title = ''
      let buttonText = 'Close'
      let secondaryText = ''
      let className = ''
      let callback = () => this.close()

      if (this.state.options && this.state.options.type) {
        type = this.state.options.type
      }

      if (type === 'confirm') {
        title = 'Are you sure?'
        buttonText = 'Confirm'
        secondaryText = 'Cancel'
      }
      if (type === 'delete') {
        title = 'Are you sure?'
        buttonText = 'Yes'
        secondaryText = 'Cancel'
      }
      if (type === 'discard') {
        title = 'Discard Changes?'
        buttonText = 'Yes'
        secondaryText = 'Cancel'
      }
      if (type === 'commentDelete') {
        title = ''
        buttonText = 'Delete'
        secondaryText = 'Cancel'
      }

      if (this.state.options && this.state.options.title) {
        title = this.state.options.title
      }
      if (this.state.options && this.state.options.secondaryText) {
        secondaryText = this.state.options.secondaryText
      }

      if (this.state.options && this.state.options.buttonText) {
        buttonText = this.state.options.buttonText
      }
      if (this.state.options && this.state.options.className) {
        className = this.state.options.className
      }

      if (this.state.options && this.state.options.submitCallback) {
        callback = () => {
          this.state.options.submitCallback(this.close)
        }
      }

      let classNames = ['modal', className]

      if (!secondaryText || secondaryText === '') {
        classNames.push('modal--no-secondary')
      }

      return (
        <>
          <WrappedComponent {...this.props} alert={this.open}/>
          <Modal className={ title === 'Timezone' ? 'timeZone-modal' : classNames.join(' ')}
            open={this.state.open}
            modalHeading={title}
            passiveModal={false}
            onRequestClose={ title !== 'Timezone' && this.close}
            onRequestSubmit={callback}
            onSecondarySubmit={this.close}
            primaryButtonText={buttonText}
            secondaryButtonText={secondaryText}
          >
            <p>{this.state.message}</p>
            {this.state.files ? <FileSharesListInModal
              files={ this.state.files }
              style={{ marginTop: '1em' }}
            /> : null}
            {this.state.list ? <>
              <AlertListModal list={this.state.list} />
            </>
              : null}
          </Modal>
        </>
      )
    }
  }
}

export default WithAlertModal

import React from 'react'
import closeArrow from '../assets/images/close-arrow-accent.svg'

const ActivityCount = (props) => (
  <div className='appActivityTitle'>
    <div className='appActivityTitle__heading'>
      Activity
      <span className='appActivityTitle__subheading'>
        {/* hide count for now */}
        {/* {props.count} */}
      </span>
    </div>
    <button className='Comments__close' onClick={props.toggleActivityFeed}>
      <img src={closeArrow} alt='close'/>
    </button>

  </div>
)

export default ActivityCount

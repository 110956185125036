import React, { useState, useEffect } from 'react'
import { compose } from 'react-apollo'

import { GetGradientFromId } from '../utils/constants'

import WithComments from '../hocs/WithComments'
import { GET_INTEGRATION_LOG, VALIDATE_RIN } from '../graphql/queries'

import CreateUserFavourite from '../hocs/mutations/CreateUserFavourite'
import DeleteUserFavourite from '../hocs/mutations/DeleteUserFavourite'
import ApolloClient from '../utils/Apollo'
import { DateTime } from 'luxon'
import WithAlertModal from '../hocs/WithAlertModal'
import Breadcrumb from './Breadcrumb'
import { Modal } from 'carbon-components-react'

import ExportIcon from '../assets/project/exportIntegration.svg'
import FolderIcon from '../assets/project/folder.svg'
import LockIcon from '../assets/project/lock.svg'
import RecordingIcon from '../assets/project/recording.svg'
import StarIcon from '../assets/project/star.svg'
import RinIcon from '../assets/project/rin.svg'
import PdfIcon from '../assets/project/pdf.svg'
import SoundExchangeIcon from '../assets/project/soundexchange.svg'
import CreatePagesHeader from './CreatePagesHeader'
import SessionIcon from '../assets/project/sessions.svg'

import IconButton from './IconButton'
import { withRouter } from 'react-router-dom'
import { memo } from 'react'
import { useMemo } from 'react'

const ProjectHero = React.memo(props => {
  const [showIntegrations, setShowIntegrations] = useState(false)
  const [showInformation, setShowInformation] = useState(false)
  const [lastExport, setLastExport] = useState({})
  const formatter = data => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
  const getIntegrationLogs = async () => {
    const { data } = await ApolloClient.query({
      query: GET_INTEGRATION_LOG,
      variables: {
        projectId: props.id
      },
      fetchPolicy: 'network-only'
    })
    const { getIntegrationLog } = data
    setLastExport(getIntegrationLog)
  }

  const projectDescriptionCheck = data => {
    if (!data) {
      return data
    }

    if (data && data.toString().length < 290) {
      return data
    }
    return (
      <div>
        {data.substring(0, 290)}
        <span
          onClick={() => setShowInformation(true)}
          className='moreDescriptionBtn'
        >
          &nbsp;...more
        </span>
      </div>
    )
  }

  const setToggle = () => {
    setShowInformation(!showInformation)
  }

  useEffect(() => {
    getIntegrationLogs()
  })

  const lockCreditsHandler = () => {
    if (!props.isProjectOwner) {
      if (props.locked) {
        props.alert(
          `The Project owner has locked the credits on this project.
         In order for you to make changes, the owner must first unlock this project.`,
          { title: '   ', className: 'credits-alert' }
        )
        return
      }
      props.alert(
        `Only the owner of this project is allowed 
      to Lock Credits, please contact the owner.`,
        { title: '   ', className: 'credits-alert' }
      )
      return
    }
    props.lockCredits()
  }

  const showIntegrationsHandler = () => {
    setShowIntegrations(cur => !cur)
    getIntegrationLogs()
  }

  const loadCommentsHandler = () => {
    props.loadComments(props.id, props.id, 'project')
  }

  const filesInfoHandler = () => {
    props.history.push(`/projects/${props.id}/files`)
  }

  const recordingsInfoHandler = () => {
    props.history.push(`/projects/${props.id}/recordings`)
  }

  const favouriteHandler = () => {
    if (props.favourite) {
      return props.deleteUserFavourite(props.id, 'project')
    }
    return props.createUserFavourite(props.id, 'project')
  }

  const exportRinHandler = async () => {
    const { data } = await ApolloClient.query({
      query: VALIDATE_RIN,
      variables: {
        project_id: props.id
      },
      fetchPolicy: 'network-only'
    })
    const { validateRIN } = data
    if (!validateRIN.success) {
      props.alert(`${validateRIN.message}`, {
        title: '   ',
        className: 'credits-alert'
      })
      return
    }
    window.open(
      `${process.env.REACT_APP_API}/api/rin/export?token=${localStorage.getItem(
        'authToken'
      )}&project=${props.id}`,
      '_blank'
    )
    setTimeout(() => {
      getIntegrationLogs()
    }, 3000)
  }

  const exportPDFHandler = () => {
    setTimeout(() => {
      getIntegrationLogs()
    }, 3000)

    window.open(
      `${process.env.REACT_APP_API}/api/pdf/export?token=${localStorage.getItem(
        'authToken'
      )}&project=${props.id}`,
      '_blank'
    )
  }

  const sessionsInfoHandler = () => {
    props.history.push(`/projects/${props.id}/sessions`)
  }

  const initials =
    props.name &&
    props.name
      .toUpperCase()
      .replace(/[^a-zA-Z- ]/g, '')
      .match(/\b\w/g)
      .join('')
      .substr(0, 4)

  return (
    <>
      <div className='projectHeroContainer'>
        <CreatePagesHeader
          commentsPaneText={`${props.commentCount}`}
          commentsPaneOnClick={loadCommentsHandler}
          actionOnClick={loadCommentsHandler}
        >
          <div
            className={
              'projectHero' + (!props.image ? ' projectHero--no-image' : '')
            }
          >
            <div className='projectHeroContent'>
              <div
                className='projectHeroThumbnail projectCover'
                style={
                  props.image
                    ? {}
                    : {
                      background: useMemo(()=>GetGradientFromId(props.id), [props.id])
                    }
                }
              >
                {props.image ? (
                  <img
                    src={`${process.env.REACT_APP_PROJECT_IMAGE_URL}${
                      props.image
                    }`}
                    alt={props.name}
                  />
                ) : (
                  <span>{initials}</span>
                )}
              </div>
              <div className='projectHeroBody'>
                <header>
                  <Breadcrumb
                    items={[
                      {
                        href: `/projects`,
                        name: 'Projects'
                      },
                      {
                        href: `/projects/${props.id}`,
                        name: props.name
                      }
                    ]}
                  />
                  <h1 className='projectTitle cutoff-project-title'>{props.name}</h1>

                  <div className='artistInfo'>
                    <p className='artistName'>
                      {props.artist ? props.artist.name : ''}
                    </p>
                    <span className='artistNumber'>#{props.number}</span>
                  </div>
                  <p className='projectDescription'>
                    {projectDescriptionCheck(props.description)}
                  </p>
                </header>
                <div className='projectHeroActionContainer'>
                  {/* Collaborator or owner */}
                  <IconButton
                    btnText={props.isProjectOwner ? 'Owner' : 'Collaborator'}
                    containerClass='iconButton--disabled'
                  />

                  {/* Favourite Toggle */}

                  <IconButton
                    icon={StarIcon}
                    clickHandler={favouriteHandler}
                    containerClass={props.favourite ? 'iconButton--active' : ''}
                  />
                  {props.collaboratorType !== 'recording' &&
                  <>
                  {/* Recording Info */}

                  <IconButton
                    icon={RecordingIcon}
                    btnText={props.recordingCount}
                    clickHandler={recordingsInfoHandler}
                  />

                  {/* Sessions Info */}

                  <IconButton
                    icon={SessionIcon}
                    btnText={props.sessionCount}
                    clickHandler={sessionsInfoHandler}
                  />

                  {/* Files Info */}

                  <IconButton
                    icon={FolderIcon}
                    btnText={props.fileCount}
                    clickHandler={filesInfoHandler}
                  />
                  </>
                  }
                  {/* Show Exports & Integrations */}

                  <IconButton
                    icon={ExportIcon}
                    btnText='Export and Integrations'
                    clickHandler={showIntegrationsHandler}
                    containerClass={
                      showIntegrations ? 'iconButton--active' : ''
                    }
                  />

                  {/* Lock Credits */}
                  
                  {props.collaboratorType !== 'recording' &&
                  <IconButton
                    icon={LockIcon}
                    btnText='Lock Credits'
                    clickHandler={lockCreditsHandler}
                    containerClass={props.locked ? 'iconButton--active' : ''}
                  />
                  }
                </div>
              </div>
            </div>
          </div>
        </CreatePagesHeader>
      </div>
      {/* Toggle Integrations */}
      {showIntegrations && (
        <div className='exportIntegrations'>
          <CreatePagesHeader subtitle='Export & Integrations' />
          <div className='sections-container'>
            <div className='content'>
              <div className='projectHeroLinks integrationLinks'>
                {/* Export RIN */}
                <div className='iconButtonContainer'>
                  <IconButton
                    icon={RinIcon}
                    btnText='Export RIN'
                    clickHandler={exportRinHandler}
                  />
                  <p className='export-text'>
                    Last Export:
                    <span className='export-date'>
                      {lastExport.rin === 'Never'
                        ? lastExport.rin
                        : formatter(lastExport.rin)}
                    </span>
                  </p>
                </div>

                {/* Export PDF */}

                <div className='iconButtonContainer'>
                  <IconButton
                    icon={PdfIcon}
                    btnText='Export PDF'
                    clickHandler={exportPDFHandler}
                  />
                  <p className='export-text'>
                    Last Export:
                    <span className='export-date'>
                      {lastExport.pdf === 'Never'
                        ? lastExport.pdf
                        : formatter(lastExport.pdf)}
                    </span>
                  </p>
                </div>

                {/* Sound Exchange   */}

                {props.sxStatus && (
                  <div className='iconButtonContainer'>
                    <IconButton
                      icon={SoundExchangeIcon}
                      btnText='SoundExchange'
                      containerClass='soundExchange'
                    />
                    <p className='export-text'>
                      Last Sent:
                      <span className='export-date'>
                        {lastExport.sx === 'Never'
                          ? lastExport.sx
                          : formatter(lastExport.sx)}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Show Full Description */}

      {showInformation && (
        <Modal
          className='export-details-modal'
          open={showInformation}
          passiveModal={false}
          onRequestClose={setToggle}
          onRequestSubmit={setToggle}
          onSecondarySubmit={false}
          primaryButtonText='Close'
          secondaryButtonText={null}
        >
          <h2>Description</h2>
          <p>{props.description}</p>
        </Modal>
      )}
    </>
  )
})

export default compose(
  memo,
  CreateUserFavourite,
  DeleteUserFavourite,
  WithComments,
  WithAlertModal,
  withRouter
)(ProjectHero)

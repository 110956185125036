import React from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: 'https://49c196bac8064fa092c655254ac6209b@sentry.io/1801207',
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
  release: 'collect-react@' + process.env.REACT_APP_VERSION,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
  autoSessionTracking: true
})

const AppWithSentry = Sentry.withProfiler(App)
ReactDOM.render(<AppWithSentry />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { UPDATE_COLLABORATOR_PERMISSIONS, UPDATE_COLLABORATOR } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import { GetCollaboratorRefreshed } from '../../hocs/queries/GetCollaborator'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import CollaboratorForm from '../../components/CollaboratorForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class CollaboratorEdit extends React.Component {
  render() {
    const name = (
      this.props.collaborator.accepted ?
        this.props.collaborator.user.name : this.props.collaborator.name
    )
    let subTitle = this.props.collaborator.type === 'recording' ? 'Edit Recording Collaborator' : 'Edit Project Collaborator'
    return (
      <>
        <Helmet>
          <title>{name} | {this.props.project.name}</title>
        </Helmet>
        <main className='main'>

          <CreatePagesHeader
            title={this.props.collaborator.name}
            subtitle={subTitle}
          >
            <Breadcrumb items={[
              {
                href: `/projects`,
                name: 'Projects'
              },
              {
                href: `/projects/${this.props.project.id}`,
                name: this.props.project.name
              },
              {
                href: `/projects/${this.props.project.id}#collaborators`,
                name: 'Collaborators'
              },
              {
                href: `/projects/${this.props.project.id}/collaborators/${this.props.collaborator.id}`,
                name: name
              }
            ]} />
          </CreatePagesHeader>

          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={UPDATE_COLLABORATOR}>
                {(updateCollaborator, { loading, error }) => (
                  <Mutation
                    mutation={UPDATE_COLLABORATOR_PERMISSIONS}>
                    {(updateCollaboratorPermissions) => (
                      <CollaboratorForm
                        {...this.props.collaborator}
                        project={{ ...this.props.project }}
                        onSubmit={(state) => {
                          if (state.type === 'normal') {
                            const permissions = Object.keys(state.permissions).map(type => {
                              let level = 'read'
                              if (state.permissions[type] === 1) {
                                level = 'download'
                              } else if (state.permissions[type] === 2) {
                                level = 'full'
                              }

                              return {
                                type,
                                level
                              }
                            })

                            updateCollaborator({
                              variables: {
                                id: this.props.collaborator.id,
                                type: state.type,
                                recordings: []
                              }
                            }).then(() => {
                              updateCollaboratorPermissions({
                                variables: {
                                  collaboratorId: this.props.collaborator.id,
                                  permissions
                                }
                              }).then(() => {
                                this.props.history.push(
                                  `/projects/${this.props.project.id}#collaborators`
                                )
                              })
                            })

                            return
                          }

                          if (state.type === 'recording') {
                            let recordings = [...state.recordings].map(r => {
                              return { id: r.id }
                            })

                            // Update the collab's recordings.
                            updateCollaborator({
                              variables: {
                                id: this.props.collaborator.id,
                                type: state.type,
                                recordings: recordings
                              }
                            }).then(() => {
                              this.props.history.push(
                                `/projects/${this.props.project.id}#collaborators`
                              )
                            })
                            return
                          }

                          this.props.history.push(
                            `/projects/${this.props.project.id}#collaborators`
                          )
                        }}
                        editing={true}
                        loading={loading}
                        errors={error} />
                    )}
                  </Mutation>
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetCollaboratorRefreshed
)(CollaboratorEdit)

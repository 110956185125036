import { Modal } from 'carbon-components-react'
import React from 'react'
import { Query } from 'react-apollo'
import { GET_PUBLIC_FILE_PREVIEW } from '../graphql/queries'

function FilePublicPreviewModal({ open, id, uuid, onRequestClose }) {
  return (
    <div>
      <Modal
        className='modal'
        open={open}
        modalHeading='File Preview'
        passiveModal={true}
        onRequestClose={onRequestClose}
      >
        {open && (
          <Query
            query={GET_PUBLIC_FILE_PREVIEW}
            variables={{
              id: id,
              uuid
            }}
            fetchPolicy='network-only'
          >
            {({ data, loading }) => {
              if (loading) {
                return <p>Loading</p>
              }

              if (['png', 'jpg', 'jpeg'].indexOf(data.getFilePublicPreview.type.toLowerCase()) > -1) {
                return (
                  <>
                    <p>{data.getFilePublicPreview.name}</p>
                    <br />
                    <img
                      src={data.getFilePublicPreview.url}
                      width='100%'
                      alt=''
                    />
                  </>
                )
              } else if (['avi', 'mov', 'mp4', 'ogg', 'wmv', 'webm'].indexOf(data.getFilePublicPreview.type.toLowerCase())) {
                return (
                  <>
                    <video width='100%' height='100%' controls autoPlay={true}>
                      <source src={data.getFilePublicPreview.url} type={`video/${data.getFilePublicPreview.type.toLowerCase()}`}/>
                    </video>
                  </>
                )
              }
            }}
          </Query>
        )}
      </Modal>
    </div>
  )
}

export default FilePublicPreviewModal

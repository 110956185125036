import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'

import { DELETE_PARTY_ADDRESS } from '../graphql/mutations'

import ResponsiveTable from './ResponsiveTable'
import WithAlertModal from '../hocs/WithAlertModal'

const headers = [
  {
    key: 'line1',
    header: 'Address'
  },
  {
    key: 'city',
    header: 'City'
  },
  {
    key: 'district',
    header: 'State/Province/Region'
  },
  {
    key: 'postalCode',
    header: 'Zip'
  },
  {
    key: 'countryName',
    header: 'Country'
  }
]

class PartyAddressTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.getRowActions = this.getRowActions.bind(this)
  }

  getRowActions = (() => {
    const actions = [
      {
        itemText: 'Edit',
        onClick: (row) => {
          const {
            onEdit
          } = this.props
          if (onEdit) {
            onEdit(row.id)
            return
          }
        }
      },
      {
        itemText: 'Delete',
        onClick: row => {
          const {
            partyId,
            alert,
            onDeleteAddress,
            onDelete,
            deletePartyAddress
          } = this.props
          alert('Are you sure you want to delete this address?', {
            type: 'delete',
            buttonText: 'Yes',
            submitCallback: close => {
              if (!partyId) {
                onDeleteAddress(row)
                close()
                return
              }
              deletePartyAddress({
                variables: {
                  id: row.id
                }
              })
                .then(() => {
                  alert('Address has successfully been deleted')
                  onDelete()
                })
                .catch(() => {
                  alert('This party address could not be deleted.')
                })
              close()
            }

          })
        }
      }
    ]

    return () => actions
  })()

  render() {
    const { rowActions } = this.props
    return (
      <ResponsiveTable headers={headers}
        {...this.props}
        rowActions={rowActions ? this.getRowActions() : false}
        emptyText='No Addresses'
      />
    )
  }
}


export default compose(
  withRouter,
  WithAlertModal,
  graphql(DELETE_PARTY_ADDRESS, { name: 'deletePartyAddress' })
)(PartyAddressTable)


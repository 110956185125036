
import React from 'react'
import { TextInput } from 'carbon-components-react'

const SocialInput = ({
  onChange,
  onBlur,
  errors,
  value,
  name,
  id,
  placeholder,
}) => {
  return (
    <div className='input-wrapper'>
      <img className='link-icon' src={require('../assets/images/link-icon.svg')} alt='social' />
      <TextInput
        id={id}
        labelText=""
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value || ''}
        invalid={errors[name] ? true : false}
        invalidText={errors[name]}
      />
    </div>
  )
}

export default SocialInput

import React from 'react'

export default function EmptyTable({
  heading,
  children
}) {
  return (
    <section className='empty-table'>
      {heading && <h3 className='heading'>{heading}</h3>}
      <div className='children'>{children}</div>
    </section>
  )
}

import React from 'react'
import { Modal } from 'carbon-components-react'
import { Mutation } from 'react-apollo'
import { DateTime } from 'luxon'

import { CREATE_SONG } from '../graphql/mutations'
import Validator from '../utils/Validator'
import SongValidator from '../validators/Song'
import SongForm from './SongForm'

const defaultSongType = { value: '19', label: 'Original Musical Work' }

const initialState = {
  songTypeId: defaultSongType.value,
  songTypeName: defaultSongType.label,
  songType: defaultSongType,
  songTypeUserDefinedValue: '',

  iswc: '',
  title: '',
  subtitle: '',

  titleAlt: '',
  subtitleAlt: '',

  createdOn: '',

  description: '',
  lyrics: '',
  notes: '',

  initialErrors: {},
  errors: {},

  userDefinedSongTypes: []
}

class CreateSongModal extends Validator {
    validator = SongValidator

    state = { ...initialState }

    constructor(props) {
      super(props)

      this.handleCreatedOnChange = this.handleCreatedOnChange.bind(this)

      this.state.createdOn = props.createdOn ?
        DateTime.fromSQL(props.createdOn).toFormat('LL/dd/yyyy') : ''
    }


    handleCreatedOnChange(full, formatted) {
      this.setState({
        ...this.state,
        createdOn: formatted,
        errors: {
          createdOn: undefined // eslint-disable-line no-undefined
        }
      })
    }

    render() {
      return (
        <Modal className='modal-scrollable create-song-modal'
          open={this.props.open}
          modalHeading='Create New Song'
          passiveModal
          shouldSubmitOnEnter={true}
          selectorPrimaryFocus='.bx--text-input'
          onRequestClose={this.props.onRequestClose}>
          <div className='form'>
            <Mutation mutation={CREATE_SONG}>
              {(createSong, { loading, error }) => (
                <SongForm
                  onSubmit={(state) => {
                    let data = { ...state }
                    Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                    createSong({
                      variables: {
                        ...data,
                        createdOn: data.createdOn ?
                          DateTime.fromFormat(data.createdOn, 'LL/dd/yyyy').toISODate() : null
                      }
                    }).then(response => {
                      this.props.onSongCreated(response.data.createSong)
                      this.setState({ ...initialState })
                    }).catch(() => {
                      //
                    })
                  }}
                  onRequestClose={this.props.onRequestClose}
                  loading={loading}
                  errors={error}
                  hideUploadImage={true}
                />
              )}
            </Mutation>
          </div>
        </Modal>
      )
    }
}

export default CreateSongModal

import React from 'react'
import { compose } from 'react-apollo'

import GetPlatformImages from '../hocs/queries/GetPlatformImages'
import AppBranding from '../components/AppBranding'
import ValidateDownload from '../hocs/queries/ValidateDownload'

class Share extends React.PureComponent {
  state = {
    requires2FA: false,
    token2FA: ''
  }

  render() {
    const [image] = this.props.platformImages.filter(i => i.location === 'login')

    if (this.props.download && !this.props.download.expired) {
      window.location.href = this.props.download.url
    }

    return (
      <div className='login'>
        <div className='loginBackground'>
          <img src={image.fullPath} alt={image.altText} />
          {image.caption && <div className='loginBackgroundCredit'>{image.caption}</div>}
        </div>
        <div className='loginFormBackground'>
          <div className='loginFormWrapper'>
            <AppBranding />

            {this.props.loading &&
              <p>Fetching download...</p>
            }

            {(!this.props.loading && this.props.download && this.props.download.expired) &&
              <p>We’re sorry this link is expired. Please login to VEVA Collect and download your files again.</p>
            }

            {(!this.props.loading && this.props.download && !this.props.download.expired) &&
              <p>Starting download...</p>
            }

          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  GetPlatformImages,
  ValidateDownload
)(Share)

import React from 'react'
import { Query } from 'react-apollo'
import { GET_SONG } from '../../graphql/queries'

const GetSong = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_SONG}
          variables={{ id: this.props.match.params.songId }}
          fetchPolicy={fetchPolicy}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            if (data.getSong === null) {
              return <h1 style={{color: 'white', textAlign: 'center'}}>404</h1>
            }

            return (
              <WrappedComponent {...this.props}
                song={data.getSong}
                refetchSong={refetch} />
            )
          }}
        </Query>
      )
    }
  }
}

export default GetSong

export const GetSongRefreshed = (WrappedComponent) => {
  return GetSong(WrappedComponent, 'network-only')
}

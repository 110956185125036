import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { GET_PLAYLIST } from '../../graphql/queries'
import { UPDATE_PLAYLIST } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import { GetPlayListRefreshed } from '../../hocs/queries/GetPlaylist'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import PlayListForm from '../../components/PlayListForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class ProjectEdit extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.playlist.title} | Playlists</title>
        </Helmet>
        <main className='main editPlaylist'>
          <CreatePagesHeader
            title={this.props.playlist.title}
            subtitle='Edit Playlist Details'
          >
            <Breadcrumb items={[
              {
                href: `/playlists`,
                name: 'Playlists'
              },
              {
                href: `/playlists/${this.props.playlist.id}`,
                name: this.props.playlist.title
              },
              {
                href: `/playlists/${this.props.playlist.id}/edit`,
                name: 'Edit Playlist Details'
              }
            ]} />
          </CreatePagesHeader>

          <div className='playlistEditContainer'>

            <SectionContainer>
              <Mutation
                mutation={UPDATE_PLAYLIST}
                update={(cache, { data: { updatePlaylist } }) => {
                  cache.writeQuery({
                    query: GET_PLAYLIST,
                    veriables: {
                      id: this.props.playlist.id
                    },
                    data: {
                      getPlaylist: updatePlaylist
                    }
                  })

                  this.props.history.push(`/playlists/${this.props.playlist.id}`)
                }}>
                {(updatePlaylist, { loading, error }) => (
                  <PlayListForm {...this.props.playlist}
                    playlistId={this.props.playlist.id}
                    onUpdatePlayList={updatePlaylist}
                    refetch={this.props.refetchPlaylist}
                    sortDirection={'NONE'}
                    onSubmit={(state) => {
                      updatePlaylist({
                        variables: {
                          ...state,
                          id: this.props.playlist.id
                        }
                      }).catch(() => {
                      //
                      })
                    }}
                    loading={loading}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetPlayListRefreshed
)(ProjectEdit)

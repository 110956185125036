import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    sessionTypeId: Field()
      .required({ message: Errors.Required }),
    startedAt: Field()
      .required({ message: Errors.Required }),
    endedAt: Field(),
    venueId: Field()
      .required({ message: Errors.Required }),
    recordingId: Field(),
    name: Field()
      .string()
      .required({ message: Errors.Required }),
    unionSession: Field(),
    analogSession: Field(),
    dropFrame: Field(),
    venueRoom: Field(),
    bitdepth: Field(),
    samplerate: Field(),
    timecodeType: Field(),
    timecodeFrameRate: Field(),
    description: Field()
  },
  { verbose: true, async: true }
)

import React from 'react'
import {
  Form,
  Button,
  TextInput
} from 'carbon-components-react'

import ApolloClient from '../utils/Apollo'

import { SelectASync } from '../components/Select'

import { GET_COUNTRIES } from '../graphql/queries'

import Validator from '../utils/Validator'
import PartyAddressValidator from '../validators/PartyAddress'

const initialState = {
  line1: '',
  line2: '',
  line3: '',
  city: '',
  district: '',
  postalCode: '',
  countryId: '',
  countryName: '',
  country: {},

  initialErrors: {},
  errors: {},

  isUploading: false,
  uploadComplete: false
}

export default class extends Validator {
  constructor(props) {
    super(props)
    this.state = { ...initialState }
    this.validator = PartyAddressValidator

    this.state.countryId = props.country ? props.country.id : ''
    this.state.countryName = props.country ? props.country.name : ''
    if (this.state.countryId) {
      this.state.country = {
        value: this.state.countryId,
        label: this.state.countryName
      }
    }


    this.state.line1 = props.line1 || ''
    this.state.line2 = props.line2 || ''
    this.state.line3 = props.line3 || ''
    this.state.city = props.city || ''
    this.state.district = props.district || ''
    this.state.postalCode = props.postalCode || ''
  }

  submit() {
    this.props.onSubmit(this.state)
    this.setState({
      line1: '',
      line2: '',
      line3: '',
      city: '',
      district: '',
      postalCode: '',
      countryId: '',
      countryName: '',
      country: {},

      initialErrors: {},
      errors: {},

      isUploading: false,
      uploadComplete: false
    })
  }

  getCountryOptions(input) {
    return new Promise((resolve) => {
      ApolloClient.query({
        query: GET_COUNTRIES,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let countries = [...data.getCountries.map((country) => {
          if (country.name.toLowerCase().indexOf(input.toLowerCase()) === -1) {
            return false
          }

          return {
            value: country.id,
            label: country.name
          }
        })].filter(Boolean)

        resolve(countries)
      })
    })
  }

  handleCountryChange(option) {
    this.setState({
      ...this.state,
      countryId: option.value,
      country: option,
      errors: {
        country: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleCountryBlur() {
    this.handleDirty({
      target: {
        name: 'country',
        value: this.state.countryId
      }
    })
  }

  render() {
    return (
      <div className='form'>
        <Form onSubmit={this.handleSubmit} autoComplete='off'>
          <div className='formRow'>
            <TextInput id='line1'
              name='line1'
              labelText='Address *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.line1 || ''}
              invalid={this.state.errors.line1 ? true : false}
              invalidText={this.state.errors.line1 || ''} />
          </div>

          <div className='formRow'>
            <TextInput id='city'
              name='city'
              labelText='City *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.city || ''}
              invalid={this.state.errors.city ? true : false}
              invalidText={this.state.errors.city || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='district'
              name='district'
              labelText='State/Province/Region *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.district || ''}
              invalid={this.state.errors.district ? true : false}
              invalidText={this.state.errors.district || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='postalCode'
              name='postalCode'
              labelText='Zip *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.postalCode || ''}
              invalid={this.state.errors.postalCode ? true : false}
              invalidText={this.state.errors.postalCode || ''} />
          </div>
          <div className='formRow'>
            <SelectASync
              labelText='Country *'
              id='countryId'
              cacheOptions
              defaultOptions
              loadOptions={this.getCountryOptions}
              onChange={(e) => this.handleCountryChange(e)}
              onBlur={(e) => this.handleCountryBlur(e)}
              defaultValue={this.state.country.value ? this.state.country : null}
              invalid={this.state.errors.countryId ? true : false}
              invalidText={this.state.errors.countryId || ''} />

          </div>
          <div className='formRow alt-footer'>
            <button type='button' className='bx--btn bx--btn--secondary bx--btn--mt' onClick={()=>{
              const closeModal = this.props.close
              closeModal()
            }}>Cancel</button>
            <Button type='submit'>{this.props.loading ? 'Saving...' : 'Create'} </Button>
          </div>
        </Form>
      </div>
    )
  }
}

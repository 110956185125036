import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const CustomDatePicker = (props) => (
  <div className='bx--form-item'>
    <div className='bx--select'>
      <label htmlFor={props.id} className='bx--label'>{props.labelText}</label>
      {props.helperText && helper(props.helperText)}
      <DatePicker
        className={'bx--text-input bx--text__input' + (props.invalid ? '  bx--text__input--invalid' : '')}
        selected={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        showTimeSelect={props.showTimeSelect}
        dateFormat='Pp'
        maxDate={props.maxDate}
        placeholderText='mm/dd/yyyy'
      />
      {props.invalid && error(props.id, props.invalidText)}
    </div>
  </div>
)

const error = (id, text) => (
  <div className={`bx--form-requirement`} id={`${id}-error-msg`}>
    {text}
  </div>
)

const helper = text => (
  <div className={`bx--form__helper-text`}>{text}</div>
)

export default CustomDatePicker

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Query } from 'react-apollo'

import GetUser from '../../hocs/queries/GetUser'
import GetSong from '../../hocs/queries/GetSong'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import { GET_PROJECT_FOLDER } from '../../graphql/queries'
import { NetworkStatus } from '../../utils/Apollo'
import FileTable from '../../components/FileTable'
import { Route } from 'react-router-dom'
import WithUploads from '../../hocs/WithUploads'
import WithUploadModal from '../../hocs/WithUploadModal'
import { getUserFavouritesByType } from '../../utils/selectors'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import * as permissions from '../../utils/permissions'
import Hero from '../../components/Hero'
import { Icon } from 'carbon-components-react'
import { iconInfoGlyph } from 'carbon-icons'
import { filterPermissions } from '../../utils/permissions'
import folderActive from '../../assets/images/folderActive.svg'
import uploadIcon from '../../assets/upload-arrow.svg'
import { WithMoveFilesFrom } from '../../hocs/MoveFiles'

class SongFiles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
   getSearchTerm=(value)=>{
     this.setState({
       searchTerm: value
     })
   }
   permissionsApplied = (row = this.props.song)=>{
     const { recordings } = row
     const fullAccess = {
       create: true,
       update: true,
       read: true,
       delete: true,
       download: true,
       comments: true

     }
     if (recordings.length === 0) {
       return fullAccess
     }
     const projectIds = [...new Set(recordings.map(item => item.projectId))]
     if (this.props.song.userId === this.props.user.id) {
       return fullAccess
     }
     const AllPermissions = []
     projectIds.forEach(async item=>{
       const permission = permissions.can(item, 'song')
       if (Object.keys(permission).length > 0) {
         AllPermissions.push(permission)
       }
     })
     let getPermission = {}
     AllPermissions.forEach(item =>{
       if (Object.keys(getPermission).length === 0) {
         getPermission = { ...item }
       } else {
         if (getPermission.create === false) {
           getPermission.create = item.create
         }
         if (getPermission.update === false) {
           getPermission.update = item.update
         }
         if (getPermission.read === false) {
           getPermission.read = item.read
         }
         if (getPermission.download === false) {
           getPermission.download = item.download
         }
         if (getPermission.delete === false) {
           getPermission.delete = item.delete
         }
         if (getPermission.comments === false) {
           getPermission.comments = item.comments
         }
       }
     })
     return getPermission
   }
  getTags=(permissions)=>{
    const tags = []
    const permissionsKyes = Object.keys(permissions)
    permissionsKyes.forEach(key =>{
      if (permissions[key] === true) {
        tags.push({
          tagName: key.charAt(0).toUpperCase() + key.slice(1)
        })
      }
    })
    return tags
  }
  getTitleWithIcon =()=>{
    return <>Song Files<Icon icon={iconInfoGlyph} description='' className='info-icon'/></>
  }
  onMoveFilesFrom =(refetch)=>{
    const { song } = this.props
    const breadCrumb = [{
      href: `/songs`,
      name: 'Songs'
    },
    {
      href: `#`,
      name: `${song.title}`
    }
    ]
    this.props.moveFilesFrom.open(null,
      this.props.song.folderId,
      breadCrumb,
      refetch
    )
  }

  onClickUpload =() =>

    this.props.uploadModal.open(
      this.props.song.title,
      '',
      null,
      this.props.song.folderId
    )


   filesDropdownList =(refetch)=> [
     { name: 'Upload', icon: uploadIcon, click: this.onClickUpload },
     { name: 'Files', icon: folderActive, click: ()=> this.onMoveFilesFrom(refetch) }
   ];

   render() {
     const favourites = getUserFavouritesByType(this.props.user)
     const isSongOwner = this.props.song.userId === this.props.user.id
     const can = this.permissionsApplied()

     return (
      <>
        <Helmet>
          <title>{this.props.song.title} | Files</title>
        </Helmet>
        <main className='main songs-file-page'>

          {this.props.song.folderId && this.props.location.pathname === this.props.match.url && (
            <Query
              query={GET_PROJECT_FOLDER}
              variables={{
                songId: this.props.song.id,
                folderId: this.props.match.params.folderId ? this.props.match.params.folderId : this.props.song.folderId
              }}
              fetchPolicy='cache-and-network'
              pollInterval={this.props.uploads.state.inProgressCount > 0 ? 0 : 10000}
            >
              {({ loading, error, data, refetch, networkStatus }) => {
                const actuallyLoading =
                loading &&
                networkStatus !== NetworkStatus.poll &&
                networkStatus !== NetworkStatus.refetch

                if (error) {
                  return `Error! ${error.message}`
                }
                let files = []
                let folders = []

                if (!actuallyLoading) {
                  files = data.getFolder.files
                  folders = data.getFolder.folders
                }

                return (
                <>
                          <Hero
                            subtitle={this.props.song.title + ' Files'}
                            showSearch={true}
                            getSearchTerm={this.getSearchTerm}
                            btnText={can.create ? 'Upload' : false}
                            withDivider
                            btnOnClick={() =>

                              this.props.uploadModal.open(
                                this.props.song.title,
                                '',
                                null,
                                this.props.song.folderId,
                                '',
                                refetch
                              )
                            }

                          >
                            <Breadcrumb items={
                              (window.location.hash === '#song' ?
                                [
                                  {
                                    href: `/files`,
                                    name: 'Files'
                                  },
                                  {
                                    href: `/songs`,
                                    name: 'Songs'
                                  },
                                  {
                                    href: `/songs/${this.props.song.id}`,
                                    name: this.props.song.title
                                  },
                                  {
                                    href: `/songs/${this.props.song.id}/files`,
                                    name: 'Song Files'
                                  }
                                ] :
                                [
                                  {
                                    href: `/songs`,
                                    name: 'Songs'
                                  },
                                  {
                                    href: `/songs/${this.props.song.id}`,
                                    name: this.props.song.title
                                  },
                                  {
                                    href: `/songs/${this.props.song.id}/files`,
                                    name: 'Song Files'
                                  }
                                ]
                              )

                            } />
                          </Hero>
          <CreatePagesHeader
            subtitle={this.getTitleWithIcon()}
            withDivider={false}
            tags={!isSongOwner ? this.getTags(filterPermissions(this.permissionsApplied())) : []}
          />
                    <SectionContainer>

                      <div className='sections-container project-files'>
                        <SectionContainer>
                          <FileTable
                            rows={[...folders, ...files]}
                            showSearch={false}
                            searchTerm={this.state.searchTerm}
                            projectId={null}
                            songId={this.props.song.id}
                            folderId={this.props.song.folderId}
                            fileFavourites={favourites.file || []}
                            folderFavourites={favourites.folder || []}
                            showSkeleton={actuallyLoading}
                            refetch={refetch}
                            favourite
                            batchActions
                            actions
                            rowActions
                            pagination
                            canCreate={can.create}
                            canDownload={can.download}
                            canDelete={can.delete}
                            comments={false}
                            perPage={100}
                          />
                        </SectionContainer>
                      </div>
                    </SectionContainer>

                </>
                )
              }}
            </Query>
          )}
          <Route
            path={`${this.props.match.url}/:folderId`}
            render={props => (
              <FileList
                {...props}
                project={this.props.song}
                uploadModal={this.props.uploadModal}
                uploads={this.props.uploads}
                setProjectIdAndFolderId={this.props.setProjectIdAndFolderId}
                fileFavourites={this.props.fileFavourites}
                folderFavourites={this.props.folderFavourites}
                songPermissions={can}
              />
            )}
          />

        </main>
      </>
     )
   }
}

export default compose(
  GetUser,
  GetSong,
  WithUploads,
  WithUploadModal,
  WithMoveFilesFrom

)(SongFiles)

class FileList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
   getSearchTerm=(value)=>{
     this.setState({
       searchTerm: value
     })
   }
   render() {
     const { songPermissions } = this.props
     return (
      <>
        {this.props.song.folderId && this.props.location.pathname === this.props.match.url && (
          <Query
            query={GET_PROJECT_FOLDER}
            variables={{
              songId: this.props.song.id,
              folderId: this.props.song.folderId
            }}
            fetchPolicy='cache-and-network'
            pollInterval={this.props.uploads.state.inProgressCount > 0 ? 0 : 10000}
          >
            {({ loading, error, data, refetch, networkStatus }) => {
              const actuallyLoading =
                loading &&
                networkStatus !== NetworkStatus.poll &&
                networkStatus !== NetworkStatus.refetch

              if (error) {
                return `Error! ${error.message}`
              }
              let files = []
              let folders = []

              if (!actuallyLoading) {
                files = data.getFolder.files
                folders = data.getFolder.folders
              }

              return (
                <>
             <CreatePagesHeader
               subtitle='Files'
               linkText='View all Files'
               linkUrl='/files'
               showSearch={true}
               getSearchTerm={this.getSearchTerm}
               btnText='Upload'
               btnOnClick={() =>

                 this.props.uploadModal.open(
                   this.props.song.title,
                   '',
                   null,
                   this.props.song.folderId
                 )
               }

             />
              <div className='sections-container'>
                <SectionContainer>
                  <FileTable
                    rows={[...folders, ...files]}
                    showSearch={false}
                    searchTerm={this.state.searchTerm}
                    projectId={null}
                    folderId={this.props.song.folderId}
                    fileFavourites={this.props.fileFavourites}
                    folderFavourites={this.props.folderFavourites}
                    showSkeleton={actuallyLoading}
                    refetch={refetch}
                    favourite
                    batchActions
                    actions
                    rowActions
                    pagination
                    canCreate={songPermissions.create}
                    canDownload={songPermissions.download}
                    canDelete={songPermissions.delete}
                    comments={false}
                  />
                </SectionContainer>
              </div>
                </>
              )
            }}
          </Query>
        )}
        <Route
          path={`${this.props.match.url}/:folderId`}
          render={props => (
            <FileList
              {...props}
              project={this.props.song}
              uploadModal={this.props.uploadModal}
              uploads={this.props.uploads}
              setProjectIdAndFolderId={this.props.setProjectIdAndFolderId}
              fileFavourites={this.props.fileFavourites}
              folderFavourites={this.props.folderFavourites}
            />
          )}
        />
      </>
     )
   }
}

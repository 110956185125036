import React from 'react'
import { Modal } from 'carbon-components-react'
import DownloadFiles from '../hocs/mutations/DownloadFiles'

const WithDownloadModal = (WrappedComponent) => {
  return DownloadFiles(class extends React.PureComponent {
    state = {
      open: false,
      error: false,
      download: false
    }

    constructor(props) {
      super(props)

      this.doClose = this.doClose.bind(this)
      this.handleDownloads = this.handleDownloads.bind(this)
    }

    doClose() {
      this.setState({
        ...this.state,
        open: false,
        error: false,
        download: false
      })
    }

    handleDownloads(files) {
      this.props.downloadFiles({
        variables: {
          files
        }
      }).then(({ data }) => {
        if (data.downloadFiles.url) {
          window.open(data.downloadFiles.url)
          return
        }

        this.setState({
          ...this.state,
          open: true,
          error: !data.downloadFiles.success
        })
      })
    }

    render() {
      return (
        <>
          <WrappedComponent {...this.props}
            downloadFiles={this.handleDownloads} />
          <Modal className='modal'
            open={this.state.open}
            modalHeading='Download Files'
            passiveModal={true}
            onRequestClose={this.doClose}
          >
            {this.state.open && !this.state.error &&
              <p>You will receive an email when your files are ready for download!</p>
            }
            {this.state.open && this.state.error &&
              <p>There was a problem with this file download. Please try again later.</p>
            }
          </Modal>
        </>
      )
    }
  })
}

export default WithDownloadModal

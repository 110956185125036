import { graphql } from 'react-apollo'
import { EXPIRE_SHARE } from '../../graphql/mutations/share'

export default graphql(EXPIRE_SHARE, {
  name: 'expireShare',
  props: data => {
    return {
      expireShare: (uuid) =>
        data.expireShare({
          variables: {
            uuid
          }
        })
    }
  }
})

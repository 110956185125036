import React, { createRef } from 'react'
import { Form, Button, TextInput } from 'carbon-components-react'
import ApolloClient from '../utils/Apollo'
import {
  GET_PROJECT_RECORDINGS,
  SEARCH_USER_EMAIL
} from '../graphql/queries'
import Slider from './Slider'
import Validator from '../utils/Validator'
import CollaboratorValidator from '../validators/Collaborator'
import WithAlertModal from '../hocs/WithAlertModal'
import { compose } from 'react-apollo'
import DiscardModal from '../components/modals/DiscardModal'
import { withRouter } from 'react-router-dom'
import { SelectStandard } from '../components/Select'


const initialState = {
  name: '',
  email: '',
  recordingOptions: [],
  recordings: [],
  permissions: {
    collaborator: 0,
    file: 0,
    recording: 0,
    session: 0,
    song: 0
  },
  type: 'normal',
  selectedType: { value: 'normal', label: 'Project' },
  initialErrors: {},
  errors: {},
  isUploading: false,
  uploadComplete: false,
  discardModal: false,
  multiValue: []
}

let initialCalcState = {}

class CollaboratorForm extends Validator {
  state = { ...initialState }
  constructor(props) {
    super(props)
    this.suggestionsListRef = createRef()
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handlePermissionChange = this.handlePermissionChange.bind(this)

    this.handleRecordingsChange = this.handleRecordingsChange.bind(this)

    this.validator = CollaboratorValidator

    this.state.type = props.type || 'normal'
    this.state.name = props.name || ''
    this.state.email = props.email || ''

    if (this.state.type === 'recording') {
      this.state.selectedType = { value: 'recording', label: 'Recording' }
    }
    if (props.permissions) {
      const permissions = [...props.permissions]
      this.state.permissions = {}
      permissions.forEach(permission => {
        let level = 0
        if (permission.level === 'download') {
          level = 1
        } else if (permission.level === 'create') {
          level = 2
        }
        if (this.state.permissions[permission.type] > level) {
          return
        }
        this.state.permissions[permission.type] = level
      })
    }

    this.state.recordings = []
    if (props.recordings) {
      props.recordings.forEach(recording => {
        this.state.recordings.push({
          value: recording.id,
          label: recording.name,
          id: recording.id
        })
      })
      this.state.multiValue = this.state.recordings
    }


    // if the route is from specific recording
    if (props.recordingsSelected) {
      props.recordingsSelected.forEach(recording => {
        this.state.recordings.push({
          value: recording.id,
          label: recording.name
        })
      })
      this.state.multiValue = this.state.recordings
    }


    this.openDiscardModal = this.openDiscardModal.bind(this)
    this.closeDiscardModal = this.closeDiscardModal.bind(this)
    this.discardChanges = this.discardChanges.bind(this)
    this.transformPermissions = this.transformPermissions.bind(this)

    this.saveInitialState = this.saveInitialState.bind(this)
    this.saveInitialState()
  }

  saveInitialState() {
    initialCalcState = this.state
  }
  componentDidMount() {
    this.getRecordingOptions().then(recordings => {
      this.setState({
        recordingOptions: [...recordings]
      })
      initialCalcState.recordingOptions = [...recordings]
    })
  }
  getRecordingOptions = () =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_PROJECT_RECORDINGS,
        variables: {
          projectId: this.props.project.id
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let recordings = []
        data.getRecordings.data.forEach(recording => {
          recordings.push({
            value: recording.id,
            label: `${recording.name} ${recording.version ? ` - ${recording.version}` : ''} `
          })
        })
        resolve(recordings)
      })
    })
 handleDirtyEmail = async (e) => {
   const { name, value } = e.target

   let isValid
   try {
     isValid = this.validator[name].validate(value, {
       verbose: true,
       values: this.state
     })
   } catch {
     isValid = 'Invalid'
   }

   if (isValid !== true) {
     this.setState({
       ...this.state,
       errors: {
         ...this.state.errors,
         [name]: isValid
       }
     })
   } else {
     this.setState({
       ...this.state,
       errors: {
         ...this.state.errors,
         [name]: undefined // eslint-disable-line no-undefined
       }
     })
   }
   if (typeof isValid === 'boolean' && isValid) {
     const { email } = this.state
     const { alert } = this.props
     const { data } = await ApolloClient.query({
       query: SEARCH_USER_EMAIL,
       variables: {
         email: email
       },
       fetchPolicy: 'network-only'
     })
     const { searchUserEmail } = data
     const { success, message } = searchUserEmail
     if (!success) {
       alert(`${message}`)
       return
     }
   }
 }

 async submit() {
   if (this.state.type === 'recording' && this.state.recordings.length === 0) {
     this.setState({
       errors: {
         ...this.state.errors,
         recordings: 'You must select at least one recording'
       }
     })
     return
   }


   const { email } = this.state
   const { alert } = this.props
   const { data } = await ApolloClient.query({
     query: SEARCH_USER_EMAIL,
     variables: {
       email: email
     },
     fetchPolicy: 'network-only'
   })
   const { searchUserEmail } = data
   const { success, message } = searchUserEmail
   if (!success) {
     alert(`${message}`)
     return
   }
   this.props.onSubmit(this.state)
 }
 handlePermissionChange(name) {
   return values => {
     this.setState({
       ...this.state,
       permissions: {
         ...this.state.permissions,
         [name]: values[0]
       }
     })
   }
 }
 handleTypeChange(selected) {
   this.setState({
     ...this.state,
     type: selected.value,
     selectedType: selected,
     errors: {
       ...this.state.errors,
       type: undefined // eslint-disable-line no-undefined
     }
   })
 }
 handleRecordingsChange(e) {
   // first check if recording route is removed, if removed then return - no state update
   let present = true
   if (this.props.recordingsSelected && this.props.recordingsSelected.length) {
     if (e.filter(entry => entry.value === this.props.recordingsSelected[0].id).length < 1) {
       present = false
     }
   }
   if (!present) {
     return null
   }
   this.setState({
     ...this.state,
     recordings: e.map((entry)=>{
       return { id: entry.value }
     }),
     multiValue: e,
     errors: {
       ...this.state.errors,
       recordings: undefined // eslint-disable-line no-undefined
     }
   })
   return null
 }

 transformPermissions() {
   const calcPermission = {}
   if (this.props.permissions) {
     const permissions = [...this.props.permissions]
     permissions.forEach(permission => {
       let level = 0
       if (permission.level === 'download') {
         level = 1
       } else if (permission.level === 'create') {
         level = 2
       }
       if (calcPermission[permission.type] > level) {
         return
       }
       calcPermission[permission.type] = level
     })
   }
   return calcPermission
 }


 openDiscardModal(e) {
   e.preventDefault()
   let change = false
   Object.entries(initialCalcState).forEach(([key]) => {
     if (JSON.stringify(initialCalcState[key]) !== JSON.stringify(this.state[key])) {
       change = true
     }
   })

   if (change) {
     this.setState({
       discardModal: true
     })
   } else {
     this.props.history.goBack()
   }
 }

 closeDiscardModal(e) {
   e.preventDefault()
   this.setState({
     discardModal: false
   })
 }

 discardChanges() {
   this.setState(initialCalcState)
 }

 render() {
   const {
     email,
     name,
     errors,
     type,
     permissions,
     recordings,
     recordingOptions
   } = this.state
   const { accepted, editing } = this.props
   return (<>

     <div className='form collaboratorForm'>
       <Form onSubmit={this.handleSubmit} autoComplete='off'>

         <div className='formRow formCol'>
           <div className={`input-wrapper formColChild nameField`}>
             <TextInput
               id='name'
               name='name'
               labelText='Name *'
               disabled={this.props.editing}
               onChange={this.handleChange}
               onBlur={this.handleDirty}
               value={name || ''}
               invalid={errors.name ? true : false}
               invalidText={errors.name || ''}
             />
           </div>
         </div>
         {
           editing && <div className='formRow'><div className='input-wrapper formColChild inviteAccepted'>
             <TextInput
               id='invite'
               name='invite'
               labelText='Invite Accepted'
               disabled
               value={accepted ? 'Yes' : 'No'}
             />
           </div>
           </div>
         }

         <div className='formRow'>
           <div className='input-wrapper emailField'>
             <TextInput
               id='email'
               name='email'
               labelText='Email *'
               disabled={this.props.editing}
               onChange={this.handleChange}
               onBlur={this.handleDirtyEmail}
               value={email || ''}
               invalid={errors.email}
               invalidText={errors.email || ''}
             />

           </div>
         </div>
         {type === 'recording' && (
          <>
           <div className='formRow'>
             <SelectStandard
               isMulti
               name='recordings'
               placeholder='Select from list ...'
               value={this.state.multiValue}
               options={recordingOptions.length ? recordingOptions : []}
               onChange={this.handleRecordingsChange}
               key={JSON.stringify(recordings)}
               id='recordings'
               cacheOptions={false}
               defaultOptions
               invalid={errors.recordings ? true : false}
               invalidText={errors.recordings || ''}
               labelText='Assign Collaborator to Recording(s)*'
             />
           </div>
           <div>
             <p>
             Collaborator can Add Recording and Session Level Create and Edit Sessions associated with Recordings, Create and Edit Recording and Session Credits  and Upload and Manage Recording Files for the selected Recordings.
             </p>
           </div>
           </>
         )}
         {type !== 'recording' && (
           <div className='sliderContainer'>
             <div className='formRow'>
               <Slider
                 onChange={this.handlePermissionChange('collaborator')}
                 labelText='Collaborators'
                 value={permissions.collaborator}
                 maxValue={2}
                 step={2}
                 tickNames={['Read Only', '', 'Full Access']}
                 description= {['Collaborator can  List other Collaborators on a Shared Project',
                   '',
                   'Collaborator can Add, Edit or Remove other Collaborators to this Project as well as manage other Collaborators’ permissions. (Cannot Edit or Remove the Project Owners’ permissions)']}
               />
             </div>
             <div className='formRow'>
               <Slider
                 onChange={this.handlePermissionChange('file')}
                 labelText='Project Files & Folders'
                 value={permissions.file}
                 maxValue={2}
                 tickNames={['Read Only', 'Download', 'Full Access']}
                 description= {['Collaborator can List & Comment on files/folders in this Project and its associated Recordings.',
                   'Collaborator can List, Preview, Download, and Comment on files/folders in this Project and its associated Recordings.',
                   'Collaborator can Upload, List, Preview, Download, Share, Move, Duplicate, Rename, Delete, Add To Playlist, and Comment on files/folders in this Project and its associated Recordings.']} />
             </div>
             <div className='formRow'>
               <Slider
                 onChange={this.handlePermissionChange('recording')}
                 labelText='Project Recordings'
                 value={permissions.recording}
                 maxValue={2}
                 step={2}
                 tickNames={['Read Only', '', 'Full Access']}
                 description= {['Collaborator can Read Recording level details & metadata associated with this Project.',
                   '',
                   'Collaborator can Create new Recordings as well as Edit Recording level details & metadata associated with this Project.']} />
             </div>
             <div className='formRow'>
               <Slider
                 onChange={this.handlePermissionChange('song')}
                 labelText='Songs in Project Recordings'
                 value={permissions.song}
                 maxValue={2}
                 step={2}
                 tickNames={['Read Only', '', 'Full Access']}
                 description= {['Collaborator can Read Song level metadata associated with all Recordings in this Project. Collaborators can List & Comment on files/folders in any Song associated with Recordings in this Project. ',
                   '',
                   'Collaborator can Create and Edit Song level metadata associated with Recordings in this Project. New Songs created associated with Recordings in a shared Project will be controlled by the owner account. Collaborator can also Upload, List, Preview, Download, Share Move, Duplicate, Rename, Delete, Add To Playlist, and Comment on files/folders in any Song associated with Recordings in this Project.']} />
             </div>
             <div className='formRow'>
               <Slider
                 onChange={this.handlePermissionChange('session')}
                 labelText='Project Credits & Sessions'
                 value={permissions.session}
                 maxValue={2}
                 step={2}
                 tickNames={['Read Only', '', 'Full Access']}
                 description= {['Collaborator can Read Project, Recording and Session crediting metadata',
                   '',
                   'Collaborator can Create and Edit Project, Recording, and Session credits as well as Create & Edit Sessions associated with Recordings in this Project.']} />
             </div>
           </div>
         )}
         <div className='formRow action-container'>
           <button className='bx--btn bx--btn--secondary' onClick={this.openDiscardModal}>
          Cancel
           </button>
           <Button type='submit'>{this.props.loading ? 'Saving...' : 'Save'}</Button>
         </div>
       </Form>
     </div>
     {
       this.state.discardModal && <DiscardModal open={this.state.discardModal} modalHeading='Discard Changes?' onRequestClose={this.closeDiscardModal} handleSubmit={this.discardChanges} />
     }
     </>
   )
 }
}
export default compose(WithAlertModal, withRouter)(CollaboratorForm)

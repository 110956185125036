import React from 'react'
import { Form, Button, TextInput } from 'carbon-components-react'

import Validator from '../utils/Validator'
import VerificationValidator from '../validators/Verification'

const initialState = {
  email: '',

  initialErrors: {},
  errors: {}
}

export default class extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.validator = VerificationValidator

    if (props.errors) {
      this.state.errors = {
        email: true
      }
    }
  }

  submit() {
    this.props.onSubmit(this.state)
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit} autoComplete='off'>
        <div className='formRow'>
          <TextInput
            id='email'
            name='email'
            labelText='Email'
            onChange={this.handleChange}
            onBlur={this.handleDirty}
            value={this.state.email || ''}
            invalid={this.props.errors || this.state.errors.email ? true : false}
            invalidText={
              this.state.errors.email ||
              'Invalid or expired E-Mail address has been provided, please try again.'
            }
          />
        </div>
        <div className='formRow'>
          <Button type='submit' className='loginSubmit'>
            {this.props.loading ? 'Verifying...' : 'Verify'}
          </Button>
        </div>
      </Form>
    )
  }
}

import React from 'react'
import { compose } from 'react-apollo'

import AppBranding from '../components/AppBranding'
import GetUserCustomDownloadPage from '../hocs/queries/GetCustomDownloadPage'
import GetPlatformImages from '../hocs/queries/GetPlatformImages'
import defaultDownloadImage from '../assets/images/veva-collect-default-download-image.jpg'

const DownloadPreview = ({ customImages, platformImages }) => {
  const [image] = platformImages.filter(i => i.location === 'login')

  return (
    <div className='login'>
      <div className='loginBackground'>
        {customImages.image ?
          <img src={customImages.image} alt='customization' /> :
            <>
            <img src={defaultDownloadImage} alt={image.altText} />
            {image.caption && <div className='loginBackgroundCredit'>{image.caption}</div>}
            </>
        }
      </div>
      <div className='loginFormBackground'>
        <div className='loginFormWrapper'>
          <AppBranding />
        </div>
      </div>
    </div>
  )
}

export default compose(
  GetUserCustomDownloadPage,
  GetPlatformImages
)(DownloadPreview)

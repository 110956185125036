import React, { useRef, useCallback, useState, useEffect } from 'react'
import { WaveSurfer, WaveForm } from 'wavesurfer-react'
import { Colors } from '../utils/constants'
import { Tooltip } from 'carbon-components-react'

const PublicPlaylistWaveform = props => {
  const { name, type, prettySize, url, filePlayingIdHandler, fileSelectedHandler, files, playing, getSongIndex, fileSelectedId, setFile, date } = props
  const { $accent, $white } = Colors

  const [loadingCount, setLoadingCount] = useState(0)
  const wavesurferRef = useRef()

  const onWSMount = useCallback(waveSurfer => {
    if (waveSurfer.markers) {
      waveSurfer.clearMarkers()
    }
    wavesurferRef.current = waveSurfer
    if (wavesurferRef.current) {
      wavesurferRef.current.load(url)

      wavesurferRef.current.on('finish', () => {
        if (getSongIndex(fileSelectedId) !== files.length - 1) {
          filePlayingIdHandler({ id: files[getSongIndex(fileSelectedId) + 1].id })
          fileSelectedHandler({ id: files[getSongIndex(fileSelectedId) + 1].id })
        } else {
          filePlayingIdHandler({ id: null })
          fileSelectedHandler({ id: null })
          setFile(null)
        }
      })

      wavesurferRef.current.on('loading', data => {
        if (data === 100) {
          wavesurferRef.current.on('ready', () => {
            if (wavesurferRef.current) {
              wavesurferRef.current.play()
              fileSelectedHandler({ id: files[getSongIndex(fileSelectedId)].id })
              filePlayingIdHandler({ id: files[getSongIndex(fileSelectedId)].id })
            }
          })
        }

        setLoadingCount(data)
      })

      wavesurferRef.current.on('pause', () => {
        filePlayingIdHandler({ id: null })
      })

      wavesurferRef.current.on('error', () => {
        filePlayingIdHandler({ id: null })
      })

      if (window) {
        window.surferidze = wavesurferRef.current
      }
    }
  }, [])

  const onPlay = useCallback(() => {
    wavesurferRef.current.playPause()
  }, [])
  const onSkipForward = useCallback(() => {
    wavesurferRef.current.skipForward(10)
  }, [])
  const onSkipBackward = useCallback(() => {
    wavesurferRef.current.skipBackward(10)
  }, [])
  const pauseAndPlayIconToggle = () => {
    if (wavesurferRef.current.isPlaying()) {
      fileSelectedHandler({ id: files[getSongIndex(fileSelectedId)].id })
      filePlayingIdHandler({ id: files[getSongIndex(fileSelectedId)].id })
    } else {
      filePlayingIdHandler({ id: null })
    }
  }

  // const getPlayPauseIcon = useCallback(() => {
  //   return playing ? 'pause-icon' : 'play-icon'
  // }, [])

  useEffect(() => {
    return () => {
      wavesurferRef.current.destroy()
    }
  }, [])

  if (!playing && wavesurferRef.current) {
    wavesurferRef.current.pause()
  }

  if (playing && wavesurferRef.current && (fileSelectedId !== files[files.length - 1].id || wavesurferRef.current.getCurrentTime() < wavesurferRef.current.getDuration())) {
    wavesurferRef.current.play()
  }

  return (
    <div className='waveform-wrapper'>
      {loadingCount !== 100 && (
        <div className='preloader'>
          <div className='preloader-inner'>{loadingCount}%</div>
        </div>
      )}

      <WaveSurfer onMount={onWSMount}>
        <WaveForm
          height='130'
          barWidth='2'
          barHeight='1.3'
          cursorWidth='2'
          responsive={true}
          cursorColor={`${$white}`}
          barGap='4'
          hideScrollbar='true'
          backend='MediaElement'
          progressColor={`${$accent}`}
          waveColor={`${$accent}`}
          id='waveform'
        />
      </WaveSurfer>
      {loadingCount === 100 && (
        <>
          <div className='actions-container'>
            <div className='audio-controls-wrapper'>
              <span className='icon rewind-icon' onClick={onSkipBackward} />
              <span
                className={`icon ${playing ? 'pause-icon' : 'play-icon'}`}
                onClick={() => {
                  onPlay()
                  pauseAndPlayIconToggle()
                }}
              />
              <span className='icon forward-icon' onClick={onSkipForward} />
            </div>
            <div className='media-details-wrapper'>
              <div className='media-details'>
                {name && name.length > 16 ? (
                  <Tooltip
                    className='playlist-tooltip'
                    direction='top'
                    tabIndex={0}
                    showIcon={false}
                    triggerText={<span className='media-name trigger-text'>{name}</span>}
                  >
                    <p className='media-name'>{name}</p>
                  </Tooltip>
                ) : (
                  <span className='media-name'>{name}</span>
                )}
                <span className='media-extension'>
                  {type && type.toUpperCase()}
                </span>
                <span className='media-size'>{prettySize}</span>
              </div>
              <span className='date'>{date}</span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PublicPlaylistWaveform

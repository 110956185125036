import React from 'react'
import { Query } from 'react-apollo'
import { GET_USER_CUSTOM_DOWNLOAD_PAGE } from '../../graphql/queries'

const GetUserCustomDownloadPage = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_USER_CUSTOM_DOWNLOAD_PAGE} fetchPolicy={fetchPolicy}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            return <WrappedComponent {...this.props} refetchCustomImages={refetch} customImages={data.getUserCustomDownloadPage} />
          }}
        </Query>
      )
    }
  }
}

export default GetUserCustomDownloadPage
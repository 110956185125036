import {
  Button,
  OverflowMenu,
  OverflowMenuItem
} from 'carbon-components-react'
import React from 'react'
import { Link } from 'react-router-dom'
import SearchField from './SearchField'
import DropdownMenu from './Dropdowns/DropdownMenu'

export default class Hero extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
  render() {
    const {
      btnOnClick,
      btnText,
      alt,
      actionText,
      src,
      actionOnClick,
      links,
      title,
      showSearch,
      getSearchTerm,
      subtitle,
      children,
      buttons,
      menuItems,
      commentsPaneText,
      commentsPaneOnClick,
      dropDownMenuList
    } = this.props
    const buttonType =
      btnText === 'Add' || btnText === 'Upload' || btnText === 'Edit'
    const secondaryIcon =
      btnText === 'Edit'
        ? require('../assets/edit.svg')
        : require('../assets/images/addActive.svg')
    const icon =
      btnText === 'Upload'
        ? require('../assets/upload-arrow.svg')
        : secondaryIcon
    const isUploadBtn = btnText === 'Upload'

    const commentsIconPath = 'M4 12H16V10H4V12ZM4 9H16V7H4V9ZM4 6H16V4H4V6ZM20 20L16 16H2C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H18C18.55 0 19.021 0.195667 19.413 0.587C19.8043 0.979 20 1.45 20 2V20ZM2 2V14H16.825L18 15.175V2H2Z' // eslint-disable-line max-len

    return (
      <div>
        <div className='heroHeaderContainer'>


          <div className='heroHeader'>
            {children ? <div className='sections-container'>{children}</div> : null}
            <img
              src={require('../images/' + (src || 'ph.hero.projects.jpg'))}
              alt={alt}
            />
            <div className='headerSectionsContainer'>
              <div className='heroHeaderContent'>
                <div className='headerTitleWrapper'>
                  {title ? <h1 className='heroTitle'>{title}</h1> : null}

                  {subtitle ? <p className='heroSubtitle'>{subtitle}</p> : null}
                  {btnText && btnOnClick && buttonType ? (
                    <button
                      type='button'
                      className='heroAddButton'
                      onClick={btnOnClick}
                    >
                      <img
                        src={icon}
                        alt='add'
                        className={isUploadBtn ? 'upload-btn' : ''}
                      />
                    </button>
                  ) : null}
                  {dropDownMenuList ? (
                    <DropdownMenu
                      dropdownList={dropDownMenuList}
                    />
                  ) : null}
                  {(buttons || []).map(button => (
                    <button
                      type='button'
                      onClick={button.onClick}
                      className='hero-button'
                    >
                      {button.text}
                    </button>
                  ))}
                  {links && (
                    <nav className='heroNav'>
                      {links.map((link, i) => {
                        if (
                          link.href &&
                      link.href.substring(0, 1) !== '#' &&
                      link.href.substring(0, 4) !== 'http'
                        ) {
                          return (
                            <Button type='button'>
                              <Link className='heroNavItem' to={link.href} key={i}>
                                {link.text}
                              </Link>
                            </Button>
                          )
                        }

                        if (link.href) {
                          return (
                            <Button>
                              <a
                                key={i}
                                className='heroNavItem'
                                href={link.href}
                                target={link.target}
                              >
                                {link.text}
                              </a>
                            </Button>
                          )
                        }

                        return (
                          <button
                            className='heroNavItem'
                            onClick={link.action}
                            key={i}
                          >
                            {link.text}
                          </button>
                        )
                      })}
                    </nav>
                  )}
                </div>


                <div className='actions-wrapper'>
                  {showSearch ? (
                    <SearchField
                      className='appSearch'
                      id='app-search'
                      value={this.state.searchTerm}
                      onChange={e => {
                        this.setState({
                          ...this.state,
                          searchTerm: e.target.value
                        })
                        getSearchTerm(e.target.value)
                      }}
                    />
                  ) : null}
                  {actionOnClick && actionText && (
                    <button
                      type='button'
                      onClick={actionOnClick}
                      className='sectionHeaderLink'
                    >
                      {actionText}
                    </button>
                  )}
                  {commentsPaneText && commentsPaneOnClick &&
            <button type='button' onClick={actionOnClick}
              className='sectionHeaderLink commentsPane'>
              {commentsPaneText}
              <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                <path d={commentsIconPath} fill='white'/>
              </svg>
            </button>
                  }
                  {menuItems && (
                    <OverflowMenu flipped>
                      {menuItems.map((item, i) => (
                        <OverflowMenuItem key={item.key || i} {...item} />
                      ))}
                    </OverflowMenu>
                  )}
                </div>


              </div>
            </div>
          </div>


        </div>
        <hr className='separator' />
      </div>
    )
  }
}

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { GetPlayListRefreshed } from '../../hocs/queries/GetPlaylist'
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import PlaylistHero from '../../components/PlaylistHero'
import PlayListSongTable from '../../components/PlayListSongTable'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class PlaylistDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      importProjectRINModalOpen: false,
      viewAll: false
    }
  }
  render() {
    const { playlist, history, refetchPlaylist } = this.props
    return (
      <>
        <Helmet>
          <title>{playlist.title} | Playlists</title>
        </Helmet>
        <main className='main'>
          <CreatePagesHeader
            subtitle='Playlist Details'
            btnText='Edit'
            btnOnClick={() => history.push(`/playlists/${playlist.id}/edit`)}
          >
            <PlaylistHero
              {...playlist}
              refetchPlaylist={this.props.refetchPlaylist}
            >
              <Breadcrumb items={[
                {
                  href: `/playlists`,
                  name: 'Playlists'
                },
                {
                  href: `/playlists/${this.props.playlist.id}`,
                  name: this.props.playlist.title
                }
              ]}
              className='breadcrumb--playlist'
              />
            </PlaylistHero>
          </CreatePagesHeader>
          <div className='sections-container'>

            <SectionContainer>
              <div className='playlist-details'>
                <PlayListSongTable
                  nameCellClass="playlist-details-table"
                  showSearch={false}
                  sortDirection={'NONE'}
                  rows={playlist.files ? playlist.files : []}
                  playlistId={playlist.id}
                  refetch={refetchPlaylist}
                  batchActions={false}
                  rowActions
                  searchTerm={this.state.searchTerm}
                  __typename={playlist.__typename || ''}
                />
              </div>

            </SectionContainer>

          </div>
        </main>

      </>
    )
  }
}

export default compose(
  GetPlayListRefreshed,
)(PlaylistDetails)

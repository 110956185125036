import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, graphql } from 'react-apollo'

import { GET_ALL_PROJECT_SESSIONS, GET_ALL_RECORDING_SESSIONS } from '../../graphql/queries'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import GetRecording from '../../hocs/queries/GetRecording'

import AssignSessionRecordingValidator from '../../validators/AssignSessionRecording'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import { SelectStandard } from '../../components/Select'
import { Button, Form } from 'carbon-components-react'
import ApolloClient from '.././../utils/Apollo'
import Validator from '.././../utils/Validator'
import DiscardModal from '../../components/modals/DiscardModal'
import CreateNewSessionModal from '../../components/CreateNewSessionModal'
import AssignSessionRecording from '../../hocs/mutations/AssignSessionRecording'
import { DELETE_MULTIPLE_SESSION } from '../../graphql/mutations'


const initialState = {
  sessionList: [],
  multiValue: [],
  assignSessionId: [],
  recordingSessionList: [],
  defaultSession: [],
  showDiscardModal: false,
  showCreateNewSessionModal: false,
  loading: false,
  errors: {}
}

class AssignSessionToRecording extends Validator {
    state = { ...initialState }

    constructor(props) {
      super(props)

      this.handleAssignSessionChange = this.handleAssignSessionChange.bind(this)
      this.validator = AssignSessionRecordingValidator
    }


    getAllRecordingSession = () =>
      new Promise(resolve => {
        ApolloClient.query({
          query: GET_ALL_RECORDING_SESSIONS,
          variables: {
            recordingId: this.props.recording.id
          },
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          let recordingSessions = []
          data.getSessionsByRecording.forEach(recordingSession => {
            recordingSessions.push({
              value: recordingSession.id,
              label: recordingSession.name
            // })
            })
            resolve(recordingSessions)
            this.setState({
              recordingSessionList: recordingSessions
            })
          })
          let multiValue = []
          let sessionList = []
          multiValue = this.state.sessionList.filter(session =>
            this.state.recordingSessionList.some(({ value }) =>
              session.value === value))
          if (this.state.recordingSessionList.length) {
            sessionList = this.state.sessionList.filter(session => this.state.recordingSessionList.some(({ value }) => session.value !== value))
          } else {
            sessionList = this.state.sessionList
          }
          this.setState({ multiValue: multiValue, defaultSession: multiValue, assignSessionId: multiValue, sessionList: sessionList })
        })
      })

  getSession = () =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_ALL_PROJECT_SESSIONS,
        variables: {
          projectId: this.props.project.id
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let sessions = []
        data.getSessions.data.forEach(session => {
          sessions.push({
            value: session && session.id,
            label: session && session.name
          })
        })
        resolve(sessions)
        this.setState({
          sessionList: sessions
        })
      })
    })
  componentDidMount() {
    this.getSession()
    this.getAllRecordingSession()
  }

  // handle change
  handleAssignSessionChange(option) {
    this.setState({
      ...this.state,
      multiValue: option,
      assignSessionId: option,
      errors: {
        ...this.state.errors,
        assignSessionId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  // Open Discard Modal handle
  openDiscardModal = () => {
    const commmonList = this.state.multiValue.filter(session =>
      this.state.recordingSessionList.every(({ value }) =>
        session.value !== value))
    const stringSession = JSON.stringify(this.state.defaultSession) === JSON.stringify(this.state.multiValue)

    if (commmonList.length || !stringSession) {
      this.setState({ showDiscardModal: true })
    } else {
      this.props.history.goBack()
    }
  }

  // Close Discard Modal handle
  closeDiscardModal =() =>{
    this.setState({ showDiscardModal: false })
  }

  // Discard Changes handle
  discardChanges = () => {
    const commmonList = this.state.multiValue.filter(session =>
      this.state.recordingSessionList.every(({ value }) =>
        session.value !== value))

    if (commmonList.length) {
      this.setState({ multiValue: this.state.recordingSessionList })
    }
    this.closeDiscardModal()
    this.props.history.goBack()
  }

  // Open CreateNewSessionModal handle
  openCreateNewSessionModal= () =>{
    this.setState({
      showCreateNewSessionModal: true
    })
  }

  // Close CreateNewSessionModal handle
  closeCreateNewSessionModal = () =>{
    this.setState({
      showCreateNewSessionModal: false
    })
    this.getSession()
    this.getAllRecordingSession()
  }

  // Submit Form
  submit = ()=> {
    const commmonList = this.state.recordingSessionList.filter(session =>
      this.state.multiValue.every(({ value }) =>
        session.value !== value))
    const ids = commmonList.map(id => id.value)

    this.setState({
      loading: true
    })
    const sessionIds = []
    this.state.multiValue.forEach((value) =>[
      sessionIds.push(Number(value.value))
    ])
    this.props.assignMultipleSessionRecording(sessionIds, this.props.recording.id).then(() =>{
      this.setState({
        loading: false
      })
      this.props.deleteMultipleSession({
        variables: {
          sessionIds: ids
        }
      })
      this.props.history.push(`/projects/${this.props.project.id}/recordings/${this.props.recording.id}`)
    })
  }

  render() {
    const { multiValue, sessionList, errors, assignSessionId } = this.state
    let recordingId = this.props.recording.id
    let projectId = this.props.project.id
    let breadcrumb = [
      {
        href: `/projects/`,
        name: 'Projects'
      },
      {
        href: `/projects/${projectId}`,
        name: this.props.project.name
      },
      {
        href: `/projects/${projectId}/recordings`,
        name: 'Project Recordings'
      },
      {
        href: `/projects/${projectId}/recordings/${recordingId}`,
        name: this.props.recording.name
      },
      {
        href: `/projects/${projectId}/recordings/${recordingId}/sessions/assign`,
        name: 'Assign Session'
      }
    ]

    return (
      <>
        <Helmet>
          <title>Assign Session | {this.props.project.name}</title>
        </Helmet>
        <main className='main assign-session'>
          <CreatePagesHeader
            title={`Assign Sessions to ${this.props.recording.name}`}
            subtitle='Assign Sessions'
          >
            <Breadcrumb items={breadcrumb} />

          </CreatePagesHeader>
          <div className='sections-container'>
            <SectionContainer>
              <div className='form new-session-form'>
                <Form onSubmit={this.handleSubmit}>
                  <div className='formRow'>
                    <SelectStandard
                      isMulti
                      name='assignSessionId'
                      placeholder='Select from list ...'
                      value={multiValue}
                      options={sessionList.length ? sessionList : []}
                      onChange={this.handleAssignSessionChange}
                      btnOnClick={this.openCreateNewSessionModal}
                      key={assignSessionId}
                      labelText='Assign Session(s) to Recording *'
                      btnText='Create'
                      id='assignSessionId'
                      cacheOptions={false}
                      defaultOptions
                      invalid={errors.assignSessionId ? true : false}
                      invalidText={errors.assignSessionId}
                    />
                  </div>
                  <div className='formRow btns-container'>
                    <Button
                      onClick={this.openDiscardModal}
                      type='button'
                      kind='secondary'
                    >
                      Cancel
                    </Button>
                    <Button type='submit'>
                      {this.state.loading ? 'Saving...' : 'Save' }
                    </Button>
                  </div>
                </Form>
              </div>
            </SectionContainer>
          </div>
        </main>
        <DiscardModal
          open={this.state.showDiscardModal}
          onRequestClose={this.closeDiscardModal}
          modalHeading='Discard Changes?'
          handleSubmit={this.discardChanges}
        />
        <CreateNewSessionModal
          open={this.state.showCreateNewSessionModal}
          onRequestClose={this.closeCreateNewSessionModal}
          recording={this.props.recording}
          projectId={this.props.project.id}
        />
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetRecording,
  AssignSessionRecording,
  graphql(DELETE_MULTIPLE_SESSION, { name: 'deleteMultipleSession' })
)(AssignSessionToRecording)

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'
import { DateTime } from 'luxon'

import { UPDATE_SESSION } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import { GetSessionRefreshed } from '../../hocs/queries/GetSession'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import SessionForm from '../../components/SessionForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class SessionEdit extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.session.name} | {this.props.project.name}</title>
        </Helmet>
        <main className='main'>
          <CreatePagesHeader
            title={this.props.session.name}
            subtitle='Edit Session Details'
          >

            <Breadcrumb items={[
              {
                href: `/projects`,
                name: 'Projects'
              },
              {
                href: `/projects/${this.props.project.id}`,
                name: this.props.project.name
              },
              {
                href: `/projects/${this.props.project.id}/sessions`,
                name: 'Project Sessions'
              },
              {
                href: `/projects/${this.props.project.id}/sessions/${this.props.session.id}`,
                name: this.props.session.name
              },
              {
                href:
                  `/projects/${this.props.project.id}/sessions/${this.props.session.id}/edit`,
                name: 'Edit Session Details'
              }
            ]} />
          </CreatePagesHeader>
          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={UPDATE_SESSION}
                update={() => {
                  this.props.history.push(
                    `/projects/${this.props.project.id}/sessions/${this.props.session.id}`
                  )
                }}>
                {(updateSession, { loading, error }) => (
                  <SessionForm {...this.props.session}
                    onSubmit={(state) => {
                      let data = { ...state }
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      updateSession({
                        variables: {
                          ...data,
                          projectId: this.props.project.id,
                          sessionId: this.props.session.id,
                          unionSession: !!data.unionSession,
                          analogSession: !!data.analogSession,
                          dropFrame: !!data.dropFrame,
                          startedAt: data.startedAt ?
                            DateTime.fromMillis(data.startedAt.getTime())
                              .toFormat('yyyy-LL-dd HH:mm:ss') : null,
                          endedAt: data.endedAt ?
                            DateTime.fromMillis(data.endedAt.getTime())
                              .toFormat('yyyy-LL-dd HH:mm:ss') : null
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    project={this.props.project}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetSessionRefreshed
)(SessionEdit)

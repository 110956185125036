import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withApollo, compose } from 'react-apollo'
import GetUser from '../hocs/queries/GetUser'
import ArrowUpIcon from '../assets/leftMenu/upArrow.svg'
import logo from '../images/new-logo.png'
import user from '../assets/leftMenu/accountActive.svg'
import ToggleMenu from '../components/ToggleMenu'
import SearchField from '../components/SearchField'
import { Urls } from '../utils/constants'
class Header extends React.PureComponent {
  state = {
    menuOpen: false,
    searchTerm: '',
    location: ''
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname === prevState.location) {
      return null
    }
    return {
      menuOpen: false,
      location: nextProps.location.pathname,
      searchTerm: (nextProps.location.pathname === '/search' ? prevState.searchTerm : '')
    }
  }

  render() {
    let userAvatar = user;
    if (this.props.user && this.props.user.image) {
      userAvatar = `${Urls.profileImageUrl}${this.props.user.image}`
    }
    return (
      <header className='appHeader'>
        <div className='appBrandingNewUi appBrandingControlPanel'>
          <Link to='/'><img src={logo} className='appLogo' alt='logo' /></Link>
        </div>
        <SearchField className='appSearch' id='app-search'
          value={this.state.searchTerm}
          onChange={e => {
            this.setState({
              ...this.state,
              searchTerm: e.target.value
            })
          }}
          onKeyDown={e => {
            if (e.which === 13) {
              this.props.history.push(`/search?term=${e.target.value}`)
            }
          }} />
        <div className='appUserActions'>
          <ToggleMenu size='Medium'
            open={this.props.menuOpen}
            updateMenu={this.props.handleMenuToggle}/>
          <div className='appUserNav'>
            <button className='appUser' onClick={(e) => {
              e.preventDefault()
              this.setState({
                menuOpen: !this.state.menuOpen
              })
            }}>
              <span onClick={() => this.props.history.push('/account')}>
                {this.props.user.firstName} {this.props.user.lastName}
              </span>
              <img className='userIcon' src={userAvatar} alt='userIcon'/>
              {this.state.menuOpen ? <img className='dropdown-up-icon'
                src={ArrowUpIcon} height='20' width='20' alt='ArrowUpIcon'/> :
                <svg xmlns='http://www.w3.org/2000/svg' className='dropdownIcon'
                  viewBox='0 0 13.34 8'>
                  <path d='M.12 1l.08.09 5.67 6.55a1 1 0 0 0 .8.36 1.07 1.07 0 0 0
                    .8-.36l5.66-6.51.1-.11a.64.64 0 0 0 .11-.36.68.68 0 0 0-.69-.66H.69A.68.68
                    0 0 0 0 .66.65.65 0 0 0 .12 1z'
                  fill='#fff'/></svg>}</button>

            {this.state.menuOpen &&
              <nav className='appUserMenu'>
                <Link to='/account'>Account</Link>
                {/* <Link to='/subscription'>Subscription</Link>*/}
                <button
                  onClick={() => {
                    localStorage.removeItem('authToken')
                    this.props.client.resetStore()
                    this.props.history.push('/login')
                  }}>
                  Log Out
                </button>
              </nav>
            }
          </div>
        </div>
      </header>
    )
  }
}
export default compose(
  withApollo,
  withRouter,
  GetUser
)(Header)

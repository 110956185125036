import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'
import { DELETE_CREDIT } from '../graphql/mutations'
import WithComments from '../hocs/WithComments'
import ResponsiveTable from './ResponsiveTable'
import WithAlertModal from '../hocs/WithAlertModal'

class SessionCreditTable extends React.PureComponent {
  state = {
    originalRows: [],
    rows: []
  };

  constructor(props) {
    super(props)

    this.getHeaders = this.getHeaders.bind(this)
    this.getRowActions = this.getRowActions.bind(this)
  }
  // Headers
  getHeaders = (() => {
    const headers = [
      {
        key: 'name',
        header: 'Party'
      },
      {
        key: 'contribution',
        header: 'Contribution'
      },
      {
        key: 'role',
        header: 'Role'
      },
      {
        key: 'instrument',
        header: 'Instrument'
      },
      {
        key: 'featureType',
        header: 'F/NF/O'
      }
    ]

    return () => headers
  })();


  //   Expanded Content
  getExpandedContent = row => {
    return [
      { title: 'ISNI', content: row.isni },
      {
        title: 'Affiliations',
        content: row.cmo.length
          ? row.cmo.map(item => <div className='infoTextContent'>{item}</div>)
          : '-'
      },
      { title: '', content: '' },
      { title: 'IPI/CAE', content: row.ipiCae ? row.ipiCae : '-' }
    ]
  };

  //   ClassNames
  getClassName = row => {
    let className = ''
    if (this.props.songPermissions && row.contributionType === 'song') {
      className = this.props.songPermissions.create ? '' : 'item-ghosted'
    } else {
      className = this.props.canUpdate ? '' : 'item-ghosted'
    }
    return className
  };

  // Row Actions

  getRowActions = (() => {
    let actions = []

    actions.push({
      itemText: 'Edit',
      className: row => this.getClassName(row),
      onClick: row => {
        return this.props.history.push(
          `/projects/${this.props.projectId}/sessions/${row.contributionId}/credits/${row.id}`
        )
      }
    })

    actions.push({
      itemText: 'Delete',
      className: row => this.getClassName(row),
      onClick: row => {
        this.props.alert(
          'Are you sure you want to permanently delete this credit?',
          {
            type: 'delete',
            buttonText: 'Yes',
            submitCallback: close => {
              this.props
                .deleteCredit({
                  variables: {
                    creditId: row.id
                  },
                  update: this.props.onDelete
                })
                .then(() => {
                  this.props.alert('The credit has been successfully deleted.')
                })
                .catch(() => {
                  this.props.alert('This credit could not be deleted.')
                })
              close()
            }
          }
        )
      }
    })

    return () => actions
  })();

  // Batch action for project credit

  getActions = (() => {
    let actions = []
    if (this.props.refetch) {
      actions.push({
        name: 'restart',
        iconDescription: 'Refresh',
        onClick: () => this.props.refetch()
      })
    }

    if (actions.length === 0) {
      actions = false
    }
    return () => actions
  })();

  //   Derived State from Props

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rows === prevState.originalRows) {
      return null
    }

    const rows = nextProps.rows.map(row => {
      const { id, name, is_my, isni, ipiCae, affiliations } = row.party
      return {
        id: row.id,
        name: is_my === true ? `${name} (My Party)` : name,
        partyId: id,
        isni: isni || '-',
        ipiCae: ipiCae || '-',
        cmo: affiliations.length
          ? affiliations.map(item =>
            item.affiliation.type === 'cmo' ? item.affiliation.name : ''
          )
          : [],
        pro: affiliations.length
          ? affiliations.map(item =>
            item.affiliation.type === 'pro' ? item.affiliation.name : ''
          )
          : [],
        contribution: nextProps.songId
          ? row.contribution.name
          : `${row.contributionType
            .charAt(0)
            .toUpperCase()}${row.contributionType.slice(1)} - ${
            row.contribution.name
          }`,
        role: row.role ? row.role.name : '',
        contributionType: row.contributionType,
        instrument: row.instrument ? row.instrument.name : '-',
        contributionId: row.contribution.id,
        split: row.split ? row.split : '-',
        featureType: row.featureType ? row.featureType : '-'
      }
    })

    return {
      rows,
      originalRows: nextProps.rows
    }
  }

  generateLink(row) {
    return `/parties/${row.partyId}`
  }

  render() {
    return (
      <ResponsiveTable
        headers={this.getHeaders()}
        {...this.props}
        expandable={true}
        rows={this.state.rows}
        linkColumn='name'
        generateLink={this.generateLink}
        showSkeleton={this.props.showSkeleton}
        showSearch={this.props.showSearch}
        pagination={this.props.pagination}
        isProjectList={this.props.isProjectList}
        rowActions={this.getRowActions()}
        getExpandedContent={this.getExpandedContent}
        actions={this.props.actions && this.getActions()}
      />
    )
  }
}

export default compose(
  withRouter,
  WithComments,
  WithAlertModal,
  graphql(DELETE_CREDIT, { name: 'deleteCredit' })
)(SessionCreditTable)

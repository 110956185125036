import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    email: Field()
      .string()
      .email({ message: Errors.Email })
      .required({ message: Errors.Required })
  },
  { verbose: true, async: true }
)

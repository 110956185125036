import React, { useState } from 'react'
import Cross from '../../../assets/images/cross-icon.svg'
import UpIcon from '../../../assets/images/up-icon.svg'
import ProgressBar from '../../ProgressBar'
import { getUploads } from '../../modals/PublicShareModal'
import PublicShareDetailedProgress from './PublicShareDetailedProgress'

const calcTotalProgress = ({ uploads }) => {
  if (!uploads || !uploads.length) {
    return 0
  }
  let sumProgress = 0
  uploads.map(upload => {
    sumProgress = sumProgress + upload.progress
    return null
  })
  let totalProgress = sumProgress / uploads.length
  if (!totalProgress || (totalProgress > 100 || totalProgress < 0)) {
    return 0
  }
  return totalProgress
}

const PublicShareUploadsProgress = props => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(cur => !cur)
  }

  const { clearUploads } = props.uploads.actions

  const uploads = getUploads(props.uploads.state)

  if (uploads.length === 0) {
    return null
  }

  const { getUppy } = props.uploads.actions
  let files = getUppy().getState().files
  let inProgressCount = Object.keys(files).filter(
    key =>
      files[key].progress &&
      files[key].progress.uploadStarted &&
      !files[key].progress.uploadComplete
  ).length

  if (inProgressCount < 0) {
    inProgressCount = 0
  }

  const closeProgressModal = () => {
    clearUploads()
    setExpanded(false)
  }

  const uploadStatusFiles = `${inProgressCount} File${
    inProgressCount > 1 ? 's' : ''
  }`

  let uploadStatus = ''

  let totalProgress = calcTotalProgress({ uploads })

  if (inProgressCount > 0) {
    uploadStatus = 'Uploading'
  } else if (totalProgress === 100) {
    uploadStatus = 'Uploaded'
  } else if (props.uploads.state.status === 'started') {
    uploadStatus = 'Failed'
  }

  return (
    <div
      className={`uploadsProgress publicShareUploads ${
        expanded ? 'uploadsProgress--expanded publicShareUploads--expanded' : ''
      }`}
    >
      {!expanded && (
        <>
          <div className='uploadStatus'>
            <p className='uploadStatus--info'>{uploadStatus}</p>
            {inProgressCount !== 0 && (
              <p className='uploadStatus--info'>{uploadStatusFiles}</p>
            )}
          </div>
          <div className='uploadPercentage'>
            <ProgressBar value={totalProgress} />
          </div>
          <p className='percentage-note'>{Math.trunc(totalProgress)}%</p>
          <div className='uploadActions'>
            <img src={UpIcon} alt='Expand' onClick={toggleExpanded} />
            <img src={Cross} alt='Close' onClick={closeProgressModal} />
          </div>
        </>
      )}
      {expanded && (
        <PublicShareDetailedProgress
          toggleExpanded={toggleExpanded}
          uploadStatus={uploadStatus}
          uploadStatusFiles={uploadStatusFiles}
          uploads={uploads}
          closeProgressModal={closeProgressModal}
          removeUpload={props.uploads.actions.removeUpload}
        />
      )}
    </div>
  )
}

export default PublicShareUploadsProgress

import React, { useState } from 'react'
import { Query } from 'react-apollo'

import {
  getQuery,
  onMoveFilesFrom,
  getDestinationFolder
} from '../../utils/moveFilesHelper'

import { MoveFilesFromModal } from '../../components/modals'
import AlertModal from '../../components/modals/AlertModal'

const WithMoveFilesFrom = WrappedComponent => {
  return props => {
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
      isMyFile: true,
      isProject: false,
      isSongs: false,
      projectId: null,
      folderId: null,
      isProjectOwner: null,
      typeName: '',
      root: 'Files',
      onCancel: null,
      selectedFolderId: null,
      selectedProjectId: null,
      message: '',
      selectionBreadcrumb: [],
      refetch: null
    })
    const [selectedId, setSelectedId] = useState('')

    const [destinationFiles, setDestinationFiles] = useState([])
    const [filesToMove, setFilesToMove] = useState([])
    const [alertOpen, setAlertOpen] = useState(false)

    const { query, queryKey } = getQuery(state)

    const openMoveFilesFromHandler = async (
      selectedProjectId,
      selectedFolderId,
      selectionBreadcrumb,
      refetch
    ) => {
      setState({
        ...state,
        selectedFolderId,
        selectedProjectId,
        selectionBreadcrumb,
        refetch
      })
      setOpen(true)

      const { files, folders } = await getDestinationFolder(selectedFolderId, selectedProjectId)
      setDestinationFiles([...folders, ...files])
      setOpen(true)
    }
    const onSave = async () => {
      const { selectedFolderId, selectedProjectId, refetch } = state
      if (filesToMove.length === 0) {
        setState({ ...state,
          message: 'Cannot move please select files or folders' })
        setAlertOpen(!alertOpen)
        return
      }
      const response = await onMoveFilesFrom(selectedFolderId, selectedProjectId, filesToMove)
      if (response && response.success) {
        setAlertOpen(!alertOpen)
        setState({
          ...state,
          message: 'Selected files moved successfully'
        })
        if (refetch) {
          refetch()
        }
      } else {
        setAlertOpen(!alertOpen)
        setState({
          ...state,
          message: 'Selected files could not moved'
        })
      }
      setOpen(false)
      setFilesToMove([])
      setDestinationFiles([])
    }

    const onMoveOutDestinationHandler = (projectId, folderId) => {
      setState({ ...state, projectId, folderId })
    }

    const onSelectDestination = ({ id, __typename, readonly }, e) => {
      if (readonly || __typename === 'Project') {
        setState({ ...state,
          message: 'Cannot move readonly folders please navigate inside to select files and folders' })
        setAlertOpen(!alertOpen)
        return
      }
      let tempFiles = [...filesToMove]
      if (e.currentTarget.className.includes('isSelected')) {
        const index = tempFiles.findIndex((item)=>Number(item.id) === Number(id))
        tempFiles.splice(index, 1)
        setFilesToMove(tempFiles)
        return
      }
      tempFiles.push({
        id,
        type: __typename === 'Folder' ? 'folder' : 'file'
      })
      setFilesToMove(tempFiles)
    }

    const onMoveInDestinationHandler = ({ id, name, __typename }) => {
      const folderId = __typename.toLowerCase() === 'folder' ? id : null
      const projectId = __typename.toLowerCase() === 'project' ? id : null

      setState({
        ...state,
        folderId,
        projectId,
        selectedId: id
      })
    }
    const onChangeDestinationFlow = value => {
      const isProject = value === 'projects'
      const isMyFile = value === 'files'
      const isSongs = value === 'songs'

      setState({
        ...state,
        isProject,
        isMyFile,
        isSongs,
        root: value,
        projectId: null,
        folderId: null
      })
      setSelectedId('')
    }
    const eventHandler = () => {}
    const handleQueryResponce = ({ loading, data }) => {
      if (!loading) {
        let destination = data[queryKey]
        const { projectId, folderId, isMyFile, isSongs } = state

        if (
          projectId ||
          folderId ||
          isMyFile ||
          isSongs
        ) {
          const destinationFolders =
          isSongs && !folderId && destination
            ? destination.songFolders
            : [...destination.folders, ...destination.files]
          destination = !destination ? [] : destinationFolders
        }

        return (
          <MoveFilesFromModal
            showModal={open}
            onSave={onSave}
            selectedFiles={destinationFiles}
            destination={destination}
            onClose={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            onMoveInDestination={onMoveInDestinationHandler}
            onChangeDestinationFlow={onChangeDestinationFlow}
            selectedId={selectedId}
            onSelectDestination={onSelectDestination}
            onMoveFiles={onSave}
            files={filesToMove}
            selectionBreadcrumb={state.selectionBreadcrumb}
            onMoveOutDestination={onMoveOutDestinationHandler}
          />
        )
      }
      return (
        <MoveFilesFromModal
          showModal={open}
          onSave={eventHandler}
          selectedFiles={destinationFiles}
          destination={[]}
          onClose={eventHandler}
          onCancel={eventHandler}
          onMoveInDestination={eventHandler}
          onChangeDestinationFlow={eventHandler}
          selectedId={state.selectedId}
          onSelectDestination={eventHandler}
          onMoveFiles={eventHandler}
          files={filesToMove}
          selectionBreadcrumb={state.selectionBreadcrumb}
          onMoveOutDestination={eventHandler}
        />
      )
    }
    return (
      <>
        <WrappedComponent
          {...props}
          moveFilesFrom={{
            open: openMoveFilesFromHandler,
            close: () => setOpen(false)
          }}
        />
        <Query
          query={query}
          variables={{
            projectId: state.projectId,
            folderId: state.folderId
          }}
          fetchPolicy='cache-and-network'
        >
          {handleQueryResponce}
        </Query>
        <AlertModal
          alertOpen={alertOpen}
          modalHeading={' '}
          onRequestClose={()=> setAlertOpen(!alertOpen)}
          handleSubmit={()=> setAlertOpen(!alertOpen)}
          message={state.message}
        />
      </>
    )
  }
}


export default WithMoveFilesFrom

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'

import { GET_ALL_RECORDINGS } from '../../graphql/queries'
import { getUserFavouritesByType } from '../../utils/selectors'
import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import * as permissions from '../../utils/permissions'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import RecordingTable from '../../components/RecordingTable'
import Hero from '../../components/Hero'
import WithAlertModal from '../../hocs/WithAlertModal'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class ProjectRecordings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
   getTags = (permission)=> {
     const tags = []
     const permissionsKyes = Object.keys(permission)
     permissionsKyes.forEach(key => {
       if (permission[key] === true) {
         tags.push({
           tagName: key.charAt(0).toUpperCase() + key.slice(1)
         })
       }
     })
     return tags
   }
   getSearchTerm=(value)=>{
     this.setState({
       searchTerm: value
     })
   }
   render() {
     const { project, match, alert, user, history } = this.props
     const collaboratorType = permissions.getCollaboratorType(project.id, user.collaborators)
     const favourites = getUserFavouritesByType(user)

     const isProjectOwner = permissions.isProjectOwner(project.id, user)
     let recordingPermissions = permissions.can(project.id, 'recording')
     const can = isProjectOwner || recordingPermissions
     const canCreate = isProjectOwner || can.create
     const canUpdate = collaboratorType !== 'recording' ? isProjectOwner || can.update : false
     const canDelete = isProjectOwner || can.delete
     delete recordingPermissions.download
     delete recordingPermissions.update
     const fullAccess = Object.values(recordingPermissions).every(permission => permission === true)
     if (fullAccess) {
       recordingPermissions = { 'Full Access': true }
     }

     return (
      <>
        <Helmet>
          <title>Recordings | {project.name}</title>
        </Helmet>
        <main className='main project-recordings'>
          <div className={`${project.locked ? 'ghosted-wrapper' : ''}`}>
            <div className='create-hero'>
              <Hero
                subtitle={`${project.name} Recordings`}
                showSearch={true}
                getSearchTerm={this.getSearchTerm}
                btnText={canCreate ? 'Add' : false}
                btnOnClick={() => {
                  if (project.locked) {
                    alert('Project Credits are locked. Please unlock to edit.',
                      { title: '   ', className: 'credits-alert'
                      })
                    return
                  }

                  history.push(`/projects/${project.id}/recordings/create`)
                }
                }
              >
                <Breadcrumb items={[
                  {
                    href: `/projects`,
                    name: 'Projects'
                  },
                  {
                    href: `/projects/${project.id}`,
                    name: project.name
                  },
                  {
                    href: `/projects/${project.id}/recordings`,
                    name: 'Project Recordings'
                  }
                ]} />
              </Hero>
            </div>
            <CreatePagesHeader
              subtitle='Project Recordings'
              withDivider={false}
              tags={!isProjectOwner ? this.getTags(recordingPermissions) : []}
            />
            <div className='mainContainer'>
              <SectionContainer>

                <Query query={GET_ALL_RECORDINGS}
                  variables={{ projectId: match.params.projectId }}
                  fetchPolicy='cache-and-network'>
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    return (
                      <div className='project-files'>
                        <RecordingTable rows={data.getRecordings ? data.getRecordings.data : []}
                          showSearch={false}
                          nameCellClass='project-recording-table'
                          searchTerm={this.state.searchTerm}
                          locked={project.locked}
                          showSkeleton={loading}
                          projectId={project.id}
                          favourites={favourites.recording || []}
                          favourite
                          pagination
                          refetch={refetch}
                          actions
                          sortByOrder={true}
                          canUpdate={canUpdate}
                          canDelete={canDelete}
                          perPage={100}
                          onDeleteAlert={() => this.props.alert('The recording has been successfully deleted.')}
                          onDelete={(cache, { data: deleteRecording }) => {
                            const { getRecordings } = cache.readQuery({
                              query: GET_ALL_RECORDINGS,
                              variables: {
                                projectId: match.params.projectId
                              }
                            })
                            const recordings = {
                              ...getRecordings,
                              data: getRecordings.data.filter(recording =>
                                parseInt(recording.id, 10) !== parseInt(
                                  deleteRecording.deleteRecording.id, 10
                                ))
                            }

                            cache.writeQuery({
                              query: GET_ALL_RECORDINGS,
                              variables: {
                                projectId: match.params.projectId
                              },
                              data: {
                                getRecordings: recordings
                              }
                            })
                          }} />
                      </div>
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>
        </main>
      </>
     )
   }
}

export default compose(
  GetUser,
  GetProject,
  WithAlertModal
)(ProjectRecordings)

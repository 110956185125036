import React from 'react'
import { Form, Button, TextInput } from 'carbon-components-react'

import Validator from '../utils/Validator'
import AccountInfoValidator from '../validators/AccountInfo'
import DeleteFileIcon from './DeleteFileIcon'
import UploadFileIcon from './UploadFileIcon'
import { GetGradientFromId } from '../utils/constants'
import DiscardModal from './modals/DiscardModal'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  initialErrors: {},
  errors: {},
  file: null,
  discardModal: false,
  image: '',
  isUploading: false,
  isRemoving: false
}

class AccountInfoForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.validator = AccountInfoValidator

    this.state.firstName = props.firstName || ''
    this.state.lastName = props.lastName || ''
    this.state.email = props.email || ''
    this.state.gradientBg = GetGradientFromId(this.props.id)
    this.state.image = props.image || null
    this.state.isUploading = false
    this.discardChanges = this.discardChanges.bind(this)
    this.openDiscardModal = this.openDiscardModal.bind(this)
    this.closeDiscardModal = this.closeDiscardModal.bind(this)
    this.state.previousEmail = props.email || ''
  }

  submit() {
    if (this.state.file) {
      this.setState({ isUploading: true })
      const file = this.state.file.obj
      const formData = new FormData()
      formData.append('file', file, file.name)
      fetch(`${process.env.REACT_APP_API}/api/profile-image-upload`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('authToken')
        },
        body: formData
      })
        .then(response => response.json())
        .then(response => {
          this.setState({ image: response.name })
          this.props.refetchGlobalUser()
          setTimeout(() => {
            this.setState({ isUploading: false })
          }, 2000)
        })
    }
    if (this.state.file === null && this.state.image === null) {
      fetch(`${process.env.REACT_APP_API}/api/profile-image-upload`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('authToken')
        },
        body: ''
      }).then(()=>this.props.refetchGlobalUser())
    }
    let isEmailChanged = false
    if (this.state.previousEmail !== this.state.email) {
      isEmailChanged = true
      this.setState({ previousEmail: this.state.email })
    }
    this.props.onSubmit(this.state, isEmailChanged)
  }

  getInitials() {
    const firstWord = this.props.firstName
    const secondWord = this.props.lastName
    let initials = ''
    if (firstWord.length > 0) {
      initials = initials + firstWord[0]
    }
    if ((secondWord || '').length > 0) {
      initials = initials + secondWord[0]
    }
    return initials
  }


  uploadImage = async (e) => {
    if (e.target.files.length < 1) {
      return
    }
    if (this.state.isUploading) {
      return
    }
    this.setState({
      file: {
        url: URL.createObjectURL(e.target.files[0]),
        obj: e.target.files[0]
      }
    })
  }

  removeImage = () => {
    this.setState({ image: null, file: null })
  }

  openDiscardModal(e) {
    e.preventDefault()

    if (
      this.props.firstName === this.state.firstName &&
      this.props.lastName === this.state.lastName &&
      this.props.email === this.state.email &&
      this.props.image === this.state.image &&
      this.state.file === null
    ) {
      this.props.history.goBack()
    } else {
      this.setState({
        discardModal: true
      })
    }
  }

  closeDiscardModal() {
    this.setState({
      discardModal: false
    })
  }

  discardChanges() {
    this.setState({
      discardModal: false,
      firstName: this.props.firstName || '',
      lastName: this.props.lastName || '',
      email: this.props.email || '',
      image: this.props.image || null,
      file: null
    })
  }

  render() {
    return (
      <div className='form'>
        <Form onSubmit={this.handleSubmit} autoComplete='off'>
        <div className='formRow accountImg'>
            <label className='bx--label'>Profile Image *</label>
            <div className='formRow img-upload-section'>
              {(this.state.image || this.state.file !== null) ? (
                <img
                  src={this.state.file ? this.state.file.url : `${process.env.REACT_APP_IMAGE_URL}profile-images/${this.state.image}`}
                  alt=''
                  className='song-img' />
              ) : (
                <div
                  className='account-img-placeholder'
                  style={{ background: this.state.gradientBg }}
                >
                  <span className='accountCover'>{this.getInitials(this.props.title)}</span>
                </div>
              )}
              <div className='actions-container'>
                {(this.state.file || this.state.image) && (
                  <DeleteFileIcon onClick={this.removeImage} />
                )}
                <UploadFileIcon onClick={this.uploadImage} loading={this.state.isUploading} />
              </div>
            </div>
          </div>

          <div className='formRow'>
            <TextInput
              id='firstName'
              name='firstName'
              labelText='First Name *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.firstName || ''}
              invalid={this.state.errors.firstName ? true : false}
              invalidText={this.state.errors.firstName || ''}
            />
          </div>
          <div className='formRow'>
            <TextInput
              id='lastName'
              name='lastName'
              labelText='Last Name'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.lastName || ''}
              invalid={this.state.errors.lastName ? true : false}
              invalidText={this.state.errors.lastName || ''}
            />
          </div>
          <div className='formRow'>
            <TextInput
              id='email'
              name='email'
              labelText='Email Address'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.email || ''}
              invalid={this.state.errors.email ? true : false}
              invalidText={this.state.errors.email || ''}
            />
          </div>
          <div className='formRow actionBtnContainer'>
            <button className='bx--btn bx--btn--secondary' onClick={this.openDiscardModal}>
              Cancel
            </button>
            <Button type='submit'>
              {this.props.loading ? 'Saving...' : 'Save'}
            </Button>
          </div>
          {this.props.success && (
            <p>We have sent you an email to verify your email address</p>
          )}
        </Form>
        {
          this.state.discardModal && <DiscardModal open={this.state.discardModal}
            onRequestClose={this.closeDiscardModal}
            modalHeading='Discard Changes?'
            handleSubmit={this.discardChanges}
          />
        }
      </div>
    )
  }
}


export default compose(
  withRouter
)(AccountInfoForm)

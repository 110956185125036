import React from 'react'

const IconButton = ({ icon, btnText, containerClass, clickHandler }) => {
  return (
    <div className={`iconButton ${containerClass}`} onClick={clickHandler}>
      {icon && <img src={icon} alt='button symbol' className='icon'/>}
      {btnText && <p className='btnText'>{btnText}</p>}
    </div>
  )
}

export default IconButton

import React from 'react'
import { Query } from 'react-apollo'
import ReactAudioPlayer from 'react-audio-player'
import { Modal } from 'carbon-components-react'
import { GET_FILE_PREVIEW } from '../graphql/queries'

export default class FilePreviewModal extends React.PureComponent {
  render() {
    const { open, id, onRequestClose } = this.props
    return (
      <Modal className='modal'
        open={open}
        modalHeading='File Preview'
        passiveModal={true}
        onRequestClose={onRequestClose}>
        {open &&
          (<Query query={GET_FILE_PREVIEW}
            variables={{
              id: id
            }}
            fetchPolicy='network-only'
          >
            {({ data, loading }) => {
              if (loading) {
                return <p>Loading</p>
              }
              let type = data.getFilePreview.type.toLowerCase()
              const imageTypeList = ['png', 'jpg', 'jpeg', 'webp']
              const videoTypeList = ['mp4', 'm4a', 'mov']

              const isImage = imageTypeList.includes(type)
              const isVideo = videoTypeList.includes(type)
              if (type === 'avi') {
                return (<> <p>Not Supported</p> </>)
              }
              else if (isImage) {
                return (
                  <>
                    <p>{data.getFilePreview.name}</p>
                    <br />
                    <img src={data.getFilePreview.url} width='100%' alt='' />
                  </>
                )
              }
              if (isVideo) {
                if (type === 'mov') type = 'mp4'
                return (
                  <>
                  <video width='100%' height='100%' controls autoPlay={true}>
                    <source src={data.getFilePreview.url} type={`video/${type}`}/>
                  </video>
                  </>
                )
              }
              return (
                <>
                  <p>{data.getFilePreview.name}</p>
                  <br />
                  <ReactAudioPlayer
                    src={data.getFilePreview.url}
                    title={data.getFilePreview.name}filePreviewModal
                    autoPlay
                    controls
                    controlsList='nodownload'
                    style={{
                      width: '100%'
                    }}
                  />
                </>
              )
            }}
          </Query>
          )}
      </Modal>
    )
  }
}

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'
import { iconInfoGlyph } from 'carbon-icons'

import { GET_ALL_PROJECT_SESSIONS } from '../../graphql/queries'
import { getUserFavouritesByType } from '../../utils/selectors'
import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import * as permissions from '../../utils/permissions'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import SessionTable from '../../components/SessionTable'
import Hero from '../../components/Hero'
import WithAlertModal from '../../hocs/WithAlertModal'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import { Icon } from 'carbon-components-react'
import { filterPermissions } from '../../utils/permissions'


class ProjectSessions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
    this.getTitleWithIcon = this.getTitleWithIcon.bind(this)
    this.getTags = this.getTags.bind(this)
  }
   getTags = (permission)=> {
     const tags = []
     const permissionsKyes = Object.keys(permission)
     permissionsKyes.forEach(key => {
       if (permission[key] === true) {
         tags.push({
           tagName: key.charAt(0).toUpperCase() + key.slice(1)
         })
       }
     })
     return tags
   }
   getSearchTerm=(value)=>{
     this.setState({
       searchTerm: value
     })
   }
   getTitleWithIcon = () => {
     return <>Project Sessions<Icon icon={iconInfoGlyph} description='' className='info-icon'/></>
   }

   render() {
     const { project, history, user, match, alert } = this.props
     const favourites = getUserFavouritesByType(user)

     const isProjectOwner = permissions.isProjectOwner(project.id, user)
     const can = isProjectOwner || permissions.can(project.id, 'session')
     const canCreate = isProjectOwner || can.create
     const canUpdate = isProjectOwner || can.update
     const canDelete = isProjectOwner || can.delete
     let sessionPermissions = permissions.can(project.id, 'session')
     delete sessionPermissions.download
     const fullAccess = Object.values(sessionPermissions).every(permission => permission === true)
     if (fullAccess) {
       sessionPermissions = { 'Full Access': true }
     }
     return (
      <>
        <Helmet>
          <title>Sessions | {project.name}</title>
        </Helmet>
        <main className='main project-seesion'>
          <div className={`${project.locked ? 'ghosted-wrapper' : ''}`}>
            <div className='create-hero'>
              <Hero
                subtitle={`${project.name} Sessions`}
                showSearch={true}
                getSearchTerm={this.getSearchTerm}
                btnText={canCreate ? 'Add' : false}
                btnOnClick={() => {
                  if (project.locked) {
                    // eslint-disable-next-line max-len
                    alert('Project Credits are locked. Please unlock to edit.', { title: '   ', className: 'credits-alert' })
                    return
                  }
                  history.push(`/projects/${project.id}/sessions/create`)
                }
                }
              >
                <Breadcrumb items={[
                  {
                    href: `/projects`,
                    name: 'Projects'
                  },
                  {
                    href: `/projects/${project.id}`,
                    name: project.name
                  },
                  {
                    href: `/projects/${project.id}/sessions`,
                    name: 'Project Sessions'
                  }
                ]} />
              </Hero>
              <CreatePagesHeader
                subtitle='Project Sessions'
                withDivider={false}
                tags={!isProjectOwner ? this.getTags(filterPermissions(sessionPermissions)) : []}
              />
            </div>
            <div className='mainContainer'>
              <SectionContainer>

                <Query query={GET_ALL_PROJECT_SESSIONS}
                  variables={{ projectId: match.params.projectId }}
                  fetchPolicy='cache-and-network'>
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    return (
                      <div className='project-files'>
                        <SessionTable rows={data.getSessions ? data.getSessions.data : []}
                          showSearch={false}
                          searchTerm={this.state.searchTerm}
                          locked={project.locked}
                          showSkeleton={loading}
                          actions
                          projectId={project.id}
                          favourites={favourites.session || []}
                          pagination
                          sortByOrder={true}
                          canUpdate={canUpdate}
                          canDelete={canDelete}
                          sortDirection='ASC'
                          sortColumn='name'
                          sortBy='Session Name'
                          refetch={refetch}
                          perPage={100}
                          onDelete={(cache, { data: { deleteSession } }) => {
                            const { getSessions } = cache.readQuery({
                              query: GET_ALL_PROJECT_SESSIONS,
                              variables: {
                                projectId: match.params.projectId
                              }
                            })

                            const sessions = {
                              ...getSessions,
                              data: getSessions.data
                                .filter(session => session.id !== deleteSession.id
                                )
                            }

                            cache.writeQuery({
                              query: GET_ALL_PROJECT_SESSIONS,
                              variables: {
                                projectId: match.params.projectId
                              },
                              data: {
                                getSessions: sessions
                              }
                            })
                          }} />
                      </div>
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>

          </div>
        </main>
      </>
     )
   }
}

export default compose(
  GetUser,
  GetProject,
  WithAlertModal
)(ProjectSessions)

import React from 'react'
import playButton from '../assets/images/playPublic.svg'
import { nanoSecondsToHMS } from '../utils/functions'
import pauseButton from '../assets/images/pausePublic.svg'
import playWhiteButton from '../assets/images/playlist-play-circle.svg'

import { DataTable } from 'carbon-components-react'

const { TableCell, Table, TableRow, TableBody } = DataTable


const PublicPlaylistSong = ({
  filePlayingIdHandler,
  fileSelectedHandler,
  showPlayer,
  filePlayingId,
  fileSelectedId,
  files
}) => {
  const playPasuseSong = (file) =>{
    let songImg = null
    // const
    if ((fileSelectedId && filePlayingId) === file.id) {
      songImg = pauseButton
    } else if (fileSelectedId === file.id) {
      songImg = playButton
    } else {
      songImg = playWhiteButton
    }
    return songImg
  }
  const playAndPauseSong = (file) => {
    if (fileSelectedId === file.id) {
      filePlayingIdHandler({ id: null })
    } else {
      showPlayer({ curfile: files })
      filePlayingIdHandler({ id: file.id })
      fileSelectedHandler({ id: file.id })
    }
  }
  return (
    <>
    <Table>
      <TableBody>
        {files.map((file, index) => (
          <TableRow key={file.id + index} className='public-playlist-row'>
            <TableCell>
              <div className='ppSongName'>
                <div className='ppNumber'>
                  <p>{index + 1}</p>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className='playBtn' onClick={() => playAndPauseSong(file, index)}>
                <img src={playPasuseSong(file)} alt='Play' className='playPauseImg'/>
              </div>
            </TableCell>
            <TableCell>
              <p>Title</p>
              <p>{file.name}</p>
            </TableCell>
            <TableCell>
              <p>Duration</p>
              <p className='ppSongTime'>{nanoSecondsToHMS(file.duration || 0)}</p>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </>
  )
}

export default PublicPlaylistSong

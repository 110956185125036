import React from 'react'
import { Button, Checkbox } from 'carbon-components-react'
import { IntegrationsContent } from '../../utils/constants'
import { compose } from 'react-apollo'
import { CHANGE_SX_STATUS } from '../../graphql/mutations'
import ApolloClient from '../../utils/Apollo'
import WithIntegrationDetailsModal from '../../hocs/WithIntegrationDetailsModal'
import GetUser from '../../hocs/queries/GetUser'
const IntegrationTiles = ({ user, details, refetch }) => {
  const changeSxStatus = async ()=>{
    await ApolloClient.mutate({
      mutation: CHANGE_SX_STATUS,
      fetchPolicy: 'no-cache'
    }
    )
    refetch()
  }
  return (
    <>
    <div className='integration-tiles'>
      { IntegrationsContent.map(({
        logoImage,
        title,
        text,
        link,
        detailsText,
        checkBoxText
      })=>{
        return (
          <div key={title} className='integration-tiles-wrapper'>
            <div className='integration-tiles-header'>
              <img
                className={`integration-tiles-logo integration-tiles-${title.toLocaleLowerCase()} `}
                src={logoImage} alt='logoImg' />
              <Button onClick={()=>{
                Object.assign(document.createElement('a'), {
                  target: '_blank',
                  href: link,
                  rel: 'noopener noreferrer'
                }).click()
              }}className='integration-tiles-button'>Go to website</Button>
            </div>
            <div className='integration-tiles-content'>
              <p className='integration-tiles-heading'>
                {title}
              </p>
              <p className='integration-tiles-text' >
                { text.length > 400 ? <>{checkBoxText ? text.slice(0, 200) : text.slice(0, 370)}...<span onClick={()=>{
                  details(text,
                    {
                      title: title,
                      logoImage: logoImage,
                      details: detailsText
                    })
                }} className='read-more-button'>more</span> </> : text }
              </p>


              {checkBoxText ?
                <div className='integration-tiles-checkbox '>
                  <Checkbox
                    labelText=''
                    className='formCheckbox'
                    id='checked'
                    onChange={()=> changeSxStatus()}
                    checked={user.sxStatus === '0' ? false : true}
                    hideLabel/>
                  <span className='integration-tiles-checkbox-label'>{checkBoxText}</span>
                </div>
                : null }

            </div>
            <Button onClick={()=>{
              details(text,
                {
                  title: title,
                  logoImage: logoImage,
                  details: detailsText
                })
            }} className='read-more'>About integration</Button>

          </div>
        )
      })}


    </div>
    </>
  )
}
export default compose(GetUser, WithIntegrationDetailsModal)(IntegrationTiles)

import React from 'react'
import {
  Form,
  TextInput,
  Modal
} from 'carbon-components-react'

import Validator from '../utils/Validator'
import Enter2FAValidator from '../validators/Enter2FA'

const initialState = {
  code: '',

  errors: {}
}

export default class extends Validator {
  validator = Enter2FAValidator

  state = { ...initialState }

  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  submit() {
    this.props.onSubmit(this.state.code)
      .catch(() => {
        this.setState({
          ...this.state,
          errors: {
            code: 'Invalid code'
          }
        })
      })
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        modalHeading='Enter 2FA Code'
        primaryButtonText='Continue'
        secondaryButtonText='Cancel'
        shouldSubmitOnEnter={true}
        selectorPrimaryFocus='.bx--text-input'
        onRequestSubmit={this.handleSubmit}
        onRequestClose={this.props.onRequestClose}>
        <div className='form'>
          <Form>
            <div className='formRow'>
              <TextInput labelText='Code'
                id='code'
                name='code'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={this.state.code}
                invalid={this.state.errors.code ? true : false}
                invalidText={this.state.errors.code} />
            </div>
          </Form>
        </div>
      </Modal>
    )
  }
}

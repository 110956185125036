import React from 'react'
import { Query } from 'react-apollo'
import { GET_COLLABORATOR } from '../../graphql/queries'

const GetCollaborator = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_COLLABORATOR}
          variables={{ collaboratorId: this.props.match.params.collaboratorId }}
          fetchPolicy={fetchPolicy}>
          {({ loading, error, data }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            if (data.getCollaborator === null) {
              return <h1 style={{ color: 'white', textAlign: 'center' }}>404</h1>
            }

            return (
              <WrappedComponent {...this.props} collaborator={data.getCollaborator} />
            )
          }}
        </Query>
      )
    }
  }
}

export default GetCollaborator

export const GetCollaboratorRefreshed = (WrappedComponent) => {
  return GetCollaborator(WrappedComponent, 'network-only')
}

import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'
import { Icon } from 'carbon-components-react'

import { DELETE_CREDIT } from '../graphql/mutations'

import WithComments from '../hocs/WithComments'
import { iconInfoGlyph } from 'carbon-icons'
import ResponsiveTable from './ResponsiveTable'
import WithAlertModal from '../hocs/WithAlertModal'
import GetUser from '../hocs/queries/GetUser'
import Tooltip from './ui/Tooltip'

class CreditTable extends React.PureComponent {
  state = {
    originalRows: [],
    rows: []
  }

  constructor(props) {
    super(props)

    this.getHeaders = this.getHeaders.bind(this)
    this.getRowActions = this.getRowActions.bind(this)
    this.generateLink = this.generateLink.bind(this)
    this.renderRoute = this.renderRoute.bind(this)
    this.getActions = this.getActions.bind(this)
  }

  getHeaders = (() => {
    if (this.props.songId) {
      const songHeaders = [
        {
          key: 'name',
          header: 'Party'
        },
        {
          key: 'contribution',
          header: 'Contribution'
        },
        {
          key: 'role',
          header: 'Role'
        },
        {
          key: 'split',
          header: 'Split'
        }
      ]

      return () => songHeaders
    }

    const headers = [
      {
        key: 'name',
        header: 'Party'
      },
      {
        key: 'contribution',
        header: 'Contribution'
      },
      {
        key: 'role',
        header: 'Role'
      },
      {
        key: 'instrument',
        header: 'Instrument'
      },
      {
        key: 'featureType',
        header: 'F/NF/O'
      }
    ]

    return () => headers
  })()
 getExpandedHeaders =()=> this.props.songId
   ? [
     {
       header: 'ISNI',
       key: 'isni'
     },
     {
       header: 'info',
       key: 'ipiCae'
     },
     {
       title: 'Affiliations',
       key: 'pro',
       formatter: (row)=> row.pro.length ? row.pro.map(item =>
         <div className='infoTextContent'>{item}</div>
       ) : '-'
     }
   ]
   : [
     { header: 'ISNI', key: 'isni' },
     {
       header: 'Affiliations',
       key: 'cmo',
       formatter: (row)=> row.cmo.length ? row.cmo.map(item =>
         <div className='infoTextContent'>{item}</div>
       ) : '-'
     }
   ]
  getExpandedContent = (row) => {
    const affiliations = []
    row.pro.map(item =>
      affiliations.push(item)
    )
    row.cmo.map(item =>
      affiliations.push(item)
    )
    const info = (
      <div className='tooltip-container'>
        <div>IPI/CAE</div>
        <div><Tooltip term={<Icon icon={iconInfoGlyph} description='' className='info-icon'/>} definition='An IPI/CAE number is an international identification number assigned to songwriters and publishers to uniquely identify rights holders.' info={true}/>
        </div>
      </div>
    )

    return (
      this.props.songId
        ? [
          {
            title: 'ISNI',
            content: row.isni
          },
          {
            title: 'Affiliations',
            content: affiliations.length > 0 ? affiliations.map(item =>
              <div className='infoTextContent'>{item}</div>
            ) : '-'
          },
          {
            title: info,
            content: row.ipiCae ? row.ipiCae : '-'
          }
        ]
        : [
          { title: 'ISNI', content: row.isni },
          {
            title: 'Affiliations',
            content: affiliations.length > 0 ? affiliations.map(item =>
              <div className='infoTextContent'>{item}</div>
            ) : '-'
          },
          {
            title: '',
            content: ''
          },
          {
            title: 'IPI/CAE',
            content: row.ipiCae ? row.ipiCae : '-'
          }
        ]
    )
  }
getClassName =(row)=>{
  let className = ''
  if (this.props.songPermissions && row.contributionType === 'song') {
    className = this.props.songPermissions.create || this.props.isProjectOwner ? '' : 'item-ghosted'
  } else {
    className = this.props.canUpdate ? '' : 'item-ghosted'
  }
  return className
}

getActions = (() => {
  let actions = []
  if (this.props.refetch) {
    actions.push({
      name: 'reload',
      iconDescription: 'Refresh',
      onClick: () => this.props.refetch
    })
  }

  if (actions.length === 0) {
    actions = false
  }
  return () => actions
})()
  getRowActions = (() => {
    let actions = []


    actions.push({
      itemText: 'Edit',
      className: (row)=> this.getClassName(row),
      onClick: (row) => {
        if (this.props.projectId && row.contributionType === 'recording' && this.props.recording) {
          return this.props.history.push(
            `/projects/${this.props.projectId}/recordings/${this.props.recording.id}/credits/${row.id}`
          )
        }
        if (this.props.projectId && row.contributionType === 'recording') {
          return this.props.history.push(
            `/projects/${this.props.projectId}/recordings/${row.contributionId}/credits/${row.id}`
          )
        }

        if (row.contributionType === 'session') {
          return this.props.history.push(
            `/projects/${this.props.projectId}/sessions/${row.contributionId}/credits/${row.id}`
          )
        }

        if (this.props.songId) {
          return this.props.history.push(
            `/songs/${this.props.songId}/credits/${row.id}/edit`
          )
        }

        if (this.props.recordingId && row.contributionType !== 'song') {
          return this.props.history.push(
            `/projects/${this.props.projectId}
            /recordings/${this.props.recording.id}/credits/${row.id}`
          )
        }

        if (row.contributionType === 'song') {
          return this.props.history.push(
            `/songs/${row.contributionId}/credits/${row.id}/edit` 
          )
        }

        if (this.props.session) {
          return this.props.history.push(`/projects/${this.props.projectId}/sessions/${this.props.session.id}/credits/${row.id}`)
        }

        return this.props.history.push(
          `/projects/${this.props.projectId}/credits/${row.id}`
        )
      }
    })


    actions.push({
      itemText: 'Delete',
      className: (row)=> this.getClassName(row),
      onClick: (row) => {
        this.props.alert(
          'Are you sure you want to permanently delete this credit?',
          {
            type: 'delete',
            buttonText: 'Yes',
            submitCallback: (close) => {
              this.props
                .deleteCredit({
                  variables: {
                    creditId: row.id
                  },
                  update: this.props.onDelete
                })
                .then(() => {
                  this.props.alert('The credit has been successfully deleted.')
                })
                .catch(() => {
                  this.props.alert('This credit could not be deleted.')
                })
              close()
            }
          }
        )
      }
    })


    return () => actions
  })()
  getActions = (() => {
    let actions = []
    if (this.props.refetch) {
      actions.push({
        name: 'restart',
        iconDescription: 'Refresh',
        onClick: () => this.props.refetch()
      })
    }

    if (actions.length === 0) {
      actions = false
    }
    return () => actions
  })()


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rows === prevState.originalRows) {
      return null
    }

    const rows = nextProps.rows.map((row) => {
      const { id, name, is_my, isni, ipiCae, affiliations, userId } = row.party
      return {
        id: row.id,
        name: is_my === true && userId === nextProps.user.id ? `${name} (My Party)` : name,
        partyId: id,
        isni: isni || '-',
        ipiCae: ipiCae || '-',
        cmo: affiliations.length
          ? affiliations.map((item) => item.affiliation.type === 'cmo' ? item.affiliation.name : '')
          : [],
        pro: affiliations.length
          ? affiliations.map((item) => item.affiliation.type === 'pro' ? item.affiliation.name : '')
          : [],
        contribution: nextProps.songId
          ? row.contribution.name
          : `${row.contributionType
            .charAt(0)
            .toUpperCase()}${row.contributionType.slice(1)} - ${
            row.contribution.name
          }`,
        role: row.role ? row.role.name : '',
        contributionType: row.contributionType,
        instrument: row.instrument ? row.instrument.name : '-',
        contributionId: row.contribution.id,
        split: row.split ? row.split : '-',
        featureType: row.featureType ? row.featureType : '-',
        party: row.party ? row.party : {}
      }
    })

    return {
      rows,
      originalRows: nextProps.rows
    }
  }

  renderRoute(partyUserId, userId, partyId, is_my) {
    if (partyUserId === userId && is_my) {
      return '/account/myParty'
    }
    return `/parties/${partyId}`
  }

  generateLink(row) {
    let route = this.renderRoute(row.party.userId, this.props.user.id, row.partyId, row.party.is_my)
    return route
  }

  render() {
    return (
      <ResponsiveTable
        headers={this.getHeaders()}
        {...this.props}
        expandable={true}
        rows={this.state.rows}
        linkColumn='name'
        generateLink={this.generateLink}
        showSkeleton={this.props.showSkeleton}
        showSearch={this.props.showSearch}
        pagination={this.props.pagination}
        isProjectList={this.props.isProjectList}
        rowActions={this.getRowActions()}
        getExpandedContent={this.getExpandedContent}
        actions={this.props.actions && this.getActions()}
        emptyText='No Credits'
      />
    )
  }
}

export default compose(
  withRouter,
  WithComments,
  WithAlertModal,
  graphql(DELETE_CREDIT, { name: 'deleteCredit' }),
  GetUser
)(CreditTable)

import React, { useState } from 'react'
import { compose } from 'react-apollo'
import Helmet from 'react-helmet-async'
import { withRouter } from 'react-router'
import Breadcrumb from '../components/Breadcrumb'
import CreatePagesHeader from '../components/CreatePagesHeader'
import CreditTable from '../components/CreditTable'
import Hero from '../components/Hero'
import SectionContainer from '../components/SectionContainer'
import GetSong from '../hocs/queries/GetSong'
import GetUser from '../hocs/queries/GetUser'
import * as permissions from '../utils/permissions'


const SongCredits = (props)=> {
  const [searchTerm, setSearchTerm] = useState('')

  const isSongOwner = props.song.userId === props.user.id

  const getTags = (permission)=>{
    const tags = []
    const permissionsKyes = Object.keys(permission)
    permissionsKyes.forEach(key => {
      if (permission[key] === true) {
        tags.push({
          tagName: key.charAt(0).toUpperCase() + key.slice(1)
        })
      }
    })
    return tags
  }
  const permissionsApplied = (row = props.song)=>{
    const { recordings } = row
    const fullAccess = {
      create: true,
      update: true,
      read: true,
      delete: true,
      download: true,
      comments: true

    }
    if (recordings.length === 0) {
      return fullAccess
    }
    const projectIds = [...new Set(recordings.map(item => item.projectId))]
    if (props.song.userId === props.user.id) {
      return fullAccess
    }
    const AllPermissions = []
    projectIds.forEach(async item=>{
      const permission = permissions.can(item, 'song')
      if (Object.keys(permission).length > 0) {
        AllPermissions.push(permission)
      }
    })
    let getPermission = {}
    AllPermissions.forEach(item =>{
      if (Object.keys(getPermission).length === 0) {
        getPermission = { ...item }
      } else {
        if (getPermission.create === false) {
          getPermission.create = item.create
        }
        if (getPermission.update === false) {
          getPermission.update = item.update
        }
        if (getPermission.read === false) {
          getPermission.read = item.read
        }
        if (getPermission.download === false) {
          getPermission.download = item.download
        }
        if (getPermission.delete === false) {
          getPermission.delete = item.delete
        }
        if (getPermission.comments === false) {
          getPermission.comments = item.comments
        }
      }
    })
    return getPermission
  }

  return (
    <>
        <Helmet>
          <title>{props.song.title} | Credits</title>
        </Helmet>
        <main className='main'>
          <Hero
            subtitle={props.song.title + ' Credits'}
            showSearch={true}
            getSearchTerm={setSearchTerm}
            btnText={permissionsApplied().create ? 'Add' : null }
            btnOnClick={() =>
              props.history.push(`/songs/${props.song.id}/credits/add`)
            }
          >
            <Breadcrumb items={[
              {
                href: `/songs`,
                name: 'Songs'
              },
              {
                href: `/songs/${props.song.id}`,
                name: props.song.title
              },
              {
                href: `/songs/${props.song.id}/credits`,
                name: 'Song Credits'
              }
            ]} />
          </Hero>
          <CreatePagesHeader
            subtitle='Song Credits'
            withDivider={false}
            tags={!isSongOwner ? getTags(permissions.filterPermissions(permissionsApplied())) : []}
          />

          <div className='sections-container project-files'>
            <SectionContainer
              id='credits'>
              <CreditTable
                rows={props.song.credits || []}
                showSearch={false}
                searchTerm={searchTerm}
                songId={props.song.id}
                pagination
                actions
                refetch={props.refetchSong}
                permissions={permissionsApplied()}
                canUpdate={permissionsApplied().update}
                canDelete={permissionsApplied().delete}
                onDelete={props.refetchSong}
                rowActions={permissionsApplied().canCreate} />
            </SectionContainer>
          </div>
        </main>
      </>
  )
}

export default compose(
  GetUser,
  GetSong,
  withRouter
)(SongCredits)

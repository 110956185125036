import React from 'react'
import Helmet from 'react-helmet-async'
import { Query } from 'react-apollo'

import { GET_FAVOURITES } from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'

import ProjectTileList from '../components/ProjectTileList'

import { getUserFavouritesByType } from '../utils/selectors'

// Our Base Components
import FileTable from '../components/FileTable'
import Hero from '../components/Hero'
import RecordingTable from '../components/RecordingTable'
import SectionContainer from '../components/SectionContainer'
import SessionTable from '../components/SessionTable'
import CreatePagesHeader from '../components/CreatePagesHeader'
import SongTileList from '../components/SongTileList'
import PlayListTileList from '../components/PlayListTileList'
import EmptySection from '../components/EmptySection'

const flattenFavoured = favourites =>
  favourites.map(favourite => favourite.favoured)

class Favourites extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }

  getSearchTerm = value => {
    this.setState({
      searchTerm: value
    })
  };

  searchFilter = (data, key) => {
    if (!data || !key) {
      return []
    }

    let filteredArray = []
    if (data && data.length) {
      data.map(i => {
        if (!i) {
          return null
        }
        const title = i[key] ? i[key].toLowerCase() : ''
        if (title.includes(this.state.searchTerm.trim().toLowerCase())) {
          filteredArray.push(i)
        }
        return null
      })
      return filteredArray
    }
    return []
  };

  render() {
    const favourites = getUserFavouritesByType(this.props.user)

    return (
      <>
        <Helmet>
          <title>Favorites</title>
        </Helmet>
        <main className='main favourites'>
          {/* Page Header */}
          <Hero
            src='ph.hero.projects.jpg'
            alt='Alt text'
            subtitle='Favorites'
            showSearch
            getSearchTerm={this.getSearchTerm}
          />

          {/* Projects  */}

          <Query
            query={GET_FAVOURITES}
            variables={{
              resourceType: 'project',
              count: 100,
              orderField: 'created_at',
              orderDirection: 'desc'
            }}
            fetchPolicy='cache-and-network'
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              const projects = flattenFavoured(
                data.getUserFavourites ? data.getUserFavourites.data : []
              )
              let filterProjects = this.searchFilter(projects, 'name')

              return (
                <div className='favProject favSection'>
                  <div className='projects-title-wrapper'>
                    <CreatePagesHeader subtitle='Projects' refetch={refetch} />
                  </div>
                  <div className='sections-container'>
                    <SectionContainer id='projects'>
                      {filterProjects.length > 0 ? (
                        <ProjectTileList
                          projects={filterProjects || []}
                          favourites={favourites.project || []}
                          user={this.props.user}
                          refetch={refetch}
                          favouritePage={true}
                          showArtist={true}
                          showFavouriteIcon={true}
                        />
                      ) : (
                        <EmptySection content='No Projects' />
                      )}
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>

          {/* Songs */}
          <Query
            query={GET_FAVOURITES}
            variables={{
              resourceType: 'song',
              count: 100,
              orderField: 'created_at',
              orderDirection: 'desc'
            }}
            fetchPolicy='cache-and-network'
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              const songs = flattenFavoured(
                data.getUserFavourites ? data.getUserFavourites.data : []
              )
              let filteredSongs = this.searchFilter(songs, 'name')

              return (
                <div className='favSong favSection'>
                  <div className='projects-title-wrapper'>
                    <CreatePagesHeader subtitle='Songs' refetch={refetch} />
                  </div>
                  <div className='sections-container'>
                    <SectionContainer id='songs'>
                      {filteredSongs.length > 0 ? (
                        <SongTileList
                          songs={filteredSongs || []}
                          refetch={refetch}
                          user={this.props.user}
                          favouritePage={true}
                          showFavouriteIcon={true}
                        />
                      ) : (
                        <EmptySection content='No Songs' />
                      )}
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>

          {/* Playlists */}
          <Query
            query={GET_FAVOURITES}
            variables={{
              resourceType: 'playlist',
              count: 100,
              orderField: 'created_at',
              orderDirection: 'desc'
            }}
            fetchPolicy='cache-and-network'
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              const playlists = flattenFavoured(
                data.getUserFavourites ? data.getUserFavourites.data : []
              )
              let filteredPlaylists = this.searchFilter(playlists, 'title')

              return (
                <div className='favPlaylist favSection'>
                  <div className='projects-title-wrapper'>
                    <CreatePagesHeader subtitle='Playlists' refetch={refetch} />
                  </div>
                  <div className='sections-container'>
                    <SectionContainer id='playlists'>
                      {filteredPlaylists.length > 0 ? (
                        <PlayListTileList
                          playlists={filteredPlaylists || []}
                          refetch={refetch}
                          withOptions={false}
                          user={this.props.user}
                        />
                      ) : (
                        <EmptySection content='No Playlists' />
                      )}
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>

          {/* Recordings */}

          <Query
            query={GET_FAVOURITES}
            variables={{
              resourceType: 'recording',
              count: 100,
              orderField: 'created_at',
              orderDirection: 'desc'
            }}
            fetchPolicy='cache-and-network'
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              const recordings = flattenFavoured(
                data.getUserFavourites ? data.getUserFavourites.data : []
              )
              let filteredRecordings = this.searchFilter(recordings, 'name')

              return (
                <div className='favRecording favSection'>
                  <CreatePagesHeader
                    name='recordingsSearchTerm'
                    subtitle='Recordings'
                    refetch={refetch}
                  />
                  <div className='sections-container'>
                    <SectionContainer id='recordings'>
                      <RecordingTable
                        nameCellClass='fav-recordings-table'
                        rows={filteredRecordings || []}
                        showSearch={false}
                        searchTerm={this.state.recordingsSearchTerm}
                        favourite
                        favouritePage
                        favourites={favourites.recording || []}
                        refetchTable={refetch}
                      />
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>

          {/* Sessions */}

          <Query
            query={GET_FAVOURITES}
            variables={{
              resourceType: 'session',
              count: 100,
              orderField: 'created_at',
              orderDirection: 'desc'
            }}
            fetchPolicy='cache-and-network'
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              const sessions = flattenFavoured(
                data.getUserFavourites ? data.getUserFavourites.data : []
              )
              let filteredSessions = this.searchFilter(sessions, 'name')

              return (
                <div className='favSection favSession'>
                  <CreatePagesHeader
                    name='sessionsSearchTerm'
                    subtitle='Sessions'
                    refetch={refetch}
                  />
                  <div className='sections-container'>
                    <SectionContainer id='sessions'>
                      <SessionTable
                        nameCellClass='fav-sessions-table'
                        rows={filteredSessions || []}
                        showSearch={false}
                        searchTerm={this.state.sessionsSearchTerm}
                        favourite
                        favouritePage
                        favourites={favourites.session || []}
                        refetchTable={refetch}
                      />
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>

          {/* Files */}

          <Query
            query={GET_FAVOURITES}
            variables={{
              resourceType: 'file',
              count: 100,
              orderField: 'created_at',
              orderDirection: 'desc'
            }}
            fetchPolicy='cache-and-network'
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              const files = flattenFavoured(
                data.getUserFavourites ? data.getUserFavourites.data : []
              )
              let filteredFiles = this.searchFilter(files, 'name')

              return (
                <div className='favFiles'>
                  <CreatePagesHeader
                    name='filesSearchTerm'
                    subtitle='Files'
                    refetch={refetch}
                  />

                  <div className='sections-container'>
                    <SectionContainer id='files'>
                      <FileTable
                        nameCellClass='fav-file-table '
                        rows={filteredFiles || []}
                        fileFavourites={favourites.file || []}
                        showSearch={false}
                        searchTerm={this.state.filesSearchTerm}
                        folderFavourites={[]}
                        favourite
                        rowActions={false}
                        favouritePage
                        pagination={false}
                        batchActions={false}
                        refetchTable={refetch}
                      />
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>
        </main>
      </>
    )
  }
}

export default GetUser(Favourites)

export const debounced = (delay, fn) => {
  let timerId
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}

export const aliasCountryName = item => {
  return { ...item, countryName: item.country ? item.country.name : '' }
}

export const aliasAffiliationsName = item => {
  if (item.publisher) {
    const country = item.country ? item.country.name : 'Worldwide'
    return { ...item,
      name: item.publisher ? item.publisher + ' - ' + country : '',
      type: item.publisher ? 'Publisher' : ''
    }
  }
  return { ...item,
    name: item.affiliation.name ? item.affiliation.name : '',
    type: item.affiliation.type ? item.affiliation.type.toUpperCase() : ''
  }
}

export const generateRandomNumber = length => {
  const add = 1
  let max = 12 - add

  if (length > max) {
    return generateRandomNumber(max) + generateRandomNumber(length - max)
  }

  max = Math.pow(10, length + add)
  const min = max / 10
  const number = Math.floor(Math.random() * (max - min + 1)) + min

  return `${number}`.substring(add)
}

export const windowLocationNewWindow = href => {
  let a = document.createElement('a')
  a.href = href
  a.setAttribute('target', '_blank')
  a.click()
}

const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const convertBytes = ({ bytes }) => {
  let l = 0; let n = parseInt(bytes, 10) || 0

  while (n >= 1024 && ++l) {
    n = n / 1000
  }

  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l])
}

export const miliSecondtoHMS = (ms) => {
  return new Date(parseInt(ms)).toISOString().slice(11, 19) || 0
}
export const durationMin = (time) => {
  const date = new Date(null)
  date.setSeconds(time)
  return date.toISOString().substr(11, 8)
}


export const nanoSecondsToHMS = (duration) => {
  if (duration === null || duration === undefined) {
    return 0
  }
  const miliSecond = duration / 1000000
  let seconds = parseInt((miliSecond / 1000) % 60)
  let minutes = parseInt((miliSecond / (1000 * 60)) % 60)
  let hours = parseInt((miliSecond / (1000 * 60 * 60)) % 24)

  hours = (hours < 10) ? '0' + hours : hours
  minutes = (minutes < 10) ? '0' + minutes : minutes
  seconds = (seconds < 10) ? '0' + seconds : seconds
  return hours + ':' + minutes + ':' + seconds
}


const padTo2Digits = (num)=> {
  return num.toString().padStart(2, '0')
}

export const calcPlaylistTotalDuration = (files) => {
  if (files === null || files === undefined) {
    return 0
  }
  let totalDuration = 0

  files.forEach(({ duration }) => {
    totalDuration = parseInt(totalDuration, 10) + parseInt(duration, 10)
  })
  totalDuration = totalDuration / 1000000
  let seconds = Math.floor(totalDuration / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  minutes = minutes % 60
  hours = hours % 24
  if (hours > 0) {
    return `${padTo2Digits(hours)} ${hours > 1 ? 'hours' : 'hour'} 
    ${padTo2Digits(minutes)} min ${padTo2Digits(seconds)} sec`
  }
  if (minutes > 0) {
    return `${padTo2Digits(minutes)} min ${padTo2Digits(seconds)} sec`
  }
  if (seconds > 0) {
    return `${padTo2Digits(seconds)} sec`
  }

  return '0 sec'
}

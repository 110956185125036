import React from 'react'
import { withRouter } from 'react-router'
import { DateTime } from 'luxon'
import { compose, graphql } from 'react-apollo'

import ResponsiveTable from './ResponsiveTable'
import { DELETE_SONG } from '../graphql/mutations'
import WithAlertModal from '../hocs/WithAlertModal'
import SectionContainer from './SectionContainer'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import GetUser from '../hocs/queries/GetUser'
import WithComments from '../hocs/WithComments'
import * as permissions from '../utils/permissions'
import GetProjects from '../hocs/queries/GetProjects'

class SongTable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.getRowActions = this.getRowActions.bind(this)
    this.formatTitle = this.formatTitle.bind(this)
  }


  formatTitle(title, data) {
    return data.userId === this.props.user.id ? title : `${title}`
  }

  headers = [
    {
      key: 'title',
      header: 'Title',
      formatter: (title, data) => this.formatTitle(title, data)
    },
    {
      key: 'subtitle',
      header: 'Subtitle'
    },
    {
      key: 'iswc',
      header: 'ISWC',
      formatter: (data) => (data ? data : '-')
    },
    {
      key: 'createdAt',
      header: 'Added',
      formatter: (data) => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
    }
  ]

  permissionsApplied =(row)=>{
    const { recordings, fromRecording } = row
    if (!fromRecording) {
      return 'not-ghosted'
    }
    const projectIds = [...new Set(recordings.map(item => item.projectId))]
    const AllPermissions = []
    const classes = []
    projectIds.forEach(async item=>{
      const permission = permissions.can(item, 'song')
      if (Object.keys(permission).length > 0) {
        AllPermissions.push(permission)
      }
    })

    AllPermissions.forEach(({ create }) =>{
      if (create !== true) {
        classes.push('ghosted')
      } else {
        classes.push('non-ghosted')
      }
    })
    return classes.includes('non-ghosted') ? 'non-ghosted' : 'ghosted'
  }

  getRowActions = (() => {
    const actions = [

    ]
    return () => actions
  })()

  getExpandedContent = (row) => {
    return ([
      {
        title: 'Type',
        content: row.type.name
      },
      {
        title: 'Title Alt',
        content: row.titleAlt
      },
      {
        title: 'Subtitle Alt',
        content: row.subtitleAlt
      },
      {
        title: 'Comments',
        content: row.description,
        requiresLargeContainer: false
      }
    ]
    )
  }

  generateLink = (row) => {
    return `/songs/${row.id}`
  }

  render() {
    return (
      <div>
        <SectionContainer>
          <ResponsiveTable headers={this.headers}
            {...this.props}
            expandable={true}
            linkColumn='title'
            generateLink={this.generateLink}
            pagination={this.props.pagination !== false}
            showSearch={this.props.showSearch !== false}
            rowActions={this.getRowActions()}
            getExpandedContent={this.getExpandedContent} />
        </SectionContainer>
      </div>
    )
  }
}

export default compose(
  GetUser,
  WithComments,
  withRouter,
  WithAlertModal,
  WithUploads,
  WithUploadModal,
  GetProjects,
  graphql(DELETE_SONG, { name: 'deleteSong' })
)(SongTable)


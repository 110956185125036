import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    password: Field()
      .string()
      .required({ message: Errors.Required }),
    passwordConfirmation: Field()
      .string()
      .equals(['#password'], { message: `${Errors.Equals} Password` })
  },
  { verbose: true, async: true }
)

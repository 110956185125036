import React from 'react'
import { compose, Mutation } from 'react-apollo'

import { GET_PARTY, GET_PARTY_ARTIST } from '../../../graphql/queries'
import { UPDATE_PARTY_ADDRESS } from '../../../graphql/mutations'

import GetUser from '../../../hocs/queries/GetUser'
import PartyAddressForm from '../../../components/PartyAddressForm'
import SectionContainer from '../../../components/SectionContainer'

class PartyAddressEdit extends React.Component {
  render() {
    return (
      <>
        <main className='main'>
          <div className='mainContainer'>

            <SectionContainer>
              <Mutation
                mutation={UPDATE_PARTY_ADDRESS}
                update={(cache, { data: { updatePartyAddress } }) => {
                  const query = this.props.party.is_my ? GET_PARTY_ARTIST : GET_PARTY
                  const { getParty } = cache.readQuery({
                    query: query,
                    variables: {
                      id: this.props.party.id
                    }
                  })
                  if (getParty) {
                    const party = { ...getParty }
                    party.addresses = [...party.addresses].map(address => {
                      if (address.id === this.props.partyAddress.id) {
                        return updatePartyAddress
                      }

                      return address
                    })
                    cache.writeQuery({
                      query: query,
                      variables: {
                        id: this.props.party.id
                      },
                      data: {
                        getParty: party
                      }
                    })
                  }
                }}>
                {(updatePartyAddress, { loading, error }) => (
                  <PartyAddressForm {...this.props.partyAddress}
                    onSubmit={(state) => {
                      const {
                        closeModal,
                        party,
                        onSubmitEditAddress,
                        partyAddress,
                        partyRefetch
                      } = this.props
                      if (!party.id) {
                        onSubmitEditAddress(state, partyAddress.id)
                        closeModal()
                        return
                      }
                      closeModal()
                      let data = { ...state }
                      data.id = this.props.partyAddress.id
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      updatePartyAddress({
                        variables: data
                      }).then(()=>{
                        partyRefetch()
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
)(PartyAddressEdit)

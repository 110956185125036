import React from 'react'
import { Mutation } from 'react-apollo'
import { Modal } from 'carbon-components-react'
import { DateTime } from 'luxon'

import { CREATE_RECORDING } from '../graphql/mutations'
import Validator from '../utils/Validator'
import RecordingValidator from '../validators/Recording'
import RecordingForm from './RecordingForm'

import ApolloClient from '../utils/Apollo'
import {
  GET_RECORDING_TYPES
} from '../graphql/queries'


const initialState = {
  recordingTypeId: '',
  recordingTypeName: '',
  type: {},
  recordingTypeUserDefinedValue: '',
  version: '',
  songId: '',
  songTitle: '',
  song: {},
  partyId: '',
  partyName: '',
  party: {},
  isrc: '',
  recordedOn: '',
  mixedOn: '',
  duration: '',
  languageId: '',
  language: {},
  keySignature: '',
  timeSignature: '',
  tempo: '',
  description: '',
  initialErrors: {},
  errors: {},
  userDefinedRecordingTypes: [],
  userDefinedVersionTypes: []
}

class CreateRecordingModal extends Validator {
    vaidator = RecordingValidator
      state = { ...initialState }

      constructor(props) {
        super(props)

        this.handleSongChange = this.handleSongChange.bind(this)
        this.handleSongBlur = this.handleSongBlur.bind(this)
        this.handlePartyChange = this.handlePartyChange.bind(this)
        this.handlePartyBlur = this.handlePartyBlur.bind(this)
        this.handleRecordedOnChange = this.handleRecordedOnChange.bind(this)
        this.handleMixedOnChange = this.handleMixedOnChange.bind(this)
        this.getRecordingTypeOptions = this.getRecordingTypeOptions.bind(this)
        this.handleRecordingTypeChange = this.handleRecordingTypeChange.bind(this)
        this.handleRecordingTypeBlur = this.handleRecordingTypeBlur.bind(this)
        this.handleLanguageChange = this.handleLanguageChange.bind(this)
        this.handleLanguageBlur = this.handleLanguageBlur.bind(this)

        this.validator = RecordingValidator

        this.state.recordingTypeId = props.type ? props.type.id : ''
        this.state.recordingTypeName = props.type ? props.type.name : ''
        this.state.recordingTypeUserDefinedValue = props.typeUserDefinedValue || ''
        if (this.state.recordingTypeId) {
          this.state.type = {
            value: this.state.recordingTypeId,
            label: this.state.recordingTypeName
          }
        }

        this.state.songId = props.song ? props.song.id : ''
        this.state.songTitle = props.song ? props.song.title : ''
        if (this.state.songId) {
          this.state.song = {
            value: this.state.songId,
            label: this.state.songTitle
          }
        }

        this.state.partyId = props.party ? props.party.id : ''
        this.state.partyName = props.party ? props.party.name : ''
        if (this.state.partyId) {
          this.state.party = {
            value: this.state.partyId,
            label: this.state.partyName
          }
        }

        this.state.languageId = props.language ? props.language.id : ''
        this.state.languageName = props.language ? props.language.name : ''
        if (this.state.languageId) {
          this.state.language = {
            value: this.state.languageId,
            label: this.state.languageName
          }
        }

        this.state.name = props.name || ''
        this.state.subtitle = props.subtitle || ''
        this.state.version = props.version || ''
        this.state.isrc = props.isrc || ''
        this.state.recordedOn = props.recordedOn ?
          DateTime.fromSQL(props.recordedOn, { zone: 'utc' }).toFormat('LL/dd/yyyy') : ''
        this.state.mixedOn = props.mixedOn ?
          DateTime.fromSQL(props.mixedOn, { zone: 'utc' }).toFormat('LL/dd/yyyy') : ''
        this.state.duration = props.duration || ''

        if (this.state.duration) {
          const date = new Date(null)
          date.setSeconds(this.state.duration)
          this.state.duration = date.toISOString().substr(11, 8)
        }

        this.state.keySignature = props.keySignature || ''
        this.state.timeSignature = props.timeSignature || ''
        this.state.tempo = props.tempo || ''
        this.state.description = props.description || ''
      }


      handleSongChange(option) {
        let name = this.state.name
        if (this.state.name.length < 1) {
          name = option.label
        }

        this.setState({
          ...this.state,
          songId: option.value,
          song: option,
          name: name,
          errors: {
            ...this.state.errors,
            songId: undefined // eslint-disable-line no-undefined
          }
        })
      }

      handleSongBlur() {
        this.handleDirty({
          target: {
            name: 'songId',
            value: this.state.songId
          }
        })
      }

      handlePartyChange(option) {
        this.setState({
          ...this.state,
          partyId: option.value,
          party: option,
          errors: {
            ...this.state.errors,
            partyId: undefined // eslint-disable-line no-undefined
          }
        })
      }

      handlePartyBlur() {
        this.handleDirty({
          target: {
            name: 'partyId',
            value: this.state.partyId
          }
        })
      }

      handleRecordedOnChange(full, formatted) {
        this.setState({
          ...this.state,
          recordedOn: formatted,
          errors: {
            ...this.state.errors,
            recordedOn: undefined // eslint-disable-line no-undefined
          }
        })
      }

      handleMixedOnChange(full, formatted) {
        this.setState({
          ...this.state,
          mixedOn: formatted,
          errors: {
            ...this.state.errors,
            mixedOn: undefined // eslint-disable-line no-undefined
          }
        })
      }

      handleRecordingTypeChange(option) {
        this.setState({
          ...this.state,
          recordingTypeId: option.value,
          type: option,
          errors: {
            ...this.state.errors,
            recordingTypeId: undefined // eslint-disable-line no-undefined
          }
        })
      }

      handleRecordingTypeBlur() {
        this.handleDirty({
          target: {
            name: 'recordingTypeId',
            value: this.state.recordingTypeId
          }
        })
      }

      handleLanguageChange(option) {
        this.setState((prevState) => ({
          languageId: option.value,
          language: option,
          errors: {
            ...prevState.errors,
            languageId: undefined // eslint-disable-line no-undefined
          }
        }))
      }

      handleLanguageBlur() {
        this.handleDirty({
          target: {
            name: 'languageId',
            value: this.state.languageId
          }
        })
      }

    getRecordingTypeOptions = inputValue =>
      new Promise(resolve => {
        ApolloClient.query({
          query: GET_RECORDING_TYPES,
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          let recordingTypes = []
          let userDefinedRecordingTypes = []
          data.getRecordingTypes.forEach(type => {
            if (!type.name.toLowerCase().includes(inputValue.toLowerCase())) {
              return
            }

            if (type.userDefined) {
              userDefinedRecordingTypes.push(type.id)
            }

            recordingTypes.push({
              value: type.id,
              label: type.name
            })
          })

          this.setState({
            ...this.state,
            userDefinedRecordingTypes
          })

          resolve(recordingTypes)
        })
      })

    render() {
      return (
        <Modal className='modal-scrollable create-recording-modal'
          open={this.props.open}
          modalHeading='Create Recording'
          passiveModal
          shouldSubmitOnEnter={true}
          selectorPrimaryFocus='.bx--text-input'
          onRequestClose={this.props.onRequestClose}>
          <div className='form'>
            <Mutation mutation={CREATE_RECORDING}>
              {(createRecording, { loading, error }) => (
                <RecordingForm
                  {...this.props}
                  showAll={this.props.showAll}
                  onSubmit={(state) => {
                    let data = { ...state }
                    Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                    createRecording({
                      variables: {
                        ...data,
                        projectId: this.props.projectId,
                        recordedOn: data.recordedOn ?
                          DateTime.fromFormat(data.recordedOn, 'LL/dd/yyyy').toISODate() : null,
                        mixedOn: data.mixedOn ?
                          DateTime.fromFormat(data.mixedOn, 'LL/dd/yyyy').toISODate() : null
                      }
                    }).then(response => {
                      this.props.onRecordingCreated(response.data.createRecording)
                      this.setState({ ...initialState })
                    }).catch(() => {
                      console.log('Something went wrong here.')
                    })
                  }}
                  loading={loading}
                  errors={error}
                  party={this.state.party}
                  project={this.props.project} />
              )}
            </Mutation>
          </div>
        </Modal>
      )
    }
}

export default CreateRecordingModal

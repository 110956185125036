import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { withApollo } from 'react-apollo'
import { compose, graphql, Query } from 'react-apollo'

import { Loading } from 'carbon-components-react'

import { GET_EVENT_LOGS_BY_PROJECT, GET_UNREAD_ACTIVITY } from '../graphql/queries'
import { DO_RESET_UNREAD_ACTIVITY } from '../graphql/mutations'
import { EVENT_LOG_CREATED_SUBSCRIPTION, USER_UNREAD_ACTIVITIES } from '../graphql/subscriptions'

import ActivityCount from '../components/ActivityCount'
import ActivityItem from '../components/ActivityItem'
import { memo } from 'react'
import ApolloClient from '../utils/Apollo'


let userUnreadActivitiesSubscriptionUnsubscribe = null

const activityIcon = <img src={require('../assets/images/electricBolt.svg')} alt='activity'/>


class RecentActivity extends React.PureComponent {
  state = {
    active: false,
    count: 0
  }

  constructor(props) {
    super(props)

    this.toggleActivityFeed = this.toggleActivityFeed.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  componentDidMount() {
    this.fetchData()
    this.interval = setInterval(this.fetchData, 5000)
  }

  fetchData = async () => {
    const { subscribeToMore, data } = await ApolloClient.query({
      query: GET_UNREAD_ACTIVITY,
      variables: {
        projectId: this.props.projectId
      },
      fetchPolicy: 'network-only'
    })
    this.subscribeToNewActivity(subscribeToMore)
    const count = data.getUnreadActivity ? data.getUnreadActivity.count : '0'
    this.setState({ count: count })
  }

  toggleActivityFeed(e) {
    e.preventDefault()
    this.setState(
      {
        ...this.state,
        active: !this.state.active
      },
      () => {
        if (this.state.active) {
          this.props.resetEventLogCount(this.props.projectId)
        }
      }
    )
  }

  componentWillUnmount() {
    if (userUnreadActivitiesSubscriptionUnsubscribe) {
      userUnreadActivitiesSubscriptionUnsubscribe()
    }

    userUnreadActivitiesSubscriptionUnsubscribe = null
    clearInterval(this.interval)
  }

  subscribeToNewActivity(subscribeToMore) {
    if (subscribeToMore) {
      userUnreadActivitiesSubscriptionUnsubscribe = subscribeToMore({
        document: USER_UNREAD_ACTIVITIES,
        variables: {
          projectId: this.props.projectId
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }

          if (this.state.active) {
            this.props.resetEventLogCount(this.props.projectId)

            return {
              ...prev,
              count: 0
            }
          }

          return {
            ...prev,
            count: subscriptionData.data.userUnreadActivities.count
          }
        }
      })
    }
  }

  render() {
    return (
      <>
        <button
          type='button'
          className='activityPaneButton'
          onClick={this.toggleActivityFeed}
        >
          <Query
            query={GET_UNREAD_ACTIVITY}
            variables={{
              projectId: this.props.projectId
            }}
            fetchPolicy='network-only'
          >
            {({ subscribeToMore, data }) => {
              this.subscribeToNewActivity(subscribeToMore)
              const count = data.getUnreadActivity ? data.getUnreadActivity.count : '0'
              return count
            }}
          </Query>
          {activityIcon}
        </button>
      <div className={'appActivity ' + (this.state.active ? ' appActivityActive' : '')}>
        <ActivityCount count={this.state.count} toggleActivityFeed={this.toggleActivityFeed} />
        <div className='appActivityFeed'>
          {this.state.active && (
            <Query
              query={GET_EVENT_LOGS_BY_PROJECT}
              variables={{
                projectId: this.props.projectId,
                page: 1
              }}
              fetchPolicy='cache-and-network'
            >
              {({ subscribeToMore, data, loading, error, fetchMore }) => (
                <ActivityItems
                  subscribeToNewActivity={() =>
                    subscribeToMore({
                      document: EVENT_LOG_CREATED_SUBSCRIPTION,
                      variables: {
                        projectId: this.props.projectId
                      },
                      updateQuery: (prev, { subscriptionData }) => {
                        if (!subscriptionData.data) {
                          return prev
                        }

                        return {
                          ...prev,
                          getEventLogsByProject: {
                            ...prev.getEventLogsByProject,
                            data: [
                              subscriptionData.data.eventLogCreated,
                              ...prev.getEventLogsByProject.data
                            ]
                          }
                        }
                      }
                    })
                  }
                  data={data}
                  loading={loading}
                  error={error}
                  fetchMore={fetchMore}
                />
              )}
            </Query>
          )}
        </div>
      </div>

  </>
    )
  }
}

export default compose(
  memo,
  withApollo,
  graphql(DO_RESET_UNREAD_ACTIVITY, {
    name: 'doResetUnreadActivity',
    props: data => {
      return {
        resetEventLogCount: projectId =>
          data.doResetUnreadActivity({
            variables: {
              projectId
            },
            update: cache => {
              const { getUnreadActivity } = cache.readQuery({
                query: GET_UNREAD_ACTIVITY,
                variables: {
                  projectId
                }
              })

              cache.writeQuery({
                query: GET_UNREAD_ACTIVITY,
                variables: {
                  projectId
                },
                data: {
                  getUnreadActivity: {
                    ...getUnreadActivity,
                    count: 0
                  }
                }
              })
            }
          })
      }
    }
  })
)(RecentActivity)

let eventLogSubscriptionUnsubscribe = null

class ActivityItems extends React.PureComponent {
  constructor(props) {
    super(props)

    this.renderScrollbarThumb = this.renderScrollbarThumb.bind(this)
    this.onScrollFrame = this.onScrollFrame.bind(this)
  }

  componentDidMount() {
    eventLogSubscriptionUnsubscribe = this.props.subscribeToNewActivity()
  }

  componentWillUnmount() {
    if (eventLogSubscriptionUnsubscribe) {
      eventLogSubscriptionUnsubscribe()
    }

    eventLogSubscriptionUnsubscribe = null
  }

  renderScrollbarThumb({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: `#B3B3B3`,
      borderRadius: '0px',
      size: 'small'

    }

    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  onScrollFrame({ scrollHeight, scrollTop, clientHeight }) {
    if (
      this.props.loading ||
      this.props.data.getEventLogsByProject.paginatorInfo.currentPage ===
        this.props.data.getEventLogsByProject.paginatorInfo.lastPage
    ) {
      return
    }

    const percScroll = (scrollTop / Math.max(0, scrollHeight - clientHeight)) * 100

    if (percScroll > 70 && percScroll < 100) {
      this.props.fetchMore({
        variables: {
          page: this.props.data.getEventLogsByProject.paginatorInfo.currentPage + 1
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev
          }

          return {
            getEventLogsByProject: {
              ...fetchMoreResult.getEventLogsByProject,
              data: [
                ...prev.getEventLogsByProject.data,
                ...fetchMoreResult.getEventLogsByProject.data
              ],
              paginatorInfo: fetchMoreResult.getEventLogsByProject.paginatorInfo
            }
          }
        }
      })
    }
  }

  render() {
    let items = []

    if (this.props.data && this.props.data.getEventLogsByProject) {
      items = this.props.data.getEventLogsByProject
        ? this.props.data.getEventLogsByProject.data
        : []
    }

    return (
      <Scrollbars
        style={{ height: '100%' }}
        renderThumbHorizontal={this.renderScrollbarThumb}
        renderThumbVertical={this.renderScrollbarThumb}
        onScrollFrame={this.onScrollFrame}
        ref='scrollbars'
      >
        {items.map(item => (
          <ActivityItem key={item.id} {...item} />
        ))}
        {items.length === 0 && !this.props.loading && (
          <p style={{ color: '#bbbbbb' }}>There has been no activity on this project.</p>
        )}

        {this.props.loading && (
          <p style={{ color: '#bbbbbb' }}>
            <Loading withOverlay={false} className='bx--loading--center' />
          </p>
        )}
      </Scrollbars>
    )
  }
}

import React from 'react'
import { Query } from 'react-apollo'
import { VALIDATE_DOWNLOAD } from '../../graphql/queries'

const ValidateDownload = WrappedComponent => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={VALIDATE_DOWNLOAD}
          fetchPolicy='network-only'
          variables={{
            uuid: this.props.match.params.token
          }}>
          {({ loading, error, data }) => {
            const download = data ? data.validateDownload : null

            return <WrappedComponent {...this.props} download={download} loading={loading} error={error} />
          }}
        </Query>
      )
    }
  }
}

export default ValidateDownload

import React from 'react'
import { Link } from 'react-router-dom'
import {
  Search,
  DataTable,
  DataTableSkeleton,
  OverflowMenu,
  OverflowMenuItem
} from 'carbon-components-react'
import { compose } from 'react-apollo'
import PropTypes from 'prop-types'

import CreateUserFavourite from '../hocs/mutations/CreateUserFavourite'
import DeleteUserFavourite from '../hocs/mutations/DeleteUserFavourite'

import SaveToggle from './SaveToggle'
import copyLinkIcon from '../assets/images/CopyLinkIcon.svg'
import ShareLink from '../hocs/mutations/ShareLink'
import WithAlertModal from '../hocs/WithAlertModal'
import DownIcon from '../assets/images/down.svg'
import UpdateSeqPlaylist from '../hocs/mutations/UpdateSeqPlaylist'

const {
  TableContainer,
  TableCell,
  Table,
  TableRow,
  TableExpandRow,
  TableExpandedRow,
  TableExpandHeader,
  TableSelectRow,
  TableSelectAll,
  TableBody
} = DataTable

const getIconsInOrder = (batchActions = []) => {
  const actions = []
  // This is the current order on Figma design
  const btnsOrder = ['share', 'add', 'downloads', 'duplicate', 'move', 'restore', 'trash', 'delete_forever']

  btnsOrder.forEach((key) => {
    const action = batchActions.find(batchAction => batchAction.name === key)
    if (action) {
      actions.push(action)
    }
  })
  return actions
}

class TableList extends React.PureComponent {
  state = {
    rows: [],
    rowsById: {},

    headersByKey: {},
    rerender: '',

    visibleRows: [],

    currentPage: 1,
    perPage: this.props.perPage ? this.props.perPage : 5,
    totalRows: 0,
    searchTerm: '',

    filter: null,
    sortColumn: this.props.sortColumn || null,
    sortDirection: this.props.sortDirection || 'NONE'
  }

  constructor(props) {
    super(props)
    // this.changeRowOrder = this.changeRowOrder.bind(this)
    this.getIndexClasses = this.getIndexClasses.bind(this)
    this.changePage = this.changePage.bind(this)
    this.changeFilter = this.changeFilter.bind(this)
    this.changeRowOrder = this.changeRowOrder.bind(this)
    this.getIndexClasses = this.getIndexClasses.bind(this)
    this.state.filter = this.props.filter ? this.props.filter : null
  }
  componentDidUpdate=(prevProps)=>{
    if (prevProps.searchTerm !== this.props.searchTerm) {
      this.setState({
        searchTerm: this.props.searchTerm
      }, ()=>this.changeFilter('', this.props.searchTerm))
    }
    if ((prevProps.rows.join('') !== this.props.rows.join(''))) {
      this.setState({
        rerender: 'rerender'
      })
    }
  }
  changeRowOrder({ moveRow, index }) {
    let curRow = this.state.rowsPage[index]
    let rows = this.state.rowsPage
    if (moveRow === 'up') {
      rows[index] = rows[index - 1]
      rows[index - 1] = curRow
    } else if (moveRow === 'down') {
      rows[index] = rows[index + 1]
      rows[index + 1] = curRow
    }
    let files = {}
    rows.map((cur, index)=>{
      files[`"${index}"`] = {
        fileId: parseInt(cur.id, 10),
        seq: index + 1
      }
      return null
    })
    this.props.updateSeqPlaylist({
      variables: {
        id: this.props.playlistId,
        files: {
          ...files
        }
      }
    }).then(()=> {
      window.location.reload()
    }
    )
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showSkeleton) {
      return null
    }

    if (prevState.rows === nextProps.rows) {
      return null
    }

    const rowsById = {}
    nextProps.rows.forEach(row => {
      rowsById[row.id] = row
    })

    const { filteredRows } = TableList.filterRows(
      nextProps.rows,
      prevState.filter,
      nextProps.headers,
      prevState.sortColumn,
      prevState.sortDirection
    )

    const totalRows = filteredRows.length

    let currentPage = prevState.currentPage
    if (prevState.currentPage * prevState.perPage > totalRows) {
      currentPage = 1
    }


    const rowsPage = nextProps.pagination
      ? filteredRows.slice(
        currentPage * prevState.perPage - prevState.perPage,
        currentPage * prevState.perPage
      )
      : [...filteredRows]

    const headersByKey = {}
    nextProps.headers.forEach(header => (headersByKey[header.key] = header))

    return {
      rows: nextProps.rows,
      visibleRows: filteredRows,

      rowsPage,
      rowsById,

      headersByKey,

      currentPage: currentPage,
      perPage: prevState.perPage,
      totalRows,

      filter: prevState.filter,
      sortColumn: prevState.sortColumn,
      sortDirection: prevState.sortDirection
    }
  }

  static filterRows(rows, filterText, filterColumns, sortColumn, sortDirection) {
    let filteredRows = rows

    if (filterText) {
      filteredRows = filteredRows.filter(row =>
        filterColumns.some(({ key }) => {
          try {
            const value = eval(`row.${key}`) // eslint-disable-line no-eval
            return value.toLowerCase().includes(filterText.toLowerCase())
          } catch (e) {
            return false
          }
        })
      )
    }

    if (sortColumn && sortDirection !== 'NONE') {
      filteredRows.sort((a, b) => {
        // eslint-disable-line no-unused-vars
        const aValue = eval(`a.${sortColumn}`) // eslint-disable-line no-eval
        const bValue = eval(`b.${sortColumn}`) // eslint-disable-line no-eval
        if (aValue === null) {
          return 1
        }
        if (bValue === null) {
          return -1
        }
        if (typeof aValue === 'boolean' || typeof bValue === 'boolean') {
          if (sortDirection === 'ASC') {
            return (aValue ? 1 : 0) - (bValue ? 1 : 0)
          }
          return (bValue ? 1 : 0) - (aValue ? 1 : 0)
        }
        if (typeof aValue === 'number' || typeof bValue === 'number') {
          if (sortDirection === 'ASC') {
            return parseInt(aValue, 10) - parseInt(bValue, 10)
          }
          return parseInt(bValue, 10) - parseInt(aValue, 10)
        }

        if (typeof aValue === 'object' || typeof bValue === 'object') {
          if (sortDirection === 'ASC') {
            return (aValue[0].email || '').localeCompare(bValue[0].email)
          }
          return (bValue[0].email || '').localeCompare(aValue[0].email)
        }

        if (sortDirection === 'ASC') {
          return (aValue || '').localeCompare(bValue)
        }
        return (bValue || '').localeCompare(aValue)
      })
    }
    return {
      filteredRows
    }
  }

  changePage({ page, pageSize }) {
    const rowsPage = this.state.visibleRows.slice(page * pageSize - pageSize, page * pageSize)

    this.setState({
      ...this.state,
      rowsPage,
      currentPage: page,
      perPage: pageSize
    })
  }

  changeFilter(e, value) {
    const { filteredRows } = TableList.filterRows(
      this.state.rows,
      (e.target ? e.target.value : value),
      this.props.headers,
      this.state.sortColumn,
      this.state.sortDirection
    )

    const rowsPage = filteredRows.slice(0, this.state.perPage)

    this.setState({
      ...this.state,
      filter: (e.target ? e.target.value : value),

      visibleRows: filteredRows,
      rowsPage,

      totalRows: filteredRows.length,
      page: 1
    })
  }

  handleSortBy({ key = this.state.sortColumn, sortDirection = this.state.sortDirection }) {
    let sortColumn = key
    const { filteredRows } = TableList.filterRows(
      this.state.rows,
      this.state.filter,
      this.props.headers,
      sortColumn,
      sortDirection
    )

    const rowsPage = this.props.pagination
      ? filteredRows.slice(
        this.state.currentPage * this.state.perPage - this.state.perPage,
        this.state.currentPage * this.state.perPage
      )
      : [...filteredRows]

    this.setState({
      sortDirection,
      sortColumn,
      rowsPage
    })
  }
  getTotalPages=()=>{
    const totalPages = Math.ceil(parseInt(this.state.totalRows, 10) / parseInt(this.state.perPage, 10))
    const pagesArray = []
    for (let i = 1; i <= totalPages; i++) {
      pagesArray.push(i)
    }
    return { totalPages, pagesArray }
  }

  displayActionHeader=()=>{
    return (this.props.pagination && this.state.totalRows > 0) || (this.props.batchActions && this.state.totalRows > 0)
  }

  getIndexClasses() {
    let className = ''
    if (this.props.__typename === 'Playlist' && this.props.editMode !== true) {
      className = 'playlistRowIndex'
    }
    if (this.props.editMode === true) {
      className = 'playlistRowIndexSwapicons'
    }
    return className
  }

  render() {
    let disableCopyLink = false
    return (
      <>
        {this.props.showSkeleton && (
          <DataTableSkeleton
            headers={this.props.headers.map(({ header }) => header)}
            rowCount={1}
            columnCount={this.props.headers.length}
          />
        )}
        {!this.props.showSkeleton && (
          <DataTable
            rows={this.state.rowsPage}
            headers={this.props.headers}
            render={({
              rows,
              headers,
              getRowProps,
              selectRow,
              getSelectionProps,
              getBatchActionProps,
              selectedRows
            }) => {
              let batchActionProps = {}
              this.cancelBatchActions = null

              if (this.props.batchActions) {
                batchActionProps = getBatchActionProps()
                this.cancelBatchActions = batchActionProps.onCancel
              }
              const sortHeader = headers.find(({ key })=> key === this.state.sortColumn)
              return (
                <TableContainer>
                  {this.props.showSearch !== false && (
                    <div className='table-search'>
                      <Search
                        labelText='Search'
                        placeHolderText='Search'
                        onChange={this.changeFilter}
                      />
                    </div>
                  )}
                  {this.props.wrapped && (
                    <div
                      className={
                        'columnedTable ' +
                        (this.props.batchActions ? 'columnedTable--with-batch-actions' : '')
                      }
                    >
                      {rows.map(row => {
                        let selectionProps = {}
                        if (this.props.batchActions) {
                          selectionProps = getSelectionProps({ row })
                        }
                        const favourite = this.props.favourite
                          ? this.props.isFavourite(this.state.rowsById[row.id])
                          : false

                        let actions = []
                        if (this.props.rowActions) {
                          this.props.rowActions.forEach((item, i) => {
                            if (
                              item.canBeApplied &&
                              item.canBeApplied(this.state.rowsById[row.id]) === false
                            ) {
                              return null
                            }
                            let classes = null

                            const { className } = item
                            if (className && typeof className === 'string') {
                              classes = item.className
                            } else if (className) {
                              classes = item.className(this.state.rowsById[row.id])
                            }

                            actions.push(
                              <OverflowMenuItem
                                key={`${row.id}_${i}`}
                                itemText={item.itemText}
                                className={classes ? classes : ''}
                                onClick={() => item.onClick(this.state.rowsById[row.id], batchActionProps.onCancel)}
                              />
                            )

                            return null
                          })
                        }

                        return (
                          <div className='columnedTable__section' key={row.id}>
                            {this.props.batchActions && (
                              <div className='columnedTable__row'>
                                <div className={`columnedTable__column select-row ${selectionProps.checked ? 'select-row--selected' : ''}`}>
                                  <label htmlFor={selectionProps.id}>Select Row</label>
                                  <input
                                    type='checkbox'
                                    checked={selectionProps.checked}
                                    onChange={selectionProps.onSelect}
                                    name={selectionProps.name}
                                    id={selectionProps.id}
                                  />
                                </div>
                              </div>
                            )}
                            {(this.props.favourite || this.props.rowActions) && (
                              <div className='columnedTable__row'>
                                {this.props.favourite &&
                                  (!this.props.canFavourite ||
                                    (this.props.canFavourite &&
                                      this.props.canFavourite(this.state.rowsById[row.id]))) && (
                                  <SaveToggle
                                    favourite={favourite}
                                    onClick={() => {
                                      const type = this.props.getFavouriteType(
                                        this.state.rowsById[row.id]
                                      )
                                      const id = row.resourceId ? row.resourceId : row.id

                                      if (favourite) {
                                        return this.props.deleteUserFavourite(id, type)
                                      }

                                      return this.props.createUserFavourite(id, type)
                                    }}
                                  />
                                )}
                                {actions.length > 0 && (
                                  <OverflowMenu>{actions.map(action => action)}</OverflowMenu>
                                )}
                              </div>
                            )}
                            {this.props.headers.map(header => {
                              const link = this.props.linkColumn
                                ? this.props.generateLink(this.state.rowsById[row.id])
                                : false
                              const value = header.formatter
                                ? header.formatter(
                                  this.state.rowsById[row.id][header.key],
                                  this.state.rowsById[row.id]
                                )
                                : this.state.rowsById[row.id][header.key]

                              let columnValue = value
                              if (header.key === this.props.linkColumn && link) {
                                if (typeof link === 'string') {
                                  columnValue = <Link onClick={()=>this.props.setFolderId ? this.props.setFolderId(this.state.rowsById[row.id]) : ''} to={link}>{value}</Link>
                                } else {
                                  columnValue = <button className='link' onClick={link}>{value}</button>
                                }
                              }

                              return (
                                <div className='columnedTable__row' key={`${row.id}_${header.key}`}>
                                  <div className='columnedTable__column'>{header.header}</div>
                                  <div className='columnedTable__column'>
                                    {columnValue}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  )}
                  {!this.props.wrapped && (
                    <>
                  {this.displayActionHeader() &&
                      <div className={`actions-header ${batchActionProps.shouldShowBatchActions ? 'actions-header--selected' : ''}`}>
                        {this.props.batchActions && <TableSelectAll {...getSelectionProps()} />}
                        {this.props.expandable && <TableExpandHeader />}
                        {this.props.favourite && <TableExpandHeader />}
                        {this.props.showIndex && <TableExpandHeader />}
                        {this.props.pagination && this.state.totalRows > 0 && (
                            <>
                            <div className='custom-pagination'>
                              <div className='pages-dropdown'>
                                <p className='pages-count'>
                                  {this.state.perPage}
                                </p>
                                <OverflowMenu
                                  renderIcon={()=> <img alt='' className='arrow-icon' src={require('../assets/images/cheveron-down.svg')}/>}
                                >
                                  {[10, 50, 100].map(pageSize=>(
                                    <OverflowMenuItem
                                      key={`asdv_${pageSize}`}
                                      itemText={pageSize}
                                      onClick={() => this.changePage({ page: 1, pageSize: pageSize })}
                                    />))}
                                </OverflowMenu>
                              </div>
                              <div className='pages-dropdown'>
                                <p className='pages-count'>
                                  {this.state.currentPage}-{this.getTotalPages().totalPages}
                                </p>
                                <OverflowMenu
                                  renderIcon={()=> <img alt='' className='arrow-icon' src={require('../assets/images/cheveron-down.svg')}/>}
                                >
                                  {this.getTotalPages().pagesArray && this.getTotalPages().pagesArray.map(page=>(
                                    <OverflowMenuItem
                                      key={`asdv_${page}`}
                                      itemText={page}
                                      onClick={() => this.changePage({ page, pageSize: this.state.perPage })}
                                    />))}
                                </OverflowMenu>
                              </div>
                              {!this.props.isProjectList && <div className='sorting-dropdown'>
                                <p className='sorting-title'>
                                  Sort by {sortHeader && sortHeader.header }
                                </p>
                                <OverflowMenu
                                  renderIcon={()=> <img alt='' className='arrow-icon' src={require('../assets/images/cheveron-down.svg')}/>}
                                >
                                  {headers && headers.map(({ key, header }, i)=>{
                                    return (
                                      <OverflowMenuItem
                                        key={`asdv_${i}`}
                                        itemText={header}
                                        onClick={() =>{
                                          this.handleSortBy({ key })
                                        }}
                                      />)
                                  })}
                                </OverflowMenu>
                              </div>}
                              {(this.props.sortByOrder) && <div className='sorting-dropdown'>
                                <p className='sorting-title'>
                                  {this.state.sortDirection === 'ASC' ? 'Asc' : this.state.sortDirection === 'DESC' ? 'Desc' : '' }
                                </p>
                                <OverflowMenu
                                  renderIcon={()=> <img alt='' className='arrow-icon' src={require('../assets/images/cheveron-down.svg')}/>}
                                >
                                  <OverflowMenuItem
                                    itemText='Asc'
                                    onClick={() =>{
                                      if (this.state.sortDirection !== 'ASC') {
                                        this.handleSortBy({ sortDirection: 'ASC' })
                                      }
                                    }}
                                  />
                                  <OverflowMenuItem
                                    itemText='Desc'
                                    onClick={() =>{
                                      if (this.state.sortDirection !== 'DESC') {
                                        this.handleSortBy({ sortDirection: 'DESC' })
                                      }
                                    }}
                                  />
                                </OverflowMenu>
                              </div>}
                            </div>
                            </>

                        )}
                        {(this.props.batchActions || this.props.actions) && <div className='batch-actions-wrapper'>
                          {batchActionProps.shouldShowBatchActions && <>
                              <div className='batch-action-item selection'>
                                {batchActionProps.totalSelected}  {batchActionProps.totalSelected > 1 ? 'items' : 'item' } selected
                              </div>
                            </>}
                          {this.props.actions && this.props.actions.map((action, i) => {
                            return (
                              <div
                                className='batch-action-item'
                                key={i}
                                title={action.iconDescription ? action.iconDescription : '' }
                                onClick={() => action.onClick()}
                              >
                                {action.name && <img alt='' className='batch-action-icons' src={require(`../assets/images/${action.name}.svg`) }/>}
                              </div>
                            )
                          })}
                          {batchActionProps.shouldShowBatchActions && getIconsInOrder(this.props.batchActions).map((action, i) => {
                            return (
                              <div
                                className='batch-action-item'
                                key={i}
                                title={action.iconDescription ? action.iconDescription : '' }
                                onClick={() => action.onClick(selectedRows, this.state.rowsById, batchActionProps.onCancel)}
                              >
                                <img alt='' className='batch-action-icons' src={require(`../assets/images/${action.name}.svg`)}/>
                              </div>
                            )
                          })}
                        </div>
                        }
                        {this.props.rowActions && <TableExpandHeader />}
                        {this.props.isFileTable && <TableExpandHeader />}
                      </div>
                  }
                    <Table className={` ${this.props.nameCellClass} ${this.props.getExpandedContent ? '' : 'without-expanded-content-table'}`}>
                      <TableBody>
                        {rows.map((row, index) => {
                          const favourite = this.props.favourite
                            ? this.props.isFavourite(this.state.rowsById[row.id])
                            : false

                          let actions = []
                          if (this.props.rowActions) {
                            const itemTextArray = []
                            this.props.rowActions.forEach(item => itemTextArray.push(item.itemText))
                            const menuItems = ['Edit', 'Delete', 'Generate Check-In Code', 'Remove' ]
                            this.props.rowActions.forEach((item, i) => {
                              if (
                                item.canBeApplied &&
                                item.canBeApplied(this.state.rowsById[row.id]) === false
                              ) {
                                return
                              }
                              let classes = null
                              const { className } = item
                              if (className && typeof className === 'string') {
                                classes = item.className
                              } else if (className) {
                                classes = item.className(this.state.rowsById[row.id])
                              }
                              if (this.props.locked && menuItems.includes(item.itemText)) {
                                actions.push(
                                  <OverflowMenuItem
                                    className='locked-menu'
                                    key={`${row.id}_${i}`}
                                    itemText={item.itemText}
                                    onClick={() => item.onClick(this.state.rowsById[row.id])}
                                  />
                                )
                              } else {
                                actions.push(
                                  <OverflowMenuItem

                                    key={`${row.id}_${i}`}
                                    itemText={item.itemText}
                                    className={classes ? classes : ''}
                                    onClick={() => item.onClick(this.state.rowsById[row.id])}
                                  />
                                )
                              }

                              return
                            })
                          }
                          let itemsArray = []
                          actions.forEach(({ props }) =>{
                            itemsArray.push(props.itemText + props.className)
                          })
                          if (itemsArray.includes('Copy Linkitem-ghosted')) {
                            disableCopyLink = true
                          } else {
                            disableCopyLink = false
                          }
                          const GenericRow = this.props.expandable ? TableExpandRow : TableRow

                          return (
                            <React.Fragment key={row.id}>
                              <GenericRow key={row.id} {...getRowProps({ row })}>
                                {this.props.batchActions && (
                                  <TableSelectRow className={` bx--data-table-v2--select-row ${this.props.__typename === 'Playlist' ? 'playlistRowSelector' : ''}`} {...getSelectionProps({ row })} />

                                )}

                                {this.props.favourite && (
                                  <TableCell>
                                    {(!this.props.canFavourite ||
                                      (this.props.canFavourite &&
                                        this.props.canFavourite(this.state.rowsById[row.id]))) && (
                                      <SaveToggle
                                        favourite={favourite}
                                        locked={this.props.locked}
                                        onClick={() => {
                                          if (this.props.locked) {
                                            this.props.alert('Project Credits are locked. Please unlock to edit.', { title: '   ', className: 'credits-alert' })
                                            return
                                          }
                                          const currentRow = this.state.rowsById[row.id]
                                          const type = this.props.getFavouriteType(currentRow)
                                          const id = currentRow.resourceId
                                            ? currentRow.resourceId
                                            : currentRow.id

                                          if (favourite) {
                                            return this.props.deleteUserFavourite(id, type)
                                          }

                                          return this.props.createUserFavourite(id, type)
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                )}
                                {
                                  this.props.showIndex &&
                                  <TableCell className={this.getIndexClasses()}>
                                    <div className='table-index-container'>
                                      { this.props.editMode &&
                                        <div className='change-order-container'>
                                          {
                                            index !== 0 && <img className='change-order-container--rotateIcon' src={DownIcon} height='15' width='15' alt='Arrow Up Icon' onClick={()=>this.props.changeRowOrder({ moveRow: 'up', index: index, rowsPage: this.state.rowsPage })}/>
                                          }
                                          {
                                            index !== (this.props.rows.length - 1) && <img src={DownIcon} height='15' width='15' alt='Arrow Down Icon' onClick={()=>this.props.changeRowOrder({ moveRow: 'down', index: index, rowsPage: this.state.rowsPage })}/>
                                          }
                                        </div>
                                      }

                                      <div className='table-index-container--number'>{index + 1}</div>
                                    </div>
                                  </TableCell>
                                }
                                {row.cells.map((cell, cellIndex) => {
                                  const value = this.state.headersByKey[cell.info.header].formatter
                                    ? this.state.headersByKey[cell.info.header].formatter(
                                      cell.value,
                                      this.state.rowsById[row.id]
                                    )
                                    : cell.value
                                  if (cell.info.header === this.props.linkColumn) {
                                    const link = this.props.generateLink(
                                      this.state.rowsById[row.id]
                                    )
                                    let columnValue = value

                                    if (link) {
                                      if (typeof link === 'string') {
                                        if (this.props.locked) {
                                          columnValue = (<Link to='#' className='locked' onClick={()=>{
                                            this.props.alert('Project Credits are locked. Please unlock to edit.', { title: '   ', className: 'credits-alert' })
                                          }}>{value}</Link>)
                                        } else {
                                          columnValue = <Link onClick={()=>this.props.setFolderId ? this.props.setFolderId(this.state.rowsById[row.id]) : ''} to={link}>{value}</Link>
                                        }
                                      } else {
                                        columnValue =
                                          (<div>
                                            {(this.props.bodyType === 'move' || this.props.bodyType === 'attach') ?
                                              <div className='move-item'>
                                                {(this.props.bodyType === 'move' || this.props.bodyType === 'attach') && (
                                                  <TableSelectRow {...getSelectionProps({ row })} className='move-radio' id={row.id} onSelect={(e) =>{
                                                    rows.map((item)=>{
                                                      if (item.id === row.id) {
                                                        selectRow(row.id)
                                                      } else if (item.isSelected) {
                                                        selectRow(item.id)
                                                      }
                                                      return this.props.onSelect(row.id, cell.value, e)
                                                    })
                                                  }} />
                                                )}
                                                <span className='move-item-name' onClick={()=>{
                                                  if (!row.isSelected && this.props.bodyType === 'move') {
                                                    link()
                                                  }
                                                }}>{value}</span>
                                              </div>
                                              :
                                              <button className='link row-value' onClick={link}>{value}</button>
                                            }
                                          </div>)
                                      }
                                      // first real column
                                      return (
                                        <TableCell key={cell.id}>
                                          <p className='row-header'>{this.state.headersByKey[cell.info.header].header}</p>
                                          <p className='row-value-name'>{columnValue}</p>

                                        </TableCell>
                                      )
                                    }
                                  }
                                  // all following columns
                                  return (<TableCell key={cell.id}>
                                    <p className='row-header'>{this.state.headersByKey[cell.info.header].header}</p>
                                    <p className={`${this.state.headersByKey[cell.info.header].header === 'Name' || (this.props.firstCellColor && cellIndex === 0) ? 'row-value-name' : 'row-value'}`}>
                                      {value}
                                    </p>
                                  </TableCell>)
                                })}
                                {(this.props.isFileTable) &&
                                 <TableCell key={`${row.id}_link`}>
                                   { row.cells[1].value === null ?
                                     <div className ='copy-link-void'/>
                                     : <img
                                       onClick={() => {
                                       // checks if link value in table row
                                         if (row.cells[1].value !== null) {
                                         // copies link to clipboard
                                           navigator.clipboard.writeText(row.cells[1].value)
                                           this.props.alert('A link to this file or folder has been copied to clipboard. You can now share it.')
                                         }
                                       }}
                                       src={copyLinkIcon}
                                       alt='icon'
                                       className={`${disableCopyLink ? 'copy-link-disabled' : ''} copy-link`}
                                     /> }
                                 </TableCell>}
                                {this.props.rowActions && (
                                  <TableCell width='1' key={`${row.id}_actions`}>
                                    {actions.length > 0 && (

                                      <OverflowMenu>
                                        {actions.map(action => action)}
                                      </OverflowMenu>
                                    )}
                                  </TableCell>
                                )}
                              </GenericRow>
                              {row.isExpanded && (
                                <TableExpandedRow className='expanded-content'>
                                  {this.props.getExpandedContent
                                    ? this.props.getExpandedContent(this.state.rowsById[row.id]).map((item, index)=>{
                                      return (
                                        <>
                                       {index === 0 && <TableCell className='expanded-row'/>}
                                        <TableCell colspan={index === (this.props.getExpandedContent(this.state.rowsById[row.id]).length - 1) ? 10 : '' } key={item.title}>
                                          <p className='row-header'>{item.title}</p>
                                          <p className='row-value'>{item.content}</p>
                                        </TableCell>
                                        </>
                                      )
                                    })
                                    : this.state.rowsById[row.id].expandedContent || ''}
                                </TableExpandedRow>
                              )}
                            </React.Fragment>
                          )
                        })}
                        {rows.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={10}>
                              <span style={{ color: 'white' }}>{this.props.emptyText}</span>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    </>
                  )}

                </TableContainer>
              )
            }}

          />
        )}
      </>
    )
  }
}

TableList.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      formatter: PropTypes.func
    })
  ).isRequired,

  // batchActions: PropTypes.arrayOf(PropTypes.shape({
  //   name: PropTypes.string.isRequired,
  //   onClick: PropTypes.func.isRequired
  // })),

  // actions: PropTypes.arrayOf(PropTypes.shape({
  //   icon: PropTypes.object.isRequired,
  //   iconDescription: PropTypes.string.isRequired,
  //   onClick: PropTypes.func.isRequired
  // })),

  pagination: PropTypes.bool,
  showSkeleton: PropTypes.bool,
  showSearch: PropTypes.bool,

  isFavourite: PropTypes.func,
  linkColumn: PropTypes.string,
  generateLink: PropTypes.func,
  getFavouriteType: PropTypes.func,

  emptyText: PropTypes.string
}

TableList.defaultProps = {
  emptyText: 'No results'
}

export default compose(
  CreateUserFavourite,
  DeleteUserFavourite,
  ShareLink,
  WithAlertModal,
  UpdateSeqPlaylist
)(TableList)

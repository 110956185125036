import React from 'react'
import { Button, TextInput } from 'carbon-components-react'
import { SelectASync } from './Select'
import Validator from '../utils/Validator'
import Affiliations from '../validators/Affiliations'
import ApolloClient from '../utils/Apollo'
import { GET_COUNTRIES } from '../graphql/queries'
import { DELETE_PUBLISHER } from '../graphql/mutations'
import { compose, graphql } from 'react-apollo'
import WithAlertModal from '../hocs/WithAlertModal'

let locationIpn = window.location.href.slice(window.location.href.search('IPN'))
let IPN = locationIpn.substring(4)
let info = localStorage.getItem('partyInfo')
  ? JSON.parse(localStorage.getItem('partyInfo'))
  : ''
let localUrl = window.location.pathname
let party = '/account/myParty'
let myParty = '/account/myParty'
let subCondition = localUrl === myParty ? myParty : ''
let windowUrl = localUrl === party ? party : subCondition

const initialState = {
  publisher: '',
  affiliationType: '',
  country: 'Worldwide',
  countryId: null,
  selectedAffiliation: '',
  description: '',
  errors: {},
  ipn: IPN,
  affiliationLabel: info ? info.affiliationLabel : '',
  territoryLabel: info ? info.territoryLabel : 'Worldwide'
}

export class CreatePublisher extends Validator {
  state = { ...initialState }

  getCountryOptions(input) {
    return new Promise((resolve) => {
      ApolloClient.query({
        query: GET_COUNTRIES,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let countries = [
          ...data.getCountries.map((country) => {
            if (
              country.name.toLowerCase().indexOf(input.toLowerCase()) === -1
            ) {
              return false
            }

            return {
              value: country.id,
              label: country.name
            }
          })
        ].filter(Boolean)
        countries = [...countries, {
          value: null,
          label: 'Worldwide'
        }]

        resolve(countries)
      })
    })
  }

  constructor(props) {
    super(props)
    // Functions
    this.handleChange = this.handleChange.bind(this)
    //
    this.validator = Affiliations
    this.state.selectedAffiliation = props.selectedAffiliation || ''
    this.state.affiliationType = info
      ? info.affiliationType
      : props.affiliationType || ''
    this.state.url = ''
    this.state.description = ''
  }

  submit = () => {
    const variables = {
      publisher: this.state.publisher,
      country_id: this.state.countryId,
      status: 'verified'
    }
    this.props.onSubmit(variables)


    if (IPN.length) {
      const nextURL = windowUrl
      const nextTitle = ''
      const nextState = { additionalInformation: '' }
      window.history.replaceState(nextState, nextTitle, nextURL)
    }
    localStorage.removeItem('partyInfo')
    this.setState({
      ...initialState,
      ipn: '',
      affiliationType: '',
      affiliationLabel: ''
    })
    initialState.ipn = ''
    initialState.affiliationLabel = ''
    info = ''
    IPN = ''
  }

  componentDidMount() {
    if (IPN.length) {
      const nextURL = windowUrl
      const nextTitle = ''
      const nextState = { additionalInformation: '' }
      window.history.replaceState(nextState, nextTitle, nextURL)
    }
  }

  handleChangeType = (ev) => {
    if (this.props.reserveId) {
      this.props.reserveId(ev.label)
    }
    if (IPN.length) {
      const nextURL = windowUrl
      const nextTitle = ''
      const nextState = { additionalInformation: '' }
      window.history.replaceState(nextState, nextTitle, nextURL)
    }

    if (this.state.ipn) {
      this.setState({
        affiliationType: ev.value,
        affiliationLabel: ev.label,
        ipn: ''
      })
      localStorage.removeItem('partyInfo')
      initialState.ipn = ''
      initialState.affiliationLabel = ''
      info = ''
      IPN = ''
    } else {
      this.setState({
        affiliationType: ev.value,
        affiliationLabel: ev.label,
        country: ev.value
      })
    }
  }

  handleChangePublisher = (ev) => {
    this.setState({
      publisher: ev.target.value
    })
  }
  cancelAddAffiliation = () => {
    this.setState({
      publisher: '',
      countryId: '',
      affiliationType: '',
      selectedAffiliation: '',
      affiliationLabel: '',
      territoryLabel: ''
    })
  }

  handleCountryChange(option) {
    this.setState({
      ...this.state,
      countryId: option.value,
      country: option,
      errors: {
        country: undefined // eslint-disable-line no-undefined
      }
    })
  }

  render() {
    return (
      <div className='form'>
        <div className='form'>
          <div className='formRow'>
            <TextInput
              id='publisher'
              name='publisher'
              labelText='Add Publisher'
              onChange={this.handleChangePublisher}
              onBlur={this.handleDirty}
              value={this.state.publisher}
            />
          </div>

          {this.state.publisher && (
                 <>
                    <SelectASync
                      labelText='Territory (if appliciable)'
                      id='countryId'
                      cacheOptions
                      defaultOptions
                      loadOptions={this.getCountryOptions}
                      onChange={(e) => this.handleCountryChange(e)}
                      defaultValue={this.state.country}
                      placeholder='Select from List...'
                      invalid={this.state.errors.countryId ? true : false}
                      invalidText={this.state.errors.countryId || ''}
                    />
                  </>
          )}
          <div className='affiliations-buttons-display'>
            <Button
              className='cancel-Affiliations-Button'
              type='button'
              onClick={()=> {
                if (this.state.publisher !== '') {
                  this.submit()
                } 
              }}
            >
              Add Publisher
            </Button>

          </div>
          <p style={{ margin: '10px 0' }}>
            {this.state.errors.graphQLErrors && this.state.errors.graphQLErrors[0].message}</p>
        </div>
      </div>
    )
  }
}

export default compose(
  WithAlertModal,
  graphql(DELETE_PUBLISHER, { name: 'deletePublisher' })
)(CreatePublisher)

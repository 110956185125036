import React from 'react'
import MoveCopyFileModal from '../components/MoveCopyFileModal'

const WithMoveCopyFileModal = WrappedComponent => {
  return class extends React.PureComponent {
    state = {
      open: false,
      action: '',
      files: [],
      projectId: null,
      folderId: null,
      onCancel: null,
      isProjectOwner: null,
      projectIds: null
    }

    constructor(props) {
      super(props)

      this.state.projectId = props.projectId
      this.state.folderId = props.folderId

      this.doOpen = this.doOpen.bind(this)
      this.doClose = this.doClose.bind(this)
    }

    doOpen(action, files, projectId, folderId, onCancel, isProjectOwner, projectIds) {
      this.setState({
        ...this.state,
        open: true,
        action,
        files,
        projectId,
        folderId,
        onCancel,
        isProjectOwner,
        projectIds
      })
    }

    doClose() {
      this.setState({
        ...this.state,
        open: false,
        action: '',
        files: [],
        projectId: null,
        folderId: null
      })
      if (this.state.onCancel) {
        this.state.onCancel()
      }
    }

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            moveCopyFileModal={{
              open: this.doOpen,
              close: this.doClose
            }}
          />
          {this.state.open &&
          <MoveCopyFileModal
            projectId={this.state.projectId}
            folderId={this.state.folderId}
            refetch={this.props.refetch}
            action={this.state.action}
            files={this.state.files}
            open={this.state.open}
            onCancel={this.state.onCancel}
            onRequestClose={this.doClose}
            isProjectOwner={this.state.isProjectOwner}
            projectIds={this.state.projectIds}
          />
          }
        </>
      )
    }
  }
}

export default WithMoveCopyFileModal

import React, { useState, useEffect } from 'react'
import { compose, Query } from 'react-apollo'

import GetPlatformImages from '../hocs/queries/GetPlatformImages'
import AppBranding from '../components/AppBranding'
import DownloadShareForm from '../components/DownloadShareForm'
import LoadingDots from '../components/ui/LoadingDots'
import PublicShareNavBar from '../components/PublicShareNavBar'
import { VALIDATE_LINK } from '../graphql/queries'

import ShowShareSocialIcons from '../components/ShowShareSocialIcons'
import GetCustomDownloadPagePublic from '../hocs/queries/GetCustomDownloadPagePublic'
import DownloadShareExpired from '../components/DownloadShareExpired'
import DownloadShareDownloaded from '../components/DownloadShareDownloaded'
import defaultDownloadImage from '../assets/images/veva-collect-default-download-image.jpg'

const Share = ({
  // platformImages,
  match: { params: {
    token
  } },
  customImages
}) => {
  const [progress, setProgress] = useState(0.2)
  const [showProgress, setShowProgress] = useState(true)
  const [downloadComplete, setDownloadComplete] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [precentageComplete, setPercentageComplete] = useState(0)


  const startProgress = () => {
    let nowProgress = 0.2
    const id = setInterval(() => {
      nowProgress = nowProgress + 0.2
      setProgress(nowProgress)
      if (nowProgress >= 1) {
        clearInterval(id)
      }
    }, 400)
  }

  const downloadFile = async (share) => {
    setShowProgress(false)
    var xhr = new XMLHttpRequest();
    xhr.open('GET', share.url, true);
    xhr.responseType = 'blob';

    xhr.onload = function() {
      if (this.readyState === 4 && this.status === 200) {
        var blob = new Blob([this.response], { type: 'application/octet-stream' });
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "veva.zip";
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    };

    xhr.addEventListener("progress", function(e) {
      if (e.lengthComputable) {
        var percentComplete = (e.loaded / e.total) * 100;
        setPercentageComplete(Math.ceil(percentComplete))
        if(percentComplete >= 100) {
          setDownloadComplete(true)
        }
      }
    });

    xhr.send();
  }

  useEffect(() => {
    startProgress()
  }, [])
  // Password form
  const [password, setPassword] = useState('')
  function handleDownloadShareFormSubmit({ password }) {
    setPassword(password)
  }
  return (

    <div className='login download-shared-files publicShare cs-direct-link'>
      <div className='loginBackground'>
        {customImages.image ? (
          <img src={customImages.image} alt='customization' />
        ) : (
          <>
            <img src={defaultDownloadImage} alt='background-img' />
            
          </>
        )}
      </div>

      <PublicShareNavBar />

      <div className='loginFormBackground'>
        <div className={`loginFormWrapper linkShare ${downloadComplete || isExpired ? 'direct-link-wrapper' : ''}` } >

          <AppBranding />

          <Query
            query={ VALIDATE_LINK }
            fetchPolicy='network-only'
            variables={{
              uuid: token,
              password
            }}
          >
            {({ loading, error, data }) => {
              const share = data.validateShareDirectLink || {}
              let feedbackMessage = ''

              // Something went wrong with the request itself.
              if (error) {
                return (
                  <p>
                    Something went wrong with our system and we couldn't retrieve your files.
                    Please try again later.
                  </p>
                )
              }

              // Let's get those files!
              if (share.url && showProgress && !downloadComplete) {
                setShowProgress(false)
                downloadFile(share)
              }
              setIsExpired(share.errors && share.errors.isExpired)
              // Message to user
              if (loading) {
                feedbackMessage = <LoadingDots />
              } else if (password && share.errors.isPasswordInvalid) {
                feedbackMessage = 'Invalid password.'
              } else if (share.errors.isExpired) {
                return (
                  <><div className='loginForm download-expired'>
                    <DownloadShareExpired />
                  </div><div className='link-share-icon'><ShowShareSocialIcons uuid={token} /></div></>
                )
              } else if (downloadComplete) {
                return (
                  <><div className='loginForm download-share'>
                    <DownloadShareDownloaded />
                  </div><ShowShareSocialIcons uuid={token} /></>
                )
              } else if (share.errors.isShareInvalid) {
                feedbackMessage = 'This link is invalid.'
              } else if (share.errors.isUserInvalid) {
                feedbackMessage = 'You don’t have access to these files.'
              } else if (share.url) {
                return (
                  <><div className="link-share-downloading">
                    {`Creating your zip file and downloading ${precentageComplete} %.`}
                  </div></>
                )
              }

              // Markup
              return (<>
                {share.isPasswordRequired && <div className='loginForm'>
                  <DownloadShareForm onSubmit={ handleDownloadShareFormSubmit } />
                </div>}
                <p className='feedback-text'>{feedbackMessage}</p>
                {(share.url && showProgress) ? <div>
                  {progress && <p className='progress-text'>{Math.min(100, Math.round(progress * 100))} % Zipping...</p>}
                </div> : null}

                <ShowShareSocialIcons uuid={token} />
              </>)
            }}
          </Query>

        </div>
      </div>
    </div>

  )
}

export default compose(
  GetPlatformImages,
  GetCustomDownloadPagePublic
)(Share)

import { graphql } from 'react-apollo'

import { GET_SESSION } from '../../graphql/queries'
import { DELETE_SESSION_RECORDING } from '../../graphql/mutations'

export default graphql(DELETE_SESSION_RECORDING, {
  name: 'deleteSessionRecording',
  props: data => {
    return {
      deleteSessionRecording: (sessionId, recordingId) =>
        data.deleteSessionRecording({
          variables: {
            sessionId: sessionId,
            recordingId: recordingId
          },
          update: (cache, { data: { deleteSessionRecording } }) => {
            const { getSession } = cache.readQuery({
              query: GET_SESSION,
              variables: {
                id: sessionId
              }
            })
            const session = { ...getSession }
            session.recordings = [...session.recordings].filter(
              recording => recording.id !== deleteSessionRecording.recordingId
            )
            cache.writeQuery({
              query: GET_SESSION,
              variables: {
                id: sessionId
              },
              data: {
                getSession: session
              }
            })
          }
        })
    }
  }
})

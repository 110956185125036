import React from 'react'
import Helmet from 'react-helmet-async'
import { Query } from 'react-apollo'

import GetUser from '../hocs/queries/GetUser'
import { GET_PARTIES } from '../graphql/queries'
import { sortPartiesByName } from '../utils/sortParties'

// Our Base Components
import Hero from '../components/Hero'
import PartyTable from '../components/PartyTable'
import SectionContainer from '../components/SectionContainer'

class PartiesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
  getSearchTerm=(value)=>{
    this.setState({
      searchTerm: value
    })
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Parties</title>
        </Helmet>
        <main className='main parties'>
          <Hero src='ph.hero.projects.jpg'
            alt='Alt text'
            subtitle='Parties'
            showSearch={true}
            getSearchTerm={this.getSearchTerm}
            btnOnClick ={()=>{
              this.props.history.push('/parties/create')
            }}
            btnText= 'Add'
            links={[
              {
                href: 'https://quansic.com/isni-registration-vevasound/',
                text: 'Get an ISNI',
                target: '_blank'
              }
            ]} />
          <div className='mainContainer custom-checkbox'>
            <SectionContainer>
              <Query query={GET_PARTIES}
                notifyOnNetworkStatusChange={true}
                fetchPolicy='network-only'>
                {({ loading, error, data, refetch }) => {
                  if (error) {
                    return `Error! ${error.message}`
                  }
                  return (
                    <PartyTable
                      nameCellClass="parties-table"
                      rows={(data.getParties && data.getParties.sort(sortPartiesByName)) || []}
                      showSkeleton={loading}
                      userId={this.props.user.id}
                      showSearch={false}
                      searchTerm={this.state.searchTerm}
                      refetch={refetch}
                      batchActions
                      sortByOrder
                      actions
                      rowActions
                      onDelete={refetch}
                      pagination
                      canCreate={true}
                      canDelete={true}
                      comments={false}
                      sortDirection='ASC'
                      sortColumn='name'
                      perPage={100}
                    />
                  )
                }}
              </Query>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default GetUser(PartiesPage)

import React from 'react'
import { Link } from 'react-router-dom'

import { PLAN_NAMES } from '../utils/constants'

export default props => (
  <div className='subscription-details'>
    <p className='subscriptionCurrentPlan'>You are currently on the <span>
      {PLAN_NAMES[props.stripePlan]}
    </span> plan.</p>
    <Link to='/account/subscription/billing'><button>View Billing Information</button></Link>
  </div>
)

import React, { useState } from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'
import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import * as permissions from '../../utils/permissions'
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import GetRecording from '../../hocs/queries/GetRecording'
import WithAlertModal from '../../hocs/WithAlertModal'
import Hero from '../../components/Hero'
import { GET_PROJECT_FOLDER } from '../../graphql/queries'
import { NetworkStatus } from '../../utils/Apollo'
import WithComments from '../../hocs/WithComments'
import WithUploads from '../../hocs/WithUploads'
import WithUploadModal from '../../hocs/WithUploadModal'
import DeleteSessionRecording from '../../hocs/mutations/DeleteSessionRecording'
import { getUserFavouritesByType } from '../../utils/selectors'
import FileTable from '../../components/FileTable'
import { WithMoveFilesFrom } from '../../hocs/MoveFiles'
import CreatePagesHeader from '../../components/CreatePagesHeader'

const ProjectRecordingFiles = props => {
  const [searchTerm, setSearchTerm] = useState('')

  const getSearchTerm = value => {
    setSearchTerm(value)
  }

  const filterRecordingFiles = rows => {
    if (!rows) {
      return []
    }

    let filteredRows = rows.filter(row =>
      row.name
        .toString()
        .toLowerCase()
        .includes(searchTerm.toString().toLowerCase())
    )
    return filteredRows || []
  }

  const { project, recording, uploads, user, uploadModal } = props
  const isProjectOwner = permissions.isProjectOwner(project.id)
  const canFiles = permissions.can(project.id, 'recording')
  const canCreateFiles = isProjectOwner || canFiles.create
  const canDownloadFiles = isProjectOwner || canFiles.delete
  const canDeleteFiles = isProjectOwner || canFiles.delete

  const favourites = getUserFavouritesByType(user)

  return (
    <>
      <Helmet>
        <title>Files | {recording.name}</title>
      </Helmet>
      <main className='main projectRecordingFiles'>
        <div className={`${project.locked ? 'ghosted-wrapper' : ''}`}>

          <Query
            query={GET_PROJECT_FOLDER}
            variables={{
              projectId: project.id,
              folderId: recording.folderId
            }}
            fetchPolicy='cache-and-network'
            pollInterval={uploads.state.inProgressCount > 0 ? 0 : 10000}
          >
            {({ loading, error, data, refetch, networkStatus }) => {
              const actuallyLoading =
                    loading &&
                    networkStatus !== NetworkStatus.poll &&
                    networkStatus !== NetworkStatus.refetch

              if (error) {
                return `Error! ${error.message}`
              }

              let isRecordingCollaborator = false
              let files = []
              let folders = []

              if (!actuallyLoading) {
                files = data.getFolder.files
                folders = data.getFolder.folders

                isRecordingCollaborator =
                      data.getFolder.recording &&
                      permissions.hasRecordingAccess(
                        project.id,
                        data.getFolder.recording.id
                      )
              }

              let rows = [...folders, ...files]
              let filteredRows = filterRecordingFiles(rows)

              return (
                    <>
                              <Hero
                                subtitle={`${recording.name} Files`}
                                getSearchTerm={getSearchTerm}
                                showSearch={true}
                                btnText={canCreateFiles ? 'Upload' : false}
                                btnOnClick={() =>
                                  uploadModal.open(
                                    recording.name,
                                    project.name,
                                    project.id,
                                    recording.folderId,
                                    '',
                                    refetch
                                  )
                                }
                              >
                                <Breadcrumb
                                  items={[
                                    {
                                      href: `/projects`,
                                      name: 'Projects'
                                    },
                                    {
                                      href: `/projects/${project.id}`,
                                      name: project.name
                                    },
                                    {
                                      href: `/projects/${project.id}/recordings`,
                                      name: 'Recordings'
                                    },
                                    {
                                      href: `/projects/${project.id}/recordings/${recording.id}`,
                                      name: recording.name
                                    },
                                    {
                                      href: `/projects/${project.id}/recordings/${
                                        recording.id
                                      }/files`,
                                      name: 'Recording Files'
                                    }
                                  ]}
                                />
                              </Hero>
          <CreatePagesHeader
            subtitle='Recording Files'
            withDivider={false}
            // eslint-disable-next-line no-nested-ternary
            tags={!isProjectOwner ? isRecordingCollaborator || canCreateFiles ? [{ tagName: 'Full Access' }] : [{ tagName: 'Read' }] : []}
          />

                           <div className='mainContainer'>
                             <SectionContainer>
                               <FileTable
                                 pagination
                                 nameCellClass='project-recording-details-file'
                                 project={project}
                                 rows={filteredRows || []}
                                 showSearch={false}
                                 projectId={project.id}
                                 folderId={recording.folderId}
                                 fileFavourites={favourites.file || []}
                                 folderFavourites={favourites.folder || []}
                                 showSkeleton={actuallyLoading}
                                 refetch={refetch}
                                 favourite
                                 batchActions
                                 actions
                                 rowActions
                                 canCreate={canCreateFiles || isRecordingCollaborator}
                                 canDownload={
                                   canDownloadFiles || isRecordingCollaborator
                                 }
                                 canDelete={canDeleteFiles || isRecordingCollaborator}
                                 canAddToPlaylist={true}
                                 perPage={100}
                               />
                             </SectionContainer>
                           </div>

                    </>
              )
            }}
          </Query>
        </div>
      </main>
    </>
  )
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser,
  GetProject,
  GetRecording,
  DeleteSessionRecording,
  WithAlertModal,
  WithComments,
  WithMoveFilesFrom
)(ProjectRecordingFiles)

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { GET_SONG } from '../../graphql/queries'
import { CREATE_CREDIT } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetSong from '../../hocs/queries/GetSong'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import CreditForm from '../../components/CreditForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class SongCreditCreate extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Create Credit | {this.props.song.title}</title>
        </Helmet>
        <main className='main'>
          <CreatePagesHeader
            title={this.props.song.title ? `Assign Credit to ${this.props.song.title}` : '' }
            subtitle='New Song Credit'>

            <Breadcrumb items={[
              {
                href: `/songs`,
                name: 'Songs'
              },
              {
                href: `/songs/${this.props.song.id}`,
                name: this.props.song.title
              },
              {
                href: `/songs/${this.props.song.id}/credits`,
                name: 'Song Credits'
              },
              {
                href: `/songs/${this.props.song.id}/credits/add`,
                name: 'New Song Credit'
              }
            ]} />
          </CreatePagesHeader>
          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={CREATE_CREDIT}
                update={(cache, { data: { createCredit } }) => {
                  const { getSong } = cache.readQuery({
                    query: GET_SONG,
                    variables: {
                      id: this.props.song.id
                    }
                  })
                  if (getSong) {
                    const song = { ...getSong }
                    song.credits = [...song.credits, createCredit]
                    cache.writeQuery({
                      query: GET_SONG,
                      variables: {
                        id: this.props.song.id
                      },
                      data: {
                        getSong: song
                      }
                    })
                  }

                  this.props.history.push(`/songs/${this.props.song.id}`)
                }}>
                {(createCredit, { loading, error }) => (
                  <CreditForm
                    contributionType='song'
                    contribution={{
                      id: this.props.song.id,
                      name: this.props.song.title
                    }}
                    onSubmit={(state) => {
                      let data = { ...state }
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      createCredit({
                        variables: {
                          ...data,
                          performing: data.performing ? true : false
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetSong
)(SongCreditCreate)

import React from 'react'
import WaveformPreviewModal from '../components/WaveformPreviewModal'

const WithWaveformPreviewModal = (WrappedComponent) => {
  return class extends React.PureComponent {
    state = {
      open: false,
      file: null,
      togglePlay: null
    }

    constructor(props) {
      super(props)

      this.doOpen = this.doOpen.bind(this)
      this.doClose = this.doClose.bind(this)
    }

    doOpen(file, togglePlay) {
      this.setState({
        ...this.state,
        open: true,
        file,
        togglePlay
      })
      if (document.getElementsByClassName('main')[0] && document.getElementsByClassName('main')[0].offsetHeight > 830) {
        document.getElementsByClassName('main')[0].style.marginBottom =
          '260px'
      }
    }

    doClose() {
      this.setState({
        ...this.state,
        open: false,
        file: null
      })
      if (this.state.togglePlay) {
        this.state.togglePlay()
      }
      if (document.getElementsByClassName('main')[0] && document.getElementsByClassName('main')[0].offsetHeight > 830) {
        document.getElementsByClassName('main')[0].style.marginBottom =
          '0px'
      }
    }

    render() {
      return (
        <>
          <WrappedComponent {...this.props} waveformPreviewModal={{
            open: this.doOpen
          }} />
          <WaveformPreviewModal open={this.state.open}
            onRequestClose={this.doClose}
            file={this.state.file}
            togglePlay={this.state.togglePlay}
          />
        </>
      )
    }
  }
}

export default WithWaveformPreviewModal

import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'
import { notInFuture } from '../utils/Validator'

export default Schema(
  {
    type: Field()
      .string()
      .required({ message: Errors.Required }),
    title: Field()
      .string(),
    prefix: Field()
      .string(),
    firstName: Field()
      .string()
      .required({ message: Errors.Required }),
    middleName: Field()
      .string(),
    lastName: Field()
      .string(),
    suffix: Field()
      .string(),
    birthDate: Field()
      .string()
      .custom(notInFuture, {
        message: 'The date cannot be in the future'
      }),
    deathDate: Field()
      .string()
      .custom(notInFuture, {
        message: 'The date cannot be in the future'
      }),
    // .required({
    //   message: 'This field is required',
    //   constraint: When(
    //     '#type',
    //     Field()
    //       .string()
    //       .equals('person')
    //   )
    // }),
    isni: Field()
      .string()
      .not.required()
      .custom((value) => {
        if (value && value.length === 16) {
          const firstPart = value.slice(0, 15)
          const lastCharacter = value.charAt(15)
          const isNumeric = /^\d+$/.test(firstPart)
          const isXorx = lastCharacter === 'X' || lastCharacter === 'x' || /^\d+$/.test(lastCharacter)
          if (isNumeric && isXorx) {
            return true
          }
          return false
        }
        throw new Error('ISNI must be 16 characters long with the last character allowed as a number or character X')
      }),
    ipiCae: Field()
      .string('IPI/CAE must be 9 or 11 numbers').not.required()
      .num({ message: 'IPI/CAE must be 9 or 11 numbers' })
      .pattern('^(\\d{9}|\\d{11})$', { message: 'IPI/CAE must be 9 or 11 numbers' }),
    comments: Field()
      .string()
  },
  { verbose: true, async: true }
)

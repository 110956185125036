import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { GET_PROJECT } from '../../graphql/queries'
import { UPDATE_PROJECT } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import { GetProjectRefreshed } from '../../hocs/queries/GetProject'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import ProjectForm from '../../components/ProjectForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class ProjectEdit extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.project.name} | Projects</title>
        </Helmet>
        <main className='main'>
            <CreatePagesHeader
            title={this.props.project.name}
            subtitle='Edit Project Details'
            >
            <Breadcrumb items={[
              {
                href: `/projects/${this.props.project.id}`,
                name: this.props.project.name
              },
              {
                href: `/projects/${this.props.project.id}/edit`,
                name: 'Edit Project Details'
              }
            ]} />
            </CreatePagesHeader>
          <div className='sections-container'>
        <SectionContainer>
              <Mutation
                mutation={UPDATE_PROJECT}
                update={(cache, { data: { updateProject } }) => {
                  cache.writeQuery({
                    query: GET_PROJECT,
                    veriables: {
                      id: this.props.project.id
                    },
                    data: {
                      getProject: updateProject
                    }
                  })

                  this.props.history.push(`/projects/${this.props.project.id}`)
                }}>
                {(updateProject, { loading, error }) => (
                  <ProjectForm {...this.props.project}
                    onSubmit={(state) => {
                      updateProject({
                        variables: {
                          ...state,
                          projectId: this.props.project.id
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProjectRefreshed
)(ProjectEdit)

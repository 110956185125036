import React from 'react'
import { Mutation } from 'react-apollo'

import { DO_VERIFICATION } from '../graphql/mutations'
import GetPlatformImages from '../hocs/queries/GetPlatformImages'

import AppBranding from '../components/AppBranding'
import VerificationForm from '../components/VerificationForm'

class Verification extends React.Component {
  render() {
    const [image] = this.props.platformImages.filter(i => i.location === 'verification')
    return (
      <div className='login'>
        <div className='loginBackground'>
          <img src={image.fullPath} alt={image.altText} />
          {image.caption && <div className='loginBackgroundCredit'>{image.caption}</div>}
        </div>
        <div className='loginFormBackground'>
          <div className='loginFormWrapper'>
            <AppBranding />
            <div className='loginForm'>
              <Mutation
                mutation={DO_VERIFICATION}
                update={(cache, { data: { doVerification } }) => {
                  localStorage.setItem('authToken', doVerification.accessToken)

                  cache.writeData({
                    data: {
                      authStatus: {
                        __typename: 'authStatus',
                        status: 'loggedIn'
                      }
                    }
                  })

                  this.props.history.push('/')
                }}
              >
                {(doVerification, { loading, error }) => (
                  <VerificationForm
                    onSubmit={state => {
                      doVerification({
                        variables: {
                          ...state,
                          token: this.props.match.params.token
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error}
                  />
                )}
              </Mutation>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GetPlatformImages(Verification)

import React from 'react'
import {
  Form,
  Button,
  TextInput,
  TextArea
} from 'carbon-components-react'

// import uuidv4 from '@bundled-es-modules/uuid/v4.js'
import Validator from '../utils/Validator'
import PlaylistValidator from '../validators/Playlist'
import PlayListSongTable from './PlayListSongTable'
import CreatePagesHeader from './CreatePagesHeader'
import DiscardModal from './modals/DiscardModal'
import DisplayPictureUpload from './DisplayPictureUpload'
import { withRouter } from 'react-router'
import { compose } from 'react-apollo'


const initialState = {
  title: '',
  description: '',
  initialErrors: {},
  errors: {},
  file: '',
  isUploading: false,
  uploadComplete: false,
  discardModal: false
}

let initialCalcState = {}

class PlaylistForm extends Validator {
  state = { ...initialState };

  constructor(props) {
    super(props)
    this.handleRemoveImage = this.handleRemoveImage.bind(this)

    this.validator = PlaylistValidator

    this.state.title = props.title || ''
    this.state.image = props.image || ''
    this.state.description = props.description || ''


    this.discardChanges = this.discardChanges.bind(this)
    this.openDiscardModal = this.openDiscardModal.bind(this)
    this.closeDiscardModal = this.closeDiscardModal.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.submitThumbnailUpload = this.submitThumbnailUpload.bind(this)
    this.calculateInitialState = this.calculateInitialState.bind(this)
    this.calculateInitialState()
  }

  calculateInitialState() {
    initialCalcState = this.state
  }

  submit = e => {
    e.preventDefault()
    if (this.state.title === '') {
      return
    }

    this.submitThumbnailUpload().then(()=>{
      this.props.onSubmit(this.state)
    })
  };


  submitThumbnailUpload=() =>
    new Promise((resolve, reject)=>{
      if (this.state.isUploading) {
        return
      }
      if (this.state.file) {
        this.setState({
          isUploading: true,
          uploadComplete: false })
        const file = this.state.file.obj
        const formData = new FormData()
        formData.append('file', file, file.name)
        fetch(`${process.env.REACT_APP_API}/api/playlist-upload`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authToken')
          },
          body: formData
        })
          .then(response => response.json())
          .then(response => {
            this.setState({
              image: response.name,
              isUploading: false,
              uploadComplete: true,
              errors: {
                ...this.state.errors,
                image: undefined // eslint-disable-line no-undefined
              } })
            resolve(response)
          }).catch((error)=>{
            reject(error)
          })
      }
      if (this.state.file === null && this.state.image === null) {
        fetch(`${process.env.REACT_APP_API}/api/playlist-upload`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authToken')
          },
          body: null
        }).then((response)=>{
          resolve(response)
        }).catch((error)=>{
          reject(error)
        })
      }
      if (!this.state.file) {
        resolve()
      }
    })


  handleRemoveImage() {
    if (this.state.deleteLoading) {
      return
    }
    this.setState({
      deleteLoading: true
    })
    this.setState({
      ...this.state,
      deleteLoading: false,
      image: '',
      errors: {
        ...this.state.errors,
        image: undefined // eslint-disable-line no-undefined
      }
    })
  }

  openDiscardModal(e) {
    e.preventDefault()
    let change = false
    Object.entries(initialCalcState).forEach(([key]) => {
      if (JSON.stringify(initialCalcState[key]) !== JSON.stringify(this.state[key])) {
        change = true
      }
    })

    if (change) {
      this.setState({
        discardModal: true
      })
    } else {
      this.props.history.goBack()
    }
  }

  closeDiscardModal() {
    this.setState({
      discardModal: false
    })
  }


  discardChanges() {
    this.setState(initialCalcState)
  }


  removeImage() {
    this.setState({ image: null, file: null })
  }

  uploadImage = async (e) => {
    if (e.target.files.length < 1) {
      return
    }
    if (this.state.isUploading) {
      return
    }
    this.setState({
      file: {
        url: URL.createObjectURL(e.target.files[0]),
        obj: e.target.files[0]
      }
    })
  }


  render() {
    const onLoading = (this.props.id ? 'Saving...' : 'Creating...')
    const offLoading = (this.props.id ? 'Save' : 'Create')
    const buttonText = (this.props.loading || this.state.isUploading) ? onLoading : offLoading
    return (
      <>

        <Form className='playlist-custom-form' onSubmit={this.submit} autoComplete='off'>
          <div className='sections-container'>
            <div className='bx--form'>
              <div className='formRow'>
                <TextInput
                  id='title'
                  name='title'
                  labelText='Title'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={this.state.title || ''}
                  file={this.state.file || null}
                  invalid={this.state.errors.title ? true : false}
                  invalidText={this.state.errors.title || ''}
                />
              </div>
              <DisplayPictureUpload
                title='Image'
                playlistTitle={this.props.title}
                fromPlaylist
                file={this.state.file}
                image={this.state.image}
                url={`${process.env.REACT_APP_IMAGE_URL}playlists`}
                removeImage={this.removeImage}
                uploadImage={this.uploadImage}
                loading={this.state.isUploading}
              />

              <div className='formRow'>
                <TextArea
                  id='description'
                  name='description'
                  labelText='Description'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={this.state.description || ''}
                  invalid={this.state.errors.description ? true : false}
                  invalidText={this.state.errors.description || ''}
                />
              </div>

              <p className='formRow'>
              Add audio tracks to this playlist from any Files section in Projects, Recordings or Sessions or by visiting the <span className='highlightText'>Files</span> page.</p>
            </div>
          </div>


          {this.props.id && (
          <>
          <CreatePagesHeader
            subtitle='Playlist Tracks'
          />
          <div className='sections-container'>
            <div className='playlist-details'>
              <PlayListSongTable
                rows={this.props.files ? this.props.files : []}
                {...this.props}
                showSearch={false}
                batchActions
                rowActions
                actions
                editMode={true}
              />
            </div>
          </div>
          </>
          )}
          <div className='formRow actionBtnContainer  bx--form sections-container'>
            <button className='bx--btn bx--btn--secondary' onClick={this.openDiscardModal}>
              Cancel
            </button>
            <Button type='submit'>
              {buttonText}
            </Button>
          </div>
        </Form>

        {
          this.state.discardModal && <DiscardModal open={this.state.discardModal}
            onRequestClose={this.closeDiscardModal}
            modalHeading='Discard Changes?'
            handleSubmit={this.discardChanges}
          />
        }
      </>
    )
  }
}

export default compose(withRouter)(PlaylistForm)

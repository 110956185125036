import React from 'react'

class SimpleListItem extends React.PureComponent {
  render() {
    const {
      partyId,
      alert,
      onDeleteItem,
      onDelete,
      deletePartyItem,
      row,
      alertType,
      alertMessage,
      alertButtonText,
      title
    } = this.props
    return (
        <>
      <div key={this.props.key} className='simple-list-item'>
        <div className='simple-List-Wrapper'>
          <ul className='bx--accordion'>
            <div className='simple-List-Accordion-Item'>
              <li className='bx--accordian-item'>
                <p className='bx--accordion__heading'>{title}</p>
              </li>
              <div className='fileOptionsContents'>
                <button type='button' className='delete-button-svg' onClick={(item)=>{
                  alert(alertMessage, {
                    type: alertType,
                    buttonText: alertButtonText,
                    submitCallback: close => {
                      if (!partyId) {
                        onDeleteItem(item)
                        close()
                        return
                      }
                      deletePartyItem({
                        variables: {
                          id: row.id
                        }
                      })
                        .then(() => {
                        //   alert('Address has successfully been deleted')
                          onDelete()
                        })
                        .catch(() => {
                          alert('This party address could not be deleted.')
                        })
                      close()
                    }
                  })
                }}>
                  <svg width='14' height='18' viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z' fill='white'/>
                  </svg>
                </button>
              </div>
            </div>
          </ul>
        </div>
      </div>
</>
    )
  }
}


export default SimpleListItem


import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'


import { getUserFavouritesByType } from '../utils/selectors'
import GetUser from '../hocs/queries/GetUser'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import Hero from '../components/Hero'

import ShareList from '../components/tables/ShareList'

import MyFiles from './MyFiles'
import ProjectFilesList from './ProjectFilesList'
import SongFiles from './SongFiles'
import RecentlyDeletedFiles from './RecentlyDeletedFiles'
import { WithMoveFilesFrom } from '../hocs/MoveFiles'

class FilesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldRerender: 'foo'

    }
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.props.uploadModal.open('/', '', null)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.state !== this.props.location.state && this.props.location.state) {
      this.props.uploadModal.open('/', '', null)
    }
  }
manualRerender = (value) => {
  this.setState({
    shouldRerender: `unel${Math.floor(Math.random() * 10)}`
  })
}
render() {
  const favourites = getUserFavouritesByType(this.props.user)

  return (
      <>
        <Helmet>
          <title>Files</title>
        </Helmet>
        <main className='main files-share'>
          <Hero src='ph.hero.projects.jpg'
            alt='Alt text'
            subtitle='Files' />

          <MyFiles match={this.props.match}
            manualRerender={this.manualRerender}
            location={this.props.location}
            uploadModal={this.props.uploadModal}
            uploads={this.props.uploads}
            project={this.props.project}
            setProjectIdAndFolderId={this.props.uploads.actions.setProjectIdAndFolderId}
            fileFavourites={favourites.file || []}
            folderFavourites={favourites.folder || []}
          />
          <ProjectFilesList match={this.props.match}
            manualRerender={this.manualRerender}
            location={this.props.location}
            uploadModal={this.props.uploadModal}
            uploads={this.props.uploads}
            project={this.props.project}
            setProjectIdAndFolderId={this.props.uploads.actions.setProjectIdAndFolderId}
            fileFavourites={favourites.file || []}
            folderFavourites={favourites.folder || []}
            {...this.props}
          />
          <SongFiles match={this.props.match}
            manualRerender={this.manualRerender}
            location={this.props.location}
            uploadModal={this.props.uploadModal}
            uploads={this.props.uploads}
            project={this.props.project}
            setProjectIdAndFolderId={this.props.uploads.actions.setProjectIdAndFolderId}
            fileFavourites={favourites.file || []}
            folderFavourites={favourites.folder || []}
            moveFilesFrom={this.props.moveFilesFrom}
          />
          <ShareList
            shouldRerender={this.state.shouldRerender}
            {...this.props}
          />
          <RecentlyDeletedFiles match={this.props.match}
            location={this.props.location}
            uploadModal={this.props.uploadModal}
            uploads={this.props.uploads}
            project={this.props.project}
            setProjectIdAndFolderId={this.props.uploads.actions.setProjectIdAndFolderId}
            fileFavourites={favourites.file || []}
            folderFavourites={favourites.folder || []}
            userId={this.props.user.id}
            recentlyDeleted={true}
            shouldRerender={this.state.shouldRerender}
          />
        </main>
      </>
  )
}
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser,
  WithMoveFilesFrom
)(FilesPage)

import React from 'react'
import ProgressBar from '../../ProgressBar'
import Cross from '../../../assets/images/cross-icon.svg'
import { convertBytes } from '../../../utils/functions'
import { memo } from 'react'

const PublicShareSingleFileProgress = props => {
  const { name, size, progress, id, type, progressBarStyle, removeUpload } = props

  return (
    <div className='singleFileProgress'>
      <div className='fileTypeBlock'><p>{type && type.toUpperCase()}</p></div>

      <div className='progressInfo'>
        <div className='progressInfo--brief'>

          <div className='progressFile'>
            <p className='fileName'>{name}</p>
            <p className='fileSize'>&nbsp;- {convertBytes({ bytes: size })}</p>
          </div>

          <div className='progressPercentage'>
            <p className='percentage'>{progress}%</p>
            {progress !== 100 && <img src={Cross} alt='Close' className='crossIcon' onClick={() => removeUpload(id)}/>}
          </div>

        </div>

        <div>
          <ProgressBar value={progress} progressBarStyle={progressBarStyle} />
        </div>
      </div>
    </div>
  )
}

export default memo(PublicShareSingleFileProgress)

import React from 'react'
import { Form, Button } from 'carbon-components-react'
import Validator from '../utils/Validator'
import ApolloClient from '../utils/Apollo'
import { GET_TIMEZONE_TYPES } from '../graphql/queries'
import AccountTimezone from '../validators/AccountTimezone'
import { SelectASync } from './Select'
import DiscardModal from './modals/DiscardModal'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'


const initialState = {
  timeZone: '',
  timezoneId: '',
  errors: {},
  userDefinedTimezoneTypes: [],
  discardModal: false
}

class AccountTimezoneForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)
    this.getTimezonesTypeOptions = this.getTimezonesTypeOptions.bind(this)
    this.handleTimezoneTypeChange = this.handleTimezoneTypeChange.bind(this)
    this.validator = AccountTimezone
    this.state.timeZone = props.timeZone || ''
    this.state.timezoneId = props.timezoneId || ''
    this.state.myTimeZone = ''
    this.discardChanges = this.discardChanges.bind(this)
    this.openDiscardModal = this.openDiscardModal.bind(this)
    this.closeDiscardModal = this.closeDiscardModal.bind(this)
  }

  submit() {
    this.props.onSubmit(this.state)
  }

  componentDidMount() {
    ApolloClient.query({
      query: GET_TIMEZONE_TYPES,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      if (this.state.timezoneId) {
        data.getTimezone.forEach((i) => {
          if (i.id === this.state.timezoneId) {
            this.setState({
              myTimeZone: i
            })
          }
        })
      }
    })
  }

  getTimezonesTypeOptions = inputValue =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_TIMEZONE_TYPES,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let timezoneTypes = []
        let userDefinedTimezoneTypes = []
        data.getTimezone.forEach(timezoneType => {
          if (!timezoneType.timezone.toLowerCase().includes(inputValue.toLowerCase())) {
            return
          }
          if (timezoneTypes.userDefined) {
            userDefinedTimezoneTypes.push(timezoneType.id)
          }
          timezoneTypes.push({
            value: timezoneType.id,
            label: timezoneType.offset
          })
        })
        this.setState({
          ...this.state,
          userDefinedTimezoneTypes
        })
        resolve(timezoneTypes)
      })
    })

  handleTimezoneTypeChange(option) {
    this.setState({
      ...this.state,
      timezoneId: option.value,
      timeZone: option.label
    })
  }


  openDiscardModal(e) {
    e.preventDefault()
    if (
      this.props.timezoneId === this.state.timezoneId
    ) {
      this.props.history.goBack()
    } else {
      this.setState({
        discardModal: true
      })
    }
  }

  closeDiscardModal() {
    this.setState({
      discardModal: false
    })
  }

  discardChanges() {
    const timezone = {
      value: this.state.myTimeZone.id ? this.state.myTimeZone.id : '',
      label: this.state.myTimeZone.offset ? this.state.myTimeZone.offset : ''
    }
    this.setState({
      timezoneId: timezone.value,
      timeZone: timezone.label,
      discardModal: false
    })
  }


  render() {
    const timezone = {
      value: this.state.myTimeZone.id ? this.state.myTimeZone.id : '',
      label: this.state.myTimeZone.offset ? this.state.myTimeZone.offset : ''
    }

    return (
      <>
          <div className='form'>
            <Form onSubmit={ this.handleSubmit } autoComplete='off'>
              <div className='formRow'>
                <SelectASync
                  labelText='Time Zone'
                  id='timeZone'
                  cacheOptions
                  defaultOptions
                  loadOptions={ this.getTimezonesTypeOptions }
                  onChange={ this.handleTimezoneTypeChange }
                  placeholder={ this.state.timeZone ? this.state.timeZone :
                    timezone.label ? timezone.label : 'Select...' }
                  invalid={ !!this.state.errors.timeZone }
                  invalidText={this.state.errors.timeZone}
                  value={this.state.timeZone}
                />
              </div>
              <div className='formRow actionBtnContainer'>
                <button className='bx--btn bx--btn--secondary' onClick={this.openDiscardModal}>
                  Cancel
                </button>
                <Button type='submit'>
                  { this.props.loading ? 'Saving...' : 'Update' }
                </Button>
              </div>
            </Form>
          </div>
          {
            this.state.discardModal && <DiscardModal open={this.state.discardModal}
              onRequestClose={this.closeDiscardModal}
              modalHeading='Discard Changes?'
              handleSubmit={this.discardChanges}
            />
          }
      </>
    )
  }
}

export default compose(
  withRouter
)(AccountTimezoneForm)


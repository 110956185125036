import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'
import { DateTime } from 'luxon'
import ApolloClient from '../../utils/Apollo'

import { CREATE_SESSION } from '../../graphql/mutations'

import { GET_SESSION } from '../../graphql/queries'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import GetRecording from '../../hocs/queries/GetRecording'

import CreateSessionRecording from '../../hocs/mutations/CreateSessionRecording'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import SessionForm from '../../components/SessionForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class SessionCreate extends React.Component {
  getPropsRecordingPage = () => {
    let location = this.props.location

    if (location && location.state && location.state.recordingDetailPage) {
      return {
        recording: location.state.recording,
        recordingField: {
          isMulti: false,
          labelText: 'Recording',
          options: [],
          className: 'disabledField',
          btnText: '',
          btnOnClick: ''
        }
      }
    }
    return {}
  }


  render() {
    let breadcrumb = [
      {
        href: `/projects/`,
        name: 'Projects'
      },
      {
        href: `/projects/${this.props.project.id}`,
        name: this.props.project.name
      },
      {
        href: `/projects/${this.props.project.id}/sessions`,
        name: 'Project Sessions'
      },
      {
        href: `/projects/${this.props.project.id}/sessions/create`,
        name: 'New Session'
      }
    ]

    let recordingId = null

    if (this.props.recording) {
      recordingId = this.props.recording.id

      breadcrumb = [
        {
          href: `/projects/${this.props.project.id}`,
          name: this.props.project.name
        },
        {
          href: `/projects/${this.props.project.id}/recordings`,
          name: 'Recordings'
        },
        {
          href: `/projects/${this.props.project.id}/recordings/${recordingId}`,
          name: this.props.recording.name
        },
        {
          href: `/projects/${this.props.project.id}/recordings/${recordingId}/sessions`,
          name: 'Sessions'
        },
        {
          href: `/projects/${this.props.project.id}/recordings/${recordingId}/sessions/create`,
          name: 'New Session'
        }
      ]
    }

    let location = this.props.location

    return (
      <>
        <Helmet>
          <title>Create Session | {this.props.project.name}</title>
        </Helmet>
        <main className='main'>
          <CreatePagesHeader
            title={'New Session'}
            subtitle='Session Details'
          >
            <Breadcrumb items={breadcrumb} />

          </CreatePagesHeader>
          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={CREATE_SESSION}
              >
                {(createSession, { loading, error }) => (
                  <SessionForm
                    recording={this.props.recording}
                    projectId={this.props.project.id}
                    project={this.props.project}
                    onSubmit={state => {
                      let data = { ...state }
                      Object.keys(data).forEach(key => data[key] === '' && (data[key] = null))
                      createSession({
                        variables: {
                          ...data,
                          projectId: this.props.project.id,
                          unionSession: !!data.unionSession,
                          analogSession: !!data.analogSession,
                          dropFrame: !!data.dropFrame,
                          startedAt: data.startedAt
                            ? DateTime.fromMillis(data.startedAt.getTime()).toFormat(
                              'yyyy-LL-dd HH:mm:ss'
                            )
                            : null,
                          endedAt: data.endedAt
                            ? DateTime.fromMillis(data.endedAt.getTime()).toFormat(
                              'yyyy-LL-dd HH:mm:ss'
                            )
                            : null
                        }
                      })
                        .then(response => {
                          const sessionId = response.data.createSession.id
                          if (this.props.recording) {
                            this.props
                              .createSessionRecording(sessionId, this.props.recording.id)
                              .then(() => {
                                ApolloClient.query({
                                  query: GET_SESSION,
                                  variables: {
                                    id: sessionId
                                  },
                                  fetchPolicy: 'network-only'
                                }).then(() => {
                                  this.props.history.goBack(-1)
                                })
                              })
                            return
                          }

                          if (location && location.state && location.state.recordingDetailPage) {
                            this.props.history.goBack(-1)
                          }

                          if (location && location.state && location.state.projectDashboardPage) {
                            this.props.history.push(`/projects/${this.props.project.id}`)
                            return
                          }

                          this.props.history.push(
                            `/projects/${this.props.project.id}/sessions/${sessionId}`
                          )
                        })
                        .catch(() => {
                          //
                        })
                    }}
                    loading={loading}
                    errors={error}
                    {...this.getPropsRecordingPage()}
                  />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetRecording,
  CreateSessionRecording
)(SessionCreate)

import React from 'react'
import { Form, Button, TextInput, TextArea, Tooltip, Checkbox } from 'carbon-components-react'
import { compose } from 'react-apollo'
import PublicFileShareValidator from '../validators/PublicFileShare'
import Validator from '../utils/Validator'
import WithAlertModal from '../hocs/WithAlertModal'
import PublicShareAPI from '../networking/publicShareAPI'
import PublicShareModal from './modals/PublicShareModal'
import WithPublicShare from '../hocs/WithPublicShare'
import rocket from '../assets/images/signup-icon.svg'
import { Link } from 'react-router-dom'

const initialState = {
  emailto: '',
  email: '',
  title: '',
  message: '',
  modalOpen: false,
  showTooltip: false,
  termsChecked: false,
  initialErrors: {},
  errors: {}
}

class PublicFileShareForm extends Validator {
  api = new PublicShareAPI()
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.validator = PublicFileShareValidator
  }

  closeModal = () => {
    this.setState({
      modalOpen: false
    })
  }
  handleWindowResize=()=> {
    const { innerWidth } = window
    if (innerWidth < 768) {
      this.setState({
        showTooltip: false
      })
    } else {
      this.setState({
        showTooltip: true
      })
    }
  }
  componentDidMount=()=>{
    window.addEventListener('resize', this.handleWindowResize)
  }
  componentWillUnmount=()=>{
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleSendVerificationToken = async (info) => {
    try {
      const response = await this.api.sendVerificationToken(info)
      const data = await response.json()
      if (response.ok) {
        this.setState({
          modalOpen: true
        })
      } else {
        console.log(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  submit() {
    let totalSize = 0
    const { email, emailto: mailto, message, title } = this.state
    if (!this.props.files.length) {
      this.props.alert('Please choose files or folders to transfer.')
    } else {
      this.props.files.forEach(file =>{
        if (file.size === 2147483648 ||
           file.size > 2147483648 ||
            totalSize > 2147483648 ||
             totalSize === 2147483648) {
          this.props.alert('Your file share cannot be larger than 2GB')
        }
        totalSize = totalSize + file.size
      })
      if (totalSize > 2147483648 || totalSize === 2147483648) {
        this.props.alert('Your file share cannot be larger than 2GB.')
        return
      // eslint-disable-next-line padded-blocks
      }
      if (totalSize !== 2147483648 || totalSize < 2147483648) {
        this.handleSendVerificationToken({
          sender_email: email,
          recipient_emails: mailto,
          message,
          title
        })
      }
    }
  }
  render() {
    const token = localStorage.getItem('authToken')
    const redirectURI = token ? '/account' : '/register'
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className='form-wrapper'>
          <div className='formRow'>
            <TextInput
              id='emailto'
              name='emailto'
              labelText='Send to Email *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.emailto || ''}
              invalid={this.state.errors.emailto ? true : false}
              invalidText={this.state.errors.emailto || ''}
            />
          </div>
          <div className='formRow'>
            <TextInput
              id='email'
              name='email'
              labelText='Your Email *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.email || ''}
              invalid={this.state.errors.email ? true : false}
              invalidText={this.state.errors.email || ''}
            />
          </div>
          <div className='formRow'>
            <TextInput
              id='title'
              name='title'
              labelText='Message Subject *'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.title || ''}
              invalid={this.state.errors.title ? true : false}
              invalidText={this.state.errors.title || ''}
            />
          </div>
          <div className='formRow'>
            <TextArea
              id='message'
              name='message'
              labelText='Message'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.message || ''}
              invalid={this.state.errors.message ? true : false}
              invalidText={this.state.errors.message || ''}
            />
          </div>
        </div>
        <div className='options'>
          <div className='options-container first-option'>
            <div className='options-text'>
              <p className='options-heading'>
               Send Bigger Transfers
              </p>
              <span className='options-sub-text'>Unlimited</span>
            </div>
            <div className='options-button'>
              {this.state.showTooltip ?
                <Tooltip
                  className='plan-tooltip'
                  direction='right'
                  tabIndex={0}
                  showIcon={false}

                  triggerText={ <Button>Sign Up</Button>}
                >
                  <div className='plan'>
                    <div className='plan-img-container'>
                      <img className ='plan-img' src={rocket} alt=''/>
                    </div>
                    <p className='plan-text'>Sign Up to VEVA Collect to activate these features</p>
                    <Link to={redirectURI}><Button>Sign Up</Button></Link>


                  </div>


                </Tooltip>
                : <Link to={redirectURI}><Button>Sign Up</Button></Link>}
            </div>
          </div>
          <div className='options-container first-option'>
            <div className='options-text'>
              <p className='options-heading'>
               Set Expiration Date
              </p>
              <span className='options-sub-text'>Manage your shares</span>
            </div>
            <div className='options-button'>
              { this.state.showTooltip ? <Tooltip
                className='plan-tooltip'
                direction='right'
                tabIndex={0}
                showIcon={false}

                triggerText={ <Button>Sign Up</Button>}
              >
                <div className='plan'>
                  <div className='plan-img-container'>
                    <img className ='plan-img' src={rocket} alt=''/>
                  </div>
                  <p className='plan-text'>Sign Up to VEVA Collect to activate these features</p>
                  <Link to={redirectURI}><Button>Sign Up</Button></Link>


                </div>


              </Tooltip> :
                <Link to={redirectURI}><Button>Sign Up</Button></Link>}

            </div>
          </div>
          <div className='options-container'>
            <div className='options-text'>
              <p className='options-heading'>
               Set Password
              </p>
              <span className='options-sub-text'>Protect your shares</span>
            </div>
            <div className='options-button'>
              { this.state.showTooltip ? <Tooltip
                className='plan-tooltip'
                direction='right'
                tabIndex={0}
                showIcon={false}

                triggerText={ <Button>Sign Up</Button>}
              >
                <div className='plan'>
                  <div className='plan-img-container'>
                    <img className ='plan-img' src={rocket} alt=''/>
                  </div>
                  <p className='plan-text'>Sign Up to VEVA Collect to activate these features</p>
                  <Link to={redirectURI}><Button>Sign Up</Button></Link>


                </div>


              </Tooltip> :
                <Link to={redirectURI}><Button>Sign Up</Button></Link>}

            </div>

          </div>
          <div className='terms-conditions-wrapper'>
            <Checkbox id='checked-2' labelText='' required />
            <a
              className='terms-conditions-text'
              href='https://www.vevacollect.com/legal/legal'
              target='_blank'
              rel='noopener noreferrer' >
              I agree to the Terms and Conditions.
            </a>
          </div>
        </div>

        <div className='button-wrapper'>

          <Button className='transfer-button' type='submit'>Transfer</Button>

        </div>
        <PublicShareModal
          email={this.state.email}
          open={this.state.modalOpen}
          onRequestClose={this.closeModal}
          files={this.props.files}
          entries={this.props.entries}
        />
      </Form>
    )
  }
}

export default compose(
  WithAlertModal,
  WithPublicShare,
)(PublicFileShareForm)

import React from 'react'
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react'
import { Link } from 'react-router-dom'
import { compose, graphql } from 'react-apollo'

import { GET_PROJECTS } from '../graphql/queries'
import { DELETE_PROJECT } from '../graphql/mutations'

import { GetGradientFromId } from '../utils/constants'

import DetectEdgeTile from '../hocs/DetectEdgeTile'
import CreateUserFavourite from '../hocs/mutations/CreateUserFavourite'
import DeleteUserFavourite from '../hocs/mutations/DeleteUserFavourite'
import WithAlertModal from '../hocs/WithAlertModal'
import * as permissions from '../utils/permissions'
import WhiteOutlineStarIcon from '../assets/images/white-outline-star.svg'
import OrangeFilledStarIcon from '../assets/images/favourite.svg'

class ProjectTile extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isFavourite: props.favourite,
      favouriteIcon: props.favourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon
    }

    if (!props.image) {
      let randGradient = GetGradientFromId(props.id)

      this.selectedGradient = {
        background: randGradient
      }
    }

    this.favHandler = this.favHandler.bind(this)
  }

  favHandler() {
    if (this.props.disableFavourite) {
      return
    }

    this.setState({
      isFavourite: !this.state.isFavourite,
      favouriteIcon: !this.state.isFavourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon
    })

    if (this.state.isFavourite) {
      this.props.deleteUserFavourite(this.props.id, 'project')
    } else {
      this.props.createUserFavourite(this.props.id, 'project')
    }

    if (this.props.refetchProjects) {
      this.props.refetchProjects()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.favourite !== prevProps.favourite) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        favouriteIcon: this.props.favourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon
      })
    }
  }

  render() {
    const isProjectOwner = permissions.isProjectOwner(this.props.id, this.props.user)

    let getFirstLetters = this.props
      .name.toUpperCase().replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g)
    const initials = getFirstLetters ? getFirstLetters.join('').substr(0, 4) : ''

    const url = (this.props.generateUrl ?
      this.props.generateUrl(this.props) : `/projects/${this.props.id}`
    )
    const filesUrl = (this.props.generateUrl ?
      this.props.generateUrl(this.props) : `/projects/${this.props.id}/files#all`
    )

    let dynamicRoute = window.location.pathname === '/files/projects' ? filesUrl : url


    const tagName = isProjectOwner ? 'Owner' : 'Collaborator'

    return (
      <div className='project'>
        {
          (this.props.image ? (
            <Link to={dynamicRoute} className='projectArt'>
              <img src={`${process.env.REACT_APP_PROJECT_IMAGE_URL}${this.props.image}`}
                alt={this.props.name} />
            </Link>
          ) : (
            <Link to={dynamicRoute} className='projectCover' style={this.selectedGradient}>
              <span>{initials}</span>
            </Link>
          ))
        }


        {/* Favorite Icon */}

        <img onClick={this.favHandler}
          src={this.state.favouriteIcon}
          className='favouriteIcon'
          alt='favorites' />


        <div className='projectInfoContainer'>
          <div className='projectInfo'>
            <p className='project-tile-title'>
              {this.props.name}
              {(this.props.artist) && (
                <aside>
                  {this.props.artist.name}
                </aside>
              )}
            </p>
            <div className='tags-wrapper'>
              <span className='tag'>
                {tagName}
              </span>
            </div>
          </div>
          {this.props.withOptions &&
            <div className='projectInfoOptions'>
              <OverflowMenu
                ariaLabel=''
                iconDescription=''
                flipped={this.props.edgeTile}
              >
                <OverflowMenuItem
                  itemText={this.props.favourite ? 'Remove from Favorites' : 'Add to Favorites' }
                  onClick={() => {
                    if (this.props.favourite) {
                      return this.props.deleteUserFavourite(this.props.id, 'project')
                    }

                    return this.props.createUserFavourite(this.props.id, 'project')
                  }} />
                {this.props.userId === this.props.user.id &&
                  <OverflowMenuItem
                    itemText='Delete'
                    onClick={() => {
                      this.props.deleteProject({
                        variables: {
                          projectId: this.props.id,
                          confirmed: false
                        },
                        update: (cache) => {
                          const { getProjects } = cache.readQuery({ query: GET_PROJECTS })
                          const projects = getProjects.filter(project => project.id !== this.props.id)

                          cache.writeQuery({
                            query: GET_PROJECTS,
                            data: {
                              getProjects: projects
                            }
                          })
                        }
                      }).then(() => {})
                        .catch(({ graphQLErrors }) => {
                          const errorMsg = graphQLErrors[0].message
                          this.props.alert(errorMsg,
                            {
                              type: 'delete',
                              buttonText: 'Confirm',
                              submitCallback: close => {
                                this.props.deleteProject({
                                  variables: {
                                    projectId: this.props.id,
                                    confirmed: true
                                  },
                                  update: (cache) => {
                                    const { getProjects } = cache.readQuery({ query: GET_PROJECTS })
                                    const projects = getProjects.filter(project => project.id !== this.props.id)

                                    cache.writeQuery({
                                      query: GET_PROJECTS,
                                      data: {
                                        getProjects: projects
                                      }
                                    })
                                  }
                                })
                                try {
                                  this.props.onDelete()
                                } catch (error) {
                                  this.props.alert('This project could not be deleted.')
                                }
                                close()
                              }
                            })
                        })
                    }} />
                }
              </OverflowMenu>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default compose(
  DetectEdgeTile,
  CreateUserFavourite,
  DeleteUserFavourite,
  WithAlertModal,
  graphql(DELETE_PROJECT, { name: 'deleteProject' })
)(ProjectTile)

import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/new-logo.png'


const PublicPlaylistHeader = () => {
  return (
    <div className='appBody'>
      <header className='appHeader'>
        <div className='appBrandingNewUi appBrandingControlPanel'>
          <Link to='/'><img src={logo} className='appLogo' alt='logo' /></Link>
        </div>
      </header>
    </div>

  )
}

export default PublicPlaylistHeader

import React from 'react'
import { graphql, compose } from 'react-apollo'
import {
  Form,
  TextInput,
  Modal,
  TextArea,
  DatePicker,
  DatePickerInput,
  InlineNotification,
  RadioButton,
  RadioButtonGroup
} from 'carbon-components-react'
import { DateTime } from 'luxon'
// import CustomDatePicker from '../../components/DatePicker'


import { SHARE_FILES } from '../../graphql/mutations/share'
import Validator from '../../utils/Validator'
import ShareValidator from '../../validators/Share'
import copyLinkIcon from '../../assets/images/CopyLinkIcon2.svg'
import ShareLink from '../../hocs/mutations/ShareLink'
import WithAlertModal from '../../hocs/WithAlertModal'

const initialState = {
  files: [],
  emails: '',
  password: '',
  expiry: '',
  message: '',
  initialErrors: {},
  errors: {},
  requestError: null,
  working: false,
  shareMode: 'email',
  link: ''
}

class CreateShareModal extends Validator {
  validator = ShareValidator
  state = {
    ...initialState,
    ...this.props.share,
    files: this.props.files,
    emails: this.props.share.users
      ? this.props.share.users
        .map((user) => user.email)
        .join(', ')
      : initialState.emails,
    expiry: this.props.share.expiry
      ? DateTime.fromSQL(this.props.share.expiry).toFormat('LL/dd/yyyy')
      : initialState.expiry
  }

  constructor(props) {
    super(props)
    this.handleExpiryChange = this.handleExpiryChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  // Save DatePicker final value to state
  handleExpiryChange(full, formatted) {
    const selected = new Date(formatted)
    const todayDate = new Date()
    if (selected.setHours(0, 0, 0, 0) >= todayDate.setHours(0, 0, 0, 0)) {
      this.setState({
        expiry: formatted,
        errors: {
          // eslint-disable-next-line no-undefined
          expiry: undefined
        }
      })
    } else {
      this.setState({
        expiry: '',
        errors: {
          expiry: 'You cannot set a past date.'
        }
      })
    }
  }

  // Called by Validator's handleSubmit()
  submit() {
    // Show user we're working
    this.setState({ working: true })

    // Assemble data for request
    let dataToSubmit = Object.assign(
      ...Object.keys(this.state)
        .filter(key => {
          // Remove unwanted properties
          if (this.state.shareMode === 'email' ? ![
            'files',
            'emails',
            'expiry',
            'password',
            'message'
          ].includes(key) :
            ![
              'files',
              'expiry',
              'password',
              'message'
            ].includes(key)
          ) {
            return false
          }
          return true
        })
        .map(key => {
          let value = this.state[key]
          // Format emails
          if (key === 'emails') {
            // This converts a list of comma-separated emails into an array of objects,
            // each with one property: email.
            value = value.split(',').map(s => ({ email: s.trim() }))
            // Easy to miss: this line is renaming the emails property key to users.
            // Because Mohsin named it users in the graphql query.
            return { users: value }
          }
          // Format expiry
          if (key === 'expiry') {
            value = DateTime.fromFormat(value, 'LL/dd/yyyy').toISODate() || initialState.expiry
          }
          // Format files
          if (key === 'files') {
            // Only include id and type properties
            value = value.map(file => ({
              id: file.id,
              type: file.type.toLowerCase() === 'file' ? 'file' : 'folder'
            }))
          }
          return { [key]: value }
        })
    )
    dataToSubmit.shareId = this.props.share ? this.props.share.id : ''

    // Request back-end
    if (this.state.shareMode === 'email') {
      this.props.shareFiles(dataToSubmit)
        .then(response => {
          if (response.data.shareFiles.success) {
            // Reset this share modal
            this.setState(initialState)
            // Expose success event to parent
            this.props.onSuccess(response.data.shareFiles)
            this.props.getType('email')
            if (this.props.onCancel) {
              this.props.onCancel()
            }
          } else {
            if (this.props.onCancel) {
              this.props.onCancel()
            }
            this.props.alert(response.data.shareFiles.message, { className: 'project-credits' })
          }
        })
      // Request fail
        .catch(requestError => {
          if (this.props.onCancel) {
            this.props.onCancel()
          }
          this.props.alert(
            'One or several folders selected cannot be shared or downloaded as they are empty')
          this.setState({
            working: false,
            requestError
          })
        })
    } else {
      this.props.shareDirectLinks({ variables: { ...dataToSubmit } })
      // Request success
        .then(response => {
          if (response.data.shareDirectLinks.url) {
            navigator.clipboard.writeText(response.data.shareDirectLinks.url)
            this.props.alert(
              'A link to this file or folder has been copied to clipboard. You can now share it.')
            this.setState(initialState)
            this.props.onSuccess(response.data.shareDirectLinks)
            this.props.getType('link')
            if (this.props.onCancel) {
              this.props.onCancel()
            }
          } else {
            if (this.props.onCancel) {
              this.props.onCancel()
            }
            this.props.alert(
              'One or several folders selected cannot be shared or downloaded as they are empty')
          }
        })
      // Request fail
        .catch(requestError => {
          if (this.props.onCancel) {
            this.props.onCancel()
          }
          this.props.alert(
            'One or several folders selected cannot be shared or downloaded as they are empty')
          this.setState({
            working: false,
            requestError
          })
        })
    }
  }

  primaryButtonText = this.state.working ? 'Sending' : 'Share'

  render() {
    const defaultExpiry = DateTime.local().plus({ days: 30 }).toISODate()
    const {
      expiry,
      errors,
      emails,
      shareMode,
      password,
      message,
      requestError,
      link
    } = this.state
    return (
      <Modal
        // className='modal-scrollable share-modal modal-background'
        className={`modal-scrollable share-modal modal-background share-files
        ${shareMode === 'link' && 'hide-save-button link-share-mode'}`}
        open={this.props.open}
        modalHeading='Share Files and Folders'
        primaryButtonText={shareMode === 'email' ? this.primaryButtonText : ''}
        passiveModal={shareMode === 'link'}
        secondaryButtonText={shareMode === 'email' ? 'Cancel' : ''}
        shouldSubmitOnEnter={false}
        selectorPrimaryFocus='.bx--text-input'
        onRequestSubmit={this.handleSubmit}
        onRequestClose={this.props.onRequestClose}
      >
        <div className='close-button'>
          <button
            type='button'
            onClick={()=>{
              this.props.onRequestClose()
            }
            }
          >
            <svg width='14' height='14' viewBox='0 0 14 14' fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z' // eslint-disable-line max-len
                fill='white'/>
            </svg>

          </button>
        </div>
        <div className='form'>
          <Form>
            <div className='formRow formRow__thin'>
              <div className='two-column'>
                <div className='radio-container'>
                  <p className='drop-helper'>Share via</p>
                  <RadioButtonGroup
                    legendText='Radio button heading'
                    name='radio-button-group'
                    onChange={(val) => {
                      this.setState({
                        shareMode: val
                      })
                    }}
                    defaultSelected='email'
                    className='radio-button-column'
                  >
                    <RadioButton labelText='Email' value='email' id='email' />
                    <RadioButton labelText='Link' value='link' id='link' />
                  </RadioButtonGroup>
                </div>

                <DatePicker
                  id='expiry-date-picker'
                  onChange={this.handleExpiryChange}
                  datePickerType='single'
                >
                  <DatePickerInput
                    id='expiry-input'
                    name='expiry'
                    labelText='Expiration Date'
                    placeholder='mm/dd/yyyy'
                    onChange={this.handleExpiryChange}
                    onBlur={this.handleDirty}
                    value={expiry || ''}
                    invalid={errors.expiry ? true : false}
                    invalidText={errors.expiry}
                  />
                </DatePicker>
              </div>
            </div>
            {shareMode === 'email' ?
              <div className='formRow'>
                <TextInput
                  id='emails'
                  name='emails'
                  labelText='Share to Email *'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={emails || ''}
                  invalid={errors.emails ? true : false}
                  invalidText={errors.emails || ''}
                  autoComplete='off'
                />
              </div> :
              <>
              <div className='formRow text-clip' style={{ display: 'flex', flexDirection: 'row' }}>
                <TextInput
                  id='link'
                  name='link'
                  labelText='Link'
                  style={{ width: '94%' }}
                  disabled={true}
                  value={link || ''}
                  className='long-link'
                />
                <div className='linkIcon'
                  onClick={this.submit}
                >
                  <img src={copyLinkIcon} alt='icon' className='copy-link'/>
                </div>
              </div>
              </>
            }
            <div className='formRow'>
              <TextInput
                id='password'
                name='password'
                type='password'
                labelText='Password'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={password || ''}
                invalid={errors.password ? true : false}
                invalidText={errors.password || ''}
                autoComplete='new-password'
              />
            </div>
            {
              shareMode === 'email' &&
            <div className='bx--form-message formRow'>
              <TextArea
                id='message'
                name='message'
                labelText='Message'
                onChange={this.handleChange}
                onBlur={this.handleDirty}
                value={message || ''}
                invalid={errors.message ? true : false}
                invalidText={errors.message || ''} />
            </div>
            }
          </Form>
          {(shareMode === 'link' && expiry !== '') &&
              <p className='link-expiration-text'>
              This link expires on { expiry ? expiry : `${defaultExpiry}, 30 days from now.`}
              </p>
          }
        </div>
        {requestError && (
          <InlineNotification
            kind='warning'
            title='Share failed.'
            subtitle='We weren&rsquo;t able to send your share request. Please try again later.'
            hideCloseButton={true}
          />
        )}
      </Modal>
    )
  }
}

export default compose(
  graphql(SHARE_FILES, {
    name: 'shareFiles',
    props: (data) => ({
      shareFiles: (variables) => data.shareFiles({ variables })
    })
  }),
  ShareLink,
  WithAlertModal
)(CreateShareModal)

import React from 'react'
import { Route } from 'react-router-dom'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'
import { GET_PROJECTS } from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import FileTable from '../components/FileTable'
import SectionContainer from '../components/SectionContainer'
import CreatePagesHeader from '../components/CreatePagesHeader'


class ProjectFilesList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
      allProjects: []
    }
  }
     getSearchTerm=(value)=>{
       this.setState({
         searchTerm: value
       })
     }

     getBreadcrumb(file, folders, currentFolder = false) {
       let breadcrumb = [
         {
           href: `/project`,
           name: 'Files'
         }
       ]

       let path = []
       if (currentFolder) {
         let hrefPath = `/project`

         if (currentFolder.path.length > 0) {
           currentFolder.path.forEach(item => {
             hrefPath = `${hrefPath}/${item.id}`
             breadcrumb.push({
               href: hrefPath,
               name: item.name
             })

             path.push(item.name)
           })
         }

         hrefPath = `${hrefPath}/${currentFolder.id}`
         breadcrumb.push({
           href: hrefPath,
           name: currentFolder.name
         })

         path.push(currentFolder.name)
       }

       return { path, breadcrumb }
     }

     render() {
       return (
        <>


          {this.props.location.pathname === this.props.match.url &&
            // gets list of projects
            <Query query={GET_PROJECTS}

              fetchPolicy='cache-and-network'
            >
              {({ loading, error, data, refetch, networkStatus }) => {
                if (loading && networkStatus !== 6 && networkStatus !== 4) {
                  return null
                }

                if (error) {
                  return `Error! ${error.message}`
                }

                const projectFilesTableRows = []

                // feeds from GET_PROJECTS query
                data.getProjects.map(item => {
                  const newItem = item
                  newItem.fileLink = true
                  projectFilesTableRows.push(item)
                  return null
                })

                projectFilesTableRows.sort((a, b)=>{
                  if (a.name < b.name) {
                    return -1
                  } else if (a.name > b.name) {
                    return 1
                  }
                  return 0
                })
                const tableRowLimit = projectFilesTableRows.sort().slice(0, 5)
                
                return (
            <>
            <CreatePagesHeader
              getSearchTerm={this.getSearchTerm}
              showSearch={true}
              subtitle='Project Files'
              linkText='View All'
              linkUrl={'/files/projects'}
            />
            <div className='sections-container project-files'>
              <SectionContainer>
                <FileTable
                  rows={window.location.pathname === '/files' ? tableRowLimit : projectFilesTableRows}
                  nameCellClass='files-table'
                  manualRerender={this.props.manualRerender}
                  searchTerm={this.state.searchTerm}
                  showSearch={false}
                  projectId={null}
                  folderId={this.props.match.params.folderId}
                  fileFavourites={this.props.fileFavourites}
                  folderFavourites={this.props.folderFavourites}
                  showSkeleton={this.props.loading && this.props.networkStatus !== 6 && this.props.networkStatus !== 4}
                  refetch={refetch}
                  favourite
                  batchActions
                  actions
                  pagination={window.location.pathname !== '/files'}
                  canCreate={true}
                  canDownload={true}
                  canDelete={true}
                  comments={false}
                  sortDirection='ASC'
                  sortColumn='name'
                  projectFilesHeaderFormat
                  sortBy='Title'
                  perPage={5}
                  projectFiles
                />
              </SectionContainer>
            </div>
                </>)
              }}
            </Query>
          }
          <Route path={`${this.props.match.url}/:folderId`}
            render={(props) => (
              <ProjectFilesList {...props}
                uploadModal={this.props.uploadModal}
                uploads={this.props.uploads}
                setProjectIdAndFolderId={this.props.setProjectIdAndFolderId}
                fileFavourites={this.props.fileFavourites}
                folderFavourites={this.props.folderFavourites} />
            )} />
        </>
       )
     }
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser
)(ProjectFilesList)

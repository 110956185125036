import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { ASSIGN_MULTIPLE_CREDIT } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import GetRecording from '../../hocs/queries/GetRecording'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import CreditForm from '../../components/CreditForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class RecordingCreditCreate extends React.Component {
  render() {
    const { project, recording, history } = this.props
    return (
      <>
        <Helmet>
          <title>
            Create Credit | {project.name} | {recording ? recording.name : ''}
          </title>
        </Helmet>
        <main className='main breadcrumb-recording createRecordingCredit'>
          <CreatePagesHeader
            title={recording ? `Assign Credit to ${recording.name}` : 'Assign New Recording Credit'}
            subtitle='Recording Credit'>
            {recording && <Breadcrumb
              items={[
                {
                  href: `/projects`,
                  name: 'Projects'
                },
                {
                  href: `/projects/${project.id}`,
                  name: project.name
                },
                {
                  href: `/projects/${project.id}/recordings`,
                  name: 'Recordings'
                },
                {
                  href: `/projects/${project.id}/recordings/${recording.id}`,
                  name: recording.name
                },
                {
                  href: `/projects/${project.id}/recordings/${
                    recording.id
                  }/credits/create`,
                  name: 'New Recording Credit'
                }

                // {
                //   href: `/projects/${this.props.project.id}`,
                //   name: this.props.project.name
                // },
                // {
                //   href: `/projects/${this.props.project.id}/recordings/${this.props.recording.id}`,
                //   name: this.props.recording.name
                // },
                // {
                //   href: `/projects/${this.props.project.id}/recordings/${this.props.recording.id}`,
                //   name: this.props.recording.name
                // }
                // {
                //   href: `/projects/${this.props.project.id}/recordings/${
                //     this.props.recording.id
                //   }#credits`,
                //   name: 'Credits'
                // },
                // {
                //   href: `/projects/${this.props.project.id}/recordings/${
                //     this.props.recording.id
                //   }/credits/create`,
                //   name: 'Create Credit'
                // }
              ]}
            />}
            {!recording && <Breadcrumb
              items={[
                {
                  href: `/projects`,
                  name: 'Projects'
                },
                {
                  href: `/projects/${project.id}`,
                  name: project.name
                },
                {
                  href: `/projects/${project.id}/credits`,
                  name: 'Project Credits'
                },
                {
                  href: `/projects/${project.id}/recording-credits/create`,
                  name: 'New Recording Credit'
                }
              ]}
            />}
          </CreatePagesHeader>
          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={ASSIGN_MULTIPLE_CREDIT}
                update={() => {
                  history.goBack(-1)
                }}
              >
                {(assignMultipleCredit, { loading, error }) => (
                  <CreditForm
                    isRecordingCredit={true}
                    projectId={project.id}
                    discardForm={true}
                    contributionType='recording'
                    contribution={recording ? {
                      value: recording.id,
                      label: recording.name
                    }: ''}
                    onSubmit={state => {
                      let data = { ...state }
                      Object.keys(data).forEach(key => data[key] === '' && (data[key] = null))
                      assignMultipleCredit({
                        variables: {
                          ...data,
                          performing: data.performing ? true : false
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error}
                  />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetRecording
)(RecordingCreditCreate)

import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

import { notInFuture } from '../utils/Validator'

export default Schema(
  {
    recordingTypeId: Field()
      .string()
      .required({ message: Errors.Required }),
    recordingTypeUserDefinedValue: Field()
      .string(),
    songId: Field()
      .string()
      .required({ message: Errors.Required }),
    isrc: Field(),
    subtitle: Field(),
    version: Field()
      .string(),
    recordedOn: Field()
      .custom(notInFuture, {
        message: 'The date cannot be in the future'
      }),
    mixedOn: Field()
      .custom(notInFuture, {
        message: 'The date cannot be in the future'
      }),
    duration: Field(),
    languageId: Field(),
    keySignature: Field(),
    timeSignature: Field(),
    tempo: Field(),
    description: Field()
  },
  { verbose: true, async: true }
)

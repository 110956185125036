import React from 'react'
import { Form, Button, TextInput } from 'carbon-components-react'

import { SelectStandard as Select } from '../components/Select'

import Validator from '../utils/Validator'
import PartyContactValidator from '../validators/PartyContact'

const initialState = {
  type: '',
  selectedType: false,
  value: '',
  name: '',

  initialErrors: {},
  errors: {},

  isUploading: false,
  uploadComplete: false
}

export default class extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.validator = PartyContactValidator

    this.state.type = props.type || 'email'
    this.state.value = props.value || ''
    this.state.name = props.name || ''
    this.state.selectedType = {
      value: props.type,
      label: props.type === 'phone' ? 'Phone' : 'E-Mail'
    }
  }

  submit = () => {
    this.props.onSubmit(this.state)
    this.setState({
      type: '',
      selectedType: false,
      value: '',
      name: '',

      initialErrors: {},
      errors: {},

      isUploading: false,
      uploadComplete: false
    })
  }

  handleTypeChange(selected) {
    const phoneValue = this.props.type === 'phone' &&
     selected.value === 'phone' ? this.props.value : ''
    const value = this.props.type === 'email' &&
     selected.value === 'email' ? this.props.value : phoneValue
    this.setState({
      ...this.state,
      type: selected.value,
      selectedType: selected,
      errors: {},
      value: value
    })
  }
  handleEmailChange(e) {
    // checks if email field
    if (this.state.selectedType.label === 'E-Mail') {
      // limits email characters to 50
      if (e.target.value.length <= 50) {
        this.handleChange(e)
      } else {
        // const { value } = e.target
        let maxLength = 'Maximum length'
        this.setState({
          ...this.state,
          errors: {
            ...this.state.errors,
            value: maxLength
          }
        })
        return
      }
    }
    // if not email
    this.handleChange(e)
  }

   containsOnlyNumbers=(value)=> {
     let validate = new RegExp('^[0-9 \-]+$') // eslint-disable-line no-useless-escape
     return validate.test(value)
   }
   handleDirtyPhoneOrEmail=(e)=> {
     const { name, value } = e.target

     let isValid
     try {
       isValid = this.validator[name].validate(value, {
         verbose: true,
         values: this.state
       })
     } catch {
       isValid = 'Invalid'
     }

     if (isValid !== true) {
       this.setState({
         ...this.state,
         errors: {
           ...this.state.errors,
           [name]: isValid
         }
       })
     } else {
       this.setState({
         ...this.state,
         errors: {
           ...this.state.errors,
           [name]: undefined // eslint-disable-line no-undefined
         }
       })
     }
   }

   validateChange = (e) => {
     const { name, value } = e.target
     if (this.state.type === 'phone' && !this.containsOnlyNumbers(value)) {
       this.setState({
         ...this.state,
         value: '',
         errors: {
           ...this.state.errors,
           [name]: 'Please enter only numbers'
         }
       })
       return
     }
     if (this.containsOnlyNumbers(value)) {
       this.setState({
         ...this.state,
         errors: {
           ...this.state.errors,
           [name]: undefined // eslint-disable-line no-undefined
         }
       })
     }
     this.handleChange(e)
   }
   render() {
     const typeOptions = [
       {
         value: 'email',
         label: 'E-Mail'
       },
       {
         value: 'phone',
         label: 'Phone'
       }
     ]
     return (
       <div className='form'>
         <Form onSubmit={this.handleSubmit} autoComplete='off'>
           <div className='formRow'>
             <TextInput
               id='name'
               name='name'
               labelText='Name *'
               onChange={this.handleChange}
               onBlur={this.handleDirty}
               value={this.state.name || ''}
               invalid={this.state.errors.name ? true : false}
               invalidText={this.state.errors.name || ''}
             />
           </div>

           <div className='formRow'>
             <Select
               id='type'
               name='type'
               labelText='Type *'
               onChange={this.handleTypeChange}
               onBlur={this.handleDirty}
               value={this.state.selectedType}
               placeholder='Select a type'
               invalid={this.state.errors.type ? true : false}
               invalidText={this.state.errors.type}
               options={typeOptions}
             />
           </div>
           <div className='formRow'>
             <TextInput
               id='value'
               name='value'
               labelText={this.state.type === 'email' ? 'Email Address *' : 'Phone Number *'}
               onChange={this.handleEmailChange}
               onBlur={this.handleDirty}
               value={this.state.value || ''}
               invalid={this.state.errors.value ? true : false}
               invalidText={this.state.errors.value || ''}
             />
           </div>
           <div className='formRow alt-footer'>
             <button type='button' className='bx--btn bx--btn--secondary bx--btn--mt' onClick={()=>{
               const closeModal = this.props.close
               closeModal()
             }}>Cancel</button>
             <Button type='submit'>{this.props.loading ? 'Saving...' : 'Create'}</Button>
           </div>
         </Form>
       </div>
     )
   }
}

import React from 'react'
import { withRouter } from 'react-router'
import ResponsiveTable from './ResponsiveTable'
import { DateTime } from 'luxon'
import { compose } from 'react-apollo'


// HOC's
import WithComments from '../hocs/WithComments'
import WithDownloadModal from '../hocs/WithDownloadModal'
import WithFilePreviewModal from '../hocs/WithFilePreviewModal'
import DeleteFiles from '../hocs/mutations/DeleteFiles'
import WithWaveformPreviewModal from '../hocs/WithWaveformPreviewModal '

class FileSearchTable extends React.PureComponent {
  state = {
    createFolderModalOpen: false,
    folderName: '',
    createFolderError: ''
  }

  constructor(props) {
    super(props)

    this.getHeaders = this.getHeaders.bind(this)
    this.getRowActions = this.getRowActions.bind(this)
    this.isFavourite = this.isFavourite.bind(this)
  }

  getHeaders = (() => {
    if (this.props.projectFiles) {
      const favouriteHeaders = [
        {
          key: 'project.name',
          header: 'Project',
          formatter: (data, row) => row.project.name
        },
        {
          key: 'project.artist.name',
          header: 'Artist',
          formatter: (data, row) => (row.project && row.project.artist ? row.project.artist.name : '-')
        },
        {
          key: 'name',
          header: 'Name'
        },
        {
          key: 'type',
          header: 'File Type',
          // Cache the file type elements
          formatter: (() => {
            const types = {}
            return (type) => {
              if (!types[type]) {
                types[type] = (
                  <div className={`fileType fileType--compact fileType--${type}`}>
                    {type ? type : 'Folder'}
                  </div>
                )
              }
              return types[type]
            }
          })()
        },
        {
          key: 'prettySize',
          header: 'Size',
          formatter: (data) => (data ? data : '-')
        },
        {
          key: 'createdAt',
          header: 'Added',
          formatter: (data) => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
        }
      ]

      return () => favouriteHeaders
    }

    const headers = [
      {
        key: 'name',
        header: 'Name'
      },
      {
        key: 'type',
        header: 'File Type',
        // Cache the file type elements
        formatter: (() => {
          const types = {}
          return (type) => {
            if (!types[type]) {
              types[type] = (
                <div className={`fileType fileType--compact fileType--${type}`}>
                  {type ? type : 'Folder'}
                </div>
              )
            }
            return types[type]
          }
        })()
      },
      {
        key: 'prettySize',
        header: 'Size',
        formatter: (data) => (data ? data : '-')
      },
      {
        key: 'createdAt',
        header: 'Added',
        formatter: (data) => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
      }
    ]

    return () => headers
  })()

  getRowActions = (() => {
    const actions = [
    ]

    return () => actions
  })()

  isFavourite(row) {
    if (row.type) {
      return this.props.fileFavourites.indexOf(parseInt(row.id, 10)) > -1
    }

    return this.props.folderFavourites.indexOf(parseInt(row.id, 10)) > -1
  }

  generateLink(row) {
    // eslint-disable-next-line no-underscore-dangle
    const fileType = row.__typename.toLowerCase()
    if ((row.isPreviewable && this.canDownload) || fileType === 'file') {
      if (row.projectId) {
        if (row.folderId) {
          return `/projects/${row.projectId}/files/${row.folderId}`
        }
        return `/projects/${row.projectId}/files#all`
      }
      if (row.folderId) {
        return `/files/${row.folderId}`
      }
      return `/files/myfiles`
    }

    if (row.type) {
      return null
    }
    if (this.canRestore) {
      return `#`
    }

    if (row.projectId) {
      return `/projects/${row.projectId}/files/${row.id}`
    }

    if (row.fileLink) {
      return `/projects/${row.id}/files#all`
    }

    if (row.songLink) {
      return `/songs/${row.id}/files#song`
    }

    return `/files/${row.id}`
  }

  getFavouriteType(row) {
    if (row.type) {
      return 'file'
    }

    return 'folder'
  }

  render() {
    let props = { ...this.props }

    // delete props.rows
    delete props.fileFavourites
    delete props.folderFavourites

    return (
      <>
        <ResponsiveTable headers={this.getHeaders()}
          {...props}
          actions={this.props.actions && this.getActions()}
          batchActions={this.props.batchActions && this.getBatchActions()}
          rowActions={this.props.rowActions && this.getRowActions()}
          isFavourite={this.isFavourite}
          linkColumn='name'
          generateLink={this.generateLink}
          getFavouriteType={this.getFavouriteType}
          emptyText='No Files'
        />
      </>
    )
  }
}

export default compose(
  DeleteFiles,
  WithDownloadModal,
  WithFilePreviewModal,
  WithWaveformPreviewModal,
  WithComments,
  withRouter
)(FileSearchTable)

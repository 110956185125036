import React, { useState, useEffect } from 'react'
import { compose } from 'react-apollo'

import GetPlatformImages from '../../hocs/queries/GetPlatformImages'
import AppBranding from '../../components/AppBranding'
import LoadingDots from '../../components/ui/LoadingDots'
import { GET_SHARE_PLAYLIST } from '../../graphql/queries'
import PlaylistShareForm from '../../components/PlaylistShareForm'
import ApolloClient from '../../utils/Apollo'
import { withRouter } from 'react-router'

const PlaylistShare = ({
  platformImages,
  match: {
    params: { token, uuid }
  },
  history
}) => {
  // Background image for layout
  const [image] = platformImages.filter(i => i.location === 'login')
  const [showLoader, setShowLoader] = useState(false)
  const [showError, setShowError] = useState(null)
  const [showForm, setShowForm] = useState(false)


  const errorTransform = (error) => {
    if (error.isExpired) {
      setShowError('Your access to these files has expired.')
    } else if (error.isPasswordInvalid) {
      setShowError('Invalid Password.')
    } else if (error.isShareInvalid) {
      setShowError('This link is invalid.')
    } else if (error.isUserInvalid) {
      setShowError('You don’t have access to these files.')
    } else if (error.isTokenInvalid) {
      setShowError('This link is invalid.')
    }
  }


  // Password form
  function handleDownloadShareFormSubmit({ password }) {
    return new Promise(() => {
      setShowLoader(true)
      ApolloClient.query({
        query: GET_SHARE_PLAYLIST,
        fetchPolicy: 'network-only',
        variables: {
          uuid,
          token,
          password
        }
      }).then(({ data }) => {
        setShowLoader(false)
        if (password.length > 0) {
          errorTransform(data.getSharePlaylist.errors)
        }
        if ((data.getSharePlaylist.success || !data.getSharePlaylist.isPasswordRequired) && data.getSharePlaylist.playlist) {
          history.push({ pathname: `/public-playlist/${data.getSharePlaylist.playlist.id}`, state: {
            uuid,
            token,
            password,
            playlist: data.getSharePlaylist.playlist
          } })
        } else if (data.getSharePlaylist.isPasswordRequired) {
          setShowForm(true)
        }
      })
    })
  }

  useEffect(() => {
    handleDownloadShareFormSubmit({ password: '' })
  }, [])

  if (showForm) {
    return (
      <div className='login'>
        <div className='loginBackground'>
          <img src={image.fullPath} alt={image.altText} />
          {image.caption && (
            <div className='loginBackgroundCredit'>{image.caption}</div>
          )}
        </div>
        <div className='loginFormBackground'>
          <div className='loginFormWrapper linkShare'>
            <AppBranding />
            <div className='loginForm'>
              <PlaylistShareForm onSubmit={handleDownloadShareFormSubmit} />
            </div>
            <div>
              {
                showLoader && <LoadingDots />
              }
              {
                showError && <p className='feedback-text'>
                  {showError}
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <></>
}

export default compose(GetPlatformImages, withRouter)(PlaylistShare)

import React from 'react'
import Helmet from 'react-helmet-async'
import { Elements, StripeProvider } from 'react-stripe-elements'

import GetUser from '../hocs/queries/GetUser'

// Our Base Components
import SubscriptionDetails from '../components/SubscriptionDetails'
import UpdateSubscription from '../components/UpdateSubscription'
import SectionContainer from '../components/SectionContainer'
import MenuSwitcher from '../components/MenuSwitcher'
import CreatePagesHeader from '../components/CreatePagesHeader'
import ShareWithPeople from '../components/ShareWithPeople'
import { ToastNotification } from 'carbon-components-react'
import ApolloClient from '../utils/Apollo'
import { compose } from 'react-apollo'
import { GET_USER_REFERRAL_CODE } from '../graphql/queries'

class Subscription extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showBillingInformation: false,
      successToastIsVisible: false,
      discountCode: '',
      codeUseCount: null
    }
    this.hideSuccessToast = this.hideSuccessToast.bind(this)
  }
  showBillingInfo = ()=>{
    this.props.history.push('/account/subscription/billing')
  }
  showSubscription = ()=>{
    this.setState({
      showBillingInformation: false
    })
  }
  hideSuccessToast() {
    this.setState({
      successToastIsVisible: false
    })
  }
  copyText = (shareCode) =>{
    navigator.clipboard.writeText(shareCode)
    this.setState({ successToastIsVisible: true })
  }

  getUserReferralCode = () =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_USER_REFERRAL_CODE,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        const code = data.getUserReferralCode.code
        const count = data.getUserReferralCode.count
        this.setState({ discountCode: code })
        this.setState({ codeUseCount: count })
        resolve(code)
      })
    })

  componentDidMount() {
    this.getUserReferralCode()
  }
  render() {
    const user = this.props.user
    const subscription = user.subscriptions[0]
    const hasSavedCard = user.cardLastFour !== null
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <>
          <Helmet>
            <title>Subscription</title>
          </Helmet>
          < main className='main account-subscription'>
            <CreatePagesHeader
              subtitle='Subscription'
              actionOnClick={this.showBillingInfo}
            >
              <div className='switcher-Style'>
                <MenuSwitcher />
              </div>

            </CreatePagesHeader>
            <div className='sections-container'>
              <SectionContainer>
                <SubscriptionDetails stripePlan={subscription.stripePlan} />
                <Elements>
                  <UpdateSubscription hasSavedCard={hasSavedCard}
                    stripePlan={subscription.stripePlan}
                    cardLastFour={user.cardLastFour} />
                </Elements>
              </SectionContainer>

            </div>
            <CreatePagesHeader
              subtitle='Share VEVA Collect with the people you Love!'
            />
            <div className='sections-container'>

              <ShareWithPeople copyText={this.copyText} discountCode={this.state.discountCode} codeUseCount={this.state.codeUseCount} />
            </div>
          </main>
          { this.state.successToastIsVisible && (
            <ToastNotification
              kind='success'
              title={'Your Personal Discount Code has been copied to clipboard!'}
              subtitle=''
              caption=''
              onCloseButtonClick={ ()=> this.hideSuccessToast() }
              timeout={6000}
            />
          ) }
        </>
      </StripeProvider>
    )
  }
}

export default compose(GetUser)(Subscription)

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { Query } from 'react-apollo'

import { GET_ALL_RECORDING_SESSIONS } from '../../graphql/queries'
import { getUserFavouritesByType } from '../../utils/selectors'
import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import * as permissions from '../../utils/permissions'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import SessionTable from '../../components/SessionTable'
import GetRecording from '../../hocs/queries/GetRecording'
import WithAlertModal from '../../hocs/WithAlertModal'
import Hero from '../../components/Hero'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import { filterPermissions } from '../../utils/permissions'
import { getTags } from '../../utils/permissions'


class ProjectRecordingSessions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
  getSearchTerm = value => {
    this.setState({
      searchTerm: value
    })
  };
  render() {
    const { project, user, history, match, alert, recording } = this.props
    const favourites = getUserFavouritesByType(user)
    const isRecordingCollaborator = permissions.hasRecordingAccess(
      project.id,
      recording.id
    )

    const isProjectOwner = permissions.isProjectOwner(project.id, user)
    const can = isProjectOwner || permissions.can(project.id, 'session')
    const canCreate = isProjectOwner || can.create
    const canUpdate = isProjectOwner || can.update
    const canDelete = isProjectOwner || can.delete

    const sessionPermissions = permissions.can(project.id, 'session')

    return (
      <>
        <Helmet>
          <title>Sessions | {project.name}</title>
        </Helmet>
        <main className='main projectRecordingSession'>
          <div className={`${project.locked ? 'ghosted-wrapper' : ''}`}>
            <Hero
              showSearch={true}
              getSearchTerm={this.getSearchTerm}
              subtitle={`${recording.name} Sessions`}
              btnText={canCreate ? 'Add' : false}
              btnOnClick={() => {
                if (project.locked) {
                  alert('Project Credits are locked. Please unlock to edit.', {
                    title: '   ',
                    className: 'credits-alert'
                  })
                  return
                }
                history.push(`/projects/${project.id}/sessions/create`)
              }}
            >
              <Breadcrumb
                items={[
                  {
                    href: `/projects`,
                    name: 'Projects'
                  },
                  {
                    href: `/projects/${project.id}`,
                    name: project.name
                  },
                  {
                    href: `/projects/${project.id}/recordings`,
                    name: 'Recordings'
                  },
                  {
                    href: `/projects/${project.id}/recordings/${recording.id}`,
                    name: recording.name
                  },
                  {
                    href: `/projects/${project.id}/recordings/${
                      recording.id
                    }/sessions`,
                    name: 'Recording Sessions'
                  }
                ]}
              />
            </Hero>
            <CreatePagesHeader
              subtitle='Recording Sessions'
              withDivider={false}
              // eslint-disable-next-line no-nested-ternary
              tags={!isProjectOwner && !isRecordingCollaborator ? getTags(filterPermissions(sessionPermissions)) : isRecordingCollaborator ? [{ tagName: 'Full Access' }] : []}
            />

            <div className='mainContainer'>
              <SectionContainer>
                <Query
                  query={GET_ALL_RECORDING_SESSIONS}
                  variables={{ recordingId: match.params.recordingId }}
                  fetchPolicy='cache-and-network'
                >
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }
                    return (
                      <SessionTable
                        rows={
                          data.getSessionsByRecording
                            ? data.getSessionsByRecording
                            : []
                        }
                        searchTerm={this.state.searchTerm}
                        locked={project.locked}
                        showSearch={false}
                        showSkeleton={loading}
                        projectId={project.id}
                        favourites={favourites.session || []}
                        pagination
                        canUpdate={canUpdate}
                        canDelete={canDelete}
                        onDelete={(cache, { data: deleteSession }) => {
                          const { getSessions } = cache.readQuery({
                            query: GET_ALL_RECORDING_SESSIONS,
                            variables: {
                              projectId: match.params.projectId
                            }
                          })
                          const sessions = {
                            ...getSessions,
                            data: getSessions.data.filter(
                              session =>
                                parseInt(session.id, 10) !==
                                parseInt(deleteSession.id, 10)
                            )
                          }

                          cache.writeQuery({
                            query: GET_ALL_RECORDING_SESSIONS,
                            variables: {
                              projectId: match.params.projectId
                            },
                            data: {
                              getSessions: sessions
                            }
                          })
                        }}
                        refetch={refetch}
                        actions={true}
                      />
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetRecording,
  WithAlertModal
)(ProjectRecordingSessions)

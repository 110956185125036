import React from 'react'
import { Search } from 'carbon-components-react'

const SearchField = props => (
  <div className={props.className}>
    <Search id={props.id}
      labelText={props.label || 'Search'}
      placeHolderText={props.placeholder || 'Search'}
      value={props.value || ''}
      name={props.name || ''}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown} />
  </div>
)

export default SearchField



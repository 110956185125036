import React from 'react'

function AlertListModal({ list }) {
  return (
    <div className='alert-list'>
      <ul>
        {list.map(message => (
          <li>
            &bull; {message.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AlertListModal

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import GetUser from '../hocs/queries/GetUser'
import WithUploads from '../hocs/WithUploads'
import WithUploadModal from '../hocs/WithUploadModal'
import Breadcrumb from '../components/Breadcrumb'
import Hero from '../components/Hero'

import ShareList from '../components/tables/ShareList'

class AllShares extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldRerender: 'foo'

    }
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.props.uploadModal.open('/', '', null)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.state !== this.props.location.state && this.props.location.state) {
      this.props.uploadModal.open('/', '', null)
    }
  }
manualRerender = (value) => {
  this.setState({
    shouldRerender: `unel${Math.floor(Math.random() * 10)}`
  })
}
render() {

  return (
      <>
        <Helmet>
          <title>Files and Playlist Shares</title>
        </Helmet>
        <main className='main files-share'>
          <Hero
            src='ph.hero.projects.jpg'
            alt='File Shares'
            subtitle='File Shares'

          >
            <Breadcrumb items={[
              {
                href: `/files`,
                name: 'Files'
              },
              {
                href: `/files/shares`,
                name: 'File Shares'
              }
            ]} />
          </Hero>
          <ShareList
            shouldRerender={this.state.shouldRerender}
            {...this.props}
            perPage={50}
          />

        </main>
      </>
  )
}
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser
)(AllShares)

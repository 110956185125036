import { compose } from 'react-apollo'
import React, { useEffect, useState } from 'react'

import { GetGradientFromId } from '../utils/constants'

import CreateUserFavourite from '../hocs/mutations/CreateUserFavourite'
import DeleteUserFavourite from '../hocs/mutations/DeleteUserFavourite'
import WithComments from '../hocs/WithComments'
import WithWaveformPreviewModal from '../hocs/WithWaveformPreviewModal '
import WithPlaylistShareModal from '../hocs/WithPlaylistShareModal'
import { calcPlaylistTotalDuration } from '../utils/functions'
import { Tooltip } from 'carbon-components-react'
import WithAlertModal from '../hocs/WithAlertModal'

import PauseIcon from '../assets/waveform/pause.svg'
import PlayIcon from '../assets/images/playIcon.svg'
import ShareIcon from '../assets/images/share.svg'
import WhiteOutlineStarIcon from '../assets/images/white-outline-star.svg'
import OrangeFilledStarIcon from '../assets/images/orange-filled-star.svg'
import { useMemo } from 'react'

const ProjectHero = (props) => {
  const [togglePlay, setTogglePlay] = useState(false)
  const [clipValue, setClipValue] = useState('')
  const [isClipped, setClipped] = useState(false)
  const [isFavourite, setFavourite] = useState(props.isFavourite)
  const [favouriteIcon, setFavouriteIcon] = useState(props.isFavourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon)

  const initials = props
    .title.toUpperCase().replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g).join('').substr(0, 4)

  const setInitialClip = ()=>{
    if (props.description && props.description.length >= 413) {
      setClipValue(370)
      setClipped(true)
    } else {
      setClipValue(props.description ? props.description.length : 0)
    }
  }

  const getBreakPoints = ()=>{
    const { innerWidth: width } = window
    if (width <= 1024) {
      setClipValue(190)
    } else if (width > 1024) {
      setInitialClip()
    }
  }

  const handlePlayPause = () => {
    if (props.files.length > 0) {
      props.waveformPreviewModal.open(props.files, setTogglePlay)
    }
  }

  const handleShare = () => {
    props.playlistShareModal.open('create', { files: props.files }, props.id)
  }

  const handleFavorites = () => {
    setFavouriteIcon(!isFavourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon)
    setFavourite(!isFavourite)

    if (isFavourite) {
      props.deleteUserFavourite(props.id, 'playlist')
    } else {
      props.createUserFavourite(props.id, 'playlist')
    }
    props.refetchPlaylist()
  }

  useEffect(() => {
    window.addEventListener('resize', getBreakPoints)
    setInitialClip()
  }, [props.description])

  const PlayPauseIcon = togglePlay ? PauseIcon : PlayIcon

  return (
    <div className={'playlistHero' + (!props.image ? ' playlistHero--no-image' : '')}>
      <div className='playlistHeroContent'>
        <div
          className='playlistHeroThumbnail'
          style={
            props.image
              ? {}
              : {
                background: useMemo(()=> GetGradientFromId(props.id), [props.id])
              }
          }
        >
          {props.image ? (
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}playlists/${props.image}`}
              alt={props.title}
            />
          ) : <div className='playlistCover'>
            <span>{initials}</span>
          </div>}
        </div>
        <div className='playlistHeroBody'>
          <header>
            {props.children ? props.children : null }
            {props.title.length > 45 ?
              <Tooltip
                className='playlist-tooltip'
                direction='bottom'
                tabIndex={0}
                showIcon={false}
                triggerText={ <h1 className='trigger-text cutoff-project-title'>{props.title}</h1>}
              >
                <p>{props.title}</p>
              </Tooltip>
              : <h1 class="cutoff-project-title">{props.title}</h1>}

          </header>
          <p className='playlist-description'>
            {props.description ? <> {props.description.slice(0, clipValue) + '' }{isClipped ? <span
              onClick={()=>props.alert(`${props.description}`,
                { title: '   ', className: 'credits-alert' })}
              className='ellipsis-text'> ...more</span> : null}</> : null}
          </p>
          <div className='playlistHeroLinks'>
            <div onClick={handlePlayPause} className='play-icon'>
              <img src={PlayPauseIcon } alt='play' />
            </div>

            <img onClick={handleFavorites}
              src={favouriteIcon}
              className='favorites-playlist'
              alt='favorites' />

            <img className='playlistHeroLinks--share-icon'
              src={ShareIcon}
              alt='share'
              onClick={handleShare} />

            <p className='files-count' >{props.files ? props.files.length : 0} files</p>
            <p className='files-count' >{calcPlaylistTotalDuration(props.files)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(
  DeleteUserFavourite,
  CreateUserFavourite,
  WithComments,
  WithWaveformPreviewModal,
  WithAlertModal,
  WithPlaylistShareModal
)(ProjectHero)

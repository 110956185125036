import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    songTypeId: Field()
      .string()
      .required({ message: Errors.Required }),
    songTypeUserDefinedValue: Field(),
    title: Field()
      .string()
      .required({ message: Errors.Required }),
    subtitle: Field(),
    iswc: Field(),
    titleAlt: Field(),
    subtitleAlt: Field(),
    createdOn: Field(),
    notes: Field(),
    lyrics: Field(),
    description: Field()
  },
  { verbose: true, async: true }
)

import { graphql } from 'react-apollo'
import { EXPIRE_SHARE_LINK } from '../../graphql/mutations/share'

export default graphql(EXPIRE_SHARE_LINK, {
  name: 'expireShareDirectLink',
  props: data => {
    return {
      expireShareDirectLink: (uuid) =>
        data.expireShareDirectLink({
          variables: {
            uuid
          }
        })
    }
  }
})

import React from 'react'
import {
  Form,
  Button,
  TextInput
} from 'carbon-components-react'

import Validator from '../utils/Validator'
import ResetPasswordValidator from '../validators/ResetPassword'

const initialState = {
  password: '',
  passwordConfirmation: '',

  initialErrors: {},
  errors: {}
}

export default class extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.validator = ResetPasswordValidator
  }

  submit() {
    this.props.onSubmit(this.state)
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit} autoComplete='off'>
        <div className='formRow'>
          <TextInput id='password'
            name='password'
            type='password'
            labelText='New Password'
            onChange={this.handleChange}
            onBlur={this.handleDirty}
            value={this.state.password || ''}
            invalid={this.state.errors.password || this.props.error ? true : false}
            invalidText={this.state.errors.password || ''} />
        </div>
        <div className='formRow mb-0'>
          <TextInput id='passwordConfirmation'
            name='passwordConfirmation'
            type='password'
            labelText='Confirm New Password'
            onChange={this.handleChange}
            onBlur={this.handleDirty}
            value={this.state.passwordConfirmation || ''}
            invalid={this.state.errors.passwordConfirmation || this.props.error ? true : false}
            invalidText={this.state.errors.passwordConfirmation || ''} />
        </div>
        <div className='formRow mb-0'>
          <Button type='submit' className='loginSubmit'>
            { this.props.loading ? 'Processing...' : 'Continue' }
          </Button>
        </div>
      </Form>
    )
  }
}

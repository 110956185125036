import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Query } from 'react-apollo'

import { GET_SONGS } from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'

// Our Base Components
import Hero from '../components/Hero'
import SectionContainer from '../components/SectionContainer'
import SongTileList from '../components/SongTileList'
import EmptyInfoTile from '../components/EmptyInfoTile'
import WithAlertModal from '../hocs/WithAlertModal'


class SongsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }
   getSearchTerm=(value)=>{
     this.setState({
       searchTerm: value
     })
   }

   createSong = () => {
     this.props.history.push('/songs/create')
   }

   render() {
     const { searchTerm } = this.state
     return (
      <>
        <Helmet>
          <title>Songs</title>
        </Helmet>
        <main className='main'>
          <Hero src='ph.hero.projects.jpg'
            alt='Alt text'
            showSearch={true}
            getSearchTerm={this.getSearchTerm}
            subtitle='Songs'
            btnOnClick ={this.createSong}
            btnText= 'Add'
          />
          <div className='mainContainer playlist-empty'>
            <div className='songs-tiles project-files'>
              <SectionContainer>
                <Query query={GET_SONGS}
                  variables={{
                    userId: this.props.user.id
                  }}
                  fetchPolicy='network-only'>
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    let length = data.getSongs ? data.getSongs.length : null

                    if (!length && !loading) {
                      return (
                        <EmptyInfoTile
                          title='Create Song'
                          btnOnClick={this.createSong}
                        />
                      )
                    }

                    const filteredRows = data.getSongs && data.getSongs.filter(({ title })=> title.toLowerCase().includes(searchTerm.toLowerCase()))
                    return (
                      <SongTileList songs={searchTerm ? filteredRows : data.getSongs || []}
                        showLoader={loading}
                        withOptions
                        refetch={refetch}
                        user={this.props.user} />
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>
        </main>
      </>
     )
   }
}

export default compose(GetUser, WithAlertModal)(SongsPage)

import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'carbon-components-react'

export default class extends React.PureComponent {
  render() {
    // const className =  `breadcrumb ${this.props.className ? this.props.className : ''}`
    const { customHeaderClass } = this.props
    const headerClasses = customHeaderClass ? `${customHeaderClass} breadcrumb` : 'breadcrumb'
    if (!this.props.items || this.props.items.length === 0) {
      return null
    }
    return (

      // <Breadcrumb className={className} noTrailingSlash={true}>
      <Breadcrumb className={headerClasses} noTrailingSlash={true}>
        {this.props.items.map((item, j) => {
          const key = item.id ? item.id : item.href
          return (
            <BreadcrumbItem key={key ? key : j}>
              {item.href ?
                <Link to={item.href}>{item.name}</Link>
                :
                <button onClick={item.action}>{item.name}</button>
              }
            </BreadcrumbItem>
          )
        })}
      </Breadcrumb>
    )
  }
}

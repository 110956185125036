import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { CREATE_CREDIT } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import CreditForm from '../../components/CreditForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class ProjectCreditCreate extends React.Component {
  render() {
    let contributionType = (this.props.location.state && this.props.location.state.contributionType) || 'project'
    let title = contributionType ? contributionType.charAt(0).toUpperCase() + contributionType.slice(1) : ''
    return (
      <>
        <Helmet>
          <title>Create Credit | {this.props.project.name}</title>
        </Helmet>
        <main className='main'>
          <CreatePagesHeader
            title={`Assign Project Credit to ${this.props.project.name}`}
            subtitle='New Project Credit'
          > <Breadcrumb items={[
              {
                href: `/projects`,
                name: 'Projects'
              },
              {
                href: `/projects/${this.props.project.id}`,
                name: 'Projects'
              },
              {
                href: `/projects/${this.props.project.id}/credits`,
                name: 'Project Credits'
              },
              {
                href: `/projects/${this.props.project.id}/credits/create`,
                name: `New ${title} Credit`
              }
            ]} />
          </CreatePagesHeader>

          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={CREATE_CREDIT}
                update={() => {
                  this.props.history.push(`/projects/${this.props.project.id}/credits`)
                }}>
                {(createCredit, { loading, error }) => (
                  <CreditForm
                    projectId={this.props.project.id}
                    contribution={{
                      id: this.props.project.id,
                      name: this.props.project.name
                    }}
                    onSubmit={(state) => {
                      let data = { ...state }
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      createCredit({
                        variables: {
                          ...data,
                          performing: data.performing ? true : false
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    discardForm={true}
                    errors={error}
                    contributionType={contributionType}
                  />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject
)(ProjectCreditCreate)

import React from 'react'
import { ToastNotification } from 'carbon-components-react'

// HOW TO USE
//
// props.toaster('Are you sure you want to delete this song?', {
//   kind: 'success',
//   title: 'success',
// // })

const WithAlertModal = (WrappedComponent) => {
  return class extends React.PureComponent {
    state = {
      open: false,
      message: '',
      options: false
    }

    constructor(props) {
      super(props)

      this.close = this.close.bind(this)
      this.open = this.open.bind(this)
    }

    close() {
      this.setState({
        ...this.state,
        open: false
      })
    }

    open(message, options) {
      this.setState({
        ...this.state,
        open: true,
        message: message,
        options: { ...options }
      })
    }

    render() {
      let kind = 'info'
      let title = ''
      const { options, open } = this.state
      if (options.kind) {
        kind = options.kind
      }
      if (options.title) {
        title = options.title
      }
      return (
        <>
          <WrappedComponent {...this.props} onToasterClose = {this.close} toaster={this.open}/>

            {open && <ToastNotification
              kind={kind}
              caption=''
              subtitle={<span>{this.state.message}</span>}
              timeout={5000}
              title={title}
            />
            }

        </>
      )
    }
  }
}

export default WithAlertModal

import React from 'react'
import { ToastNotification } from 'carbon-components-react'
import ApolloClient from '../utils/Apollo'
import { GET_SHARES } from '../graphql/queries/share'
import SharePlaylistModal from '../components/modals/SharePlaylistModal'

const initialState = {
  open: false,
  files: [],
  projectId: null,
  folderId: null,
  onSuccess: null,
  type: null,
  playlistId: null
}

const WithPlaylistShareModal = WrappedComponent => {
  return class extends React.PureComponent {
    state = initialState;

    constructor(props) {
      super(props)

      this.state.projectId = props.projectId
      this.state.folderId = props.folderId

      this.doOpen = this.doOpen.bind(this)
      this.doClose = this.doClose.bind(this)
      this.onSuccess = this.onSuccess.bind(this)
      this.hideSuccessToast = this.hideSuccessToast.bind(this)
      this.handleGetShare = this.handleGetShare.bind(this)
      this.getType = this.getType.bind(this)
    }

    getType = type => {
      this.setState({ type })
    };

    doOpen(action, { files = [], share = {}, onSuccess = null }, playlistId) {
      // So function call can just pass share
      if (files.length < 1 && !!share.files) {
        files = share.files
      }

      this.setState({
        ...this.state,
        open: true,
        action,
        files,
        share,
        onSuccess,
        playlistId
      })
    }

    doClose() {
      this.setState(initialState)
    }

    onSuccess(type) {
      if (this.state.onSuccess) {
        this.state.onSuccess()
      }
      if (this.state.action === 'create') {
        this.setState({
          // Reset and hide modal
          ...initialState,
          // Show success feedback to user
          successToastIsVisible: true
        })
        this.handleGetShare()
        this.getType(type)
      } else {
        // this.doClose()
      }
    }

    hideSuccessToast() {
      this.setState({
        successToastIsVisible: false
      })
    }
    handleGetShare = () =>
      new Promise(resolve => {
        ApolloClient.query({
          query: GET_SHARES,
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          let shares = []
          data.getShares.forEach(sharesList => {
            shares.push({ sharesList })
          })
          resolve(shares)
        })
      });

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            playlistShareModal={{
              open: this.doOpen,
              close: this.doClose
            }}
          />
          {this.state.open && (
            <SharePlaylistModal
              refetch={this.props.refetch}
              files={this.state.files}
              share={this.state.share}
              open={this.state.open}
              onRequestClose={this.doClose}
              onSuccess={this.onSuccess}
              getType={this.getType}
              playlistId={this.state.playlistId}
            />
          )}
          {this.state.successToastIsVisible && (
            <ToastNotification
              kind='success'
              title={
                this.state.type === 'link'
                  ? 'Link copied to clipboard'
                  : 'Share invite sent'
              }
              subtitle=''
              caption=''
              onCloseButtonClick={() => this.hideSuccessToast()}
              timeout={6000}
            />
          )}
        </>
      )
    }
  }
}

export default WithPlaylistShareModal

import gql from 'graphql-tag'

export const RECORDING_TABLE_FIELDS = gql `
  fragment RecordingTableFields on Recording {
    id
    projectId
    name
    subtitle
    isrc
    recordedOn
    party {
      id
      name
    }
    song {
      id
      title
      subtitle
    }
    project {
      id
      name
      artist {
        id
        name
      }
    }
    version
  }
`

export const SESSION_TABLE_FIELDS = gql `
  fragment SessionTableFields on Session {
    id
    _id
    name
    projectId
    startedAt
    endedAt
    venue {
      id
      name
    }
    type {
      id
      name
    }
    createdAt
    updatedAt
    commentCount
  }
`

export const FILE_TABLE_FIELDS = gql `
  fragment FileTableFields on File {
    id
    projectId
    folderId
    type
    name
    prettySize
    isPreviewable
    regionCommentCount
    status
    createdAt
    commentCount
  }
`

export const FOLDER_TABLE_FIELDS = gql `
  fragment FolderTableFields on Folder {
    id
    projectId
    name
    readonly
    recording {
      id
    }
    path{
      id
      name
    }
    files {
      id
      projectId
      type
      name
      prettySize
      isPreviewable
      status
      createdAt
      commentCount
    }
    createdAt
    commentCount
  }
`

export const FAVOURITE_FIELDS = gql `
  fragment FavouriteFields on UserFavourite {
    resourceId
    resourceType
  }
`

export const COMMENT_FIELDS = gql `
  fragment CommentFields on Comment {
    id
    message
    createdAt
    resourceId
    resourceType
    user {
      firstName
      lastName
    }
  }
`

export const PROJECT_FIELDS = gql `
  fragment ProjectFields on Project {
    id
    userId
    name
    description
    image
    label {
      id
      name
    }
    artist {
      id
      name
    }
    user {
      id
      name
      email
    }
    number
    fileCount
    recordingCount
    locked
    createdAt
    updatedAt
    commentCount
    sessionCount
  }
`

export const COUNTRY_FIELDS = gql `
  fragment CountryFields on Country {
    id
    name
    isoCode
  }
`

export const RECORDING_FIELDS = gql `
  fragment RecordingFields on Recording {
    id
    name
    folderId
    party {
      id
      name
    }
    song {
      id
      title
    }
    sessions {
      id
      startedAt
      endedAt
    }
    subtitle
    type {
      id
      name
    }
    typeUserDefinedValue
    description
    isrc
    version
    recordedOn
    mixedOn
    duration
    language {
      id
      code
      name
    }
    keySignature
    timeSignature
    tempo
    createdAt
    updatedAt
    commentCount
  }
`

export const CREDIT_TABLE_FIELDS = gql `
  fragment CreditTableFields on Credit {
    id
    party {
      id
      userId
      name
      isni
      is_my
      ipiCae
      affiliations {
        id
        number
        affiliation {
          name
          type
        }
      }
    }
    role {
      id
      name
    }
    instrument {
      id
      name
    }
    creditRoleUserDefinedValue
    contributionType
    featureType
    split
    contribution {
      ... on Song {
        id
        name
      }
      ... on Project {
        id
        name
      }
      ... on Recording {
        id
        name
      }
      ... on Session {
        id
        name
      }
    }
  }
`

export const ASSIGN_MULTIPLE_CREDIT_TABLE_FIELDS = gql `
  fragment CreditTableFields on Credit {
    id
    featureType
    party {
      id
      name
      isni
    }
    role {
      id
      name
    }
    instrument {
      id
      name
    }
    creditRoleUserDefinedValue
    contributionType
    contribution {
      ...on Song {
        id
        name
      }
      ...on Project {
        id
        name
      }
      ...on Recording {
        id
        name
      }
      ...on Session {
        id
        name
      }
    }
  }
`

export const SONG_FIELDS = gql `
  fragment SongFields on Song {
    id
    userId
    fileCount
    image
    type {
      id
      name
    }
    typeUserDefinedValue
    sharedFromProject
    credits {
      ...CreditTableFields
      instrument {
        id
        name
      }
      split
    }
    recordings{
      id
      name
      folderId
      projectId
      party {
        id
        name
      }
      song {
        id
        title
      }
      sessions {
        id
        startedAt
        endedAt
      }
      subtitle
      type {
        id
        name
      }
      typeUserDefinedValue
      description
      isrc
      version
      recordedOn
      mixedOn
      duration
      language {
        id
        code
        name
      }
      keySignature
      timeSignature
      tempo
      createdAt
      updatedAt
      commentCount
    }
    fromRecording
    iswc
    createdOn
    title
    subtitle
    titleAlt
    subtitleAlt
    notes
    description
    lyrics
    createdAt
    updatedAt
    folderId
  }
  ${CREDIT_TABLE_FIELDS}
`

export const PARTY_FIELDS = gql `
  fragment PartyFields on Party {
    id
    userId
    type
    prefix
    title
    name
    firstName
    middleName
    lastName
    suffix
    isni
    ipiCae
    is_my
    birthDate
    deathDate
    comments
    createdAt
    updatedAt
    publishers {
      id
      publisher
      country {
          id
          name
      }
      createdAt
      updatedAt
    }
    contacts {
      id
      name
      type
      value
    }
    addresses {
      id
      line1
      city
      district
      postalCode
      country {
        id
        isoCode
        name
      }
    }
    affiliations {
      id
      number
      affiliation {
        name
        type
      }
    }
  }
`

export const PARTY_FIELDS_ARTIST = gql `
  fragment PartyFields on Party {
    id
    userId
    type
    prefix
    title
    name
    firstName
    middleName
    lastName
    suffix
    is_my
    isni
    ipiCae
    birthDate
    deathDate
    comments
    createdAt
    updatedAt
    contacts {
      id
      name
      type
      value
    }
    addresses {
      id
      line1
      city
      district
      postalCode
      country {
        id
        name
      }
    }
    affiliations {
      id
      number
      affiliation {
        name
        type
      }
    }
  }
`

export const PARTY_ADDRESS_FIELDS = gql `
  fragment PartyAddressFields on PartyAddress {
    id
    line1
    city
    district
    country {
      id
      isoCode
      name
    }
    postalCode
  }
`

export const SESSION_FIELDS = gql `
  fragment SessionFields on Session {
    id
    _id
    type {
      id
      name
    }
    venue {
      id
      name
    }
    projectId
    name
    description
    startedAt
    endedAt
    unionSession
    analogSession
    dropFrame
    venueRoom
    bitdepth
    samplerate
    timecodeType
    timecodeFrameRate
    createdAt
    updatedAt
    recordings {
      ...RecordingTableFields
    }
    commentCount
  }
  ${RECORDING_TABLE_FIELDS}
`

export const USER_ACCOUNT_FIELDS = gql `
  fragment UserAccountFields on User {
    id
    firstName
    lastName
    timezoneId
    email
    phone
    image
    twoFactorEnabled
    image
    profile {
      studioType
      label
      jobRole
      genre
      workload
    }
  }
`

export const EVENT_LOG_FIELDS = gql `
  fragment EventLogFields on EventLog {
    id
    user {
      id
      name
      image

    }
    action
    projectId
    resourceId
    resourceType
    message
    createdAt
    resource {
      ... on Project {
        id
        name
      }
      ... on Recording {
        id
        name
      }
      ... on Session {
        id
        name
      }
      ... on Collaborator {
        id
        type
        name
        user {
          name
          id

        }
      }
      ... on File {
        id
        name
        folderId
      }
      ... on Comment {
        id
        user {
          id
          name
        }
        resourceId
        resourceType
        resource {
          ... on Project {
            id
            name
          }
          ... on Recording {
            id
            name
          }
          ... on Session {
            id
            name
          }
          ... on File {
            id
            name
          }
          ... on Folder {
            id
            name
          }
        }
      }
    }
  }
`

export const USER_FIELDS = gql `
  fragment UserFields on User {
    id
    firstName
    lastName
    email
    phone
    image
    favourites {
      ...FavouriteFields
    }
    subscriptions {
      stripePlan
    }
    cardBrand
    cardLastFour
    totalStorageUsed
    deletedStorageUsed
    deletedStorageUsedPretty
    deletedStorageUsedPercentage
    totalStorageUsedPretty
    totalStorageUsedPercentage
    sxStatus
    collaborators {
      id
      type
      projectId
      permissions {
        type
        level
      }
      recordings {
        id
      }
    }
    updatedAt
  }
  ${FAVOURITE_FIELDS}
`

export const GET_PPL_URL_FIELD = gql `
  fragment UserFields on User {
    id
    firstName
    lastName
    email
    phone
    favourites {
      ...FavouriteFields
    }
    subscriptions {
      stripePlan
    }
    cardBrand
    cardLastFour
    totalStorageUsed
    totalStorageUsedPretty
    totalStorageUsedPercentage
    collaborators {
      id
      type
      projectId
      permissions {
        type
        level
      }
      recordings {
        id
      }
    }
    updatedAt
  }
  ${FAVOURITE_FIELDS}
`

export const PUBLISHER_FIELDS = gql `
  fragment PublisherFields on Publisher {
    id
    publisher
    country {
          id
          name
      }
    createdAt
    updatedAt
  }
`

export const UserSocialFields = gql `
  fragment UserSocialFields on UsersSocial {
    id
    socialUrl
    social {
        id
        name
        preUrl
    }
    isOn
  }
`
export const PLAYLIST_FIELDS = gql `
fragment PlaylistFields on Playlist {
  id
  userId
  title
  description
  image
  user {
    id
    name
    email
  }
  files {
    id
    projectId
    type
    name
    prettySize
    isPreviewable
    status
    createdAt
    commentCount
  }
  createdAt
  updatedAt
}
`

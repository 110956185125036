import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Query } from 'react-apollo'
import { DateTime } from 'luxon'
import nl2br from 'react-nl2br'

import GetUser from '../../hocs/queries/GetUser'
import GetSong from '../../hocs/queries/GetSong'

// Our Base Components
// import Breadcrumb from '../../components/Breadcrumb'
import CreditTable from '../../components/CreditTable'
import InfoGrid from '../../components/InfoGrid'
import SongHero from '../../components/SongHero'
import SectionContainer from '../../components/SectionContainer'
import { GET_PROJECT_FOLDER } from '../../graphql/queries'
import { NetworkStatus } from '../../utils/Apollo'
import FileTable from '../../components/FileTable'
import { Route } from 'react-router-dom'
import WithUploads from '../../hocs/WithUploads'
import WithUploadModal from '../../hocs/WithUploadModal'
import { getUserFavouritesByType } from '../../utils/selectors'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import * as permissions from '../../utils/permissions'
import { WithMoveFilesFrom } from '../../hocs/MoveFiles'
import folderActive from '../../assets/images/folderActive.svg'
import uploadIcon from '../../assets/upload-arrow.svg'
import { getTags, filterPermissions } from '../../utils/permissions'

class SongDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
      tags: []
    }
  }
  getSearchTerm = value => {
    this.setState({
      searchTerm: value
    })
  };
  permissionsApplied = (row = this.props.song) => {
    const { recordings } = row
    const fullAccess = {
      create: true,
      update: true,
      read: true,
      delete: true,
      download: true,
      comments: true
    }
    if (recordings.length === 0) {
      return fullAccess
    }
    const projectIds = [...new Set(recordings.map(item => item.projectId))]
    if (this.props.song.userId === this.props.user.id) {
      return fullAccess
    }
    const AllPermissions = []
    projectIds.forEach(async item => {
      const permission = permissions.can(item, 'song')
      if (Object.keys(permission).length > 0) {
        AllPermissions.push(permission)
      }
    })
    let getPermission = {}
    AllPermissions.forEach(item => {
      if (Object.keys(getPermission).length === 0) {
        getPermission = { ...item }
      } else {
        if (getPermission.create === false) {
          getPermission.create = item.create
        }
        if (getPermission.update === false) {
          getPermission.update = item.update
        }
        if (getPermission.read === false) {
          getPermission.read = item.read
        }
        if (getPermission.download === false) {
          getPermission.download = item.download
        }
        if (getPermission.delete === false) {
          getPermission.delete = item.delete
        }
        if (getPermission.comments === false) {
          getPermission.comments = item.comments
        }
      }
    })
    return getPermission
  };
  getTags = () => {
    let tags = []
    if (this.props.song.userId === this.props.user.id) {
      return tags
    }
    const allPermissons = this.permissionsApplied()
    for (let singlePerm in allPermissons) {
      if (allPermissons[singlePerm]) {
        tags.push({ tagName: singlePerm })
      }
    }
    return tags
  };
  render() {
    const { song, user, history } = this.props
    song.name = song.title
    song.imageDir = 'song-images/'
    const favourites = getUserFavouritesByType(user)
    const isSongOwner = song.userId === user.id
    const canUpdateSong = isSongOwner
    const hasFullAccess = permissions.hasFullAccess(song.id, user)
    const projectIds = [...new Set(this.props.song.recordings.map(item => item.projectId))]

    return (
      <>
        <Helmet>
          <title>{song.title} | Songs</title>
        </Helmet>
        <main className='main song-detail'>

          <CreatePagesHeader
            subtitle='Song Details'
            btnText={canUpdateSong ? 'Edit' : false}
            btnOnClick={() => history.push(`/songs/${song.id}/edit`)}
            isSong= {true}
          >
            <>
              <SongHero
                isSongOwner={isSongOwner}
                sxStatus={user.sxStatus === '0' ? false : true}
                lockCredits={this.lockCredits}
                locked={song.locked}
                favourite={
                  (favourites.song || []).indexOf(parseInt(song.id, 10)) > -1
                }
                {...song}
                openImportProjectRINModal={this.openImportProjectRINModal}
                canImport={hasFullAccess}
                isSongHeader={true}
                SongHeroSection='SongHeroSection'
              />
            </>
          </CreatePagesHeader>

            <>
            <div className='sections-container'>
              <div className='info-grid-wrapper'>
                <InfoGrid
                  gridClass={'song-section-infoGrid'}
                  items={[
                    {
                      title: 'Title',
                      content: this.props.song.title || '-'
                    },
                    {
                      title: 'Alternative Title',
                      content: this.props.song.titleAlt || '-'
                    },
                    {
                      title: 'Type',
                      content: this.props.song.type.name || '-'
                    },
                    {
                      title: 'Created On',
                      content: this.props.song.createdOn
                        ? DateTime.fromSQL(this.props.song.createdOn).toISODate()
                        : '-'
                    },
                    {
                      title: 'Subtitle',
                      content: this.props.song.subtitle || '-'
                    },
                    {
                      title: 'Alternative Subtitle',
                      content: this.props.song.subtitleAlt || '-'
                    },
                    {
                      title: 'ISWC',
                      content: this.props.song.iswc || '-'
                    },
                    {
                      title: 'Shared From Project',
                      content: this.props.song.sharedFromProject || '-'
                    },

                    {
                      title: 'Lyrics',
                      content: this.props.song.lyrics
                        ? nl2br(this.props.song.lyrics)
                        : '-',
                      requiresLargeContainer: true
                    },
                    {
                      title: 'Notes',
                      content: this.props.song.notes
                        ? nl2br(this.props.song.notes)
                        : '-',
                      requiresLargeContainer: true
                    }
                  ]}
                />
              </div>
            </div>
              </>

            <SectionContainer>
              <FileList
                match={this.props.match}
                location={this.props.location}
                uploadModal={this.props.uploadModal}
                uploads={this.props.uploads}
                song={this.props.song}
                user={this.props.user}
                recording={this.props.song}
                songPermissions ={this.permissionsApplied()}
                setProjectIdAndFolderId={this.props.uploads.actions.setProjectIdAndFolderId}
                fileFavourites={favourites.file || []}
                folderFavourites={favourites.folder || []}
                moveFilesFrom={this.props.moveFilesFrom}
                tags={this.getTags()}
                isSongOwner={isSongOwner}
                projectIds={projectIds}
              />
            </SectionContainer>
            <div className='song-credits-section'>
              <CreatePagesHeader
                getSearchTerm={this.getSearchTerm}
                subtitle='Song Credits'
                showSearch={true}
                btnText={this.permissionsApplied().create ? 'Add' : false}
                btnOnClick={() =>
                  this.props.history.push(
                    `/songs/${this.props.song.id}/credits/add`
                  )
                }
                linkText='View All'
                linkUrl={`/songs/${this.props.song.id}/credits`}
                tags={!isSongOwner ? getTags(filterPermissions(this.permissionsApplied())) : []}

              />
              <div className='sections-container song-credits'>
                <SectionContainer
                  id='credits'>
                  <CreditTable
                    nameCellClass='song-detail-credit'
                    rows={this.props.song.credits || []}
                    songId={this.props.song.id}
                    showSearch={false}
                    searchTerm={this.state.searchTerm}
                    pagination
                    refetch={this.props.refetch()}
                    actions
                    canUpdate={this.permissionsApplied().update}
                    canDelete={this.permissionsApplied().delete}
                    onDelete={this.props.refetchSong}
                    rowActions={this.permissionsApplied().canCreate}
                    sortByOrder={true}
                    sortDirection='ASC'
                    sortColumn='name'
                  />


                </SectionContainer>
              </div>
            </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetSong,
  WithUploads,
  WithUploadModal,
  WithMoveFilesFrom

)(SongDetail)

class FileList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }

  onMoveFilesFrom =(refetch)=>{
    const { song } = this.props
    const breadCrumb = [{
      href: `/songs`,
      name: 'Songs'
    },
    {
      href: `#`,
      name: `${song.title}`
    }
    ]
    this.props.moveFilesFrom.open(null,
      this.props.song.folderId,
      breadCrumb,
      refetch
    )
  }

  onClickUpload =(refetch) =>{
    this.props.uploadModal.open(
      this.props.song.title,
      '',
      null,
      this.props.song.folderId,
      '',
      refetch
    )
  }
   getSearchTerm=(value)=>{
     this.setState({
       searchTerm: value
     })
   }
   dropDownMenuList = (refetch)=>{
     return (
       [
         { name: 'Upload', icon: uploadIcon, click: ()=> this.onClickUpload(refetch) },
         { name: 'Files', icon: folderActive, click: ()=> this.onMoveFilesFrom(refetch) }
       ]
     )
   }
   render() {
     const { songPermissions, isSongOwner, projectIds } = this.props
     let songShared = this.props.song.userId !== this.props.user.id
     return (
      <>
        {this.props.song.folderId &&
          this.props.location.pathname === this.props.match.url && (
          <Query
            query={GET_PROJECT_FOLDER}
            variables={{
              songId: this.props.song.id,
              folderId: this.props.song.folderId
            }}
            fetchPolicy='cache-and-network'
            pollInterval={
              this.props.uploads.state.inProgressCount > 0 ? 0 : 10000
            }
          >
            {({ loading, error, data, refetch, networkStatus }) => {
              const actuallyLoading =
                  loading &&
                  networkStatus !== NetworkStatus.poll &&
                  networkStatus !== NetworkStatus.refetch

              if (error) {
                return `Error! ${error.message}`
              }
              let files = []
              let folders = []

              if (!actuallyLoading) {
                files = data.getFolder.files
                folders = data.getFolder.folders
              }

              return (
                <>
         <CreatePagesHeader
           subtitle='Song Files'
           showSearch={true}
           getSearchTerm={this.getSearchTerm}
           linkText='View All'
           linkUrl={`/songs/${this.props.song.id}/files`}
           tags={!this.props.isSongOwner ? getTags(filterPermissions(songPermissions)) : []}
           dropDownMenuList={ songPermissions.create ? this.dropDownMenuList(refetch) : false}

         />
              <div className='sections-container song-files project-files'>
                <SectionContainer>
                  <FileTable
                    nameCellClass='song-detail-file'
                    rows={[...folders, ...files]}
                    showSearch={false}
                    searchTerm={this.state.searchTerm}
                    projectId={null}
                    folderId={this.props.song.folderId}
                    song={this.props.song}
                    songId={this.props.song.id}
                    fileFavourites={this.props.fileFavourites}
                    folderFavourites={this.props.folderFavourites}
                    showSkeleton={actuallyLoading}
                    projectIds={projectIds}
                    refetch={refetch}
                    favourite
                    batchActions
                    actions
                    rowActions
                    pagination
                    canCreate={songPermissions.create}
                    canDownload={songPermissions.download}
                    canDelete={songPermissions.delete}
                    comments={false}
                    isSongOwner={isSongOwner}
                    hideShare={songShared}
                    hideCopy={songShared}
                    sortDirection='ASC'
                    sortColumn='name'
                    canAddToPlaylist={false}
                    deletePermanent={true}
                    hideCancelBtn={true}
                    sortByOrder={true}
                  />
                </SectionContainer>
              </div>
                </>
              )
            }}
          </Query>
        )}
        <Route
          path={`${this.props.match.url}/:folderId`}
          render={props => (
            <FileList
              {...props}
              project={this.props.song}
              uploadModal={this.props.uploadModal}
              uploads={this.props.uploads}
              setProjectIdAndFolderId={this.props.setProjectIdAndFolderId}
              fileFavourites={this.props.fileFavourites}
              folderFavourites={this.props.folderFavourites}
            />
          )}
        />
      </>
     )
   }
}


import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Query, Mutation, graphql } from 'react-apollo'

import ApolloClient from '../utils/Apollo'
import { GET_USER_ACCOUNT } from '../graphql/queries'
import { UPDATE_USER, DO_TWO_FACTOR } from '../graphql/mutations'

import GetUser from '../hocs/queries/GetUser'

// Our Base Components
import AccountInfoForm from '../components/AccountInfoForm'
import ChangePasswordForm from '../components/ChangePasswordForm'
import Enter2FAModal from '../components/Enter2FAModal'
import Update2FAForm from '../components/Update2FAForm'
import SectionContainer from '../components/SectionContainer'
import AccountTimezoneForm from '../components/AccountTimezoneForm'
import WithAlertModal from '../hocs/WithAlertModal'
import ScrollToTop from '../utils/ScrollToTop'
import MenuSwitcher from '../components/MenuSwitcher'
import CreatePagesHeader from '../components/CreatePagesHeader'
class AccountEdit extends React.Component {
  state = {
    enter2FAModalOpen: false,
    token2FA: '',
    success: false,
    discardModal: false
  }

  constructor(props) {
    super(props)
    this.closeEnter2FAModal = this.closeEnter2FAModal.bind(this)
    this.enter2FASubmit = this.enter2FASubmit.bind(this)
  }

  closeEnter2FAModal() {
    this.setState({
      ...this.state,
      enter2FAModalOpen: false
    })
  }

  componentDidMount() {
    let info = sessionStorage.getItem('userFromDashboard')

    ApolloClient.query({
      query: GET_USER_ACCOUNT,
      fetchPolicy: 'network-only'
    }).then((r) => {
      if (!r.data.getUser.timezoneId) {
        if (info === 'notOpen') {
          sessionStorage.removeItem('userFromDashboard')
          window.scrollTo(0, 1000)
        } else {
          this.props.alert(
            'You need to set your time zone to be able to use our new update of VEVA Collect.',
            {
              title: 'Time Zone',
              buttonText: 'Set Time Zone',
              submitCallback: (close) => {
                close()
                window.scrollTo(0, 1000)
                sessionStorage.removeItem('userFromDashboard')
              }
            }
          )
        }
      }
    })
  }

  enter2FASubmit(code) {
    this.props.doTwoFactor(this.state.token2FA, code).then(() => {
      ApolloClient.query({
        query: GET_USER_ACCOUNT,
        fetchPolicy: 'network-only'
      })

      this.setState({
        ...this.state,
        enter2FAModalOpen: false,
        token2FA: ''
      })
    })
  }


  render() {
    return (
      <>
        <Helmet>
          <title>Account</title>
        </Helmet>


        <main className='main'>
          <CreatePagesHeader
            subtitle='Account Info'
          >
            <MenuSwitcher />
          </CreatePagesHeader>
          <Query query={GET_USER_ACCOUNT} fetchPolicy='network-only'>
            {({ loading, error, data }) => {
              if (loading) {
                return null
              }

              if (error) {
                return `Error! ${error.message}`
              }

              return (
                  <>
                  <div className='sections-container'>
                    <SectionContainer>
                      <Mutation mutation={UPDATE_USER}>
                        {(updateUser, { loading, error }) => (
                          <AccountInfoForm
                            firstName={data.getUser.firstName}
                            lastName={data.getUser.lastName}
                            email={data.getUser.email}
                            loading={loading}
                            errors={error}
                            success={this.state.success}
                            image={data.getUser.image}
                            refetchGlobalUser={this.props.refetch}
                            onSubmit={(state, isEmailChanged) => {
                              return updateUser({
                                variables: {
                                  firstName: state.firstName,
                                  lastName: state.lastName,
                                  email: state.email
                                }
                              })
                                .then(() => this.setState({ success: isEmailChanged }))
                                .catch((e) => {})
                            }}
                            id={data.getUser.id}
                          />
                        )}
                      </Mutation>
                    </SectionContainer>
                  </div>
                  <CreatePagesHeader
                    subtitle={
                      data.getUser.twoFactorEnabled ? '2FA' : 'Enable 2FA'
                    }
                  />
                  <div className='sections-container'>
                    <SectionContainer>
                      <Mutation mutation={UPDATE_USER}>
                        {(updateUser, { loading, error }) => (
                          <Update2FAForm
                            loading={loading}
                            errors={error}
                            twoFactorEnabled={data.getUser.twoFactorEnabled}
                            phone={data.getUser.phone}
                            onSubmit={(state) => {
                              return updateUser({
                                variables: {
                                  firstName: data.getUser.firstName,
                                  lastName: data.getUser.lastName,
                                  email: data.getUser.email,
                                  phone: state.phone,
                                  currentPassword: state.currentPassword,
                                  twoFactorEnabled: state.twoFactorEnabled
                                    ? true
                                    : false
                                }
                              }).then((response) => {
                                if (response.data.updateUser.twoFactor) {
                                  this.setState({
                                    ...this.state,
                                    enter2FAModalOpen: true,
                                    token2FA:
                                      response.data.updateUser.twoFactor
                                        .accessToken
                                  })
                                }
                              })
                            }}
                          />
                        )}
                      </Mutation>
                    </SectionContainer>
                  </div>
                  <ScrollToTop />
                  <CreatePagesHeader
                    subtitle='Time Zone'
                  />
                  <div className='sections-container'>
                    <SectionContainer>
                      <Mutation mutation={UPDATE_USER}>
                        {(updateUser, { loading, error }) => (
                          <AccountTimezoneForm
                            timezoneId={data.getUser.timezoneId}
                            loading={loading}
                            errors={error}
                            onSubmit={(state) => {
                              return updateUser({
                                variables: {
                                  firstName: data.getUser.firstName,
                                  lastName: data.getUser.lastName,
                                  email: data.getUser.email,
                                  timezoneId: state.timezoneId
                                }
                              }).catch((e) => {})
                            }}
                          />
                        )}
                      </Mutation>
                    </SectionContainer>
                  </div>
                  <CreatePagesHeader
                    subtitle='Update Password'
                  />
                  <div className='sections-container'>
                    <SectionContainer>
                      <Mutation mutation={UPDATE_USER}>
                        {(updateUser, { loading, error }) => (
                          <ChangePasswordForm
                            loading={loading}
                            errors={error}
                            onSubmit={(state, success: () => {}) => {
                              updateUser({
                                variables: {
                                  firstName: data.getUser.firstName,
                                  lastName: data.getUser.lastName,
                                  email: data.getUser.email,
                                  currentPassword: state.currentPassword,
                                  password: state.newPassword,
                                  confirmPassword: state.confirmNewPassword
                                }
                              }).then(() => {
                                success()
                              })
                            }}
                          />
                        )}
                      </Mutation>
                    </SectionContainer>
                  </div>
                </>
              )
            }}
          </Query>
        </main>
        <Enter2FAModal
          open={this.state.enter2FAModalOpen}
          onRequestClose={this.closeEnter2FAModal}
          onSubmit={this.enter2FASubmit}
        />
      </>
    )
  }
}

export default compose(
  GetUser,
  WithAlertModal,
  graphql(DO_TWO_FACTOR, {
    name: 'doTwoFactor',
    props: (data) => {
      return {
        doTwoFactor: (token, code) =>
          data.doTwoFactor({
            variables: {
              code,
              token
            }
          })
      }
    }
  })
)(AccountEdit)

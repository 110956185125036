import React from 'react'
import { Query } from 'react-apollo'
import { GET_PLATFORM_IMAGES } from '../../graphql/queries'

const GetPlatformImages = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_PLATFORM_IMAGES} fetchPolicy={fetchPolicy}>
          {({ loading, error, data }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            return <WrappedComponent {...this.props} platformImages={data.getPlatformImages} />
          }}
        </Query>
      )
    }
  }
}

export default GetPlatformImages


import React, { useState } from 'react'
import { Query } from 'react-apollo'

import {
  getQuery,
  onMoveFiles
} from '../../utils/moveFilesHelper'

import { MoveFilesToModal } from '../../components/modals'
import AlertModal from '../../components/modals/AlertModal'

const WithMoveFilesTo = WrappedComponent => {
  return props => {
    const [open, setOpen] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [state, setState] = useState({
      isMyFile: true,
      isProject: false,
      isSongs: false,
      projectId: null,
      folderId: null,
      isProjectOwner: null,
      typeName: '',
      root: 'Files',
      files: [],
      onCancel: null,
      message: '',
      refetch: null,
      selectedFrom: []

    })
    const [selectedId, setSelectedId] = useState('')
    const [selectedFiles, setSelectedFiles] = useState([])

    const { query, queryKey } = getQuery(state)

    const openMoveFilesToHandler = (
      { files,
        projectId,
        folderId,
        isProjectOwner,
        onCancel,
        refetch,
        selectedFrom }
    ) => {
      const updatedProjectId = isProjectOwner ? null : projectId

      setState({
        ...state,
        projectId: updatedProjectId,
        files: files,
        folderId,
        isProjectOwner,
        onCancel,
        refetch,
        selectedFrom
      })
      setSelectedFiles(files)
      setOpen(true)
    }
    const onSave = async () => {
      const { isMyFile, onCancel, refetch } = state
      const noDestination = !selectedId && !isMyFile
      if (noDestination) {
        setAlertOpen(!alertOpen)
        setState({
          ...state,
          message: 'Can not move please select destination'
        })
        return
      }

      const response = await onMoveFiles(state, selectedId)
      if (response && response.success) {
        setAlertOpen(!alertOpen)
        setState({
          ...state,
          message: 'Selected files moved successfully'
        })
      } else {
        setAlertOpen(!alertOpen)
        setState({
          ...state,
          message: 'Selected files could not moved'
        })
      }
      setOpen(false)
      if (onCancel) {
        onCancel()
      }
      if (refetch) {
        refetch()
      }
      setSelectedId('')
    }

    const onMoveOutDestinationHandler = (projectId, folderId) => {
      setState({ ...state, projectId, folderId })
    }
    const onClose = ()=>{
      setOpen(false)
      setSelectedId('')
    }

    const onSelectDestination = ({ id, __typename }, e) => {
      if (e.currentTarget.className.includes('isSelected')) {
        setSelectedId('')
        setState({
          ...state,
          typeName: ''
        })
        return
      }
      setState({
        ...state,
        typeName: __typename
      })
      setSelectedId(id)
    }

    const onMoveInDestinationHandler = ({ id, __typename }) => {

      const folderId = __typename.toLowerCase() === 'folder' ? id : null
      const projectId = __typename.toLowerCase() === 'project' ? id : null

      setState({
        ...state,
        folderId,
        projectId
      })
      setSelectedId(id)
    }

    const onChangeDestinationFlow = value => {
      const isProject = value === 'projects'
      const isMyFile = value === 'files'
      const isSongs = value === 'songs'

      setState({
        ...state,
        isProject,
        isMyFile,
        isSongs,
        projectId: null,
        folderId: null,
        root: value
      })
      setSelectedId('')
    }
    const eventHandler = () => {}
    const handleQueryResponce = ({ loading, data }) => {
      if (!loading) {
        let destination = data[queryKey]
        const { projectId, folderId, isMyFile, isSongs } = state

        if (
          projectId ||
          folderId ||
          isMyFile ||
          isSongs
        ) {
          const destinationFolders =
            isSongs && !folderId && destination
              ? destination.songFolders
              : destination.folders
          destination = !destination ? [] : destinationFolders
        }

        return (
          <MoveFilesToModal
            showModal={open}
            onSave={onSave}
            selectedFiles={selectedFiles}
            destination={destination}
            onClose={onClose}
            onCancel={onClose}
            onMoveInDestination={onMoveInDestinationHandler}
            onChangeDestinationFlow={onChangeDestinationFlow}
            selectedId={selectedId}
            onSelectDestination={onSelectDestination}
            onMoveFiles={onMoveFiles}
            selectedFrom={state.selectedFrom}
            onMoveOutDestination={onMoveOutDestinationHandler}
          />
        )
      }
      return (
        <MoveFilesToModal
          showModal={open}
          onSave={eventHandler}
          selectedFiles={selectedFiles}
          destination={[]}
          onClose={eventHandler}
          onCancel={eventHandler}
          onMoveInDestination={eventHandler}
          onChangeDestinationFlow={eventHandler}
          selectedId={selectedId}
          onSelectDestination={eventHandler}
          onMoveFiles={eventHandler}
          selectedFrom={state.selectedFrom}
          onMoveOutDestination={eventHandler}
        />
      )
    }
    return (
      <>
        <WrappedComponent
          {...props}
          moveFilesTo={{
            open: openMoveFilesToHandler,
            close: () => setOpen(false)
          }}
        />
        <Query
          query={query}
          variables={{
            projectId: state.projectId,
            folderId: state.folderId
          }}
          fetchPolicy='cache-and-network'
        >
          {handleQueryResponce}
        </Query>
        <AlertModal
          alertOpen={alertOpen}
          modalHeading={' '}
          onRequestClose={()=> setAlertOpen(!alertOpen)}
          handleSubmit={()=> setAlertOpen(!alertOpen)}
          message={state.message}
        />
      </>
    )
  }
}

export default WithMoveFilesTo

import React from 'react'
import Helmet from 'react-helmet-async'
import { Link, withRouter } from 'react-router-dom'
import { compose, Query } from 'react-apollo'
import { Loading } from 'carbon-components-react'

import {
  GET_RECENT_OVERVIEW,
  GET_PROJECTS,
  GET_SONGS,
  GET_USER_INVITES,
  GET_USER_ACCOUNT,
  GET_ALL_PLAYLISTS,
  GET_REFERRAL_NOTIFICATION,
  GET_REDEEMS
} from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'

import {
  getUserFavouritesByType,
  getUserStorageLimits
} from '../utils/selectors'

// Our Base Components
import Hero from '../components/Hero'
import ProjectTileList from '../components/ProjectTileList'
import RecentResourceTable from '../components/RecentResourceTable'
import SectionContainer from '../components/SectionContainer'
import StorageLimit from '../components/StorageLimit'
import ApolloClient from '../utils/Apollo'
import WithAlertModal from '../hocs/WithAlertModal'
import CreatePagesHeader from '../components/CreatePagesHeader'
import SongTileList from '../components/SongTileList'
import PlayListTileList from '../components/PlayListTileList'
import RedeemModal from '../components/RedeemModal'
import Redeem from '../hocs/mutations/Redeem'

const initialState = {
  previousPath: '',
  openRedeemModal: false,
  message: '',
  referralUserId: ''
}
class Dashboard extends React.Component {
  state = { ...initialState }

  constructor(props) {
    super(props)
    this.state.previousPath = ''
    this.getRecent = this.getRecent.bind(this)
  }

  getRecent(arr) {
    let sorted = arr.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    )

    return sorted.length ? sorted.slice(0, 5) : []
  }
  closeRadeemModal = () =>{
    this.setState({ openRedeemModal: false })
  }

  openRadeemModal = () =>{
    this.setState({ openRedeemModal: true })
  }
  getRedeem = () =>{
    ApolloClient.query({
      query: GET_REDEEMS,
      fetchPolicy: 'network-only'
    }).then(async ({ data }) => {
      const msg = data.getRedeems.message
      if (data.getRedeems.success) {
        this.setState({ message: msg })
        this.setState({ referralUserId: data.getRedeems.referralUserId })
        this.openRadeemModal()
      }
    })
  }

  getReferralNotification = () =>{
    ApolloClient.query({
      query: GET_REFERRAL_NOTIFICATION,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      if (data.getReferralNotification.message) {
        this.props.alert(`${data.getReferralNotification.message}`)
      }
    })
  }

  componentDidMount() {
    if (this.props.location.pathname === '/redeem') {
      this.getRedeem()
    }

    this.props.refetch()
    ApolloClient.query({
      query: GET_USER_ACCOUNT,
      fetchPolicy: 'network-only'
    }).then(r => {
      if (!r.data.getUser.timezoneId) {
        this.props.alert(
          'You need to set your time zone to be able to use our new update of VEVA Collect.',
          {
            title: 'Time Zone',
            buttonText: 'Set Time Zone',
            submitCallback: close => {
              sessionStorage.setItem('userFromDashboard', 'notOpen')
              this.props.history.push(`/account`)
              close()
            }
          }
        )
      }
    })

    this.getReferralNotification()
  }

  render() {
    const favourites = getUserFavouritesByType(this.props.user)

    const storageLimitData = getUserStorageLimits(this.props.user)

    return (
      <>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <main className='main dashboardPage'>
          {/* Header */}

          <Hero subtitle='Dashboard' />

          {/* Storage */}

          <div className='mainContainer storageContainer'>
            {storageLimitData && <StorageLimit {...storageLimitData} />}
          </div>

          {/* Invites */}

          <Query
            query={GET_USER_INVITES}
            key='get-user-invites-query'
            fetchPolicy='network-only'
          >
            {({ loading, error, data }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              if (loading || (data && data.getUserInvites.length === 0)) {
                return null
              }

              return (
                <>
                  <CreatePagesHeader subtitle='Invites' />
                  <div className='sections-container'>
                    <SectionContainer key='invites'>
                      {loading && (
                        <Loading
                          withOverlay={false}
                          key='invites-loading'
                          className='bx--loading--center'
                        />
                      )}
                      {!loading &&
                        data.getUserInvites.map((invite, i) => (
                          <p
                            key={`invites-text-${i}`}
                            style={{ color: 'white' }}
                          >
                            You have been invited to collaborate on "
                            {invite.project.name}".{' '}
                            <Link to={`/invite/${invite.token}`}>
                              Click here
                            </Link>{' '}
                            to accept.
                          </p>
                        ))}
                    </SectionContainer>
                  </div>
                </>
              )
            }}
          </Query>

          {/* Recent Projects */}

          <Query query={GET_PROJECTS} fetchPolicy='network-only'>
            {({ loading, error, data }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              let recentProjects = this.getRecent(data.getProjects || [])

              return (
                <div className='dashboardSection dashboardProject'>
                  <CreatePagesHeader
                    subtitle='Recent Projects'
                    key='projects'
                  />
                  <div className='sections-container'>
                    <SectionContainer>
                      <ProjectTileList
                        projects={recentProjects || []}
                        favourites={favourites.project || []}
                        withOptions={false}
                        user={this.props.user}
                        onDelete={() =>
                          this.props.alert(
                            'The project has been successfully deleted.'
                          )
                        }
                        showArtist={true}
                        showFavouriteIcon={true}
                      />
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>

          {/* Recent Songs */}

          <Query
            query={GET_SONGS}
            variables={{
              userId: this.props.user.id
            }}
            fetchPolicy='network-only'
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              let recentSongs = this.getRecent(data.getSongs || [])

              return (
                <div className='dashboardSection dashboardSong'>
                  <CreatePagesHeader subtitle='Recent Songs' key='songs' />
                  <div className='sections-container'>
                    <SectionContainer>
                      <SongTileList
                        songs={recentSongs}
                        withOptions={false}
                        refetch={refetch}
                        user={this.props.user}
                        showFavouriteIcon={true}
                      />
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>

          {/* Recent Playlists */}

          <Query query={GET_ALL_PLAYLISTS} fetchPolicy='network-only'>
            {({ loading, error, data, refetch }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              let recentPlaylist = this.getRecent(data.getPlaylists || [])

              return (
                <div className='dashboardSection dashboardPlaylist'>
                  <CreatePagesHeader subtitle='Recent Playlists' key='songs' refetch={refetch} />
                  <div className='sections-container'>
                    <SectionContainer>
                      <PlayListTileList
                        playlists={recentPlaylist || []}
                        refetch={refetch}
                        withOptions={false}
                        user={this.props.user}
                        showFavouriteIcon={true}
                      />
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>

          {/* Recently Updated */}

          <Query query={GET_RECENT_OVERVIEW} variables={{ default: true }} fetchPolicy='network-only' pollInterval={3000}>
            {({ loading, error, data }) => {
              if (error) {
                return `Error! ${error.message}`
              }

              return (
                <div className='dashboardFiles'>
                  <CreatePagesHeader subtitle='Recently Updated' />
                  <div className='sections-container recentUpdated'>
                    <SectionContainer key='recently-updated'>
                      <RecentResourceTable
                        nameCellClass='recently-updated-table'
                        rows={data.getRecents || []}
                        showSearch={false}
                        pagination={false}
                        favourites={favourites}
                      />
                    </SectionContainer>
                  </div>
                </div>
              )
            }}
          </Query>
        </main>
        {this.state.openRedeemModal &&
          <RedeemModal
            open={this.state.openRedeemModal}
            onRequestClose={this.closeRadeemModal}
            message={this.state.message}
            referralUserId={this.state.referralUserId}
            {...this.props}
          />
        }
      </>
    )
  }
}

export default compose(
  GetUser,
  WithAlertModal,
  withRouter,
  Redeem
)(Dashboard)

import React from 'react'
import ResponsiveTable from './ResponsiveTable'
import { DateTime } from 'luxon'
import { compose } from 'react-apollo'

// HOC's
import WithComments from '../hocs/WithComments'
import WithDownloadModal from '../hocs/WithDownloadModal'
import WithFilePreviewModal from '../hocs/WithFilePreviewModal'
import DetachPlaylistFiles from '../hocs/mutations/DetachPlaylistFiles'
import WithAlertModal from '../hocs/WithAlertModal'
import WithMoveCopyFileModal from '../hocs/WithMoveCopyFileModal'
import WithShareModal from '../hocs/WithShareModal'
import ShareLink from '../hocs/mutations/ShareLink'

import WithWaveformPreviewModal from '../hocs/WithWaveformPreviewModal '
import DuplicateFiles from '../hocs/mutations/DuplicateFiles'
import WithToasterNotification from '../hocs/WithToasterNotification'
import PermanentlyDeleteFiles from '../hocs/mutations/PermanentlyDeleteFiles'
import RestoreFiles from '../hocs/mutations/RestoreFiles'
import { withRouter } from 'react-router'
import { nanoSecondsToHMS } from '../utils/functions'
import UpdateSeqPlaylist from '../hocs/mutations/UpdateSeqPlaylist'

class FileTable extends React.PureComponent {
  state = {
    createFolderModalOpen: false,
    createFolderName: '',

    renameFolderModalOpen: false,
    renameFolderName: '',
    renameFolderId: null,

    renameFileModalOpen: false,
    renameFileName: '',
    renameFileId: null,
    renderKey: 'bhdsbhb'
  }

  constructor(props) {
    super(props)

    this.getHeaders = this.getHeaders.bind(this)
    this.getRowActions = this.getRowActions.bind(this)
    this.isFavourite = this.isFavourite.bind(this)
    this.generateLink = this.generateLink.bind(this)
  }

  getHeaders = (() => {
    if (this.props.favouritePage) {
      const favouriteHeaders = [
        {
          key: 'name',
          header: 'Name'
        },
        {
          key: 'project.name',
          header: 'Project',
          formatter: (data, row) => (row.project ? row.project.name : '-')
        },
        {
          key: 'type',
          header: 'File Type',
          // Cache the file type elements
          formatter: (() => {
            const types = {}
            return type => {
              if (!types[type]) {
                types[type] = (
                  <div
                    className={`fileType fileType--compact fileType--${type}`}
                  >
                    {type ? type : 'Folder'}
                  </div>
                )
              }
              return types[type]
            }
          })()
        },
        {
          key: 'prettySize',
          header: 'Size',
          formatter: data => (data ? data : '-')
        },
        {
          key: 'regionCommentCount',
          header: 'Comments'
        },
        {
          key: 'createdAt',
          header: 'Added',
          formatter: data => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
        }
      ]

      return () => favouriteHeaders
    }

    const headers = [
      {
        key: 'name',
        header: 'Title',
        formatter: data => data
      },
      {
        key: 'duration',
        header: 'Duration',
        formatter: data => (data ? nanoSecondsToHMS(data) : '-')
      },
      {
        key: 'regionCommentCount',
        header: 'Comments'
      },
      {
        key: 'createdAt',
        header: 'Added',
        formatter: data => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
      }
    ]
    if (this.props.canRestore) {
      headers.push({
        key: 'parentFolder',
        header: 'Parent Folder'
      })
    }

    return () => headers
  })();

  getBatchActions = (() => {
    let actions = []

    actions.push({
      name: 'trash',
      iconDescription: 'Remove from playlist',
      onClick: (selectedRows, rowsById, onCancel) => {
        let error = false
        let files = selectedRows.map(selectedRow => {
          const row = rowsById[selectedRow.id]
          return parseInt(row.id, 10)
        })
        if (error) {
          onCancel()
          return
        }
        this.props
          .DetachFiles({
            variables: {
              id: this.props.playlistId,
              file_ids: files
            }
          })
          .then(res => {
            if (res.data.detachFiles.success) {
              this.props.refetch()
              if (onCancel) {
                onCancel()
              }
              this.props.alert('Files removed from the playlist successfully')
            }
          })
          .catch(() => {
            this.props.refetch()
            if (onCancel) {
              onCancel()
            }
            this.props.alert('Unable to remove playlist try again')
          })
      }
    })

    if (actions.length === 0) {
      actions = false
    }

    return () => actions
  })()
  changeRowOrder=({ moveRow, index, rowsPage })=> {
    let curRow = rowsPage[index]
    let rows = rowsPage
    if (moveRow === 'up') {
      rows[index] = rows[index - parseInt('1', 10)]
      rows[index - parseInt('1', 10)] = curRow
    } else if (moveRow === 'down') {
      rows[index] = rows[index + parseInt('1', 10)]
      rows[index + parseInt('1', 10)] = curRow
    }
    let files = {}
    rows.map((cur, index)=>{
      files[`"${index}"`] = {
        fileId: parseInt(cur.id),
        seq: index + parseInt('1', 10)
      }
      return null
    })

    this.props.updateSeqPlaylist({
      variables: {
        id: this.props.playlistId,
        files: {
          ...files
        }
      }

    }).then(()=> {
      if (this.props.refetch) {
        this.props.refetch()
        this.setState({
          ...this.state,
          renderKey: `ncnc${Math.random() * (10 - 9) + 9}`
        }
        )
      }
    }
    )
  }
  getActions = (() => {
    let actions = []
    if (this.props.refetch) {
      actions.push({
        name: 'restart',
        iconDescription: 'Refresh',
        onClick: () => this.props.refetch()
      })
    }

    if (actions.length === 0) {
      actions = false
    }

    return () => actions
  })()
  getRowActions = (() => {
    let actions = []

    actions.push({
      itemText: 'Go to file',
      onClick: row => {
        const { history } = this.props
        if (row.projectId && row.folderId) {
          history.push(`/projects/${row.projectId}/files/${row.folderId}`)
          return
        } else if (row.projectId) {
          history.push(`/projects/${row.projectId}/files`)
          return
        } else if (row.folderId) {
          history.push(`/files/${row.folderId}`)
          return
        }
        history.push(`/files`)
      },
      canBeApplied: () => true
    })
    actions.push({
      itemText: 'Remove from playlist',
      onClick: row => {
        const ids = []
        ids.push(parseInt(row.id, 10))
        this.props
          .DetachFiles({
            variables: {
              id: this.props.playlistId,
              file_ids: ids
            }
          })
          .then(res => {
            if (res.data.detachFiles.success) {
              this.props.refetch()

              this.props.alert('Files removed from the playlist successfully')
            }
          })
          .catch(() => {
            this.props.alert('Unable to remove playlist try again')
          })
      },
      canBeApplied: () => true
    })

    return () => actions
  })();

  isFavourite(row) {
    if (row.type) {
      return this.props.fileFavourites.indexOf(parseInt(row.id, 10)) > -1
    }

    return this.props.folderFavourites.indexOf(parseInt(row.id, 10)) > -1
  }

  generateLink(row) {
    if (row.isPreviewable) {
      const isPNG = row.type === 'png' || row.type === 'PNG'
      const isJPG = row.type === 'jpg' || row.type === 'JPG'
      const isJPEG = row.type === 'jpeg' || row.type === 'JPEG'

      if (isPNG || isJPG || isJPEG) {
        return () => this.props.filePreviewModal.open(row.id)
      }
      return () => this.props.waveformPreviewModal.open(row)
    }

    if (row.type) {
      return null
    }
    if (this.props.canRestore) {
      return `#`
    }

    if (row.projectId) {
      return `/projects/${row.projectId}/files/${row.id}`
    }

    return `/files/${row.id}`
  }

  getFavouriteType(row) {
    if (row.type) {
      return 'file'
    }

    return 'folder'
  }
  render() {
    let props = { ...this.props }

    // delete props.rows
    delete props.fileFavourites
    delete props.folderFavourites
    return (
      <div key={this.state.renderKey}>
        <ResponsiveTable
          headers={this.getHeaders()}
          {...props}
          isFileTable={false}
          changeRowOrder={this.changeRowOrder}
          actions={this.props.actions && this.getActions()}
          batchActions={this.props.batchActions && this.getBatchActions()}
          rowActions={false}
          linkColumn='name'
          generateLink={this.generateLink}
          showIndex={true}
          emptyText='No Files'
        />
      </div>
    )
  }
}

export default compose(
  DetachPlaylistFiles,
  DuplicateFiles,
  ShareLink,
  PermanentlyDeleteFiles,
  RestoreFiles,
  WithDownloadModal,
  WithFilePreviewModal,
  WithWaveformPreviewModal,
  WithComments,
  WithAlertModal,
  WithShareModal,
  withRouter,
  WithToasterNotification,
  WithMoveCopyFileModal,
  UpdateSeqPlaylist
)(FileTable)

import React from 'react'

const AccountSubMenu = ({
  icon,
  title = '',
  onClickHandler,
  titleClass = '',
  containerClass = ''
}) => {
  return (
    <div
      className={`main-Switcher-Content ${containerClass}`}
      onClick={onClickHandler}
    >
      <img className='account-menu-icon' src={icon} alt='Account Sub Menu' />
      <p className={titleClass}>{title}</p>
    </div>
  )
}

export default AccountSubMenu

import React from 'react'
import ResponsiveTable from './ResponsiveTable'
import { DateTime } from 'luxon'
import { Modal, Form, TextInput } from 'carbon-components-react'
import { compose, Mutation } from 'react-apollo'

import {
  CREATE_FOLDER,
  RENAME_FOLDER,
  RENAME_FILE
} from '../graphql/mutations'

// HOC's
import WithComments from '../hocs/WithComments'
import WithDownloadModal from '../hocs/WithDownloadModal'
import WithFilePreviewModal from '../hocs/WithFilePreviewModal'
import DeleteFiles from '../hocs/mutations/DeleteFiles'
import WithAlertModal from '../hocs/WithAlertModal'
import WithMoveCopyFileModal from '../hocs/WithMoveCopyFileModal'
import WithShareModal from '../hocs/WithShareModal'
import ShareLink from '../hocs/mutations/ShareLink'
import ApolloClient from '../utils/Apollo'
import { GET_FOLDER_STATUS, CHECK_RESTORE } from '../graphql/queries'

import WithWaveformPreviewModal from '../hocs/WithWaveformPreviewModal '
import DuplicateFiles from '../hocs/mutations/DuplicateFiles'
import WithToasterNotification from '../hocs/WithToasterNotification'
import PermanentlyDeleteFiles from '../hocs/mutations/PermanentlyDeleteFiles'
import RestoreFiles from '../hocs/mutations/RestoreFiles'
import { GET_SHARES } from '../graphql/queries/share'
import FolderIcon from '../assets/images/folder.svg'
import WithAttachToPlaylistModal from '../hocs/WithAttachToPlaylistModal'
import { WithMoveFilesTo, WithMoveFilesFrom } from '../hocs/MoveFiles'

class FileTable extends React.PureComponent {
  state = {
    createFolderModalOpen: false,
    createFolderName: '',

    renameFolderModalOpen: false,
    renameFolderName: '',
    renameFolderId: null,

    renameFileModalOpen: false,
    renameFileName: '',
    renameFileId: null
  };

  constructor(props) {
    super(props)

    this.getHeaders = this.getHeaders.bind(this)
    this.getRowActions = this.getRowActions.bind(this)
    this.isFavourite = this.isFavourite.bind(this)
    this.showCreateFolderModal = this.showCreateFolderModal.bind(this)
    this.hideCreateFolderModal = this.hideCreateFolderModal.bind(this)
    this.handleCreateFolderNameChange = this.handleCreateFolderNameChange.bind(
      this
    )

    this.showRenameFolderModal = this.showRenameFolderModal.bind(this)
    this.hideRenameFolderModal = this.hideRenameFolderModal.bind(this)
    this.handleRenameFolderNameChange = this.handleRenameFolderNameChange.bind(
      this
    )

    this.showRenameFileModal = this.showRenameFileModal.bind(this)
    this.hideRenameFileModal = this.hideRenameFileModal.bind(this)
    this.handleRenameFileNameChange = this.handleRenameFileNameChange.bind(
      this
    )
    this.generateLink = this.generateLink.bind(this)
    this.alertMsg = this.alertMsg.bind(this)
  }

  getHeaders = (() => {
    if (this.props.favouritePage) {
      const favouriteHeaders = [
        {
          key: 'name',
          header: 'Name'
        },
        {
          key: 'type',
          header: 'File Type',
          // Cache the file type elements
          formatter: (() => {
            const types = {}
            return type => {
              if (!types[type]) {
                types[type] = (
                  <div
                    className={`fileType fileType--compact fileType--${type}`}
                  >
                    {type ? type : 'Folder'}
                  </div>
                )
              }
              return types[type]
            }
          })()
        },
        {
          key: 'prettySize',
          header: 'Size',
          formatter: data => (data ? data : '-')
        },
        {
          key: 'commentCount',
          header: 'Comments'
        },
        {
          key: 'createdAt',
          header: 'Added',
          formatter: data => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
        }
      ]

      return () => favouriteHeaders
    }
    // adds special formatter for Project Files table
    if (this.props.projectFilesHeaderFormat) {
      const headers = [
        {
          key: 'name',
          header: 'Title',
          formatter: data => (data ? `Project: ${data}` : '-')
        },
        {
          key: 'type',
          header: 'File Type',
          // Cache the file type elements
          formatter: (() => {
            const types = {}
            return type => {
              if (!types[type]) {
                types[type] = (
                  <div className='fileType-container'>
                    {type ? (
                      <p className='fileType-chip'>{type.toUpperCase()}</p>
                    ) : (
                      <img
                        src={FolderIcon}
                        alt='Folder Icon'
                        className='fileType-icon'
                      />
                    )}
                    <div>
                      <p>{type ? type.toUpperCase() : 'Folder'}</p>
                    </div>
                  </div>
                )
              }
              return types[type]
            }
          })()
        },
        {
          key: 'prettySize',
          header: 'Size',
          formatter: data => (data ? data : '-')
        },
        {
          key: 'commentCount',
          header: 'Comments'
        },
        {
          key: 'createdAt',
          header: 'Added',
          formatter: data => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
        }
      ]
      return () => headers
    }
    // adds special formatter for Song Files table
    if (this.props.songFiles) {
      const headers = [
        {
          key: 'name',
          header: 'Title',
          formatter: (data, row) =>
            data ? <a href={`/songs/${row.id}/files#song`}>{`${data}`}</a> : '-'
        },
        {
          key: 'type',
          header: 'File Type',
          formatter: data =>
            data ? (
              <div className='fileType-container'>
                <img
                  src={FolderIcon}
                  alt='Folder Icon'
                  className='fileType-icon'
                />
                <p> Folder </p>
              </div>
            ) : (
              '-'
            )
        },
        {
          key: 'prettySize',
          header: 'Size',
          formatter: data => (data ? data : '-')
        },
        {
          key: 'commentCount',
          header: 'Comments',
          formatter: data => (data ? data : '-')
        },
        {
          key: 'createdAt',
          header: 'Added',
          formatter: data => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
        }
      ]
      return () => headers
    }
    // headers for all other file tables
    const headers = [
      {
        key: 'name',
        header: 'Title'
      },
      {
        key: 'type',
        header: 'File Type',
        // Cache the file type elements
        formatter: (() => {
          const types = {}
          return type => {
            if (!types[type]) {
              types[type] = (
                <div className='fileType-container'>
                  {type ? (
                    <p className='fileType-chip'>{type.toUpperCase()}</p>
                  ) : (
                    <img
                      src={FolderIcon}
                      alt='Folder Icon'
                      className='fileType-icon'
                    />
                  )}
                  <div>
                    <p>{type ? type.toUpperCase() : 'Folder'}</p>
                  </div>
                </div>
              )
            }
            return types[type]
          }
        })()
      },
      {
        key: 'prettySize',
        header: 'Size',
        formatter: data => (data ? data : '-')
      },
      {
        key: 'commentCount',
        header: 'Comments'
      },
      {
        key: 'createdAt',
        header: 'Added',
        formatter: data => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
      }
    ]

    return () => headers
  })();

  alertMsg() {
    this.props.toaster('Added to Playlist(s)', { kind: 'success', title: '' })
    setTimeout(() => {
      this.props.onToasterClose()
    }, 5000)
  }

  getBatchActions = (() => {
    const {
      projectId,
      project,
      isProjectOwner,
      song,
      recording,
      refetch,
      projectFiles,
      songFiles
    } = this.props
    let actions = []
    if (this.props.canDownload) {
      actions.push({
        name: 'downloads',
        iconDescription: 'Download',
        onClick: (selectedRows, rowsById, onCancel) => {
          let files = selectedRows.map(selectedRow => {
            const row = rowsById[selectedRow.id]
            return {
              id: parseInt(row.id, 10),
              type: row.type ? 'file' : 'folder'
            }
          })
          ApolloClient.query({
            query: GET_FOLDER_STATUS,
            fetchPolicy: 'network-only',
            variables: {
              input: files
            }
          }).then(data => {
            if (data.data.getFoldersStatus.success) {
              this.props.downloadFiles(files)
              onCancel()
            } else {
              this.props.alert(
                'One or several folders selected cannot be shared or downloaded as they are empty'
              )
              onCancel()
            }
          })
        }
      })
    }
    if (this.props.canDelete) {
      actions.push({
        name: 'playlist-add',
        iconDescription: 'Add to Playlist',
        onClick: (selectedRows, rowsById, onCancel) => {
          let error = false
          let files = selectedRows.map(selectedRow => {
            const row = rowsById[selectedRow.id]
            const isPNG = row.type === 'png' || row.type === 'PNG'
            const isJPG = row.type === 'jpg' || row.type === 'JPG'
            const isJPEG = row.type === 'jpeg' || row.type === 'JPEG'

            if (!row.type || isPNG || isJPG || isJPEG) {
              this.props.alert('Only audio files can be added to a playlist')
              error = true
            }
            return parseInt(row.id, 10)
          })

          if (error) {
            return
          }

          this.props.attachToPlaylistModal.open(files, onCancel, this.alertMsg)
        }
      })
      if (!this.props.hideShare) {
        actions.push({
          name: 'share',
          iconDescription: 'Share',
          onClick: (selectedRows, rowsById, onCancel) => {
            let files = selectedRows.map(selectedRow => {
              const row = rowsById[selectedRow.id]
              return {
                id: parseInt(row.id, 10),
                type: row.type ? 'file' : 'folder',
                name: row.name,
                prettySize: row.prettySize
              }
            })
            let checkedFiles = files.map(function (item) {
              return {
                id: item.id,
                type: item.type
              }
            })
            ApolloClient.query({
              query: GET_FOLDER_STATUS,
              fetchPolicy: 'network-only',
              variables: {
                input: checkedFiles
              }
            }).then(data => {
              if (data.data.getFoldersStatus.success) {
                this.props.shareModal.open('create', { files }, onCancel)
              } else {
                onCancel()

                this.props.alert(
                  'One or several folders selected cannot be shared or downloaded as they are empty'
                )
                onCancel()
              }
            })
          }
        })
      }
    }

    if (this.props.canDelete) {
      actions.push(
        ...(projectFiles || songFiles
          ? []
          : [
            {
              name: 'trash',
              iconDescription: 'Delete',
              onClick: async (selectedRows, rowsById, onCancel) => {
                let error = false
                let rows = []
                let files = selectedRows.map(selectedRow => {
                  const row = rowsById[selectedRow.id]
                  rows.push(row)
                  if (row.type) {
                    return {
                      id: parseInt(row.id, 10),
                      type: 'file'
                    }
                  }

                  return {
                    id: parseInt(row.id, 10),
                    type: 'folder'
                  }
                })
                if (error) {
                  onCancel()
                  return
                }
                if (this.props.refetchProject) {
                  this.props.refetchProject()
                }
                const ShareFiles = await this.getShares()
                const isShared = []
                let allFiles = []
                rows.forEach(items => {
                  if (items.files) {
                    items.files.forEach(item => {
                      allFiles.push(item)
                    })
                  } else {
                    allFiles.push(items)
                  }
                })

                allFiles.forEach(item => {
                  ShareFiles.forEach(fileShare => {
                    fileShare.files.forEach(({ file }) => {
                      if (file.id === item.id) {
                        isShared.push(fileShare)
                      }
                    })
                  })
                })

                const IncludedShares = isShared
                  ? isShared.map(share => ({
                    ...share,
                    expiry: share.expiresAt,
                    uuid: share.id,
                    files: share.files.map(shareFile => shareFile.file)
                  }))
                  : []
                const uniqueShares = [
                  ...new Map(
                    IncludedShares.map(item => [item.id, item])
                  ).values()
                ]
                if (uniqueShares.length > 0) {
                  this.props.alert(
                    'The files/folders you are deleting are part of file shares. Deleting these will end the following file shares:',
                    {
                      title: ' ',
                      secondaryText: 'Cancel',
                      buttonText: 'Delete',
                      submitCallback: close => {
                        this.props
                          .deleteFiles({
                            variables: {
                              files
                            },
                            update: this.props.refetch
                          })
                          .then(() => {
                            if (this.props.manualRerender) {
                              this.props.manualRerender()
                            }
                            if (this.props.refetchProject) {
                              this.props.refetchProject()
                            }
                            this.props.alert(
                              `The file has been successfully removed.`
                            )
                          })
                          .catch(() => {
                            this.props.alert(
                              `The file could not be deleted.`
                            )
                          })
                        close()
                      }
                    },
                    uniqueShares
                  )
                  return
                }

                this.props.alert(
                  `Are you sure you want to delete ${
                    selectedRows.length === 1
                      ? 'selected file?'
                      : 'selected files?'
                  }`,
                  {
                    type: 'delete',
                    buttonText: 'Delete',
                    submitCallback: close => {
                      this.props
                        .deleteFiles({
                          variables: {
                            files
                          }
                        })
                        .then(({ data }) => {
                          const { deleteFiles } = data
                          const { success, message } = deleteFiles
                          if (success) {
                            this.props.alert(
                              `${
                                selectedRows.length === 1
                                  ? 'The file'
                                  : 'The files'
                              } has been successfully deleted.`
                            )
                            onCancel()
                            if (this.props.manualRerender) {
                              this.props.manualRerender()
                            }
                            if (this.props.refetchProject) {
                              this.props.refetchProject()
                            }
                            return
                          }
                          this.props.alert(`${message}`)
                          onCancel()
                        })
                        .catch(() => {
                          this.props.alert(
                            `${
                              selectedRows.length === 1
                                ? 'The file'
                                : 'The files'
                            } could not be deleted.`
                          )
                          onCancel()
                        })
                      close()
                    }
                  }
                )
              }
            }
          ])
      )

      actions.push({
        name: 'duplicate',
        iconDescription: 'Duplicate',
        onClick: (selectedRows, rowsById, onCancel) => {
          let error = false

          let files = selectedRows.map(selectedRow => {
            if (error) {
              return true
            }
            const row = rowsById[selectedRow.id]
            if (row.type) {
              return {
                id: parseInt(row.id, 10),
                type: 'file'
              }
            }

            return {
              id: parseInt(row.id, 10),
              type: 'folder'
            }
          })

          if (error) {
            return
          }

          this.props
            .duplicateFiles({
              variables: {
                files
              },
              update: this.props.refetch()
            })
            .then(({ data }) => {
              const { duplicateFiles } = data
              if (duplicateFiles.success) {
                this.props.toaster('Duplication in progress', {
                  kind: 'success',
                  title: 'Success'
                })
                setTimeout(() => {
                  this.props.onToasterClose()
                  this.props.refetch()
                  onCancel()
                }, 5000)
                return
              }
            })
            .catch(err => {
              this.props.toaster('Duplication Failed', {
                kind: 'error',
                title: 'Error'
              })
              setTimeout(() => {
                this.props.onToasterClose()
                this.props.refetch()
                onCancel()
              }, 5000)
            })
        }
      })

      actions.push(
        ...(projectFiles || songFiles
          ? []
          : [
            {
              name: 'move',
              iconDescription: 'Move',
              onClick: (selectedRows, rowsById, onCancel) => {
                let error = false

                let files = selectedRows.map(selectedRow => {
                  if (error) {
                    return true
                  }
                  const row = rowsById[selectedRow.id]
                  if (row.recording) {
                    this.props.alert('Recording folders cannot be moved.', {
                      title: 'Error'
                    })
                    error = true
                  }
                  if (row.readonly) {
                    this.props.alert('Read only folders cannot be moved.', {
                      title: 'Error'
                    })
                    error = true
                  }

                  return {
                    id: parseInt(row.id, 10),
                    name: row.name,
                    type: row.type ? 'file' : 'folder'
                  }
                })

                if (error) {
                  return
                }
                const selectedFrom = (() => {
                  if (projectId) {
                    return [
                      {
                        href: `/projects/${projectId}/files`,
                        name: 'Projects'
                      },
                      {
                        href: `/projects/${projectId}/files`,
                        name: `${project.name}`
                      }
                    ]
                  }
                  if (song) {
                    return [
                      {
                        href: '/songs',
                        name: 'Songs'
                      },
                      {
                        href: `/songs/${song.id}`,
                        name: `${song.title}`
                      }
                    ]
                  }
                  if (recording) {
                    return [
                      {
                        href: `/projects/${recording.projectId}/recordings`,
                        name: 'Recordings'
                      },
                      {
                        href: `/projects/${recording.projectId}/recordings/${
                          recording.id
                        }`,
                        name: `${recording.title}`
                      }
                    ]
                  }
                  return [
                    {
                      href: `/files`,
                      name: `Files`
                    }
                  ]
                })()
                this.props.moveFilesTo.open({
                  files,
                  projectId,
                  folderId: 0,
                  isProjectOwner,
                  onCancel,
                  refetch,
                  selectedFrom
                })
              }
            }
          ])
      )
    }

    if (this.props.canRestore) {
      actions.push({
        name: 'delete_forever',
        iconDescription: 'Delete Permanently',
        onClick: (selectedRows, rowsById, onCancel) => {
          let files = selectedRows.map(selectedRow => {
            const row = rowsById[selectedRow.id]
            if (row.type) {
              return {
                id: parseInt(row.id, 10),
                type: 'file'
              }
            }

            return {
              id: parseInt(row.id, 10),
              type: 'folder'
            }
          })
          this.props.alert(
            `Are you sure you want to permanently delete ${
              selectedRows.length === 1 ? 'selected file?' : 'selected files?'
            }`,
            {
              type: 'delete',
              buttonText: 'Delete',
              submitCallback: close => {
                this.props
                  .permanentDeleteFiles({
                    variables: {
                      files
                    }
                  })
                  .then(({ data }) => {
                    data.permanentDeleteFiles.success
                      ? this.props.refetch()
                      : this.props.alert(
                        'Selected file(s) could not be deleted.'
                      )
                    onCancel()
                  })
                  .catch(() => {
                    this.props.alert('Selected file(s) could not be deleted.')
                    onCancel()
                  })
                close()
              }
            }
          )
        }
      })

      actions.push({
        name: 'restore',
        iconDescription: 'Restore',
        onClick: (selectedRows, rowsById, onCancel) => {
          let files = selectedRows.map(selectedRow => {
            const row = rowsById[selectedRow.id]
            if (row.type) {
              return {
                id: parseInt(row.id, 10),
                type: 'file'
              }
            }

            return {
              id: parseInt(row.id, 10),
              type: 'folder'
            }
          })
          this.props.alert(
            `Are you sure you want to restore ${
              selectedRows.length === 1 ? 'this file?' : 'these files?'
            }`,
            {
              type: 'delete',
              buttonText: 'Restore',
              submitCallback: close => {
                ApolloClient.query({
                  query: CHECK_RESTORE,
                  variables: {
                    files
                  },
                  fetchPolicy: 'network-only'
                }).then(({ data }) => {
                  const { checkRestoreFiles } = data
                  if (checkRestoreFiles.success) {
                    this.props
                      .restoreFiles({
                        variables: {
                          files
                        }
                      })
                      .then(({ data }) => {
                        data.restoreFiles.success
                          ? this.props.refetch()
                          : this.props.alert(
                            'Selected file(s) could not be restored.'
                          )
                        onCancel()
                      })
                      .catch(() => {
                        this.props.alert(
                          'Selected file(s) could not be restored.'
                        )
                        onCancel()
                      })
                    return
                  }
                  this.props.alert(
                    `The original location of the files/folders you are trying to restore no longer exists. Your restored files will therefore be placed in your accounts Your Files section.`,
                    {
                      type: 'delete',
                      buttonText: 'Restore',
                      submitCallback: close => {
                        this.props
                          .restoreFiles({
                            variables: {
                              files
                            }
                          })
                          .then(({ data }) => {
                            data.restoreFiles.success
                              ? this.props.refetch()
                              : this.props.alert(
                                'Selected file(s) could not be restored.'
                              )
                            onCancel()
                          })
                          .catch(() => {
                            this.props.alert(
                              'Selected file(s) could not be restored.'
                            )
                            onCancel()
                          })
                        close()
                      }
                    }
                  )
                })

                close()
              }
            }
          )
        }
      })
    }

    if (actions.length === 0) {
      actions = false
    }

    return () => actions
  })();

  getActions = (() => {
    let actions = []
    if (this.props.refetch) {
      actions.push({
        name: 'restart',
        iconDescription: 'Refresh',
        onClick: () => this.props.refetch()
      })
    }
    if (
      this.props.canCreate &&
      !this.props.projectFiles &&
      !this.props.songFiles
    ) {
      actions.push({
        name: 'createFolder',
        iconDescription: 'New Folder',
        onClick: () => this.showCreateFolderModal()
      })
    }

    if (actions.length === 0) {
      actions = false
    }

    return () => actions
  })();

  expiresFormatter = expirationDate => {
    if (!expirationDate) {
      return 'Never'
    }
    if (new Date(expirationDate) < new Date()) {
      return 'Expired'
    }
    return DateTime.fromSQL(expirationDate, { zone: 'utc' }).toISODate()
  };

  getShares = async () => {
    const { data } = await ApolloClient.query({
      query: GET_SHARES,
      fetchPolicy: 'network-only'
    })
    const { getShares } = data
    const allShares = []
    getShares.forEach(item => {
      if (this.expiresFormatter(item.expiresAt) !== 'Expired') {
        allShares.push(item)
      }
    })
    return allShares
  };

  getRowActions = (() => {
    const {
      comments,
      canCreate,
      canDelete,
      canDownload,
      project,
      projectId,
      isProjectOwner,
      recording,
      song,
      refetch,
      hideCopy,
      hideShare
    } = this.props
    let actions = []

    if (
      !this.props.canRestore &&
      !this.props.projectFiles &&
      !this.props.songFiles
    ) {
      actions.push({
        itemText: 'Comments',
        onClick: row =>
          this.props.loadComments(
            row.projectId,
            row.id,
            row.type ? 'file' : 'folder'
          ),
        className: `${comments !== false ? 'mmmW' : 'item-ghosted'}`
      })

      actions.push({
        itemText: 'Download',
        onClick: row => {
          let file = {
            id: parseInt(row.id, 10),
            type: 'folder'
          }

          if (row.type) {
            file = {
              id: parseInt(row.id, 10),
              type: 'file'
            }
          }
          ApolloClient.query({
            query: GET_FOLDER_STATUS,
            fetchPolicy: 'network-only',
            variables: {
              input: [file]
            }
          }).then(data => {
            if (data.data.getFoldersStatus.success) {
              this.props.downloadFiles([file])
            } else {
              this.props.alert(
                'One or several folders selected cannot be shared or downloaded as they are empty'
              )
            }
          })
        },
        className: `${canDownload ? '' : 'item-ghosted'}`,
        canBeApplied: () => true
      })
      actions.push({
        itemText: 'Preview',
        onClick: row => {
          const { id, type } = row
          const mediaTypeList = ['png', 'jpg', 'jpeg', 'mp4', 'mov', 'webp', 'avi']
          const isMediaType = mediaTypeList.includes(type.toLowerCase())
          if (isMediaType) {
            this.props.filePreviewModal.open(id)
            return
          }
          if (type.toLowerCase() === 'pdf') {
            this.props.downloadFiles([
              {
                id: parseInt(id, 10),
                type: 'file'
              }
            ])
            return
          }
          this.props.waveformPreviewModal.open(row)
        },
        canBeApplied: row =>
          row.isPreviewable === true && this.props.canDownload
      })
    }

    if (!this.props.canRestore) {
      actions.push({
        itemText: 'Move',
        onClick: (row, onCancel) => {
          let error = false
          if (row.recording) {
            this.props.alert('Recording folders cannot be moved.', {
              title: 'Error'
            })
            error = true
          }
          if (row.readonly) {
            this.props.alert('Read only folders cannot be moved.', {
              title: 'Error'
            })
            error = true
          }
          if (error) {
            if (typeof onCancel === 'function') {
              onCancel()
            }
            return
          }
          const selectedFrom = (() => {
            if (projectId) {
              return [
                {
                  href: `/projects/${projectId}/files`,
                  name: 'Projects'
                },
                {
                  href: `/projects/${projectId}/files`,
                  name: `${project.name}`
                }
              ]
            }
            if (song) {
              return [
                {
                  href: '/songs',
                  name: 'Songs'
                },
                {
                  href: `/songs/${song.id}`,
                  name: `${song.title}`
                }
              ]
            }
            if (recording) {
              return [
                {
                  href: `/projects/${recording.projectId}/recordings`,
                  name: 'Recordings'
                },
                {
                  href: `/projects/${recording.projectId}/recordings/${
                    recording.id
                  }`,
                  name: `${recording.title}`
                }
              ]
            }
            return [
              {
                href: `/files`,
                name: `Files`
              }
            ]
          })()

          this.props.moveFilesTo.open({
            files: [
              {
                id: parseInt(row.id, 10),
                name: row.name,
                type: row.__typename.toLowerCase()
              }
            ],

            projectId,
            folderId: 0,
            isProjectOwner,
            onCancel,
            refetch,
            selectedFrom
          })
        },
        className: row =>
          `${
            (!canCreate && row.recording) || row.readonly ? 'item-ghosted' : ''
          }`,
        canBeApplied: row => this.props.canCreate && !row.recording
      })
      if (!this.props.projectFiles && !this.props.songFiles) {
        actions.push({
          itemText: 'Share',
          onClick: (row, onCancel) => {
            ApolloClient.query({
              query: GET_FOLDER_STATUS,
              fetchPolicy: 'network-only',
              variables: {
                input: [
                  {
                    id: parseInt(row.id, 10),
                    type: row.type ? 'file' : 'folder'
                  }
                ]
              }
            }).then(data => {
              if (data.data.getFoldersStatus.success) {
                this.props.shareModal.open(
                  'create',
                  {
                    files: [
                      {
                        id: parseInt(row.id, 10),
                        type: row.type ? 'file' : 'folder',
                        name: row.name,
                        prettySize: row.prettySize
                      }
                    ]
                  },
                  onCancel
                )
              } else {
                this.props.alert(
                  'One or several folders selected cannot be shared or downloaded as they are empty'
                )
              }
            })
          },
          className: `${canDelete && !hideShare ? '' : 'item-ghosted'}`,
          canBeApplied: () => true
        })
      }
      if (!this.props.projectFiles && !this.props.songFiles) {
        actions.push({
          itemText: 'Add to playlist',
          onClick: (row, onCancel) => {
            let error = false

            if (row.readonly) {
              this.props.alert('Read only folders cannot be deleted.', {
                title: 'Error'
              })
              error = true
            }

            if (error) {
              return
            }
            let files = [parseInt(row.id, 10)]
            this.props.attachToPlaylistModal.open(
              files,
              onCancel,
              this.alertMsg
            )
          },
          canBeApplied: row =>
            row.type === 'mp3' ||
            row.type === 'wav' ||
            row.type === 'm4a' ||
            row.type === 'aiff' ||
            row.type === 'aif'
        })
      }
      actions.push({
        itemText: 'Delete',
        onClick: async row => {
          let error = false

          if (row.readonly) {
            this.props.alert('Read only folders cannot be deleted.', {
              title: 'Error'
            })
            error = true
          }

          if (error) {
            return
          }
          const ShareFiles = await this.getShares()
          const shares = []
          if (row.files) {
            row.files.forEach(item => {
              ShareFiles.forEach(fileShare => {
                fileShare.files.forEach(({ file }) => {
                  if (file.id === item.id) {
                    shares.push(fileShare)
                  }
                })
              })
            })
          } else {
            ShareFiles.forEach(fileShare => {
              fileShare.files.forEach(({ file }) => {
                if (file.id === row.id) {
                  shares.push(fileShare)
                }
              })
            })
          }
          const IncludedShares = shares
            ? shares.map(share => ({
              ...share,
              expiry: share.expiresAt,
              uuid: share.id,
              files: share.files.map(shareFile => shareFile.file)
            }))
            : []

          const uniqueShares = [
            ...new Map(IncludedShares.map(item => [item.id, item])).values()
          ]
          if (uniqueShares.length > 0) {
            this.props.alert(
              'The files/folders you are deleting are part of file shares. Deleting these will end the following file shares:',
              {
                title: ' ',
                secondaryText: 'Cancel',
                buttonText: 'Delete',
                submitCallback: close => {
                  this.props
                    .deleteFiles({
                      variables: {
                        files: [
                          {
                            id: parseInt(row.id, 10),
                            type: row.type ? 'file' : 'folder'
                          }
                        ]
                      },
                      update: this.props.refetch
                    })
                    .then(() => {
                      if (this.props.manualRerender) {
                        this.props.manualRerender()
                      }
                      if (this.props.refetchProject) {
                        this.props.refetchProject()
                      }
                      this.props.alert(
                        `The file has been successfully removed.`
                      )
                    })
                    .catch(() => {
                      this.props.alert(`The file could not be deleted.`)
                    })
                  close()
                }
              },
              uniqueShares
            )

            return
          }

          this.props.alert(`Are you sure you want to delete selected file?`, {
            type: 'delete',
            buttonText: 'Delete',
            submitCallback: close => {
              this.props
                .deleteFiles({
                  variables: {
                    files: [
                      {
                        id: parseInt(row.id, 10),
                        type: row.type ? 'file' : 'folder'
                      }
                    ]
                  },
                  update: this.props.refetch
                })
                .then(() => {
                  if (this.props.manualRerender) {
                    this.props.manualRerender()
                  }
                  if (this.props.refetchProject) {
                    this.props.refetchProject()
                  }
                  this.props.alert(`The file has been successfully removed.`)
                })
                .catch(() => {
                  this.props.alert(`The file could not be deleted.`)
                })
              close()
            }
          })
        },
        className: row => {
          return `${!canDelete || row.readonly ? 'item-ghosted' : ''}`
        },
        canBeApplied: () => true
      })
      if (!this.props.projectFiles && !this.props.songFiles) {
        actions.push({
          itemText: 'Duplicate',
          onClick: row => {
            this.props
              .duplicateFiles({
                variables: {
                  files: [
                    {
                      id: parseInt(row.id, 10),
                      type: row.type ? 'file' : 'folder'
                    }
                  ]
                },
                update: this.props.refetch
              })
              .then(({ data }) => {
                const { duplicateFiles } = data
                if (duplicateFiles.success) {
                  this.props.toaster('Duplication in progress', {
                    kind: 'success',
                    title: 'Success'
                  })
                  setTimeout(() => {
                    this.props.onToasterClose()
                  }, 5000)
                  return
                }
              })
              .catch(err => {
                this.props.toaster('Duplication Failed', {
                  kind: 'error',
                  title: 'Error'
                })
                setTimeout(() => {
                  this.props.onToasterClose()
                }, 5000)
              })
          },
          className: `${canDelete && canCreate ? '' : 'item-ghosted'}`
        })
        actions.push({
          itemText: 'Copy Link',
          onClick: row => {
            let file = {
              id: parseInt(row.id, 10),
              type: 'folder'
            }

            if (row.type) {
              file = {
                id: parseInt(row.id, 10),
                type: 'file'
              }
            }

            this.props
              .shareDirectLinks({
                variables: {
                  files: [file],
                  expiry: '',
                  password: '',
                  message: ''
                }
              })
              .then(({ data }) => {
                if (data.shareDirectLinks.url) {
                  navigator.clipboard.writeText(data.shareDirectLinks.url)
                  this.props.alert(
                    'A link to this file or folder has been copied to clipboard. You can now share it.'
                  )
                } else {
                  this.props.alert(
                    'One or several folders selected cannot be shared or downloaded as they are empty'
                  )
                }
              })
              .catch(err => console.log(err))
          },
          className: `${
            canDelete && canCreate && !hideCopy ? '' : 'item-ghosted'
          }`,
          canBeApplied: () => true
        })
      }
      if (!this.props.projectFiles && !this.props.songFiles) {
        actions.push({
          itemText: 'Rename',
          onClick: row =>
            row.type
              ? this.showRenameFileModal(row)
              : this.showRenameFolderModal(row),
          className: `${canCreate ? '' : 'item-ghosted'}`,
          canBeApplied: row =>
            this.props.canCreate && (!row.type || (row.type && !row.recording))
        })
      }
    }
    if (this.props.canRestore) {
      actions.push({
        itemText: 'Delete Permanently',
        onClick: row => {
          this.props.alert(
            `Are you sure you want to permanently delete selected file?`,
            {
              type: 'delete',
              buttonText: 'Delete',
              submitCallback: close => {
                this.props
                  .permanentDeleteFiles({
                    variables: {
                      files: [
                        {
                          id: parseInt(row.id, 10),
                          type: row.type ? 'file' : 'folder'
                        }
                      ]
                    }
                  })
                  .then(({ data }) => {
                    data.permanentDeleteFiles.success // eslint-disable-line
                      ? this.props.refetch()
                      : this.props.alert(
                        'Selected file(s) could not be deleted.'
                      )
                  })
                  .catch(() => {
                    this.props.alert('Selected file(s) could not be deleted.')
                  })
                close()
              }
            }
          )
        }
      })

      actions.push({
        itemText: 'Restore',
        onClick: row => {
          this.props.alert(`Are you sure you want to restore this file?`, {
            type: 'delete',
            buttonText: 'Restore',
            submitCallback: close => {
              ApolloClient.query({
                query: CHECK_RESTORE,
                variables: {
                  files: [
                    {
                      id: parseInt(row.id, 10),
                      type: row.type ? 'file' : 'folder'
                    }
                  ]
                },
                fetchPolicy: 'network-only'
              }).then(({ data }) => {
                const { checkRestoreFiles } = data
                if (checkRestoreFiles.success) {
                  this.props
                    .restoreFiles({
                      variables: {
                        files: [
                          {
                            id: parseInt(row.id, 10),
                            type: row.type ? 'file' : 'folder'
                          }
                        ]
                      }
                    })
                    .then(({ data }) => {
                      data.restoreFiles.success // eslint-disable-line
                        ? this.props.refetch()
                        : this.props.alert(
                          'Selected file(s) could not be restored.'
                        )
                    })
                    .catch(() => {
                      this.props.alert(
                        'Selected file(s) could not be restored.'
                      )
                    })
                  return
                }
                this.props.alert(
                  `The original location of the files/folders you are trying to restore no longer exists. Your restored files will therefore be placed in your accounts Your Files section.`,
                  {
                    type: 'delete',
                    buttonText: 'Restore',
                    submitCallback: close => {
                      this.props
                        .restoreFiles({
                          variables: {
                            files: [
                              {
                                id: parseInt(row.id, 10),
                                type: row.type ? 'file' : 'folder'
                              }
                            ]
                          }
                        })
                        .then(({ data }) => {
                          data.restoreFiles.success // eslint-disable-line
                            ? this.props.refetch()
                            : this.props.alert(
                              'Selected file(s) could not be restored.'
                            )
                        })
                        .catch(() => {
                          this.props.alert(
                            'Selected file(s) could not be restored.'
                          )
                        })
                      close()
                      return
                    }
                  }
                )
              })

              close()
            }
          })
        }
      })
    }

    return () => actions
  })();

  isFavourite(row) {
    if (row.type) {
      return this.props.fileFavourites.indexOf(parseInt(row.id, 10)) > -1
    }

    return this.props.folderFavourites.indexOf(parseInt(row.id, 10)) > -1
  }

  generateLink(row) {
    if (row.isPreviewable && this.props.canDownload) {
      const { id, type } = row
      const mediaTypeList = ['png', 'jpg', 'jpeg', 'mp4', 'mov', 'webp', 'avi']
      const isMediaType = mediaTypeList.includes(type.toLowerCase())
      if (isMediaType) {
        return () => this.props.filePreviewModal.open(id)
      }
      if (type.toLowerCase() === 'pdf') {
        return () =>
          this.props.downloadFiles([
            {
              id: parseInt(id, 10),
              type: 'file'
            }
          ])
      }
      return () => this.props.waveformPreviewModal.open(row)
    }

    if (row.type) {
      return null
    }
    if (this.props.canRestore) {
      return `#`
    }

    if (row.projectId) {
      return `/projects/${row.projectId}/files/${row.id}`
    }

    if (row.fileLink) {
      return `/projects/${row.id}/files#all`
    }
    if (this.props.songId) {
      return `/songs/${this.props.songId}/files/${row.id}`
    }

    if (row.songLink) {
      return `/songs/${row.id}/files#song`
    }

    return `/files/${row.id}`
  }

  getFavouriteType(row) {
    if (row.type) {
      return 'file'
    }

    return 'folder'
  }

  showCreateFolderModal() {
    this.setState({
      ...this.state,
      createFolderModalOpen: true,
      createFolderName: ''
    })
  }

  hideCreateFolderModal() {
    this.setState({
      ...this.state,
      createFolderModalOpen: false,
      createFolderName: ''
    })
  }

  handleCreateFolderNameChange(e) {
    this.setState({
      ...this.state,
      // createFolderModalOpen: true,
      createFolderName: e.target.value
    })
  }

  showRenameFolderModal(folder) {
    this.setState({
      ...this.state,
      renameFolderModalOpen: true,
      renameFolderName: folder.name,
      renameFolderId: folder.id
    })
  }

  hideRenameFolderModal() {
    this.setState({
      ...this.state,
      renameFolderModalOpen: false,
      renameFolderName: '',
      renameFolderId: null
    })
  }

  handleRenameFolderNameChange(e) {
    this.setState({
      ...this.state,
      renameFolderName: e.target.value
    })
  }

  showRenameFileModal(file) {
    this.setState({
      ...this.state,
      renameFileModalOpen: true,
      renameFileName: file.name,
      renameFileId: file.id
    })
  }

  hideRenameFileModal() {
    this.setState({
      ...this.state,
      renameFileModalOpen: false,
      renameFileName: '',
      renameFileId: null
    })
  }

  handleRenameFileNameChange(e) {
    this.setState({
      ...this.state,
      renameFileName: e.target.value
    })
  }

  render() {
    let props = { ...this.props }
    // delete props.rows
    delete props.fileFavourites
    delete props.folderFavourites

    return (
      <>
        <ResponsiveTable
          headers={this.getHeaders()}
          {...props}
          isFileTable={true}
          actions={this.props.actions && this.getActions()}
          batchActions={this.props.batchActions && this.getBatchActions()}
          rowActions={this.props.rowActions && this.getRowActions()}
          isFavourite={this.isFavourite}
          linkColumn='name'
          generateLink={this.generateLink}
          getFavouriteType={this.getFavouriteType}
          emptyText='No Files'
        />

        {this.props.actions && this.state.createFolderModalOpen && (
          <Mutation
            mutation={CREATE_FOLDER}
            update={() => {
              this.props.refetch()
              this.hideCreateFolderModal()
            }}
            errorPolicy='ignore'
          >
            {(createFolder, { error }) => (
              <Modal
                className='modal rename-folder'
                open={this.state.createFolderModalOpen}
                modalHeading='New Folder'
                primaryButtonText='Create'
                primaryButtonDisabled={
                  !this.state.createFolderName ? true : false
                }
                secondaryButtonText='Cancel'
                shouldSubmitOnEnter={true}
                selectorPrimaryFocus='.bx--text-input'
                onRequestSubmit={e => {
                  e.preventDefault()

                  createFolder({
                    variables: {
                      projectId: this.props.projectId,
                      folderId: this.props.folderId,
                      name: this.state.createFolderName
                    }
                  }).catch(() => {
                    //
                  })
                }}
                onRequestClose={this.hideCreateFolderModal}
              >
                <div className='form'>
                  <Form>
                    <div className='formRow'>
                      <TextInput
                        labelText='Name'
                        id='folderName'
                        onChange={this.handleCreateFolderNameChange}
                        value={this.state.createFolderName}
                        invalid={error ? true : false}
                        invalidText={
                          error ? error.graphQLErrors[0].message : ''
                        }
                      />
                    </div>
                  </Form>
                </div>
              </Modal>
            )}
          </Mutation>
        )}

        <Mutation
          mutation={RENAME_FOLDER}
          update={() => {
            this.props.refetch()
            this.hideRenameFolderModal()
          }}
          errorPolicy='ignore'
        >
          {(renameFolder, { error }) => (
            <Modal
              className='modal rename-folder'
              open={this.state.renameFolderModalOpen}
              modalHeading='Rename Folder'
              primaryButtonText='Rename'
              primaryButtonDisabled={
                !this.state.renameFolderName ? true : false
              }
              secondaryButtonText='Cancel'
              shouldSubmitOnEnter={true}
              selectorPrimaryFocus='.bx--text-input'
              onRequestSubmit={e => {
                e.preventDefault()

                renameFolder({
                  variables: {
                    projectId: this.props.projectId,
                    folderId: this.state.renameFolderId,
                    name: this.state.renameFolderName
                  }
                }).catch(() => {
                  //
                })
              }}
              onRequestClose={this.hideRenameFolderModal}
            >
              <div className='form'>
                <Form>
                  <div className='formRow'>
                    <TextInput
                      labelText='Name'
                      id='folderName'
                      onChange={this.handleRenameFolderNameChange}
                      value={this.state.renameFolderName}
                      invalid={error ? true : false}
                      invalidText={error ? error.graphQLErrors[0].message : ''}
                    />
                  </div>
                </Form>
              </div>
            </Modal>
          )}
        </Mutation>

        {this.state.renameFileModalOpen && (
          <Mutation
            mutation={RENAME_FILE}
            update={() => {
              this.props.refetch()
              this.hideRenameFileModal()
            }}
            errorPolicy='ignore'
          >
            {(renameFile, { error }) => (
              <>
                <Modal
                  className='modal rename-file'
                  open={this.state.renameFileModalOpen}
                  modalHeading='Rename File'
                  primaryButtonText='Rename'
                  primaryButtonDisabled={
                    !this.state.renameFileName ? true : false
                  }
                  secondaryButtonText='Cancel'
                  shouldSubmitOnEnter={true}
                  selectorPrimaryFocus='.bx--text-input'
                  onRequestSubmit={e => {
                    e.preventDefault()

                    renameFile({
                      variables: {
                        projectId: this.props.projectId,
                        folderId: this.props.folderId,
                        fileId: this.state.renameFileId,
                        name: this.state.renameFileName
                      }
                    }).catch(() => {
                      //
                    })
                  }}
                  onRequestClose={this.hideRenameFileModal}
                >
                  <div className='form'>
                    <Form>
                      <div className='formRow'>
                        <TextInput
                          labelText='New Name *'
                          id='folderName'
                          onChange={this.handleRenameFileNameChange}
                          value={this.state.renameFileName}
                          invalid={error ? true : false}
                          invalidText={
                            error ? error.graphQLErrors[0].message : ''
                          }
                        />{' '}
                      </div>
                    </Form>
                  </div>
                </Modal>
              </>
            )}
          </Mutation>
        )}
      </>
    )
  }
}

export default compose(
  DeleteFiles,
  DuplicateFiles,
  ShareLink,
  PermanentlyDeleteFiles,
  RestoreFiles,
  WithDownloadModal,
  WithFilePreviewModal,
  WithAttachToPlaylistModal,
  WithWaveformPreviewModal,
  WithComments,
  WithAlertModal,
  WithShareModal,
  WithToasterNotification,
  WithMoveCopyFileModal,
  WithMoveFilesTo,
  WithMoveFilesFrom
)(FileTable)

import React from 'react'
import {
  Form,
  Button,
  TextInput
} from 'carbon-components-react'

import Validator from '../utils/Validator'
import Update2FA from '../validators/Update2FA'
import DiscardModal from './modals/DiscardModal'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'

const initialState = {
  phone: '',
  currentPassword: '',
  twoFactorEnabled: true,

  initialErrors: {},
  errors: {},
  discardModal: false
}

class Update2FAForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.validator = Update2FA
    this.disable = this.disable.bind(this)

    this.state.phone = props.phone
    this.state.isFromUpdated = false
    this.state.twoFactorEnabled = props.twoFactorEnabled
    this.discardChanges = this.discardChanges.bind(this)
    this.openDiscardModal = this.openDiscardModal.bind(this)
    this.closeDiscardModal = this.closeDiscardModal.bind(this)
  }

  submit() {
    this.props.onSubmit({
      ...this.state,
      twoFactorEnabled: true
    })

    this.setState({
      ...this.state,
      currentPassword: '',
      twoFactorEnabled: true
    })
  }

  disable() {
    this.handleSubmit(false, () => {
      this.props.onSubmit({
        ...this.state,
        twoFactorEnabled: false
      })

      this.setState({
        ...this.state,
        currentPassword: '',
        twoFactorEnabled: false
      })
    })
  }

  openDiscardModal(e) {
    e.preventDefault()
    if (this.state.isFromUpdated) {
      this.setState({
        discardModal: true
      })
    }
  }

  closeDiscardModal() {
    this.setState({
      discardModal: false
    })
  }

  discardChanges() {
    this.setState({ isFromUpdated: false })
    this.setState({
      phone: this.props.phone || '',
      twoFactorEnabled: this.props.twoFactorEnabled || '',
      discardModal: false,
      currentPassword: '',
      initialErrors: {},
      errors: {}
    })
  }
  onDataChange(e, handleChange) {
    handleChange(e)
    this.setState({ isFromUpdated: true })
  }
  render() {
    return (
      <div className='form'>
        <Form onSubmit={this.handleSubmit} autoComplete='off'>
          <div className='formRow'>
            <TextInput id='phone'
              name='phone'
              disabled={this.props.twoFactorEnabled}
              labelText='Mobile Phone Number'
              onChange={!this.props.twoFactorEnabled ? (e)=> this.onDataChange(e, this.handleChange) : () => {}}
              onBlur={!this.props.twoFactorEnabled ? this.handleDirty : () => {}}
              value={this.state.phone || ''}
              invalid={this.state.errors.phone ? true : false}
              invalidText={this.state.errors.phone || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='currentPassword2fa'
              name='currentPassword'
              labelText='Current Password'
              type='password'
              onChange={(e)=> this.onDataChange(e, this.handleChange)}
              onBlur={this.handleDirty}
              value={this.state.currentPassword || ''}
              invalid={this.state.errors.currentPassword ? true : false}
              invalidText={this.state.errors.currentPassword || ''} />
          </div>
          {this.props.twoFactorEnabled &&
            <div className='formRow'>
              <button className='bx--btn bx--btn--secondary' onClick={this.openDiscardModal}>
                Cancel
              </button>
              <Button type='button' onClick={this.disable}>
                {this.props.loading && !this.state.twoFactorEnabled ? 'Disabling...' : 'Disable' }
              </Button>
            </div>
          }
          {!this.props.twoFactorEnabled &&
            <div className='formRow actionBtnContainer'>
              <button className='bx--btn bx--btn--secondary' onClick={this.openDiscardModal}>
                Cancel
              </button>
              <Button type='submit'>
                {this.props.loading ? 'Enabling...' : 'Enable' }
              </Button>
            </div>
          }
        </Form>
        {
          this.state.discardModal && <DiscardModal open={this.state.discardModal}
            onRequestClose={this.closeDiscardModal}
            modalHeading='Discard Changes?'
            handleSubmit={this.discardChanges}
          />
        }
      </div>
    )
  }
}

export default compose(
  withRouter
)(Update2FAForm)


import React from 'react'
import { ReactComponent as FolderIcon } from '../assets/images/folder.svg'

import { DataTable } from 'carbon-components-react'

const { TableCell, Table, TableRow, TableBody } = DataTable

const EmptySection = ({ content }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className='empty-row'>
            <FolderIcon /> <span>{content}</span>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default EmptySection

import React from 'react'
import classNames from 'classnames'
import right from '../assets/leftMenu/right.svg'
import left from '../assets/leftMenu/left.svg'
export default class ToggleMenu extends React.PureComponent {
  render() {
    // Size options are "Small", "Medium" and "Large"
    const menuSize = `appMenuToggle ${this.props.size}`

    const classes = classNames({
      [menuSize]: true,
      appMenuToggleActive: this.props.open
    })

    return (
      <button className={classes} onClick={this.props.updateMenu}>
        <img className='appMenuToggleIcon'
          src={this.props.open === false ? right : left} alt='icon'/>
      </button>
    )
  }
}

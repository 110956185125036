import gql from 'graphql-tag'
import * as fragment from './fragments'

export const DO_LOGIN = gql`
  mutation DoLogin($email: String!, $password: String!) {
    doLogin(input: { email: $email, password: $password }) {
      accessToken
      twoFactor
    }
  }
`

export const DO_REFRESH = gql`
  mutation DoRefresh {
    doRefresh {
      accessToken
    }
  }
`

export const DO_REGISTER = gql`
  mutation DoRegister(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $timezoneId:ID
    $plan: String!
    $stripeToken: String
    $coupon: String
    $referralCode: String
  ) {
    doRegister(
      input: {
        first_name: $firstName
        last_name: $lastName
        email: $email
        password: $password
        password_confirmation: $passwordConfirmation
        timezone_id: $timezoneId
        plan: $plan
        stripe_token: $stripeToken
        coupon: $coupon
        referral_code: $referralCode 
      }
    ) {
      success
    }
  }
`

export const DO_FORGOT_PASSWORD = gql`
  mutation DoForgotPassword($email: String!) {
    doForgotPassword(input: { email: $email }) {
      success
      message
    }
  }
`

export const DO_RESET_PASSWORD = gql`
  mutation DoResetPassword(
    $token: String!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    doResetPassword(
      input: {
        token: $token
        email: $email
        password: $password
        password_confirmation: $passwordConfirmation
      }
    ) {
      success
    }
  }
`

export const DO_VERIFICATION = gql`
  mutation DoVerification($email: String!, $token: String!) {
    doVerification(input: { email: $email, token: $token }) {
      accessToken
    }
  }
`

export const DO_TWO_FACTOR = gql`
  mutation DoTwoFactor($code: String!, $token: String!) {
    doTwoFactor(input: { code: $code, token: $token }) {
      accessToken
      authenticated
      tokenType
    }
  }
`

export const ACCEPT_COLLABORATOR_INVITE = gql`
  mutation AcceptCollaboratorInvite($token: String!) {
    acceptCollaboratorInvite(input: { token: $token }) {
      id
      project {
        id
      }
    }
  }
`

export const CREATE_COMMENT = gql`
  mutation CreateComment(
    $projectId: ID!
    $resourceId: ID!
    $resourceType: String!
    $message: String!
    $checklist: [CreateCommentChecklistInput]
  ) {
    createComment(
      input: {
        project_id: $projectId
        resource_id: $resourceId
        resource_type: $resourceType
        message: $message
      },
      checklist: $checklist
    ) {
      ...CommentFields
    }
  }
  ${fragment.COMMENT_FIELDS}
`
export const DELETE_COMMENT = gql`
  mutation DeleteComment(
    $input:DeleteCommentInput!
  ) {
    deleteComment(
      input: $input
    ) {
      ...CommentFields
    }
  }

  fragment CommentFields on Comment {
    id
    message
    createdAt
    resourceId
    resourceType
    resource {
      id
    }
    user {
      firstName
      lastName
    }
    parentId
    checklist
  }

`

export const CREATE_USER_FAVOURITE = gql`
  mutation CreateUserFavourite($resource_type: String!, $resource_id: Int!) {
    createUserFavourite(input: { resource_type: $resource_type, resource_id: $resource_id }) {
      ...FavouriteFields
    }
  }
  ${fragment.FAVOURITE_FIELDS}
`

export const DELETE_USER_FAVOURITE = gql`
  mutation DeleteUserFavourite($resource_type: String!, $resource_id: Int!) {
    deleteUserFavourite(input: { resource_type: $resource_type, resource_id: $resource_id }) {
      ...FavouriteFields
    }
  }
  ${fragment.FAVOURITE_FIELDS}
`

export const DELETE_FILES = gql`
  mutation DeleteFiles($files: [DeleteFilesInput!]!) {
    deleteFiles(files: $files) {
      success
      message
    }
  }
`

export const MOVE_FILES = gql`
  mutation MoveFiles($files: [MoveFileInput!]!,
     $project_id: ID, $folder_id: ID,  $recording_id: ID) {
    moveFiles(input: { files: $files, project_id: $project_id,
      folder_id: $folder_id,recording_id: $recording_id  }) {
      success
    }
  }
`

export const DUPLICATE_FILES = gql`
  mutation DuplicateFiles($files: [DuplicateFileInput!]!) {
    duplicateFiles(input: { files: $files}) {
          success
    }
  }
`

export const DOWNLOAD_FILES = gql`
  mutation DownloadFiles($files: [DownloadFilesInput!]!) {
    downloadFiles(files: $files) {
      success
      url
    }
  }
`

export const CREATE_FOLDER = gql`
  mutation CreateFolder($projectId: ID, $folderId: ID, $name: String!) {
    createFolder(input: { projectId: $projectId, folderId: $folderId, name: $name }) {
      id
      name
      createdAt
    }
  }
`

export const RENAME_FOLDER = gql`
  mutation RenameFolder($projectId: ID, $folderId: ID!, $name: String!) {
    renameFolder(input: { projectId: $projectId, folderId: $folderId, name: $name }) {
      id
      name
      createdAt
    }
  }
`

export const RENAME_FILE = gql`
  mutation RenameFile($projectId: ID, $folderId: ID, $fileId: ID!, $name: String!) {
    renameFile(
      input: { projectId: $projectId, folderId: $folderId, fileId: $fileId, name: $name }
    ) {
      id
      name
      createdAt
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $projectId: ID!
    $name: String!
    $description: String
    $labelId: ID
    $number: String
    $artistId: ID
    $image: String
  ) {
    updateProject(
      input: {
        id: $projectId
        name: $name
        description: $description
        label_id: $labelId
        number: $number
        main_artist_id: $artistId
        image: $image
      }
    ) {
      ...ProjectFields
    }
  }
  ${fragment.PROJECT_FIELDS}
`

export const CREATE_PROJECT = gql`
  mutation createProject(
    $name: String!
    $description: String
    $labelId: ID
    $number: String
    $artistId: ID
    $image: String
  ) {
    createProject(
      input: {
        name: $name
        description: $description
        label_id: $labelId
        number: $number
        main_artist_id: $artistId
        image: $image
      }
    ) {
      ...ProjectFields
    }
  }
  ${fragment.PROJECT_FIELDS}
`
export const LOCK_UNLOCK_PROJECT = gql`
mutation LockUnlockProject(
  $projectId: ID!
) {
  lockUnlockProject(
      projectId: $projectId
  ){
      ...ProjectFields
    }
  }
  ${fragment.PROJECT_FIELDS}
`
export const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: ID!, $confirmed: Boolean) {
    deleteProject(input: { id: $projectId, confirmed: $confirmed }) {
      id
    }
  }
`

export const CREATE_RECORDING = gql`
  mutation CreateRecording(
    $projectId: ID!
    $songId: ID!
    $recordingTypeId: ID!
    $recordingTypeUserDefinedValue: String
    $isrc: String
    $subtitle: String
    $version: String
    $recordedOn: String
    $mixedOn: String
    $duration: String
    $languageId: ID
    $keySignature: String
    $timeSignature: String
    $tempo: String
    $description: String
  ) {
    createRecording(
      input: {
        project_id: $projectId
        song_id: $songId
        recording_type_id: $recordingTypeId
        recording_type_user_defined_value: $recordingTypeUserDefinedValue
        isrc: $isrc
        subtitle: $subtitle
        version: $version
        recorded_on: $recordedOn
        mixed_on: $mixedOn
        duration: $duration
        language_id: $languageId
        key_signature: $keySignature
        time_signature: $timeSignature
        tempo: $tempo
        description: $description
      }
    ) {
      ...RecordingFields
    }
  }
  ${fragment.RECORDING_FIELDS}
`

export const UPDATE_RECORDING = gql`
  mutation UpdateRecording(
    $recordingId: ID!
    $songId: ID!
    $recordingTypeId: ID!
    $recordingTypeUserDefinedValue: String
    $isrc: String
    $subtitle: String
    $version: String
    $recordedOn: String
    $mixedOn: String
    $duration: String
    $languageId: ID
    $keySignature: String
    $timeSignature: String
    $tempo: String
    $description: String
  ) {
    updateRecording(
      input: {
        id: $recordingId
        song_id: $songId
        recording_type_id: $recordingTypeId
        recording_type_user_defined_value: $recordingTypeUserDefinedValue
        isrc: $isrc
        subtitle: $subtitle
        version: $version
        recorded_on: $recordedOn
        mixed_on: $mixedOn
        duration: $duration
        language_id: $languageId
        key_signature: $keySignature
        time_signature: $timeSignature
        tempo: $tempo
        description: $description
      }
    ) {
      ...RecordingFields
    }
  }
  ${fragment.RECORDING_FIELDS}
`

export const DELETE_RECORDING = gql`
  mutation DeleteRecording($recordingId: ID!, $confirmed: Boolean) {
    deleteRecording(input: { id: $recordingId, confirmed: $confirmed }) {
      id
    }
  }
`

export const CREATE_SONG = gql`
  mutation CreateSong(
    $songTypeId: ID!
    $songTypeUserDefinedValue: String
    $iswc: String
    $createdOn: String
    $title: String!
    $subtitle: String
    $titleAlt: String
    $subtitleAlt: String
    $notes: String
    $description: String
    $lyrics: String
    $image: String
  ) {
    createSong(
      input: {
        song_type_id: $songTypeId
        song_type_user_defined_value: $songTypeUserDefinedValue
        created_on: $createdOn
        iswc: $iswc
        title: $title
        subtitle: $subtitle
        title_alt: $titleAlt
        subtitle_alt: $subtitleAlt
        notes: $notes
        description: $description
        lyrics: $lyrics
        image: $image
      }
    ) {
      ...SongFields
    }
  }
  ${fragment.SONG_FIELDS}
`

export const UPDATE_SONG = gql`
  mutation UpdateSong(
    $id: ID!
    $songTypeId: ID!
    $songTypeUserDefinedValue: String
    $iswc: String
    $createdOn: String
    $title: String!
    $subtitle: String
    $titleAlt: String
    $subtitleAlt: String
    $notes: String
    $description: String
    $lyrics: String
    $image: String
  ) {
    updateSong(
      input: {
        id: $id
        song_type_id: $songTypeId
        song_type_user_defined_value: $songTypeUserDefinedValue
        iswc: $iswc
        created_on: $createdOn
        title: $title
        subtitle: $subtitle
        title_alt: $titleAlt
        subtitle_alt: $subtitleAlt
        notes: $notes
        description: $description
        lyrics: $lyrics
        image: $image
      }
    ) {
      ...SongFields
    }
  }
  ${fragment.SONG_FIELDS}
`

export const DELETE_SONG = gql`
  mutation DeleteSong($songId: ID!, $confirmed: Boolean) {
    deleteSong(input: { id: $songId, confirmed: $confirmed }) {
      success
      message
      song {
        id
    }
    recordings {
        id
        name
    }
  }
}
`

export const CREATE_PARTY = gql`
  mutation CreateParty(
    $affiliationId:[ID],
    $publisherIds: [ID],
    $isni: String
    $type: String!
    $title: String
    $prefix: String
    $ipiCae: String
    $firstName: String!
    $middleName: String
    $lastName: String
    $suffix: String
    $birthDate: String
    $deathDate: String
    $comments: String
    $contacts:[PartyContactInput]!
    $addresses:[PartyAddressInput]!
  ) {
    createParty(
      input: {
        user_affiliation_ids: $affiliationId,
        publisher_ids: $publisherIds,
        isni: $isni
        ipi_cae: $ipiCae
        type: $type
        title: $title
        prefix: $prefix
        first_name: $firstName
        middle_name: $middleName
        last_name: $lastName
        suffix: $suffix
        birth_date: $birthDate
        death_date: $deathDate
        comments: $comments
        contacts:$contacts
        addresses:$addresses
      }
    ) {
      ...PartyFields
    }
  }
  ${fragment.PARTY_FIELDS}
`
export const CREATE_PARTY_ARTIST = gql`
  mutation CreateParty(
    $affiliationId:[ID],
    $publisherIds: [ID],
    $isni: String
    $ipiCae: String
    $type: String!
    $title: String
    $isMy: Boolean
    $prefix: String
    $firstName: String!
    $middleName: String
    $lastName: String
    $suffix: String
    $birthDate: String
    $deathDate: String
    $comments: String
    $contacts:[PartyContactInput]!
    $addresses:[PartyAddressInput]!
  ) {createParty(
      input: {
        user_affiliation_ids: $affiliationId,
        publisher_ids: $publisherIds,
        isni: $isni
        ipi_cae: $ipiCae
        type: $type
        title: $title
        is_my: $isMy
        prefix: $prefix
        first_name: $firstName
        middle_name: $middleName
        last_name: $lastName
        suffix: $suffix
        birth_date: $birthDate
        death_date: $deathDate
        comments: $comments
        contacts:$contacts
        addresses:$addresses
      }
    ) {
      ...PartyFields
    }
  }
  ${fragment.PARTY_FIELDS_ARTIST}
`

export const UPDATE_PARTY = gql`
  mutation UpdateParty(
    $id: ID!
    $affiliationId:[ID],
    $publisherIds: [ID],
    $isni: String
    $ipiCae: String
    $type: String!
    $title: String
    $prefix: String
    $firstName: String!
    $middleName: String
    $lastName: String
    $suffix: String
    $birthDate: String
    $deathDate: String
    $comments: String
  ) {
    updateParty(
      input: {
        id: $id
        user_affiliation_ids: $affiliationId,
        publisher_ids: $publisherIds,
        isni: $isni
        ipi_cae: $ipiCae
        type: $type
        title: $title
        prefix: $prefix
        first_name: $firstName
        middle_name: $middleName
        last_name: $lastName
        suffix: $suffix
        birth_date: $birthDate
        death_date: $deathDate
        comments: $comments
      }
    ) {
            ...PartyFields
    }
  }
  ${fragment.PARTY_FIELDS}
`

export const DELETE_PARTY = gql`
  mutation DeleteParty($partyId: ID!) {
    deleteParty(input: { id: $partyId }) {
      id
    }
  }
`

export const CREATE_PARTY_ADDRESS = gql`
  mutation CreatePartyAddress(
    $partyId: ID!
    $line1: String!
    $line2: String
    $line3: String
    $city: String!
    $district: String!
    $postalCode: String!
    $countryId: ID!
  ) {
    createPartyAddress(
      input: {
        party_id: $partyId
        line_1: $line1
        line_2: $line2
        line_3: $line3
        city: $city
        district: $district
        postal_code: $postalCode
        country_id: $countryId
      }
    ) {
      ...PartyAddressFields
    }
  }
  ${fragment.PARTY_ADDRESS_FIELDS}
`

export const UPDATE_PARTY_ADDRESS = gql`
  mutation UpdatePartyAddress(
    $id: ID!
    $line1: String!
    $line2: String
    $line3: String
    $city: String!
    $district: String!
    $postalCode: String!
    $countryId: ID!
  ) {
    updatePartyAddress(
      input: {
        id: $id
        line_1: $line1
        line_2: $line2
        line_3: $line3
        city: $city
        district: $district
        postal_code: $postalCode
        country_id: $countryId
      }
    ) {
      ...PartyAddressFields
    }
  }
  ${fragment.PARTY_ADDRESS_FIELDS}
`

export const DELETE_PARTY_ADDRESS = gql`
  mutation DeletePartyAddress($id: ID!) {
    deletePartyAddress(input: { id: $id }) {
      id
    }
  }
`

export const CREATE_PARTY_CONTACT = gql`
  mutation CreatePartyContact($partyId: ID!, $name: String, $type: String!, $value: String!) {
    createPartyContact(
      input: { party_id: $partyId, name: $name, type: $type, value: $value, primary: false }
    ) {
      id
      name
      type
      value
    }
  }
`

export const UPDATE_PARTY_CONTACT = gql`
  mutation UpdatePartyContact($id: ID!, $name: String, $type: String!, $value: String!) {
    updatePartyContact(
      input: { id: $id, name: $name, type: $type, value: $value, primary: false }
    ) {
      id
      name
      type
      value
    }
  }
`

export const DELETE_PARTY_CONTACT = gql`
  mutation DeletePartyContact($id: ID!) {
    deletePartyContact(input: { id: $id }) {
      id
    }
  }
`

export const CREATE_SESSION = gql`
  mutation CreateSession(
    $projectId: ID!
    $sessionTypeId: ID!
    $recordingId: [ID]
    $venueId: ID!
    $name: String!
    $startedAt: String!
    $endedAt: String
    $description: String
    $unionSession: Boolean
    $analogSession: Boolean
    $dropFrame: Boolean
    $venueRoom: String
    $bitdepth: Int
    $samplerate: Int
    $timecodeType: String
    $timecodeFrameRate: String
  ) {
    createSession(
      input: {
        project_id: $projectId
        session_type_id: $sessionTypeId
        venue_id: $venueId
        recording_id: $recordingId
        name: $name
        started_at: $startedAt
        ended_at: $endedAt
        description: $description
        union_session: $unionSession
        analog_session: $analogSession
        drop_frame: $dropFrame
        venue_room: $venueRoom
        bitdepth: $bitdepth
        samplerate: $samplerate
        timecode_type: $timecodeType
        timecode_frame_rate: $timecodeFrameRate
      }
    ) {
      ...SessionFields
    }
  }
  ${fragment.SESSION_FIELDS}
`

export const UPDATE_SESSION = gql`
  mutation UpdateSession(
    $projectId: ID!
    $sessionId: ID!
    $sessionTypeId: ID!
    $venueId: ID!
    $recordingId: [ID]
    $name: String!
    $startedAt: String!
    $endedAt: String
    $description: String
    $unionSession: Boolean!
    $analogSession: Boolean!
    $dropFrame: Boolean!
    $venueRoom: String
    $bitdepth: Int
    $samplerate: Int
    $timecodeType: String
    $timecodeFrameRate: String
  ) {
    updateSession(
      input: {
        project_id: $projectId
        id: $sessionId
        session_type_id: $sessionTypeId
        venue_id: $venueId
        recording_id: $recordingId
        name: $name
        started_at: $startedAt
        ended_at: $endedAt
        description: $description
        union_session: $unionSession
        analog_session: $analogSession
        drop_frame: $dropFrame
        venue_room: $venueRoom
        bitdepth: $bitdepth
        samplerate: $samplerate
        timecode_type: $timecodeType
        timecode_frame_rate: $timecodeFrameRate
      }
    ) {
      ...SessionFields
    }
  }
  ${fragment.SESSION_FIELDS}
`

export const DELETE_SESSION = gql`
  mutation DeleteSession($sessionId: ID!) {
    deleteSession(input: { id: $sessionId }) {
      id
    }
  }
`

export const CREATE_VENUE = gql`
  mutation CreateVenue(
    $name: String!
    $address1: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
    $countryId: String!
  ) {
    createVenue(
      input: {
        name: $name
        address_1: $address1
        address_2: $address2
        city: $city
        state: $state
        zip: $zip
        country: $countryId
      }
    ) {
      id
      name
    }
  }
`

export const CREATE_SESSION_RECORDING = gql`
  mutation CreateSessionRecording($sessionId: ID!, $recordingId: ID!) {
    createSessionRecording(input: { session_id: $sessionId, recording_id: $recordingId }) {
      sessionId
      recordingId
    }
  }
`

export const DELETE_SESSION_RECORDING = gql`
  mutation DeleteSessionRecording($sessionId: ID!, $recordingId: ID!) {
    deleteSessionRecording(input: { session_id: $sessionId, recording_id: $recordingId }) {
      recordingId
    }
  }
`

export const CREATE_CREDIT = gql`
  mutation CreateCredit(
    $partyId: ID!
    $contributionId: ID!
    $contributionType: String!
    $creditRoleId: ID!
    $creditRoleUserDefinedValue: String
    $instrumentId: ID
    $instrumentUserDefinedValue: String
    $performing: Boolean
    $split: Float
    $featureType: String
  ) {
    createCredit(
      input: {
        party_id: $partyId
        contribution_id: $contributionId
        contribution_type: $contributionType
        credit_role_id: $creditRoleId
        credit_role_user_defined_value: $creditRoleUserDefinedValue
        instrument_id: $instrumentId
        instrument_user_defined_value: $instrumentUserDefinedValue
        performing: $performing
        split: $split
        feature_type: $featureType
      }
    ) {
      ...CreditTableFields
      instrument {
        name
      }
      split
    }
  }
  ${fragment.CREDIT_TABLE_FIELDS}
`
export const ASSIGN_MULTIPLE_CREDITS = gql`
mutation AssignMultipleCredits(
  $partyId: ID!
  $contributionIds: [ID]!
  $contributionType: String!
  $featureType: String
  $creditRoleId: ID!
  $creditRoleUserDefinedValue: String
  $instrumentId: ID
  $instrumentUserDefinedValue: String
  $performing: Boolean
  $split: Float
) {
  assignMultipleCredits(
    input: {
      party_id: $partyId
      contribution_ids: $contributionIds
      contribution_type: $contributionType
      feature_type: $featureType
      credit_role_id: $creditRoleId
      credit_role_user_defined_value: $creditRoleUserDefinedValue
      instrument_id: $instrumentId
      instrument_user_defined_value: $instrumentUserDefinedValue
      performing: $performing
      split: $split
    }
  ) {
    ...CreditTableFields
    instrument {
      name
    }
    split
  }
}
  ${fragment.CREDIT_TABLE_FIELDS}
`

export const ASSIGN_MULTIPLE_CREDIT = gql`
  mutation AssignMultipleCredits(
    $partyId: ID!
    $contributionIds: [ID]!
    $contributionType: String!
    $featureType: String
    $creditRoleId: ID!
    $creditRoleUserDefinedValue: String
    $instrumentId: ID
    $instrumentUserDefinedValue: String
    $performing: Boolean
    $split: Float
  ) {
      assignMultipleCredits(
        input: {
          party_id: $partyId
          contribution_ids: $contributionIds
          contribution_type: $contributionType
          feature_type: $featureType
          credit_role_id: $creditRoleId
          credit_role_user_defined_value: $creditRoleUserDefinedValue
          instrument_id: $instrumentId
          instrument_user_defined_value: $instrumentUserDefinedValue
          performing: $performing
          split: $split
        }
    ) {
      ...CreditTableFields
      instrument {
        name
      }
      split
    }
  }
  ${fragment.ASSIGN_MULTIPLE_CREDIT_TABLE_FIELDS}
`

export const UPDATE_CREDIT = gql`
  mutation UpdateCredit(
    $creditId: ID!
    $partyId: ID!
    $contributionId: ID!
    $contributionType: String!
    $creditRoleId: ID!
    $creditRoleUserDefinedValue: String
    $instrumentId: ID
    $instrumentUserDefinedValue: String
    $performing: Boolean
    $split: Float
    $featureType: String
  ) {
    updateCredit(
      input: {
        id: $creditId
        party_id: $partyId
        contribution_id: $contributionId
        contribution_type: $contributionType
        credit_role_id: $creditRoleId
        credit_role_user_defined_value: $creditRoleUserDefinedValue
        instrument_id: $instrumentId
        instrument_user_defined_value: $instrumentUserDefinedValue
        performing: $performing
        split: $split
        feature_type: $featureType
      }
    ) {
      ...CreditTableFields
      instrument {
        name
      }
      split
    }
  }
  ${fragment.CREDIT_TABLE_FIELDS}
`

export const DELETE_CREDIT = gql`
  mutation deleteCredit($creditId: ID!) {
    deleteCredit(input: { id: $creditId }) {
      id
    }
  }
`

export const CREATE_COLLABORATOR = gql`
  mutation CreateCollaborator(
    $projectId: ID!
    $type: String!
    $recordings: [CollaboratorRecordingInput]
    $email: String!
    $name: String!
    $permissions: [CollaboratorPermissionInput]!
  ) {
    createCollaborator(
      input: {
        project_id: $projectId
        type: $type
        recordings: $recordings
        email: $email
        name: $name
        permissions: $permissions
      }
    ) {
      id
      type
      email
      name
      permissions {
        type
        level
      }
    }
  }
`

export const UPDATE_COLLABORATOR = gql`
  mutation UpdateCollaborator(
    $id: ID!
    $type: String!
    $recordings: [CollaboratorRecordingInput]!
  ) {
    updateCollaborator(input: { id: $id, type: $type, recordings: $recordings }) {
      id
      type
      email
      name
      recordings {
        id
      }
    }
  }
`

export const UPDATE_COLLABORATOR_PERMISSIONS = gql`
  mutation UpdateCollaboratorPermissions(
    $collaboratorId: ID!
    $permissions: [CollaboratorPermissionInput]!
  ) {
    updateCollaboratorPermissions(
      input: { collaborator_id: $collaboratorId, permissions: $permissions }
    ) {
      permissions {
        type
        level
      }
    }
  }
`

export const DELETE_COLLABORATOR = gql`
  mutation DeleteCollaborator($collaboratorId: ID!) {
    deleteCollaborator(input: { id: $collaboratorId }) {
      id
    }
  }
`

export const LEAVE_COLLABORATOR = gql`
  mutation LeaveCollaborator($collaboratorId: ID!, $recordingId: ID) {
    leaveCollaborator(
      input: { id: $collaboratorId, recording_id: $recordingId }
    ) {
      id
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $firstName: String!
    $lastName: String!
    $timezoneId: ID
    $email: String!
    $password: String
    $confirmPassword: String
    $currentPassword: String
    $phone: String
    $twoFactorEnabled: Boolean
  ) {
    updateUser(
      input: {
        first_name: $firstName
        last_name: $lastName
        timezone_id: $timezoneId
        email: $email
        password: $password
        password_confirmation: $confirmPassword
        current_password: $currentPassword
        phone: $phone
        two_factor_enabled: $twoFactorEnabled
      }
    ) {
      ...UserAccountFields
      twoFactor {
        accessToken
        twoFactor
      }
    }
  }
  ${fragment.USER_ACCOUNT_FIELDS}
`

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $studioType: String
    $label: String
    $jobRole: String
    $genre: String
    $workload: Int
  ) {
    updateUserProfile(
      input: {
        studio_type: $studioType
        label: $label
        job_role: $jobRole
        genre: $genre
        workload: $workload
      }
    ) {
      studioType
      label
      jobRole
      genre
      workload
    }
  }
`

export const DO_SUBSCRIPTION_UPDATE = gql`
  mutation DoSubscriptionUpdate($plan: String!) {
    doSubscriptionUpdate(input: { plan: $plan }) {
      stripePlan
    }
  }
`

export const DO_SUBSCRIPTION_BILLING_UPDATE = gql`
  mutation DoSubscriptionBillingUpdate($stripeToken: String!) {
    doSubscriptionBillingUpdate(input: { stripe_token: $stripeToken }) {
      cardBrand
      cardLastFour
    }
  }
`

export const DO_RESET_UNREAD_ACTIVITY = gql`
  mutation DoResetUnreadActivity($projectId: ID!) {
    doResetUnreadActivity(input: { project_id: $projectId }) {
      count
    }
  }
`

export const DO_RESEND_VERIFICATION_EMAIL = gql`
  mutation DoResendVerificationEmail($email: String!) {
    doResendVerificationEmail(input: { email: $email }) {
      success
    }
  }
`

export const DO_RESEND_COLLABORATOR_INVITE = gql`
  mutation DoResendCollaboratorInvite($collaboratorId: Int!) {
    doResendCollaboratorInvite(input: { collaboratorId: $collaboratorId }) {
      success
    }
  }
`


export const DELETE_AFFILIATIONS = gql`
mutation DeleteAffiliation($id: ID!){
    deleteAffiliation(input:{id:$id}){
        id
        number
        affiliation {
            name
            type
        }
        status
        createdAt
        updatedAt
    }
}
`

export const CREATE_AFFILIATION = gql`
  mutation CreateAffiliation(
     $affiliationId: ID!,
     $number: String!,
     $status:String){
    createAffiliation(
    input:{
      affiliation_id: $affiliationId,
      number: $number,
      status: $status
      }){
        id
        number
        affiliation {
            name
            type
        }
        status
        createdAt
        updatedAt
    }
}
`

export const CREATE_PUBLISHER = gql`
  mutation AddPublisher($input: AddPublisherInput!) {
    addPublisher (input: $input) {
        ...PublisherFields
    }
  }
  ${fragment.PUBLISHER_FIELDS}
`

export const DELETE_PUBLISHER = gql(`
  mutation RemovePublisher($input: RemovePublisherInput!) {
    removePublisher (input: $input) {
      id
    }
  }
`)

export const VERIFY_EMAIL_CHANGE = gql`
  mutation DoEmailChangeVerification($email: String!, $token: String!) {
    doEmailChangeVerification(input: { email: $email, token: $token }) {
      accessToken
  }
}`

export const CHANGE_SX_STATUS = gql`
mutation ChangeSXStatus{
  changeSXStatus{
    success
  }
}`
export const COMPLETE_PUBLIC_SHARE = gql`
  mutation PublicShare($publicShareId: ID!) {
    publicShare(publicShareId: $publicShareId) {
      success
  }
}
`

export const DOWNLOAD_SHARED_FILES = gql`
  mutation publicShareDownload($publicShareId: ID!) {
    publicShareDownload(publicShareId: $publicShareId) {
      success
      url
    }
  }
`

export const ADD_REGION_COMMENT = gql`
  mutation AddRegionComment($input: AddRegionCommentInput) {
    addRegionComment(input: $input) {
      id
      start
      end
      comment
      color
      data
      createdAt
      user {
        id
        firstName
        lastName
        image
      }
    }
  }
`

export const DELETE_REGION_COMMENT = gql`
  mutation DeleteRegionComment($id: ID!) {
    deleteRegionComment(id: $id) {
      id
      comment
      color
      data
      createdAt
    }
  }
`

export const EDIT_REGION_COMMENT = gql`
  mutation EditRegionComment($input: EditRegionCommentInput) {
    editRegionComment(input: $input) {
      id
      start
      end
      comment
      color
      data
      createdAt
      user {
        id
        firstName
        lastName
        image
      }
    }
  }
`
export const SHARE_LINK = gql`
  mutation shareDirectLinks(
    $files: [ShareFileInput!]!,
    $expiry: String,
    $password: String,
    $message: String
  ) {
    shareDirectLinks(input: {
      files: $files,
      expiry: $expiry,
      password: $password,
      message: $message
    })
    {
      success
      url
      __typename
    }
}
`

export const PERMANENTLY_DELETE_FILES = gql`
  mutation PermanentDeleteFiles($files: [PermanentDeleteFilesInput!]!) {
    permanentDeleteFiles(files: $files) {
      success
    }
  }
`
export const RESTORE_FILES = gql`
  mutation RestoreFiles($files: [RestoreFilesInput!]!) {
    restoreFiles(files: $files) {
      success
    }
  }
`
export const CREATE_UPDATE_USER_SOCIALS = gql`
mutation CreateUserSocials($socials: [CreateUserSocialsInput!]!) {
  createUserSocials(socials: $socials) {
    success
  }
}
`
export const CREATE_PLAYLIST = gql`
mutation createPlaylist(
  $title: String!
  $description: String
  $image: String
) {
  createPlaylist(
    input: {
      title: $title
      description: $description
      image: $image
    }
  ) {
    ...PlaylistFields
  }
}

${fragment.PLAYLIST_FIELDS}

`
export const UPDATE_PLAYLIST = gql`
mutation updatePlaylist(
  $id: ID!
  $title: String!
  $description: String
  $image: String
) {
  updatePlaylist(
    input: {
      id: $id
      title: $title
      description: $description
      image: $image
    }
  ) {
    ...PlaylistFields
  }
}

${fragment.PLAYLIST_FIELDS}

`
export const REMOVE_FROM_PLAYLIST = gql`
mutation DetachFiles(
  $id: ID!
  $file_ids: [ID]
) {
detachFiles(
      id: $id
      fileIds: $file_ids
) {
  success
}
}
`
export const DELETE_PLAYLIST = gql`
mutation DeletePlaylist($input: DeletePlaylistInput!) {
  deletePlaylist(input: $input) {
    success
  }
}
`

export const ATTACH_TO_PLAYLIST = gql`
mutation AttachFiles(
  $id: ID!
  $file_ids: [ID]
) {
attachFiles(
      id: $id
      fileIds: $file_ids
) {
  success
}
}
`

export const UPDATE_SEQ_PLAYLIST = gql`
mutation updateSeqPlaylist($id: ID!, $files: [PlaylistSeqInput]
  ) {
    updateSeqPlaylist(
      input: {
        id: $id
        files: $files
      }
    ) {
      ...PlaylistFields
    }
  }
  ${fragment.PLAYLIST_FIELDS}
`
export const ASSIGN_MULTIPLE_SESSION_RECORDING = gql`
mutation AssignMultipleSessionRecording($sessionIds: [ID]!, $recordingId: ID!) {
  assignMultipleSessionRecording(session_ids: $sessionIds, recording_id: $recordingId) {
    success
  }
}
`

export const DELETE_MULTIPLE_SESSION = gql`
mutation DeleteMultipleSession($sessionIds: [ID]!) {
  deleteMultipleSession(ids: $sessionIds ) {
    id
  }
}
`

export const ASSIGN_MULTIPLE_RECORDING_SESSION = gql`
  mutation AssignMultipleRecordingSession($recordingIds: [ID]!, $sessionId: ID!) {
    assignMultipleRecordingSession(session_id: $sessionId, recording_ids: $recordingIds) {
      success
    }
  }
`


export const REDEEM_FREE_MONTH = gql`
mutation RedeemFreeMonth($referralUserId: ID!, $plan: String!) {
  redeemFreeMonth(referral_user_id: $referralUserId, plan: $plan) {
    success
    message
  }
}
`

import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'
import { DateTime } from 'luxon'

import { DELETE_RECORDING } from '../graphql/mutations'
import WithComments from '../hocs/WithComments'

import ResponsiveTable from './ResponsiveTable'
import WithAlertModal from '../hocs/WithAlertModal'

class RecordingTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.getHeaders = this.getHeaders.bind(this)
    this.getRowActions = this.getRowActions.bind(this)
    this.isFavourite = this.isFavourite.bind(this)
    this.getFavouriteType = this.getFavouriteType.bind(this)
  }

  getHeaders = (() => {
    if (this.props.favouritePage) {
      const favouriteHeaders = [
        {
          key: 'name',
          header: 'Recording'
        },
        {
          key: 'subtitle',
          header: 'Sub Title',
          formatter: (data) => (data ? data : '-')
        },
        {
          key: 'party.name',
          header: 'Artist',
          formatter: (data, row) => (row.party ? row.party.name : '-')
        },
        {
          key: 'recordedOn',
          header: 'Recorded On',
          formatter: data => data ? DateTime.fromSQL(data, { zone: 'utc' }).toISODate() : '-'
        }
      ]

      return () => favouriteHeaders
    }

    if (this.props.projectDashboardPage) {
      const projectPage = [
        {
          key: 'name',
          header: 'Recording Title',
          formatter: (data, row) => `${row.name}${(row.version ? ` - ${row.version}` : '')}`
        },
        {
          key: 'subtitle',
          header: 'Subtitle',
          formatter: (subtitle) => subtitle || '-'
        },
        {
          key: 'party.name',
          header: 'Artist',
          formatter: (data, row) => (row.party ? row.party.name : '-')
        },
        {
          key: 'recordedOn',
          header: 'Recorded On',
          formatter: data => data ? DateTime.fromSQL(data, { zone: 'utc' }).toISODate() : '-'
        }
      ]
      return () => projectPage
    }


    const headers = [
      {
        key: 'name',
        header: 'Recording Title',
        formatter: (data, row) => `${row.name}${(row.version ? ` - ${row.version}` : '')}`
      },
      {
        key: 'subtitle',
        header: 'Subtitle',
        formatter: (subtitle) => subtitle || '-'
      },
      {
        key: 'party.name',
        header: 'Artist',
        formatter: (data, row) => (row.party ? row.party.name : '-')
      },
      {
        key: 'recordedOn',
        header: 'Recorded On',
        formatter: data => DateTime.fromSQL(data, { zone: 'utc' }).toISODate() || '-'
      }
    ]

    return () => headers
  })()
  getActions = (() => {
    let actions = []
    if (this.props.refetch) {
      actions.push({
        name: 'restart',
        iconDescription: 'Refresh',
        onClick: () => this.props.refetch()
      })
    }

    if (actions.length === 0) {
      actions = false
    }

    return () => actions
  })()
  getRowActions = (() => {
    let actions = [
      {
        itemText: 'Comments',
        onClick: row => {
          this.props.loadComments(row.projectId, row.id, 'recording')
        } }
    ]

    actions.push({
      itemText: 'View',
      onClick: row => this.props.history.push(`/projects/${row.projectId}/recordings/${row.id}`)
    })

    actions.push({
      itemText: 'Edit',
      className: this.props.canUpdate ? '' : 'item-ghosted',
      onClick: row =>{
        this.props.history.push(`/projects/${row.projectId}/recordings/${row.id}/edit`)
      }
    })


    actions.push({
      itemText: 'Delete',
      className: this.props.canUpdate ? '' : 'item-ghosted',
      onClick: row => {
        this.props
          .deleteRecording({
            variables: {
              recordingId: row.id,
              confirmed: false
            },
            update: this.props.onDelete
          }).then(() => {})
          .catch(({ graphQLErrors }) => {
            const errorMsg = graphQLErrors[0].message
            this.props.alert(errorMsg,
              {
                type: 'delete',
                buttonText: 'Confirm',
                submitCallback: close => {
                  this.props
                    .deleteRecording({
                      variables: {
                        recordingId: row.id,
                        confirmed: true
                      },
                      update: this.props.onDelete
                    }).then(() => {
                      this.props.onDeleteAlert()
                      if (this.props.favouriteRefetch) {
                        this.props.favouriteRefetch()
                      }
                    })
                    .catch(() => {
                      this.props.alert('This recording could not be deleted.')
                    })
                  close()
                }
              })
          })
      }
    })

    if (this.props.rowActions) {
      actions = [...actions, ...this.props.rowActions]
    }

    return () => actions
  })()

  isFavourite(row) {
    return this.props.favourites.indexOf(parseInt(row.id, 10)) > -1
  }

  getFavouriteType() {
    return 'recording'
  }

  generateLink(row) {
    return `/projects/${row.projectId}/recordings/${row.id}`
  }

  render() {
    return (
      <ResponsiveTable
        headers={this.getHeaders()}
        {...this.props}
        linkColumn='name'
        generateLink={this.generateLink}
        isFavourite={this.isFavourite}
        rowActions={this.props.favouritePage ? false : this.getRowActions()}
        actions={this.props.actions && this.getActions()}
        sortDirection='ASC'
        sortColumn='name'
        getFavouriteType={this.getFavouriteType}
        emptyText='No Recordings'
      />
    )
  }
}

export default compose(
  withRouter,
  WithComments,
  WithAlertModal,
  graphql(DELETE_RECORDING, { name: 'deleteRecording' })
)(RecordingTable)

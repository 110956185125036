/* eslint-disable no-debugger */
import React from 'react'
import { Modal } from 'carbon-components-react'
import Breadcrumb from './Breadcrumb'
import { compose, graphql, Query } from 'react-apollo'
import {
  GET_PROJECT_ROOT_FOLDERS,
  GET_USER_ROOT_FOLDERS,
  GET_PROJECTS,
  GET_PROJECT_FOLDER
} from '../graphql/queries'
import {
  MOVE_FILES,
  DUPLICATE_FILES
} from '../graphql/mutations'
import ResponsiveTable from './ResponsiveTable'
import WithAlertModal from '../hocs/WithAlertModal'
class MoveCopyFileModal extends React.PureComponent {
  constructor(props) {
    super(props)

    this.currentFolderCount = 1

    this.state = {
      folderId: '',
      projectId: '',
      recordingId: '',
      selectedId: '',
      filesFlow: false,
      projectFlow: true,
      inYourFiles: false,
      inProject: false,
      working: false
    }
    this.state.projectId = this.props.isProjectOwner ? '' : this.props.projectId
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    const { selectedId, inYourFiles, filesFlow, projectId, inProject, recordingId } = this.state
    if (!selectedId && !inYourFiles) {
      this.props.alert('Please select a destination for the selected file(s)', { title: ' ', className: 'credits-alert' })
      return
    }
    const query = this.props.action === 'move' ? this.props.moveFiles : this.props.copyFiles

    this.setState({
      ...this.state,
      working: true
    })
    const variables = {}

    if ((projectId === '' || projectId === null) && !filesFlow && !inYourFiles) {
      variables.folder_Id = 0
      variables.project_id = selectedId
      variables.recording_id = recordingId || 0
    } else if (inProject && !filesFlow && !inYourFiles) {
      variables.folder_Id = 0
      variables.project_id = selectedId
      variables.recording_id = 0
    } else if (inYourFiles) {
      variables.folder_Id = null
      variables.project_id = null
      variables.recording_id = null
    } else {
      variables.folder_Id = selectedId
      variables.project_id = 0
      variables.recording_id = 0
    }

    query({
      variables: {
        folder_id: variables.folder_Id,
        project_id: variables.project_id,
        recording_id: variables.recording_id
      }
    })
      .then(() => {
        this.setState({
          ...this.state,
          working: false
        })
        if (this.props.onCancel) {
          this.props.onCancel()
        }
        this.props.onRequestClose()
        if (this.props.refetch) {
          this.props.refetch()
        }
      })
      .catch(e => {
        this.setState({
          ...this.state,
          working: false
        })

        if (this.props.onCancel) {
          this.props.onCancel()
        }
        let errors = []
        e.graphQLErrors.map(({ message }) => {
          errors.push(message)
          return true
        })

        this.props.alert(errors.join(', '))
      })
  }

  onSelect = (id, name, e) =>{
    if (e.target.checked === false) {
      this.setState({
        selectedId: '',
        inYourFiles: false
      })
      return
    }
    if (name === 'Your Files') {
      this.setState({
        inYourFiles: true
      })
      return
    }
    this.setState({
      selectedId: id,
      inProject: false,
      inYourFiles: false
    })
  }

  render() {
    const { projectFlow, filesFlow, projectId, folderId, isSelected, selectedId } = this.state
    if (!this.props.open) {
      return null
    }
    const foldersQuery = folderId ? GET_PROJECT_FOLDER : GET_PROJECTS
    const foldersQueryKey = folderId ? 'getFolder' : 'getProjects'
    let query = (projectId ? GET_PROJECT_ROOT_FOLDERS : foldersQuery)
    if (filesFlow) {
      query = (folderId ? GET_PROJECT_FOLDER : GET_USER_ROOT_FOLDERS)
    }


    let queryKey = (projectId ? 'getProject' : foldersQueryKey)
    if (filesFlow) {
      queryKey = (folderId ? 'getFolder' : 'getUser')
    }

    let baseBreadcrumb = []
    if (!this.props.projectId) {
      baseBreadcrumb.push({
        id: 'root',
        name: ('Files'),
        action: () => {
          this.setState({
            ...this.state,
            projectId: null,
            folderId: null,
            projectFlow: true,
            filesFlow: false
          })
        }
      })
    } else if (this.props.isProjectOwner) {
      baseBreadcrumb.push({
        id: 'root',
        name: ('Files'),
        action: () => {
          this.setState({
            ...this.state,
            projectId: null,
            folderId: null,
            projectFlow: true,
            filesFlow: false
          })
        }
      })
    }

    const genericRow = [
      {
        id: '1num',
        name: 'Your Files',
        filesFlow: true

      }
    ]
    return (
      <div className='move-modal'>
        <Modal
          className='modal-scrollable'
          open={this.props.open}
          modalHeading={(
            this.props.action === 'move' ?
              'Move selected files/folders to' :
              'Copy files/folders into')}
          primaryButtonText={this.props.action === 'move' ? 'Move' : 'Copy'}
          secondaryButtonText='Cancel'
          selectorPrimaryFocus='.bx--text-input'
          onRequestSubmit={this.handleSubmit}
          onRequestClose={this.props.onRequestClose}
        >

          { projectFlow && <Query query={query}
            variables={{
              projectId: projectId,
              folderId: folderId
            }}
            fetchPolicy='cache-and-network'>
            {({ loading, error, data }) => {
              if (loading) {
                return (
                <>
                  <Breadcrumb items={baseBreadcrumb}/>
                  <ResponsiveTable
                    rows={[].fill(null, 0, this.currentFolderCount)}
                    showSearch={false}
                    showSkeleton={true}
                    headers={[
                      {
                        key: 'name',
                        header: 'Name'
                      }
                    ]}
                  />
                </>
                )
              }

              if (error) {
                return (
                <>
                  <Breadcrumb items={baseBreadcrumb}/>
                  <p>Error</p>
                </>
                )
              }

              const root = data[queryKey]
              let projects = []
              if (queryKey === 'getProject') {
                projects = root.folders
              } else if (queryKey === 'getFolder') {
                projects = root.folders
              } else {
                projects = [...genericRow, ...root]
              }
              this.props.files.forEach(item => {
                const index = projects.findIndex(file => file.id.toString() === item.id.toString())
                if (index >= 0) {
                  projects.splice(index, 1)
                }
              })


              let breadcrumb = [...baseBreadcrumb]


              if (projectId) {
                breadcrumb.push({
                  id: projectId,
                  name: root.name,
                  action: () => {
                    this.setState({
                      ...this.state,
                      projectId: projectId,
                      selectedId: projectId,
                      inProject: true
                    })
                  }
                })
              }
              if (folderId) {
                breadcrumb.push({
                  id: root.project.id,
                  name: root.project.name,
                  action: () => {
                    this.setState({
                      ...this.state,
                      projectId: root.project.id,
                      selectedId: root.project.id,
                      inProject: true,
                      folderId: null
                    })
                  }
                },
                {
                  id: folderId,
                  name: root.name,
                  action: () => {
                    this.setState({
                      ...this.state,
                      folderId: folderId,
                      inProject: false,
                      projectId: null
                    })
                  }
                })
              }

              this.currentFolderCount = Math.min(projects.length, 1)
              return (
              <>
                <Breadcrumb items={breadcrumb}/>
                <ResponsiveTable
                  bodyType={this.props.action === 'move' ? 'move' : 'copy'}
                  projectId={this.props.projectId}
                  folderId={folderId}
                  onSelect = {this.onSelect}
                  isSelected={isSelected}
                  selectedId={selectedId}
                  rows={projects}
                  showSearch={false}
                  headers={[
                    {
                      key: 'name',
                      header: 'Name'
                    }
                  ]}
                  linkColumn='name'
                  emptyText='Empty folder'
                  generateLink={row => {
                    return () => {
                      if (row.__typename === 'Project') {
                        this.setState({
                          ...this.state,
                          projectId: row.id,
                          inYourFiles: false,
                          inProject: true,
                          selectedId: row.id
                        })
                      }
                      if (row.__typename === 'Folder') {
                        this.setState({
                          ...this.state,
                          folderId: row.id,
                          selectedId: row.id,
                          inYourFiles: false,
                          inProject: false,
                          projectId: null,
                          recordingId: row.recording ? row.recording.id : undefined
                        })
                      }
                      if (row.filesFlow) {
                        this.setState({
                          ...this.state,
                          projectFlow: false,
                          filesFlow: true,
                          inProject: false,
                          inYourFiles: true
                        })
                      }
                    }
                  }}
                />
              </>
              )
            }}
          </Query>}
          { filesFlow && <Query query={query}
            variables={{
              projectId: projectId || projectId,
              folderId: folderId
            }}
            fetchPolicy='cache-and-network'>
            {({ loading, error, data }) => {
              if (loading) {
                return (
                <>
                  <Breadcrumb items={baseBreadcrumb}/>
                  <ResponsiveTable
                    rows={[].fill(null, 0, this.currentFolderCount)}
                    showSearch={false}
                    showSkeleton={true}
                    headers={[
                      {
                        key: 'name',
                        header: 'Name'
                      }
                    ]}
                  />
                </>
                )
              }

              if (error) {
                return (
                <>
                  <Breadcrumb items={baseBreadcrumb}/>
                  <p>Error</p>
                </>
                )
              }

              const root = data[queryKey]
              let projects = root.folders
              let breadcrumb = [...baseBreadcrumb]

              if (filesFlow) {
                breadcrumb.push({
                  id: Math.floor(Math.random() * 10),
                  name: 'Your Files',
                  action: () => {
                    this.setState({
                      ...this.state,
                      filesFlow: true,
                      inProject: false,
                      folderId: null
                    })
                  }
                })
              }


              if (folderId) {
                breadcrumb.push({
                  id: folderId,
                  name: root.name,
                  action: () => {
                    this.setState({
                      ...this.state,
                      folderId: folderId,
                      inProject: false
                    })
                  }
                })
              }

              this.currentFolderCount = Math.min(projects.length, 1)
              return (
              <>
                <Breadcrumb items={breadcrumb}/>
                <ResponsiveTable
                  bodyType={this.props.action === 'move' ? 'move' : 'copy'}
                  projectId={this.props.projectId}
                  folderId={this.state.folderId}
                  onSelect = {this.onSelect}
                  isSelected={isSelected}
                  selectedId={selectedId}
                  rows={projects}
                  showSearch={false}
                  headers={[
                    {
                      key: 'name',
                      header: 'Name'
                    }
                  ]}
                  linkColumn='name'
                  emptyText='Empty folder'
                  generateLink={row => {
                    return () => {
                      if (row.__typename === 'Project') {
                        this.setState({
                          ...this.state,
                          projectId: row.id,
                          inProject: true,
                          selectedId: row.id
                        })
                      }
                      if (row.__typename === 'Folder') {
                        this.setState({
                          ...this.state,
                          folderId: row.id,
                          inYourFiles: false,
                          inProject: false,
                          selectedId: row.id
                        })
                      }
                    }
                  }}
                />
              </>
              )
            }}
          </Query>}
        </Modal>
      </div>
    )
  }r
}

export default compose(
  graphql(MOVE_FILES, { name: 'moveFiles' }),
  graphql(DUPLICATE_FILES, { name: 'copyFiles' }),
  WithAlertModal
)(MoveCopyFileModal)


import React from 'react'
import { Modal } from 'carbon-components-react'
import ApolloClient from '../utils/Apollo'
import { GENERATE_SESSION_CODE } from '../graphql/queries'

const CheckInModalBody = ({ loading, code }) => {
  if (!loading && code) {
    return (
      <div>
        <p
          style={{
            fontSize: '50px',
            letterSpacing: '8px',
            marginBottom: '20px'
          }}
        >
          {code}
        </p>
        <small style={{
          color: '#fff',
          fontSize: '12px',
          opacity: '0.7',
          letterSpacing: '0.2px'
        }}>
          This code will expire in 7 days. Re-opening this window will generate a new token. Any
          previous tokens will remain valid until they expire.
        </small>
      </div>
    )
  }

  return (
    <div
      className='loading'
      style={{
        margin: '50px auto'
      }}
    />
  )
}

const WithCheckInCodeModal = WrappedComponent => {
  return class extends React.PureComponent {
    state = {
      sessionId: false,
      open: false,
      loading: true
    }

    constructor(props) {
      super(props)

      this.close = this.close.bind(this)
      this.open = this.open.bind(this)
    }

    componentDidUpdate() {
      if (this.state.sessionId && this.state.loading) {
        this.generateSessionCode(this.state.sessionId).then(sessionCode => {
          this.setState({
            loading: false,
            code: sessionCode.code
          })
        })
      }
    }

    close() {
      this.setState({
        ...this.state,
        sessionId: false,
        open: false,
        loading: false
      })
    }

    open(sessionId) {
      this.setState({
        ...this.state,
        sessionId: sessionId,
        open: true,
        loading: true
      })
    }

    generateSessionCode(sessionId) {
      return new Promise(resolve => {
        ApolloClient.query({
          query: GENERATE_SESSION_CODE,
          fetchPolicy: 'network-only',
          variables: {
            sessionId: parseInt(sessionId, 0)
          }
        }).then(({ data }) => {
          const { sessionCode } = data.generateSessionCode
          resolve(sessionCode)
        })
      })
    }

    render() {
      return (
        <>
          <WrappedComponent {...this.props} checkInCodeModal={this.open} />

          <Modal
            className='modal modal--check-in'
            open={this.state.open}
            modalHeading='Check-In Code'
            passiveModal={false}
            onRequestSubmit={this.close}
            onRequestClose={this.close}
            primaryButtonText='Close'
          >
            {this.state.open && (
              <CheckInModalBody loading={this.state.loading} code={this.state.code} />
            )}
          </Modal>
        </>
      )
    }
  }
}

export default WithCheckInCodeModal

import React from 'react'
import { Loading } from 'carbon-components-react'
import { compose, graphql } from 'react-apollo'

import ApolloClient from '../utils/Apollo'
import { PLAN_NAMES, PLAN_CHANGES, PLAN_PRICES } from '../utils/constants'

import { DO_SUBSCRIPTION_UPDATE, DO_SUBSCRIPTION_BILLING_UPDATE } from '../graphql/mutations'
import { GET_USER } from '../graphql/queries'

import CardDetailsForm from './CardDetailsForm'
import WithAlertModal from '../hocs/WithAlertModal'
import DiscardModal from './modals/DiscardModal'

class UpdateSubscription extends React.PureComponent {
  state = {
    loading: false,
    changingPlan: false,
    plan: '',
    showDiscardModal: false,
    cardRef: null,
    creditCardValue: false
  }

  constructor(props) {
    super(props)

    this.changePlan = this.changePlan.bind(this)
    this.refreshUser = this.refreshUser.bind(this)
    this.openDiscardModal = this.openDiscardModal.bind(this)
    this.closeDiscardModal = this.closeDiscardModal.bind(this)
    this.discardChanges = this.discardChanges.bind(this)

    this.state.plan = props.stripePlan
  }

  changePlan(plan) {
    const upgrade = PLAN_CHANGES[this.props.stripePlan][plan]

    if (!this.props.hasSavedCard) {
      this.setState({
        ...this.state,
        plan
      })
      return
    }

    this.props.alert('Are you sure you want to ' + (upgrade ? 'upgrade' : 'downgrade') + ' your account to ' + PLAN_NAMES[plan] + '?', {
      title: (upgrade ? 'Upgrade' : 'Downgrade') + ' your account',
      type: 'confirm',
      submitCallback: close => {
        if (this.state.changingPlan) {
          return
        }

        this.setState({
          ...this.state,
          changingPlan: true
        })

        this.props.doSubscriptionUpdate(plan).then(() => {
          this.refreshUser()

          this.setState({
            ...this.state,
            changingPlan: false
          })

          close()
        }).then(() => {
          this.props.alert(`Your account has been ${upgrade ? 'upgraded' : 'downgraded'}.`)
        }).catch(() => {
          this.props.alert('There was a problem updating your subscription.', {
            title: 'Problem updating subscription'
          })

          this.setState({
            loading: false
          })
        })
      }
    })
  }

  refreshUser() {
    ApolloClient.query({
      query: GET_USER,
      fetchPolicy: 'network-only'
    })
  }

  closeDiscardModal() {
    this.setState({ showDiscardModal: false })
  }
  cardChange = (e) =>{
    const brand = e.brand
    if (brand && brand !== 'unknown') {
      this.setState({ creditCardValue: true })
    } else {
      this.setState({ creditCardValue: false })
    }
  }
  openDiscardModal(ref) {
    if (this.state.plan !== this.props.stripePlan || this.state.creditCardValue) {
      this.setState({ showDiscardModal: true })
      this.setState({ cardRef: ref })
    }
  }

  discardChanges() {
    const node = this.state.cardRef.current
    // eslint-disable-next-line no-underscore-dangle
    node._element.clear()
    this.props.doSubscriptionUpdate(this.state.plan).then(() => {
      this.refreshUser()
      this.setState({
        ...this.state,
        changingPlan: false
      })
    }).then(() => {
      this.closeDiscardModal()
    }).catch(() => {
      this.props.alert('There was a problem updating your subscription.', {
        title: 'Problem updating subscription'
      })
    })
  }


  render() {
    return (
      <>
        {this.props.hasSavedCard &&
          <div className='subscription'>
            <p>Change your plan</p>
            <>
              {this.state.changingPlan &&
                <Loading withOverlay={false} />
              }

              {!this.state.changingPlan &&
                <div className='subscriptionOptions'>
                  <button disabled={this.state.changingPlan}
                    onClick={() => this.changePlan('free')} className={'planOption ' + (this.props.stripePlan === 'free' ? 'planOption--current' : '')}>
                    {PLAN_NAMES.free}&nbsp;{PLAN_PRICES.free}
                  </button>
                  <button disabled={this.state.changingPlan}
                    onClick={() => this.changePlan('individual')} className={'planOption ' + (this.props.stripePlan === 'individual' ? 'planOption--current' : '')}>
                    {PLAN_NAMES.individual && 'Lite'}&nbsp;{PLAN_PRICES.individual}
                  </button>
                  <button disabled={this.state.changingPlan}
                    onClick={() => this.changePlan('pro')} className={'planOption ' + (this.props.stripePlan === 'pro' ? 'planOption--current' : '') }>
                    {PLAN_NAMES.pro}&nbsp;{PLAN_PRICES.pro}
                  </button>
                  <button disabled={this.state.changingPlan}
                    onClick={() => this.changePlan('prounlimited')} className={'planOption lastOption ' + (this.props.stripePlan === 'prounlimited' ? 'planOption--current' : '') }>
                    {PLAN_NAMES.prounlimited} {PLAN_PRICES.prounlimited}
                  </button>
                </div>
              }
            </>
          </div>
        }

        {!this.props.hasSavedCard &&
          <div className='subscription'>
            <p>Choose your plan</p>
            <div className='subscriptionOptions'>
              <button onClick={() => this.changePlan('individual')} className={'planOption ' + (this.state.plan === 'individual' ? 'planOption--current' : '')}>
                {PLAN_NAMES.individual && 'Lite'}&nbsp;{PLAN_PRICES.individual}
              </button>
              <button onClick={() => this.changePlan('pro')} className={'planOption ' + (this.state.plan === 'pro' ? 'planOption--current' : '') }>
                {PLAN_NAMES.pro}&nbsp;{PLAN_PRICES.pro}
              </button>
              <button onClick={() => this.changePlan('prounlimited')} className={'planOption ' + (this.state.plan === 'prounlimited' ? 'planOption--current' : '') }>
                {PLAN_NAMES.prounlimited} {PLAN_PRICES.prounlimited}
              </button>
            </div>
          </div>
        }

        <div className='subscriptionCard'>
          {this.props.hasSavedCard &&
            <div className='subscriptionCardEnding'>
              <div className='updateLabel'>Credit Card</div>
              <div className='cardEnding'>Current card ending <span>{this.props.cardLastFour}</span></div>
            </div>
          }
          <CardDetailsForm
            loading={this.state.loading}
            stripePlan={this.props.stripePlan}
            hasSavedCard={this.props.hasSavedCard}
            onCancel={this.openDiscardModal}
            cardChange={this.cardChange}
            onSubmit={(token) => {
              this.setState({
                loading: true
              })

              if (this.props.hasSavedCard) {
                return this.props.doSubscriptionBillingUpdate(token.id)
                  .then(() => {
                    this.refreshUser()
                  }).catch(() => {
                    this.props.alert('There was a problem updating your card. Please check your card details and try again.', {
                      title: 'Problem updating card'
                    })
                  }).finally(() => {
                    this.setState({
                      loading: false
                    })
                  })
              }

              this.props.doSubscriptionBillingUpdate(token.id)
                .then(() => {
                  this.props.doSubscriptionUpdate(this.state.plan).then(() => {
                    this.refreshUser()
                  }).catch(() => {
                    this.props.alert('There was a problem updating your card. Please check your card details and try again.', {
                      title: 'Problem updating card'
                    })
                  }).finally(() => {
                    this.setState({
                      loading: false
                    })
                  })
                }).catch(() => {
                  this.props.alert('There was a problem updating your card. Please check your card details and try again.', {
                    title: 'Problem updating card'
                  })

                  this.setState({
                    loading: false
                  })
                })
            }} />
        </div>

      <DiscardModal open={this.state.showDiscardModal}
        onRequestClose={this.closeDiscardModal}
        modalHeading='Discard Changes?'
        handleSubmit={this.discardChanges}
      />
      </>
    )
  }
}

export default compose(
  WithAlertModal,
  graphql(DO_SUBSCRIPTION_BILLING_UPDATE, {
    name: 'doSubscriptionBillingUpdate',
    props: (data) => {
      return {
        doSubscriptionBillingUpdate: stripeToken =>
          data.doSubscriptionBillingUpdate({
            variables: {
              stripeToken
            }
          })
      }
    }
  }),
  graphql(DO_SUBSCRIPTION_UPDATE, {
    name: 'doSubscriptionUpdate',
    props: (data) => {
      return {
        doSubscriptionUpdate: plan =>
          data.doSubscriptionUpdate({
            variables: {
              plan
            }
          })
      }
    }
  })
)(UpdateSubscription)

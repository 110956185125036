import React from 'react'
import AttachToPlaylistModal from '../components/AttachToPlaylistModal'

const WithAttachToPlaylistModal = WrappedComponent => {
  return class extends React.PureComponent {
    state = {
      open: false,
      files: [],
      projectId: null,
      folderId: null,
      onCancel: null,
      alertMsg: null
    }

    constructor(props) {
      super(props)

      this.state.projectId = props.projectId
      this.state.folderId = props.folderId

      this.doOpen = this.doOpen.bind(this)
      this.doClose = this.doClose.bind(this)
    }

    doOpen(files, onCancel, alertMsg) {
      this.setState({
        ...this.state,
        open: true,
        files,
        onCancel,
        alertMsg
      })
    }

    doClose() {
      this.setState({
        ...this.state,
        open: false,
        files: []
      })
      if (this.state.onCancel) {
        this.state.onCancel()
      }
    }

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            attachToPlaylistModal={{
              open: this.doOpen,
              close: this.doClose
            }}
          />
          {this.state.open &&
          <AttachToPlaylistModal
            refetch={this.props.refetch}
            files={this.state.files}
            open={this.state.open}
            onCancel={this.state.onCancel}
            onRequestClose={this.doClose}
            alertMsg= {this.state.alertMsg}
          />
          }
        </>
      )
    }
  }
}

export default WithAttachToPlaylistModal

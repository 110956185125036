import React from 'react'

export default function LoadingDots(props) {
  return (
    <span {...props} className='loading-dots'>
      <span>•</span>
      <span>•</span>
      <span>•</span>
    </span>
  )
}

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { UPDATE_CREDIT } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import { GetCreditRefreshed } from '../../hocs/queries/GetCredit'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import CreditForm from '../../components/CreditForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class ProjectCreditEdit extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Create Credit | {this.props.project.name}</title>
        </Helmet>
        <main className='main edit-credit-project'>
          <CreatePagesHeader
            title={`Assign Project Credit to ${this.props.project.name}`}
            subtitle='Edit Project Credit'
          >

            <Breadcrumb items={[
              {
                href: `/projects`,
                name: 'Projects'
              },
              {
                href: `/projects/${this.props.project.id}`,
                name: 'Projects'
              },
              {
                href: `/projects/${this.props.project.id}/credits`,
                name: `${this.props.project.name}`
              },
              {
                href: `/projects/${this.props.project.id}/credits/${this.props.credit.id}/edit`,
                name: 'Edit Project Credit'
              }
            ]} />
          </CreatePagesHeader>

          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={UPDATE_CREDIT}
                update={() => {
                  this.props.history.push(`/projects/${this.props.project.id}/credits`)
                }}>
                {(updateCredit, { loading, error }) => (
                  <CreditForm
                    {...this.props.credit}
                    projectId={this.props.project.id}
                    contributionType='project'
                    contribution={{
                      id: this.props.project.id,
                      name: this.props.project.name
                    }}
                    onSubmit={(state) => {
                      let data = { ...state }
                      Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                      updateCredit({
                        variables: {
                          ...data,
                          creditId: this.props.credit.id,
                          performing: data.performing ? true : false
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    discardForm={true}
                    errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetCreditRefreshed
)(ProjectCreditEdit)

import { Field, Schema, When } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    name: Field()
      .string()
      .required({ message: Errors.Required }),
    type: Field()
      .string()
      .required({ message: Errors.Required }),
    value: Field()
      .string()
      .email({
        constraint: When(
          '#type',
          Field()
            .string()
            .equals('email')
        ),
        message: Errors.Email
      })
      .required({ message: Errors.Required })
  },
  { verbose: true, async: true }
)

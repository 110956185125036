/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router'

import SectionContainer from '../../components/SectionContainer'
import FileUploader from '../../components/FileUploader'
import uploadImage from '../../assets/images/uploadImage.png'
import WithAlertModal from '../../hocs/WithAlertModal'
import SocialInput from '../../components/SocialInput'
// import SocialInputValidator from '../../validators/SocialInputValidator'
import { Button, Toggle, Loading } from 'carbon-components-react'
import { GET_USER_SOCIALS_LIST } from '../../graphql/queries'
import ApolloClient from '../../utils/Apollo'
import { CREATE_UPDATE_USER_SOCIALS } from '../../graphql/mutations'
import GetUserCustomDownloadPage from '../../hocs/queries/GetCustomDownloadPage'
import trashIcon from '../../assets/images/trash-white.svg'
import uploadIcon from '../../assets/images/upload.svg'
import UploadFileIcon from '../../components/UploadFileIcon'
import GetUser from '../../hocs/queries/GetUser'
import { PLAN_EDUCATION, PLAN_PRO, PLAN_PRO_UNLIMITED, MIN_WIDTH_CUSTOM_DOWNLOAD_PAGE_IMG }
  from '../../utils/constants'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import DiscardModal from '../../components/modals/DiscardModal'
// import default image
import defaultDownloadImage from '../../assets/images/veva-collect-default-download-image.jpg'

const placeholders = {
  Instagram: 'username',
  TikTok: 'username',
  YouTube: 'username',
  SoundCloud: 'username',
  Spotify: 'username',
  AppleMusic: 'artist-name/ID-Number',
  Tidal: 'ID-Number',
  Venmo: 'username',
  Twitter: 'username'
}

// const isAlphaNumWithHypenAndUnderscore = (val) => {
//   if (typeof val !== 'string') return false;
//   // This regex will allow A-Za-z, underscore, and hypen
//   return /^[a-z\-_0-9]*$/i.test(val);
// }

// const isValidAppleMusicUsername = (val) => {
//   if (typeof val !== 'string') return false;
//   return /^[a-z\-_0-9]*(?:\/[a-z\-_0-9]*)?$/igm.test(val)
// }

const Customization = ({ customImages, refetchCustomImages, user, ...props }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState('')
  const [errors /* setErrors */] = useState('')
  const [link, setLink] = useState({})
  const [toggle, setToggle] = useState({})
  const [socials, setSocials] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [imgError, setImgError] = useState('')
  const [discardModal, setDiscardModal] = useState(false)
  // const Validator = SocialInputValidator
  const setInitialData = (userSocials)=>{
    let linksObj = { ...link }
    let toggleObj = { ...toggle }
    userSocials.forEach(({ social, socialUrl, isOn }) => {
      linksObj[social.name] = socialUrl
      toggleObj[social.name] = isOn === '0' ? false : true
    })

    setLink(linksObj)
    setToggle(toggleObj)
  }
  const getSocials = async () =>{
    const { data } = await ApolloClient.query({
      query: GET_USER_SOCIALS_LIST,
      fetchPolicy: 'network-only'
    })
    const { getUserSocials } = data
    setInitialData(getUserSocials)

    setSocials(getUserSocials)
  }
  useEffect(() => {
    getSocials()
  }, [])
  const createUpdateSocials = async ()=>{
    setLoading(true)
    const socialsToUpdate = []
    // 1- socials that have username can have state either ON/OFF
    // 2- socials that don't have username must be OFF
    socials.forEach(({ social })=>{
      const hasUsername = (link[social.name] || '').trim().length > 0
      const is_on = hasUsername ? toggle[social.name] : false
      socialsToUpdate.push({
        social_id: parseInt(social.id, 10),
        social_url: link[social.name],
        is_on: is_on // (link[social.name] || '').trim().length > 0 // toggle[social.name]
      })
    })
    try {
      await ApolloClient.mutate({
        mutation: CREATE_UPDATE_USER_SOCIALS,
        variables: {
          socials: socialsToUpdate
        }
      })
      getSocials()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error.message)
    }
  }
  const handleChange = (e)=>{
    const linksObj = { ...link }
    linksObj[e.target.name] = e.target.value
    setLink(linksObj)
  }
  // const handleDirty = async (e) => {
  //   const { name, value } = e.target

  //   let isValid
  //   try {
  //     isValid = Validator.link.validate(value, {
  //       verbose: true,
  //       values: { ...link }
  //     })
  //   } catch {
  //     isValid = 'Invalid'
  //   }

  //   if (isValid !== true) {
  //     setErrors({
  //       ...errors,
  //       [name]: isValid
  //     }
  //     )
  //   } else {
  //     setErrors({
  //       ...errors,
  //       [name]: undefined // eslint-disable-line no-undefined
  //     })
  //   }
  // }

  const isEmpty = (val) => {
    if (val === null) {
      return true
    }
    if (typeof val === 'undefined') {
      return true
    }
    if (typeof val === 'string') {
      return val.trim().length < 1
    }
    return false
  }
  const handleBlur = (e) => {
    const { name, value } = e.target
    setLink(ps => ({ ...ps, [name]: value.trim() }))
    const hasUsername = !isEmpty(link[name])
    if (!hasUsername) {
      setToggle(ps => ({ ...ps, [name]: false }))
    }
  }

  const handleImgDimension = (file) => {
    return new Promise((resolve) => {
      const img = document.createElement('img')
      img.style.position = 'absolute'
      img.style.top = '-1000000px'
      document.body.appendChild(img)
      img.src = URL.createObjectURL(file)
      img.onload = function (ev) {
        const imgWidth = ev.target.offsetWidth
        const minAllowedWidth = Number(MIN_WIDTH_CUSTOM_DOWNLOAD_PAGE_IMG.split('px')[0])
        if (minAllowedWidth > imgWidth) {
          return resolve(false)
        }
        document.body.removeChild(img)
        // If the height and width are fine
        resolve(true)
      }
      img.onerror = function () {
        resolve(false)
      }
    })
  }

  const handleThumbnailUpload = async (e) => {
    setImgError('')
    if (e.target.files.length < 1) {
      return
    }
    if (isUploading) {
      return
    }
    setIsUploading(true)

    const file = e.target.files[0]
    const canUpload = await handleImgDimension(file)
    if (!canUpload) {
      setImgError('Image width must be greater than equal to 1200px')
      setIsUploading(false)
      return
    }

    const formData = new FormData()
    formData.append('file', file, file.name)

    fetch(`${process.env.REACT_APP_API}/api/custom-download-page-image`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('authToken')
      },
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        setTimeout(() => {
          setIsUploading(false)
        }, 2000)
        if (response.success) {
          refetchCustomImages()
          setImage(file.name)
        }
      })
  }

  const handleRemoveImage = () => {
    if (deleteLoading) {
      return
    }
    setDeleteLoading(true)
    setImgError('')
    fetch(`${process.env.REACT_APP_API}/api/custom-download-page-image`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('authToken')
      },
      body: JSON.stringify({ file: null })
    })
      .then(response => response.json())
      .then(response => {
        setDeleteLoading(false)
        if (response.success) {
          refetchCustomImages()
          setImage('')
        }
      })
  }

  const onToggle = async (e, name)=>{
    const hasUsername = !isEmpty(link[name])
    if (!hasUsername) {
      return
    }

    const toggleObj = { ...toggle }
    toggleObj[name] = e.target.checked
    setToggle(toggleObj)
  }
  const hasDownloadPageImage = Boolean(customImages && customImages.image) || defaultDownloadImage
  const customImage = Boolean(customImages && customImages.image)
  let isProUser = false
  if (user && Array.isArray(user.subscriptions) && user.subscriptions.length > 0) {
    const [sub] = user.subscriptions
    const stripePlan = sub ? sub.stripePlan : undefined
    isProUser =
      stripePlan === PLAN_PRO ||
      stripePlan === PLAN_PRO_UNLIMITED ||
      stripePlan === PLAN_EDUCATION
  }

  if (!isProUser) {
    return null
  }

  const openDiscardModal = () => {
    const toggleObj = {}
    socials.forEach(({ social, isOn }) => {
      toggleObj[social.name] = isOn === '0' ? false : true
    })
    if (JSON.stringify(toggleObj) !== JSON.stringify(toggle)) {
      setDiscardModal(true)
    } else {
      props.history.goBack()
    }
  }

  const closeDiscardModal = () => {
    setDiscardModal(false)
  }

  const discardChanges = () => {
    setDiscardModal(false)
    setInitialData(socials)
  }

  return (
        <>

            <div className='mainContainer customization-page'>
              <SectionContainer
                btnText={!hasDownloadPageImage ? 'Preview' : undefined}
                btnOnClick={!hasDownloadPageImage ? () =>{
                  props.history.push('/download-preview')
                } : undefined}
                additionally='customizationButton'query
                headerWrapper='customization'
                withoutBtnClasses
              >
                <div className='custom-download-page-image'>
                  <div className='cdpi-header-container'>
                    <h3>Custom Download Page Image</h3>
                  </div>
                  <div className='uploadCustomization'>
                    {!hasDownloadPageImage && (
                  <>
                <div className='uploadSection'>
                  <img className='customizationUploadImage' alt='customization' src={uploadImage}/>
                  <div className='uploadContainer'>
                    <p className='uploadText'>Upload</p>
                    <p className='uploadFormats'>Images should be in .jpg, .png formats.</p>
                    {image ? <p className='uploadFormats'>{image}</p> : null}
                    <p className='img-label img-dimension-error'>{imgError}</p>
                  </div>
                </div>
                <FileUploader
                  className='customization-upload'
                  disabled={isUploading}
                  buttonLabel={isUploading ? 'Uploading...' : 'Upload'}
                  filenameStatus={isUploading ? 'uploading' : 'complete'}
                  accept={['.jpg', '.png']}
                  name='image'
                  value={image}
                  showFiles={false}
                  onClick={e => {
                    if (isUploading) {
                      return
                    }

                    if (!e.target.classList.contains('bx--visually-hidden')) {
                      return
                    }
                  }}
                  onChange={handleThumbnailUpload}
                />
                </>
                    )}
                    {hasDownloadPageImage && (
                      <div className='custom-image-container'>
                        <div className='custom-image-and-actions-container'>
                          <img src={customImages.image || defaultDownloadImage} alt='' className='custom-download-pg-image' />
                          <div className='dpi-actions'>
                            <div className='dpi-remove-upload-actions'>
                              {customImage && (
                                  <>
                              <div className='dpi-action-container'>
                                {deleteLoading ? (
                                  <Loading withOverlay={false} small className='action-btn-loading' />
                                ) : (
                                  <img
                                    src={trashIcon}
                                    alt=''
                                    role='button'
                                    className='dpi-action'
                                    onClick={handleRemoveImage}
                                    title='Remove'
                                  />
                                )}
                              </div>
                                </>
                              )}
                              <div className='dpi-action-container'>
                                {isUploading ? (
                                  <Loading withOverlay={false} small className='action-btn-loading' />
                                ) : (
                                  <UploadFileIcon
                                    icon={uploadIcon}
                                    className='dpi-action'
                                    onClick={handleThumbnailUpload}
                                  />
                                )}
                              </div>
                            </div>
                            <div>
                              <h4>Images should be in .jpg, .png formats.</h4>
                              <Button type='button' onClick={() => {
                                props.history.push('/download-preview')
                              }}>
                          Preview
                              </Button>
                            </div>
                          </div>
                        </div>
                        <p className='img-label img-dimension-error'>{imgError}</p>
                      </div>
                    )}
                  </div>
                </div>
              </SectionContainer>
            </div>
            <div className='social-follow'>
              <CreatePagesHeader
                subtitle='Social Follow'
              />
              <div className='mainContainer'>
                <section className='section'>
                  <div className='social-follow-wrapper'>

                    {socials.length !== 0 ? <>
                {socials.map(({ id, social })=>{
                  return (

                    <div key={id} className='social-follow-container'>
                      <div key={id} className='toggle-wrapper'>
                        {toggle[social.name]}
                        <Toggle
                          aria-label='toggle button'
                          id={id}
                          checked={toggle[social.name]}
                          // defaultToggled={toggle[social.name]}
                          toggled={toggle[social.name]}
                          onChange={(e) => onToggle(e, social.name)}
                          // hideLabel={true}
                          labelText=''
                        />
                      </div>
                      {social.name === 'TikTok' || social.name === 'Tidal' ?
                        <div className='socila-img-wrapper'>
                          <img className='social-img-secondary'
                            src={require(`../../assets/images/${social.name}.svg`)} alt='social' />
                        </div> : <img className='social-img'
                          src={require(`../../assets/images/${social.name}.svg`)} alt='social' />}

                      <SocialInput
                        id={id}
                        name={social.name}
                        value={link[social.name]}
                        preUrl={social.preUrl}
                        onChange={handleChange}
                        // onBlur={handleDirty}
                        onBlur={handleBlur}
                        errors={errors}
                        placeholder={placeholders[social.name]}


                      />
                    </div>
                  )
                })}
                <div className='buttons-wrapper'>
                  <Button onClick={openDiscardModal}>Cancel</Button>
                  <Button onClick={createUpdateSocials}>{loading ? 'Saving...' : 'Save' }</Button>
                </div>
                </> : null}
                  </div>
                </section>
              </div>
            </div>

          <DiscardModal open={discardModal}
            onRequestClose={closeDiscardModal}
            modalHeading='Discard Changes?'
            handleSubmit={discardChanges}
          />
      </>
  )
}

export default compose(
  withRouter,
  WithAlertModal,
  GetUserCustomDownloadPage,
  GetUser
)(Customization)

import React from 'react'
import ResponsiveTable from './ResponsiveTable'
import { withRouter } from 'react-router'
import { compose } from 'react-apollo'

const headers = [
  {
    key: 'invoiceNumber',
    header: 'Invoice Number',
    formatter: data => (data ? data : '-')
  },
  {
    key: 'created',
    header: 'Created',
    formatter: data => (data ? data : '-')
  },
  {
    key: 'currency',
    header: 'Currency',
    formatter: data => (data ? data.toUpperCase() : '-')
  },
  {
    key: 'amount',
    header: 'Amount',
    formatter: data => (data ? data : '-')
  }
]

class BillingInformationTable extends React.PureComponent {
  state = {
    originalRows: [],
    rows: []
  };

  constructor(props) {
    super(props)

    this.getRowActions = this.getRowActions.bind(this)
  }

  //
  getExpandedHeaders = () => [
    {
      header: 'Description',
      key: 'discountCode'
    }
  ];

  renderTier = (tier) => {
    switch (tier) {
    case 'pro':
      return 'Pro'

    case 'prounlimited':
      return 'Pro Unlimited'

    case 'individual':
      return 'Lite'

    default:
      return '-'
    }
  }

  renderDescription = row => {
    return (
      <div>
        <p>Tier: {this.renderTier(row.tier)},</p>
        <p>Discount Code: {row.discountCode}</p>
      </div>
    )
  };

  //
  getExpandedContent = row => {
    return [
      {
        title: 'Description',
        content: this.renderDescription(row)
      }
    ]
  };

  //
  getRowActions = (() => {
    let actions = []
    actions.push({
      itemText: 'Export to PDF',
      name: 'invoicePdf',
      iconDescription: 'invoicePdf',
      onClick: row => window.open(row.invoicePdf, '_blank', 'noreferrer')
    })

    if (actions.length === 0) {
      actions = false
    }
    return () => actions
  })();


  render() {
    let transformRows = this.props.rows.map((row, index) => {
      return { id: index, ...row }
    })
    return (
      <ResponsiveTable
        {...this.props}
        rows={transformRows}
        headers={headers}
        expandable={true}
        showSkeleton={this.props.showSkeleton}
        showSearch={false}
        pagination={false}
        rowActions={this.getRowActions()}
        getExpandedContent={this.getExpandedContent}
      />
    )
  }
}

export default compose(withRouter)(BillingInformationTable)

import { Button, Form, Loading } from "carbon-components-react";
import React from "react";
import { DateTime } from "luxon";
import WaveForms from "./WaveForm";
// import CommentsIcon from '../assets/images/comments-Icon.svg'
import editIcon from "../assets/images/edit.svg";
import pauseIcon from "../assets/images/pause.svg";
import playIcon from "../assets/images/play.svg";
import TrashIcon from "../assets/images/trash.svg";
import loopIcon from "../assets/images/loop.svg";
import AccountActive from "../assets/leftMenu/accountActive.svg";

class WaveformPreviewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      comment: "",
      regions: [],
      newRegionId: null,
      comments: [],
      selectedComment: null,
      playingRegionId: null,
      loopRegionId: null,
      commentLoading: false,
      deleteRegionId: null
    };
    this.inputRef = React.createRef();
    this.wavesurferRef = React.createRef();
    this.commentsContainerRef = React.createRef();
  }

  componentWillUnmount() {
    this.setState({
      open: false,
      comment: "",
      regions: [],
      newRegionId: null,
      comments: [],
      selectedComment: null,
      playingRegionId: null,
      loopRegionId: null
    });
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    if (
      this.props.data &&
      this.props.data.getFilePreview &&
      this.props.data.getFilePreview.regionComments
    ) {
      const regions = this.props.data.getFilePreview.regionComments.map(r =>
        this.transformRegion(r)
      );
      const comments = this.props.data.getFilePreview.regionComments.map(r =>
        this.transformComment(r)
      );
      this.setState({ regions, comments });
    }
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    // Logic works for window resize
    const { open } = this.state
    this.setState({ open: false })
    this.setState({ open: open })
  };

  transformRegion = region => {
    return {
      id: region.id,
      start: region.start,
      end: region.end,
      color: region.color,
      data: region.data || {},
      userInitials: this.getUserInitials(region.user),
      drag: region.user.id === this.props.user.id,
      resize: region.user.id === this.props.user.id
    };
  };

  transformComment = region => {
    return {
      regionId: region.id,
      createdAt: region.createdAt,
      comment: region.comment,
      username: `${region.user.firstName} ${region.user.lastName}`,
      image: region.user.image
        ? process.env.REACT_APP_IMAGE_URL +
          "profile-images/" +
          region.user.image
        : AccountActive,
      id: region.id,
      userId: region.user.id,
      userInitials: this.getUserInitials(region.user),
      isOwner: region.user.id === this.props.user.id
    };
  };

  createComment = ({ region, comment }) => {
    this.setState({ commentLoading: true });
    this.props
      .addRegionComment({
        variables: {
          input: {
            file_id: this.props.file.id,
            color: region.color,
            start: region.start,
            end: region.end,
            comment,
            data: null
          }
        },
        update: () => {
          // this.props.onCreateComment(cache, data)
        }
      })
      .then(res => {
        this.setState(ps => {
          // Remove the region generated by waveform
          const index = ps.regions.findIndex(r => r.id === ps.newRegionId);
          const newRegion = this.transformRegion(res.data.addRegionComment);
          ps.regions[index] = newRegion;
          const newComment = this.transformComment(res.data.addRegionComment);
          ps.comments.push(newComment);

          // Remove regions that doesn't have a comment associated with them
          const commentIds = new Set(ps.comments.map(c => c.regionId));
          const regions = ps.regions.filter(r => commentIds.has(r.id));

          return {
            regions,
            comments: ps.comments,
            comment: "",
            newRegionId: null,
            selectedComment: null,
            commentLoading: false
          };
        });
      })
      .catch(err => {
        this.setState({ commentLoading: false });
        console.log(err);
      });
  };

  updateComment = ({ region, comment }) => {
    this.setState({ commentLoading: true });
    this.props
      .editRegionComment({
        variables: {
          input: {
            id: region.id,
            color: region.color,
            start: region.start,
            end: region.end,
            comment: comment,
            data: null
          }
        }
      })
      .then(res => {
        //   selectedComment.comment = commentVal;
        this.setState(ps => {
          const commentIndex = ps.comments.findIndex(
            c => c.regionId === region.id
          );
          const regionIndex = ps.regions.findIndex(r => r.id === region.id);
          if (commentIndex < 0 || regionIndex < 0) return ps;
          ps.comments[commentIndex] = this.transformComment(
            res.data.editRegionComment
          ); // selectedComment;
          ps.regions[regionIndex] = this.transformRegion(
            res.data.editRegionComment
          );

          return {
            regions: ps.regions,
            comments: [...ps.comments],
            comment: "",
            newRegionId: null,
            selectedComment: null,
            commentLoading: false
          };
        });
      })
      .catch(err => {
        this.setState({ commentLoading: false });
        console.log(err);
      });
  };

  onCommentsToggle = () => {
    this.setState({ open: !this.state.open }, this.updateView);
  };
  onChangeComment = event => {
    this.setState({ comment: event.target.value });
  };
  handleKeyUp = e => {
    const enterKeyCode = 13;
    if (e.key === "Enter" || e.keyCode === enterKeyCode) {
      const form = document.querySelector("#comment-form");
      form.dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };
  updateView() {
    const { open } = this.state;
    if (open) {
      document.getElementsByClassName(
        "waveform-container"
      )[0].style.marginRight = "374px";
      document.getElementsByClassName(
        "waveform-container"
      )[0].style.width = "calc(100% - 375px)";
      document
        .getElementsByClassName("comments-section")[0]
        .classList.add("comments-drawer");
      document.getElementsByClassName("comments-toggle-icon")[0].style.right =
        "402px";
      if (document.getElementsByClassName("user-avatar-wrapper")[0]) {
        document.getElementsByClassName("user-avatar-wrapper")[0].style.right =
          "419px";
      }
    } else {
      document
        .getElementsByClassName("comments-section")[0]
        .classList.remove("comments-drawer");
        document.getElementsByClassName(
          "waveform-container"
        )[0].style.width = "100%";
      document.getElementsByClassName(
        "waveform-container"
      )[0].style.marginRight = "0px";
      document.getElementsByClassName("comments-toggle-icon")[0].style.right =
        "24px";
      if (document.getElementsByClassName("user-avatar-wrapper")[0]) {
        document.getElementsByClassName("user-avatar-wrapper")[0].style.right =
          "0";
      }
    }
  }

  handleRegionsCreate = (regionsCallback, newRegion) => {
    let isWaveformRegion = false;
    if (newRegion && newRegion.id && newRegion.id.startsWith("wavesurfer")) {
      newRegion.userInitials = this.getUserInitials();
      isWaveformRegion = true;
    }

    if (!isWaveformRegion) return;
    if (typeof regionsCallback === "function") {
      this.setState(
        ps => {
          const newRegionId = ps.newRegionId;
          return {
            regions: regionsCallback(
              newRegionId !== null
                ? ps.regions.filter(r => r.id !== newRegionId)
                : ps.regions
            ),
            open: true,
            newRegionId: newRegion ? newRegion.id : null,
            comment: "",
            selectedComment: null
          };
        },
        () => this.updateView()
      );

      if (this.inputRef) {
        this.inputRef.current.focus();
      }
    }
  };

  handleRegionsUpdate = region => {
    const isWaveformRegion = (region.id || "").startsWith("wavesurfer");

    if (!isWaveformRegion) {
      const comment = this.state.comments.find(c => c.regionId === region.id);
      if (!comment) return;
      this.updateComment({ region, comment: comment.comment });
    }

    this.setState(ps => {
      const index = ps.regions.findIndex(r => r.id === region.id);
      this.setSelectedRegion(ps.regions);
      this.setSelectedComment(ps.comments);
      if (index < 0) {
        // newState = ps.concat(region);
        return ps;
      }
      ps.regions[index] = region;
      return { regions: ps.regions };
    });
  };

  handleBlur = () => {
    const { newRegionId, comment } = this.state;
    const isEmptyComment = comment.trim().length < 1;

    // If we've created a new region but we've not added a comment to it
    // then remove it.
    if (isEmptyComment && newRegionId !== null) {
      this.setState(ps => ({
        regions: ps.regions.filter(r => r.id !== newRegionId),
        newRegionId: null
      }));
    }
  };

  getUserInitials(user) {
    if (!this.props.user && !user) return;
    let firstName = "",
      lastName = "";
    if (user) {
      firstName = user.firstName;
      lastName = user.lastName;
    } else {
      firstName = this.props.user.firstName;
      lastName = this.props.user.lastName;
    }

    const [f] = firstName;
    const [l] = lastName;
    return f + l;
  }

  handleCommentSubmit = e => {
    e.preventDefault();
    if (this.state.commentLoading) return;
    const {
      comment,
      newRegionId,
      selectedComment,
      comments,
      regions
    } = this.state;
    this.setSelectedComment(comments);
    this.setSelectedRegion(regions);
    if (!newRegionId && !selectedComment) return;

    const commentVal = comment.trim();
    const isCommentEmpty = commentVal.length < 1;
    if (isCommentEmpty) return;

    // New comment case
    if (newRegionId) {
      const region = this.state.regions.find(r => r.id === newRegionId);
      if (!region) return;
      this.createComment({ region, comment: commentVal });
      return;
    }

    // Update comment case
    if (selectedComment) {
      const region = this.state.regions.find(
        r => r.id === selectedComment.regionId
      );
      this.updateComment({
        region,
        comment: commentVal
      });
    }
  };

  handleRegionDelete = (regionId, commentId) => {
    if (this.wavesurferRef.current) {
      this.wavesurferRef.current.pause();
    }
    this.setState({ deleteRegionId: regionId });
    this.props
      .deleteRegionComment({
        variables: { id: regionId }
      })
      .then(() => {
        this.setState(ps => {
          this.setSelectedComment(ps.comments);
          this.setSelectedRegion(ps.regions);
          return {
            regions: ps.regions.filter(r => r.id !== regionId),
            comments: ps.comments.filter(c => c.id !== commentId),
            playingRegionId: null,
            loopRegionId: null,
            deleteRegionId: null
          };
        });
      })
      .catch(err => {
        this.setState({ deleteRegionId: null });
        console.log("ERROR:", err);
      });
  };

  handleEditPress = regionId => {
    this.setState(ps => {
      const comment = ps.comments.find(c => c.regionId === regionId);
      this.setSelectedComment(ps.comments, regionId);
      this.setSelectedRegion(ps.regions, regionId);
      if (!comment) return ps;
      const newRegionId = ps.newRegionId;
      return {
        selectedComment: comment,
        comment: comment.comment,
        regions: ps.regions.filter(r => r.id !== newRegionId),
        newRegionId: null
      };
    });
    setTimeout(() => {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }, 50);
  };

  /**
   * play will play the region only one time not in a loop
   * and set the loop property to false if any is set to true
   */
  handlePlayRegion = regionId => {
    if (!this.wavesurferRef.current) return;
    // if (this.wavesurferRef.current.regions.list[regionId].loop) {
    //   this.wavesurferRef.current.regions.list[regionId].playLoop();
    // } else {
    this.wavesurferRef.current.regions.list[regionId].play();
    // }
    this.setState(ps => {
      this.setSelectedRegion(ps.regions, regionId);
      this.setSelectedComment(ps.comments, regionId);
      return { playingRegionId: regionId, loopRegionId: null };
    });
  };

  handleRegionOut = region => {
    if (!region || !this.wavesurferRef.current) return;
    const id = region.id;
    if (!id) return;
    const loop = this.wavesurferRef.current.regions.list[id].loop;
    const isPlayingRegion = id === this.state.playingRegionId;
    if (!loop && isPlayingRegion) {
      this.handlePauseRegion();
    } else if (loop && isPlayingRegion) {
      setTimeout(() => {
        this.wavesurferRef.current.regions.list[id].play();
      }, 0);
    }
  };

  handlePauseRegion = () => {
    if (!this.wavesurferRef.current) return;
    this.wavesurferRef.current.pause();
    this.updateLoopStatus();
    this.setState({ playingRegionId: null, loopRegionId: null });
  };

  /**
   * updateLoopStatus(regionId):
   * if we provide regionId to this function
   * then it will set the loop property of that region to true
   * and rest to false and if we don't provide regionId then
   * it will set all regions' loop property to false.
   */
  updateLoopStatus = regionId => {
    this.state.regions.forEach(r => {
      this.wavesurferRef.current.regions.list[r.id].loop =
        r.id !== regionId ? false : true;
    });
  };

  handleLoopRegion = regionId => {
    if (!this.wavesurferRef.current) return;
    const isLoopEnabled = this.wavesurferRef.current.regions.list[regionId]
      .loop;
    this.updateLoopStatus(isLoopEnabled ? undefined : regionId);
    if (isLoopEnabled) {
      this.wavesurferRef.current.pause();
    } else {
      // this.wavesurferRef.current.regions.list[regionId].playLoop();
      // playLoop sometimes doesn't work that's why I manually
      // re-run the loop when it reaches the end in handleRegionOut
      this.wavesurferRef.current.regions.list[regionId].play();
    }
    this.setState(ps => {
      if (!isLoopEnabled) {
        this.setSelectedRegion(ps.regions, regionId);
        this.setSelectedComment(ps.comments, regionId);
      }
      return {
        playingRegionId: isLoopEnabled ? null : regionId,
        loopRegionId: isLoopEnabled ? null : regionId
      };
    });

    // if (this.wavesurferRef.current.regions.list[regionId].loop) {
    //   this.wavesurferRef.current.regions.list[regionId].loop = false;
    // } else {
    //   this.wavesurferRef.current.regions.list[regionId].loop = true;
    // }
  };

  setSelectedComment = (comments, regionId) => {
    let selectedComment = null;
    comments.forEach(c => {
      const comm = document.getElementById(c.id);
      if (!comm) return;
      if (c.regionId === regionId) {
        comm.classList.add("selected-comment");
        selectedComment = comm;
      } else comm.classList.remove("selected-comment");
    });
    return selectedComment;
  };

  setSelectedRegion = (regions, regionId) => {
    regions.forEach(r => {
      // data-id="wavesurfer_0km345mlcdo"
      const reg = document.querySelector(`[data-id="${r.id}"]`);
      if (!reg) return;
      if (r.id === regionId) reg.classList.add("selected-region");
      else reg.classList.remove("selected-region");
    });
  };

  onUserInitialsPress = regionId => {
    const commentDiv = this.setSelectedComment(this.state.comments, regionId);
    this.setSelectedRegion(this.state.regions, regionId);
    if (!commentDiv) return;
    const paddingTop = 10;
    this.commentsContainerRef.current.scrollTo({
      behavior: "smooth",
      top: commentDiv.offsetTop - paddingTop
    });
  };

  handleUserInitialsPress = regionId => {
    if (!this.commentsContainerRef.current) return;
    if (!this.state.open) {
      this.onCommentsToggle();
      setTimeout(() => this.onUserInitialsPress(regionId), 20);
    } else {
      this.onUserInitialsPress(regionId);
    }
  };

  handlePlayPausePress = () => {
    this.setState(ps => {
      this.setSelectedRegion(ps.regions);
      this.setSelectedComment(ps.comments);
      this.updateLoopStatus();
      return {
        playingRegionId: null,
        loopRegionId: null
      };
    });
  };

  render() {
    const { file, data, setCount } = this.props;
    const isDisabled = !this.state.newRegionId && !this.state.selectedComment;
    let placeholder = 'Select audio to leave comment'
    if (this.state.newRegionId || this.state.selectedComment) {
      placeholder = 'Leave a comment'
    }
    const isFileOwner = this.props.user.id === this.props.data.getFilePreview.userId;
    return (
      <>
        <div className="wrapper-container">
          <div className="waveform-container">
            <WaveForms
              onCommentsToggle={this.onCommentsToggle}
              details={file}
              url={data.getFilePreview.url}
              open={this.state.open}
              regions={this.state.regions}
              handleRegionsCreate={this.handleRegionsCreate}
              handleRegionsUpdate={this.handleRegionsUpdate}
              wavesurferRef={this.wavesurferRef}
              handleRegionOut={this.handleRegionOut}
              handleUserInitialsPress={this.handleUserInitialsPress}
              setCount={setCount}
              isPlaying={this.state.playingRegionId !== null}
              handlePlayPausePress={this.handlePlayPausePress}
              togglePlay={this.props.togglePlay}
            />
          </div>
          <div onClick={this.onCommentsToggle} className="comments-toggle-icon">
            <img
              src={require("../assets/images/comments-icon.svg")}
              alt="img"
            />
          </div>
        </div>
          <div className="comments-section">
            <div className="comment-wrapper" ref={this.commentsContainerRef}>
              {this.state.comments.map((c, idx) => {
                return (
                  <RenderComment
                    key={idx}
                    comment={c}
                    handleRegionDelete={this.handleRegionDelete}
                    handleEditPress={this.handleEditPress}
                    handlePlayRegion={this.handlePlayRegion}
                    handleLoopRegion={this.handleLoopRegion}
                    handlePauseRegion={this.handlePauseRegion}
                    isPlaying={this.state.playingRegionId === c.regionId}
                    isEditing={
                      this.state.selectedComment
                        ? this.state.selectedComment.regionId === c.regionId
                        : false
                    }
                    isLoopEnabled={this.state.loopRegionId === c.regionId}
                    isDeleteLoading={this.state.deleteRegionId === c.regionId}
                    isFileOwner={isFileOwner}
                  />
                );
              })}
            </div>
            <div>
              <Form
                className="comment-form"
                id="comment-form"
                onSubmit={this.handleCommentSubmit}
              >
                <textarea
                  id="comment"
                  //   labeltext=""
                  className="bx--text-area comment-input"
                  ref={this.inputRef}
                  onBlur={this.handleBlur}
                  name="comment"
                  placeholder={placeholder}
                  onChange={this.onChangeComment}
                  value={this.state.comment || ""}
                  required
                  onKeyDownCapture={this.handleKeyUp}
                  // onKeyUp={this.handleKeyUp}
                  disabled={this.state.commentLoading || isDisabled}
                />
                <Button
                  type="submit"
                  className="add-comment-btn"
                  disabled={this.state.commentLoading || isDisabled}
                >
                  {this.state.selectedComment ? "Update" : "Send"}
                  {this.state.commentLoading ? "..." : ""}
                </Button>
              </Form>
            </div>
          </div>
      </>
    );
  }
}

export default WaveformPreviewModal;

const RenderComment = React.memo(
  ({
    comment,
    handleRegionDelete,
    handleEditPress,
    handlePlayRegion,
    handleLoopRegion,
    handlePauseRegion,
    isPlaying,
    isEditing,
    isLoopEnabled,
    isDeleteLoading,
    isFileOwner,
  }) => {
    let deleteIconMarkup = null;
    if (comment.isOwner || isFileOwner) {
      deleteIconMarkup = isDeleteLoading ? (
        <Loading
          small
          withOverlay={false}
          className="delete-comment-loading"
          color="#B3B3B3"
        />
      ) : (
        <img
          className="trash-icon"
          src={TrashIcon}
          alt="delete"
          onClick={() => handleRegionDelete(comment.regionId, comment.id)}
          role="button"
          title="Delete"
        />
      );
    }
    let editIconMarkup = null;
    if (comment.isOwner) {
      editIconMarkup = (
        <img
          className="trash-icon"
          src={editIcon}
          alt="edit"
          onClick={() => handleEditPress(comment.regionId, comment.id)}
          role="button"
          title="Edit"
        />
      );
    }

    return (
      <div
        className={`comment ${isEditing ? "selected-comment" : ""}`}
        id={comment.id}
      >
        <div className="comment-header">
          <div className="user">
            <img className="user-avatar" alt="img" src={comment.image} />
            <p className="user-name">{comment.username}</p>
          </div>
          <div className="actions">
            <img
              className="trash-icon"
              src={isLoopEnabled ? pauseIcon : loopIcon}
              alt="loop"
              onClick={() => handleLoopRegion(comment.regionId, comment.id)}
              role="button"
              title="Loop"
            />
            <img
              className="trash-icon"
              src={isPlaying ? pauseIcon : playIcon}
              alt="play"
              onClick={() =>
                isPlaying
                  ? handlePauseRegion()
                  : handlePlayRegion(comment.regionId, comment.id)
              }
              role="button"
              title={isPlaying ? "Pause" : "Play"}
            />
            {editIconMarkup}
            {deleteIconMarkup}
            <p className="time-stamps">
              {/* {new Date(comment.createdAt).toLocaleDateString()} */}
              {DateTime.fromSQL(comment.createdAt, { zone: "utc" }).toISODate()}
            </p>
          </div>
        </div>
        <div className="comment-content">
          <p className="comment-description">{comment.comment}</p>
        </div>
      </div>
    );
  }
);

import React from 'react'
// import SaveToggle from '../components/SaveToggle'
import * as timeago from 'timeago.js'
import { DateTime } from 'luxon'
import { Urls } from '../utils/constants'
import defaultIcon from '../assets/images/profile-img-accent.svg'

export default class ActivityItem extends React.PureComponent {
  state = {}

  constructor(props) {
    super(props)

    this.timer = null
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({
        ...this.state,
        timeFormatted: timeago.format(this.state.time)
      })
    }, 60000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.createdAt === prevState.createdAt) {
      return null
    }

    const time = DateTime.fromSQL(nextProps.createdAt, { zone: 'utc' }).toMillis()

    return {
      createdAt: nextProps.createdAt,
      createdAtLocal: DateTime.fromSQL(nextProps.createdAt, { zone: 'utc' }).toLocaleString(
        DateTime.DATETIME_FULL
      ),
      time,
      timeFormatted: timeago.format(time)
    }
  }

  updateTimeAgo() {
    this.setState({
      ...this.state,
      timeFormatted: timeago.format(this.state.time)
    })
  }

  activityResourceLink() {
    const typeToPath = {
      session: 'sessions',
      recording: 'recordings',
      folder: 'files',
      file: 'files',
      collaborator: 'collaborators'
    }

    if (this.props.resourceType === 'comment') {
      if (this.props.resource !== null) {
        if (this.props.resource.resourceType !== 'project') {
          return (
            '/projects/' +
            this.props.projectId +
            '/' +
            typeToPath[this.props.resource.resourceType] +
            '/' +
            this.props.resource.resourceId
          )
        }
      }
      // PUT BACK
      // return '/projects/' + this.props.resource.resourceId
    }

    if (this.props.resourceType === 'project') {
      return '/projects/' + this.props.resourceId
    }
    // /exp
    if (!this.props.resourceType) {
      return '/projects/'
    }

    if (this.props.resourceType === 'file') {
      return `/projects/${this.props.projectId}/files${this.props.resource ? `/${this.props.resource.folderId}` : ''}`
    }

    return (
      '/projects/' +
      this.props.projectId +
      '/' +
      typeToPath[this.props.resourceType] +
      '/' +
      this.props.resourceId
    )
  }

  commentedOn() {
    return null
  }

  render() {
    let userAvatar = this.props.user.image ? `${Urls.profileImageUrl}${this.props.user.image}` : defaultIcon // eslint-disable-line max-len

    return (
      <div className='appActivityItem'>
        <div className='activityBody'>
          <div className='activityBody__meta'>
            <span className='activityicon'>
              <img src={userAvatar} height={20} alt='user icon'/>
            </span>
            <span className='activityuser'>
              {this.props.user.name}
            </span>
            <span>
              <div className='activityTimeAgo'>
                <time
                  className='activityTimeAgoText'
                  dateTime={this.props.createdAt}
                  title={this.state.createdAtLocal}
                >
                  {this.state.timeFormatted}
                </time>
              </div>
            </span>
          </div>
          <div className='preview'>
            {this.props.action !== 'delete' ? (
              <a href={this.activityResourceLink()}>{this.props.message}</a>
            ) : (
              this.props.message
            )}

            {this.props.resourceType === 'comment' && this.commentedOn()}
          </div>
        </div>
      </div>
    )
  }
}

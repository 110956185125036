import React from 'react'
import { DateTime } from 'luxon'
import { graphql, compose } from 'react-apollo'
import {
  Button,
  ComposedModal,
  ModalBody,
  RadioButton,
  RadioButtonGroup,
  TextInput,
  InlineNotification
} from 'carbon-components-react'

import {
  UPDATE_SHARE,
  UPDATE_SHARE_DIRECT_LINKS
} from '../../graphql/mutations/share'

import { Form, DatePicker, DatePickerInput } from 'carbon-components-react'
import { Field, Schema } from 'v4f'
import PlaylistIcon from '../../assets/images/playlist-icon.svg'
import Validator from '../../utils/Validator'
import linkPlaylist from '../../assets/images/link-playlist.svg'
import WithToasterNotification from '../../hocs/WithToasterNotification'
import CloseIcon from '../../assets/images/cross-icon.svg'

const initialState = {
  files: [],
  emails: '',
  password: '',
  message: '',

  expiry: '',
  initialErrors: {},
  errors: {}
}

let options = { year: 'numeric', month: '2-digit', day: '2-digit' }

class UpdateShareModal extends Validator {
  getEmails = () => {
    let emails =
      this.props.share.users && this.props.share.users.map(user => user.email)
    if (emails && emails.length !== 0) {
      return emails.reduce((prev, curr) => [prev, ', ', curr])
    }

    return ''
  };

  state = {
    ...initialState,
    ...this.props.share,
    files: this.props.files,
    playlistTitle: this.props.playlistTitle,
    emails: this.getEmails(),
    createdAtReadable:
      this.props.share.createdAt &&
      DateTime.fromSQL(this.props.share.createdAt).toFormat('LLL dd, yyyy')
  };

  constructor(props) {
    super(props)
    this.handleExpiryChange = this.handleExpiryChange.bind(this)
    this.state.expiry = this.props.share.expiry
      ? new Date(this.props.share.expiry).toLocaleDateString(undefined, options)
      : ''

    this.handleSuccess = this.handleSuccess.bind(this)
    this.cancelHandler = this.cancelHandler.bind(this)
    this.submit = this.submit.bind(this)
    this.copyLink = this.copyLink.bind(this)
  }

  validator = Schema(
    {
      expiry: Field().string()
    },
    { verbose: true, async: true }
  );

  // Save DatePicker final value to state
  handleExpiryChange(full, formatted) {
    const selected = new Date(formatted)
    const todayDate = new Date()
    if (selected.setHours(0, 0, 0, 0) >= todayDate.setHours(0, 0, 0, 0)) {
      this.setState({
        expiry: formatted,
        errors: {
          // eslint-disable-next-line no-undefined
          expiry: undefined
        }
      })
    } else {
      this.setState({
        expiry: '',
        errors: {
          expiry: 'You cannot set a past date.'
        }
      })
    }
  }

  submit() {
    const expiryIso = this.state.expiry
      ? DateTime.fromFormat(this.state.expiry, 'LL/dd/yyyy').toISODate()
      : ''


    let apiToCall =
      this.props.share.__typename === 'ShareDirectLink'
        ? 'updateShareDirectLink'
        : 'updateShare'
    this.props[apiToCall]({
      uuid: this.state.uuid,
      expiry: expiryIso,
      ...(this.state.password.length && { password: this.state.password })
    })
      .then(() => {
        if (this.handleSuccess) {
          this.handleSuccess()
        } else {
          this.setState({
            notification: {
              kind: 'success',
              title: 'File Share Updated',
              subtitle: ''
            }
          })
        }
      })
      .catch(() => {
        this.setState({
          notification: {
            kind: 'warning',
            title: 'Update failed.',
            subtitle:
              'We couldn’t Save the configurations. Please try again later.'
          }
        })
      })
  }

  handleSuccess() {
    this.props.onSuccess()
    this.props.onRequestClose()
  }

  cancelHandler(e) {
    e.preventDefault()
    this.props.onRequestClose()
  }

  copyLink() {
    if (this.state.link) {
      navigator.clipboard.writeText(this.state.link)
      this.props.toaster('', { kind: 'success', title: 'Link Copied!' })
    } else {
      this.props.toaster('', { kind: 'error', title: 'No Link Found!' })
    }
  }

  render() {
    let modalClass = this.props.playlistTitle
      ? 'playlistViewEdit'
      : 'filesViewEdit'

    let defaultExpiry = ''

    return (
      <>
        <ComposedModal
          className={`modal-scrollable share-view-edit ${modalClass}`}
          open={this.props.open}
          selectorPrimaryFocus='.bx--text-input'
          onClose={this.props.onRequestClose}
        >
          <div className='closeIcon'>
            <img src={CloseIcon} alt='Close Icon' onClick={this.props.onRequestClose} />
          </div>
          <ModalBody>
            <h1 className='title'>
              {this.props.playlistTitle
                ? 'Shared Playlist Info'
                : 'Shared Files Info'}
            </h1>
            <Form onSubmit={this.handleSubmit} autoComplete='off'>
              <>

                {/* created at  */}
                <div className='formRow'>
                  <p className='createdAt'>
                    Created: {this.state.createdAtReadable}
                  </p>
                </div>


                {/* share details */}
                <div className='sharedExpiration formRow'>
                  <div className='sharedVia'>
                    <label className='bx--label'>Shared Via</label>
                    <RadioButtonGroup
                      name='radio-button-group'
                      valueSelected={this.state.emails ? 'email' : 'link'}
                      disabled
                    >
                      <RadioButton
                        id='email'
                        labelText='Email'
                        value='email'
                        disabled={!this.state.emails}
                      />
                      <RadioButton
                        id='link'
                        labelText='Link'
                        value='link'
                        disabled={this.state.emails}
                      />
                    </RadioButtonGroup>
                  </div>

                  <div className='expiration'>
                    <DatePicker
                      id='expiry-date-picker'
                      onChange={this.handleExpiryChange}
                      datePickerType='single'
                    >
                      <DatePickerInput
                        id='expiry-input'
                        name='expiry'
                        labelText='Expiration Date'
                        placeholder='mm/dd/yyyy'
                        onChange={this.handleExpiryChange}
                        onBlur={this.handleDirty}
                        value={this.state.expiry || ''}
                        invalid={this.state.errors.expiry ? true : false}
                        invalidText={this.state.errors.expiry}
                        autoComplete='new-password'
                      />
                    </DatePicker>
                  </div>
                </div>


                {/* Message */}
                {!this.state.expiry && (
                  <p className='link-expiration-text formRow'>
                    {`This link expires on ${defaultExpiry}, 30 days from now.`}
                  </p>
                )}


                {/* Show Emails if Shared with Email */}
                {this.state.emails && (
                  <div className='formRow'>
                    <TextInput
                      id='email'
                      name='Email'
                      labelText='Shared to Email'
                      value={this.state.emails || ''}
                      disabled
                    />
                  </div>
                )}


                {/* Show Link if Shared with Link */}
                {!this.state.emails && (
                  <div className='formRow linkRow'>
                    <TextInput
                      id='link'
                      name='Link'
                      labelText='Link'
                      value={this.state.link || ''}
                      disabled
                    />
                    <div className='linkPlaylistIcon' onClick={this.copyLink}>
                      <img src={linkPlaylist} alt='icon' className='icon' />
                    </div>
                  </div>
                )}


                {/* Password Field */}
                <div className='formRow'>
                  <TextInput
                    id='password'
                    name='password'
                    labelText='Password'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.password}
                    type='password'
                    placeholder='Enter New Password'
                    autoComplete='new-password'
                    // invalid={this.state.errors.password ? true : false}
                    // invalidText={this.state.errors.password || ''}
                  />
                </div>


                {/* Files Which are Shared */}
                {this.state.files && this.state.files.length > 0 && (
                  <div className='formRow'>
                    <label className='bx--label'>Files Shared</label>
                    <div className='filesListContainer'>
                      {this.state.files.map((file, i) => {
                        return (
                          <p key={i} className='fileName'>
                            {file.name}
                          </p>
                        )
                      })}
                    </div>
                  </div>
                )}


                {/* Playlist Name which is Shared */}
                {this.state.files &&
                  this.state.files.length === 0 &&
                  this.props.playlistTitle && (
                  <div className='formRow'>
                    <label className='bx--label'>Playlist Shared</label>
                    <div className='playlistName'>
                      <img src={PlaylistIcon} alt='playlist' />
                      <p className='name'>{this.props.playlistTitle}</p>
                    </div>
                  </div>
                )}
              </>


              <div className='formRow actionBtnContainer'>
                <button
                  className='bx--btn bx--btn--secondary'
                  onClick={this.cancelHandler}
                >
                  Cancel
                </button>
                <Button type='submit'>
                  {this.props.loading ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </ComposedModal>
        {this.state.notification && (
          <InlineNotification
            {...this.state.notification}
            hideCloseButton={true}
          />
        )}
      </>
    )
  }
}

export default compose(
  graphql(UPDATE_SHARE, {
    name: 'updateShare',
    props: data => ({
      updateShare: variables => data.updateShare({ variables })
    })
  }),
  graphql(UPDATE_SHARE_DIRECT_LINKS, {
    name: 'updateShareDirectLink',
    props: data => ({
      updateShareDirectLink: variables =>
        data.updateShareDirectLink({ variables })
    })
  }),
  WithToasterNotification
)(UpdateShareModal)

// import  image from '../assets/integrationAssests/ppl-logo.png'
export const Gradients = [
  'linear-gradient(326deg, #bd4f6c 0%, #d7816a 74%)',
  'linear-gradient(315deg, #f8ceec 0%, #a88beb 74%)',
  'linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%)',
  'linear-gradient(315deg, #d5adc8 0%, #ff8489 74%)',
  'linear-gradient(315deg, #7f53ac 0%, #647dee 74%)',
  'linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%)',
  'linear-gradient(315deg, #734ae8 0%, #89d4cf 74%)',
  'linear-gradient(315deg, #fc9842 0%, #fe5f75 74%)',
  'linear-gradient(315deg, #fc5296 0%, #f67062 74%)',
  'linear-gradient(315deg, #f907fc 0%, #05d6d9 74%)',
  'linear-gradient(315deg, #f9484a 0%, #fbd72b 74%)',
  'linear-gradient(315deg, #c7e9fb 0%, #e61d8c 74%)'
]

export const GetGradientFromId = id => {
  const gradientsLength = Gradients.length
  if (['number', 'string'].indexOf(typeof id) < 0) {
    const value = Math.floor(Math.random() * gradientsLength)
    return Gradients[value % gradientsLength]
  }
  return Gradients[Number(id) % gradientsLength]
}

export const PLAN_FREE = 'free'
export const PLAN_EDUCATION = 'education'
export const PLAN_INDIVIDUAL = 'individual'
export const PLAN_PRO = 'pro'
export const PLAN_PRO_UNLIMITED = 'prounlimited'

export const STORAGE_LIMITS = {
  [PLAN_FREE]: {
    amount: 2 * 1024 * 1024 * 1024,
    label: '2GB'
  },
  [PLAN_INDIVIDUAL]: {
    amount: 1024 * 1024 * 1024 * 1024,
    label: '1TB'
  },
  [PLAN_PRO]: {
    amount: 1024 * 1024 * 1024 * 1024 * 3,
    label: '3TB'
  }
}

export const PLAN_NAMES = {
  [PLAN_FREE]: 'Free',
  [PLAN_INDIVIDUAL]: 'Lite',
  [PLAN_PRO]: 'Pro',
  [PLAN_PRO_UNLIMITED]: 'Pro Unlimited'
}

export const PLAN_PRICES = {
  [PLAN_FREE]: '$0',
  [PLAN_INDIVIDUAL]: '$13.99',
  [PLAN_PRO]: '$19.99',
  [PLAN_PRO_UNLIMITED]: '$49.99'
}

export const PLAN_CHANGES = {
  [PLAN_FREE]: {
    [PLAN_INDIVIDUAL]: true,
    [PLAN_PRO]: true,
    [PLAN_PRO_UNLIMITED]: true
  },
  [PLAN_INDIVIDUAL]: {
    [PLAN_FREE]: false,
    [PLAN_PRO]: true,
    [PLAN_PRO_UNLIMITED]: true
  },
  [PLAN_PRO]: {
    [PLAN_FREE]: false,
    [PLAN_INDIVIDUAL]: false,
    [PLAN_PRO_UNLIMITED]: true
  },
  [PLAN_PRO_UNLIMITED]: {
    [PLAN_FREE]: false,
    [PLAN_INDIVIDUAL]: false,
    [PLAN_PRO]: false
  }
}

export const IntegrationsContent = [
  {
    title: 'Creative Passport',
    logoImage: require('../assets/integrationAssests/cp-logo.png'),
    text: `Founded by music makers for music makers, The Creative
    Passport is a free verified digital ID built for music makers,
    where they can access, update and manage information about
     themselves and their works, and share it with others.
     By integrating with other music services, platforms and
      organisations, it ensures that every place an artist’s
      work shows up, so do they. Data can be shared both via
      a public page and privately. With huge growth potential
       music makers are empowered to connect the dots for
       existing and future music services.`,
    link: 'https://www.creativepassport.net',
    detailsText: `Easily add your credits using your Creative Passport account.
    VEVA’s Session Check-in Code is integrated with Creative Passport’s
    technology. Distribute your VEVA session code to all of
    your collaborators to streamline the collection of credits
     for your recordings.
     Creative Passport users can use your VEVA
     session code to automatically add their credit into your VEVA session.`,
    checkBoxText: null
  },
  {
    title: 'PPL',
    logoImage: require('../assets/integrationAssests/ppl-logo.png'),
    text: `PPL licences recorded music in the UK when it is played
    in public or broadcast and ensures that revenue flows back to
     our members. These include independent and major record companies,
     together with performers ranging from emerging musicians to globally
      renowned artists. In 2020 we collected £225.7 million while also
      distributing money to over 135,000 performers and recording rights holders.`,
    link: 'https://www.ppluk.com',
    detailsText: `Go to your ‘My Party’ page within your account.
     If you are a PPL member, select PPL in the CMO dropdown list.
      This will open a window that is integrated with PPL’s
       technology and allows VEVA to authenticate a performer’s
        identification, retrieve their IPN from PPL’s database
         and add it to your party. Then, anywhere you attribute
          your credit, the metadata will include
    your IPN number, which is verified against PPL’s database,
    allowing for proper credit for all of your recordings.`,
    checkBoxText: null
  },
  {
    title: 'Jaxsta',
    logoImage: require('../assets/integrationAssests/jaxta-logo.png'),
    text: ` Are you an artist, musician, songwriter, or studio professional?
     Sign up to Jaxsta Pro to promote and share your achievements with a
      single profile link that captures everything you've done and who you've
       worked with, all populated by Jaxsta's official credits.`,
    link: 'https://jaxsta.com',
    detailsText: `Sign up for your Jaxsta Pro account
     to become a member of the largest verified
     credits database in the world.`,
    checkBoxText: null
  },

  {
    title: 'SoundExchange',
    logoImage: require('../assets/integrationAssests/sound-exchange-logo.png'),
    text: `SoundExchange is the premier music tech organization on a mission
    to power the future of music. It was independently formed in 2003 to build
     a fairer, simpler, and more efficient music industry through technology,
     data, and advocacy. The only organization designated by the U.S. government
      to administer the Section 114 sound recording license, SoundExchange collects
       and distributes digital performance royalties on behalf of 560,000 music
       creators and growing. Through proprietary music tech solutions that turn
       data into accurate revenue, SoundExchange has paid more than $9 billion
        in distributions to date`,
    link: 'https://www.soundexchange.com',
    detailsText: `In this integrations section of your profile,
     click the button to consent to send your data to SoundExchange.
      For all of your projects, within your recording - designate if
       anyone should be listed as Featured by selecting F in the F/NF/O
        (F=Featured / NF= Non-Featured / O = Other) parameter.
        Once all of your credits have been added to your recording,
        click the Lock Credit button in the top section of your project.
         SoundExchange will then appear as a tag, and a RIN file is
         automatically sent to SoundExchange containing the credits
          for the specific project.`,
    checkBoxText: `By clicking this box, I hereby authorize
    VEVA Sound to share with SoundExchange, Inc. any recording,
     song, and credit information that I have provided VEVA Sound.`
  },
  {
    title: 'Quansic',
    logoImage: require('../assets/integrationAssests/quansic.png'),
    text: `Quansic is the world leader in Music Industry identifiers. 
    We operate the largest artist database with over 14 million artists
     identified with ISNI, IPI, or IPN linked to 180 million assets 
     identified with ISRC, ISWC, and BOW. Our services range from 
     accurate data matching to real-time identifier assignment and 
     are available both as APIs or web services.`,
    link: 'https://quansic.com/',
    detailsText: `Click “Get an ISNI” button anywhere it appears in 
    VEVA Collect, and you will be linked to the Quansic website where
     you can sign up for an ISNI identifier.`,
    checkBoxText: null
  },
]
export const Colors = {
  $accent: '#d5743e',
  $white: '#fff',
  $defaultRegionColor: 'rgba(255, 255, 255, 0.1)',
  $selectedRegionColor: 'rgba(75, 181, 67, 0.2)',
}
export const Urls = {
  profileImageUrl: process.env.REACT_APP_IMAGE_URL + 'profile-images/'
}

export const MIN_WIDTH_CUSTOM_DOWNLOAD_PAGE_IMG = '1200px';

import React, { useEffect, useState } from 'react'
import { compose } from 'react-apollo'
import { withRouter } from 'react-router'
import ApolloClient from '../utils/Apollo'
import AppBranding from '../components/AppBranding'
import download from '../assets/images/download-share.svg'
import GetPlatformImages from '../hocs/queries/GetPlatformImages'
import { GET_PUBLIC_SHARE_FILES } from '../graphql/queries'
import { Button } from 'carbon-components-react'
import { DOWNLOAD_SHARED_FILES } from '../graphql/mutations'
import PublicShareNavBar from '../components/PublicShareNavBar'
import DownloadShareExpired from '../components/DownloadShareExpired'
import DownloadShareDownloaded from '../components/DownloadShareDownloaded'
import DownloadShareBackground from '../assets/images/download-shared-files.svg'
import ImagePreview from '../assets/images/image-preview.svg'


const PublicShareDownload = ({ match }) => {
  const [files, setFiles] = useState([])
  const [expired, setExpired] = useState(false)
  const [downloadComplete, setDownloadComplete] = useState(false)
  useEffect(() => {
    getFiles(match.params.token)
  }, [])

  const getFiles = (publicShareId) => {
    ApolloClient.query({
      query: GET_PUBLIC_SHARE_FILES,
      variables: { publicShareId },
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      data.publicSharePreviewFiles.expired ? setExpired(true) :
        setFiles(data.publicSharePreviewFiles.files)
    })
  }

  const onDownload = (publicShareId) => {
    return new Promise(() => {
      ApolloClient.mutate({
        variables: { publicShareId },
        mutation: DOWNLOAD_SHARED_FILES,
        fetchPolicy: 'no-cache'
      }).then(({ data }) => {
        window.open(data.PublicShareDownload.url, '_blank')
        setDownloadComplete(true)
      }).catch((err) => {
        console.log(err, 'err')
      })
    })
  }
  // const [image] = platformImages.filter(i => i.location === 'login')
  return (
    <div className='publicShare download-public-share'>
      <div className='download-advertisement-container'>
        <img className='advertisement-img' src={DownloadShareBackground} alt='background public share'/>
        {/* {image.caption && <div className='backgroundCredit'>{image.caption}</div>} */}
      </div>
      <PublicShareNavBar/>
      <div className='publicShareBackground'>
        <div className='public-share-download-logo-container'>
          <AppBranding/>
        </div>
        {downloadComplete && (
          <div className='download-shared-files'>
            <div className='loginForm'>
              <DownloadShareDownloaded />
            </div>
          </div>
        )

        }
        <div className='downloadWrapper publicShareWrapper'>
          {!expired && !downloadComplete && <div><div className='publicDownload'>
            <img alt='download' src={download}/>
          </div>
          <div className='downloadTextContainer'>
            <h1>Ready to Download</h1>
            <p className='expirationLimitText'>File share expires in 7 days</p>
          </div>
          <div className={!expired ? 'downloadLine' : ''}/>
          {files.map((item, i) => (
            <div key={i} className='publicShareContainer'>
              <div className='selectedFileContainer'>
                <p className='selectedFile'>{item.name.length > 30 ? `${item.name.substring(0, 30)}...` : item.name}</p>
                <div className='fileOptions'>
                  <div className={`fileType fileType--compact fileType--${item.name.split('.')[item.name.split('.').length - 1]}`}>
                    {item.name.split('.')[item.name.split('.').length - 1]}</div>
                  <p className='fileSize'>{item.size} Bytes</p>
                </div>
              </div>
              <div onClick={() => window.open(item.url)} className='imagePreviewContainer'>
                <img src={ImagePreview} alt='download-single' className='downloadImage'/>
              </div>
              <div onClick={() => window.open(item.url)} className='downloadImageContainer'>
                <img src={download} alt='download-single' className='downloadImage'/>
              </div>
            </div>
          ))}
          <div className='publicDownloadButton'>
            <Button className='downloadButton' onClick={() => onDownload(match.params.token)}>
                 Download
            </Button>
          </div>
          </div>}
          {expired && (<div className='loginForm download-share'>
            <DownloadShareExpired />
          </div>)}
        </div>
      </div>

    </div>
  )
}

export default compose(withRouter, GetPlatformImages)(PublicShareDownload)

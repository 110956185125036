
import React from 'react'
import Cross from '../../../assets/images/cross-icon.svg'
import DownIcon from '../../../assets/images/down-icon.svg'
import SingleFileProgress from './PublicShareSingleFileProgress'
import { memo } from 'react'


const SuccessfullUploads = ({ uploads }) => {
  if (!uploads || uploads.length === 0) {
    return []
  }
  return uploads.filter(uploadFile => uploadFile.complete)
}

const UploadingFiles = ({ uploads }) => {
  if (!uploads || uploads.length === 0) {
    return []
  }
  return uploads.filter(uploadFile => uploadFile.uploading)
}

const FailedUploads = ({ uploads }) => {
  if (!uploads || uploads.length === 0) {
    return []
  }
  return uploads.filter(uploadFile => uploadFile.cancelled || uploadFile.error)
}

const PublicShareDetailedProgress = props => {
  const {
    toggleExpanded,
    uploadStatus,
    uploadStatusFiles,
    closeProgressModal,
    removeUpload
  } = props
  const uploads = props.uploads

  const successfullUploads = SuccessfullUploads({ uploads })
  const failedUploads = FailedUploads({ uploads })
  const uploadingFiles = UploadingFiles({ uploads })

  return (
    <div className='detailedProgress fullWidthModal'>
      <div className='detailedProgress--header'>
        <div className='infoContainer'>
          {uploadingFiles.length > 0 && (
            <>
              <p className='uploadStatus--info'>{uploadStatus}</p>
              <p className='uploadStatus--info'>{uploadStatusFiles}</p>
            </>
          )}
        </div>
        <div className='expandIcon'>
          <img src={DownIcon} alt='Expand' onClick={toggleExpanded} />
        </div>
        <div className='closeIcon'>
          <img src={Cross} alt='Close' onClick={closeProgressModal} />
        </div>
      </div>

      <div className='sectionsContainer'>
        {uploadingFiles.length > 0 && (
          <div className='fileProgressContainer'>
            {uploadingFiles.map(uploadFile => (
              <SingleFileProgress
                name={uploadFile.name}
                size={uploadFile.size}
                progress={uploadFile.progress}
                id={uploadFile.id}
                type={uploadFile.type}
                uploading={true}
                removeUpload={removeUpload}
              />
            ))}
          </div>
        )}

        {failedUploads.length > 0 && (
          <div>
            <div className='detailedProgress--header'>
              <div className='infoContainer'>
                <p className='uploadStatus--info'>Failed</p>
                <p className='uploadStatus--info'>
                  {failedUploads.length} File{failedUploads.length > 1 && 's'}
                </p>
              </div>
            </div>
            <div className='fileProgressContainer'>
              {failedUploads.map(uploadFile => (
                <SingleFileProgress
                  name={uploadFile.name}
                  size={uploadFile.size}
                  progress={uploadFile.progress}
                  id={uploadFile.id}
                  type={uploadFile.type}
                  progressBarStyle='fail'
                  removeUpload={removeUpload}
                />
              ))}
            </div>
          </div>
        )}

        {successfullUploads.length > 0 && (
          <div>
            <div className='detailedProgress--header'>
              <div className='infoContainer'>
                <p className='uploadStatus--info'>Success</p>
                <p className='uploadStatus--info'>
                  {successfullUploads.length} File
                  {successfullUploads.length > 1 && 's'}
                </p>
              </div>
            </div>
            <div className='fileProgressContainer'>
              {successfullUploads.map(uploadFile => (
                <SingleFileProgress
                  name={uploadFile.name}
                  size={uploadFile.size}
                  progress={uploadFile.progress}
                  id={uploadFile.id}
                  type={uploadFile.type}
                  progressBarStyle='success'
                  removeUpload={removeUpload}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(PublicShareDetailedProgress)

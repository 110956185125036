import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    contributionType: Field()
      .string()
      .required({ message: Errors.Required }),
      //Not required for multiselect
    contributionId: Field(),
    contributionIds: Field().array(),
    partyId: Field()
      .string()
      .required({ message: Errors.Required }),
    creditRoleId: Field()
      .string()
      .required({ message: Errors.Required }),
    creditRoleUserDefinedValue: Field(),
    instrumentId: Field()
      .string(),
    instrumentUserDefinedValue: Field(),
    split: Field()

  },
  { verbose: true, async: true }
)

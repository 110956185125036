import React from 'react'
import {
  Form,
  Button,
  DatePicker,
  DatePickerInput,
  TextInput,
  TextArea
} from 'carbon-components-react'
import { DateTime } from 'luxon'


import ApolloClient from '../utils/Apollo'
import { SelectASync } from '../components/Select'
import CreateSongModal from '../components/CreateSongModal'
import GetUser from '../hocs/queries/GetUser'

import {
  GET_SONGS,
  GET_RECORDING_TYPES,
  GET_LANGUAGES
} from '../graphql/queries'

import Validator from '../utils/Validator'
import RecordingValidator from '../validators/Recording'
import Tooltip from './ui/Tooltip'
import definitions from '../utils/definitions.json'
import { compose } from 'react-apollo'
import WithAlertModal from '../hocs/WithAlertModal'

import * as permissions from '../utils/permissions'
import DiscardModal from './modals/DiscardModal'
import { withRouter } from 'react-router-dom'

const initialState = {
  recordingTypeId: '',
  recordingTypeName: '',
  type: {},
  recordingTypeUserDefinedValue: '',

  version: '',

  songId: '',
  songTitle: '',
  song: {},

  isrc: '',

  recordedOn: '',
  mixedOn: '',

  duration: '',
  languageId: '',
  language: {},

  keySignature: '',
  timeSignature: '',
  tempo: '',
  description: '',

  initialErrors: {},
  errors: {},
  createSongModalOpen: false,

  userDefinedRecordingTypes: [],
  userDefinedVersionTypes: [],
  discardModal: false
}

let initialCalcState = {}
class RecordingForm extends Validator {
  state = { ...initialState }

  static defaultProps = {
    type: {
      id: '2',
      name: 'MusicalWorkSoundRecording'
    }
  }

  constructor(props) {
    super(props)

    this.handleSongChange = this.handleSongChange.bind(this)
    this.handleRecordedOnChange = this.handleRecordedOnChange.bind(this)
    this.handleMixedOnChange = this.handleMixedOnChange.bind(this)
    this.getRecordingTypeOptions = this.getRecordingTypeOptions.bind(this)
    this.handleRecordingTypeChange = this.handleRecordingTypeChange.bind(this)
    this.handleRecordingTypeBlur = this.handleRecordingTypeBlur.bind(this)
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.handleLanguageBlur = this.handleLanguageBlur.bind(this)

    this.openCreateSongModal = this.openCreateSongModal.bind(this)
    this.closeCreateSongModal = this.closeCreateSongModal.bind(this)
    this.onSongCreated = this.onSongCreated.bind(this)

    this.validator = RecordingValidator

    this.state.name = props.name ? props.name : ''
    this.state.recordingTypeId = props.type ? props.type.id : ''
    this.state.recordingTypeName = props.type ? props.type.name : ''
    this.state.recordingTypeUserDefinedValue = props.typeUserDefinedValue || ''
    if (this.state.recordingTypeId) {
      this.state.type = {
        value: this.state.recordingTypeId,
        label: this.state.recordingTypeName
      }
    }

    this.state.name = props.name ? props.name  : ''
    this.state.songId = props.song ? props.song.id : ''
    this.state.songTitle = props.song ? props.song.title : ''
    if (this.state.songId) {
      this.state.song = {
        value: this.state.songId,
        label: this.state.songTitle,
        title: this.filterSongTitle(this.state.songTitle)
      }
    }

    this.state.languageId = props.language ? props.language.id : ''
    this.state.languageName = props.language ? props.language.name : ''
    if (this.state.languageId) {
      this.state.language = {
        value: this.state.languageId,
        label: this.state.languageName
      }
    }

    this.state.subtitle = props.subtitle || ''
    this.state.version = props.version || ''
    this.state.isrc = props.isrc || ''
    this.state.recordedOn = props.recordedOn ?
      DateTime.fromSQL(props.recordedOn, { zone: 'utc' }).toFormat('LL/dd/yyyy') : ''
    this.state.mixedOn = props.mixedOn ?
      DateTime.fromSQL(props.mixedOn, { zone: 'utc' }).toFormat('LL/dd/yyyy') : ''
    this.state.duration = props.duration || ''

    if (this.state.duration) {
      const date = new Date(null)
      date.setSeconds(this.state.duration)
      this.state.duration = date.toISOString().substr(11, 8)
    }

    this.state.keySignature = props.keySignature || ''
    this.state.timeSignature = props.timeSignature || ''
    this.state.tempo = props.tempo || ''
    this.state.description = props.description || ''


    this.discardChanges = this.discardChanges.bind(this)
    this.openDiscardModal = this.openDiscardModal.bind(this)
    this.closeDiscardModal = this.closeDiscardModal.bind(this)
    this.getPartyNames = this.getPartyNames.bind(this)
    this.filterSongTitle = this.filterSongTitle.bind(this)

    this.saveCalculatedState = this.saveCalculatedState.bind(this)
    this.saveCalculatedState()
  }

  saveCalculatedState() {
    initialCalcState = this.state
  }

  getPartyNames(credits) {
    let partyNames = []
    credits.map((credit)=> {
      if (credit.party) {
        partyNames.push(credit.party.name)
      }
      return null
    })
    return partyNames.join(', ')
  }

  filterSongTitle(songTitle){
    let filteredValue = songTitle.replace("(Shared)", "").trim()
    return filteredValue || ''
   }
  
  songOptions = inputValue =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_SONGS,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let songs = []

        data.getSongs.filter(({ userId })=>{
          if (this.props.project.user.id === this.props.user.id) {
            return userId === this.props.user.id
          }
          return (userId === this.props.user.id) || (userId === this.props.project.user.id)
        }).forEach(song => {
          if (!song.title.toLowerCase().includes(inputValue.toLowerCase())) {
            return
          }
          const songWriters = []
          song.credits.forEach(credit =>{
            if (credit.role.name === 'Songwriter' || credit.role.name === 'songwriter') {
              songWriters.push(credit.party.name)
            }
          })
          let partyNames = this.getPartyNames(song.credits)
          songs.push({
            userId: song.userId,
            value: song.id,
            label: `${song.title}${song.subtitle ? ` - ${song.subtitle}` : ''}${partyNames ? ` - ( ${partyNames} )` : ''}`,
            title: this.filterSongTitle(song.title)
          })
        })
        this.setState({
          songs: songs
        })
        resolve(songs)
      })
    })


  submit() {
    const { project, user, alert } = this.props

    const { song } = this.state
    const songPermission = permissions.can(this.props.project.id, 'song')

    if (project.userId === user.id || song.userId !== user.id) {
      this.props.onSubmit(this.state, this.state.name)
      return
    }
    alert( `The Song you associated with this Recording is owned by you and you have chosen to associate it with this Recording. 
    It will be copied to the Project owners’ account excluding any associated Song files. 
    Only Song details and credits will be copied. 
    The Project owner will be the owner of this new shared Song, 
    and it will be governed by the permissions set in the Project owner’s account, 
    now set to ${songPermission.create ? 'Full Access' : 'Read Only'}. 
    This new shared Song will also be available to all other Project collaborators with “(Shared)” added to its name. 
    The original Song will still be owned by you and available in your account.`,
    
    {
      title: ' ',
      secondaryText: 'Cancel',
      buttonText: 'Approve',
      submitCallback: (close)=>{
        this.props.onSubmit(this.state)
        close()
      }
    }
    )
  }

  handleSongChange(option) {

    this.setState({
      ...this.state,
      songId: option.value,
      song: option,
      title: option.title,
      name: option.title + ' - Version ' + this.state.version,
      errors: {
        ...this.state.errors,
        songId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  openCreateSongModal() {
    this.setState({
      ...this.state,
      createSongModalOpen: true
    })
  }

  closeCreateSongModal() {
    this.setState({
      ...this.state,
      createSongModalOpen: false
    })
  }

  onSongCreated(song) {
    this.setState({
      ...this.state,
      createSongModalOpen: false,
      song: {
        value: song.id,
        label: song.title,
        title: song.title
      },
      songId: song.id,
      songTitle: song.title
    })
  }

  handleRecordedOnChange(full, formatted) {
    this.setState({
      ...this.state,
      recordedOn: formatted,
      errors: {
        ...this.state.errors,
        recordedOn: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleMixedOnChange(full, formatted) {
    this.setState({
      ...this.state,
      mixedOn: formatted,
      errors: {
        ...this.state.errors,
        mixedOn: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleRecordingTypeChange(option) {
    this.setState({
      ...this.state,
      recordingTypeId: option.value,
      type: option,
      errors: {
        ...this.state.errors,
        recordingTypeId: undefined // eslint-disable-line no-undefined
      }
    })
  }

  handleRecordingTypeBlur() {
    this.handleDirty({
      target: {
        name: 'recordingTypeId',
        value: this.state.recordingTypeId
      }
    })
  }

  handleLanguageChange(option) {
    this.setState((prevState) => ({
      languageId: option.value,
      language: option,
      errors: {
        ...prevState.errors,
        languageId: undefined // eslint-disable-line no-undefined
      }
    }))
  }

  handleLanguageBlur() {
    this.handleDirty({
      target: {
        name: 'languageId',
        value: this.state.languageId
      }
    })
  }

  getLanguageOptions = (option = '') =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_LANGUAGES,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let languages = []
        data.getLanguages.filter(language => language.name.toLowerCase()
          .includes(option.toLowerCase()))
          .forEach(language => {
            languages.push({
              value: language.id,
              label: language.name
            })
          })

        resolve(languages)
      })
    })

  getRecordingTypeOptions = inputValue =>
    new Promise(resolve => {
      ApolloClient.query({
        query: GET_RECORDING_TYPES,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        let recordingTypes = []
        let userDefinedRecordingTypes = []
        data.getRecordingTypes.forEach(type => {
          if (!type.name.toLowerCase().includes(inputValue.toLowerCase())) {
            return
          }

          if (type.userDefined) {
            userDefinedRecordingTypes.push(type.id)
          }

          recordingTypes.push({
            value: type.id,
            label: type.name
          })
        })

        this.setState({
          ...this.state,
          userDefinedRecordingTypes
        })
        initialCalcState.userDefinedRecordingTypes = userDefinedRecordingTypes
        resolve(recordingTypes)
      })
    })


  openDiscardModal(e) {
    e.preventDefault()
    const {onRequestClose}= this.props
   

    let change = false
    Object.entries(initialCalcState).forEach(([key]) => {
      if (JSON.stringify(initialCalcState[key]) !== JSON.stringify(this.state[key])) {
        change = true
      }
    })

    if (change) {
      this.setState({
        discardModal: true
      })
    } else {
      if (onRequestClose) {
        onRequestClose()
        return
      }
      this.props.history.goBack()
    }
  }

  closeDiscardModal() {
    this.setState({
      discardModal: false
    })
  }

  discardChanges() {
    this.setState(initialCalcState)
  }


  defaultSongValue(song) {
    let defaultSong = this.state.songs ? this.state.songs.find((songEntry)=> songEntry.value === song.value) : song
    return defaultSong ? defaultSong : song
  }

  render() {
    const showRequiredOnly = !this.props.showAll
    return (
      <>
      <div className='form recordingForm'>
        <Form onSubmit={this.handleSubmit} autoComplete='off'>

          <div className='formRow'>
            <TextInput id='name'
              disabled
              name='name'
              labelText='Recording Title (Generated)'
              value={(this.state.song.title ? this.state.song.title : '') + (this.state.version ? ' - ' + this.state.version : '')}
              placeholder='New Recording Title'
            />
          </div>

            {showRequiredOnly && (
              <div className='formRow'>
                <TextInput
                  id='subtitle'
                  name='subtitle'
                  labelText='Subtitle'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={this.state.subtitle || ''}
                  invalid={this.state.errors.subtitle ? true : false}
                  invalidText={this.state.errors.subtitle}
                />
              </div>
            )}

            <div className='formRow'>
              <SelectASync
                labelText='Type *'
                id='recordingTypeId'
                key={this.state.type.value}
                cacheOptions
                defaultOptions
                loadOptions={this.getRecordingTypeOptions}
                onChange={this.handleRecordingTypeChange}
                onBlur={this.handleRecordingTypeBlur}
                defaultValue={this.state.type.value ? this.state.type : null}
                invalid={this.state.errors.recordingTypeId ? true : false}
                invalidText={this.state.errors.recordingTypeId}
              />
            </div>

            {showRequiredOnly && (
              <div className='formRow datePickerContainer'>
                <div className='datePicker'>
                  <DatePicker
                    id='recordedOn-date-picker'
                    onChange={this.handleRecordedOnChange}
                    datePickerType='single'
                  >
                    <DatePickerInput
                      id='recordedOn-input'
                      name='recordedOn'
                      labelText='Recorded On'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleRecordedOnChange}
                      onBlur={this.handleDirty}
                      value={this.state.recordedOn || ''}
                      invalid={this.state.errors.recordedOn ? true : false}
                      invalidText={this.state.errors.recordedOn}
                    />
                  </DatePicker>
                </div>

                <div className='datePicker'>
                  <DatePicker
                    id='mixedOn-date-picker'
                    onChange={this.handleMixedOnChange}
                    datePickerType='single'
                  >
                    <DatePickerInput
                      id='mixedOn-input'
                      name='mixedOn'
                      labelText='Mixed On'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleMixedOnChange}
                      onBlur={this.handleDirty}
                      value={this.state.mixedOn || ''}
                      invalid={this.state.errors.mixedOn ? true : false}
                      invalidText={this.state.errors.mixedOn}
                    />
                  </DatePicker>
                </div>
              </div>
            )}

            <div className='formRow'>
              <SelectASync
                key={this.state.song.value}
                labelText='Song *'
                btnText={showRequiredOnly ? 'Create' : null}
                btnOnClick={this.openCreateSongModal}
                id='songId'
                cacheOptions
                defaultOptions
                loadOptions={this.songOptions}
                onChange={this.handleSongChange}
                defaultValue={this.state.song.value ? this.state.song : null}
                invalid={this.state.errors.songId ? true : false}
                invalidText={this.state.errors.songId}
                placeholder='Select from list ...'
              />
            </div>

            {showRequiredOnly && (
              <>
                <div className='formRow'>
                  <TextInput
                    id='version'
                    name='version'
                    labelText='Version'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.version || ''}
                    invalid={this.state.errors.version ? true : false}
                    invalidText={this.state.errors.version || ''}
                  />
                </div>
                <div className='formRow'>
                  <TextInput
                    id='isrc'
                    name='isrc'
                    labelText={
                      <Tooltip term={'ISRC'} definition={definitions.ISRC} />
                    }
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.isrc || ''}
                    invalid={this.state.errors.isrc ? true : false}
                    invalidText={this.state.errors.isrc}
                  />
                </div>
                <div className='formRow'>
                  <TextInput
                    id='duration'
                    name='duration'
                    labelText='Duration (Hours : Minutes : Seconds)'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.duration || ''}
                    invalid={this.state.errors.duration ? true : false}
                    invalidText={this.state.errors.duration}
                  />
                </div>
                <div className='formRow'>
                  <SelectASync
                    labelText='Language'
                    id='languageId'
                    cacheOptions
                    defaultOptions
                    loadOptions={this.getLanguageOptions}
                    onChange={this.handleLanguageChange}
                    onBlur={this.handleLanguageBlur}
                    key={JSON.stringify(this.state.language)}
                    defaultValue={
                      this.state.language.value ? this.state.language : null
                    }
                    invalid={this.state.errors.languageId ? true : false}
                    invalidText={this.state.errors.languageId}
                    placeholder='Select from list ...'
                  />
                </div>
                <>
                  {this.state.userDefinedRecordingTypes.indexOf(
                    this.state.type.value
                  ) > -1 && (
                    <div className='formRow'>
                      <TextInput
                        id='recordingTypeUserDefinedValue'
                        name='recordingTypeUserDefinedValue'
                        labelText='Type Name'
                        onChange={this.handleChange}
                        onBlur={this.handleDirty}
                        value={this.state.recordingTypeUserDefinedValue || ''}
                        invalid={
                          this.state.errors.recordingTypeUserDefinedValue
                            ? true
                            : false
                        }
                        invalidText={
                          this.state.errors.recordingTypeUserDefinedValue || ''
                        }
                      />
                    </div>
                  )}
                </>
                <div className='formRow'>
                  <TextInput
                    id='keySignature'
                    name='keySignature'
                    labelText='Key Signature'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.keySignature || ''}
                    invalid={this.state.errors.keySignature ? true : false}
                    invalidText={this.state.errors.keySignature}
                  />
                </div>
                <div className='formRow'>
                  <TextInput
                    id='timeSignature'
                    name='timeSignature'
                    labelText='Time Signature'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.timeSignature || ''}
                    invalid={this.state.errors.timeSignature ? true : false}
                    invalidText={this.state.errors.timeSignature}
                  />
                </div>
                <div className='formRow'>
                  <TextInput
                    id='tempo'
                    name='tempo'
                    labelText='Tempo'
                    type='number'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.tempo || ''}
                    key={this.state.tempo}
                    invalid={this.state.errors.tempo ? true : false}
                    invalidText={this.state.errors.tempo}
                  />
                </div>
                <div className='formRow'>
                  <TextArea
                    id='description'
                    name='description'
                    labelText='Description'
                    onChange={this.handleChange}
                    onBlur={this.handleDirty}
                    value={this.state.description || ''}
                    invalid={this.state.errors.description ? true : false}
                    invalidText={this.state.errors.description || ''}
                  />
                </div>
              </>
            )}

            <div className='formRow actionBtnContainer'>
              <button
                className='bx--btn bx--btn--secondary'
                onClick={this.openDiscardModal}
              >
                Cancel
              </button>
              <Button type='submit'>
                {this.props.loading
                  ? `${this.props.editing ? 'Saving...' : 'Creating...'}`
                  : `${this.props.editing ? 'Save' : 'Create'}`}
              </Button>
            </div>
          </Form>
        </div>
        {this.state.createSongModalOpen &&
         <CreateSongModal
         open={this.state.createSongModalOpen}
         onRequestClose={this.closeCreateSongModal}
         onSongCreated={this.onSongCreated}
       />
        }
        
        {this.state.discardModal && (
          <DiscardModal
            open={this.state.discardModal}
            onRequestClose={this.closeDiscardModal}
            modalHeading='Discard Changes?'
            handleSubmit={this.discardChanges}
          />
        )}
      </>
    )
  }
}
export default compose(
  GetUser,
  WithAlertModal,
  withRouter
)(RecordingForm)

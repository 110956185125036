import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Tooltip } from 'carbon-components-react'
import { WaveSurfer, WaveForm, Region } from 'wavesurfer-react'
import { DateTime } from 'luxon'
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min'
import { Colors } from '../utils/constants'

const WaveForms = React.memo(
  ({
    url,
    open,
    details,
    onCommentsToggle,
    regions,
    handleRegionsCreate,
    handleRegionsUpdate,
    handleRegionOut,
    wavesurferRef,
    handleUserInitialsPress,
    setCount,
    handlePlayPausePress,
    togglePlay
  }) => {
    const [, setToggleIcon] = useState(false)
    const [loadingCount, setLoadingCount] = useState(0)
    const { $accent, $white } = Colors
    const { type, name, createdAt, prettySize } = details
    const Date = DateTime.fromSQL(createdAt, { zone: 'utc' }).toISODate()
    const plugins = useMemo(() => {
      return [
        {
          plugin: RegionsPlugin,
          options: { dragSelection: true }
        }
      ].filter(Boolean)
    }, [])

    useEffect(() => {
      return () => {
        wavesurferRef.current = null
      }
    }, [])

    const setRegions = (newRegions, newRegion) => {
      if (typeof newRegions !== 'function') {
        return
      }
      handleRegionsCreate(newRegions, newRegion)
    }

    const addRegionChildern = (id, userInitials) => {
      const region = document.querySelector(`[data-id="${id}"]`)
      if (!region) {
        return
      }
      if (
        region.lastChild &&
        region.lastChild.classList &&
        region.lastChild.classList.contains('user-initials')
      ) {
        return
      }

      const initialsContainer = document.createElement('div')
      initialsContainer.onclick = function (e) {
        e.preventDefault()
        e.stopPropagation()
        handleUserInitialsPress(id, e)
      }
      initialsContainer.classList.add('user-initials')
      initialsContainer.innerHTML = userInitials
      region.appendChild(initialsContainer)
    }

    useEffect(() => {
      const waves = document.getElementsByTagName('wave')
      const wave = waves.length > 0 ? waves[0] : null
      if (!wave) {
        return
      }
      wave.setAttribute(
        'style',
        (wave.getAttribute('style') || '') + ';overflow: unset;'
      )
    }, [])

    useEffect(() => {
      regions.forEach(({ id, userInitials }) =>
        addRegionChildern(id, userInitials)
      )
    }, [regions])

    useEffect(() => {
      if (open && loadingCount === 100) {
        wavesurferRef.current.zoom(1)
      } else if (loadingCount === 100) {
        wavesurferRef.current.zoom(0)
      }
    }, [open])

    const regionCreatedHandler = useCallback(region => {
      if (region.data.systemRegionId) {
        return
      }
      setRegions(
        ps => [
          ...ps,
          { ...region, data: { ...region.data, systemRegionId: -1 } }
        ],
        region
      )
    }, [])
    const onWSMount = useCallback(
      waveSurfer => {
        if (waveSurfer.markers) {
          waveSurfer.clearMarkers()
        }
        wavesurferRef.current = waveSurfer
        if (wavesurferRef.current) {
          wavesurferRef.current.load(url)
          wavesurferRef.current.on('region-created', e => {
            e.color = Colors.$defaultRegionColor
            regionCreatedHandler(e)
          })

          wavesurferRef.current.on('region-out', e => {
            handleRegionOut(e)
          })

          wavesurferRef.current.on('finish', () => {
            setToggleIcon(false)
            if (setCount) {
              setCount()
            }
          })

          wavesurferRef.current.on('waveform-ready', () => {
            if (wavesurferRef.current) {
              wavesurferRef.current.play()
            }
            setToggleIcon(true)
            if (togglePlay) {
              togglePlay('clicked')
            }
          })

          wavesurferRef.current.on('loading', data => {
            setLoadingCount(data)
          })

          if (window) {
            window.surferidze = wavesurferRef.current
          }
        }
      },
      [regionCreatedHandler]
    )

    useEffect(() => {
      return () => {
        if (loadingCount === 100) {
          wavesurferRef.current.stop()
          if (togglePlay) {
            togglePlay(wavesurferRef.current.isPlaying() ? true : false)
          }
        }
      }
    }, [])

    // const onPlay = useCallback(() => {
    //   wavesurferRef.current.playPause();
    // }, []);
    const onSkipForward = useCallback(() => {
      wavesurferRef.current.skipForward(10)
    }, [])
    const onSkipBackward = useCallback(() => {
      wavesurferRef.current.skipBackward(10)
    }, [])
    // const pauseAndPlayIconToggle = () => {
    //   setToggleIcon(!toggleIcon);
    // };

    const handlePlayPause = useCallback(() => {
      wavesurferRef.current.playPause()
      setToggleIcon(ps => !ps)
      if (handlePlayPausePress) {
        handlePlayPausePress()
      }
      if (togglePlay) {
        togglePlay(wavesurferRef.current.isPlaying() ? true : false)
      }
    }, [handlePlayPausePress, wavesurferRef, togglePlay])

    const getPlayPauseIcon = useCallback(() => {
      if (!wavesurferRef.current) {
        return 'play-icon'
      }
      return wavesurferRef.current.isPlaying() ? 'pause-icon' : 'play-icon'
    }, [])

    return (
      <div className='waveform-wrapper'>
        {loadingCount !== 100 && (
          <div className='preloader'>
            <div className='preloader-inner'>{loadingCount}%</div>
          </div>
        )}
        <div
          onClick={() => {
            if (!open) {
              onCommentsToggle()
            }
            // onGenerateMarker()
          }}
        >
          <WaveSurfer plugins={plugins} onMount={onWSMount}>
            <WaveForm
              height='130'
              barWidth='2'
              barHeight='1.3'
              cursorWidth='2'
              responsive={true}
              cursorColor={`${$white}`}
              barGap='4'
              hideScrollbar='true'
              backend='MediaElement'
              progressColor={`${$accent}`}
              waveColor={`${$accent}`}
              id='waveform'
            >
              <RenderRegion
                regions={regions}
                handleRegionsUpdate={handleRegionsUpdate}
              />
            </WaveForm>
          </WaveSurfer>
        </div>
        {loadingCount === 100 && (
          <>
            <div className='actions-container'>
              <div className='audio-controls-wrapper'>
                <span className='icon rewind-icon' onClick={onSkipBackward} />
                <span
                  className={`icon ${getPlayPauseIcon()}`}
                  onClick={handlePlayPause}
                />
                <span className='icon forward-icon' onClick={onSkipForward} />
              </div>
              <div className='media-details-wrapper'>
                <div className='media-details'>
                  <Tooltip
                    direction='top'
                    tabIndex={0}
                    showIcon={false}
                    triggerText={<span className='media-name'>{name}</span>}
                  >
                    <span className='media-name'>{name}</span>
                  </Tooltip>
                  <span className='media-extension'>{type && type.toUpperCase()}</span>
                  <span className='media-size'>{prettySize}</span>
                </div>
                <span className='date'>{Date}</span>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
)
export default WaveForms

const RenderRegion = React.memo(({ regions, handleRegionsUpdate }) => {
  return regions.map(regionProps => (
    <Region
      key={regionProps.id}
      onUpdateEnd={handleRegionsUpdate}
      {...regionProps}
    />
  ))
})

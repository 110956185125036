import { TextInput } from 'carbon-components-react'
import React from 'react'
import Copy from '../assets/images/copy.svg'
import Facebook from '../assets/images/facebook.svg'
import Email from '../assets/images/email.svg'
import Twitter from '../assets/images/twitter-share.svg'


function ShareWithPeople({ copyText, discountCode, codeUseCount }) {
  const message =
  `I’m using VEVA Collect and think you would love it, too!\n
Get one month free of any paid subscription tier when you sign up using my Personal Discount Code.\n
My Personal Discount Code: ${discountCode}
Get started at: `

  const link = `https://app.vevacollect.com/register/discount-code/${discountCode}`

  const facebookClickHandler = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${link}`
    window.open(url, '_blank')
  }


  const emailClickHandler = () => {
    const subject = 'I’m using VEVA Collect and think you would love it, too! '
    const body = message + link
    const recipient = ''
    const emailLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    window.location.href = emailLink
  }

  const twitterClickHandler = () => {
    const tweetText = encodeURIComponent(message)
    const tweetUrl = `https://twitter.com/intent/tweet?url=${link}&text=${tweetText}`
    window.open(tweetUrl)
  }

  return (
    <div className='share-with-people'>
      <p> <span>Share the love!</span> Invite up to 10 friends to sign up for
        VEVA Collect using your
        Personal Discount Code and for each successful signup,
        you'll both receive one month free of any chosen subscription tier.
      </p>
      <p>
        Your Personal Discount Code has been used <span>{codeUseCount}/10</span> times,
        granting you <span>{codeUseCount}</span> free months of any chosen subscription!</p>
      <div className='share-link'>
        <div className='copy-clipboard'>
          <TextInput
            id='code'
            name='code'
            value={discountCode}
            disabled={true}
          />
          <button className='bx--btn bx--btn--secondary' onClick={ () => copyText(discountCode)}>
            <img src={Copy} alt='copy'/>Copy to Clipboard</button>
        </div>
      </div>
      <div className='share'>
        <div className='share-as' onClick={facebookClickHandler}>
          <img src={Facebook} alt='copy'/>Share on Facebook
        </div>
        <div className='share-as' onClick={emailClickHandler}>
          <img src={Email} alt='copy' /> Share on Email
        </div>
        <div className='share-as' onClick={twitterClickHandler}>
          <img src={Twitter} alt='copy' className='twitterIcon' /> Share on Twitter
        </div>
      </div>
    </div>
  )
}

export default ShareWithPeople

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Query } from 'react-apollo'

import {
  GET_RECENT_PROJECT_SESSIONS,
  GET_RECENT_PROJECT_CREDITS,
  GET_COLLABORATORS,
  GET_RECENT_PROJECT_RECORDINGS
} from '../../graphql/queries'
import { LOCK_UNLOCK_PROJECT } from '../../graphql/mutations'

import {
  PLAN_PRO,
  PLAN_PRO_UNLIMITED,
  PLAN_EDUCATION,
  PLAN_FREE,
  PLAN_INDIVIDUAL
} from '../../utils/constants'

import * as permissions from '../../utils/permissions'

import { getUserFavouritesByType } from '../../utils/selectors'
import GetUser from '../../hocs/queries/GetUser'
import { GetProjectRefreshed } from '../../hocs/queries/GetProject'

// Our Base Components

import CollaboratorsTable from '../../components/CollaboratorsTable'
import CreditTable from '../../components/CreditTable'
import InfoGrid from '../../components/InfoGrid'
import ProjectHero from '../../components/ProjectHero'
import RecordingTable from '../../components/RecordingTable'
import SectionContainer from '../../components/SectionContainer'
import SessionTable from '../../components/SessionTable'

import ImportProjectRINModal from '../../components/ImportProjectRINModal'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import WithAlertModal from '../../hocs/WithAlertModal'
import ApolloClient from '../../utils/Apollo'
import RecentActivity from '../../components/RecentActivity'

import DropdownMenu from '../../components/Dropdowns/DropdownMenu'
import ProjectDropdownIcon from '../../assets/recordings/project-collaborator.svg'
import RecordingDropdownIcon from '../../assets/recordings/recording-collaborator.svg'
import SessionDropdownIcon from '../../assets/images/sessionCredit.svg'
import { filterPermissions } from '../../utils/permissions'
import ProjectFilesList from '../../components/ProjectFilesList'
import FolderActiveIcon from '../../assets/images/folderActive.svg'
import UploadIconIcon from '../../assets/upload-arrow.svg'
import { WithMoveFilesFrom } from '../../hocs/MoveFiles'

class ProjectDashboard extends React.Component {
  state = {
    importProjectRINModalOpen: false,
    collaboratorSearchTerm: '',
    viewAll: false,
    showIntegrations: false,
    searchRecording: '',
    searchSession: '',
    searchCredit: '',
    searchFile: ''
  };

  constructor(props) {
    super(props)

    this.openImportProjectRINModal = this.openImportProjectRINModal.bind(this)
    this.closeImportProjectRINModal = this.closeImportProjectRINModal.bind(
      this
    )
    this.searchFilter = this.searchFilter.bind(this)
    this.searchRecordingHandler = this.searchRecordingHandler.bind(this)
    this.searchSessionHandler = this.searchSessionHandler.bind(this)
    this.searchCreditHandler = this.searchCreditHandler.bind(this)
    this.searchFileHandler = this.searchFileHandler.bind(this)
    this.transformCredits = this.transformCredits.bind(this)
    this.uploadFiles = this.uploadFiles.bind(this)
    this.moveFiles = this.moveFiles.bind(this)
    this.projectLockedMessage = this.projectLockedMessage.bind(this)
  }

  openImportProjectRINModal() {
    this.setState({
      importProjectRINModalOpen: true
    })
  }

  closeImportProjectRINModal() {
    this.setState({
      importProjectRINModalOpen: false
    })
  }
  getCollaboratorSearchTerm = value => {
    this.setState({
      collaboratorSearchTerm: value
    })
  };
  lockCredits = async () => {
    await ApolloClient.mutate({
      mutation: LOCK_UNLOCK_PROJECT,
      variables: {
        projectId: this.props.project.id
      },
      fetchPolicy: 'no-cache'
    })
    this.props.refetchProject()
  };
  getTags = permissions => {
    const tags = []
    const permissionsKyes = Object.keys(permissions)
    permissionsKyes.forEach(key => {
      if (permissions[key] === true) {
        tags.push({
          tagName: key.charAt(0).toUpperCase() + key.slice(1)
        })
      }
    })
    return tags
  };

  searchFilter = ({ data, key, searchValue }) => {
    if (!data || !key) {
      return []
    }

    let filteredArray = []
    if (data && data.length) {
      data.map(i => {
        if (!i) {
          return null
        }
        const title = i[key] ? i[key].toLowerCase() : ''
        if (title.includes(searchValue.trim().toLowerCase())) {
          filteredArray.push(i)
        }
        return null
      })
      return filteredArray
    }
    return []
  };

  searchRecordingHandler(e) {
    this.setState({
      searchRecording: e
    })
  }

  searchSessionHandler(e) {
    this.setState({
      searchSession: e
    })
  }

  searchCreditHandler(e) {
    this.setState({
      searchCredit: e
    })
  }

  searchFileHandler(e) {
    this.setState({
      searchFile: e
    })
  }

  transformCredits(credits = []) {
    let transCredits = credits.map(credit => {
      return {
        ...credit,
        name: credit.party ? credit.party.name : ''
      }
    })

    return transCredits || []
  }

  uploadFiles() {
    this.props.history.push(`/projects/${this.props.project.id}/files?upload`)
  }

  moveFiles() {
    const { project } = this.props
    const breadCrumb = [
      {
        href: `/projects`,
        name: 'Projects'
      },
      {
        href: `#`,
        name: `${project.name}`
      }
    ]
    this.props.moveFilesFrom.open(
      this.props.project.id,
      null,
      breadCrumb,
      null
    )
  }

  projectLockedMessage() {
    this.props.alert('Project Credits are locked. Please unlock to edit.', {
      title: '   ',
      className: 'credits-alert'
    })
  }

  render() {
    const { project, user, alert, history, match } = this.props
    const accountType = user.subscriptions[0] ? user.subscriptions[0].stripePlan : ''
    const favourites = getUserFavouritesByType(user)

    const hasFullAccess = permissions.hasFullAccess(project.id, user)
    const isProjectOwner = permissions.isProjectOwner(project.id, user)
    const canUpdateProject = isProjectOwner
    const canCreateRecording =
      isProjectOwner || permissions.canCreate(project.id, 'recording')
    const canCreateFile =
      isProjectOwner || permissions.canCreate(project.id, 'file')
    const canCreateSession =
      isProjectOwner || permissions.canCreate(project.id, 'session')
    const canCreateCredit =
      isProjectOwner || permissions.canCreate(project.id, 'session')
    const canCreateCollaborator =
      isProjectOwner || permissions.canCreate(project.id, 'collaborator')
    const canUpdateCollaborator =
      isProjectOwner || permissions.canUpdate(project.id, 'collaborator')
    const canDeleteCollaborator =
      isProjectOwner || permissions.canDelete(project.id, 'collaborator')
    const filePermissions = permissions.can(project.id, 'file')
    const recordingPermissions = permissions.can(project.id, 'recording')
    const sessionPermissions = permissions.can(project.id, 'session')
    const cretitPermissions = permissions.can(project.id, 'session')
    const collaboratorType = permissions.getCollaboratorType(project.id, user.collaborators)

    let collaboratorDropdownList = [
      {
        name: 'Project Collaborator',
        icon: ProjectDropdownIcon,
        click: () =>
          history.push(`/projects/${project.id}/collaborators/create/project`)
      },
      {
        name: 'Recording Collaborator',
        icon: RecordingDropdownIcon,
        click: () =>
          history.push(`/projects/${project.id}/collaborators/create/recording`)
      }
    ]

    let creditDropdownList = [
      {
        name: 'Project Credit',
        icon: ProjectDropdownIcon,
        click: () =>
          history.push({
            pathname: `/projects/${project.id}/credits/create`,
            state: { contributionType: 'project' }
          })
      },
      {
        name: 'Recording Credit',
        icon: RecordingDropdownIcon,
        click: () =>
          history.push({
            pathname: `/projects/${project.id}/recording-credits/create`,
            state: { contributionType: 'recording' }
          })
      },
      {
        name: 'Session Credit',
        icon: SessionDropdownIcon,
        click: () =>
          history.push({
            pathname: `/projects/${project.id}/session-credits/create`,
            state: { contributionType: 'session' }
          })
      }
    ]

    let filesDropdownList = [
      {
        name: 'Upload',
        icon: UploadIconIcon,
        click: this.uploadFiles
      },
      { name: 'Files', icon: FolderActiveIcon, click: this.moveFiles }
    ]

    return (
      <>
        <Helmet>
          <title>{project.name} | Projects</title>
        </Helmet>
        <main className='main projectDashboard'>
          {/* Project Hero */}
          <>
            <ProjectHero
              isProjectOwner={isProjectOwner}
              sxStatus={user.sxStatus === '0' ? false : true}
              lockCredits={this.lockCredits}
              locked={project.locked}
              favourite={
                (favourites.project || []).indexOf(parseInt(project.id, 10)) >
                -1
              }
              {...project}
              openImportProjectRINModal={this.openImportProjectRINModal}
              canImport={hasFullAccess}
              canUpdateProject={canUpdateProject}
              collaboratorType={collaboratorType}
            />
          </>

          {/*  Project Details */}
          <div className='projectDetails'>
            <CreatePagesHeader
              subtitle='Project Details'
              btnText={canUpdateProject ? 'Edit' : false}
              btnOnClick={() =>
                this.props.history.push(`/projects/${project.id}/edit`)
              }
            />

            <div className='sections-container projectInfoGrid'>
              <SectionContainer>
                <InfoGrid
                  fullWidth={true}
                  items={[
                    {
                      title: 'Title',
                      content: project.name || '-'
                    },
                    {
                      title: 'Project Number',
                      content: project.number || '-'
                    },
                    {
                      title: 'Project Artist',
                      content: project.artist ? project.artist.name : '-'
                    },
                    {
                      title: 'Created At',
                      content: project.createdAt
                    },
                    {
                      title: 'Last Updated At',
                      content: project.updatedAt
                    },
                    {
                      title: 'Label',
                      content: project.label ? project.label.name : '-'
                    },
                    {
                      title: 'Project Owner',
                      content: project.user ? project.user.name : '-'
                    },
                    {
                      title: 'Description',
                      content: project.description,
                      requiresLargeContainer: true
                    }
                  ]}
                />
              </SectionContainer>
            </div>
          </div>

          {/* Project Recordings - Start */}

          <div
            className={`projectRecordings ${
              project.locked ? 'ghosted-wrapper' : ''
            }`}
          >
            <CreatePagesHeader
              subtitle='Project Recordings'
              tags={!isProjectOwner ? this.getTags(filterPermissions(recordingPermissions)) : []}
              btnText={canCreateRecording ? 'Add' : false}
              btnOnClick={() => {
                if (project.locked) {
                  alert('Project Credits are locked. Please unlock to edit.', {
                    title: '   ',
                    className: 'credits-alert'
                  })
                  return
                }
                history.push({ pathname: `/projects/${project.id}/recordings/create`, state: { projectDashboardPage: true } })
              }}
              linkText='View All'
              linkUrl={`/projects/${this.props.project.id}/recordings`}
              showSearch={true}
              getSearchTerm={this.searchRecordingHandler}
            />

            <div className='sections-container'>
              <SectionContainer>
                <Query
                  query={GET_RECENT_PROJECT_RECORDINGS}
                  variables={{ projectId: project.id }}
                  fetchPolicy='network-only'
                >
                  {({ loading, error, data }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    const canUpdate = isProjectOwner ? true : false

                    let filteredRecordings = this.searchFilter({
                      data: data.getRecordings ? data.getRecordings.data : [],
                      key: 'name',
                      searchValue: this.state.searchRecording
                    })
                    return (
                      <div className='project-files'>
                        <RecordingTable
                          nameCellClass='project-recording-table'
                          rows={filteredRecordings || []}
                          locked={project.locked}
                          showSearch={false}
                          showSkeleton={loading}
                          onDeleteAlert={() => this.props.alert('The project Recording has been successfully deleted.')}
                          favourite
                          projectId={project.id}
                          favourites={favourites.recording || []}
                          canUpdate={canUpdate}
                          collaboratorType={collaboratorType}
                          projectDashboardPage={true}
                          onDelete={(cache, { data: deleteRecording }) => {
                            const { getRecordings } = cache.readQuery({
                              query: GET_RECENT_PROJECT_RECORDINGS,
                              variables: {
                                projectId: match.params.projectId
                              }
                            })
                            const recordings = {
                              ...getRecordings,
                              data: getRecordings.data.filter(
                                recording =>
                                  parseInt(recording.id, 10) !==
                                  parseInt(
                                    deleteRecording.deleteRecording.id,
                                    10
                                  )
                              )
                            }

                            cache.writeQuery({
                              query: GET_RECENT_PROJECT_RECORDINGS,
                              variables: {
                                projectId: match.params.projectId
                              },
                              data: {
                                getRecordings: recordings
                              }
                            })
                          }}
                        />
                      </div>
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>
          {collaboratorType !== 'recording' ? <>
          {/* Project Recordings - End */}

          {/* Project Sessions - Start */}

          <div
            className={`projectSessions ${
              project.locked ? 'ghosted-wrapper' : ''
            }`}
          >
            <CreatePagesHeader
              subtitle='Project Sessions'
              tags={!isProjectOwner ? this.getTags(filterPermissions(sessionPermissions)) : []}
              btnText={canCreateSession ? 'Add' : false}
              btnOnClick={() => {
                if (project.locked) {
                  alert('Project Credits are locked. Please unlock to edit.', {
                    title: '   ',
                    className: 'credits-alert'
                  })
                  return
                }
                history.push({ pathname: `/projects/${project.id}/sessions/create`, state: { projectDashboardPage: true } })
              }}
              linkText='View All'
              linkUrl={`/projects/${this.props.project.id}/sessions`}
              showSearch={true}
              getSearchTerm={this.searchSessionHandler}
            />

            <div className='sections-container'>
              <SectionContainer>
                <Query
                  query={GET_RECENT_PROJECT_SESSIONS}
                  variables={{ projectId: project.id }}
                  fetchPolicy='network-only'
                >
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    const canUpdate =
                      isProjectOwner ||
                      permissions.canUpdate(project.id, 'session')

                    const canDelete =
                      isProjectOwner ||
                      permissions.canDelete(project.id, 'session')

                    let filteredSessions = this.searchFilter({
                      data: data.getSessions ? data.getSessions.data : [],
                      key: 'name',
                      searchValue: this.state.searchSession
                    })

                    return (
                      <div className='project-files'>
                        <SessionTable
                          nameCellClass='session-table'
                          rows={filteredSessions || []}
                          locked={project.locked}
                          showSearch={false}
                          showSkeleton={loading}
                          projectId={project.id}
                          favourites={favourites.session || []}
                          canUpdate={canUpdate}
                          canDelete={canDelete}
                          onDelete={refetch}
                        />
                      </div>
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>

          {/* Project Sessions - End */}

          {/* Project Files - Start */}

          <div className={`projectFiles ${project.locked ? 'ghosted-wrapper' : ''}`}>
            <CreatePagesHeader
              subtitle='Project Files'
              btnText={project.locked ? 'Add' : false}
              btnOnClick={this.projectLockedMessage}
              tags={!isProjectOwner ? this.getTags(filterPermissions(filePermissions, 'files')) : []}
              linkText='View All'
              linkUrl={`/projects/${this.props.project.id}/files`}
              dropDown={
                !project.locked &&
                canCreateFile && (
                  <DropdownMenu dropdownList={filesDropdownList} />
                )
              }
              showSearch={true}
              getSearchTerm={this.searchFileHandler}
            />
            <ProjectFilesList
              {...this.props}
              searchFile={this.state.searchFile}
            />
          </div>

          {/* Project Files - End */}

          {/* Project Credits - Start */}

          <div
            className={`projectCredits ${
              project.locked ? 'ghosted-wrapper' : ''
            }`}
          >
            <CreatePagesHeader
              subtitle='Project Credits'
              btnText={project.locked ? 'Add' : false}
              btnOnClick={this.projectLockedMessage}
              tags={!isProjectOwner ? this.getTags(filterPermissions(cretitPermissions)) : []}
              linkText='View All'
              linkUrl={`/projects/${project.id}/credits`}
              showSearch={true}
              getSearchTerm={this.searchCreditHandler}
              dropDown={
                !project.locked &&
                canCreateCredit && (
                  <DropdownMenu dropdownList={creditDropdownList} />
                )
              }
            />
            <div className='sections-container'>
              <SectionContainer>
                <Query
                  query={GET_RECENT_PROJECT_CREDITS}
                  variables={{
                    projectId: project.id
                  }}
                  fetchPolicy='network-only'
                >
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }
                    const canUpdate =
                      isProjectOwner ||
                      permissions.canUpdate(project.id, 'session')

                    const canDelete =
                      isProjectOwner ||
                      permissions.canDelete(project.id, 'session')

                    const canSongs = permissions.can(project.id, 'song')

                    let transCredits = this.transformCredits(
                      data.getCreditsByProject ? data.getCreditsByProject : []
                    )
                    let filteredCredits = this.searchFilter({
                      data: transCredits,
                      key: 'name',
                      searchValue: this.state.searchCredit
                    })

                    return (
                      <div className='project-files'>
                        <CreditTable
                          nameCellClass='credit-table'
                          rows={filteredCredits || []}
                          locked={project.locked}
                          showSearch={false}
                          showSkeleton={loading}
                          projectId={project.id}
                          pagination={false}
                          rowActions
                          canUpdate={canUpdate}
                          canDelete={canDelete}
                          onDelete={refetch}
                          songPermissions={canSongs}
                          isProjectOwner={isProjectOwner}
                        />
                      </div>
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>

          {/* Project Credits - End */}

          {
            <>
              {(user.subscriptions[0].stripePlan === PLAN_PRO ||
                user.subscriptions[0].stripePlan === PLAN_PRO_UNLIMITED ||
                user.subscriptions[0].stripePlan === PLAN_EDUCATION ||
                user.subscriptions[0].stripePlan === PLAN_FREE ||
                user.subscriptions[0].stripePlan === PLAN_INDIVIDUAL
              ) && (
                <div
                  className={`${
                    project.locked ? 'ghosted-wrapper' : ''
                  } projectCollaborator`}
                >
                  <CreatePagesHeader
                    subtitle='Collaborators'
                    btnText={project.locked && (accountType !== 'free') ? 'Add' : false}
                    btnOnClick={this.projectLockedMessage}
                    tags={
                      !isProjectOwner && (!canDeleteCollaborator
                        ? [{ tagName: 'Read' }] : [{ tagName: 'Full Access' }])
                    }
                    getSearchTerm={this.getCollaboratorSearchTerm}
                    showSearch={true}
                    dropDown={
                      !project.locked && (accountType !== 'free') &&
                      canCreateCollaborator && (
                        <DropdownMenu dropdownList={collaboratorDropdownList} />
                      )
                    }
                  />

                  <div className='sections-container'>
                    <SectionContainer>
                      <Query
                        query={GET_COLLABORATORS}
                        variables={{ projectId: project.id }}
                        fetchPolicy='network-only'
                      >
                        {({ loading, error, data }) => {
                          if (error) {
                            return `Error! ${error.message}`
                          }

                          let collaborators = data.getCollaborators
                          let owner = project.user

                          return (
                            <div>
                              <CollaboratorsTable
                                nameCellClass='collaborator-table'
                                rows={collaborators || []}
                                showSkeleton={loading}
                                owner={owner}
                                searchTerm={this.state.collaboratorSearchTerm}
                                showSearch={false}
                                canUpdate={canUpdateCollaborator}
                                canDelete={canDeleteCollaborator}
                                projectId={project.id}
                                userId={user.id}
                                projectPage={true}
                              />
                            </div>
                          )
                        }}
                      </Query>
                    </SectionContainer>
                  </div>
                </div>
              )}
            </>
          }
          </>
            :
            ''
          }
        </main>
        <RecentActivity projectId={project.id} />
        {hasFullAccess && (
          <ImportProjectRINModal
            open={this.state.importProjectRINModalOpen}
            onRequestClose={this.closeImportProjectRINModal}
          />
        )}
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProjectRefreshed,
  WithAlertModal,
  WithMoveFilesFrom
)(ProjectDashboard)

import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Query } from 'react-apollo'
import { GET_USER_ACCOUNT } from '../graphql/queries'
import WithAlertModal from '../hocs/WithAlertModal'
import GetUser from '../hocs/queries/GetUser'
import CreatePagesHeader from '../components/CreatePagesHeader'
import MenuSwitcher from '../components/MenuSwitcher'

// Our Base Components

import MyPartyForm from './Affiliations/MyPartyForm'

class MyParty extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>My Party</title>
        </Helmet>
        <main className='main'>
          <CreatePagesHeader
            subtitle='My Party'
          >
            <div className='switcher-Style'>
              <MenuSwitcher/>
            </div>
          </CreatePagesHeader>
            <Query query={GET_USER_ACCOUNT}
              fetchPolicy='network-only'>
              {({ loading, error, data }) => {
                if (loading) {
                  return null
                }

              if (error) {
                return `Error! ${error.message}`
              }

              return (
                <MyPartyForm
                  userInfo={data.getUser}
                  inMyParty={true}
                />
              )
            }}
          </Query>
        
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  WithAlertModal,
)(MyParty)

/* eslint-disable no-inline-comments */
import React from 'react'
import { DateTime } from 'luxon'
import Textarea from 'react-textarea-autosize'
import nl2br from 'react-nl2br'
import * as timeago from 'timeago.js'
import { Scrollbars } from 'react-custom-scrollbars'

import { Loading } from 'carbon-components-react'

import { compose } from 'react-apollo'
import CreateComment from '../../hocs/mutations/CreateComment'
import DeleteComment from '../../hocs/mutations/DeleteComment'


import './Comments.scss'
import WithAlertModal from '../../hocs/WithAlertModal'
import { Urls } from '../../utils/constants'
import defaultIcon from '../../assets/images/profile-img-accent.svg'


timeago.register('en-general', (number, index) => {
  return [
    ['just now', 'right now'],
    ['just now', 'in %s seconds'],
    ['a few minutes ago', 'in 1 minute'],
    ['a few minutes ago', 'in %s minutes'],
    ['1 hour ago', 'in 1 hour'],
    ['%s hours ago', 'in %s hours'],
    ['1 day ago', 'in 1 day'],
    ['%s days ago', 'in %s days'],
    ['1 week ago', 'in 1 week'],
    ['%s weeks ago', 'in %s weeks'],
    ['1 month ago', 'in 1 month'],
    ['%s months ago', 'in %s months'],
    ['1 year ago', 'in 1 year'],
    ['%s years ago', 'in %s years']
  ][index]
})

let commentSubscriptionUnsubscribe = null

class Comments extends React.PureComponent {
  state = {
    resourceType: '',
    resourceId: '',
    comment: '',

    submitting: false
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitButton = this.handleSubmitButton.bind(this)
  }

  handleChange(e) {
    this.setState({
      comment: e.target.value
    })
  }

  handleSubmit(e) {
    if (e.which !== 13) {
      return
    }

    if (!!e.shiftKey !== false) {
      return
    }

    if (this.state.comment === '') {
      return
    }

    if (this.state.submitting) {
      return
    }

    this.setState({
      ...this.state,
      submitting: true
    })

    this.props
      .createComment({
        variables: {
          projectId: this.props.projectId,
          resourceId: this.state.resourceId,
          resourceType: this.state.resourceType,
          message: this.state.comment,
          checklist: []
        },
        update: (cache, data) => {
          this.props.onCreateComment(cache, data)

          if (this.refs.scrollbars) {
            this.refs.scrollbars.scrollToBottom()
          }
        }
      })
      .then(() => {
        this.setState({
          ...this.state,
          comment: '',
          submitting: false
        })
        this.props.refetch()
      })
      .catch(() => {
        this.setState({
          ...this.state,
          submitting: false
        })

        this.props.alert('Your comment could not be posted.')
      })
  }
  handleSubmitButton(e) {
    if (e.which === undefined) { // eslint-disable-line 

      this.setState({
        ...this.state,
        submitting: true
      })

      this.props
        .createComment({
          variables: {
            projectId: this.props.projectId,
            resourceId: this.state.resourceId,
            resourceType: this.state.resourceType,
            message: this.state.comment,
            checklist: []
          },
          update: (cache, data) => {
            this.props.onCreateComment(cache, data)

            if (this.refs.scrollbars) {
              this.refs.scrollbars.scrollToBottom()
            }
          }
        })
        .then(() => {
          this.setState({
            ...this.state,
            comment: '',
            submitting: false
          })
          this.props.refetch()
        })
        .catch(() => {
          this.setState({
            ...this.state,
            submitting: false
          })

          this.props.alert('Your comment could not be posted.')
        })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.resourceType === nextProps.resourceType &&
      prevState.resourceId === nextProps.resourceId
    ) {
      return null
    }

    if (commentSubscriptionUnsubscribe) {
      commentSubscriptionUnsubscribe()

      commentSubscriptionUnsubscribe = null
    }

    return {
      resourceType: nextProps.resourceType,
      resourceId: nextProps.resourceId,
      comment: ''
    }
  }

  componentDidMount() {
    commentSubscriptionUnsubscribe = this.props.subscribeToNewComments()
  }

  componentWillUnmount() {
    if (commentSubscriptionUnsubscribe) {
      commentSubscriptionUnsubscribe()
    }

    commentSubscriptionUnsubscribe = null
  }

  componentDidUpdate(prevProps) {
    if (this.refs.scrollbars) {
      this.refs.scrollbars.scrollToBottom()
    }

    this.textarea.focus()

    if (
      prevProps.resourceType !== this.props.resourceType ||
      prevProps.resourceId !== this.props.resourceId
    ) {
      commentSubscriptionUnsubscribe = this.props.subscribeToNewComments()
    }
  }

  renderScrollbarThumb({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: `#B3B3B3`,
      borderRadius: '0px',
      opacity: '.5'
    }

    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  render() {
    const arrowIconPath = 'M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z'
    
    return (
      <div className='Comments flyoutPane'>
        <div className='Comments__title'>
          <div className='Comments__title__for'>
            <span className='Comments__title__heading'>
              Comments for
            </span>
            <span className='Comments__title__subheading'>
              {this.props.objectType}{' '}
              {this.props.resourceType !== 'project' ? ` on ${this.props.projectName}` : ''}
              {this.props.resourceName}
            </span>
          </div>
          <button onClick={this.props.hideComments} className='Comments__close'>
            <svg width='20' height='14' viewBox='0 0 20 14' fill='none'>
              <path d={arrowIconPath} fill='#D5743E'/>
            </svg>
          </button>
        </div>
        <div className='Comments__list'>
          {this.props.loading && (
            <p key='comments-loading'>
              <Loading withOverlay={false} className='bx--loading--center' />
            </p>
          )}
          {!this.props.loading && this.props.comments.length > 0 && (
            <Scrollbars
              style={{ height: '100%' }}
              renderThumbHorizontal={this.renderScrollbarThumb}
              renderThumbVertical={this.renderScrollbarThumb}
              ref='scrollbars'
            >
              <CommentList 
                comments={this.props.comments} 
                deleteComment={this.props.deleteComment} 
                refetch={this.props.refetch} 
                alert={this.props.alert} 
                userInfo={this.props.userInfo} 
                projectOwner={this.props.projectOwner}/>
            </Scrollbars>
          )}
          {!this.props.loading && this.props.comments.length === 0 && (
            <p key='comments-no-comments'>There are no comments, add one below!</p>
          )}
        </div>
        <div className='Comments__form'>
          <div className='bx--form-item comment-field'>
            <Textarea
              placeholder='Leave a comment'
              id='comment-textarea-auto'
              className='bx--text-area comment-text'
              disabled={this.state.submitting}
              autoFocus
              inputRef={tag => (this.textarea = tag)}
              onChange={this.handleChange}
              onKeyDown={this.handleSubmit}
              value={this.state.comment}
              maxRows={5}
            />
            <button
              type='submit'
              className='bx--btn bx--btn--primary'
              value='return'
              onClick={(e)=>{
                document.getElementById('comment-textarea-auto').focus()
                this.handleSubmitButton(e)
              }
              }
              disabled={this.state.submitting}
            >Send</button>
          </div>
        </div>
      </div>
    )
  }
}

const CommentList = React.memo(props =>
  props.comments.map((comment, i) => {
    return <CommentItem key={'comment-' + i} {...comment} deleteComment={props.deleteComment} refetch={props.refetch} alert={props.alert} userInfo={props.userInfo} projectOwner={props.projectOwner}/> // eslint-disable-line max-len
  })
)

class CommentItem extends React.PureComponent {
  state = {}

  constructor(props) {
    super(props)

    this.timer = null
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({
        ...this.state,
        timeFormatted: timeago.format(this.state.time)
      })
    }, 60000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.createdAt === prevState.createdAt) {
      return null
    }

    const time = DateTime.fromSQL(nextProps.createdAt, { zone: 'utc' }).toMillis()

    return {
      createdAt: nextProps.createdAt,
      time,
      timeFormatted: timeago.format(time)
    }
  }

  updateTimeAgo() {
    this.setState({
      ...this.state,
      timeFormatted: timeago.format(this.state.time)
    })
  }
  async deleteComments(id) {
    await this.props.deleteComment({
      variables: {
        input: {
          id: id
        }
      }
    })
    this.props.refetch()
  }

  render() {
    const { alert } = this.props
    let userAvatar = this.props.user.image ? `${Urls.profileImageUrl}${this.props.user.image}`
      : defaultIcon

    const projectOwner = this.props.projectOwner
    const userId = this.props.userInfo
    const commenterId = this.props.user.id

    let deleteIconPath = 'M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z' // eslint-disable-line max-len

    return (
      <div className={`Comments__comment`} key={this.props.id}>
        <div className='Comments__comment__meta'>
          <span className='commenterIcon'>
            <img src={userAvatar} height={20} alt='userAvatar'/>
          </span>
          <span className='commenterName'>
            {this.props.user.firstName} {this.props.user.lastName}
          </span>
          <span className='commentDate'>
            {this.state.timeFormatted}
          </span>
          {
            (userId === commenterId || userId === projectOwner) &&
          <span className='trashbutton'>
            <button type='button' onClick={()=> {
              alert(
                'Do you really want to delete this comment?',
                {
                  type: 'commentDelete',
                  buttonText: 'Yes',
                  submitCallback: (close) => {
                    this.deleteComments(this.props.id)
                    this.props.refetch()
                    close()
                  }
                }
              )  
            }
            }>
              <svg width='14' height='18' viewBox='0 0 14 18' fill='none'>
                <path d={deleteIconPath} fill='#B3B3B3'/>
              </svg>
            </button>
          </span>
          }
        </div>
        <p>{nl2br(this.props.message)}</p>
      </div>
    )
  }
}

export default compose(
  CreateComment,
  DeleteComment,
  WithAlertModal,
)(Comments)

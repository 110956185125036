import React from 'react'
import { Modal } from 'carbon-components-react'

const AlertModal = props => {
  const { alertOpen, modalHeading, onRequestClose, handleSubmit, message } = props
  return (
    <Modal
      className='modal--no-secondary credits-alert'
      open={alertOpen}
      modalHeading={modalHeading}
      onRequestClose={
        onRequestClose
      }
      primaryButtonText='Close'
      onRequestSubmit={handleSubmit}
    >
      <p>{message && message}</p>
    </Modal>
  )
}

export default AlertModal

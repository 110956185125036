import React from 'react'
import { withRouter } from 'react-router'

import CommentsProvider from '../contexts/Comments'

// Our Base Components
import UploadsProgress from '../components/UploadsActivity/UploadsProgress'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'

class Base extends React.Component {
  state = {
    newOpen: false,
    menuOpen: false,
    location: ''
  }

  handleNewToggle = () => {
    this.setState({
      ...this.state,
      newOpen: !this.state.newOpen
    })
  }

  handleMenuToggle = () => {
    this.setState({
      ...this.state,
      menuOpen: !this.state.menuOpen
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname === prevState.location) {
      return null
    }

    return {
      newOpen: false,
      menuOpen: window.innerWidth >= 1024 ? prevState.menuOpen : false,
      location: nextProps.location.pathname
    }
  }

  render() {
    const { pathname } = this.props.history.location
    let showSidebar = true
    let noSidebarPage = ['send', 'public-share', 'public-playlist']
    noSidebarPage.map((pageUrl)=>{
      if (pathname.toLowerCase().split('/').includes(pageUrl)) {
        showSidebar = false
      }
      return null
    })
    return (
      <div className='appBody'>
        <Header menuOpen={this.state.menuOpen}
          handleMenuToggle={this.handleMenuToggle} />
        {/* <DeviceNotice text='Wherever you see this symbol, use your finger to scroll the content.' />*/}

        { showSidebar && <Sidebar newOpen={this.state.newOpen}
          menuOpen={this.state.menuOpen}
          handleMenuToggle={this.handleMenuToggle}
          handleNewToggle={this.handleNewToggle} />
        }

        <CommentsProvider>
          {this.props.children}
        </CommentsProvider>

        <UploadsProgress />
      </div>
    )
  }
}

export default withRouter(Base)

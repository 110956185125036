import React from 'react'
import Helmet from 'react-helmet-async'
import { Query, compose, graphql } from 'react-apollo'
import nl2br from 'react-nl2br'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import GetSession from '../../hocs/queries/GetSession'

import { GET_ALL_SESSION_CREDITS, GET_SESSION } from '../../graphql/queries'

import WithComments from '../../hocs/WithComments'
import WithCheckInCodeModal from '../../hocs/WithCheckInCodeModal'

import { getUserFavouritesByType } from '../../utils/selectors'
import * as permissions from '../../utils/permissions'

import CreateSessionRecording from '../../hocs/mutations/CreateSessionRecording'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import WithAlertModal from '../../hocs/WithAlertModal'
import { DELETE_SESSION_RECORDING } from '../../graphql/mutations'
import Hero from '../../components/Hero'
import DropdownMenu from '../../components/Dropdowns/DropdownMenu'
import upload from '../../assets/session/session-recording.svg'
import SessionCreditTable from '../../components/SessionCreditTable'
import { withRouter } from 'react-router'
import SessionRecordingTable from '../../components/SessionRecordingTable'
import EmptyTable from '../../components/EmptyTable'
import { GET_RECORDINGS_BY_SESSION } from '../../graphql/queries'
import { filterPermissions, getTags } from '../../utils/permissions'


export const InfoCard = ({ title, content }) => {
  return (
    <div className='infoCard'>
      <h6 className='infoTitle'>{title}</h6>
      <p className='infoContent'>{content}</p>
    </div>
  )
}

class SessionDetail extends React.Component {
  state = {
    searchTerm: '',
    searchRecording: ''
  };

  constructor(props) {
    super(props)

    this.editSession = this.editSession.bind(this)
    this.searchRecordingHandler = this.searchRecordingHandler.bind(this)
    this.assignRecording = this.assignRecording.bind(this)
    this.createRecording = this.createRecording.bind(this)
  }


  getSearchTerm = value => {
    this.setState({
      searchTerm: value
    })
  };


  assignRecording = () => {
    if (this.props.project.locked) {
      // eslint-disable-next-line
      alert("Project Credits are locked. Please unlock to edit.", {
        title: '   ',
        className: 'credits-alert'
      })
      return
    }
    this.props.history.push(
      `/projects/${this.props.project.id}/sessions/${this.props.session.id}/sessions-to-recording`)
  }

  createRecording = () => {
    if (this.props.project.locked) {
      // eslint-disable-next-line
      alert("Project Credits are locked. Please unlock to edit.", {
        title: '   ',
        className: 'credits-alert'
      })
      return
    }

    this.props.history.push({
      pathname: `/projects/${this.props.project.id}/recordings/create`, state: { sessionDetail: true, session: this.props.session } })
  }


  creditDropdownList = [
    {
      name: 'Create Recording',
      icon: upload,
      click: this.createRecording
    },
    {
      name: 'Assign Recordings',
      icon: upload,
      click: this.assignRecording
    }
  ];

  getActions = (() => {
    let actions = []
    if (this.props.refetch) {
      actions.push({
        name: 'restart',
        iconDescription: 'Refresh',
        onClick: () => this.props.refetch()
      })
    }

    if (actions.length === 0) {
      actions = false
    }
    return () => actions
  })();

  editSession() {
    this.props.history.push(`${this.props.location.pathname}/edit`)
  }


  searchRecordingHandler(value) {
    this.setState({
      searchRecording: value
    })
  }

  render() {
    const {
      user,
      history,
      project,
      alert,
      session,
      loadComments,
      checkInCodeModal,
      deleteSessionRecording
    } = this.props

    const isProjectOwner = permissions.isProjectOwner(project.id, user)
    const canSession = permissions.can(project.id, 'session')

    const canUpdate = isProjectOwner || canSession.update

    // note: the credits use the same permissions property as the sessions.
    const canCreateCredit = isProjectOwner || canSession.create
    const canUpdateCredit = isProjectOwner || canSession.update
    const canDeleteCredit = isProjectOwner || canSession.delete

    const favourites = getUserFavouritesByType(user)
    const canSongs = permissions.can(project.id, 'song')


    // const canDelete = isProjectOwner || permissions.canDelete(project.id, 'session')



    let breadCrumbs = [
      {
        href: `/projects`,
        name: 'Projects'
      },
      {
        href: `/projects/${this.props.project.id}`,
        name: this.props.project.name
      },
      {
        href: `/projects/${this.props.project.id}/sessions`,
        name: 'Project Sessions'
      },
      {
        href: `/projects/${this.props.project.id}/sessions/${
          this.props.session.id
        }`,
        name: this.props.session.name
      },
      {
        href: `/projects/${this.props.project.id}/sessions/${
          this.props.session.id
        }`,
        name: 'Session Details'
      }
    ]

    return (
      <>
        <Helmet>
          <title>
            {session.name} | {project.name}
          </title>
        </Helmet>
        <main className='main sessionDetail'>
          {/* Header */}
          <div
            className={` sessionHeader ${
              project.locked ? 'ghosted-wrapper' : ''
            }`}
          >
            <Hero
              subtitle={this.props.session.name}
              actionOnClick={() =>
                loadComments(this.props.project.id, this.props.session.id, 'session')
              }
              commentsPaneText={`${this.props.session.commentCount}`}
              commentsPaneOnClick={() =>
                loadComments(this.props.project.id, this.props.session.id, 'session')
              }

              buttons={[
                {
                  text: 'Generate Check-In Code',
                  onClick: () => {
                    if (project.locked) {
                      alert('Project Credits are locked. Please unlock to edit.', { title: '   ', className: 'credits-alert' })
                      return
                    }
                    checkInCodeModal(session.id)
                  }
                }
              ]}

            >
              <Breadcrumb items={breadCrumbs} />
            </Hero>
            <CreatePagesHeader
              subtitle='Session Details'
              btnText='Edit'
              btnOnClick={canUpdate && this.editSession}
            />
          </div>

          {/* Info Grid  */}
          <div className='mainContainer infoContainer'>
            <SectionContainer>
              <table className='sessionTable'>
                <tr>
                  <td>
                    <InfoCard title='Session Title' content={session.name} />
                  </td>
                  <td>
                    <InfoCard title='Venue' content={session.venue.name} />
                  </td>
                  <td>
                    <InfoCard
                      title='Analog Session'
                      content={session.analogSession ? 'Yes' : 'No'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <InfoCard
                      title='Venue Room'
                      content={session.venueRoom ? session.venueRoom : '-'}
                    />
                  </td>
                  <td className='infoCardGroup'>
                    <InfoCard title='Start' content={session.startedAt} />
                    <InfoCard
                      title='End'
                      content={session.endedAt ? session.endedAt : '-'}
                    />
                  </td>
                  <td>
                    <InfoCard
                      title='Union Session'
                      content={session.unionSession ? 'Yes' : 'No'}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <InfoCard
                      title='Time Code Type'
                      content={
                        session.timecodeType ? session.timecodeType : '-'
                      }
                    />
                  </td>
                  <td className='infoCardGroup'>
                    <InfoCard
                      title='Bit Depth'
                      content={session.bitdepth ? session.bitdepth : '-'}
                    />
                    <InfoCard
                      title='Sample Rate'
                      content={
                        session.samplerate ? `${session.samplerate} Hz` : '-'
                      }
                    />
                  </td>
                  <td>
                    <InfoCard
                      title='Frame Rate'
                      content={
                        session.timecodeFrameRate
                          ? session.timecodeFrameRate
                          : '-'
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <InfoCard
                      title='Description'
                      content={
                        session.description ? nl2br(session.description) : '-'
                      }
                    />
                  </td>
                </tr>
              </table>
            </SectionContainer>
          </div>

          {/* Recordings Table */}
          <div
            className={` sessionRecordings ${
              project.locked ? 'ghosted-wrapper' : ''
            }`}
          >
            <CreatePagesHeader
              tags={!isProjectOwner ? getTags(filterPermissions(canSession)) : []}
              subtitle='Session Recordings'
              showSearch={true}
              getSearchTerm={this.searchRecordingHandler}
              dropDown={
                canUpdate && (
                  <DropdownMenu dropdownList={this.creditDropdownList} />
                )
              }
            />

            <div className='sections-container'>
              <Query
                query={GET_RECORDINGS_BY_SESSION}
                fetchPolicy='cache-and-network'
                variables={{
                  sessionId: session.id
                }}
              >
                {({ loading, error, data, refetch }) => {
                  if (error) {
                    return (
                      <EmptyTable>
                        <p>We encountered an error loading your file shares. Please try again later.</p>
                      </EmptyTable>
                    )
                  }

                  let filteredData = data.getRecordingsBySession ? data.getRecordingsBySession.filter((recording)=> recording.name.toString().includes(this.state.searchRecording)) : []

                  return (
                    <SectionContainer>
                      <div className='project-files'>
                        <SessionRecordingTable
                          nameCellClass='session-recording-table'
                          rows={filteredData || []}
                          locked={project.locked}
                          showSearch={false}
                          projectId={project.id}
                          canUpdate={canUpdate}
                          rowActions={[
                            {
                              itemText: 'Unassign',
                              className: canUpdate ? '' : 'item-ghosted',
                              onClick: row => {
                                alert(
                                  'Are you sure you want to remove this recording from this session?',
                                  {
                                    type: 'confirm',
                                    buttonText: 'Delete',
                                    submitCallback: close => {
                                      deleteSessionRecording({
                                        variables: {
                                          sessionId: session.id,
                                          recordingId: row.id
                                        },

                                        update: (
                                          cache,
                                          { data: { deleteSessionRecording } }
                                        ) => {
                                          const { getSession } = cache.readQuery({
                                            query: GET_SESSION,
                                            variables: {
                                              id: this.props.session.id
                                            }
                                          })
                                          const session = { ...getSession }
                                          session.recordings = [
                                            ...session.recordings
                                          ].filter(
                                            recording =>
                                              recording.id !==
                                            deleteSessionRecording.recordingId
                                          )
                                          cache.writeQuery({
                                            query: GET_SESSION,
                                            variables: {
                                              id: session.id
                                            },
                                            data: {
                                              getSession: session
                                            }
                                          })
                                        }
                                      }).then(()=>{
                                        refetch()
                                      })
                                      close()
                                    }
                                  }
                                )
                              }
                            }
                          ]}
                          pagination={true}
                          favourite
                          favourites={favourites.recording || []}
                          loading={loading}
                          refetch={refetch}
                        />
                      </div>
                    </SectionContainer>
                  )
                }}
              </Query>


            </div>
          </div>

          {/* Credits Table */}
          <div
            className={` sessionCredits ${
              project.locked ? 'ghosted-wrapper' : ''
            }`}
          >
            <CreatePagesHeader
              tags={!isProjectOwner ? getTags(filterPermissions(canSession)) : []}
              id='credits'
              subtitle='Session Credits'
              btnText={canCreateCredit ? 'Add' : false}
              showSearch={true}
              getSearchTerm={this.getSearchTerm}
              btnOnClick={() => {
                if (project.locked) {
                  alert('Project Credits are locked. Please unlock to edit.', {
                    title: '   ',
                    className: 'credits-alert'
                  })
                  return
                }
                history.push(
                  `/projects/${project.id}/sessions/${
                    session.id
                  }/credits/create`
                )
              }}
            />
            <div className='sections-container'>
              <SectionContainer>
                <Query
                  query={GET_ALL_SESSION_CREDITS}
                  variables={{
                    sessionId: session.id
                  }}
                  fetchPolicy='cache-and-network'
                >
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    return (
                      <SessionCreditTable
                        nameCellClass='session-credit-table'
                        rows={
                          data.getCreditsBySession
                            ? data.getCreditsBySession
                            : []
                        }
                        showSearch={false}
                        locked={project.locked}
                        searchTerm={this.state.searchTerm}
                        showSkeleton={loading}
                        projectId={project.id}
                        session={session}
                        favourites={favourites.credit || []}
                        pagination
                        canUpdate={canUpdateCredit}
                        canDelete={canDeleteCredit}
                        onDelete={refetch}
                        actions={true}
                        refetch={refetch}
                        songPermissions={canSongs}
                      />
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>
        </main>

      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetSession,
  WithComments,
  WithCheckInCodeModal,
  WithAlertModal,
  CreateSessionRecording,
  graphql(DELETE_SESSION_RECORDING, { name: 'deleteSessionRecording' }),
  withRouter
)(SessionDetail)

/* eslint-disable no-undefined */
import React from 'react'
import { Form, Modal, TextInput } from 'carbon-components-react'


import Validator from '../utils/Validator'
import SessionValidator from '../validators/Session'
import { compose, graphql } from 'react-apollo'
import ApolloClient from '../utils/Apollo'
import DatePicker from '../components/DatePicker'

import { CREATE_SESSION } from '../graphql/mutations'

import { GET_SESSION_TYPES, GET_VENUES } from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'

import CreateSessionRecording from '../hocs/mutations/CreateSessionRecording'

// Our Base Components
import { SelectASync } from './Select'
import { DateTime } from 'luxon'
import { withRouter } from 'react-router'
import { MapError } from '../utils/FilterError'

const sessionTypeOptions = inputValue =>
  new Promise(resolve => {
    ApolloClient.query({
      query: GET_SESSION_TYPES,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      let sessionTypes = []
      data.getSessionTypes.forEach(type => {
        if (!type.name.toLowerCase().includes(inputValue.toLowerCase())) {
          return
        }
        sessionTypes.push({
          value: type.id,
          label: type.name
        })
      })
      resolve(sessionTypes)
    })
  })

const venueOptions = inputValue =>
  new Promise(resolve => {
    ApolloClient.query({
      query: GET_VENUES,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      let venues = []
      data.getVenues.forEach(venue => {
        if (!venue.name.toLowerCase().includes(inputValue.toLowerCase())) {
          return
        }
        venues.push({
          value: venue.id,
          label: venue.name
        })
      })
      resolve(venues)
    })
  })
  const calendarIcon = 'M16.2 2.31818H15.3V0.5H13.5V2.31818H4.5V0.5H2.7V2.31818H1.8C0.81 2.31818 0 3.13636 0 4.13636V18.6818C0 19.6818 0.81 20.5 1.8 20.5H16.2C17.19 20.5 18 19.6818 18 18.6818V4.13636C18 3.13636 17.19 2.31818 16.2 2.31818ZM16.2 18.6818H1.8V8.68182H16.2V18.6818ZM16.2 6.86364H1.8V4.13636H16.2V6.86364Z' //eslint-disable-line

const initialState = {
  sessionTypeId: '',
  type: {},
  venueId: '',
  venueName: '',
  venue: {},
  startedAt: '',
  name: '',
  loading: false,
  initialErrors: {},
  errors: {},
  createVenueModalOpen: false,
  createRecordingModalOpen: false
}
class CreateNewSessionModal extends Validator {
  state = { ...initialState }
  constructor(props) {
    super(props)
    this.validator = SessionValidator
    this.handleSessionTypeChange = this.handleSessionTypeChange.bind(this)
    this.handleSessionTypeBlur = this.handleSessionTypeBlur.bind(this)
    this.handleVenueChange = this.handleVenueChange.bind(this)
    this.handleVenueBlur = this.handleVenueBlur.bind(this)
    this.handleStartedAtBlur = this.handleStartedAtBlur.bind(this)
    this.handleStartedAtChange = this.handleStartedAtChange.bind(this)
  }

  handleSessionTypeChange(option) {
    this.setState({
      ...this.state,
      sessionTypeId: option.value,
      type: option,
      errors: {
        ...this.state.errors,
        sessionTypeId: undefined
      }
    })
  }

  handleSessionTypeBlur() {
    this.handleDirty({
      target: {
        name: 'sessionTypeId',
        value: this.state.sessionTypeId
      }
    })
  }

  handleVenueChange(option) {
    this.setState({
      ...this.state,
      venue: option,
      venueId: option.value,
      errors: {
        ...this.state.errors,
        venueId: undefined
      }
    })
  }

  handleVenueBlur() {
    this.handleDirty({
      target: {
        name: 'venueId',
        value: this.state.venueId
      }
    })
  }

  handleStartedAtChange(date) {
    this.setState({
      ...this.state,
      startedAt: date,
      errors: {
        ...this.state.errors,
        startedAt: undefined
      }
    })
  }

  handleStartedAtBlur() {
    this.handleDirty({
      target: {
        name: 'startedAt',
        value: this.state.startedAt
      }
    })
  }

  closeNewSessionModal = () =>{
    this.props.onRequestClose()
    this.setState({ ...initialState })
  }

  submit = () => {
    const { sessionTypeId, venueId, name } = this.state
    this.setState({ loading: true })
    this.props.createSession({
      variables: {
        sessionTypeId,
        venueId,
        name,
        projectId: this.props.projectId,
        startedAt: this.state.startedAt
          ? DateTime.fromMillis(this.state.startedAt.getTime()).toFormat(
            'yyyy-LL-dd HH:mm:ss'
          )
          : null
      }
    })
      .then(response => {
        const sessionId = response.data.createSession.id
        if (this.props.recording) {
          this.props
            .createSessionRecording(sessionId, this.props.recording.id)
            .then(() => {
              this.setState({ loading: false })
              this.setState({ ...initialState })
              this.props.onRequestClose()
            })
          return
        }
      })
      .catch(({ message }) => {
        this.setState({
          errors: {
            ...this.state.errors,
            startedAt: MapError(message)
          },
          loading: false
        })
      })
  }
  render() {
    const createButton = this.state.loading ? 'Creating...' : 'Create'
    return (
      <Modal
        className='modal-scrollable create-new-session-modal'
        open={this.props.open}
        modalHeading='Create New Session'
        primaryButtonText={createButton}
        secondaryButtonText='Cancel'
        shouldSubmitOnEnter={true}
        selectorPrimaryFocus='.bx--text-input'
        onRequestSubmit={this.handleSubmit}
        onRequestClose={this.closeNewSessionModal}
      >
        <div className='sections-container'>

          <div className='form new-session-form'>
            <Form autoComplete='off'>

              <div className='formRow'>

                <TextInput
                  id='name'
                  name='name'
                  labelText='Title *'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={this.state.name || ''}
                  invalid={this.state.errors.name ? true : false}
                  invalidText={this.state.errors.name}
                />
              </div>
              <div className='formRow'>
                <SelectASync
                  labelText='Type *'
                  id='sessionTypeId'
                  placeholder='Select from list ...'
                  cacheOptions
                  defaultOptions
                  key={this.state.type.value}
                  loadOptions={sessionTypeOptions}
                  onChange={this.handleSessionTypeChange}
                  onBlur={this.handleSessionTypeBlur}
                  defaultValue={this.state.type.value ? this.state.type : null}
                  invalid={this.state.errors.sessionTypeId ? true : false}
                  invalidText={this.state.errors.sessionTypeId}
                />
              </div>
              <div className='formRow--Four-Column react-datepicker-black with-time'>
                {(this.state.startedAt === '' || this.state.startedAt === null) &&
                      <div className='calendar-placeholder-icon-started'>
                        <svg width='18' height='21' viewBox='0 0 18 21' fill='none'>
                          <path d={calendarIcon} fill='#D5743E'/>
                        </svg>
                      </div> }
                <DatePicker
                  id='startedAt'
                  value={this.state.startedAt || undefined}
                  onChange={this.handleStartedAtChange}
                  onBlur={this.handleStartedAtBlur}
                  labelText='Started At *'
                  showTimeSelect
                  invalid={this.state.errors.startedAt ? true : false}
                  invalidText={this.state.errors.startedAt}
                />

              </div>
              <div className='formRow'>
                <SelectASync
                  key={this.state.venue.value}
                  labelText='Venue *'
                  id='venueId'
                  placeholder='Select from list ...'
                  cacheOptions
                  defaultOptions
                  loadOptions={venueOptions}
                  onChange={this.handleVenueChange}
                  onBlur={this.handleVenueBlur}
                  defaultValue={this.state.venue.value ? this.state.venue : null}
                  invalid={this.state.errors.venueId ? true : false}
                  invalidText={this.state.errors.venueId}
                />
              </div>

            </Form>

          </div>
        </div>

      </Modal>
    )
  }
}

export default compose(
  GetUser,
  withRouter,
  CreateSessionRecording,
  graphql(CREATE_SESSION, { name: 'createSession' })
)(CreateNewSessionModal)

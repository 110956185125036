import React from 'react'
import {
  Form,
  Button,
  TextInput,
  RadioButton,
  RadioButtonGroup
} from 'carbon-components-react'
import { withRouter } from 'react-router'
import Validator from '../utils/Validator'
import PartyValidator from '../validators/Party'
import { compose } from 'react-apollo'
import ApolloClient from '../utils/Apollo'
import { GET_PARTY, GET_PARTY_ARTIST } from '../graphql/queries'
import WithAlertModal from '../hocs/WithAlertModal'


const initialState = {
  type: 'person',
  selectedType: false,
  firstName: '',
  lastName: '',
  addresses: [],
  contacts: [],
  refresh: true,
  initialErrors: {},
  errors: {}
}

export class PartyForm extends Validator {
  state = { ...initialState }
  constructor(props) {
    super(props)
    this.validator = PartyValidator
  }


  onChangeType = (value)=>{
    this.setState({
      type: value
    })
  }

  partyRefetch = async () =>{
    if (this.props.is_my) {
      this.props.partyRefetch()
      return
    }
    const query = this.props.is_my ? GET_PARTY_ARTIST : GET_PARTY
    await ApolloClient.query({
      query: query,
      variables: {
        id: this.props.id
      },
      fetchPolicy: 'network-only'
    })
  }

  clearState = () => {
    this.setState({
      firstName: '',
      lastName: '',
      type: 'person'
    })
  }

  partyMiniModalClose =(e)=> {
    e.preventDefault()
    this.props.onRequestClose()
    this.clearState()
  }

   handleSubmit =async (e) => {
     e.preventDefault()
     const { type, firstName, lastName, contacts, addresses } = this.state
     let obj = {}
     if (this.state.type === 'person') {
       obj = {
         type,
         firstName,
         lastName,
         addresses,
         contacts
       }
     } else {
       obj = {
         type,
         firstName,
         addresses,
         contacts
       }
     }
     await this.props.onSubmit(obj)
   }

   render() {
     const {
       errors,
       type,
       firstName,
       lastName
     } = this.state
     const {
       loading
     } = this.props
     return (
      <>
      <div className='form party-form-custom'>
        <Form id='partyMiniForm' onSubmit={this.handleSubmit} autoComplete='off'>
          <div className='fields-container'>
            <div className='formRow'>
              <div className='party-form'>
                <label className='bx--label'>Type</label>
                <RadioButtonGroup
                  name='radio-button-group'
                  valueSelected={type}
                  onChange={this.onChangeType}
                >
                  <RadioButton
                    id='person'
                    labelText='Person'
                    value='person'
                    className='radio-person'
                  />
                  <RadioButton
                    id='organization'
                    labelText='Organization'
                    value='organisation'
                  />
                </RadioButtonGroup>
              </div>

            </div>

            {type === 'person' && (
            <>
              <div className='formRow'>
                <TextInput
                  id='firstName'
                  name='firstName'
                  labelText='First Name *'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={firstName || ''}
                  invalid={errors.firstName ? true : false}
                  invalidText={errors.firstName}
                />
              </div>

              <div className='formRow'>
                <TextInput
                  id='lastName'
                  name='lastName'
                  labelText='Last Name'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={lastName || ''}
                  invalid={errors.lastName ? true : false}
                  invalidText={errors.lastName}
                />
              </div>
            </>
            )}
            {type !== 'person' && (
              <div className='formRow'>
                <TextInput
                  id='firstName'
                  name='firstName'
                  labelText='Name *'
                  onChange={this.handleChange}
                  onBlur={this.handleDirty}
                  value={firstName || ''}
                  invalid={errors.firstName ? true : false}
                  invalidText={errors.firstName}
                />
              </div>
            )}
          </div>
          <div className='fields-container party-mini-btn'>
            <div className='formRow'>
              <button className='bx--btn bx--btn--secondary' onClick={this.partyMiniModalClose}>
                  Cancel
              </button>
              <Button form='partyMiniForm' type='submit'>
                {loading ? 'Creating...' : 'Create'}
              </Button>
            </div>
          </div>
        </Form>
      </div>
      </>
     )
   }
}

export default compose(
  WithAlertModal,
  withRouter,
)(PartyForm)

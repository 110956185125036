import React from 'react'
import Helmet from 'react-helmet-async'
import { compose, Mutation } from 'react-apollo'

import { UPDATE_CREDIT } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import GetRecording from '../../hocs/queries/GetRecording'
import { GetCreditRefreshed } from '../../hocs/queries/GetCredit'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import CreditForm from '../../components/CreditForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class RecordingCreditEdit extends React.Component {
  render() {
    const { recording } = this.props
    return (
      <>
        <Helmet>
          <title>
            Create Credit | {this.props.project.name} | {this.props.recording.name}
          </title>
        </Helmet>
        <main className='main recordingCreditEdit'>
          <CreatePagesHeader

            title={`Edit Credit of ${this.props.recording.name}`}
            subtitle='Edit Recording Credit'
          >

            <Breadcrumb
              items={[
                {
                  href: `/projects`,
                  name: 'Projects'
                },
                {
                  href: `/projects/${this.props.project.id}`,
                  name: this.props.project.name
                },
                {
                  href: `/projects/${this.props.project.id}/recordings`,
                  name: 'Recordings'
                },
                {
                  href: `/projects/${this.props.project.id}/recordings/${this.props.recording.id}`,
                  name: this.props.recording.name
                },
                {
                  href: `/projects/${this.props.project.id}/recordings/${
                    this.props.recording.id
                  }#credits`,
                  name: 'Recording Credits'
                },
                {
                  href: `/projects/${this.props.project.id}/recordings/${
                    this.props.recording.id
                  }/credits/${this.props.credit.id}`,
                  name: 'Edit Recording Credit'
                }
              ]}
            />

          </CreatePagesHeader>
          <div className='sections-container editRecordingEdit'>
            <SectionContainer>
              <Mutation
                mutation={UPDATE_CREDIT}
                update={() => {
                  this.props.history.push(
                    `/projects/${this.props.project.id}/recordings/${
                      this.props.recording.id
                    }#credits`
                  )
                }}
              >
                {(updateCredit, { loading, error }) => (
                  <CreditForm
                    {...this.props.credit}
                    projectId={this.props.project.id}
                    onSubmit={state => {
                      let data = { ...state }
                      Object.keys(data).forEach(key => data[key] === '' && (data[key] = null))
                      updateCredit({
                        variables: {
                          ...data,
                          creditId: this.props.credit.id,
                          performing: data.performing ? true : false
                        }
                      }).catch(() => {
                        //
                      })
                    }}
                    loading={loading}
                    errors={error}
                    discardForm={true}
                    contributionType='recording'

                    recordingProps={{ labelText: 'Recording *', isDisabled: true, isMulti: false }}
                    isRecordingCredit
                    contribution={recording ? {
                      value: recording.id,
                      label: recording.name,
                      id: recording.id
                    } : ''}
                  />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  GetProject,
  GetRecording,
  GetCreditRefreshed
)(RecordingCreditEdit)

import React from 'react'
import Helmet from 'react-helmet-async'
import { Mutation } from 'react-apollo'

import { CREATE_PROJECT } from '../../graphql/mutations'

import GetUser from '../../hocs/queries/GetUser'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import ProjectForm from '../../components/ProjectForm'
import SectionContainer from '../../components/SectionContainer'
import CreatePagesHeader from '../../components/CreatePagesHeader'

class ProjectCreate extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Create Project</title>
        </Helmet>
        <main className='main'>
            
            <CreatePagesHeader
            title='New Project'
            subtitle='Project Details'
            >
              <Breadcrumb items={[
              {
                href: `/projects`,
                name: 'Projects'
              },
              {
                href: `/projects/create`,
                name: 'New Project'
              }
            ]} />
            </CreatePagesHeader>

          <div className='sections-container'>
            <SectionContainer>
              <Mutation
                mutation={CREATE_PROJECT}
                update={(cache, { data: { createProject } }) => {
                  this.props.history.push(`/projects/${createProject.id}`)
                }}>
                {(createProject, { loading, error }) => (
                  <ProjectForm onSubmit={(state) => {
                    createProject({
                      variables: state
                    }).catch(() => {
                      //
                    })
                  }}
                  loading={loading}
                  errors={error} />
                )}
              </Mutation>
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default GetUser(ProjectCreate)

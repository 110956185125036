import React, { useState, useRef } from 'react'
import upArrow from '../assets/images/up-arrow.svg'
import downArrow from '../assets/images/down-arrow.svg'
import { Query } from 'react-apollo'
import { GET_FILE_PREVIEW, GET_PUBLIC_FILE_PREVIEW } from '../graphql/queries'
import PublicPlaylistWaveform from './PublicPlaylistWaveform'
import crossIcon from '../assets/images/cross.svg'
import { Tooltip } from 'carbon-components-react'

const PublicPlaylistAudioModal = props => {
  const {
    open,
    onRequestClose,
    file,
    uuid,
    fromPlaylist,
    filePlayingIdHandler,
    fileSelectedHandler,
    playing,
    fileSelectedId,
    setFile
  } = props

  const getSongIndex = (selectedId) => {
    if (selectedId === null) {
      return 0
    }
    let fileIndex = file.findIndex((obj)=> {
      return obj.id === selectedId
    })
    return fileIndex === -1 ? 0 : fileIndex
  }
  const [showWaveform, setShowWaveform] = useState(JSON.parse(sessionStorage.getItem('show-wave-form')) || false)
  const audioPlayer = useRef()
  const timeline = useRef()
  const [currentTime, setCurrentTime] = useState('00:00')
  const [totalTime, setTotalTime] = useState('00:00')

  const changeAudioPlayer = () => {
    setShowWaveform(cur => {
      const newState = !cur
      sessionStorage.setItem('show-wave-form', newState)
      return newState
    })
    filePlayingIdHandler({ id: file[getSongIndex(fileSelectedId)].id })
  }

  const playAudio = () => {
    audioPlayer.current.play()
    filePlayingIdHandler({ id: file[getSongIndex(fileSelectedId)].id })
    fileSelectedHandler({ id: file[getSongIndex(fileSelectedId)].id })
  }
  const pauseAudio = () => {
    audioPlayer.current.pause()
    filePlayingIdHandler({ id: null })
    fileSelectedHandler({ id: file[getSongIndex(fileSelectedId)].id })
  }
  const onSkipBackward = () => {
    audioPlayer.current.currentTime = audioPlayer.current.currentTime - 10
  }
  const onSkipForward = () => {
    audioPlayer.current.currentTime = audioPlayer.current.currentTime + 10
  }
  let query = GET_FILE_PREVIEW
  let variables = file && file.length ? fileSelectedId : file && file.id
  if (fromPlaylist) {
    query = GET_PUBLIC_FILE_PREVIEW
    variables = {
      id: parseInt(file && file.length ? fileSelectedId : file && file.id, 10),
      uuid
    }
  }

  const strPadLeft = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length)
  }
  const timeUpdate = event => {
    const minutes = Math.floor(event.target.currentTime / 60)
    const seconds = Math.floor(event.target.currentTime - minutes * 60)
    const Time =
      strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2)

    const percentagePosition =
      (100 * event.target.currentTime) / event.target.duration
    timeline.current.style.backgroundSize = `${percentagePosition}% 100%`
    timeline.current.value = percentagePosition
    setCurrentTime(Time)
  }

  const getTotalAudioTime = timeSec => {
    const minutes = Math.floor(timeSec / 60)
    const seconds = Math.floor(timeSec - minutes * 60)
    return strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2)
  }

  const durationUpdate = event => {
    setTotalTime(getTotalAudioTime(event.target.duration))
  }

  const dateTransform = createdAt => {
    let date = new Date(createdAt)
    return (
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    )
  }

  const changeSeek = () => {
    let time = (timeline.current.value * audioPlayer.current.duration) / 100
    if (isNaN(time)) {
      time = 0.1
    }

    audioPlayer.current.currentTime = time
    if (time < audioPlayer.current.duration && playing) {
      audioPlayer.current.play()
    }
  }


  (() => {
    let element = null
    if (audioPlayer.current) {
      element = audioPlayer.current
      element.addEventListener('ended', () => {
        if (getSongIndex(fileSelectedId) !== file.length - 1) {
          filePlayingIdHandler({ id: file[getSongIndex(fileSelectedId) + 1].id })
          fileSelectedHandler({ id: file[getSongIndex(fileSelectedId) + 1].id })
        } else {
          filePlayingIdHandler({ id: null })
          fileSelectedHandler({ id: null })
          setFile(null)
        }
      })
      element.addEventListener('play', ()=>{
        if (file && file.length > 0) {
          filePlayingIdHandler({ id: fileSelectedId })
        }
      })
      // element.addEventListener('pause', ()=>{
      //   filePlayingIdHandler({ id: null })
      // })
    }
  })()

  if (!playing && audioPlayer.current) {
    // audioPlayer.current.pause()
  }

  if (playing && audioPlayer.current && (fileSelectedId !== file[file.length - 1].id || audioPlayer.current.currentTime < audioPlayer.current.duration)) {
    audioPlayer.current.play()
  }

  const singleFile = file && file.length ? file[getSongIndex(fileSelectedId)] : file
  return (
    <>
      {open && file && (
        <div className='audio-preview-modal-container'>
          <div
            className={`audio-preview-modal ${showWaveform &&
              'audio-preview-modal-waveform'}`}
          >
            {!showWaveform && file && (
              <div className='publicPlaylistModal'>
                <div className='audio-player-section'>
                  <div className='cross-icon-container'>
                    <img
                      src={crossIcon}
                      alt='Show Waveform'
                      onClick={onRequestClose}
                      className='cross-icon'
                    />
                  </div>
                  <div className='show-hide'>
                    <img
                      src={showWaveform ? downArrow : upArrow}
                      alt='Show Waveform'
                      onClick={changeAudioPlayer}
                      className='show-hide-toggle'
                    />
                  </div>
                  {open && file && (
                    <Query
                      query={query}
                      variables={variables}
                      fetchPolicy='network-only'
                    >
                      {({ data, loading, error }) => {
                        if (loading) {
                          return <p>Loading</p>
                        }
                        if (error) {
                          return (
                            <div className='error-message'>{error.message}</div>
                          )
                        }
                        return (
                          <>
                            {!showWaveform && (
                              <div className='audio-wrapper'>
                                <div className='audio-controls-wrapper'>
                                  <span
                                    className='icon rewind-icon'
                                    onClick={onSkipBackward}
                                  />
                                  <span
                                    className={`icon ${
                                      playing ? 'pause-icon' : 'play-icon'
                                    }`}
                                    onClick={
                                      playing
                                        ? () => pauseAudio(file)
                                        : () => playAudio(file)
                                    }
                                  />
                                  <span
                                    className='icon forward-icon'
                                    onClick={onSkipForward}
                                  />
                                </div>
                                <div>
                                  {data.getFilePublicPreview.name && data.getFilePublicPreview.name.length > 16 ? (
                                    <Tooltip
                                      className='playlist-tooltip'
                                      direction='top'
                                      tabIndex={0}
                                      showIcon={false}
                                      triggerText={<p className='song-title trigger-text'>{data.getFilePublicPreview.name}</p>}
                                    >
                                      <p className='song-title'>{data.getFilePublicPreview.name}</p>
                                    </Tooltip>
                                  ) : (
                                    <p className='song-title'>{data.getFilePublicPreview.name}</p>
                                  )}
                                </div>
                                <div className='audio-player-container'>
                                  <div className='song-time'>
                                    <p className='song-time-played'>
                                      {currentTime}
                                    </p>
                                    <p>{totalTime}</p>
                                  </div>

                                  {!showWaveform && (
                                    <>
                                      <div className='timelineContainer'>
                                        <input
                                          type='range'
                                          className='timeline'
                                          max='100'
                                          min='0'
                                          defaultValue='0'
                                          ref={timeline}
                                          onChange={changeSeek}
                                        />
                                      </div>
                                      <audio
                                        className='audio-player'
                                        src={data.getFilePublicPreview.url}
                                        controls
                                        ref={audioPlayer}
                                        autoPlay
                                        onTimeUpdate={timeUpdate}
                                        onDurationChange={durationUpdate}
                                        controlsList='nodownload noplaybackrate'
                                      />
                                    </>
                                  )}

                                  <div className='song-date'>
                                    <p>{file[getSongIndex(fileSelectedId)].createdAt && dateTransform(file[getSongIndex(fileSelectedId)].createdAt)}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )
                      }}
                    </Query>
                  )}
                </div>
              </div>
            )}
          </div>
          {showWaveform && file && (
            <div className='waveform-public-playlist'>
              <div className='waveform-preview-modal'>
                <div className='publicPlaylistModal'>
                  <div className='cross-icon-container'>
                    <img
                      src={crossIcon}
                      alt='Show Waveform'
                      onClick={onRequestClose}
                      className='cross-icon'
                    />
                  </div>
                  <div className='show-hide'>
                    <img
                      src={showWaveform ? downArrow : upArrow}
                      alt='Show Waveform'
                      onClick={changeAudioPlayer}
                      className='show-hide-toggle'
                    />
                  </div>
                  {showWaveform && (
                    <Query
                      query={query}
                      variables={variables}
                      fetchPolicy='network-only'
                    >
                      {({ data, loading }) => {
                        if (loading) {
                          return <p>Loading</p>
                        }

                        return (
                          <>
                            <PublicPlaylistWaveform
                              url={
                                data.getFilePublicPreview
                                  ? data.getFilePublicPreview.url
                                  : ''
                              }
                              name={
                                data.getFilePublicPreview
                                  ? data.getFilePublicPreview.name
                                  : ''
                              }
                              type={singleFile ? singleFile.type : ''}
                              filePlayingIdHandler={filePlayingIdHandler}
                              fileSelectedHandler={fileSelectedHandler}
                              id={singleFile.id}
                              files={file}
                              prettySize={singleFile.prettySize}
                              playing={playing}
                              date={dateTransform(singleFile.createdAt)}
                              getSongIndex={getSongIndex}
                              fileSelectedId={fileSelectedId}
                              setFile={setFile}
                            />
                          </>
                        )
                      }}
                    </Query>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default PublicPlaylistAudioModal

import { Loading } from 'carbon-components-react'
import React from 'react'
import trashIcon from '../assets/images/trash-white.svg'

function DeleteFileIcon({ onClick, className, style, loading }) {
  return (
    <div className='action-container'>
      {loading ? (
        <Loading withOverlay={false} small className='action-btn-loading' />
      ) : (
        <img
          src={trashIcon}
          alt=''
          role='button'
          style={style}
          className={'dpi-action ' + className}
          onClick={onClick}
          title='Remove'
        />
      )}
    </div>
  )
}

export default DeleteFileIcon

import React from 'react'
import { withRouter } from 'react-router'
import { compose, graphql } from 'react-apollo'

import ResponsiveTable from './ResponsiveTable'
import { DELETE_PARTY } from '../graphql/mutations'
import WithAlertModal from '../hocs/WithAlertModal'
import DuplicateFiles from '../hocs/mutations/DuplicateFiles'
import { GET_SHARES } from '../graphql/queries/share'
import ApolloClient from '../utils/Apollo'
import { DateTime } from 'luxon'
import DeleteFiles from '../hocs/mutations/DeleteFiles'

import GetUser from '../hocs/queries/GetUser'

class PartyTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.getRowActions = this.getRowActions.bind(this)
    this.generateLink = this.generateLink.bind(this)
    this.getActions = this.getActions.bind(this)
    this.getBatchActions = this.getBatchActions.bind(this)
  }

  headers = [
    {
      key: 'name',
      header: 'Party',
      formatter: (data, row) => (row.is_my === true && row.userId === this.props.user.id ? `${row.name } (My Party)` : row.name)
    },
    {
      key: 'type',
      header: 'Type',
      formatter: (type) => (type === 'organisation' ? 'Organization' : 'Person')
    },
    {
      key: 'isni',
      header: 'ISNI',
      formatter: (data, row) => (row.isni ? row.isni : '-')
    }
  ]


  expiresFormatter = (expirationDate) => {
    if (!expirationDate) {
      return 'Never'
    }
    if (new Date(expirationDate) < new Date()) {
      return 'Expired'
    }
    return DateTime.fromSQL(expirationDate, { zone: 'utc' }).toISODate()
  }
  getShares = async ()=>{
    const { data } = await ApolloClient.query({
      query: GET_SHARES,
      fetchPolicy: 'network-only'
    })
    const { getShares } = data
    const allShares = []
    getShares.forEach(item =>{
      if (this.expiresFormatter(item.expiresAt) !== 'Expired') {
        allShares.push(item)
      }
    })
    return allShares
  }

  getBatchActions = (() => {
    let actions = []
    if (this.props.canDelete) {
      actions.push({
        name: 'trash',
        iconDescription: 'Delete',
        onClick: async (selectedRows, rowsById, onCancel) => {
          let error = false
          let rows = []
          if (error) {
            onCancel()
            return
          }
          selectedRows.map(selectedRow => {
            const row = rowsById[selectedRow.id]
            rows.push(row)
            if (row.is_my) {
              this.props.alert('You can\'t delete your Party')
            } else {
              this.props.alert('Are you sure you want to permanently delete this party?', {
                type: 'delete',
                buttonText: 'Yes',
                submitCallback: close => {
                  this.props.deleteParty({
                    variables: {
                      partyId: row.id
                    }
                  }).then(() => {
                    this.props.alert('The party has been successfully deleted.')
                    this.props.onDelete()
                  })
                    .catch(() => {
                      this.props.alert('This party could not be deleted.')
                    })
                  close()
                }
              })
            }
            return selectedRow
          })
        }
      })
    }

    if (actions.length === 0) {
      actions = false
    }

    return () => actions
  })()
  getActions = (() => {
    let actions = []
    if (this.props.refetch) {
      actions.push({
        name: 'restart',
        iconDescription: 'Refresh',
        onClick: () => this.props.onDelete()
      })
    }
    if (actions.length === 0) {
      actions = false
    }

    return () => actions
  })()


  getRowActions = (() => {
    const actions = [
      {
        itemText: 'View',
        onClick: (row) =>
          this.props.history.push(`/parties/${row.id}`)
      },
      {
        itemText: 'Edit',
        canBeApplied: (row) => row.userId === this.props.userId,
        onClick: (row) =>
          this.props.history.push(`/parties/${row.id}/edit`)
      }
    ]
    actions.push({
      itemText: 'Delete',
      onClick: row => {
        if (row.is_my) {
          this.props.alert('You can\'t delete your Party')
        } else {
          this.props.alert('Are you sure you want to permanently delete this party?', {
            type: 'delete',
            buttonText: 'Yes',
            submitCallback: close => {
              this.props.deleteParty({
                variables: {
                  partyId: row.id
                },
                update: this.props.onDelete
              }).then(() => {
                this.props.alert('The party has been successfully deleted.')
              })
                .catch(() => {
                  this.props.alert('This party could not be deleted.')
                })
              close()
            }
          })
        }
      }
    })
    return () => actions
  })()

  generateLink(row) {
    if (row.is_my && row.userId === this.props.user.id) {
      return '/account/myParty'
    }
    return `/parties/${row.id}`
  }

  render() {
    return (
      <ResponsiveTable headers={this.headers}
        {...this.props}
        linkColumn='name'
        actions={this.props.actions && this.getActions()}
        batchActions={this.props.batchActions && this.getBatchActions()}
        generateLink={this.generateLink}
        rowActions={this.getRowActions()}
        emptyText='No Parties'
      />
    )
  }
}


export default compose(
  DeleteFiles,
  DuplicateFiles,
  withRouter,
  WithAlertModal,
  graphql(DELETE_PARTY, { name: 'deleteParty' }),
  GetUser
)(PartyTable)

import React from 'react'
import { Query } from 'react-apollo'
import { GET_PROJECT } from '../../graphql/queries'

const GetProject = (WrappedComponent, fetchPolicy = 'cache-first') => {
  return class extends React.PureComponent {
    render() {
      return (
        <Query query={GET_PROJECT}
          variables={{ id: this.props.match.params.projectId }}
          fetchPolicy={fetchPolicy}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return null
            }

            if (error) {
              return `Error! ${error.message}`
            }

            if (data.getProject === null) {
              return <h1 style={{color: 'white', textAlign: 'center'}}>404</h1>
            }

            return (
              <WrappedComponent {...this.props} refetchProject={refetch} project={data.getProject} />
            )
          }}
        </Query>
      )
    }
  }
}

export default GetProject

export const GetProjectRefreshed = (WrappedComponent) => {
  return GetProject(WrappedComponent, 'network-only')
}

import React from 'react'
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import { withApollo } from 'react-apollo'

import {
  GET_PROJECT_ROOT_FILES,
  GET_PROJECT_FOLDER,
  GET_USER_ROOT_FILES
} from '../graphql/queries'
import { refreshToken } from '../utils/Apollo'
import { debounced } from '../utils/functions'

export const UploadsContext = React.createContext(null)
export const UploadsConsumer = UploadsContext.Consumer
export class UploadsProvider extends React.Component {
  initialState = {
    uploads: {},
    uploadIds: [],
    inProgressCount: 0,
    errorCount: 0,
    status: null,

    projectId: null,
    folderId: null,

    upgradeRequired: false,
    uploadModal: true
  }

  constructor(props) {
    super(props)

    this.state = { ...this.initialState }
    this.state.uploadModal = true


    this.uppy = Uppy({
      autoProceed: true,
      onBeforeFileAdded: currentFile => {
        if (currentFile.data.size === 0) {
          return false
        }

        if (currentFile.name.indexOf('.DS_Store') > -1) {
          return false
        }

        if (this.state.uploads[currentFile.id]) {
          return false
        }

        return true
      }
    })

    this.setupUppy()

    this.getUppy = this.getUppy.bind(this)
    this.removeUpload = this.removeUpload.bind(this)
    this.setProjectIdAndFolderId = this.setProjectIdAndFolderId.bind(this)
    this.setUpgradeRequiredFlag = this.setUpgradeRequiredFlag.bind(this)
    this.updateFileListing = debounced(1000, this.updateFileListing.bind(this))
    this.clearUploads = this.clearUploads.bind(this)
    this.onBeforeUnload = this.onBeforeUnload.bind(this)
    this.openModal = this.openModal.bind(this)

    window.addEventListener('beforeunload', this.onBeforeUnload)
  }

  onBeforeUnload(e) {
    if (this.state.inProgressCount === 0) {
      return
    }

    e.preventDefault()
    e.returnValue = ''
  }

  getUppy() {
    return this.uppy
  }

  removeUpload(id) {
    if (this.state.uploads[id].uploading === true) {
      this.uppy.removeFile(id)
    }

    this.removeFileFromList(id)
  }

  openModal() {
    this.setState({ uploadModal: true })
  }
  clearUploads() {
    this.uppy.cancelAll()
    this.setState({
      ...this.initialState,
      uploadModal: false
    })
  }

  removeFileFromList(id) {
    let uploads = { ...this.state.uploads }
    let uploadIds = [ ...this.state.uploadIds ]

    const isInProgress = this.state.uploads[id].uploading
    const hasError = this.state.uploads[id].error

    delete uploads[id]
    delete uploadIds[uploadIds.indexOf(id)]

    uploadIds = uploadIds.filter(uploadId => typeof uploadId !== 'undefined')

    this.setState({
      uploads,
      uploadIds,
      inProgressCount: (isInProgress ? this.state.inProgressCount - 1 : this.state.inProgressCount),
      errorCount: (hasError ? this.state.errorCount - 1 : this.state.errorCount)
    })
  }

  setProjectIdAndFolderId(projectId, folderId, batchId) {
    this.uppy.setMeta({
      projectId,
      folderId,
      batchId
    })

    this.setState({
      ...this.state,
      projectId: (projectId ? parseInt(projectId, 10) : null),
      folderId: (folderId ? parseInt(folderId, 10) : null),
      batchId: batchId
    })
  }

  updateFileListing() {
    let query = (this.state.folderId ? GET_PROJECT_FOLDER : GET_USER_ROOT_FILES)

    if (this.state.projectId) {
      query = (this.state.folderId ? GET_PROJECT_FOLDER : GET_PROJECT_ROOT_FILES)
    }

    this.props.client.query({
      query,
      variables: {
        projectId: this.state.projectId,
        folderId: this.state.folderId
      },
      fetchPolicy: 'network-only'
    })
  }

  setUpgradeRequiredFlag(upgradeRequired) {
    this.setState({
      ...this.state,
      upgradeRequired
    })
  }

  createMultipartUpload(file, resolve, reject) {
    return fetch(`${process.env.REACT_APP_API}/api/multipart-uploads/create`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      },
      body: JSON.stringify(file)
    })
      .then(response => response.json())
      .then(response => {
        if (response.message === 'Unauthenticated.') {
          refreshToken().then(() => this.createMultipartUpload(file, resolve, reject))
          return
        }

        if (response.upgradeRequired) {
          if (this.state.upgradeRequired === true) {
            reject('Upgrade required')
            return
          }

          this.setState({
            ...this.state,
            uploads: {
              ...this.state.uploads,
              [file.id]: {
                ...this.state.uploads[file.id],
                cancelled: true,
                complete: true,
                uploading: false
              }
            },
            upgradeRequired: true,
            inProgressCount: this.state.inProgressCount - 1
          })

          this.uppy.removeFile(file.id)

          // const uploads = { ...this.state.uploads }
          // let uploadIds = [ ...this.state.uploadIds ]

          // uploadIds = uploadIds.filter(uploadId => {
          //   if (uploads[uploadId].uploading === true) {
          //     delete uploads[uploadId]
          //     return false
          //   }

          //   return true
          // })

          // this.setState({
          //   ...this.state,
          //   uploads,
          //   uploadIds
          // })

          // this.uppy.cancelAll()

          reject('Upgrade required')
          return
        }

        if (response.name !== file.key) {
          this.uppy.setFileState(file.id, {
            name: response.name
          })

          this.setState({
            ...this.state,
            uploads: {
              ...this.state.uploads,
              [file.id]: {
                ...this.state.uploads[file.id],
                fileId: response.id,
                folderId: response.folderId,
                projectId: response.projectId,
                name: response.name
              }
            }
          })
        }

        resolve(response)
      })
      .catch(e => reject(e))
      .finally(() => {
        this.updateFileListing()
      })
  }

  prepareUploadPart(file, partData, resolve, reject) {
    return fetch(`${process.env.REACT_APP_API}/api/multipart-uploads/prepare`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      },
      body: JSON.stringify(partData)
    })
      .then(response => response.json())
      .then(response => {
        if (response.message === 'Unauthenticated.') {
          refreshToken().then(() => setTimeout(() => {
            this.prepareUploadPart(file, partData, resolve, reject)
          }, 40))
          return
        }

        resolve(response)
      })
      .catch(e => reject(e))
  }

  listParts(uploadId, key, resolve, reject) {
    return fetch(`${process.env.REACT_APP_API}/api/multipart-uploads/list`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      },
      body: JSON.stringify({
        uploadId,
        key
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.message === 'Unauthenticated.') {
          refreshToken().then(() => this.listParts(uploadId, key, resolve, reject))
          return
        }

        resolve(response)
      })
      .catch(e => reject(e))
  }

  abortMultipartUpload(file, uploadId, key, resolve, reject) {
    const projectId = this.state.uploads[file.id].projectId
    const folderId = this.state.uploads[file.id].folderId

    return fetch(`${process.env.REACT_APP_API}/api/multipart-uploads/abort`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      },
      body: JSON.stringify({
        id: this.state.uploads[file.id].fileId,
        projectId: projectId,
        folderId: folderId,
        uploadId,
        key
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.message === 'Unauthenticated.') {
          refreshToken().then(() => this.abortMultipartUpload(file, uploadId, key, resolve, reject))
          return
        }

        resolve(response)
      })
      .catch(e => reject(e))
      .finally(() => {
        if (projectId === this.state.projectId && folderId === this.state.folderId) {
          this.updateFileListing()
        }
      })
  }

  completeMultipartUpload(file, uploadId, key, parts, resolve, reject) { // eslint-disable-line
    return fetch(`${process.env.REACT_APP_API}/api/multipart-uploads/complete`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      },
      body: JSON.stringify({
        id: this.state.uploads[file.id].fileId,
        projectId: this.state.uploads[file.id].projectId,
        folderId: this.state.uploads[file.id].folderId,
        uploadId,
        key,
        parts
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.message === 'Unauthenticated.') {
          refreshToken().then(() =>
            this.completeMultipartUpload(file, uploadId, key, parts, resolve, reject)
          )
          return
        }

        resolve(response)
      })
      .catch(e => reject(e))
  }

  setupUppy() {
    this.uppy.use(AwsS3Multipart, {
      limit: 10,
      createMultipartUpload: file => {
        return new Promise((resolve, reject) => {
          this.createMultipartUpload(file, resolve, reject)
        })
      },

      prepareUploadPart: (file, partData) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            this.prepareUploadPart(file, partData, resolve, reject)
          }, 40)
        })
      },

      listParts: (file, { uploadId, key }) => {
        return new Promise((resolve, reject) => {
          this.listParts(uploadId, key, resolve, reject)
        })
      },

      abortMultipartUpload: (file, { uploadId, key }) => {
        if (!this.state.uploads[file.id]) {
          return new Promise((resolve) => resolve())
        }

        return new Promise((resolve, reject) => {
          this.abortMultipartUpload(file, uploadId, key, resolve, reject)
        })
      },

      completeMultipartUpload: (file, { uploadId, key, parts }) => {
        return new Promise((resolve, reject) => {
          this.completeMultipartUpload(file, uploadId, key, parts, resolve, reject)
        })
      }
    })

    this.uppy.on('upload', ({ fileIDs }) => {
      let uploads = { ...this.state.uploads }
      let uploadIds = [ ...this.state.uploadIds ]

      fileIDs.forEach(id => {
        const file = this.uppy.getFile(id)

        let path = (file.data.fullPath ? file.data.fullPath : file.data.relativePath)
        let pathexp = (path ? path : '').split('/')
        if (pathexp[pathexp.length - 1] === file.name) {
          delete pathexp[pathexp.length - 1]
          path = pathexp.join('/')
        }

        uploads[id] = {
          id,
          progress: 0,
          name: file.name,
          uploading: true,
          complete: false,
          size: file.size,
          path: (path || '/'),
          location: '',
          type: file.extension ? file.extension : file.type.split('/')[1]
        }

        if (uploadIds.indexOf(id) === -1) {
          uploadIds.push(id)
        }
      })

      this.setState({
        uploads,
        uploadIds,
        inProgressCount: this.state.inProgressCount + fileIDs.length
      })
    })

    this.uppy.on('file-removed', file => {
      if (file.progress.uploadComplete) {
        return
      }
      if (file.s3Multipart && file.s3Multipart.uploadId) {
        this.abortMultipartUpload(
          file,
          file.s3Multipart.uploadId,
          file.s3Multipart.key,
          () => {},
          () => {}
        )
      }
    })

    this.uppy.on('upload-error', (file, error) => {
      this.setState({
        uploads: {
          ...this.state.uploads,
          [file.id]: {
            ...this.state.uploads[file.id],
            uploading: false,
            error: error.message
          }
        },
        inProgressCount: this.state.inProgressCount - 1,
        errorCount: this.state.errorCount + 1
      })

      this.uppy.removeFile(file.id)
    })

    this.uppy.on('upload-success', file => {
      this.setState({
        uploads: {
          ...this.state.uploads,
          [file.id]: {
            ...this.state.uploads[file.id],
            uploading: false,
            complete: true
          }
        },
        inProgressCount: this.state.inProgressCount - 1
      })

      this.uppy.removeFile(file.id)
    })

    this.uppy.on('upload-progress', (file, progress) => {
      const progressPercentage = Math.floor((progress.bytesUploaded / progress.bytesTotal) * 100)

      if (!this.state.uploads[file.id] ||
          progressPercentage === this.state.uploads[file.id].progress) {
        return
      }

      this.setState({
        ...this.state,
        uploads: {
          ...this.state.uploads,
          [file.id]: {
            ...this.state.uploads[file.id],
            progress: progressPercentage
          }
        }
      })
    })

    this.uppy.on('upload-started', () => {
      this.setState({
        status: 'started'
      })
    })
    this.uppy.on('complete', () => {
      this.setState({
        status: 'complete'
      })
    })
  }

  render() {
    return (
      <UploadsContext.Provider value={{
        state: this.state,
        actions: {
          getUppy: this.getUppy,
          removeUpload: this.removeUpload,
          setProjectIdAndFolderId: this.setProjectIdAndFolderId,
          setUpgradeRequiredFlag: this.setUpgradeRequiredFlag,
          clearUploads: this.clearUploads,
          openModal: this.openModal
        }
      }}>
        {this.props.children}
      </UploadsContext.Provider>
    )
  }
}

export const WrappedUploadsProvider = withApollo(UploadsProvider)

export const getUploads = (state) => {
  return state.uploadIds.map(uploadId => state.uploads[uploadId])
}

import React from 'react'
import {
  Form,
  Button,
  TextInput
} from 'carbon-components-react'

import Validator from '../utils/Validator'
import ChangePasswordValidator from '../validators/ChangePassword'
import DiscardModal from './modals/DiscardModal'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'


const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',

  initialErrors: {},
  errors: {},
  discardModal: false
}

class ChangePasswordForm extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.validator = ChangePasswordValidator
    this.openDiscardModal = this.openDiscardModal.bind(this)
    this.closeDiscardModal = this.closeDiscardModal.bind(this)
    this.discardChanges = this.discardChanges.bind(this)
  }

  submit() {
    this.props.onSubmit(this.state, () => {
      this.state = { ...initialState }
    })
  }

  openDiscardModal(e) {
    e.preventDefault()
    if (
      this.state.currentPassword === '' &&
      this.state.newPassword === '' &&
      this.state.confirmNewPassword === ''
    ) {
      this.props.history.goBack()
    } else {
      this.setState({
        discardModal: true
      })
    }
  }

  closeDiscardModal() {
    this.setState({
      discardModal: false
    })
  }

  discardChanges() {
    this.setState(initialState)
  }

  render() {
    return (
      <div className='form'>
        <Form onSubmit={this.handleSubmit} autoComplete='off'>
          <div className='formRow'>
            <TextInput id='currentPassword'
              name='currentPassword'
              labelText='Current Password'
              type='password'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.currentPassword || ''}
              invalid={this.state.errors.currentPassword ? true : false}
              invalidText={this.state.errors.currentPassword || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='newPassword'
              name='newPassword'
              labelText='New Password'
              type='password'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.newPassword || ''}
              invalid={this.state.errors.newPassword ? true : false}
              invalidText={this.state.errors.newPassword || ''} />
          </div>
          <div className='formRow'>
            <TextInput id='confirmNewPassword'
              name='confirmNewPassword'
              labelText='Confirm New Password'
              type='password'
              onChange={this.handleChange}
              onBlur={this.handleDirty}
              value={this.state.confirmNewPassword || ''}
              invalid={this.state.errors.confirmNewPassword ? true : false}
              invalidText={this.state.errors.confirmNewPassword || ''} />
          </div>
          <div className='formRow actionBtnContainer'>
            <button className='bx--btn bx--btn--secondary' onClick={this.openDiscardModal}>
              Cancel
            </button>
            <Button type='submit'>
              {this.props.loading ? 'Updating...' : 'Update' }
            </Button>
          </div>
        </Form>
        {
          this.state.discardModal && <DiscardModal open={this.state.discardModal}
            onRequestClose={this.closeDiscardModal}
            modalHeading='Discard Changes?'
            handleSubmit={this.discardChanges}
          />
        }
      </div>
    )
  }
}

export default compose(
  withRouter
)(ChangePasswordForm)


import React from 'react'
import WrappedUploadModal, { UploadModal } from '../components/UploadModal/UploadModal'

import * as permissions from '../utils/permissions'
import { debounced } from '../utils/functions'


const WithUploadModal = WrappedComponent => {
  return class extends React.PureComponent {
    state = {
      open: false,
      projectName: '',
      projectId: null,
      path: '',
      user: {}
    }

    constructor(props) {
      super(props)

      this.doOpen = this.doOpen.bind(this)
      this.doClose = this.doClose.bind(this)
      this.debouncedRefetch = debounced(2000, this.refetchAPI)
    }


    refetchAPI = (refetch) => {
      if (refetch) {
        refetch()
      }
    };


    doOpen(path, projectName, projectId, folderId, user, refetch) {
      this.setState({
        ...this.state,
        open: true,
        path,
        projectName,
        projectId,
        user
      })

      const batchId = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5)
      this.props.uploads.actions.setProjectIdAndFolderId(projectId, folderId, batchId)

      this.props.uploads.actions.getUppy().on('upload-success', () => {
        if (this.props.uploads.actions.getUppy().getState().totalProgress === 0) {
          if (refetch) {
            this.debouncedRefetch(refetch)
          }
        }
      })


      this.props.uploads.actions.openModal()
    }

    doClose() {
      this.setState({
        ...this.state,
        open: false,
        path: '',
        projectName: ''
      })
    }

    render() {
      const isProjectOwner = permissions.isProjectOwner(this.state.projectId, this.state.user)

      return (
        <>
          <WrappedComponent
            {...this.props}
            uploadModal={{
              open: this.doOpen,
              close: this.doClose
            }}
          />
          {this.props.uploads && (
            <UploadModal
              open={this.state.open}
              onRequestClose={this.doClose}
              uploads={this.props.uploads}
              path={this.state.path}
              projectId={this.state.projectId}
              projectName={this.state.projectName}
              isProjectOwner={isProjectOwner}
            />
          )}
          {!this.props.uploads && (
            <WrappedUploadModal open={this.state.open} onRequestClose={this.doClose} />
          )}
        </>
      )
    }
  }
}

export default WithUploadModal

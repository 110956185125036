import React from 'react'
import { Modal } from 'carbon-components-react'

const ResendVerificationEmailModal = (props) => {
  const { isOpen, handleCancel, handleSubmit, message } = props

  return (
    <Modal
      open={isOpen}
      passiveModal={false}
      onRequestSubmit={handleSubmit}
      onSecondarySubmit={handleCancel}
      primaryButtonText='Verify'
      secondaryButtonText='Cancel'
    >
      <p>{ message }</p>
    </Modal>
  )
}

export default ResendVerificationEmailModal

import React from 'react'
import {
  Form,
  Button,
  TextInput
} from 'carbon-components-react'

import Validator from '../utils/Validator'
import Login2FAValidator from '../validators/Login2FA'

const initialState = {
  code: '',

  initialErrors: {},
  errors: {}
}

export default class extends Validator {
  state = { ...initialState }

  constructor(props) {
    super(props)

    this.validator = Login2FAValidator
  }

  submit() {
    this.props.onSubmit(this.state)
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit} autoComplete='off'>
        <div className='formRow'>
          <TextInput id='code'
            name='code'
            labelText='Enter 2FA Code'
            onChange={this.handleChange}
            onBlur={this.handleDirty}
            value={this.state.code || ''}
            invalid={this.state.errors.code || this.props.error ? true : false}
            invalidText={this.state.errors.code || ''} />
        </div>
        <div className='formRow'>
          <Button type='submit' className='loginSubmit'>
            { this.props.loading ? 'Logging In...' : 'Login' }
          </Button>
        </div>
      </Form>
    )
  }
}

import gql from 'graphql-tag'
import React from 'react'
import { compose, graphql } from 'react-apollo'

const AuthRoute = () => (WrappedComponent) => (props) => (
  <WrappedComponent {...props} />
)

const GET_AUTH = gql`
query authStatus {
  authStatus @client {
    status
  }
}
`

export default compose(graphql(GET_AUTH), AuthRoute())

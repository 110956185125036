import { graphql } from 'react-apollo'
import { ASSIGN_MULTIPLE_RECORDING_SESSION } from '../../graphql/mutations'

export default graphql(ASSIGN_MULTIPLE_RECORDING_SESSION, {
  name: 'assignMultipleRecordingSession',
  props: data => {
    return {
      assignMultipleRecordingSession: (sessionId, recordingIds) =>
        data.assignMultipleRecordingSession({
          variables: {
            sessionId,
            recordingIds
          }
        })
    }
  }
})

import React from 'react'
import { compose, Mutation } from 'react-apollo'

import { GET_PARTY, GET_PARTY_ARTIST } from '../../../graphql/queries'
import { CREATE_PARTY_CONTACT } from '../../../graphql/mutations'

import GetUser from '../../../hocs/queries/GetUser'
import PartyContactForm from '../../../components/PartyContactForm'
import SectionContainer from '../../../components/SectionContainer'
const PartyContactCreate = (props) => (
  <>
      <div className='mainContainer'>
        <SectionContainer>
          <Mutation
            mutation={CREATE_PARTY_CONTACT}
            update={(cache, { data: { createPartyContact } }) => {
              const query = props.party.is_my ? GET_PARTY_ARTIST : GET_PARTY
              const { getParty } = cache.readQuery({
                query: query,
                variables: {
                  id: props.party.id
                }
              })
              if (getParty) {
                const party = { ...getParty }
                party.contacts = [...party.contacts, createPartyContact]
                cache.writeQuery({
                  query: query,
                  variables: {
                    id: props.party.id
                  },
                  data: {
                    getParty: party
                  }
                })
              }
              props.history.push(`/parties/${props.party.id}`)
            }}>
            {(createPartyContact, { loading, error }) => (
              <PartyContactForm
                party={{ ...props.party }}
                close={props.closeModal}
                onSubmit={(state) => {
                  let data = { ...state }
                  const { closeModal, party, onSubmitContact, partyRefetch } = props
                  if (!party.id) {
                    onSubmitContact(state)
                    closeModal()
                    return
                  }
                  data.partyId = party.id
                  Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null))
                  createPartyContact({
                    variables: data
                  }).then(()=>{
                    partyRefetch()
                  }).catch(() => {
                    //
                  })
                  closeModal()
                }}
                loading={loading}
                errors={error} 
                />
            )}
          </Mutation>
        </SectionContainer>
      </div>
  </>
)

export default compose(
  GetUser,
)(PartyContactCreate)

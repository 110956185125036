/* eslint-disable no-nested-ternary */
import React from 'react'
import { Route } from 'react-router-dom'
import Helmet from 'react-helmet-async'
import { compose } from 'react-apollo'
import { DateTime } from 'luxon'
import nl2br from 'react-nl2br'
import { Query } from 'react-apollo'
import {
  GET_PROJECT_FOLDER,
  GET_ALL_RECORDING_SESSIONS,
  GET_ALL_RECORDING_CREDITS,
  GET_RECORDING_COLLABORATORS
} from '../../graphql/queries'
import { NetworkStatus } from '../../utils/Apollo'

import GetUser from '../../hocs/queries/GetUser'
import GetProject from '../../hocs/queries/GetProject'
import GetRecording from '../../hocs/queries/GetRecording'

import WithComments from '../../hocs/WithComments'
import WithUploads from '../../hocs/WithUploads'
import WithUploadModal from '../../hocs/WithUploadModal'


import DeleteSessionRecording from '../../hocs/mutations/DeleteSessionRecording'

import { getUserFavouritesByType } from '../../utils/selectors'
import * as permissions from '../../utils/permissions'

// Our Base Components
import Breadcrumb from '../../components/Breadcrumb'
import FileTable from '../../components/FileTable'
import InfoGrid from '../../components/InfoGrid'
import SectionContainer from '../../components/SectionContainer'

import SessionTable from '../../components/SessionTable'
import CreditTable from '../../components/CreditTable'
import WithAlertModal from '../../hocs/WithAlertModal'
import Hero from '../../components/Hero'
import CreatePagesHeader from '../../components/CreatePagesHeader'
import CollaboratorsTable from '../../components/CollaboratorsTable'
import folderActive from '../../assets/images/folderActive.svg'
import uploadIcon from '../../assets/upload-arrow.svg'
import { WithMoveFilesFrom } from '../../hocs/MoveFiles'
import DropdownMenu from '../../components/Dropdowns/DropdownMenu'
import CreateSession from '../../assets/session/createSession.svg'
import AssignSession from '../../assets/session/folder.svg'
import { getTags, filterPermissions } from '../../utils/permissions'


class RecordingDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filesSearchTerm: '',
      creditsSearchTerm: '',
      sessionsSearchTerm: ''
    }

    this.routeAssignSessions = this.routeAssignSessions.bind(this)
    this.routeCreateSessions = this.routeCreateSessions.bind(this)
  }
  getSearchTerm=(value, name)=>{
    this.setState({
      [name]: value
    })
  }

  routeAssignSessions() {
    if (this.props.project.locked) {
      // eslint-disable-next-line no-alert
      alert('Project Credits are locked. Please unlock to edit.',
        { title: '   ', className: 'credits-alert' })
      return
    }
    this.props.history.push(
      `/projects/${this.props.project.id}/recordings/${
        this.props.recording.id
      }/sessions/assign`
    )
  }

  routeCreateSessions() {
    if (this.props.project.locked) {
      // eslint-disable-next-line no-alert
      alert('Project Credits are locked. Please unlock to edit.',
        { title: '   ', className: 'credits-alert' })
      return
    }
    this.props.history.push({ pathname: `/projects/${this.props.project.id}/sessions/create`,
      state: { recordingDetailPage: true, recording: this.props.recording } })
  }

  render() {
    const {
      project,
      user,
      history,
      location,
      match,
      recording,
      uploads,
      loadComments,
      uploadModal,
      alert
    } = this.props
    const accountType = user.subscriptions[0] ? user.subscriptions[0].stripePlan : ''
    const isProjectOwner = permissions.isProjectOwner(project.id, user)
    const isRecordingCollaborator = permissions.hasRecordingAccess(
      project.id,
      recording.id
    )


    let duration = '-'
    if (recording.duration) {
      const date = new Date(null)
      date.setSeconds(recording.duration)
      duration = date.toISOString().substr(11, 8)
    }

    const favourites = getUserFavouritesByType(user)

    const canSession = permissions.can(project.id, 'session')
    const canSongs = permissions.can(project.id, 'song')
    const canCreateSession = isProjectOwner || isRecordingCollaborator || canSession.create
    const canUpdateSession = isProjectOwner || isRecordingCollaborator || canSession.update
    const canDeleteSession = isProjectOwner || isRecordingCollaborator || canSession.delete

    // note: the credits use the same permissions property as the sessions.
    const canCreateCredit = isProjectOwner || isRecordingCollaborator || canSession.create
    const canUpdateCredit = isProjectOwner || isRecordingCollaborator || canSession.update
    const canDeleteCredit = isProjectOwner || isRecordingCollaborator || canSession.delete

    const canCreateCollaborator =
    isProjectOwner || permissions.canCreate(project.id, 'collaborator')
    const canUpdateCollaborator = !isRecordingCollaborator ?
      isProjectOwner || permissions.canUpdate(project.id, 'collaborator') : false
    const canDeleteCollaborator = !isRecordingCollaborator ?
      isProjectOwner || permissions.canDelete(project.id, 'collaborator') : false

    const sessionPermissions = permissions.can(project.id, 'session')


    let sessionDropdownList = [
      {
        name: 'Create Session',
        icon: CreateSession,
        click: this.routeCreateSessions
      },
      {
        name: 'Assign Sessions',
        icon: AssignSession,
        click: this.routeAssignSessions
      }
    ]


    return (
      <>
        <Helmet>
          <title>
            {recording.name} | {project.name}
          </title>
        </Helmet>
        <main className='main recording-detail'>

          <div className={` ${project.locked ? 'ghosted-wrapper' : ''}`}>
            <Hero
              subtitle={recording.version ? `${recording.name} - ${recording.version}` : recording.name}
              actionOnClick={() =>
                loadComments(project.id, recording.id, 'recording')
              }
              commentsPaneText={`${this.props.recording.commentCount}`}
              commentsPaneOnClick={() =>
                loadComments(this.props.project.id, this.props.session.id, 'session')
              }
            >
              <Breadcrumb
                items={[
                  {
                    href: `/projects/`,
                    name: 'Projects'
                  },
                  {
                    href: `/projects/${project.id}`,
                    name: project.name
                  },
                  {
                    href: `/projects/${project.id}/recordings`,
                    name: 'Project Recordings'
                  },
                  {
                    href: `/projects/${project.id}/recordings/${recording.id}`,
                    name: recording.version ? `${recording.name} - ${recording.version}` : recording.name
                  }
                ]}
              />
            </Hero>

            <CreatePagesHeader
              id='recording-details'
              subtitle='Recording Details'
              showSearch={false}
              name='sessionsSearchTerm'
              getSearchTerm={this.getSearchTerm}
              btnText={isProjectOwner ? 'Edit' : false}
              btnOnClick={() => {
                if (project.locked) {
                  alert('Project Credits are locked. Please unlock to edit.',
                    { title: '   ', className: 'credits-alert' })
                  return
                }
                history.push(
                  `/projects/${project.id}/recordings/${
                    recording.id
                  }/edit`
                )
              }
              }
              tags={!isProjectOwner ? [{ tagName: 'Read' }] : []}
            />
            <div className='mainContainer'>
              <SectionContainer>
                <InfoGrid
                  items={[
                    {
                      title: 'Recording Title',
                      content: recording.name ? recording.name : '-'
                    },
                    {
                      title: 'Type',
                      content: recording.type ? recording.type.name : '-'
                    },
                    {
                      title: 'Version',
                      content: recording.version ? recording.version : '-'
                    },
                    {
                      title: 'ISRC',
                      content: recording.isrc || '-'
                    },
                    {
                      title: 'Subtitle',
                      content: recording.subtitle || '-'
                    },
                    {
                      title: 'Created At',
                      content: recording.createdAt
                    },
                    {
                      title: 'Recorded On',
                      content: recording.recordedOn
                        ? DateTime.fromSQL(recording.recordedOn).toISODate()
                        : '-'
                    },
                    {
                      title: 'Mixed On',
                      content: recording.mixedOn
                        ? DateTime.fromSQL(recording.mixedOn).toISODate()
                        : '-'
                    },
                    {
                      title: 'Duration',
                      content: duration
                    },
                    {
                      title: 'Song',
                      content: recording.song.title || '-'
                    },
                    {
                      title: 'Key Signature',
                      content: recording.keySignature || '-'
                    },
                    {
                      title: 'Time Signature',
                      content: recording.timeSignature || '-'
                    },
                    {
                      title: 'Tempo',
                      content: recording.tempo ? `${recording.tempo} BPM` : '-'
                    },
                    {
                      title: 'Language',
                      content: recording.language
                        ? recording.language.name
                        : '-'
                    },
                    {
                      title: 'Main Artist',
                      content: recording.party ? recording.party.name : '-'
                    },
                    {
                      title: 'Last Updated At',
                      content: recording.updatedAt
                    },
                    {
                      title: 'Description',
                      content: recording.description
                        ? nl2br(recording.description)
                        : '-',
                      requiresLargeContainer: true
                    }
                  ]}
                />
              </SectionContainer>

            </div>
          </div>
          <div className={` ${project.locked ? 'ghosted-wrapper' : ''}`}>
            <CreatePagesHeader
              id='sessions'
              subtitle='Recording Sessions'
              showSearch={true}
              name='sessionsSearchTerm'
              getSearchTerm={this.getSearchTerm}
              linkText='View All'
              linkUrl={`/projects/${this.props.project.id}/recordings/${recording.id}/sessions`}
              // eslint-disable-next-line no-nested-ternary
              tags={!isProjectOwner && !isRecordingCollaborator ? getTags(filterPermissions(sessionPermissions)) : isRecordingCollaborator ? [{ tagName: 'Full Access' }] : []}
              dropDown={canCreateSession && (
                <DropdownMenu dropdownList={sessionDropdownList} />
              )
              }
            />
            <div className='sections-container recording-sessions'>
              <SectionContainer>
                <Query
                  query={GET_ALL_RECORDING_SESSIONS}
                  variables={{
                    recordingId: recording.id
                  }}
                  fetchPolicy='cache-and-network'
                >
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    return (
                      <SessionTable
                        nameCellClass='project-recording-session-table'
                        rows={data.getSessionsByRecording ? data.getSessionsByRecording : []}
                        searchTerm={this.state.sessionsSearchTerm}
                        showSearch={false}
                        locked={project.locked}
                        showSkeleton={loading}
                        projectId={project.id}
                        favourites={favourites.session || []}
                        canUpdate={canUpdateSession}
                        canDelete={canDeleteSession}
                        onDelete={refetch}
                        recordingDetailsPage
                        songPermissions={canSongs}
                      />
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>
          <div className='project-files recording-files'>
            <FileList
              match={match}
              location={location}
              uploadModal={uploadModal}
              uploads={uploads}
              project={project}
              recording={recording}
              setProjectIdAndFolderId={uploads.actions.setProjectIdAndFolderId}
              fileFavourites={favourites.file || []}
              folderFavourites={favourites.folder || []}
              moveFilesFrom= {this.props.moveFilesFrom}
            />
          </div>
          <div className={` ${project.locked ? 'ghosted-wrapper' : ''} `}>
            <CreatePagesHeader
              id='credits'
              subtitle='Recording Credits'
              btnText={canCreateCredit ? 'Add' : false}
              showSearch={true}
              name='creditsSearchTerm'
              getSearchTerm={this.getSearchTerm}
              btnOnClick={() => {
                if (project.locked) {
                  alert('Project Credits are locked. Please unlock to edit.', { title: '   ', className: 'credits-alert' })
                  return
                }
                history.push(
                  `/projects/${project.id}/recordings/${
                    recording.id
                  }/credits/create`
                )
              }
              }
              // eslint-disable-next-line no-nested-ternary
              tags={!isProjectOwner && !isRecordingCollaborator ? getTags(filterPermissions(sessionPermissions)) : isRecordingCollaborator ? [{ tagName: 'Full Access' }] : []}
              linkText='View All'
              linkUrl={`/projects/${this.props.project.id}/recordings/${recording.id}/credits`}
            />
            <div className='sections-container recording-credits'>
              <SectionContainer>
                <Query
                  query={GET_ALL_RECORDING_CREDITS}
                  variables={{
                    recordingId: recording.id
                  }}
                  fetchPolicy='cache-and-network'
                >
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }
                    return (
                      <div className='project-files '>
                        <CreditTable
                          nameCellClass='project-recording-credit-table'
                          rows={data.getCreditsByRecording ? data.getCreditsByRecording : []}
                          locked={project.locked}
                          showSkeleton={loading}
                          projectId={project.id}
                          recording={recording}
                          favourites={favourites.credit || []}
                          canUpdate={canUpdateCredit}
                          canDelete={canDeleteCredit}
                          onDelete={refetch}
                          showSearch={false}
                          songPermissions={canSongs}
                          hideIPI
                          isProjectOwner={isProjectOwner}
                        />
                      </div>
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>
          <div className={` ${project.locked ? 'ghosted-wrapper' : ''} `}>
            <CreatePagesHeader
              id='collaborators'
              subtitle='Recording Collaborators'
              btnText={!project.locked && (accountType !== 'free') && canCreateCollaborator ? 'Add' : false}
              showSearch={true}
              name='collaboratorsSearchTerm'
              getSearchTerm={this.getSearchTerm}
              btnOnClick={() => {
                history.push(`/projects/${project.id}/collaborators/create/recording`)
              }}
              tags={!isProjectOwner && (accountType !== 'free') ? isRecordingCollaborator || !canCreateCollaborator ? [{ tagName: 'Read' }] : [{ tagName: 'Full Access' }] : []}

            />
            <div className='sections-container recording-collaborators'>
              <SectionContainer>
                <Query
                  query={GET_RECORDING_COLLABORATORS}
                  variables={{
                    recordingId: recording.id }}
                  fetchPolicy='network-only'
                >
                  {({ loading, error, data }) => {
                    if (error) {
                      return `Error! ${error.message}`
                    }

                    let collaborators = data.getCollaboratorsByRecording
                    let owner = project.user

                    return (
                      <div className='project-files'>
                        <CollaboratorsTable
                          nameCellClass='collaborator-table'
                          rows={collaborators || []}
                          showSkeleton={loading}
                          owner={owner}
                          searchTerm={this.state.collaboratorSearchTerm}
                          showSearch={false}
                          canUpdate={canUpdateCollaborator}
                          canDelete={canDeleteCollaborator}
                          projectId={project.id}
                          recordingId={recording.id}
                          userId={user.id}
                          pagination
                          sortDirection='ASC'
                          sortBy='Name'
                          perPage={10}
                          projectPage={false}
                        />
                      </div>
                    )
                  }}
                </Query>
              </SectionContainer>
            </div>
          </div>

        </main>
      </>
    )
  }
}

export default compose(
  WithUploads,
  WithUploadModal,
  GetUser,
  GetProject,
  GetRecording,
  DeleteSessionRecording,
  WithAlertModal,
  WithComments,
  WithMoveFilesFrom
)(RecordingDetail)

class FileList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
  }


  onMoveFilesFrom =(refetch)=>{
    const { project, recording } = this.props
    const breadCrumb = [{
      href: `/projects/${project.id}`,
      name: `${project.name}`
    },
    {
      href: `#`,
      name: `${recording.name}`
    }
    ]
    this.props.moveFilesFrom.open(null,
      this.props.recording.folderId,
      breadCrumb,
      refetch
    )
  }

  filesDropdownList =(refetch)=> [
    { name: 'Upload', icon: uploadIcon, click: ()=>this.onClickUpload(refetch) },
    { name: 'Files', icon: folderActive, click: ()=> this.onMoveFilesFrom(refetch) }
  ];

  onClickUpload =(refetch) =>{
    const { uploadModal, recording, project } = this.props
    uploadModal.open(
      recording.name,
      project.name,
      project.id,
      recording.folderId,
      '',
      refetch
    )
  }
   getSearchTerm=(value)=>{
     this.setState({
       searchTerm: value
     })
   }
   dropDownMenuList = (path, refetch)=>{
     return (
       [
         { name: 'Upload', icon: uploadIcon, click: this.onClickUpload },
         { name: 'Files', icon: folderActive, click: ()=> this.onMoveFilesFrom(refetch) }
       ]
     )
   }
   getBreadcrumb(file, folders, currentFolder = false) {
     const { project, recording } = this.props
     let breadcrumb = [
       {
         href: `/projects/${project.id}`,
         name: project.name
       },
       {
         href: `/projects/${project.id}/recordings`,
         name: 'Recordings'
       },
       {
         href: `/projects/${project.id}/recordings/${recording.id}`,
         name: recording.name
       },
       {
         href: `/projects/${project.id}/recordings/${recording.id}/files`,
         name: 'Files'
       }
     ]

     return { breadcrumb }
   }

   render() {
     const {
       project,
       location,
       match,
       recording,
       uploads,
       fileFavourites,
       folderFavourites,
       uploadModal,
       setProjectIdAndFolderId
     } = this.props
     const isProjectOwner = permissions.isProjectOwner(project.id)
     const canFiles = permissions.can(project.id, 'recording')
     const canCreateFiles = isProjectOwner || canFiles.create
     const canDownloadFiles = isProjectOwner || canFiles.delete
     const canDeleteFiles = isProjectOwner || canFiles.delete

     return (
      <>
        {location.pathname === match.url && (
          <Query
            query={GET_PROJECT_FOLDER}
            variables={{
              projectId: project.id,
              folderId: recording.folderId
            }}
            fetchPolicy='cache-and-network'
            pollInterval={uploads.state.inProgressCount > 0 ? 0 : 10000}
          >
            {({ loading, error, data, refetch, networkStatus }) => {
              const actuallyLoading =
                loading &&
                networkStatus !== NetworkStatus.poll &&
                networkStatus !== NetworkStatus.refetch

              if (error) {
                return `Error! ${error.message}`
              }

              let isRecordingCollaborator = false
              let files = []
              let folders = []

              if (!actuallyLoading) {
                files = data.getFolder.files
                folders = data.getFolder.folders

                isRecordingCollaborator =
                  data.getFolder.recording &&
                  permissions.hasRecordingAccess(project.id, data.getFolder.recording.id)
              }

              return (
                <>
                <CreatePagesHeader
                  subtitle='Recording Files'
                  btnText={canCreateFiles || isRecordingCollaborator ? 'Upload' : false}
                  dropDownMenuList={canCreateFiles || isRecordingCollaborator ? this.filesDropdownList(refetch) : false}

                  showSearch={true}
                  getSearchTerm={this.getSearchTerm}
                  linkText='View All'
                  linkUrl={`/projects/${this.props.project.id}/recordings/${recording.id}/files`}
                  // eslint-disable-next-line no-nested-ternary
                  tags={!isProjectOwner ? isRecordingCollaborator || canCreateFiles ? [{ tagName: 'Full Access' }] : [{ tagName: 'Read' }] : []}
                />
                <div className='sections-container'>
                  <SectionContainer>
                    <FileTable
                      nameCellClass='project-recording-details-file'
                      rows={[...folders, ...files]}
                      showSearch={false}
                      searchTerm={this.state.searchTerm}
                      projectId={project.id}
                      folderId={recording.folderId}
                      fileFavourites={fileFavourites}
                      folderFavourites={folderFavourites}
                      showSkeleton={actuallyLoading}
                      refetch={refetch}
                      favourite
                      batchActions
                      actions
                      rowActions
                      canCreate={canCreateFiles || isRecordingCollaborator}
                      canDownload={canDownloadFiles || isRecordingCollaborator}
                      canDelete={canDeleteFiles || isRecordingCollaborator}
                      canAddToPlaylist={true}
                    />
                  </SectionContainer>
                </div>
                  </>
              )
            }}
          </Query>
        )}
        <Route
          path={`${match.url}/:folderId`}
          render={props => (
            <FileList
              {...props}
              project={project}
              uploadModal={uploadModal}
              uploads={uploads}
              setProjectIdAndFolderId={setProjectIdAndFolderId}
              fileFavourites={fileFavourites}
              folderFavourites={folderFavourites}
            />
          )}
        />
      </>
     )
   }
}

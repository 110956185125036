import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'react-apollo'

import { GetGradientFromId } from '../utils/constants'

import WithComments from '../hocs/WithComments'

import CreateUserFavourite from '../hocs/mutations/CreateUserFavourite'
import DeleteUserFavourite from '../hocs/mutations/DeleteUserFavourite'
import SaveToggle from './SaveToggle'
import ApolloClient from '../utils/Apollo'
import { DateTime } from 'luxon'
import WithAlertModal from '../hocs/WithAlertModal'
import Breadcrumb from './Breadcrumb'
import { Modal } from 'carbon-components-react'
import { useMemo } from 'react'
import { GET_SONG_INTEGRATION_LOG } from '../graphql/queries'

const SongHero = React.memo(props => {
  const [showIntegrations, setShowIntegrations] = useState(false)
  const [showInformation, setShowInformation] = useState(false)
  const [lastExport, setLastExport] = useState({})
  const formatter = data => DateTime.fromSQL(data, { zone: 'utc' }).toISODate()
  const getIntegrationLogs = async () => {
    const { data } = await ApolloClient.query({
      query: GET_SONG_INTEGRATION_LOG,
      variables: {
        songId: props.id
      },
      fetchPolicy: 'network-only'
    })
    const { getSongIntegrationLog } = data
    setLastExport(getSongIntegrationLog)
  }

  const songDescriptionCheck = data => {
    if (data.split('').length < 360) {
      return data
    }
    return (
      <div>
        {data.substring(0, 360)}
        <span onClick={() => setShowInformation(true)}
          className='moreDescriptionBtn'>&nbsp;&nbsp;...more</span>
      </div>
    )
  }

  const setToggle = () => {
    setShowInformation(!showInformation)
  }

  useEffect(() => {
    getIntegrationLogs()
  }, [])


  let getFirstLetters = props
    .name.toUpperCase().replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g)
  const initials = getFirstLetters ? getFirstLetters.join('').substr(0, 4) : ''


  const exportPdf = () => {
    setTimeout(() => {
      getIntegrationLogs()
    }, 3000)

    window.open(
      `${process.env.REACT_APP_API}/api/pdf/song-export?token=${localStorage.getItem(
        'authToken'
      )}&songId=${props.id}`,
      '_blank'
    )
  }


  return (
    <div
      className={
        'song-hero' +
        (!props.image ? ' song-hero--no-image' : '') +
        (' ' + props.SongHeroSection)
      }
    >
      <div className='songHeroContent song-HeroContent'>
        <div
          className='songHeroThumbnail'
          style={
            props.image
              ? {}
              : {
                background: useMemo(()=>GetGradientFromId(props.id), [props.id])
              }
          }
        >
          {props.image && (
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}${props.imageDir}${props.image}`}
              alt={props.name}
            />
          )}
          {!props.image && <span>{initials}</span>}
        </div>
        <div className='songHeroBody'>
          <header className='song-detail'>
            {props.artist && (
              <h3>
                {props.artist ? props.artist.name : ''} &&{' '}
                <span>#{props.number}</span>
              </h3>
            )}
            {props.isSongHeader && (
              <Breadcrumb
                customHeaderClass='margin-0'
                items={[
                  {
                    href: `/songs`,
                    name: 'Songs'
                  },
                  {
                    href: `/songs/${props.id}`,
                    name: props.name
                  }
                ]}
              />
            )}
            <h1 className='song-detail-header  cutoff-project-title'>{props.name}</h1>
            <div className='songHeroDesription'>
              {props.description && (
                <><span className='songHeroDesription'>
                  {songDescriptionCheck(props.description)}
                </span></>
              )}
            </div>
            {showInformation && <Modal className='export-details-modal'
              open={showInformation}
              passiveModal={false}
              onRequestClose={setToggle}
              onRequestSubmit={setToggle}
              onSecondarySubmit={false}
              primaryButtonText='Close'
              secondaryButtonText={null}
            ><h2>Description</h2><p>{props.description}</p></Modal>}

          </header>
          <div className='song-HeroLinks songHeroLinks'>
            <div className='songHeroItem'>
              {props.isSongOwner ? (
                <span>Owner</span>
              ) : (
                <span>Collaborator</span>
              )}
            </div>
            <SaveToggle
              favourite={props.favourite}
              onClick={() => {
                if (props.favourite) {
                  return props.deleteUserFavourite(props.id, 'song')
                }
                return props.createUserFavourite(props.id, 'song')
              }}
            />
            <Link
              to={`/songs/${props.id}/files`}
              className='songHeroItem'
            >
              <img
                src={require('../assets/images/files-icon.svg')}
                className='hero-icon'
                alt=''
              />
              <span>{props.fileCount}</span>
            </Link>
            <button
              onClick={() => {
                setShowIntegrations(!showIntegrations)
                getIntegrationLogs()
              }}
              className={`songHeroItem songHeroExport ${
                showIntegrations ? 'export-active' : ''
              }`}
            >
              <img
                src={require('../assets/images/data-icon.svg')}
                className='hero-icon'
                alt=''
              />
              <span>Export</span>
            </button>
          </div>
        </div>
      </div>
      {showIntegrations ? (
        <div className='export-integrations-wrapper'>
          <div className='songHeroContent'>
            <div className='header'>Export</div>
            <div className='content'>
              <div className='song-HeroLinks'>
                <div className={props.SongHeroSection} onClick={exportPdf}>
                  <div className='btn-group'>
                    <p
                      target='_blank'
                      rel='noopener noreferrer'
                      className='songHeroItem'
                    >
                      <img
                        src={require('../assets/images/pdf.svg')}
                        className='hero-icon'
                        alt=''
                      />
                    Export PDF
                    </p>
                  </div>

                  <p className='export-text'>
                  Last Export:
                    <span className='export-date'>
                      { lastExport && lastExport.pdf === 'Never'
                        ? lastExport.pdf
                        : formatter(lastExport.pdf)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
})

export default compose(
  CreateUserFavourite,
  DeleteUserFavourite,
  WithComments,
  WithAlertModal
)(SongHero)

import { Modal } from 'carbon-components-react'
import React from 'react'
import { compose, Query } from 'react-apollo'
import { GET_FILE_PREVIEW } from '../graphql/queries'
import GetUser from '../hocs/queries/GetUser'
import WaveFormAndComments from './WaveFormAndComments'
import AddRegionComment from '../hocs/mutations/AddRegionComment'
import DeleteRegionComment from '../hocs/mutations/DeleteRegionComment'
import EditRegionComment from '../hocs/mutations/EditRegionComment'
class WaveformPreviewModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      count: 0
    }
  }

  setCount = () => {
    const { file } = this.props
    const { count } = this.state
    if (file.length && count === file.length - 1) {
      this.setState({
        count: 0
      })
    } else {
      this.setState({
        count: count + 1
      })
    }
  };

  render() {
    const { file } = this.props
    const { count } = this.state
    return (
      <div className='waveform-preview-modal'>
        <Modal
          className='modal'
          open={this.props.open}
          passiveModal={true}
          onRequestClose={this.props.onRequestClose}
        >
          {this.props.open && (
            <Query
              query={GET_FILE_PREVIEW}
              variables={{
                id: file.length ? file[count].id : file.id
              }}
              fetchPolicy='network-only'
            >
              {({ data, loading }) => {
                if (loading) {
                  return <p>Loading</p>
                }

                return (
                  <WaveFormAndComments
                    data={data}
                    {...this.props}
                    setCount={this.setCount}
                    file={file.length ? file[count] : file}
                  />
                )
              }}
            </Query>
          )}
        </Modal>
      </div>
    )
  }
}

export default compose(
  GetUser,
  AddRegionComment,
  DeleteRegionComment,
  EditRegionComment,
)(WaveformPreviewModal)

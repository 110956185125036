import gql from 'graphql-tag'
import * as fragment from './fragments'

export const COMMENTS_SUBSCRIPTION = gql`
  subscription CommentCreated($projectId: ID!, $resourceType: String!, $resourceId: ID!) {
    commentCreated(projectId: $projectId, resourceType: $resourceType, resourceId: $resourceId) {
      ...CommentFields
    }
  }
  ${fragment.COMMENT_FIELDS}
`

export const EVENT_LOG_CREATED_SUBSCRIPTION = gql`
  subscription EventLogCreated($projectId: ID!) {
    eventLogCreated(projectId: $projectId) {
      ...EventLogFields
    }
  }
  ${fragment.EVENT_LOG_FIELDS}
`

export const USER_UNREAD_ACTIVITIES = gql`
  subscription UserUnreadActivities($projectId: ID!) {
    userUnreadActivities(projectId: $projectId) {
      count
      lastRead
    }
  }
`

export const USER_STORAGE_UPDATED = gql`
  subscription UserStorageUpdated {
    userStorageUpdated {
      totalStorageUsed
      totalStorageUsedPretty
      totalStorageUsedPercentage
    }
  }
`

export const USER_SUBSCRIPTION_UPDATED = gql`
  subscription UserSubscriptionUpdated {
    userSubscriptionUpdated {
      stripePlan
    }
  }
`

export const USER_PERMISSIONS_UPDATED = gql`
  subscription UserPermissionsUpdated {
    userPermissionsUpdated {
      ...UserFields
    }
  }
  ${fragment.USER_FIELDS}
`

import { Field, Schema } from 'v4f'
import * as Errors from '../utils/errors'

export default Schema(
  {
    assignSessionId: Field()
      .array()
      .required({ message: Errors.Required })
  },
  { verbose: true, async: true }
)

import React from 'react'

const Overlay = () => {
  const goOnAppStore = () => {
    window.open(
      'https://apps.apple.com/us/app/veva-check-in/id1518893753',
      '_blank'
    )
  }
  const goOnGoogle = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.vevacheckin.vevacheckin',
      '_blank'
    )
  }
  return (
    <div className='black-overlay'>
      <p>
        VEVA Collect web application works with windows widths 1024 pix and up.
        For smaller screens, please use our mobile app for Android or iOS.
      </p>
      <div className='store-link'>
        <img
          alt='google store'
          onClick={goOnGoogle}
          src='https://lh3.googleusercontent.com/q1k2l5CwMV31JdDXcpN4Ey7O43PxnjAuZBTmcHEwQxVuv_2wCE2gAAQMWxwNUC2FYEOnYgFPOpw6kmHJWuEGeIBLTj9CuxcOEeU8UXyzWJq4NJM3lg=s0'
        />
        <img
          alt='app store'
          onClick={goOnAppStore}
          src='https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg'
        />
      </div>
    </div>
  )
}

export default Overlay

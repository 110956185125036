import React from 'react'
import { Modal } from 'carbon-components-react'
import { compose, graphql, Query } from 'react-apollo'
import {
  GET_ALL_PLAYLISTS
} from '../graphql/queries'
import {
  ATTACH_TO_PLAYLIST
} from '../graphql/mutations'
import ResponsiveTable from './ResponsiveTable'
import WithAlertModal from '../hocs/WithAlertModal'
import { withRouter } from 'react-router'
class AttachToPlaylistModal extends React.PureComponent {
  constructor(props) {
    super(props)

    this.currentFolderCount = 1

    this.state = {
      selectedId: '',
      filesFlow: false,
      projectFlow: true,
      working: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit=async ()=> {
    try {
      await this.props.attachFiles({
        variables: {
          id: this.state.selectedId,
          file_ids: this.props.files

        }
      })
      this.props.alertMsg()
      this.props.onRequestClose()
    } catch (error) {
      this.props.alert('Could not add to playlist')
    }
  }

  onSelect = (id, name, e) =>{
    if (e.target.checked === true) {
      this.setState({
        selectedId: id

      })
    } else {
      this.setState({
        selectedId: ''

      })
    }
  }

  render() {
    const { isSelected, selectedId } = this.state
    if (!this.props.open) {
      return null
    }


    return (
      <div className='move-modal'>
        <Modal
          className='modal-scrollable'
          open={this.props.open}
          modalHeading='Add To Playlist'
          primaryButtonText='Attach'
          secondaryButtonText='Cancel'
          selectorPrimaryFocus='.bx--text-input'
          onRequestSubmit={this.handleSubmit}
          onRequestClose={this.props.onRequestClose}
        >
          <Query query={GET_ALL_PLAYLISTS}
            fetchPolicy='network-only'>
            {({ loading, error, data }) => {
              if (loading) {
                return (
                <>
                  <ResponsiveTable
                    rows={[].fill(null, 0, this.currentFolderCount)}
                    showSearch={false}
                    showSkeleton={true}
                    headers={[
                      {
                        key: 'name',
                        header: 'Name'
                      }
                    ]}
                    emptyText='No Playlists'
                  />
                </>
                )
              }

              if (error) {
                return (
                <>
                  <p>Error</p>
                </>
                )
              }


              return (
              <>
                <ResponsiveTable
                  bodyType='attach'
                  projectId={this.props.projectId}
                  folderId={this.state.folderId}
                  onSelect = {this.onSelect}
                  isSelected={isSelected}
                  selectedId={selectedId}
                  rows={data.getPlaylists || []}
                  showSearch={false}
                  headers={[
                    {
                      key: 'title',
                      header: ''
                    }
                  ]}
                  linkColumn='title'
                  emptyText='Empty folder'
                  generateLink={() => true}
                />
              </>
              )
            }}
          </Query>
        </Modal>
      </div>
    )
  }r
}

export default compose(
  graphql(ATTACH_TO_PLAYLIST, { name: 'attachFiles' }),
  WithAlertModal,
  withRouter
)(AttachToPlaylistModal)


import React from 'react'
import { compose, graphql } from 'react-apollo'
import { Loading } from 'carbon-components-react'

import ApolloClient from '../utils/Apollo'
import { ACCEPT_COLLABORATOR_INVITE } from '../graphql/mutations'
import { GET_USER } from '../graphql/queries'

import GetUser from '../hocs/queries/GetUser'

import SectionContainer from '../components/SectionContainer'
import Hero from '../components/Hero'

class Invite extends React.Component {
  state = {
    accepting: true,
    error: false
  }

  componentDidMount() {
    this.props.acceptCollaboratorInvite(this.props.match.params.token)
      .then(({ data }) => {
        this.refreshUser().then(() => {
          this.props.history.push(`/projects/${data.acceptCollaboratorInvite.project.id}`)
        })
      }).catch(() => {
        this.setState({
          ...this.state,
          accepting: false,
          error: true
        })
      })
  }

  refreshUser() {
    return ApolloClient.query({
      query: GET_USER,
      fetchPolicy: 'network-only'
    })
  }

  render() {
    return (
      <>
        <main className='main'>
          <Hero 
          subtitle='Accepting Invite'
          />
          <div className='mainContainer'>
            <SectionContainer >
              {this.state.accepting &&
                <Loading withOverlay={false}
                  className='bx--loading--center' />
              }
              {this.state.error &&
                <p>Invalid invite.</p>
              }
            </SectionContainer>
          </div>
        </main>
      </>
    )
  }
}

export default compose(
  GetUser,
  graphql(ACCEPT_COLLABORATOR_INVITE, {
    name: 'acceptCollaboratorInvite',
    props: (data) => {
      return {
        acceptCollaboratorInvite: (token) =>
          data.acceptCollaboratorInvite({
            variables: {
              token
            }
          })
      }
    }
  })
)(Invite)

import React from 'react'
import Helmet from 'react-helmet-async'
import CreatePagesHeader from '../../components/CreatePagesHeader'
// Our Base Components
import MenuSwitcher from '../../components/MenuSwitcher'
import IntegrationTiles from './IntegrationTiles'

const Integrations = () => {
  return (
      <>
        <Helmet>
          <title>Integrations</title>
        </Helmet>
        <main className='main integrations'>
          <CreatePagesHeader
            subtitle=' Integrations'>
            <div className='switcher-Style'>
              <MenuSwitcher/>

            </div>
          </CreatePagesHeader>

          <div className='sections-container'>
            <IntegrationTiles/>
          </div>
        </main>
      </>
  )
}


export default Integrations

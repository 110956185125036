import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose, graphql } from 'react-apollo'
import { DELETE_PLAYLIST } from '../graphql/mutations'

import { GetGradientFromId } from '../utils/constants'

import DetectEdgeTile from '../hocs/DetectEdgeTile'
import WithAlertModal from '../hocs/WithAlertModal'
import DropdownMenu from '../components/Dropdowns/DropdownMenu'
import toggleButton from '../assets/actions.svg'
import CreateUserFavourite from '../hocs/mutations/CreateUserFavourite'
import DeleteUserFavourite from '../hocs/mutations/DeleteUserFavourite'

import PauseIcon from '../assets/images/pausePublic.svg'
import PlayIcon from '../assets/waveform/play-icon-playlist.svg'
import WhiteOutlineStarIcon from '../assets/images/white-outline-star.svg'
import OrangeFilledStarIcon from '../assets/images/favourite.svg'

class ProjectTile extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      toggleIcon: false,
      isFavourite: props.isFavourite,
      favouriteIcon: props.isFavourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon

    }

    if (!props.image) {
      let randGradient = GetGradientFromId(props.id)
      this.selectedGradient = {
        background: randGradient
      }
    }
    this.handleFavorites = this.handleFavorites.bind(this)
  }


  componentDidUpdate(prevProps) {
    if (this.props.isFavourite !== prevProps.isFavourite) {
      this.setState({
        favouriteIcon: this.props.isFavourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon
      })
    }
  }

  handleToggleIcon = (value)=>{
    this.setState({
      toggleIcon: value
    })
  }

  viewPlaylist = ()=> {
    this.props.history.push(`playlists/${this.props.id}`)
  }

  editPlaylist = () => {
    this.props.history.push(`playlists/${this.props.id}/edit`)
  }

  deletePlaylistPush = () => {
    this.props.alert('Do you really want to delete this playlist and all its content?', {
      type: 'delete',
      buttonText: 'Yes',
      submitCallback: async close => {
        try {
          await this.props.deletePlaylist({
            variables: {
              input: {
                id: this.props.id
              }
            }
          })
          this.props.refetch()
          close()
        } catch (error) {
          this.props.alert('This playlist could not be deleted.')
        }
      }

    })
  }
  handleFavorites = () => {
    if (this.props.disableFavourite) {
      return
    }

    this.setState({
      isFavourite: !this.state.isFavourite,
      favouriteIcon: !this.state.isFavourite ? OrangeFilledStarIcon : WhiteOutlineStarIcon
    })

    if (this.state.isFavourite) {
      this.props.deleteUserFavourite(this.props.id, 'playlist')
    } else {
      this.props.createUserFavourite(this.props.id, 'playlist')
    }
    this.props.refetch()
  }

  render() {
    const {
      title,
      files,
      generateUrl,
      id,
      image,
      withOptions,
      waveformPreviewModal,
      showPlayIcon
    } = this.props

    const initials = this.props
      .title.toUpperCase().replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g).join('').substr(0, 4)

    const url = (generateUrl ?
      generateUrl(this.props) : `/playlists/${id}`
    )
    const playlistDropdownList = [
      {
        name: 'View',
        icon: '',
        click: this.viewPlaylist
      },
      { name: 'Edit', icon: '', click: this.editPlaylist },
      { name: 'Delete', icon: '', click: this.deletePlaylistPush }
    ]

    const handlePlayPause = () => {
      if (files.length > 0) {
        waveformPreviewModal.open(files, this.handleToggleIcon)
      }
    }

    const PlayPauseIcon = this.state.toggleIcon ? PauseIcon : PlayIcon
    const PlayListArtOrCover = image ? (
      <Link to={url} className='playlistArt'>
        <img src={`${process.env.REACT_APP_IMAGE_URL}playlists/${image}`}
          alt={title} />
      </Link>
    ) : (
      <Link to={url} className='playlistCover' style={this.selectedGradient}>
        <span>{initials}</span>
      </Link>
    )
    return (
      <div className='playlist'>
        {PlayListArtOrCover}

        <img onClick={this.handleFavorites}
          src={this.state.favouriteIcon}
          className='playlistTile-favorites'
          alt='favorites' />

        <div className='playlistInfoContainer'>
          <div className='playlistInfo'>
            <div className='info-container'>
              {showPlayIcon &&
                <div onClick={handlePlayPause} className='play-icon'>
                  <img src={PlayPauseIcon} alt='play' />
                </div>
              }
              <div className='title-container'>
                <h3 title={title}>{title}</h3>
                { files && <aside>  {files.length} Files </aside> }
              </div>
            </div>
          </div>

          {withOptions &&
          <div className='dropdown-icon'>
            <DropdownMenu
              dropdownList={playlistDropdownList}
              toggleBtn= {toggleButton}
            />
          </div>
          }
        </div>
      </div>
    )
  }
}

export default compose(
  DeleteUserFavourite,
  CreateUserFavourite,
  DetectEdgeTile,
  WithAlertModal,
  withRouter,
  graphql(DELETE_PLAYLIST, { name: 'deletePlaylist' })
)(ProjectTile)
